import {
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAILED,
  GET_CUSTOMERS_DETAIL_REQUEST,
  GET_CUSTOMERS_DETAIL_SUCCESS,
  GET_CUSTOMERS_DETAIL_FAILED,
  TRANSFER_ADMIN_REQUEST,
  TRANSFER_ADMIN_SUCCESS,
  TRANSFER_ADMIN_FAILED,
  LOAD_MORE_CUSTOMERS_REQUEST,
  LOAD_MORE_CUSTOMERS_SUCCESS,
  LOAD_MORE_CUSTOMERS_FAILED,
  REGISTER_CUSTOMER_REQUEST,
  REGISTER_CUSTOMER_SUCCESS,
  REGISTER_CUSTOMER_FAILED,
  UPDATE_CUSTOMERS_SUCCESS,
  CLEAR_CUSTOMERS_DETAIL
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';

const transferAdminHelper = (state, action) => {
  const currentList = state.data.list.map(item => {
    if (item.teamId === action.payload.data.team._id) {
      if (item.userId._id === action.payload.data.team.admin.userId._id) {
        return {
          ...item,
          isAdmin: true,
        };
      }
      return {
        ...item,
        isAdmin: false,
      };
    }
    return item;
  });
  return { ...state.data, list: currentList };
};

const loadMoreHelper = (state, action) => {
  const currentList =
    state.data && state.data.list && state.data.list.length
      ? [...state.data.list, ...action.payload.data.list]
      : [...action.payload.data.list];
  return { ...state.data, ...action.payload.data, list: currentList };
};

const defaultState = {
  success: false,
  error: null,
  data: [],
  isLoading: false,
  detailData: null,
};

const customersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_REQUEST: {
      return {
        ...state,
      };
    }
    case GET_CUSTOMERS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case GET_CUSTOMERS_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        error: action.payload,
      };
    }
    case CLEAR_CUSTOMERS_DETAIL: {
      return {
        ...state,
        detailData: null
      }
    }
    case LOAD_MORE_CUSTOMERS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case LOAD_MORE_CUSTOMERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: loadMoreHelper(state, action),
      };
    }
    case LOAD_MORE_CUSTOMERS_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case GET_CUSTOMERS_DETAIL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_CUSTOMERS_DETAIL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        detailData: { ...action.payload.data },
      };
    }
    case GET_CUSTOMERS_DETAIL_FAILED: {
      if (action.payload.message) {
        openNotification('error', action.payload.message);
      }

      console.log('GET_CUSTOMERS_DETAIL_FAILED', action.payload);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case REGISTER_CUSTOMER_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case REGISTER_CUSTOMER_SUCCESS: {
      openNotification('success', 'Invitation sent successfully');
      return {
        ...state,
        isLoading: false,
        detailData: action.payload.data,
      };
    }
    case REGISTER_CUSTOMER_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case TRANSFER_ADMIN_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case TRANSFER_ADMIN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        detailData: { ...action.payload.data },
        data: transferAdminHelper(state, action),
      };
    }
    case TRANSFER_ADMIN_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case UPDATE_CUSTOMERS_SUCCESS: {
      openNotification('success', 'Update successful');
      return {
        ...state,
        isLoading: false,
        detailData: { ...action.payload.data },
      };
    }
    default:
      return state;
  }
};

export default customersReducer;
