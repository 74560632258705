import emitter from './index';

// Auth
export const sendLogoutAction = () => {
  emitter.emit('logout');
};

export const logoutActionListener = listener => {
  emitter.on('logout', listener);

  return () => emitter.off('logout', listener);
};

// Get Number of Project
export const sendGetNumberProjectAction = () => {
  emitter.emit('getNumberProject');
};

export const getNumberProject = listener => {
  emitter.on('getNumberProject', listener);

  return () => emitter.off('getNumberProject', listener);
};

// Get customers
export const sendGetCustomersAction = () => {
  emitter.emit('getCustomers');
};

export const getCustomers = listener => {
  emitter.on('getCustomers', listener);

  return () => emitter.off('getCustomers', listener);
};


// Change alias to update data asset frame io
export const sendGetListAssetAction = (data) => {
  emitter.emit('getListAsset', data);
};

export const getListAssetsHandler = listener => {
  emitter.on('getListAsset', listener);

  return () => emitter.off('getListAsset', listener);
};
