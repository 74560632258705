import { getEnv } from '../configs/envConfig';
import io from 'socket.io-client';

let socketInstance;
const socketEvents = {};
let timeout = null;

export default () => {
  let socket;

  if (socketInstance) {
    socket = socketInstance;
  } else {
    socket = io.connect(`${getEnv('API_SERVER')}`, {
      path: '/ws',
      transports: ['websocket'],
    });
    socketInstance = socket;
  }
  socket.on('connect', () => {
    console.info('connected from client-----');
  });

  socket.on('error', err => {
    console.info('received socket error:');
    console.info(err);
  });

  function unregisterEvent() {
    socket.off('ReceivedMessage');
    socket.off('SendMessage');
    socket.off('MessageSent');
    socket.off('InitConversation');
    socket.off('SendNotification');
    socket.off('ReadNotification');
    socket.off('LeaveConversation');
    socket.off('joinProject');
    socket.off('updateProject');
    socket.off('leaveProject');
    socket.off('updateProjectSuccess');
    socket.off('CreateNewTopic');
    socket.off('UpdateTopic');
    socket.off('joinTopic');
    socket.off('updateTopic');
    socket.off('leaveTopic');
    socket.off('updateTopicSuccess');
  }

  function onConnected(callback) {
    socket.on('connect', callback);
  }

  function disconnect() {
    socket.close();
    socketInstance = null;
  }

  function onInitUserLogin(data) {
    socket.emit('InitUserLogin', data);
    // Object.assign(socketEvents, {
    //   InitUserLogin: () => socket.emit('InitUserLogin', data),
    // });
  }

  function onLeaveConversation(data) {
    socket.emit('LeaveConversation', data);
    delete socketEvents['JoinConversation'];
  }

  function onJoinConversation(conversation) {
    socket.emit('JoinConversation', conversation);
    Object.assign(socketEvents, {
      JoinConversation: () => socket.emit('JoinConversation', conversation),
    });
  }

  function onSendMessage(message) {
    socket.emit('SendMessage', message);
  }

  function onReceivedMessage(callback) {
    socket.on('ReceivedMessage', callback);
    Object.assign(socketEvents, {
      ReceivedMessage: () => socket.on('ReceivedMessage', callback),
    });
  }

  function onMessageSent(callback) {
    socket.on('MessageSent', callback);
    Object.assign(socketEvents, {
      MessageSent: () => socket.on('MessageSent', callback),
    });
  }

  function onSendNotification(callback) {
    socket.on('SendNotification', callback);
    Object.assign(socketEvents, {
      SendNotification: () => socket.on('SendNotification', callback),
    });
  }

  function onReadNotification(callback) {
    socket.on('ReadNotification', callback);
    Object.assign(socketEvents, {
      ReadNotification: () => socket.on('ReadNotification', callback),
    });
  }

  // project
  function joinProject(projectId) {
    socket.emit('joinProject', projectId);
  }

  function updateProject(project) {
    socket.emit('updateProject', project);
  }

  function leaveProject(project) {
    socket.emit('leaveProject', project);
  }

  function updateProjectSuccess(callback) {
    socket.on('updateProjectSuccess', callback);
    Object.assign(socketEvents, {
      updateProjectSuccess: () => socket.on('updateProjectSuccess', callback),
    });
  }

  function createNewTopic(callback) {
    socket.on('CreateNewTopic', callback);
    Object.assign(socketEvents, {
      CreateNewTopic: () => socket.on('CreateNewTopic', callback),
    });
  }

  function UpdateTopic(callback) {
    socket.on('UpdateTopic', callback);
    Object.assign(socketEvents, {
      UpdateTopic: () => socket.on('UpdateTopic', callback),
    });
  }

  // Topic
  function joinTopic(topicId) {
    socket.emit('joinTopic', topicId);
  }

  function updateTopic(topic) {
    socket.emit('updateTopic', topic);
  }

  function leaveTopic(topic) {
    socket.emit('leaveTopic', topic);
  }

  function updateTopicSuccess(callback) {
    socket.on('updateTopicSuccess', callback);
    Object.assign(socketEvents, {
      updateTopicSuccess: () => socket.on('updateTopicSuccess', callback),
    });
  }

  socket.on('reconnect', () => {
    unregisterEvent();
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      console.log('socket events = ', socketEvents);
      Object.values(socketEvents).map(socketFunc => socketFunc());
    }, 250);
  });

  return {
    socket,
    unregisterEvent,
    onJoinConversation,
    onSendMessage,
    onReceivedMessage,
    onMessageSent,
    onSendNotification,
    onLeaveConversation,
    onInitUserLogin,
    onReadNotification,
    joinProject,
    updateProject,
    updateProjectSuccess,
    leaveProject,
    onConnected,
    disconnect,
    createNewTopic,
    UpdateTopic,
    joinTopic,
    updateTopic,
    leaveTopic,
    updateTopicSuccess,
  };
};
