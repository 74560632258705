import React from 'react';
import './serviceTypeForm.scss';
import { Form, Input, Button, Select, Upload, Icon, Checkbox } from 'antd';
import { dummyRequest, getS3CDN } from '../../../../core/utils/commonUtils';
import { S3_FOLDERS } from 'src/core/constants/constants';
import {
  extractFileExtension,
  uuid,
  renderRequireLabel,
} from 'src/core/utils/commonUtils';
import { httpService } from 'src/core/services/httpService';
import UploadList from '../../uploadList/uploadList';

const { Option } = Select;
const { TextArea } = Input;

class ServiceTypeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      showFileUpload: true,
      hasRemoveClicked: false,
    };
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const { itemUpdate, isCreate } = this.props;
        const isShowSlide = values.isShowSlide || false;
        const name = values.name.trim();
        const status = values.status.trim() === 'Active' ? true : false;
        const description = values.description.trim();
        const productTypeId = values.productTypeId;
        const { fileList, hasRemoveClicked } = this.state;
        const fileUpload = fileList && fileList.length ? fileList[0].originFileObj : null;
        let logoFile;
        const data = {
          productTypeId,
          name,
          status,
          description,
          isShowSlide,
        };
        // check if photo is required
        if (fileList && fileList.length === 0 && isCreate) {
          this.setState({
            isLogoRequired: true,
          });
          return;
        } else {
          this.setState({
            isLogoRequired: false,
          });
        }
        // upload file
        if (fileUpload) {
          logoFile = {
            filename: `${
              S3_FOLDERS.CMS.PRICING.SERVICE_TYPE
            }/${uuid()}${extractFileExtension({ filename: fileUpload.name })}`,
            file: fileUpload,
          };
          this.setState({
            isLoading: true,
          });
          const res = await httpService._upload({ photos: [logoFile] });
          if (res && res.length) {
            data.logoUrl = res[0];
          }
        } else {
          if (hasRemoveClicked || !itemUpdate.logoUrl) {
            this.setState({
              isLogoRequired: true,
            });
            return;
          } else {
            this.setState({
              isLoading: true,
            });
            data.logoUrl = itemUpdate.logoUrl;
          }
        }
        this.props.isCreate
          ? this.props.onCreateServiceType(data)
          : this.props.onUpdateServiceType(data);
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.serviceTypes !== prevProps.serviceTypes) {
      this.setState({
        isLoading: false,
      });
    }
  }

  onUploadChange = ({ fileList }) => {
    fileList = [...fileList].slice(-1);
    if (fileList.length) {
      const lastImage = fileList[fileList.length - 1];
      this.setState({
        isBtnDisabled: lastImage.status !== 'done',
      });
    }
    this.setState({ fileList, isLogoRequired: false });
  };

  onHandleRemoveFile = () => {
    this.setState({
      fileList: [],
      showFileUpload: false,
      hasRemoveClicked: true,
      isLogoRequired: true,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isCreate, itemUpdate, productTypes } = this.props;
    const { fileList, isLoading, showFileUpload, isLogoRequired } = this.state;
    const propsImage = {
      customRequest: dummyRequest,
      listType: 'picture',
      fileList: [...fileList],
      className: 'upload-list-inline',
      onChange: this.onUploadChange,
    };
    const productTypesRender = productTypes.map(item => (
      <Option key={item._id} value={item._id}>
        {item.name}
      </Option>
    ));
    if (isCreate) {
      return (
        <Form className="serviceType-form">
          <Form.Item label="Name">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Please input production type name',
                },
                {
                  whitespace: true,
                  message: 'Please do not input whitespace only',
                },
              ],
              initialValue: null,
            })(<Input placeholder="Name" size="large" />)}
          </Form.Item>
          <Form.Item label={renderRequireLabel('Illustration')}>
            <Upload {...propsImage}>
              <Button>
                <Icon type="upload" /> Upload Illustration
              </Button>
            </Upload>
            {isLogoRequired && (
              <div className="ant-form-explain content-error">Please upload file!</div>
            )}
          </Form.Item>
          <Form.Item label="Product Type" className="productTypes-item">
            {getFieldDecorator('productTypeId', {
              rules: [
                {
                  required: true,
                  message: 'Please select Product Type',
                },
              ],
              initialValue: productTypes[0]._id,
            })(<Select>{productTypesRender}</Select>)}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator('description', {
              rules: [
                {
                  required: true,
                  message: 'Please input description',
                },
              ],
              initialValue: null,
            })(<TextArea maxLength="100" rows={3} />)}
          </Form.Item>
          <Form.Item label="Status" className="status-item">
            {getFieldDecorator('status', {
              rules: [
                {
                  required: true,
                  message: 'Please select production type status',
                },
              ],
              initialValue: 'Active',
            })(
              <Select>
                <Option value="Active">Active</Option>
                <Option value="Inactive">Inactive</Option>
              </Select>
            )}
          </Form.Item>

          <Form.Item label="" className="status-item">
            {getFieldDecorator('isShowSlide', {
              initialValue: false,
              valuePropName: 'checked',
            })(<Checkbox>Show in Landing page</Checkbox>)}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              loading={isLoading}
              onClick={this.handleSubmit}
              className="serviceType-form-button btn-primary-0"
              size="large"
              disabled={isLoading}
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      );
    }
    return (
      <Form className="serviceType-form">
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Please input production type name',
              },
              {
                whitespace: true,
                message: 'Please do not input whitespace only',
              },
            ],
            initialValue: itemUpdate.name,
          })(<Input placeholder="Name" size="large" />)}
        </Form.Item>
        <Form.Item label={renderRequireLabel('Illustration')}>
          <Upload {...propsImage}>
            <Button>
              <Icon type="upload" /> Change Illustration
            </Button>
          </Upload>
          {isLogoRequired && (
            <div className="ant-form-explain content-error">Please upload file!</div>
          )}
        </Form.Item>
        {fileList && fileList.length === 0 && showFileUpload && itemUpdate.logoUrl && (
          <Form.Item>
            <UploadList
              url={getS3CDN(itemUpdate.logoUrl)}
              title={itemUpdate.name}
              onDelete={this.onHandleRemoveFile}
            />
          </Form.Item>
        )}
        <Form.Item label="Product Type" className="productTypes-item">
          {getFieldDecorator('productTypeId', {
            rules: [
              {
                required: true,
                message: 'Please select Product Type',
              },
            ],
            initialValue: itemUpdate.productTypeId._id,
          })(<Select>{productTypesRender}</Select>)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator('description', {
            rules: [
              {
                required: true,
                message: 'Please input description',
              },
            ],
            initialValue: itemUpdate.description,
          })(<TextArea maxLength="100" rows={3} />)}
        </Form.Item>
        <Form.Item label="Status" className="status-item mb-2">
          {getFieldDecorator('status', {
            rules: [
              {
                required: true,
                message: 'Please select production type status',
              },
            ],
            initialValue: itemUpdate.statusText,
          })(
            <Select>
              <Option value="Active">Active</Option>
              <Option value="Inactive">Inactive</Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label="" className="status-item">
          {getFieldDecorator('isShowSlide', {
            initialValue: itemUpdate.isShowSlide,
            valuePropName: 'checked',
          })(<Checkbox>Show in Landing page</Checkbox>)}
        </Form.Item>

        <Form.Item className="mb-2">
          <Button
            type="primary"
            loading={isLoading}
            onClick={this.handleSubmit}
            className="serviceType-form-button btn-primary-0"
            size="large"
            disabled={isLoading}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedServiceTypeForm = Form.create({
  name: 'normal_serviceType',
})(ServiceTypeForm);

export default WrappedServiceTypeForm;
