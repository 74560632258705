import React, { memo } from 'react';
import UserAvatar from 'src/components/common/userAvatar';

const AdminFullName = (props, ref) => {
  const { fullName, avt } = props;

  return (
    <div className="d-flex align-items-center">
      <UserAvatar avatar={avt} status={true} size="small" fullName={fullName} />

      <span className="ml-2">{fullName}</span>
    </div>
  );
};

export default memo(AdminFullName);
