import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './customerForm.scss';
import { Form, Divider, Spin } from 'antd';
import {
  getCustomersDetail,
  transferAdmin,
  getCustomers,
  updateCustomers,
} from '../../../../redux/actions/admin/customersAction';
import NotFound from '../../../screens/404/404';
import CustomerInfo from './customerInfo/customerInfo';
import TeamInfo from './teamInfo/teamInfo';

class CustomersForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getCustomerId = () => {
    const { params } = this.props.match;
    const { currentCustomerId } = this.props;
    return params.itemId || currentCustomerId;
  }

  componentDidMount() {
    this.props.onGetCustomersDetail(this.getCustomerId());
  }

  render() {
    const customerId = this.getCustomerId();
    const {
      customer,
      isLoading,
      onClose,
      onTransferAdmin,
      onGetCustomers,
      currentUser,
      onUpdateUser,
    } = this.props;
    const isFetching = (!customer && isLoading) || (customer && customer._id !== customerId);
    if (isFetching) {
      return <Spin />;
    }

    const isNotfound = !customer && !isLoading;
    if (isNotfound) {
      return <NotFound embedded={true} />;
    }

    return (
      <div className="customer-drawer-container">
        <>
          <CustomerInfo customerInfo={customer} onTransferAdmin={onTransferAdmin} />
          <Divider className="customer-divider" />
          <TeamInfo
            customerInfo={customer}
            onClose={onClose}
            onGetCustomers={onGetCustomers}
            currentUser={currentUser}
            onUpdateCompanyUser={onUpdateUser}
          />
        </>
      </div>
    );
  }
}

const WrappedCustomersForm = Form.create({ name: 'normal_customers' })(CustomersForm);

const mapStateToProps = state => {
  return {
    customer: state.customers.detailData,
    isLoading: state.customers.isLoading,
    currentUser: state.user.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetCustomersDetail: params => {
      dispatch(getCustomersDetail(params));
    },
    onGetCustomers: () => {
      dispatch(getCustomers({ page: 1, limit: 10 }));
    },
    onTransferAdmin: body => {
      dispatch(transferAdmin(body));
    },
    onUpdateUser: data => {
      dispatch(updateCustomers(data));
    },
  };
};

export default withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(WrappedCustomersForm)
);
