import {
	GET_HOUR_RATE_REQUEST,
	GET_HOUR_RATE_SUCCESS,
	GET_HOUR_RATE_FAILED,
	CREATE_HOUR_RATE_REQUEST,
	CREATE_HOUR_RATE_SUCCESS,
	CREATE_HOUR_RATE_FAILED,
	UPDATE_HOUR_RATE_REQUEST,
	UPDATE_HOUR_RATE_SUCCESS,
	UPDATE_HOUR_RATE_FAILED,
	UPDATE_ORDER_HOUR_RATE_SUCCESS,
	UPDATE_ORDER_HOUR_RATE_FAILED
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';
import { updateHelper, compare } from '../../../core/utils/commonUtils';

const defaultState = {
	success: false,
	error: null,
	data: [],
	isLoading: false
};

const hourRateReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_HOUR_RATE_REQUEST: {
			return {
				...state
			};
		}
		case GET_HOUR_RATE_SUCCESS: {
			return {
				...state,
				...action.payload
			};
		}
		case GET_HOUR_RATE_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				error: action.payload
			};
		}
		case CREATE_HOUR_RATE_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case CREATE_HOUR_RATE_SUCCESS: {
			openNotification('success', 'Create successfully');
			return {
				...state,
				isLoading: false,
				data: [action.payload.data, ...state.data].sort(compare)
			};
		}
		case CREATE_HOUR_RATE_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_HOUR_RATE_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case UPDATE_HOUR_RATE_SUCCESS: {
			openNotification('success', 'Update successful');
			return {
				...state,
				isLoading: false,
				data: updateHelper(state, action)
			};
		}
		case UPDATE_HOUR_RATE_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_ORDER_HOUR_RATE_SUCCESS: {
			return {
				...state,
				isLoading: false,
				data: action.payload.data
			};
		}
		case UPDATE_ORDER_HOUR_RATE_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		default:
			return state;
	}
};

export default hourRateReducer;
