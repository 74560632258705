import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Empty, Modal, DatePicker, InputNumber, Form, Input } from 'antd';
import Geosuggest from 'react-geosuggest';
import ReactPhoneInput from 'react-phone-input-2';
import { VIDOPS_LOCATION } from 'src/core/constants/constants';
import { getCreatives, loadMoreCreatives } from 'src/redux/actions/admin/creativesAction';
import ScrollList from 'src/components/common/scrollList/scrollList';

const { TextArea } = Input;
/**
 * @param {{
*   detail?: import('./index').ProductionDate & { order: number }
*   visible: boolean
*   onClose: Function
*   form: import('antd/lib/form/Form').WrappedFormUtils
* }} props
*/
function ProductionDateModal(props) {
  const { detail, visible, onClose, form } = props;
  const { getFieldDecorator } = form;
  const dispatch = useDispatch();
  const creativesData = useSelector(state => state.creatives.data)
  const isLoadingMore = useSelector(state => state.creatives.isLoadingMore)

  const address = {
    address_components: [],
    formatted_address: VIDOPS_LOCATION.formatted_address,
    location: {
      lat: VIDOPS_LOCATION.lat,
      lng: VIDOPS_LOCATION.lng,
    },
    place_id: VIDOPS_LOCATION.place_id,
    label: VIDOPS_LOCATION.label,
  };

  const [data, setData] = useState((detail && detail.startDate) ? detail : {
    startDate: null,
    duration: null,
    address,
    addressNote: null,
    contact: {
      fullName: null,
      email: null,
      phoneNumber: null,
      note: null
    },
    creatives: [],
  });

  const [creativesPage, setCreativesPage] = useState(1);
  const [isLoadCreatives, setLoadCreatives] = useState(true);

  const updateFieldData = (field, fieldData) => {
    setData({
      ...data,
      [field]: fieldData
    });
  };

  const updateAddress = suggestion => {
    if (!suggestion) {
      return;
    }

    const { location, label, gmaps } = suggestion;
    const { address_components, formatted_address, place_id } = gmaps;

    const address = {
      address_components,
      formatted_address,
      location,
      place_id,
      label,
    };
    updateFieldData('address', address);
  };

  const updateContact = (field, fieldData) => {
    const contact = {
      ...(data.contact || {}),
      [field]: fieldData
    };

    updateFieldData('contact', contact);
  };

  const updateCreatives = (id) => {
    const existed = data.creatives.includes(id);
    if (existed) {
      const creativeIds = [...data.creatives].filter(i => i !== id);
      updateFieldData('creatives', creativeIds);
      return;
    }

    const creativeIds = [
      ...data.creatives,
      id
    ];

    updateFieldData('creatives', creativeIds);
  }

  const loadMoreCreativesHandler = () => {
    const totalPage = creativesData.pages;
    if (creativesPage >= totalPage) {
      return;
    }
    setCreativesPage(creativesPage + 1);
    dispatch(loadMoreCreatives({
      page: creativesPage,
      limit: '',
      status: 'active',
    }));
  };

  useEffect(() => {
    if (!creativesData || !creativesData.list || !creativesData.list.length) {
      setLoadCreatives(true);
      dispatch(getCreatives({
        page: 1,
        limit: '',
        status: 'active',
        tiers: '',
      }));
    } else {
      setLoadCreatives(false);
    }
  }, [creativesData, dispatch]);

  return (
    <Modal
      className='production-date-modal'
      title={detail && detail.startDate ? 'Shooting date #' + detail.order : 'Create new Shooting date'}
      visible={visible}
      onOk={() => onClose(data)}
      onCancel={() => onClose()}
    >
      <div className="calendar-container">
        <div className="start-date-container">
          Time:{' '}
          <DatePicker
            format='YYYY-MM-DD HH:mm'
            showTime={{ format: 'HH:mm' }}
            className="date-picker"
            showToday={false}
            allowClear={false}
            defaultValue={data.startDate ? moment(data.startDate) : null}
            onChange={(_date, dateString) => updateFieldData('startDate', new Date(dateString).toUTCString())}
          />
        </div>
        <div className="duration-container">
          Duration(hours):{' '}
          <InputNumber
            min={0}
            step={0.01}
            defaultValue={1}
            value={data.duration || null}
            onChange={(value) => updateFieldData('duration', value)}
          />
        </div>
      </div>

      <div className="address-section">
        <h6>Production Address</h6>
        <Form.Item label="Location" className="project-form-item">
          <div id="map" className="suggestion-wrapper">
            <Geosuggest
              placeholder={`Address`}
              initialValue={data.address ? data.address.label : "VidOps | Streamlined Media Creation, Acklam Road, London, UK"}
              inputClassName="ant-input suggestion-input"
              onSuggestSelect={(value) => updateAddress(value)}
            />
          </div>
        </Form.Item>
        <Form.Item label="Address Note" className="project-form-item">
          {getFieldDecorator('addressNote', {
            initialValue: data.addressNote || null
          })(
            <TextArea
              rows={4}
              onBlur={e => {
                updateFieldData('addressNote', e.target.value)
              }}
            />
          )}
        </Form.Item>
      </div>

      <div className="information-section">
        <h6>On-site Contact Information</h6>
        <Form.Item label="Name" className="project-form-item">
          {getFieldDecorator('fullName', {
            initialValue: data.contact.fullName || null
          })(
            <Input
              placeholder="Name"
              onBlur={e => {
                updateContact('fullName', e.target.value)
              }}
              onPressEnter={e =>
                updateContact('fullName', e.target.value)
              }
            />
          )}
        </Form.Item>
        <Form.Item label="Email" className="project-form-item">
          {getFieldDecorator('email', {
            initialValue: data.contact.email || null
          })(
            <Input
              placeholder="Email"
              onBlur={e => {
                updateContact('email', e.target.value)
              }}
              onPressEnter={e =>
                updateContact('email', e.target.value)
              }
            />
          )}
        </Form.Item>
        <Form.Item label="Phone" className="project-form-item">
          <ReactPhoneInput
            country={'gb'}
            value={data.contact.phoneNumber || null}
            onBlur={e => {
              updateContact('phoneNumber', e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item label="Note" className="project-form-item">
          {getFieldDecorator('contactNote', {
            initialValue: data.contact.note || null
          })(
            <TextArea
              rows={4}
              onBlur={e => {
                updateContact('note', e.target.value)
              }}
            />
          )}
        </Form.Item>
      </div>
      <div className="assignment-section">
        <h6>Creatives assignment (only active)</h6>
        <ScrollList
          ids={data.creatives}
          data={creativesData || <Empty />}
          loadMore={loadMoreCreativesHandler}
          isLoading={isLoadCreatives || isLoadingMore}
          type="creatives"
          onSelectItem={updateCreatives}
        />
      </div>
    </Modal>
  );
};

class ProductionDateModalWrapper extends React.Component {
  render() {
    return <ProductionDateModal {...this.props} />
  }
}

const ProductionDateForm = Form.create({ name: 'ProductionDateModal' })(ProductionDateModalWrapper);

export default ProductionDateForm;
