export const en = {
  msg: {
    required: 'This field is a mandatory field',
    character_string:
      'Contents for this textbox must have at least one Alphabetic character',
    special_characters: "Can't enter special characters",
    select_required: 'Please select a value for this field.',
    email: 'Please enter a valid email address',
    email_exists: 'This email already exists.',
    maxlength:
      'This field only allows max {{value}} characters, please revise accordingly.',
    minlength: 'This field can be at least {{value}} characters long',
    maxDigits: 'This field can be at most XXX numeric digits',
    minDigits: 'This field can be at least {{value}} numeric digits',
    password_format:
      'Password should be at least 6 characters long and include at least one number or symbol.',
    password_format_lenght: 'Be at least 6 characters long.',
    password_format_contain: 'Include at least one number or symbol.',
    password_not_match: 'Confirm password field does not match the password field.',
    old_pw_invalid: 'Old password do not match',
    birthday_invalid: 'Birthday is invalid',
    phone: 'Must be a valid 10 digits phone number',
    valid: 'Please enter a valid {{value}}.',
    promocode_minprice:
      'You need to spend minimum {{value}} on promo specific product to enjoy this discount.',
    promocode_not_apply: 'Promo code not applicable.',
    card_number_length:
      'You need to re-enter your 16 digit credit card number to save latest details',
    image_empty: 'Please upload a photo of your whisky bottle',
    image_invalid: 'Your image file is unsupported, please try again',
    image_limit: 'Please upload 3 or more (max 10) pictures of your whisky bottle',
    requiredFile: 'Please select a file to upload.',
    max_length: 'Maximum {{value}} characters',
  },
  field: {
    email: 'email address',
    phone: 'phone number',
    postcode: 'postcode',
  },
  btn: {
    continue: 'CONTINUE',
    back: 'BACK',
    save_changes: 'Save Changes',
    cancel: 'Cancel',
    cancel_auction: 'Cancel Auction',
    close: 'Close',
    save: 'Save',
    select: 'Select',
    revert: 'Undo Cancellation',
    update_plan: 'UPDATE PLAN',
    login: 'LOGIN',
    create_account: 'CREATE AN ACCOUNT',
    forgot_password: 'Forgot Password',
    logout: 'SIGN OUT',
    back_signin: 'BACK TO LOGIN',
    send: 'SEND',
    resend: 'RESEND',
    confirm: 'Confirm',
    next: 'Next',
    read_more: 'READ MORE',
    submit: 'SUBMIT',
    upload_photo: 'UPLOAD PHOTOS',
    add_another_bottle: 'Add another bottle',
    edit_submission: 'EDIT SUBMISSION',
    packing_guide: 'PACKING GUIDE',
    details: 'DETAILS',
    go_to_acc_dashboard: 'GO TO ACCOUNT DASHBOARD',
    save_draft: 'Save Draft',
    save_publish: 'Save & Publish',
    change_bank_details: 'Change bank details',
    update_bank_details: 'Update bank details',
    export_csv: 'Export CSV',
  },
  notifications: {
    no_data: "You don't have any notifications yet",
    read_all_notifications: 'Mark all my notifications as seen',
    move_link_and_file_to_asset:
      'A new asset has been uploaded to your project assets, check it out <1>here</1>',
    customer_book_project:
      'A new booking enquiry has been created <1>click here</1> to view the project',
    update_production_date:
      'Your production dates have been updated in <0> "{{projectTitle}}" </0> see project info',
    update_onsite_contact:
      'Your onsite contact info has been updated in <0> "{{projectTitle}}" </0> see project info',
    pre_project_to_pre_production:
      'Your project <0>"{{projectTitle}}"</0> has moved to Pre-Production!',
    pre_production_to_production:
      'Your project <0>"{{projectTitle}}"</0> has moved to Production-Ready!',
    upcoming_project: 'Your project <0>"{{projectTitle}}"</0> starts in 24 hours',
    production_day: `It's shoot day for your project <0>"{{projectTitle}}"</0>!`,
    creative_editor_production_to_post_production: `The status of project <0>"{{projectTitle}}"</0> has been updated <1>click here</1> to view`,
    customer_post_production_closed: `Your project <0>"{{projectTitle}}"</0> has been closed`,
    production_to_post_production: `And that's a wrap...your project <0>"{{projectTitle}}"</0> has moved to Post-Production!`,
    // 5020 - 5110
    creative_production_to_post_production: `The status of project <0>"{{projectTitle}}"</0> has been updated <1>click here</1> to view`,
    // 5090
    post_production_closed:
      'The status of project <0>"{{projectTitle}}"</0> has been updated <1>click here</1> to view',
    create_topic:
      'A new topic has been created by <0>{{userAffectedName}}</0> <1>click here</1> to view the topic',
    create_topic_owner: 'You have created a topic <1>click here</1> to view the topic',
    add_participant:
      'A new participant <0>{{userAffectedName}}</0> has been added to topic <0>{{topic}}</0> <1>click here</1> to view the topic',
    add_participant_owner:
      'You have been added to topic <0>{{topic}}</0> <1>click here</1> to view the topic',
    remove_participant:
      '<0>{{userAffectedName}}</0> has been removed from topic <0>{{topic}}</0>',
    remove_participant_owner: 'You has been removed from topic <0>{{topic}}</0>',
    change_title: 'The title of <0>{{topic}}</0> has been updated',
    change_status: '<0>{{topic}}</0> has been updated to <0>{{status}}</0>',
    send_message: '<1>Click here</1> to view new message(s) in topic <0>{{topic}}</0>',
    send_upload: '<1>Click here</1> to view new upload(s) in topic <0>{{topic}}</0>',
    project_invitation: 'You have been invited to a job <1>click here</1> to review',
    accept_project_invitation: 'You have accepted a new job!',
    admin_send_quote:
      'A new quote has been created for your project <0>"{{projectTitle}}"</0> <1>click here</1> to view the quote',
    admin_create_project:
      'A new project has been created called <0>"{{projectTitle}}"</0> <1>click here</1> to view',
    member_join_team: '<0>{{userAffectedName}}</0> has joined your team',
    member_join_team_owner: 'You has joined the team',
    change_admin_team: '<0>{{userAffectedName}}</0> is now team admin',
    change_admin_team_owner: 'You are now team admin',
    creative_vetting_approved: 'Welcome to our community!',
    request_to_join_team: 'You have a new team request from <0>{{email}}</0>',
    accept_request_to_join_team: `You've joined a team on VidOps`,
    reject_request_to_join_team: `Create a new team with VidOps`,
    create_new_project_from_customer: `A new project has been created from customer <0>{{projectCreator}}</0> <1>click here to view</1>`,
    create_new_project_from_admin:
      'A new project has been created by Vidops <1>click here to view</1>',
    create_new_project_from_customer_not_signed_in: `A new project has been created by a new online booking <1>click here to view</1>`,
    creative_sign_up_superadmin:
      'A new creative has signed up to the platform <1>click here to view</1>',
    customer_sign_up_superadmin:
      'A new customer has signed up to the platform <1>click here to view</1>',
    admin_assigned_project_owner: "You have been assigned to a project <1>click here to view</1> the project",
    admin_unassigned_project_owner: "You have been unassigned from a project <1>click here to view</1> the project"
  },
  errors: {},
  tooltips: {},
};
