import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Input, Button } from 'antd';
import { validatePassword } from 'src/core/utils/customValidator.js';
import TopSession from './topSession';

import './profileScreen.scss';

class ProfileScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Admin Units - VidOps',
      data: [],
      key: '',
      itemUpdate: null,
      isModalOpen: false,
      isCreate: false,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      isInvalid: false,
      fullName: '',
    };
  }
  componentDidMount() {
    this.props.onGetCurrentUser();
  }

  onHandleChange = e => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
      isInvalid: false,
    });
  };

  onChangePassword = () => {
    const { currentPassword, newPassword } = this.state;

    if (!currentPassword || this.checkNewPassword() || this.checkConfirmPassword()) {
      this.setState({
        isInvalid: true,
      });
    } else {
      this.setState(
        {
          isInvalid: false,
        },
        () => {
          this.props._updatePassword({ newPassword, oldPassword: currentPassword });
        }
      );
    }
  };

  checkNewPassword = () => {
    const { newPassword, currentPassword } = this.state;

    if (newPassword && newPassword.trim() === currentPassword.trim()) {
      return 'Your new password cannot be the same as your current password.';
    }
    return validatePassword(newPassword, 'new password');
  };

  checkConfirmPassword = () => {
    const { newPassword, confirmPassword } = this.state;
    if (!confirmPassword) {
      return 'Please input your confirm password';
    }
    if (newPassword.trim() !== confirmPassword.trim()) {
      return `Your passwords don't match, try again`;
    }
  };

  render() {
    const { userLoading, user } = this.props;
    const { newPassword, currentPassword, confirmPassword, isInvalid } = this.state;

    const { avt, fullName } = user || {};
    const checkPass = isInvalid && !currentPassword && 'Please input your password';
    const checkNewPass = isInvalid && this.checkNewPassword();
    const checkConfirmPass = isInvalid && this.checkConfirmPassword();

    return (
      <main role="main" className="page-content admin-profile">
        <Form layout="horizontal">
          <TopSession
            onChangeName={this.onChangeName}
            avt={avt}
            fullName={fullName}
            userLoading={userLoading}
            isInvalid={isInvalid}
          />
          <div className="user-change-password box mt-4">
            <Form.Item
              label="Current password"
              required
              validateStatus={checkPass ? 'error' : ''}
              help={checkPass}
            >
              <Input
                size="large"
                placeholder="Current password"
                type="password"
                name="currentPassword"
                value={currentPassword}
                onChange={this.onHandleChange}
              />
            </Form.Item>
            <Form.Item
              label="New password"
              required
              validateStatus={checkNewPass ? 'error' : ''}
              help={checkNewPass}
            >
              <Input
                size="large"
                placeholder="New password"
                type="password"
                name="newPassword"
                value={newPassword}
                onChange={this.onHandleChange}
              />
            </Form.Item>
            <Form.Item
              label="Confirm password"
              required
              validateStatus={checkConfirmPass ? 'error' : ''}
              help={checkConfirmPass}
            >
              <Input
                size="large"
                placeholder="Confirm password"
                type="password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={this.onHandleChange}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                size="large"
                className="ml-auto btn-primary-0"
                onClick={this.onChangePassword}
              >
                Update password
              </Button>
            </Form.Item>
          </div>
        </Form>
      </main>
    );
  }
}

export default withTranslation('common')(ProfileScreen);
