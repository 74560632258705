import React from "react";
import "./newModal.scss";
import { Modal } from "antd";

class NewModal extends React.Component {
  render() {
    const {
      dataSource,
      visible,
      title,
      onCloseModal,
      onOkModal,
      footer,
      className
    } = this.props;
    return (
      <Modal
        className={className}
        visible={visible}
        footer={footer}
        title={title}
        onCancel={onCloseModal}
        onOk={onOkModal}
        destroyOnClose={true}
      >
        {dataSource}
      </Modal>
    );
  }
}

export default NewModal;
