import {
  GET_USER_INFO_REQUEST,
  UPLOAD_AVATAR_REQUEST,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_USER_INFO_REQUEST,
} from '../typeAction';

export const getCurrentUser = () => ({
  type: GET_USER_INFO_REQUEST,
});

export const updateUserInfo = data => ({
  type: UPDATE_USER_INFO_REQUEST,
  payload: data,
});

export const uploadAvatar = data => ({
  type: UPLOAD_AVATAR_REQUEST,
  payload: data,
});
export const updatePassword = data => ({
  type: UPDATE_PASSWORD_REQUEST,
  payload: data,
});
