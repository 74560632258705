import { connect } from 'react-redux';
import HourRateScreen from '../../../components/screens/admin/hourRate/hourRateScreen';
import {
	getHourRate,
	createHourRate,
	updateHourRate,
	updateOrder
} from '../../../redux/actions/admin/hourRateAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';

const mapStateToProps = state => {
	return {
		success: state.hour_rate.success,
		error: state.hour_rate.error,
		isLoading: state.hour_rate.isLoading,
		data: state.hour_rate.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetHourRate: () => {
			dispatch(getHourRate());
		},
		onCreateHourRate: data => {
			dispatch(createHourRate(data));
		},
		onUpdateHourRate: ({ data, id }) => {
			dispatch(updateHourRate({ data, id }));
		},
		onUpdateOrder: data => {
			dispatch(updateOrder(data));
		},
		onGetCurrentUser: () => {
			dispatch(getCurrentUser());
		}
	};
};

const HourRateContainer = connect(mapStateToProps, mapDispatchToProps)(HourRateScreen);

export default HourRateContainer;
