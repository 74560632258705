import i18next from 'i18next';
import { en } from '../locales/en';
import { vi } from '../locales/vi';

if (!i18next.isInitialized) {
    i18next.init({
        interpolation: { escapeValue: false },
        lng: 'en',
        ns: ['common'],
        defaultNS: 'common',
        react: {
            useSuspense: false,
        },
        resources: {
            en: {
                common: en,
            },
            vi: {
                common: vi,
            },
        },
    });
}
