import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
// import sagas and reducer of home screen
import reducers from './reducers';
import rootSaga from './sagas/rootSaga';

import loggingMiddleware from './middlewares/logging';
import storage from 'redux-persist/lib/storage';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'notification',
    'register',
    'verification',
    'forgotPassword',
    'hour_rate',
    'privacy',
    'skills',
    'banner',
    'customerStories',
    'howitwork',
    'policies',
    'creatives',
    'serviceType',
    'budget',
    'login',
    'categories',
    'packages',
    'addhoc',
    'projects',
    'items',
    'addons',
    'ourTeam',
    'ourTeamMembers',
    'editors',
    'activities',
    'assets',
    'productTypes',
    'caseStudies',
    'subCategories',
    'units',
    'topics',
    'filesAndLinks',
    'faqs',
    'teams',
    'customers',
    'admins',
    'roles',
    'user'
  ]
};

const persistedReducer = persistReducer(persistConfig, reducers);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(persistedReducer, {}, composeEnhancers(
  applyMiddleware(sagaMiddleware, loggingMiddleware)
));

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { store, persistor };
