import {
	GET_HOUR_RATE_REQUEST,
	CREATE_HOUR_RATE_REQUEST,
	UPDATE_HOUR_RATE_REQUEST,
	UPDATE_ORDER_HOUR_RATE_SUCCESS
} from '../typeAction';

export const getHourRate = () => ({
	type: GET_HOUR_RATE_REQUEST
});

export const createHourRate = data => ({
	type: CREATE_HOUR_RATE_REQUEST,
	payload: data
});

export const updateHourRate = ({ data, id }) => ({
	type: UPDATE_HOUR_RATE_REQUEST,
	payload: { data, id }
});

export const updateOrder = data => ({
	type: UPDATE_ORDER_HOUR_RATE_SUCCESS,
	payload: data
});
