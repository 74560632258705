import { connect } from 'react-redux';
import PoliciesScreen from '../../../components/screens/admin/policies/policiesScreen';
import {
	getPolicies,
	createPolicies,
	updatePolicies
} from '../../../redux/actions/admin/policiesAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';

const mapStateToProps = state => {
	return {
		success: state.policies.success,
		error: state.policies.error,
		isLoading: state.policies.isLoading,
		data: state.policies.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetPolicies: type => {
			dispatch(getPolicies(type));
		},
		onCreatePolicies: data => {
			dispatch(createPolicies(data));
		},
		onUpdatePolicies: ({ data, id }) => {
			dispatch(updatePolicies({ data, id }));
		},
		onGetCurrentUser: () => {
			dispatch(getCurrentUser());
		}
	};
};

const PoliciesContainer = connect(mapStateToProps, mapDispatchToProps)(PoliciesScreen);

export default PoliciesContainer;
