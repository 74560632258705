import {
	GET_PRODUCT_TYPES_REQUEST,
	CREATE_PRODUCT_TYPES_REQUEST,
	UPDATE_PRODUCT_TYPES_REQUEST,
	UPDATE_ORDER_PRODUCT_TYPES_SUCCESS,
	GET_PRODUCT_TYPES_BY_ID_REQUEST
} from '../typeAction';

export const getProductTypes = (params) => ({
	type: GET_PRODUCT_TYPES_REQUEST,
	payload: params
});

export const createProductTypes = data => ({
	type: CREATE_PRODUCT_TYPES_REQUEST,
	payload: data
});

export const updateProductTypes = ({ data, id }) => ({
	type: UPDATE_PRODUCT_TYPES_REQUEST,
	payload: { data, id }
});

export const updateOrder = data => ({
	type: UPDATE_ORDER_PRODUCT_TYPES_SUCCESS,
	payload: data
});

export const getProductTypesById = id => ({
	type: GET_PRODUCT_TYPES_BY_ID_REQUEST,
	payload: id
});
