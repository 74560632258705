import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './404.scss';

class notFound extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        if (this.props.embedded) {
            return;
        }
        document.getElementById('sidebar-wrapper').style.display = 'none';
        document.getElementById('navbar-admin').style.display = 'none';
        document.getElementById('page-content-wrapper').style.background = '#ffffff';
    }

    componentWillUnmount() {
        if (this.props.embedded) {
            return;
        }
        document.getElementById('sidebar-wrapper').style.display = '';
        document.getElementById('navbar-admin').style.display = '';
        document.getElementById('page-content-wrapper').style.background = '#dddde0';
    }

    render() {
        return (
            <main role="main" className="error-content-wrapper">
                <div className="error-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className="error-text">
                                    <h1 className="error">404 Error</h1>
                                    <div className="im-sheep">
                                        <div className="top">
                                            <div className="body"></div>
                                            <div className="head">
                                                <div className="im-eye one"></div>
                                                <div className="im-eye two"></div>
                                                <div className="im-ear one"></div>
                                                <div className="im-ear two"></div>
                                            </div>
                                        </div>
                                        <div className="im-legs">
                                            <div className="im-leg"></div>
                                            <div className="im-leg"></div>
                                            <div className="im-leg"></div>
                                            <div className="im-leg"></div>
                                        </div>
                                    </div>
                                    <h4>Oops! This page Could Not Be Found!</h4>
                                    <p>Sorry bit the page you are looking for does not exist, have been removed or name changed.</p>
                                    {this.props.embedded ? null : <Link to="/" className="btn btn-round btn-primary-0">Go to homepage</Link>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

export default notFound;
