import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../../actions/typeAction';
import {
  sendEmailService,
  resetPasswordService,
} from '../../../core/services/modules/forgotPasswordService';

export function* sendEmailSaga(action) {
  const response = yield call(sendEmailService, action.payload);
  if (response.data.error) {
    yield put({
      type: actionTypes.SEND_EMAIL_FAILED,
      payload: response.data.error,
    });
  } else {
    yield put({
      type: actionTypes.SEND_EMAIL_SUCCESS,
      payload: response.data,
    });
  }
}

export function* resetPasswordSaga(action) {
  const response = yield call(resetPasswordService, action.payload);
  if (response.data.error) {
    yield put({
      type: actionTypes.RESET_PASSWORD_FAILED,
      payload: response.data.error,
    });
  } else {
    yield put({
      type: actionTypes.RESET_PASSWORD_SUCCESS,
      payload: response.data,
    });
  }
}

export function* resendEmailSaga(action) {
  const response = yield call(sendEmailService, action.payload);
  if (response.data.error) {
    yield put({
      type: actionTypes.RESEND_EMAIL_FAILED,
      payload: response.data.error,
    });
  } else {
    yield put({
      type: actionTypes.RESEND_EMAIL_SUCCESS,
      payload: response.data,
    });
  }
}

export function* watchForgotPassword() {
  yield takeLatest(actionTypes.SEND_EMAIL_REQUEST, sendEmailSaga);
  yield takeLatest(actionTypes.RESET_PASSWORD_REQUEST, resetPasswordSaga);
  yield takeLatest(actionTypes.RESEND_EMAIL_REQUEST, resendEmailSaga);
}
