import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';
import { extractFileExtension, getS3CDN } from 'src/core/utils/commonUtils';
import { S3_UPLOAD_TYPE } from '../../../core/constants/constants';

export const getUserInfo = () => {
  const url = BaseConfig.END_POINT.ADMIN.USER;
  const response = httpService._get(url);
  return response;
};

export const updateUserInfo = data => {
  const url = BaseConfig.END_POINT.ADMIN.USER;
  const response = httpService._put(url, data);
  return response;
};

export const deleteAccount = id => {
  const url = BaseConfig.END_POINT.ADMIN.USER + '/' + id;
  const response = httpService._put(url);
  return response;
};

export const updatePassword = data => {
  const url = BaseConfig.END_POINT.AUTH.UPDATE_PASSWORD;
  const response = httpService._put(url, data);
  return response;
};

export const uploadAvatar = async ({ user, file: fileAvatar }) => {
  const url = BaseConfig.END_POINT.AUTH.AVATAR;
  const photo = {
    filename: `${user._id}-${new Date().getTime()}${extractFileExtension({
      filename: fileAvatar.name,
    })}`,
    file: fileAvatar,
    path: 'cdn',
    type: S3_UPLOAD_TYPE.USER_AVATAR,
  };
  let [avatar] = await httpService._upload({ photos: [photo], typeUpload: 'avatar' });
  let response = await httpService._put(url, { avatar });
  Object.assign(response, { avatar: getS3CDN(avatar) });
  return response;
};

export const syncUserStatus = () => {
  const url = BaseConfig.END_POINT.ADMIN.SYNC_USER;
  const response = httpService._post(url);
  return response;
};
