import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import DashboardForm from '../../../common/form/dashboardForm/dashboardForm';
import './dashboardScreen.scss';

class AdminDashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: 'Admin Dashboard - VidOps'
		};
	}

	updateBannerHandler = updateData => {
		this.props.onUpdateBanner(updateData);
	};

	componentDidMount() {
		this.props.onGetCurrentUser();
		this.props.onGetBanner();
	}

	render() {
		const { error, isLoading, data, success } = this.props;
		return (
			<main role="main" className="page-content dashboard-screen">
				<DashboardForm
					onUpdateBanner={this.updateBannerHandler}
					data={data}
					error={error}
					isLoading={isLoading}
					success={success}
				/>
			</main>
		);
	}
}

export default withTranslation('common')(AdminDashboard);
