import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const getPoliciesService = payload => {
	const url = BaseConfig.END_POINT.ADMIN.POLICIES;
	const response = httpService._get(`${url}?type=${payload}`);
	return response;
};

export const createPoliciesService = data => {
	const url = BaseConfig.END_POINT.ADMIN.POLICIES;
	const response = httpService._post(url, data);
	return response;
};

export const updatePoliciesService = ({ data, id }) => {
	const url = BaseConfig.END_POINT.ADMIN.POLICIES;
	const response = httpService._put(`${url}/${id}`, data);
	return response;
};
