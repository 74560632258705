import React from 'react';
import './style.scss';
import {
  List,
  Avatar,
  Spin,
  Modal,
  Input,
  Button,
  notification,
  Icon,
  Tooltip,
  Card,
  Dropdown,
  Menu,
} from 'antd';
import moment from 'moment';

import InfiniteScroll from 'react-infinite-scroller';
import {
  getListFiles,
  setPublicAllFiles,
} from 'src/core/services/modules/googleDriveService';
import {
  getThumbnailLinkDrive,
  getIconLinkDrive,
  truncateText,
} from 'src/core/utils/commonUtils';
import IconImageThumbnail from 'src/assets/images/google-login/icons8-google-drive.svg';

const SORT = [
  {
    name: 'modifiedTime',
    value: 'Last modified',
  },
  {
    name: 'name',
    value: 'Name',
  },
  {
    name: 'modifiedByMeTime',
    value: 'Last modified by me',
  },
  {
    name: 'viewedByMeTime',
    value: 'Last opened by me',
  },
];
export default class ModalGoogleDrive extends React.Component {
  state = {
    data: [],
    loading: false,
    hasMore: true,
    nextPage: undefined,
    header: [
      {
        name: 'name',
        id: 'id',
        owner: 'ownner',
        last_modified: 'Last Modified',
      },
    ],
    isSelected: false,
    keyword: '',
    typeList: 'appstore',
    sort: SORT[0].name,
    sortName: SORT[0].value,
    sortHeader: SORT[0].value,
  };

  componentDidMount() {
    this.fetchData((files, nextPage) => {
      this.setState({
        data: files,
        nextPage,
      });
    });
  }

  fetchData = async callback => {
    const { nextPage, keyword, sort } = this.state;
    const { data: results } = await getListFiles({
      pageSize: 8,
      nextPage,
      keyword,
      orderBy: sort,
    });
    let { files, nextPageToken } = results.data;
    files = files.map(e => {
      return {
        ...e,
        selected: false,
      };
    });
    callback(files, nextPageToken);
  };

  fetchDataSearch = async callback => {
    const { value } = this.textInput.state;
    if (value === undefined) return;
    if ((!value || (value && !value.trim())) && value.trim() === this.state.keyword)
      return;
    this.scrollTop();
    setTimeout(() => {
      this.setState(
        {
          loading: true,
          data: [],
          keyword: value.trim(),
          isSelected: false,
          hasMore: true,
          nextPage: null,
        },
        () => {
          this.fetchData((files, nextPage) => {
            this.setState({
              data: files,
              loading: false,
              nextPage,
            });
          });
        }
      );
    }, 200);
  };

  handleInfiniteOnLoad = () => {
    let { data, nextPage } = this.state;
    this.setState({
      loading: true,
    });
    if (nextPage === undefined) {
      this.setState({
        hasMore: false,
        loading: false,
      });
      return;
    }
    this.fetchData((files, nextPage) => {
      data = data.concat(files);
      this.setState({
        data,
        loading: false,
        nextPage,
      });
    });
  };

  selectItem = id => {
    let { data } = this.state;
    const index = data.findIndex(e => e.id === id);
    data = data.map((e, i) => {
      if (index === i) {
        return {
          ...e,
          selected: !e.selected,
        };
      } else return e;
    });
    const isSelect = data.some(e => e.selected);
    this.setState({
      data,
      isSelected: isSelect,
    });
  };

  sendFileDrive = async () => {
    const { data } = this.state;
    const fileSelected = data.filter(e => e.selected);
    if (fileSelected.length > 5) {
      this.openNotification('error', 'Please choice maximum 5 files');
      return;
    }
    this.onClose();
    const files = fileSelected.map(e => e.id);
    const urls = fileSelected.map(e => {
      return { webViewLink: e.webViewLink, fileName: e.name };
    });
    // const result = await setPublicAllFiles({ files });
    await setPublicAllFiles({ files });
    urls.map(e => this.props.onSubmitMessage(e.webViewLink, 'drive', e.fileName));
  };

  openNotification = (type, message, content) => {
    notification[type]({
      message: message,
      description: content,
    });
  };

  onChangeTypeList = () => {
    let typeList = this.state.typeList;
    if (typeList === 'appstore') typeList = 'bars';
    else typeList = 'appstore';
    this.scrollTop();
    this.setState({ typeList });
  };

  onClose = () => {
    this.props.closeModalDrive();
  };

  renderTime = item => {
    let time = item.modifiedTime;
    switch (this.state.sort) {
      case 'modifiedByMeTime': {
        time = item.modifiedByMeTime || item.modifiedTime;
        break;
      }
      case 'viewedByMeTime': {
        time = item.viewedByMeTime || item.modifiedTime;
        break;
      }
      default:
        break;
    }
    return moment(time).format('MMM DD, YYYY');
  };

  renderListView = () => {
    const { data, loading, hasMore } = this.state;
    return (
      <List
        dataSource={data}
        renderItem={item => (
          <List.Item
            key={item.id}
            style={item.selected ? { background: 'aliceblue' } : {}}
            onClick={() => this.selectItem(item.id)}
          >
            <List.Item.Meta
              avatar={<Avatar src={getIconLinkDrive(item.iconLink, 32)} />}
              title={item.name}
            />
            <List.Item.Meta title={item.ownedByMe ? 'me' : item.owners[0].displayName} />
            <div>{this.renderTime(item)}</div>
          </List.Item>
        )}
      >
        {loading && hasMore && (
          <div className="loading-drive">
            <Spin />
          </div>
        )}
      </List>
    );
  };

  renderGridView = () => {
    const { data, loading, hasMore } = this.state;
    return (
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 4,
          xxl: 4,
        }}
        style={{ marginTop: 20 }}
        dataSource={data}
        renderItem={item => (
          <List.Item key={item.id} onClick={() => this.selectItem(item.id)}>
            <Card title={null} style={item.selected ? { background: 'aliceblue' } : {}}>
              {
                // item.hasThumbnail ?
                <img
                  onError={ev => {
                    ev.target.src = IconImageThumbnail;
                  }}
                  src={getThumbnailLinkDrive(item.id)}
                  style={{ width: '100%', height: 'auto', maxHeight: 150 }}
                  alt=''
                />
                // :
                // <img
                //   src={IconImageThumbnail}
                //   style={{ width: '100%', height: 'auto', maxHeight: 166 }}
                //   />
                // (<div style={{ width: '100%', height: 'auto', maxHeight: 150 }}><Icon type="file-image" className="d-block" style={{ fontSize: 150 }}/></div>)
              }
              <div style={{ padding: 12 }}>
                <img
                  src={getIconLinkDrive(item.iconLink, 32)}
                  className="mr-3"
                  style={{ zoom: '0.5' }}
                  alt=''
                />
                {truncateText(item.name)}
              </div>
            </Card>
          </List.Item>
        )}
      >
        {loading && hasMore && (
          <div className="loading-drive">
            <Spin />
          </div>
        )}
      </List>
    );
  };

  orderBy = (key, header) => {
    if (header && this.state.sort !== key && this.state.sort !== 'name') return;
    if (this.state.sort === key) return;
    const sortName = SORT.filter(e => e.name === key);
    this.scrollTop();
    if (key !== 'name') {
      this.setState({
        sortHeader: sortName[0].value,
      });
    }
    setTimeout(() => {
      this.setState(
        {
          loading: true,
          data: [],
          isSelected: false,
          hasMore: true,
          nextPage: null,
          sort: key,
          sortName: sortName[0].value,
        },
        () => {
          this.fetchData((files, nextPage) => {
            this.setState({
              data: files,
              loading: false,
              nextPage,
            });
          });
        }
      );
    }, 200);
  };

  scrollTop = () => {
    const tableContent = document.querySelector('.infinite-drive-body');
    tableContent.scrollTop = 0;
  };

  renderOrderBy = () => {
    const { typeList, sort, sortHeader } = this.state;
    const checkLastModified = sort !== SORT[1].name && typeList === 'appstore';
    let header = sortHeader;
    if (sort === 'name') header = 'Last modified';
    return (
      <div className="d-flex">
        <h4
          style={checkLastModified ? { fontWeight: 600, fontSize: 14 } : { fontSize: 14 }}
          onClick={() => this.orderBy('modifiedTime', true)}
        >
          {typeList === 'appstore' ? header : ''}
        </h4>
        {checkLastModified && (
          <Icon style={{ marginLeft: 5, fontSize: 15 }} type="arrow-down" />
        )}
      </div>
    );
  };

  menu = () => (
    <Menu>
      {SORT.map(e => {
        return (
          <Menu.Item
            className="p-2 d-flex align-items-center"
            onClick={() => this.orderBy(e.name)}
          >
            <Icon
              type="check"
              style={{
                marginTop: -7,
                marginRight: 15,
                visibility: this.state.sort === e.name ? 'visible' : 'hidden',
              }}
            />
            <h4
              className="ant-list-item-meta-title"
              style={{ fontWeight: this.state.sort === e.name ? 600 : 500 }}
            >
              {e.value}
            </h4>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  render() {
    const { visible } = this.props;
    const { typeList, loading, hasMore, isSelected, sort } = this.state;
    const name = typeList === 'appstore' ? 'Name' : 'Files';
    const owner = typeList === 'appstore' ? 'Owner' : '';
    return (
      <Modal
        className="modal-google-drive"
        visible={visible}
        footer={null}
        title={'Select a file'}
        width={1052}
        onCancel={() => this.onClose()}
        destroyOnClose={true}
        closable={true}
        maskClosable={false}
      >
        <div className="d-flex align-items-center search-input">
          <Input
            onPressEnter={() => this.fetchDataSearch()}
            ref={ref => (this.textInput = ref)}
            placeholder="Search file name"
          />
          <Button icon="search" onClick={() => this.fetchDataSearch()}></Button>
          <div className="d-flex" style={{ marginLeft: 'auto' }}>
            <div className="view-type">
              <Tooltip
                placement="top"
                title={typeList === 'appstore' ? 'Grid view' : 'List view'}
              >
                <Icon type={typeList} onClick={() => this.onChangeTypeList()} />
              </Tooltip>
            </div>
            <div className="view-type">
              <Tooltip placement="top" title="Sort options">
                <Dropdown overlay={this.menu} placement="bottomCenter">
                  <Icon type="sort-ascending" />
                </Dropdown>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="infinite-drive-header">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <h4
                style={
                  sort === 'name' ? { fontWeight: 600, fontSize: 14 } : { fontSize: 14 }
                }
                onClick={() => this.orderBy('name')}
              >
                {name}
              </h4>
              {sort === 'name' && name === 'Name' && (
                <Icon style={{ marginLeft: 5, fontSize: 15 }} type="arrow-down" />
              )}
            </div>
            <h4 className="owner">{owner}</h4>
            {this.renderOrderBy()}
          </div>
        </div>
        <div className="infinite-drive-body">
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={this.handleInfiniteOnLoad}
            hasMore={!loading && hasMore}
            useWindow={false}
          >
            {typeList === 'appstore' ? this.renderListView() : this.renderGridView()}
          </InfiniteScroll>
        </div>
        <div className="table-drive-footer">
          <Button
            className="selected"
            style={!isSelected ? { opacity: '0.5' } : {}}
            disabled={!isSelected}
            onClick={() => this.sendFileDrive()}
          >
            Select
          </Button>
          <Button className="cancel" onClick={() => this.onClose()}>
            Cancel
          </Button>
        </div>
      </Modal>
    );
  }
}
