import React, { PureComponent } from 'react';
import 'antd/dist/antd.css';
import './activityForm.scss';
import { Form, List, Button, Icon } from 'antd';
import { ACTIVITY_FIELDS } from '../../../../../core/constants/constants';
import moment from 'moment';
import UserAvatar from 'src/components/common/userAvatar/index.js';

class ActivityForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderDate = data => {
    return (
      <>
        StartDate: {moment.unix(JSON.parse(data).startDate).format('YYYY/MM/DD HH:mm')}{' '}
        - Duration: {JSON.parse(data).duration} hour(s)
      </>
    );
  };

  renderChanged = item => {
    let { oldData, newData } = item;
    if (item.action === 'added') {
      if (['creative', 'editor', 'admin'].includes(item.field)) {
        return JSON.parse(newData).fullName;
      }
      if (item.field === 'productionDates') {
        return this.renderDate(newData);
      }
      return newData;
    }
    if (item.action === 'removed') {
      if (['creative', 'editor', 'admin'].includes(item.field)) {
        return JSON.parse(oldData).fullName;
      }
      if (item.field === 'productionDates') {
        return this.renderDate(oldData);
      }
      return oldData;
    }
    if (item.action === 'changed') {
      if (item.field === 'isUrgent') {
        oldData = oldData === 'true' ? 'HIGH' : 'NORMAL';
        newData = newData === 'true' ? 'HIGH' : 'NORMAL';
      }
      if (item.field === 'productionDates') {
        oldData = this.renderDate(oldData);
        newData = this.renderDate(newData);
      }
      if (item.field === 'isPublic') {
        oldData = oldData === 'true' ? 'Public' : 'Private';
        newData = newData === 'true' ? 'Public' : 'Private';
      }
    }
    return (
      <div>
        <span>{oldData}</span>
        <Icon className="arrow-change" type="arrow-right" />
        <span className="new-data">{newData}</span>
      </div>
    );
  };

  renderFieldText = item => {
    if (item.field === 'isPublic') {
      return item.fileName;
    }
    const fieldIndex = ACTIVITY_FIELDS.findIndex(el => el.field === item.field);
    return fieldIndex > -1 ? ACTIVITY_FIELDS[fieldIndex].text : item.field;
  };

  renderFields = item => {
    const { avt, fullName } = (item && item.createdBy) || {};
    return (
      <div className="activities-container">
        <div className="mr-2">
          <UserAvatar key={item._id} fullName={fullName} avatar={avt} status={true} />
        </div>
        <div className="activities-content">
          <div className="content-title">
            <div>
              <span className="activities-title">{item.createdBy.fullName}</span>{' '}
              {item.action}{' '}
              <span className="activities-title">{this.renderFieldText(item)}</span>
            </div>
            <div className="created-date">
              {moment(item.createdAt).format('YYYY/MM/DD HH:mm')}
            </div>
          </div>

          {this.renderChanged(item)}
        </div>
      </div>
    );
  };

  activitiesRender = () => {
    const { activities, activitiesPage, isActivitiesLoading } = this.props;
    const totalPage = activities && activities.pages ? activities.pages : 1;
    const docs = activities && activities.docs ? activities.docs : [];
    return (
      <>
        <List dataSource={docs} renderItem={item => this.renderFields(item)}></List>
        {activitiesPage < totalPage ? (
          <Button
            className="btn-load-more btn-primary-0"
            type="primary"
            loading={isActivitiesLoading}
            onClick={this.props.onloadMoreClick}
          >
            Load more
          </Button>
        ) : null}
      </>
    );
  };

  render() {
    return this.activitiesRender();
  }
}

const WrappedActivityForm = Form.create({ name: 'normal_activity' })(ActivityForm);

export default WrappedActivityForm;
