import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import './topicsForm.scss';
import { Form, Input, Row, Col } from 'antd';
import SendFileIcon from '../../../../../../assets/images/icons/send-file.svg';
import SendImgIcon from '../../../../../../assets/images/icons/send-img.svg';
import SendIcon from '../../../../../../assets/images/icons/send.svg';
// import socketTopicNameSpace from '../../../../../../core/sockets/topic';
import { storageService } from '../../../../../../core/services/storageService';
import { createTopic } from '../../../../../../redux/actions/admin/topicsAction';
import GreetingPlaceholder from 'src/components/common/form/workspace/topicsScreen/greetingPlaceholder';
import { IMAGES_TYPE, FILES_TYPE } from 'src/core/constants/constants.js';
// const socketTopic = socketTopicNameSpace();

class TopicsForm extends PureComponent {
  createTopicHandler = e => {
    const { currentProject } = this.props;
    const body = {
      projectId: currentProject._id,
      title: e.target.value,
      participants: [],
    };
    this.props.onCreateTopic(body);
  };

  isParticipants = topic => {
    const currentUser = storageService.getCurrentUser();
    const participants =
      topic.participants && topic.participants.length ? topic.participants : [];
    if (currentUser && currentUser.role === 'admin') {
      return true;
    }
    if (currentUser && currentUser.role !== 'admin') {
      const participantsIndex = participants.findIndex(
        item =>
          (item.creativeId && item.creativeId.userId === currentUser.userId) ||
          (item.customerId && item.customerId.userId === currentUser.userId) ||
          (item.editorId && item.editorId.userId === currentUser.userId)
      );
      return participantsIndex > -1;
    }
    return false;
  };

  componentDidMount() {
    this.props.onToggleActionsContainer(false);
    // socketTopic.updateTopicSuccess(topicUpdated => {
    // 	this.setState(
    // 		{
    // 			currentTopic: topicUpdated
    // 		},
    // 		() => {
    // 			if (!this.isParticipants(topicUpdated)) {
    // 				socketTopic.unregisterEvent();
    // 				// Log for A.Quy testing
    // 				openNotification(
    // 					'error',
    // 					`You already has been removed of this topic ${topicUpdated._id}!`
    // 				);
    // 				this.props.onClickBackBtn();
    // 			}
    // 		}
    // 	);
    // });
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentTopic && !prevProps.currentTopic) {
      // Join topic and listen topic after update successfully
      // socketTopic.joinTopic(this.props.currentTopic._id);
      const editor = document.getElementsByClassName('conversation-editor')[0];
      editor.scrollIntoView();
    }
    if (
      this.props.currentTopic &&
      prevProps.currentTopic &&
      this.props.currentTopic !== prevProps.currentTopic
    ) {
      // this.setState({
      // 	currentTopic: this.props.currentTopic
      // });
      // Emit topic when got update response
      // socketTopic.updateTopic(this.props.currentTopic);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form className="topics-form">
        <Row>
          <Col span={17}>
            <div className="conversation-title">
              <Form.Item>
                {getFieldDecorator('title', {
                  initialValue: null,
                })(
                  <Input
                    className="topics-title"
                    onPressEnter={e => {
                      if (e.target.value) {
                        this.createTopicHandler(e);
                      }
                    }}
                    onBlur={e => {
                      if (e.target.value) {
                        this.createTopicHandler(e);
                      }
                    }}
                    placeholder="Title"
                    size="large"
                    autoFocus
                  />
                )}
              </Form.Item>
            </div>
            <GreetingPlaceholder />
            <div className="conversation-editor">
              <div className="conversation-compose">
                <input
                  className="input-msg"
                  placeholder="Leave a comment..."
                  disabled={true}
                />
                <div className="photo">
                  <div className="upload-btn-wrapper">
                    <img src={SendFileIcon} alt="" width="20" height="20" />
                    <input
                      className="input-upload-invisible add-cursor"
                      type="file"
                      disabled={true}
                      accept={FILES_TYPE.join(',')}
                    />
                  </div>
                  <div className="upload-btn-wrapper">
                    <img src={SendImgIcon} alt="" width="25" height="25" />
                    <input
                      className="input-upload-invisible add-cursor"
                      type="file"
                      accept={IMAGES_TYPE.join(',')}
                      disabled={true}
                    />
                  </div>
                </div>
                <button type="button" className="send" disabled={true}>
                  <div className="circle">
                    <img src={SendIcon} alt="" />
                  </div>
                </button>
              </div>
            </div>
          </Col>
          <Col span={1}></Col>
          <Col span={6}>
            <Form.Item>
              <div className="lh-20 setting-title">Creator</div>
            </Form.Item>
            <Form.Item>
              <div className="lh-20 setting-title">Participants</div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedTopicsForm = Form.create({ name: 'normal_topics' })(TopicsForm);

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onCreateTopic: body => {
      dispatch(createTopic(body));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WrappedTopicsForm);
