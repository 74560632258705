import {
	GET_PACKAGE_REQUEST,
	GET_PACKAGE_SUCCESS,
	GET_PACKAGE_FAILED,
	CREATE_PACKAGE_REQUEST,
	CREATE_PACKAGE_SUCCESS,
	CREATE_PACKAGE_FAILED,
	UPDATE_PACKAGE_REQUEST,
	UPDATE_PACKAGE_SUCCESS,
	UPDATE_PACKAGE_FAILED,
	UPDATE_ORDER_PACKAGE_SUCCESS,
	UPDATE_ORDER_PACKAGE_FAILED,
	GET_PACKAGE_BY_PARAMS_REQUEST,
	GET_PACKAGE_BY_PARAMS_SUCCESS,
	GET_PACKAGE_BY_PARAMS_FAILED,
	GET_PACKAGE_BY_ID_REQUEST,
	GET_PACKAGE_BY_ID_SUCCESS,
	GET_PACKAGE_BY_ID_FAILED
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';
import { updateHelper } from '../../../core/utils/commonUtils';

const defaultState = {
	success: false,
	error: null,
	data: [],
	isLoading: false,
	detailData: null
};

const packageReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_PACKAGE_REQUEST: {
			return {
				...state
			};
		}
		case GET_PACKAGE_SUCCESS: {
			return {
				...state,
				...action.payload
			};
		}
		case GET_PACKAGE_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				error: action.payload
			};
		}
		case GET_PACKAGE_BY_PARAMS_REQUEST: {
			return {
				...state
			};
		}
		case GET_PACKAGE_BY_PARAMS_SUCCESS: {
			return {
				...state,
				...action.payload
			};
		}
		case GET_PACKAGE_BY_PARAMS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				error: action.payload
			};
		}
		case GET_PACKAGE_BY_ID_REQUEST: {
			return {
				...state
			};
		}
		case GET_PACKAGE_BY_ID_SUCCESS: {
			return {
				...state,
				detailData: action.payload.data
			};
		}
		case GET_PACKAGE_BY_ID_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				error: action.payload
			};
		}
		case CREATE_PACKAGE_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case CREATE_PACKAGE_SUCCESS: {
			openNotification('success', 'Create successfully');
			return {
				...state,
				isLoading: false,
				data: [...state.data, action.payload.data]
			};
		}
		case CREATE_PACKAGE_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_PACKAGE_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case UPDATE_PACKAGE_SUCCESS: {
			openNotification('success', 'Update successful');
			return {
				...state,
				isLoading: false,
				data: updateHelper(state, action)
			};
		}
		case UPDATE_PACKAGE_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_ORDER_PACKAGE_SUCCESS: {
			return {
				...state
			};
		}
		case UPDATE_ORDER_PACKAGE_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		default:
			return state;
	}
};

export default packageReducer;
