import { connect } from 'react-redux';
import CaseStudiesScreen from '../../../components/screens/admin/caseStudies/caseStudiesScreen';
import {
    getCaseStudies,
    createCaseStudies,
    updateCaseStudies,
    updateOrder,
} from '../../../redux/actions/admin/caseStudiesAction';
import { getCustomerStories } from '../../../redux/actions/admin/customerStoriesAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';
import { getProductTypes } from '../../../redux/actions/admin/productTypesAction';
import { getServiceType } from '../../../redux/actions/admin/serviceTypeAction';

const mapStateToProps = (state) => {
    return {
        success: state.caseStudies.success,
        error: state.caseStudies.error,
        isLoading: state.caseStudies.isLoading,
        data: state.caseStudies.data,
        customerStories: state.customerStories.data,
        productType: state.productType.data,
        serviceType: state.serviceType.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetCaseStudies: () => {
            dispatch(getCaseStudies());
        },
        onCreateCaseStudies: (data) => {
            dispatch(createCaseStudies(data));
        },
        onUpdateCaseStudies: ({ data, id }) => {
            dispatch(updateCaseStudies({ data, id }));
        },
        onGetCustomerStories: () => {
            dispatch(getCustomerStories());
        },
        onUpdateOrder: (data) => {
            dispatch(updateOrder(data));
        },
        onGetCurrentUser: () => {
            dispatch(getCurrentUser());
        },
        onGetProductTypes: () => {
            dispatch(getProductTypes());
        },
        onGetServiceType: (id) => {
            dispatch(getServiceType(id));
        },
    };
};

const CaseStudiesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CaseStudiesScreen);

export default CaseStudiesContainer;
