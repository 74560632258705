// eslint-disable-next-line
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Navbar, Nav, Button, Container, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BadgeRB from 'react-bootstrap/Badge';
import { Dropdown, Menu } from 'antd';
import { withRouter } from 'react-router-dom';
import './header.scss';
import { storageService } from '../../../core/services/storageService';
import socket from '../../../core/sockets';
import {
  countNotification,
  getNotification,
  readNotification,
} from '../../../redux/actions/admin/notificationAction';
import { NOTIFICATION_CONTENT_TYPE } from '../../../core/constants/constants';
import { sendLogoutAction } from 'src/core/utils/event_emitter/handler';
import { logoutService } from 'src/core/services/modules/authService';
import HeaderAuthChecker from 'src/components/layouts/header/headerAuthChecker';
import NotificationDropdown from 'src/components/common/notificationDropdown';
import UserAvatar from 'src/components/common/userAvatar/index.js';

class HeaderAdmin extends Component {
  constructor(props) {
    super(props);
    this.socketIO = null;
    this.state = {
      scrolled: false,
      isShowCollapse: false,
      count: 0,
      listNotification: [],
      type: 'list',
      pageNotif: 1,
      limitNotif: 10,
      userNotified: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { notification } = nextProps;
    const { type } = this.state;

    if (notification.dataSuccess && notification.isSuccess && type === 'list') {
      this.setState({
        count: notification.dataSuccess.total,
        listNotification: notification.dataSuccess.docs,
      });
    } else if (notification.dataSuccess && notification.isSuccess && type === 'read') {
      this.props.onGetNotification();
      this.setState({
        type: 'list',
      });
    }
  }

  componentWillUnmount() {
    if (this.socketIO) {
      this.socketIO.unregisterEvent();
    }
  }

  onInitSocket = () => {
    const userLogin = storageService.getCurrentUser();

    this.socketIO = socket();
    this.socketIO.onConnected(() => {
      this.socketIO.onInitUserLogin(userLogin.userId);
      this.socketIO.createNewTopic(() => {
        this.props.onGetNotification();
      });
      this.socketIO.onSendNotification(data => {
        this.props.onGetNotification();
      });
      this.socketIO.UpdateTopic(data => {
        this.setState(
          {
            userNotified: data.user,
          },
          () => {
            this.props.onGetNotification();
          }
        );
      });
    });
    this.props.onGetNotification();
    this.props.onCountNotification();
  };

  toggleMenu = e => {
    e.preventDefault();
    var element = document.getElementById('admin-wrapper');
    element.classList.toggle('toggled');
  };

  logout = async () => {
    sendLogoutAction();
    if (this.socketIO) {
      this.socketIO.disconnect();
    }
    try {
      await logoutService();
    } catch (e) {}
  };

  _readNotification(id) {
    if (id) {
      this.setState(
        {
          type: 'read',
        },
        () => {
          this.props.onReadNotification(id);
        }
      );
    }
  }

  checkContentNotification = data => {
    let content;
    const { userNotified } = this.state;
    const { contentType, senderId, projectId, unRead, referenceId, topicId } = data;
    const getLast4Words =
      !!projectId && !!projectId._id && projectId._id.substr(projectId.length - 4);
    switch (contentType) {
      case NOTIFICATION_CONTENT_TYPE.TOPIC:
        content = `A new topic has been created by ${senderId.fullName} in project ${getLast4Words}`;
        break;
      case NOTIFICATION_CONTENT_TYPE.REMOVE_PARTICIPANT:
        content = `${userNotified && userNotified.fullName} has been removed from topic ${
          topicId.title
        }`;
        break;
      case NOTIFICATION_CONTENT_TYPE.ADD_PARTICIPANT:
        content = `A new participant ${
          userNotified && userNotified.fullName
        } has been added to topic ${topicId.title}`;
        break;
      case NOTIFICATION_CONTENT_TYPE.CHANGE_PROJECT_STATUS:
        content = `The status of ${topicId.title} has been updated`;
        break;
      case NOTIFICATION_CONTENT_TYPE.CHANGE_PROJECT_TITLE:
        content = `The title of ${topicId.title} has been updated`;
        break;
      case NOTIFICATION_CONTENT_TYPE.UPDATED_PRODUCTION_DATE:
        content = `Your production dates have been updated in ${projectId} see project info`;
        break;
      case NOTIFICATION_CONTENT_TYPE.UPDATED_ONSITE_CONTACT:
        content = `Your onsite contact info has been updated in ${projectId} see project info`;
        break;
      case NOTIFICATION_CONTENT_TYPE.PRE_PROJECTS_TO_PRE_PRODUCTION:
        content = `Your project ${projectId} has moved to Pre-Production!`;
        break;
      case NOTIFICATION_CONTENT_TYPE.PRE_PRODUCTION_TO_PRODUCTION:
        content = `Your project ${projectId} is Production-Ready!`;
        break;
      case NOTIFICATION_CONTENT_TYPE.PRODUCTION_TO_POST_PRODUCTION:
        content = `And that's a wrap... ${projectId} has moved to Post-Production!`;
        break;
      case NOTIFICATION_CONTENT_TYPE.POST_PRODUCTION_TO_CLOSE:
        content = `${projectId} has been closed, come back soon!`;
        break;
      default:
        content = `You have (${unRead}) new message(s) in Topic: ${
          referenceId && referenceId.title
        }`;
        break;
    }

    let view = (
      <div>
        <span className="msg-notif-content">{content}</span>
        <BadgeRB variant="danger">{unRead}</BadgeRB>
      </div>
    );

    return view;
  };

  render() {
    const user = storageService.getCurrentUser();
    const { avt, fullName } = user || {};

    const menu = (
      <Menu className="p-0">
        <Menu.Item>
          <Link to="/setting">
            <span>Settings</span>
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/profile">
            <span>Profile</span>
          </Link>
        </Menu.Item>
        <Menu.Divider className="m-0" />
        <Menu.Item>
          <Link to="#">
            <span onClick={this.logout}>Logout</span>
          </Link>
        </Menu.Item>
      </Menu>
    );

    return (
      <>
        {!!user && <HeaderAuthChecker onInitialSocket={this.onInitSocket} />}
        <nav
          className="navbar navbar-expand-lg navbar-light bg-light border-bottom"
          id="navbar-admin"
        >
          <button className="navbar-toggler-2" id="menu-toggle" onClick={this.toggleMenu}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="notification-head">
            <NotificationDropdown />
          </div>
          <div className="admin-user-info">
            <UserAvatar avatar={avt} fullName={fullName} status={true} />

            {storageService.isLoggedIn() && (
              <Dropdown overlay={menu}>
                <span className="ant-dropdown-link nav-link dropdown-toggle admin-username">
                  {fullName}
                </span>
              </Dropdown>
            )}
          </div>
        </nav>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    notification: state.notification,
    user: state.login,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetNotification: () => {
      dispatch(getNotification());
    },
    onCountNotification: () => {
      dispatch(countNotification());
    },
    onReadNotification: id => {
      dispatch(readNotification(id));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderAdmin));
