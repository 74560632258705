import {
	GET_ADMINS_REQUEST,
	UPDATE_ADMINS_REQUEST,
	REGISTER_ADMIN_REQUEST,
	LOAD_MORE_ADMINS_REQUEST,
	GET_ADMINS_DETAIL_REQUEST
} from '../typeAction';

export const getAdmins = data => ({
	type: GET_ADMINS_REQUEST,
	payload: data
});

export const loadMoreAdmins = data => ({
	type: LOAD_MORE_ADMINS_REQUEST,
	payload: data
});

export const getAdminDetail = id => ({
	type: GET_ADMINS_DETAIL_REQUEST,
	payload: id
});

export const updateAdmin = data => ({
	type: UPDATE_ADMINS_REQUEST,
	payload: data
});

export const registerAdmin = data => ({
	type: REGISTER_ADMIN_REQUEST,
	payload: data
});
