import { put, call, takeLatest } from 'redux-saga/effects';

import {
	GET_CREATIVES_FAILED,
	GET_CREATIVES_SUCCESS,
	GET_CREATIVES_DETAIL_FAILED,
	GET_CREATIVES_DETAIL_SUCCESS,
	SEARCH_CREATIVES_FAILED,
	SEARCH_CREATIVES_SUCCESS,
	GET_CREATIVES_REQUEST,
	GET_CREATIVES_DETAIL_REQUEST,
	SEARCH_CREATIVES_REQUEST,
	LOAD_MORE_CREATIVES_FAILED,
	LOAD_MORE_CREATIVES_SUCCESS,
	LOAD_MORE_CREATIVES_REQUEST,
	SEND_INVITATION_CREATIVES_FAILED,
	SEND_INVITATION_CREATIVES_REQUEST,
	SEND_INVITATION_CREATIVES_SUCCESS,
	UPDATE_CREATIVES_SUCCESS,
	UPDATE_CREATIVES_FAILED,
	UPDATE_CREATIVES_REQUEST,
	RESEND_INVITATION_CREATIVES_FAILED,
	RESEND_INVITATION_CREATIVES_SUCCESS,
	RESEND_INVITATION_CREATIVES_REQUEST
} from '../../actions/typeAction';
import {
	getCreativesService,
	getCreativesDetailService,
	searchCreativesService,
	updateCreativesService,
	sendInvitationService,
	resendInvitationService
} from '../../../core/services/modules/creativesService';

export function* getCreativesSaga(action) {
	const response = yield call(getCreativesService, action.payload);
	if (response.data.error) {
		yield put({
			type: GET_CREATIVES_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: GET_CREATIVES_SUCCESS,
			payload: data
		});
	}
}

export function* loadMoreCreativesSaga(action) {
	const response = yield call(getCreativesService, action.payload);
	if (response.data.error) {
		yield put({
			type: LOAD_MORE_CREATIVES_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: LOAD_MORE_CREATIVES_SUCCESS,
			payload: data
		});
	}
}

export function* getCreativesDetailSaga(action) {
	const response = yield call(getCreativesDetailService, action.payload);
	if (response.data.error || !response.data.data) {
		yield put({
			type: GET_CREATIVES_DETAIL_FAILED,
			payload: response.data.error || {}
		});
	} else {
		const data = response.data;
		yield put({
			type: GET_CREATIVES_DETAIL_SUCCESS,
			payload: data
		});
	}
}

export function* searchCreativesSaga(action) {
	const response = yield call(searchCreativesService, action.payload);
	if (response.data.error) {
		yield put({
			type: SEARCH_CREATIVES_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: SEARCH_CREATIVES_SUCCESS,
			payload: data
		});
	}
}

export function* updateCreativesSaga(action) {
	const response = yield call(updateCreativesService, action.payload);
	if (response.data.error) {
		yield put({
			type: UPDATE_CREATIVES_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: UPDATE_CREATIVES_SUCCESS,
			payload: data
		});
	}
}

export function* sendInvitationSaga(action) {
	const response = yield call(sendInvitationService, action.payload);
	if (response.data.error) {
		yield put({
			type: SEND_INVITATION_CREATIVES_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: SEND_INVITATION_CREATIVES_SUCCESS,
			payload: data
		});
	}
}

export function* resendInvitationSaga(action) {
	const response = yield call(resendInvitationService, action.payload);
	if (response.data.error) {
		yield put({
			type: RESEND_INVITATION_CREATIVES_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: RESEND_INVITATION_CREATIVES_SUCCESS,
			payload: data
		});
	}
}

export function* watchCreatives() {
	yield takeLatest(GET_CREATIVES_REQUEST, getCreativesSaga);
	yield takeLatest(LOAD_MORE_CREATIVES_REQUEST, loadMoreCreativesSaga);
	yield takeLatest(GET_CREATIVES_DETAIL_REQUEST, getCreativesDetailSaga);
	yield takeLatest(SEARCH_CREATIVES_REQUEST, searchCreativesSaga);
	yield takeLatest(UPDATE_CREATIVES_REQUEST, updateCreativesSaga);
	yield takeLatest(SEND_INVITATION_CREATIVES_REQUEST, sendInvitationSaga);
	yield takeLatest(RESEND_INVITATION_CREATIVES_REQUEST, resendInvitationSaga);
}
