import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const oauthGoogleCalendar = () => {
  return BaseConfig.END_POINT.OAUTH.GOOGLE_CALENDAR;
};

export const verifyTokenCalendar = () => {
  const url = BaseConfig.END_POINT.OAUTH.VERIFY_TOKEN_CALENDAR;
  const response = httpService._get(url);
  return response;
};

export const logoutCalendar = () => {
  const url = BaseConfig.END_POINT.OAUTH.LOGOUT_CALENDAR;
  const response = httpService._post(url);
  return response;
};
