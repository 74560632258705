import { put, call, takeLatest } from 'redux-saga/effects';
import { sendGetNumberProjectAction } from 'src/core/utils/event_emitter/handler';
import {
  GET_PROJECTS_FAILED,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_DETAIL_FAILED,
  GET_PROJECTS_DETAIL_SUCCESS,
  SEARCH_PROJECTS_FAILED,
  SEARCH_PROJECTS_SUCCESS,
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_DETAIL_REQUEST,
  SEARCH_PROJECTS_REQUEST,
  UPDATE_STATUS_PROJECTS_FAILED,
  UPDATE_STATUS_PROJECTS_SUCCESS,
  UPDATE_STATUS_PROJECTS_REQUEST,
  UPDATE_PROJECTS_REQUEST,
  UPDATE_PROJECTS_FAILED,
  UPDATE_PROJECTS_SUCCESS,
  ADMIN_CREATE_PROJECT_FAILED,
  ADMIN_CREATE_PROJECT_SUCCESS,
  ADMIN_CREATE_PROJECT_REQUEST,
  GET_NUMBER_OF_PROJECTS_FAILED,
  GET_NUMBER_OF_PROJECTS_SUCCESS,
  GET_NUMBER_OF_PROJECTS_REQUEST,
  GET_BRIEF_HISTORY_SUCCESS,
  GET_BRIEF_HISTORY_FAIL,
  GET_BRIEF_HISTORY_REQUEST,
} from '../../actions/typeAction';
import {
  getProjectsService,
  getProjectsDetailService,
  searchProjectsService,
  updateStatusService,
  updateProjectsService,
  createProjectService,
  getNumberOfProjectService,
  getBriefHistoryProjectService,
} from '../../../core/services/modules/projectsService';

export function* getProjectsSaga(action) {
  const response = yield call(getProjectsService, action.payload);
  if (response.data.error) {
    yield put({
      type: GET_PROJECTS_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: GET_PROJECTS_SUCCESS,
      payload: data,
    });
  }
}

export function* getProjectsDetailSaga(action) {
  const response = yield call(getProjectsDetailService, action.payload);
  if (response.data.error || response.data.data.error) {
    yield put({
      type: GET_PROJECTS_DETAIL_FAILED,
      payload: { ...response.data.data.error, requestingId: action.payload },
    });
  } else {
    const data = response.data;
    yield put({
      type: GET_PROJECTS_DETAIL_SUCCESS,
      payload: data,
    });
  }
}

export function* createProjectsSaga(action) {
  const response = yield call(createProjectService, action.payload);
  if (response.data.error) {
    yield put({
      type: ADMIN_CREATE_PROJECT_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: ADMIN_CREATE_PROJECT_SUCCESS,
      payload: data,
    });
    // emitter update number of project
    sendGetNumberProjectAction();
  }
}

export function* searchProjectsSaga(action) {
  const response = yield call(searchProjectsService, action.payload);
  if (response.data.error) {
    yield put({
      type: SEARCH_PROJECTS_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: SEARCH_PROJECTS_SUCCESS,
      payload: data,
    });
  }
}

export function* updateStatusSaga(action) {
  const response = yield call(updateStatusService, action.payload);
  if (response.data.error) {
    yield put({
      type: UPDATE_STATUS_PROJECTS_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: UPDATE_STATUS_PROJECTS_SUCCESS,
      payload: data,
    });
  }
}

export function* updateProjectSaga(action) {
  const response = yield call(updateProjectsService, action.payload);
  if (response.data.error) {
    yield put({
      type: UPDATE_PROJECTS_FAILED,
      payload: response.data.error,
    });
  } else {
    yield put({
      type: UPDATE_PROJECTS_SUCCESS,
      payload: response.data,
    });
  }
}

export function* getNumberOfProjectSaga(action) {
  const response = yield call(getNumberOfProjectService, action.payload);
  if (response.data.error) {
    yield put({
      type: GET_NUMBER_OF_PROJECTS_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: GET_NUMBER_OF_PROJECTS_SUCCESS,
      payload: data,
    });
  }
}

export function* getBriefHistoryProjectSaga(action) {
  const response = yield call(getBriefHistoryProjectService, action.payload);
  if (response.data.error) {
    yield put({
      type: GET_BRIEF_HISTORY_FAIL,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: GET_BRIEF_HISTORY_SUCCESS,
      payload: data,
    });
  }
}

export function* watchProjects() {
  yield takeLatest(GET_PROJECTS_REQUEST, getProjectsSaga);
  yield takeLatest(GET_PROJECTS_DETAIL_REQUEST, getProjectsDetailSaga);
  yield takeLatest(ADMIN_CREATE_PROJECT_REQUEST, createProjectsSaga);
  yield takeLatest(SEARCH_PROJECTS_REQUEST, searchProjectsSaga);
  yield takeLatest(UPDATE_PROJECTS_REQUEST, updateProjectSaga);
  yield takeLatest(UPDATE_STATUS_PROJECTS_REQUEST, updateStatusSaga);
  yield takeLatest(GET_NUMBER_OF_PROJECTS_REQUEST, getNumberOfProjectSaga);
  yield takeLatest(GET_BRIEF_HISTORY_REQUEST, getBriefHistoryProjectSaga);
}
