import queryString from 'query-string';
import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const postAttachLink = data => {
  const url = BaseConfig.END_POINT.ADMIN.RESOURCE;
  const items = {
    path: data.path,
    isPublic: data.isPublic,
    projectId: data.projectId,
    name: data.name ? data.name : undefined
  };
  const response = httpService._post(url, items);
  return response;
};

export const postSignUrl = data => {
  const url = BaseConfig.END_POINT.ADMIN.SIGN_URL;
  let items = {};
  if (data.isGallery) {
    items = {
      projectId: data.projectId,
      gallery: data.gallery,
      data: data.fileUploads,
    };
  } else {
    items = {
      projectId: data.projectId,
      // gallery: data.gallery,
      data: data.fileUploads,
    };
  }
  const response = httpService._post(url, items);
  return response;
};

export const postCreateFolder = data => {
  const url = BaseConfig.END_POINT.ADMIN.ASSET_FOLDER;
  let items = {
    gallery: data.gallery,
    projectId: data.projectId,
    isPublic: data.isPublic,
  };
  const response = httpService._post(url, items);
  return response;
};

export const deleteResource = assetId => {
  const url = BaseConfig.END_POINT.ADMIN.RESOURCE;
  const response = httpService._delete(`${url}/${assetId}`);
  return response;
};

export const updateResource = data => {
  const url = BaseConfig.END_POINT.ADMIN.RESOURCE;
  const response = httpService._put(`${url}/${data.assetId}`, data);
  return response;
};

export const getGalleryFile = params => {
  const url = BaseConfig.END_POINT.ADMIN.GALLERY;
  const response = httpService._get(`${url}/${params.id}?page=${params.page}`);
  return response;
};

export const getListAssets = params => {
  const queryParams = queryString.stringify(params);
  const url = BaseConfig.END_POINT.ADMIN.RESOURCES;
  const response = httpService._get(`${url}?${queryParams}`);
  return response;
};

export const deleteAllResources = data => {
  const url = BaseConfig.END_POINT.ADMIN.RESOURCES;
  const response = httpService._put(`${url}`, data);
  return response;
};

export const renameImageGallery = data => {
  const url = BaseConfig.END_POINT.ADMIN.RESOURCE;
  const response = httpService._put(`${url}/${data.assetId}`, data);
  return response;
};
