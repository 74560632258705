import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Jumbotron, Container } from 'react-bootstrap';
import { chain } from 'lodash';
import './itemsScreen.scss';
import ModalData from '../../../common/newModal/newModal';
import ItemsForm from '../../../common/form/itemsForm/itemsForm';
import { ORDER_KEYS } from '../../../../core/constants/constants';
import Table from 'react-bootstrap/Table';

class ItemsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Admin Items - VidOps',
      data: [],
      key: '',
      itemUpdate: null,
      isModalOpen: false,
      isCreate: false,
      currentPackages: []
    };
  }

  componentDidMount() {
    this.props.onGetCurrentUser();
    this.props.onGetUnits();
    this.props.onGetPackages();
    this.props.onGetItems();
    this.props.onGetProductTypes();
  }

  handleUpdate = item => {
    if (item) {
      this.setState({
        itemUpdate: item
      });
    }
    this.setState({
      isModalOpen: true,
      isCreate: false
    });
  };

  handleAdd = currentService => {
    this.setState({
      isModalOpen: true,
      isCreate: true,
      currentService
    });
  };

  createHandler = data => {
    this.props.onCreateItems(data);
  };

  updateHandler = data => {
    const payload = {
      data,
      id: this.state.key
    };
    this.props.onUpdateItems(payload);
  };

  closeModalHandler = () => {
    this.setState({
      isModalOpen: false
    });
  };

  formatData = data => {
    if (data && data.length) {
      // Group by name and add key for table row
      const groupArr = chain(data)
        .groupBy('name')
        .map((value, key) => ({ key: key, values: value }))
        .value();
      return groupArr;
    }
  };

  renderItems = (service, items) => {
    const { packages, productTypeId } = service;
    if (packages && items && packages.length && items.length) {
      // Render packages title
      const packagesRender = packages.map(item => <th key={item._id}>{item.name}</th>);
      // Render prices base on packages
      const itemsRender = items.map(item => {
        const packagesData = [];
        if (item.values[0].productTypeId === productTypeId) {
          return (
            <tr key={item.key}>
              <td>{item.key}</td>
              {packages.map(el => {
                const pricesRender = el.items.filter(
                  price => price.name === item.key
                )[0];
                if (pricesRender) {
                  packagesData.push({
                    itemId: pricesRender._id,
                    packageId: el._id,
                    name: el.name,
                    price: pricesRender.price
                  });
                  return (
                    <td key={el._id}>
                      {pricesRender.price !== null
                        ? pricesRender.price
                        : 'N/A'}
                    </td>
                  );
                }
                packagesData.push({
                  packageId: el._id,
                  name: el.name,
                  price: 'N/A'
                });
                return <td key={el._id}>N/A</td>;
              })}
              <td>{item.values && item.values[0].measureUnitId.name}</td>
              <td>{item.values && item.values[0].status ? 'Active' : 'Inactive'}</td>
              <td>
                <Button
                  onClick={() =>
                    this.handleUpdate({
                      name: item.key,
                      description: item.values[0].description,
                      status: item.values[0].status,
                      subContent: item.values[0].subContent,
                      packages: packagesData,
                      order: item.values[0].order,
                      measureUnitId: item.values[0].measureUnitId
                    })
                  }
                  type="primary"
                  className="btn-primary-0"
                  icon="edit"
                ></Button>
              </td>
            </tr>
          );
        }
        return null;
      });

      return (
        <React.Fragment>
          <thead>
            <tr>
              <th>Items/Packages</th>
              {packagesRender}
              <th>Measure Unit</th>
              <th>Status</th>
              <th>Operation</th>
            </tr>
          </thead>
          <tbody>{itemsRender}</tbody>
        </React.Fragment>
      );
    }
  };

  groupPackagesByServiceId = () => {
    const { packages } = this.props;
    const packageFilter = this.filterActivePackages(packages);
    if (packageFilter && packageFilter.length) {
      // Group by name and add key for table row
      const groupArr = chain(packageFilter)
        .groupBy('productTypeId[_id]')
        .map((value, key) => ({
          productTypeId: key,
          packages:
            value && value.length ? value.map(item => ({ ...item, key: item._id })) : []
        }))
        .value();
      return groupArr;
    }
  };

  renderItemsByService = () => {
    const { data } = this.props;
    const groupPackages = this.groupPackagesByServiceId();
    const items = this.formatData(data);
    if (groupPackages && groupPackages.length) {
      return groupPackages.map(el => (
        <Jumbotron key={el.productTypeId} className="mgt-40">
          <h2 className="sub-heading">Items {el.packages[0].productTypeId.name}</h2>
          <p className="description">Manage Items {el.packages[0].productTypeId.name}</p>
          <div className="divider"></div>
          <Container>
            <Button
              className="btn-primary-0"
              onClick={() => this.handleAdd(el)}
              type="primary"
              style={{ marginBottom: 16 }}
            >
              Add new
            </Button>
            <Table striped bordered hover>
              {this.renderItems(el, items)}
            </Table>
          </Container>
        </Jumbotron>
      ));
    }
    return null;
  };

  componentDidUpdate(prevProps) {
    if (this.props.productTypes !== prevProps.productTypes) {
      this.props.onGetDetailService(this.props.productTypes[0]._id);
    }
    if (this.props.data !== prevProps.data) {
      this.setState({
        isModalOpen: false
      });
      this.props.onGetPackages();
    }
  }

  updateOrderHandler = orderData => {
    const updateOrderData = {
      key: ORDER_KEYS.PRICE,
      ...orderData
    };
    this.props.onUpdateOrder(updateOrderData);
  };

  filterActivePackages = packages => {
    return packages.filter(item => item.status);
  };

  render() {
    const { isLoading, packages, units, productTypes, productTypesDetail } = this.props;
    const { isModalOpen, isCreate, key, itemUpdate, currentService } = this.state;
    const packageFilter = this.filterActivePackages(packages);
    return (
      <main role="main" className="page-content items-screen">
        {this.renderItemsByService()}
        <ModalData
          className="big-modal"
          title={isCreate ? 'Create Items' : 'Update Items'}
          visible={isModalOpen}
          onCloseModal={this.closeModalHandler}
          dataSource={
            <ItemsForm
              packages={packageFilter}
              onCreateItems={this.createHandler}
              onUpdateItems={this.updateHandler}
              isLoading={isLoading}
              isCreate={isCreate}
              itemUpdate={itemUpdate}
              key={key}
              units={units}
              productTypes={productTypes}
              onProductTypesChange={this.props.onGetDetailService}
              productTypesDetail={productTypesDetail}
              currentService={currentService}
            />
          }
          footer={null}
        />
      </main>
    );
  }
}

export default withTranslation('common')(ItemsScreen);
