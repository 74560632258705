import React from 'react';
import { Button, Modal } from 'antd';
import './confirmUpdateModal.scss';

class ConfirmUpdateModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderModal = () => {
		const { isLoading, modalVisible } = this.props;
		return (
			<Modal
				visible={modalVisible}
				onCancel={this.props.onCloseModal}
				className="admin-confirm-modal"
				footer={null}
				closable={true}
				destroyOnClose={true}
			>
				<h1 className="invitation-title">Are you sure you want to block this account?</h1>
				<div className="buttons-container">
					<div className="btn-item-container">
						<Button
							type="primary"
							onClick={() => this.props.onCloseModal()}
							className="btn btn-cancel"
							size="large"
						>
							No
						</Button>
					</div>
					<div className="btn-item-container">
						<Button
							type="primary"
							loading={isLoading}
							onClick={() => this.props.onConfirm()}
							className="btn btn-create btn-primary-0"
							size="large"
							disabled={isLoading}
						>
							Yes
						</Button>
					</div>
				</div>
			</Modal>
		);
	};

	render() {
		return this.renderModal();
	}
}

export default ConfirmUpdateModal;
