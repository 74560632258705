import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const getCaseStudiesService = () => {
	const url = BaseConfig.END_POINT.ADMIN.CASE_STUDIES;
	const response = httpService._get(url);
	return response;
};

export const createCaseStudiesService = data => {
	const url = BaseConfig.END_POINT.ADMIN.CASE_STUDIES;
	const response = httpService._post(url, data);
	return response;
};

export const updateCaseStudiesService = ({ data, id }) => {
	const url = BaseConfig.END_POINT.ADMIN.CASE_STUDIES;
	const response = httpService._put(`${url}/${id}`, data);
	return response;
};

export const getCaseStudiesServiceBy = (id) => {
	const url = BaseConfig.END_POINT.ADMIN.CASE_STUDIES;
	const response = httpService._get(`${url}/${id}`);
	return response;
};