import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Jumbotron, Container } from 'react-bootstrap';
import './budgetScreen.scss';
import DataTable from '../../../common/dragTable/dragTable';
import ModalData from '../../../common/newModal/newModal';
import BudgetForm from '../../../common/form/budgetForm/budgetForm';
import { ORDER_KEYS } from '../../../../core/constants/constants';

class BudgetScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: 'Admin Budget - VidOps',
			data: [],
			key: '',
			itemUpdate: null,
			isModalOpen: false,
			isCreate: false
		};
	}

	updateBannerHandler = updateData => {
		this.props.onUpdateBanner(updateData);
	};

	componentDidMount() {
		this.props.onGetCurrentUser();
		this.props.onGetBudget();
	}

	handleDelete = key => {
		this.props.onDeleteBudget(key);
	};

	handleUpdate = key => {
		const data = this.formatData(this.props.data);
		if (data) {
			this.setState({
				itemUpdate: data.find(item => item._id === key)
			});
		}
		this.setState({
			isModalOpen: true,
			isCreate: false,
			key
		});
	};

	formatColumns = () => {
		return [
			{
				title: 'Name',
				dataIndex: 'name'
			},
			{
				title: 'Status',
				dataIndex: 'statusText',
				filters: [
					{
						text: 'Active',
						value: 'Active'
					},
					{
						text: 'Inactive',
						value: 'Inactive'
					}
				],
				onFilter: (value, record) => record.statusText.indexOf(value) === 0
			},
			{
				title: 'Value',
				dataIndex: 'value'
			},
			{
				title: 'Operation',
				dataIndex: 'operation',
				render: (text, record) => {
					return (
						<div className="operation-container">
							<Button
								onClick={() => this.handleUpdate(record.key)}
								type="primary"
								className="btn-primary-0"
								icon="edit"
							></Button>
						</div>
					);
				}
			}
		];
	};

	handleAdd = () => {
		this.setState({
			isModalOpen: true,
			isCreate: true
		});
	};

	createHandler = data => {
		this.props.onCreateBudget(data);
	};

	updateHandler = data => {
		const payload = {
			data,
			id: this.state.key
		};
		this.props.onUpdateBudget(payload);
	};

	closeModalHandler = () => {
		this.setState({
			isModalOpen: false
		});
	};

	formatData = data => {
		if (data && data.length) {
			return data.map((item, index) => {
				if (item.status) {
					return {
						...item,
						key: item._id,
						statusText: 'Active'
					};
				}
				return {
					...item,
					key: item._id,
					statusText: 'Inactive'
				};
			});
		}
	};

	componentDidUpdate(prevProps) {
		if (this.props.data !== prevProps.data) {
			this.setState({
				isModalOpen: false
			});
		}
	}

	updateOrderHandler = orderData => {
		const updateOrderData = {
			key: ORDER_KEYS.BUDGET,
			...orderData
		};
		this.props.onUpdateOrder(updateOrderData);
	};

	render() {
		const { data, isLoading } = this.props;
		const { isModalOpen, isCreate, key, itemUpdate } = this.state;
		const columns = this.formatColumns();
		const dataSource = this.formatData(data);
		return (
			<main role="main" className="page-content budget-screen">
				<Jumbotron className="mgt-40">
					<h2 className="sub-heading">Budget</h2>
					<p className="description">Manage Budget</p>
					<div className="divider"></div>
					<Container>
						<Button
							className="btn-primary-0"
							onClick={this.handleAdd}
							type="primary"
							style={{ marginBottom: 16 }}
						>
							Add new
						</Button>
						<DataTable
							columns={columns}
							dataSource={dataSource}
							pagination={false}
							onUpdateOrder={this.updateOrderHandler}
						/>
						<ModalData
							className="budget-modal"
							title={isCreate ? 'Create Budget' : 'Update Budget'}
							visible={isModalOpen}
							onCloseModal={this.closeModalHandler}
							dataSource={
								<BudgetForm
									onCreateBudget={this.createHandler}
									onUpdateBudget={this.updateHandler}
									isLoading={isLoading}
									isCreate={isCreate}
									itemUpdate={itemUpdate}
									key={key}
								/>
							}
							footer={null}
						/>
					</Container>
				</Jumbotron>
			</main>
		);
	}
}

export default withTranslation('common')(BudgetScreen);
