import React from 'react';
import { Input, Icon } from 'antd';

const SearchColumn = ({ onChange }) => {
  const renderInput = () => {
    return (
      <Input
        className="search-input-column"
        onChange={onChange}
        placeholder="Search..."
        prefix={<Icon type="search" />}
      />
    );
  };

  return renderInput();
};

export default SearchColumn;
