import {
  GET_YOUTUBE_LINK_REQUEST,
  GET_YOUTUBE_LINK_SUCCESS,
  GET_YOUTUBE_LINK_FAILED,
  CREATE_YOUTUBE_LINK_REQUEST,
  CREATE_YOUTUBE_LINK_SUCCESS,
  CREATE_YOUTUBE_LINK_FAILED,
  REMOVE_YOUTUBE_LINK_REQUEST,
  REMOVE_YOUTUBE_LINK_SUCCESS,
  REMOVE_YOUTUBE_LINK_FAILED,
} from '../../actions/typeAction';

const defaultState = {
  success: false,
  error: null,
  data: null,
  avatarUser: null,
  avtLoading: false,
  isLoading: false,
  isUrlUploading: false,
};

const emailTemplateReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_YOUTUBE_LINK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_YOUTUBE_LINK_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: true,
      };
    case GET_YOUTUBE_LINK_FAILED:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_YOUTUBE_LINK_REQUEST:
      return {
        ...state,
        isUrlUploading: true,
      };
    case CREATE_YOUTUBE_LINK_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isUrlUploading: false,
      };
    case CREATE_YOUTUBE_LINK_FAILED:
      return {
        ...state,
        isUrlUploading: false,
      };
    case REMOVE_YOUTUBE_LINK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case REMOVE_YOUTUBE_LINK_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: true,
      };
    case REMOVE_YOUTUBE_LINK_FAILED:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
};

export default emailTemplateReducer;
