/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Select,
  Input,
  DatePicker,
  Upload,
  Avatar,
  message,
  InputNumber,
} from 'antd';
import moment from 'moment';
// import {
//   PROJECT_STATUS,
//   PROJECT_PRIORITY,
// } from '../../../../../core/constants/constants';
// import { updateProjects } from '../../../../../redux/actions/admin/projectsAction';
import {
  getProductTypes,
  getProductTypesById,
} from 'src/redux/actions/admin/productTypesAction';
import {
  getPackageByParams,
  getPackageById,
} from 'src/redux/actions/admin/packageAction';
import { getServiceType } from 'src/redux/actions/admin/serviceTypeAction';
import DownloadIcon from 'src/assets/images/icons/common/download.svg';
// import FlagIcon from '../../../../../assets/images/icons/common/maps-and-flags.svg';
// import { dummyRequest } from '../../../../../core/utils/commonUtils';
// import Geosuggest from 'react-geosuggest';
// import GoogleMapReact from 'google-map-react';
import { PROJECT_STATUS, PROJECT_PRIORITY, VIDOPS_LOCATION, FILES_TYPE, IMAGES_TYPE } from 'src/core/constants/constants';
// import CKEditor from '@ckeditor/ckeditor5-react';
// import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
// import { FILES_TYPE, IMAGES_TYPE } from 'src/core/constants/constants.js';
import { getProjectsDetail, updateProjects } from 'src/redux/actions/admin/projectsAction';
import { dummyRequest, getS3CDN } from 'src/core/utils/commonUtils';
import { sendGetNumberProjectAction } from 'src/core/utils/event_emitter/handler';

import './projectInformation.scss';

const { Option } = Select;
const { TextArea } = Input;

class ProjectInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      brief: '',
      briefSchedule: '',
      briefShotlist: '',
      briefScript: '',
      briefTechnical: '',
      addressNote: '',
      quickNote: '',
      lat: VIDOPS_LOCATION.lat,
      lng: VIDOPS_LOCATION.lng,
      hasProductTypeUpdated: false,
      isAnimationProductType: false,
    };
  }

  onTextAreaChange = (property, value) => {
    this.setState({
      [property]: value,
    });
  };

  onUpdate = (property, value) => {
    const { currentProject } = this.props;
    const valueData = typeof value === 'string' ? value.trim() : value;

    if (property === 'packages' || property === 'address') {
      this.props.onUpdateProject({
        id: currentProject._id,
        [property]: JSON.stringify(valueData),
      });
    } else {
      // if(valueData.toString() === currentProject[property].toString()) return null;
      this.props.onUpdateProject({
        id: currentProject._id,
        [property]: valueData,
      });
    }
    if (property === 'status') {
      setTimeout(() => {
        this.props.refreshOnChangeStatus();
        sendGetNumberProjectAction();
      }, 1000);
    }
  };

  handleUpload = info => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      this.setState({
        uploadFiles: info.fileList,
      });
      this.props.onInputFields('uploads', info.fileList);
      this.props.onInputFields('uploadsSize', info.fileList);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  removeHandler = file => {
    const { uploadFiles } = this.state;
    this.setState({
      uploadFiles:
        uploadFiles.length && uploadFiles.filter(item => item.uid !== file.uid),
    });

    // remove file handler
    const removeFiles =
      uploadFiles.length && uploadFiles.filter(item => item.uid !== file.uid);
    this.props.onInputFields('uploads', removeFiles);
    this.props.onInputFields('uploadsSize', removeFiles);
  };

  renderUploadFiles = () => {
    const { currentProject, hasAssigned } = this.props;
    const props = {
      accept: [...FILES_TYPE, ...IMAGES_TYPE].join(','),
      name: 'file',
      customRequest: dummyRequest,
      headers: {
        authorization: 'authorization-text',
      },
      onChange: this.handleUpload,
      onRemove: this.removeHandler,
    };
    return (
      <Upload {...props}>
        {!currentProject && (
          <Avatar
            className="icon-plus"
            icon="plus"
            disabled={!hasAssigned || (currentProject && currentProject.isDelete)}
          />
        )}
      </Upload>
    );
  };

  renderFiles = () => {
    const { currentProject } = this.props;
    const resources = (currentProject && currentProject.resourceId) || [];
    if (resources.length) {
      return resources.map(item => (
        <div key={item._id} className="files-item-container">
          <a
            className="item-link"
            target="_blank"
            rel="noopener noreferrer"
            href={getS3CDN(item.path)}
            download
          >
            <img src={DownloadIcon} alt="Download" className="item-icon" />
          </a>
          <a
            className="item-name"
            target="_blank"
            rel="noopener noreferrer"
            href={getS3CDN(item.path)}
            download
          >
            {item.name}
          </a>
        </div>
      ));
    }
    return null;
  };

  componentDidUpdate(prevProps) {
    const { hasProductTypeUpdated, isAnimationProductType } = this.state;
    const { currentProject } = this.props;
    if (prevProps.serviceTypes !== this.props.serviceTypes && !currentProject) {
      this.props.onInputFields('serviceTypeId', '');
      this.props.form.setFieldsValue({ serviceTypes: '' });
    }
    if (
      prevProps.serviceTypes !== this.props.serviceTypes &&
      currentProject &&
      hasProductTypeUpdated
    ) {
      if (!isAnimationProductType) {
        const serviceTypes = this.props.serviceTypes;
        const firstServiceTypeId =
          serviceTypes && serviceTypes.length && serviceTypes[0]._id;
        this.props.form.setFieldsValue({ serviceTypes: firstServiceTypeId });
        this.onUpdate('serviceTypeId', firstServiceTypeId);
      }
    }
    if (prevProps.packages !== this.props.packages && !currentProject) {
      this.props.onInputFields('packages', '');
      this.props.form.setFieldsValue({ packages: '' });
    }
    if (
      prevProps.packages !== this.props.packages &&
      currentProject &&
      hasProductTypeUpdated
    ) {
      if (!isAnimationProductType) {
        const packages = this.props.packages;
        const firstPackage = packages && packages.length && packages[0];
        const firstPackageId = firstPackage && firstPackage._id;
        this.props.form.setFieldsValue({ packages: firstPackageId });
        this.onUpdate('packages', firstPackage);
      }
    }
  }

  componentDidMount() {
    const { currentProject } = this.props;
    const productTypeId =
      (currentProject &&
        currentProject.productTypeId &&
        currentProject.productTypeId._id) ||
      null;

    if (productTypeId) {
      this.props.onGetPackageByParams({ productTypeId });
      this.props.onGetServiceTypeByProductType({ productTypeId, status: true });
      const isAnimationProductType = this.isAnimationProductType(productTypeId);
      this.setState({ isAnimationProductType });
    }
    this.props.onGetProductTypes({ status: true });
  }

  onHandleSuggestion = suggestion => {
    if (suggestion) {
      const { location, label, gmaps } = suggestion;
      const { address_components, formatted_address, place_id } = gmaps;
      const { lat, lng } = location;
      this.setState({
        lat,
        lng,
      });
      const address = {
        address_components,
        formatted_address,
        location,
        place_id,
        label,
      };
      this.props.onInputFields('address', address);
    }
  };

  handleProductTypeChange = productTypeId => {
    const { currentProject } = this.props;
    const isAnimationProductType = this.isAnimationProductType(productTypeId);
    this.setState({
      isAnimationProductType,
    });
    if (currentProject) {
      this.setState({
        hasProductTypeUpdated: true,
      });
      if (isAnimationProductType) {
        this.onUpdate('productTypeId', productTypeId);
        this.onUpdate('serviceTypeId', '');
        this.onUpdate('packages', {});
        this.props.form.setFieldsValue({ packages: '' });
        this.props.form.setFieldsValue({ serviceTypes: '' });
      } else {
        this.onUpdate('productTypeId', productTypeId);
        this.props.onGetServiceTypeByProductType({ productTypeId, status: true });
        this.props.onGetPackageByParams({ productTypeId });
      }
    } else {
      this.props.onInputFields('productTypeId', productTypeId);
      this.props.onGetServiceTypeByProductType({ productTypeId, status: true });
      this.props.onGetPackageByParams({ productTypeId });
    }
  };

  isAnimationProductType = productTypeId => {
    const { productTypes } = this.props;
    if (productTypes && productTypes.length) {
      const filterProductType = productTypes.find(item => item._id === productTypeId);
      if (filterProductType && filterProductType.name === 'Animation') {
        return true;
      }
    }
    return false;
  };

  hasCurrentServiceType = currentServiceType => {
    const { serviceTypes } = this.props;
    if (serviceTypes && serviceTypes.length) {
      const foundService = serviceTypes.find(item => item._id === currentServiceType);
      if (foundService) {
        return true;
      }
    }
    return false;
  };

  hasCurrentPackage = currentPackage => {
    const { packages } = this.props;
    if (packages && packages.length) {
      const foundPackage = packages.find(item => item._id === currentPackage);
      if (foundPackage) {
        return true;
      }
    }
    return false;
  };

  // Content CKEditor Change
  contentChange = (event, editor) => {
    const content = editor.getData();

    if (content) {
      this.setState({
        errors: this.state.errors.filter(item => item !== 'content'),
      });
    }
    const { currentProject } = this.props;
    const brief = (currentProject && currentProject.brief) || null;

    if (!content || (content && brief === content)) return;

    this.onUpdate('brief', content);
    this.setState({
      brief: content,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      currentProject,
      packages,
      productTypes,
      hasAssigned,
      serviceTypes,
    } = this.props;
    // const { lat, lng } = this.state;
    const { isAnimationProductType } = this.state;
    const equipments = (currentProject && currentProject.equipments) || null;
    const budget = (currentProject && currentProject.budget) || 0;
    const estimationPrice = (currentProject && currentProject.estimationPrice) || 0;
    const inspirationLink = (currentProject && currentProject.inspirationLink) || null;
    const status = (currentProject && currentProject.status) || null;
    const currentProductType = (currentProject && currentProject.productTypeId) || null;
    const currentProductTypeName =
      (currentProductType && currentProductType.name) || null;
    const currentServiceType = (currentProject && currentProject.serviceTypeId) || null;
    const currentServiceTypeId = (currentServiceType && currentServiceType._id) || null;
    const currentPackage = (currentProject && currentProject.packages) || null;
    const currentPackageId = (currentPackage && currentPackage._id) || null;
    const isUrgent = (currentProject && currentProject.isUrgent) || null;
    // const brief = (currentProject && currentProject.brief) || '';
    // const addressNote = (currentProject && currentProject.addressNote) || null;
    const quickNote = (currentProject && currentProject.quickNote) || null;
    // const address = (currentProject && currentProject.address) || null;
    // const location = (address && address.location) || null;
    // const latCurrentProject = (location && location.lat) || lat;
    // const lngCurrentProject = (location && location.lng) || lng;
    // const label = (address && address.label) || null;
    const isProjectDeleted = (currentProject && currentProject.isDelete) || null;
    // const AnyReactComponent = () => (
    //   <div>
    //     <img alt="pin" style={{ width: '20px' }} src={FlagIcon} />
    //   </div>
    // );
    // const zoom = 13;
    // update project
    if (currentProject) {
      return (
        <div className="information-section">
          <h6 className="information-title">Project Information</h6>
          <Form.Item label="Created Date" className="project-form-item">
            <DatePicker
              className="created-date-picker"
              defaultValue={
                currentProject ? moment(currentProject.createdAt) : moment(new Date())
              }
              allowClear={false}
              showTime={{ format: 'HH:mm' }}
              placeholder="Select Time"
              onOk={value => this.onUpdate('createdDate', value)}
              format="YYYY/MM/DD HH:mm"
              disabled
            />
          </Form.Item>

          <Form.Item label="Status" className="project-form-item">
            {getFieldDecorator('status', {
              rules: [
                {
                  required: true,
                  message: 'Please select status',
                },
                {
                  whitespace: true,
                  message: 'Please do not input whitespace only',
                },
              ],
              initialValue: status,
            })(
              <Select
                className="status-select"
                size="small"
                onChange={value => this.onUpdate('status', value)}
                disabled={!hasAssigned || isProjectDeleted}
                getPopupContainer={triggerNode => triggerNode.parentElement}
              >
                {PROJECT_STATUS.map(item => (
                  <Option key={item.value} value={item.value}>
                    {item.value}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Product Type" className="project-form-item">
            {getFieldDecorator('productTypes', {
              rules: [
                {
                  required: true,
                  message: 'Please select product type',
                },
                {
                  whitespace: true,
                  message: 'Please do not input whitespace only',
                },
              ],
              initialValue: currentProductTypeName,
            })(
              <Select
                disabled={!hasAssigned || isProjectDeleted}
                onChange={value => this.handleProductTypeChange(value)}
                getPopupContainer={triggerNode => triggerNode.parentElement}
              >
                {productTypes.map(item => (
                  <Option key={item._id} value={item._id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Service Type" className="project-form-item">
            {getFieldDecorator('serviceTypes', {
              initialValue: this.hasCurrentServiceType(currentServiceTypeId)
                ? currentServiceTypeId
                : null,
            })(
              <Select
                disabled={!hasAssigned || isAnimationProductType || isProjectDeleted}
                onChange={value => {
                  this.onUpdate('serviceTypeId', value);
                }}
                getPopupContainer={triggerNode => triggerNode.parentElement}
              >
                {serviceTypes.map(item => (
                  <Option key={item._id} value={item._id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Package" className="project-form-item">
            {getFieldDecorator('packages', {
              initialValue: this.hasCurrentPackage(currentPackageId)
                ? currentPackageId
                : null,
            })(
              <Select
                disabled={!hasAssigned || isAnimationProductType || isProjectDeleted}
                onChange={value => {
                  const choosenPackage = packages.filter(item => item._id === value)[0];
                  this.onUpdate('packages', choosenPackage);
                }}
                getPopupContainer={triggerNode => triggerNode.parentElement}
              >
                {packages.map(item => (
                  <Option key={item._id} value={item._id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Budget" className="project-form-item">
            {getFieldDecorator('budget', {
              initialValue: budget,
            })(
              <InputNumber
                disabled={!hasAssigned || isProjectDeleted}
                className="budget-input"
                min={0}
                onBlur={e => {
                  if (String(budget) !== String(e.target.value)) {
                    this.onUpdate('budget', e.target.value);
                  }
                }}
                onPressEnter={e => {
                  if (budget !== e.target.value) {
                    this.onUpdate('budget', e.target.value);
                  }
                }}
                size="large"
              />
            )}
          </Form.Item>
          <Form.Item label="Estimation price" className="project-form-item">
            {getFieldDecorator('estimationPrice', {
              initialValue: estimationPrice,
            })(
              <InputNumber
                disabled={!hasAssigned || isProjectDeleted}
                className="estimation-price-input"
                min={0}
                onBlur={e => {
                  if (String(estimationPrice) !== String(e.target.value)) {
                    this.onUpdate('estimationPrice', e.target.value);
                  }
                }}
                onPressEnter={e => {
                  if (estimationPrice !== e.target.value) {
                    this.onUpdate('estimationPrice', e.target.value);
                  }
                }}
                size="large"
              />
            )}
          </Form.Item>
          <Form.Item label="Priority" className="project-form-item">
            {getFieldDecorator('isUrgent', {
              rules: [
                {
                  required: true,
                  message: 'Please select priority',
                },
                {
                  whitespace: true,
                  message: 'Please do not input whitespace only',
                },
              ],
              initialValue: isUrgent ? 'HIGH' : 'NORMAL',
            })(
              <Select
                onChange={value =>
                  this.onUpdate('isUrgent', value === 'HIGH' ? true : false)
                }
                disabled={!hasAssigned || isProjectDeleted}
                getPopupContainer={triggerNode => triggerNode.parentElement}
              >
                {PROJECT_PRIORITY.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          {/* <Form.Item label="Vision (Legacy brief)" className="project-form-item">
            <div className="w-100">
              <CKEditor
                disabled={!hasAssigned || isProjectDeleted}
                className="w-100"
                onInit={editor => {
                  editor.ui
                    .getEditableElement()
                    .parentElement.insertBefore(
                      editor.ui.view.toolbar.element,
                      editor.ui.getEditableElement()
                    );
                }}
                onBlur={(event, editor) => {
                  this.contentChange(event, editor);
                }}
                editor={DecoupledEditor}
                row="4"
                config={{
                  toolbar: [
                    'heading',
                    'bold',
                    'italic',
                    'link',
                    'numberedList',
                    'bulletedList',
                    'alignment',
                  ],
                }}
                data={brief}
              />
            </div>
          </Form.Item> */}
          <Form.Item label="Equipment" className="project-form-item">
            {getFieldDecorator('equipments', {
              initialValue: equipments,
            })(
              <Input
                onBlur={e => {
                  const eq = equipments || '';
                  if (eq !== e.target.value) {
                    this.onUpdate('equipments', e.target.value);
                  }
                }}
                onPressEnter={e => this.onUpdate('equipments', e.target.value)}
                placeholder="Equipment"
                size="large"
                disabled={!hasAssigned || isProjectDeleted}
              />
            )}
          </Form.Item>
          <Form.Item label="Inspiration URL" className="project-form-item">
            {getFieldDecorator('inspirationLink', {
              initialValue: inspirationLink,
            })(
              <Input
                onBlur={e => {
                  const i = inspirationLink || '';
                  if (i !== e.target.value) {
                    this.onUpdate('inspirationLink', e.target.value);
                  }
                }}
                onPressEnter={e => this.onUpdate('inspirationLink', e.target.value)}
                placeholder="Inspiration URL"
                size="large"
                disabled={!hasAssigned || isProjectDeleted}
              />
            )}
          </Form.Item>
          <Form.Item label="Project Notes" className="project-form-item">
            {getFieldDecorator('quickNote', {
              initialValue: quickNote,
            })(
              <TextArea
                rows={4}
                disabled={!hasAssigned || isProjectDeleted}
                onBlur={e => {
                  const q = quickNote || '';
                  if (q !== e.target.value) {
                    this.onUpdate('quickNote', e.target.value);
                  }
                }}
              />
            )}
          </Form.Item>
          <Form.Item
            label="Uploaded files"
            className="project-form-item files-upload-container"
          >
            {this.renderUploadFiles()}
            {this.renderFiles()}
          </Form.Item>
        </div>
      );
    }
    // create project
    return (
      <div className="information-section">
        <h6 className="information-title">Project Information</h6>
        <Form.Item label="Created Date" className="project-form-item">
          <DatePicker
            className="created-date-picker"
            defaultValue={
              currentProject ? moment(currentProject.createdAt) : moment(new Date())
            }
            allowClear={false}
            showTime={{ format: 'HH:mm' }}
            placeholder="Select Time"
            onOk={value => this.onUpdate('createdDate', value)}
            format="YYYY/MM/DD HH:mm"
            disabled
          />
        </Form.Item>
        {/* <Form.Item label="Location" className="project-form-item">
          <div id="map" className="suggestion-wrapper">
            <Geosuggest
              placeholder={`Address`}
              initialValue="VidOps | Streamlined Media Creation, Acklam Road, London, UK"
              inputClassName="ant-input suggestion-input"
              onSuggestSelect={value => this.onHandleSuggestion(value)}
            />
          </div>
        </Form.Item>
        <Form.Item label="Map" className="project-form-item map-item">
          <div className="map-wrapper">
            <GoogleMapReact
              bootstrapURLKeys={{
                lang: 'en',
              }}
              center={{ lat, lng }}
              zoom={zoom}
            >
              <AnyReactComponent lat={lat} lng={lng} text="My Marker" />
            </GoogleMapReact>
          </div>
        </Form.Item> */}
        {/* <Form.Item label="Address Note" className="project-form-item">
          {getFieldDecorator(
            'addressNote',
            {}
          )(
            <TextArea
              rows={4}
              onBlur={e => {
                this.props.onInputFields('addressNote', e.target.value);
              }}
            />
          )}
        </Form.Item> */}
        <Form.Item label="Status" className="project-form-item">
          {getFieldDecorator('status', {
            rules: [
              {
                required: true,
                message: 'Please select status',
              },
              {
                whitespace: true,
                message: 'Please do not input whitespace only',
              },
            ],
            initialValue: PROJECT_STATUS[0].value,
          })(
            <Select
              className="status-select"
              size="small"
              onChange={value => this.props.onInputFields('status', value)}
              getPopupContainer={triggerNode => triggerNode.parentElement}
            >
              {PROJECT_STATUS.map(item => (
                <Option key={item.value} value={item.value}>
                  {item.value}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Product Type" className="project-form-item">
          {getFieldDecorator('productTypes', {
            rules: [
              {
                required: true,
                message: 'Please select product type',
              },
              {
                whitespace: true,
                message: 'Please do not input whitespace only',
              },
            ],
            initialValue: null,
          })(
            <Select
              disabled={!!currentProject}
              onChange={value => this.handleProductTypeChange(value)}
              getPopupContainer={triggerNode => triggerNode.parentElement}
            >
              {productTypes.map(item => (
                <Option key={item._id} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Service Type" className="project-form-item">
          {getFieldDecorator('serviceTypes', {
            initialValue: null,
          })(
            <Select
              disabled={!!currentProject || isAnimationProductType}
              onChange={value => {
                this.props.onInputFields('serviceTypeId', value);
              }}
              getPopupContainer={triggerNode => triggerNode.parentElement}
            >
              {serviceTypes.map(item => (
                <Option key={item._id} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Package" className="project-form-item">
          {getFieldDecorator('packages', {
            initialValue: null,
          })(
            <Select
              disabled={!!currentProject || isAnimationProductType}
              onChange={value =>
                this.props.onInputFields(
                  'packages',
                  packages.filter(item => item._id === value)[0]
                )
              }
              getPopupContainer={triggerNode => triggerNode.parentElement}
            >
              {packages.map(item => (
                <Option key={item._id} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Budget" className="project-form-item">
          {getFieldDecorator('budget', {
            initialValue: null,
          })(
            <InputNumber
              className="budget-input"
              min={0}
              onBlur={e => {
                this.props.onInputFields('budget', e.target.value);
              }}
              onPressEnter={e => {
                this.props.onInputFields('budget', e.target.value);
              }}
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item label="Estimation price" className="project-form-item">
          {getFieldDecorator('estimationPrice', {
            initialValue: null,
          })(
            <InputNumber
              className="estimation-price-input"
              min={0}
              onBlur={e => {
                this.props.onInputFields('estimationPrice', e.target.value);
              }}
              onPressEnter={e => {
                this.props.onInputFields('estimationPrice', e.target.value);
              }}
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item label="Priority" className="project-form-item">
          {getFieldDecorator('isUrgent', {
            rules: [
              {
                required: true,
                message: 'Please select priority',
              },
              {
                whitespace: true,
                message: 'Please do not input whitespace only',
              },
            ],
            initialValue: isUrgent ? 'HIGH' : 'NORMAL',
          })(
            <Select
              onChange={value =>
                this.props.onInputFields('isUrgent', value === 'HIGH' ? true : false)
              }
              getPopupContainer={triggerNode => triggerNode.parentElement}
            >
              {PROJECT_PRIORITY.map(item => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        {/* <Form.Item required label="Brief" className="project-form-item">
          <div className="w-100">
            <CKEditor
              className="w-100"
              onInit={editor => {
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                  );
              }}
              onChange={(event, editor) =>
                this.props.onInputFields('brief', editor.getData())
              }
              editor={DecoupledEditor}
              row="4"
              config={{
                toolbar: [
                  'heading',
                  'bold',
                  'italic',
                  'link',
                  'numberedList',
                  'bulletedList',
                  // 'imageUpload',
                  'blockQuote',
                  'insertTable',
                  'mediaEmbed',
                ],
              }}
            />
          </div>
        </Form.Item> */}
        <Form.Item label="Equipment" className="project-form-item">
          {getFieldDecorator('equipments', {
            initialValue: equipments,
          })(
            <Input
              onBlur={e => {
                this.props.onInputFields('equipments', e.target.value);
              }}
              onPressEnter={e => this.props.onInputFields('equipments', e.target.value)}
              placeholder="Equipment"
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item label="Inspiration URL" className="project-form-item">
          {getFieldDecorator('inspirationLink', {
            initialValue: inspirationLink,
          })(
            <Input
              onBlur={e => {
                this.props.onInputFields('inspirationLink', e.target.value);
              }}
              onPressEnter={e =>
                this.props.onInputFields('inspirationLink', e.target.value)
              }
              placeholder="Inspiration URL"
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item label="Project Notes" className="project-form-item">
          {getFieldDecorator('quickNote', {
            initialValue: null,
          })(
            <TextArea
              rows={4}
              onBlur={e => {
                this.props.onInputFields('quickNote', e.target.value);
              }}
            />
          )}
        </Form.Item>
        <Form.Item label="Uploaded files" className="project-form-item">
          {this.renderUploadFiles()}
        </Form.Item>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    packages: state.packages.data,
    package: state.packages.detailData,
    productTypes: state.productType.data,
    productType: state.productType.detailData,
    serviceTypes: state.serviceType.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetProjectsDetail: id => {
      dispatch(getProjectsDetail(id));
    },
    onUpdateProject: data => {
      dispatch(updateProjects(data));
    },
    onGetPackageByParams: params => {
      dispatch(getPackageByParams(params));
    },
    onGetProductTypes: params => {
      dispatch(getProductTypes(params));
    },
    onGetProductTypeById: id => {
      dispatch(getProductTypesById(id));
    },
    onGetPackageById: id => {
      dispatch(getPackageById(id));
    },
    onGetServiceTypeByProductType: params => {
      dispatch(getServiceType(params));
    },
  };
};

const WrappedProjectInformation = Form.create({ name: 'normal_customer_information' })(
  connect(mapStateToProps, mapDispatchToProps)(ProjectInformation)
);

export default WrappedProjectInformation;
