import { connect } from 'react-redux';
import EditorsScreen from '../../../components/screens/admin/editors/editorsScreen';
import {
	getEditors,
	getEditorsDetail,
	sendInvitation
} from '../../../redux/actions/admin/editorsAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';

const mapStateToProps = state => {
	return {
		success: state.editors.success,
		error: state.editors.error,
		isLoading: state.editors.isLoading,
		editors: state.editors.data,
		isGettingDetail: state.editors.isGettingDetail,
		editorDetail: state.editors.detailData,
		isSendingInvitation: state.editors.isSendingInvitation,
		hasSentInvitation: state.editors.hasSentInvitation
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetEditors: params => {
			dispatch(getEditors(params));
		},
		onGetEditorsDetail: id => {
			dispatch(getEditorsDetail(id));
		},
		onSendInvitation: data => {
			dispatch(sendInvitation(data));
		},
		onGetCurrentUser: () => {
			dispatch(getCurrentUser());
		}
	};
};

const EditorsContainer = connect(mapStateToProps, mapDispatchToProps)(EditorsScreen);

export default EditorsContainer;
