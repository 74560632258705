import React, { PureComponent } from 'react';
import { Link, Route } from 'react-router-dom';
import { shallowEqual } from 'react-redux';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { Button, Pagination, Drawer, Icon, Tag, Form, Input } from 'antd';
import { Jumbotron, Container } from 'react-bootstrap';

import AdminIcon from '../../../../assets/images/icons/customers/admin-icon.svg';
import CustomersForm from '../../../common/form/customerForm/customerForm';
import { CUSTOMER_STATUS } from 'src/core/constants/constants';
import DataTable from '../../../common/dragTable/dragTable';
import InvitationModal from './invitationModal/invitationModal';
import UserAvatar from 'src/components/common/userAvatar';
import StatusFilter from './statusFilter/statusFilter';
import { joinArr } from 'src/core/utils/commonUtils';
import qs from 'src/core/utils/querystring';
import Teams from './teams/teams';

import './customersScreen.scss';
import CreateNewTeam from './createNewTeam';
import { getCustomers } from 'src/core/utils/event_emitter/handler';
const { Search } = Input;

class CustomersScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Admin Customers - VidOps',
      currentPage: 1,
      currentLimit: '',
      visibleDrawer: false,
      visibleModal: false,
      hasTeamsListDisplayed: false,
      selectedTeamIds: [],
      selectedStatus: [],
      currentCustomerId: '',
      textSearchCustomer: '',
      forceClearFilter: false,
      isSearch: false,
      // create new team
      visibleCreateTeam: false,
    };
    this.getCustomersListener = null;
  }

  handleFilterByTeam = selectedTeamIds => {
    const { currentLimit, selectedStatus } = this.state;
    const filterParams = {
      page: 1,
      limit: currentLimit,
      teams: joinArr(selectedTeamIds),
      status: joinArr(selectedStatus),
    };
    this._getCustomer(filterParams);
    this.setState({
      currentPage: 1,
      selectedTeamIds,
    });
  };

  handleFilterByStatus = selectedStatus => {
    const { currentLimit, textSearchCustomer } = this.state;
    const filterParams = {
      page: 1,
      limit: currentLimit,
      status: joinArr(selectedStatus),
      search: textSearchCustomer,
    };
    this._getCustomer(filterParams);
    this.setState({
      currentPage: 1,
      selectedStatus,
      forceClearFilter: false,
    });
  };

  resetFilterByTeam = () => {
    const { selectedStatus } = this.state;
    const filterParams = {
      page: 1,
      limit: '',
      teams: '',
      status: joinArr(selectedStatus),
    };
    this._getCustomer(filterParams);
    this.setState({
      selectedTeamIds: [],
    });
  };

  resetFilterByStatus = () => {
    const { textSearchCustomer } = this.state;
    const filterParams = {
      page: 1,
      limit: '',
      status: '',
      search: textSearchCustomer,
    };
    this._getCustomer(filterParams);
    this.setState({
      selectedStatus: [],
      isSearch: false,
    });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: props => {
      return dataIndex === 'teamName' ? (
        <Teams
          {...props}
          onFilterCustomerByTeam={this.handleFilterByTeam}
          onResetFilter={this.resetFilterByTeam}
        />
      ) : (
        <StatusFilter
          {...props}
          forceClearFilter={this.state.forceClearFilter}
          onFilterCustomerByTeam={this.handleFilterByStatus}
          onResetFilter={this.resetFilterByStatus}
        />
      );
    },
    filterIcon: filtered => {
      return (
        <Icon
          type="filter"
          theme="filled"
          style={{ color: filtered && !this.state.isSearch ? '#1890ff' : undefined }}
        />
      );
    },
  });

  componentDidMount() {
    this.props.onGetCurrentUser();
    this._getCustomer({
      page: this.state.currentPage,
      limit: this.state.currentLimit,
      status: joinArr(this.state.selectedStatus),
    });

    this.onInitCustomer();
    this.getCustomersListener = getCustomers(() =>
      this.onChangePage(this.state.currentPage)
    );
  }

  componentWillUnmount() {
    if (this.getCustomersListener) {
      this.getCustomersListener();
    }
  }

  _getCustomer = params => {
    this.props.onGetCustomers(params);
  };

  onInitCustomer() {
    const location = this.props.location;
    const search = location.search || '';
    const queryString = search.replace('?', '');
    const queryData = qs.parse(queryString);
    const { customerId } = queryData || {};

    if (!customerId) return null;

    this.setState({
      currentCustomerId: customerId,
      visibleDrawer: true,
    });
  }

  componentDidUpdate(prevProps) {
    if (!shallowEqual(this.props.location, prevProps.location)) {
      this.onInitCustomer();
    }

    if (this.props.match.isExact) {
      this.props.onClearCustomerDetail();
    }
  }

  formatColumns = () => {
    const { url } = this.props.match;
    return [
      {
        title: 'Full Name',
        dataIndex: 'userId',
        width: 235,
        render: (userId, record) => {
          const { isAdmin } = record || {};
          const { avt, fullName } = userId || {};
          return (
            <Link
              className="customer-id d-flex align-items-center"
              to={{ pathname: url + '/' + record._id }}
              // onClick={() =>
              //   this.setState({
              //     currentCustomerId: record._id,
              //     visibleDrawer: true,
              //   })
              // }
            >
              <UserAvatar
                fullName={fullName}
                avatar={avt}
                status={true}
                size="small"
                iconSrc={isAdmin && AdminIcon}
              />
              <span className="ml-2 customer-name">{userId && userId.fullName}</span>
            </Link>
          );
        },
      },
      {
        title: 'Team Name',
        dataIndex: 'teamName',
        width: 193,
        // ...this.getColumnSearchProps('teamName'),
      },
      {
        title: 'Email',
        dataIndex: 'userId[email]',
      },
      {
        title: 'Phone',
        dataIndex: 'userId[phoneNumber]',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status, record) => {
          const { userId } = record;
          const { isDelete } = userId || {};
          const statusIndex = CUSTOMER_STATUS.findIndex(item => item.value === status);
          const isDeleteTag = isDelete && <Tag color="#f50">Deleted</Tag>;
          return statusIndex > -1 ? (
            <div className="customer-status-container">
              <span className="customer-status">
                {CUSTOMER_STATUS[statusIndex].label}
              </span>
              {isDeleteTag}
            </div>
          ) : null;
        },
        ...this.getColumnSearchProps('status'),
        filtered: !this.state.forceClearFilter && this.state.isSearch,
      },
    ];
  };

  formatData = data => {
    if (data && data.length) {
      return data.map((item, index) => {
        return {
          ...item,
          key: item._id,
        };
      });
    }
  };

  onChangePage = page => {
    this.setState(
      {
        currentPage: page,
      },
      () => {
        this._getCustomer({
          search: this.state.textSearchCustomer,
          page: this.state.currentPage,
          limit: this.state.currentLimit,
          status: joinArr(this.state.selectedStatus),
          teams: joinArr(this.state.selectedTeamIds),
        });
      }
    );
  };

  closeDrawer = () => {
    // this.setState({
    //   visibleDrawer: false,
    // });
    const { path } = this.props.match;
    this.props.history.push({
      pathname: path,
    });
  };

  openInvitationModal = () => this.setState({ visibleModal: true });

  openCreateTeamModal = () => this.setState({ visibleCreateTeam: true });

  onCloseModal = () => this.setState({ visibleModal: false });

  onCloseModalCreateTeam = () => this.setState({ visibleCreateTeam: false });
  handleSearchProject = debounce(async text => {
    const textSearch = text.trim();
    this._getCustomer({
      page: 1,
      search: textSearch,
      limit: this.state.currentLimit,
      selectedStatus: [],
      status: joinArr(this.state.currentStatus),
    });
    this.setState({ selectedStatus: [] });
    this.setState({ forceClearFilter: true, isSearch: true });
  }, 400);

  render() {
    const { path } = this.props.match;
    const { data } = this.props;
    const {
      textSearchCustomer,
      visibleModal,
      currentCustomerId,
      currentPage,
      visibleCreateTeam,
    } = this.state;
    let dataSource = [];
    const columns = this.formatColumns();
    if (data && data.list) {
      dataSource = this.formatData(data.list);
    }
    return (
      <main role="main" className="page-content customers-screen">
        <Jumbotron className="mgt-40">
          <h2 className="sub-heading">All Customers</h2>
          <p className="description">Manage all customers</p>
          <div className="divider"></div>
          <Container>
            <Button
              className="btn-primary-0"
              onClick={this.openInvitationModal}
              type="primary"
            >
              Team Invites List
            </Button>
            {visibleModal && (
              <InvitationModal
                visibleModal={visibleModal}
                onCloseModal={this.onCloseModal}
              />
            )}

            <Button
              className="btn-primary-0 ml-2"
              onClick={this.openCreateTeamModal}
              type="primary"
            >
              Create New Team
            </Button>
            {visibleCreateTeam && (
              <CreateNewTeam
                visibleModal={visibleCreateTeam}
                onCloseModal={this.onCloseModalCreateTeam}
              />
            )}

            <div className="control-container">
              <Form>
                <Form.Item>
                  <Search
                    placeholder="search..."
                    allowClear
                    value={textSearchCustomer}
                    onChange={e => this.setState({ textSearchCustomer: e.target.value })}
                    onSearch={this.handleSearchProject}
                    style={{ width: 300, height: 42 }}
                  />
                </Form.Item>
              </Form>
              {!!data.total && (
                <Pagination
                  className="customer-pagination"
                  total={data.total}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  pageSize={10}
                  simple={false}
                  onChange={this.onChangePage}
                  current={currentPage}
                />
              )}
            </div>
            <DataTable
              columns={columns}
              dataSource={dataSource}
              onChangeHandler={this.handleChangeTable}
              hiddenUpdateOrder={true}
            />
            <Route
              exact
              path={`${path}/:itemId`}
              render={routeProps => (
                <Drawer
                  className="drawer-detail-customer"
                  width={790}
                  placement="right"
                  closable={true}
                  onClose={this.closeDrawer}
                  visible={true}
                  destroyOnClose={true}
                >
                  <CustomersForm
                    {...routeProps}
                    currentCustomerId={currentCustomerId}
                    onClose={this.closeDrawer}
                  />
                </Drawer>
              )}
            />
          </Container>
        </Jumbotron>
      </main>
    );
  }
}

export default withTranslation('common')(CustomersScreen);
