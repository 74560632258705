import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Jumbotron, Container } from 'react-bootstrap';
import './ourTeamScreen.scss';
import DataTable from '../../../common/dragTable/dragTable';
import ModalData from '../../../common/newModal/newModal';
import { getS3CDN } from '../../../../core/utils/commonUtils';
import OurTeamMembersForm from '../../../common/form/ourTeamMembersForm/ourTeamMembersForm';
import OurTeamForm from '../../../common/form/ourTeamForm/ourTeamForm';
import { ORDER_KEYS } from '../../../../core/constants/constants';

class HowItWorkScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Admin OurTeam - VidOps',
      data: [],
      key: '',
      itemUpdate: null,
      isModalOpen: false,
      isCreate: false
    };
  }

  componentDidMount() {
    this.props.onGetCurrentUser();
    this.props.onGetOurTeam();
    this.props.onGetOurTeamMembers();
  }

  handleUpdateMembers = key => {
    const data = this.formatData(this.props.ourTeamMembers);
    if (data) {
      this.setState({
        itemUpdate: data.find(item => item._id === key)
      });
    }
    this.setState({
      isModalOpen: true,
      isCreate: false,
      key
    });
  };

  formatColumns = () => {
    return [
      {
        title: 'Name',
        dataIndex: 'name'
      },
      {
        title: 'Image',
        dataIndex: 'url',
        render: (text, record) =>
          record.url ? <img className="thumbnail" src={record.url} alt="logo" /> : null
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: status => {
          if (status) {
            return <span>Active</span>;
          }
          return <span>Inactive</span>;
        },
        filters: [
          {
            text: 'Active',
            value: 'Active'
          },
          {
            text: 'Inactive',
            value: 'Inactive'
          }
        ],
        onFilter: (value, record) => {
          const status = record.status ? 'Active' : 'Inactive';
          return status.indexOf(value) === 0;
        }
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        render: (text, record) => {
          return (
            <div className="operation-container">
              <Button
                onClick={() => this.handleUpdateMembers(record.key)}
                type="primary"
                className="btn-primary-0"
                icon="edit"
              ></Button>
            </div>
          );
        }
      }
    ];
  };

  formatData = data => {
    if (data && data.length) {
      return data.map((item, index) => {
        return {
          ...item,
          key: item._id,
          url: getS3CDN(item.url)
        };
      });
    }
  };

  handleAddMembers = () => {
    this.setState({
      isModalOpen: true,
      isCreate: true
    });
  };

  createMembersHandler = data => {
    this.props.onCreateOurTeamMembers(data);
  };

  updateMembersHandler = data => {
    const payload = {
      data,
      id: this.state.key
    };
    this.props.onUpdateOurTeamMembers(payload);
  };

  updateOurTeamHandler = data => {
    this.props.onUpdateOurTeam(data);
  };

  closeModalHandler = () => {
    this.setState({
      isModalOpen: false
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.ourTeamMembers !== prevProps.ourTeamMembers) {
      this.setState({
        isModalOpen: false
      });
    }
  }

  updateOrderHandler = orderData => {
    const updateOrderData = {
      key: ORDER_KEYS.TEAM,
      ...orderData
    };
    this.props.onUpdateOrder(updateOrderData);
  };

  render() {
    const { ourTeamMembers, ourTeam, isLoading, isLoadingBanner } = this.props;
    const { isModalOpen, isCreate, key, itemUpdate } = this.state;
    const columns = this.formatColumns();
    const dataSource = this.formatData(ourTeamMembers);
    return (
      <main role="main" className="page-content ourTeam-screen">
        <Jumbotron className="mgt-40">
          <h2 className="sub-heading">Our Team</h2>
          <p className="description">Manage Our Team</p>
          <div className="divider"></div>
          <Container>
            <OurTeamForm
              onUpdateOurTeam={this.updateOurTeamHandler}
              ourTeam={ourTeam}
              isLoadingBanner={isLoadingBanner}
            />
          </Container>
        </Jumbotron>
        <Jumbotron className="mgt-40">
          <h2 className="sub-heading">Our Team Members</h2>
          <p className="description">Manage Our Team Members</p>
          <div className="divider"></div>
          <Container>
            <Button
              className="btn-primary-0"
              onClick={this.handleAddMembers}
              type="primary"
              style={{ marginBottom: 16 }}
            >
              Add new
            </Button>
            <DataTable
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              onUpdateOrder={this.updateOrderHandler}
            />
            <ModalData
              className="big-modal"
              title={isCreate ? 'Add new member' : 'Create new member'}
              visible={isModalOpen}
              onCloseModal={this.closeModalHandler}
              dataSource={
                <OurTeamMembersForm
                  onCreateOurTeamMembers={this.createMembersHandler}
                  onUpdateOurTeamMembers={this.updateMembersHandler}
                  isLoading={isLoading}
                  isCreate={isCreate}
                  itemUpdate={itemUpdate}
                  key={key}
                />
              }
              footer={null}
            />
          </Container>
        </Jumbotron>
      </main>
    );
  }
}

export default withTranslation('common')(HowItWorkScreen);
