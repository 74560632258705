import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const getYoutubeLinksService = () => {
  const url = BaseConfig.END_POINT.ADMIN.YOUTUBE_LINK + '/list';
  const response = httpService._get(url);
  return response;
};

export const createYoutubeLinksService = req => {
  const url = BaseConfig.END_POINT.ADMIN.YOUTUBE_LINK;
  const response = httpService._post(url, req);
  return response;
};

export const updateYoutubeService = req => {
  const url = BaseConfig.END_POINT.ADMIN.YOUTUBE_LINK;
  const response = httpService._put(`${url}/${req.id}`, req);
  return response;
};

export const removeYoutubeLinksService = id => {
  const url = BaseConfig.END_POINT.ADMIN.YOUTUBE_LINK + `/${id}`;
  const response = httpService._delete(url);
  return response;
};
