import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../../actions/typeAction';
import {
	getSkillsService,
	createSkillsService,
	updateSkillsService
} from '../../../core/services/modules/skillsService';
import { updateOrderService } from '../../../core/services/modules/orderService';
import { openNotification } from '../../../components/common/notification/notification';

export function* getSkillsSaga() {
	const response = yield call(getSkillsService);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_SKILLS_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_SKILLS_SUCCESS,
			payload: data
		});
	}
}

export function* createSkillsSaga(action) {
	const response = yield call(createSkillsService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.CREATE_SKILLS_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.CREATE_SKILLS_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateSkillsSaga(action) {
	const response = yield call(updateSkillsService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_SKILLS_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.UPDATE_SKILLS_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateOrderSaga(action) {
	const response = yield call(updateOrderService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_ORDER_SKILLS_FAILED,
			payload: response.data.error
		});
	} else {
		openNotification('success', 'Update successful');
	}
}

export function* watchSkills() {
	yield takeLatest(actionTypes.GET_SKILLS_REQUEST, getSkillsSaga);
	yield takeLatest(actionTypes.CREATE_SKILLS_REQUEST, createSkillsSaga);
	yield takeLatest(actionTypes.UPDATE_SKILLS_REQUEST, updateSkillsSaga);
	yield takeLatest(actionTypes.UPDATE_ORDER_SKILLS_SUCCESS, updateOrderSaga);
}
