import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../../actions/typeAction';
import {
	getHowitworkService,
	deleteHowitworkService,
	createHowitworkService,
	updateHowitworkService
} from '../../../core/services/modules/howitworkService';
import { updateOrderService } from '../../../core/services/modules/orderService';
import { openNotification } from '../../../components/common/notification/notification';

export function* getHowitworkSaga() {
	const response = yield call(getHowitworkService);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_HOWITWORK_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_HOWITWORK_SUCCESS,
			payload: data
		});
	}
}

export function* deleteHowitworkSaga(action) {
	const response = yield call(deleteHowitworkService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.DELETE_HOWITWORK_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.DELETE_HOWITWORK_SUCCESS,
			payload: action.payload
		});
	}
}

export function* createHowitworkSaga(action) {
	const response = yield call(createHowitworkService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.CREATE_HOWITWORK_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.CREATE_HOWITWORK_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateHowitworkSaga(action) {
	const response = yield call(updateHowitworkService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_HOWITWORK_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.UPDATE_HOWITWORK_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateOrderSaga(action) {
	const response = yield call(updateOrderService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_ORDER_HOWITWORK_FAILED,
			payload: response.data.error
		});
	} else {
		openNotification('success', 'Update successful');
	}
}

export function* watchHowitwork() {
	yield takeLatest(actionTypes.GET_HOWITWORK_REQUEST, getHowitworkSaga);
	yield takeLatest(actionTypes.DELETE_HOWITWORK_REQUEST, deleteHowitworkSaga);
	yield takeLatest(actionTypes.CREATE_HOWITWORK_REQUEST, createHowitworkSaga);
	yield takeLatest(actionTypes.UPDATE_HOWITWORK_REQUEST, updateHowitworkSaga);
	yield takeLatest(
		actionTypes.UPDATE_ORDER_HOWITWORK_SUCCESS,
		updateOrderSaga
	);
}
