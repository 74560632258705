import React from 'react';
import 'antd/dist/antd.css';
import './categoriesForm.scss';
import { Form, Input, Button, Select } from 'antd';

const { Option } = Select;
const { TextArea } = Input;

class CategoriesForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const name = values.name.trim();
				const status = values.status.trim() === 'Active' ? true : false;
				const description = values.description.trim();
				const data = {
					name,
					status,
					description
				};
				this.props.isCreate
					? this.props.onCreateCategories(data)
					: this.props.onUpdateCategories(data);
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { isCreate, itemUpdate, isLoading } = this.props;
		if (isCreate) {
			return (
				<Form className="categories-form">
					<Form.Item label="Name">
						{getFieldDecorator('name', {
							rules: [
								{
									required: true,
									message: 'Please input categories name'
								},
								{
									whitespace: true,
									message:
										'Please do not input whitespace only'
								}
							],
							initialValue: null
						})(<Input placeholder="Name" size="large" />)}
					</Form.Item>
					<Form.Item label="Description">
						{getFieldDecorator('description', {
							rules: [
								{
									required: true,
									message:
										'Please input categories description'
								},
								{
									whitespace: true,
									message:
										'Please do not input whitespace only'
								}
							],
							initialValue: null
						})(<TextArea placeholder="Description" rows={4} />)}
					</Form.Item>
					<Form.Item label="Status" className="status-item">
						{getFieldDecorator('status', {
							rules: [
								{
									required: true,
									message: 'Please select categories status'
								}
							],
							initialValue: 'Active'
						})(
							<Select>
								<Option value="Active">Active</Option>
								<Option value="Inactive">Inactive</Option>
							</Select>
						)}
					</Form.Item>
					<Form.Item>
						<Button
							type="primary"
							loading={isLoading}
							onClick={this.handleSubmit}
							className="categories-form-button btn-primary-0"
							size="large"
							disabled={isLoading}
						>
							Create
						</Button>
					</Form.Item>
				</Form>
			);
		}
		return (
			<Form className="categories-form">
				<Form.Item label="Name">
					{getFieldDecorator('name', {
						rules: [
							{
								required: true,
								message: 'Please input categories name'
							},
							{
								whitespace: true,
								message: 'Please do not input whitespace only'
							}
						],
						initialValue: itemUpdate.name
					})(<Input placeholder="Name" size="large" />)}
				</Form.Item>
				<Form.Item label="Description">
					{getFieldDecorator('description', {
						rules: [
							{
								required: true,
								message: 'Please input categories description'
							},
							{
								whitespace: true,
								message: 'Please do not input whitespace only'
							}
						],
						initialValue: itemUpdate.description
					})(<TextArea placeholder="Description" rows={4} />)}
				</Form.Item>
				<Form.Item label="Status" className="status-item">
					{getFieldDecorator('status', {
						rules: [
							{
								required: true,
								message: 'Please select categories status'
							}
						],
						initialValue: itemUpdate.statusText
					})(
						<Select>
							<Option value="Active">Active</Option>
							<Option value="Inactive">Inactive</Option>
						</Select>
					)}
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						loading={isLoading}
						onClick={this.handleSubmit}
						className="categories-form-button btn-primary-0"
						size="large"
						disabled={isLoading}
					>
						Update
					</Button>
				</Form.Item>
			</Form>
		);
	}
}

const WrappedCategoriesForm = Form.create({ name: 'normal_categories' })(
	CategoriesForm
);

export default WrappedCategoriesForm;
