import {
	GET_BUDGET_REQUEST,
	CREATE_BUDGET_REQUEST,
	UPDATE_BUDGET_REQUEST,
	UPDATE_ORDER_BUDGET_SUCCESS
} from '../typeAction';

export const getBudget = () => ({
	type: GET_BUDGET_REQUEST
});

export const createBudget = data => ({
	type: CREATE_BUDGET_REQUEST,
	payload: data
});

export const updateBudget = ({ data, id }) => ({
	type: UPDATE_BUDGET_REQUEST,
	payload: { data, id }
});

export const updateOrder = data => ({
	type: UPDATE_ORDER_BUDGET_SUCCESS,
	payload: data
});
