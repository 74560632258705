import React from 'react';
import './customerInfo.scss';
import { Modal, List, Icon, Button, Popover } from 'antd';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import AdminIcon from '../../../../../assets/images/icons/customers/admin-icon.svg';
import EmailIcon from '../../../../../assets/images/icons/common/email.svg';
import PhoneIcon from '../../../../../assets/images/icons/common/phone.svg';
import UserAvatar from 'src/components/common/userAvatar/index.js';

class CustomerInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      newAdmin: '',
      popupVisible: false,
    };
  }

  tranferAdmin = () => {
    const { newAdmin } = this.state;
    const { customerInfo } = this.props;
    const teamId = (customerInfo.team && customerInfo.team._id) || '';
    this.props.onTransferAdmin({
      customerId: customerInfo._id,
      teamId,
      newAdmin,
    });
    this.closeModal();
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      newAdmin: '',
    });
  };

  openModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  openPopup = () => {
    this.setState({
      popupVisible: true,
    });
  };

  renderModal = () => {
    const { modalVisible } = this.state;
    return (
      <Modal
        visible={modalVisible}
        onCancel={this.closeModal}
        className="customer-info-modal customers-confirm-modal"
        footer={null}
      >
        {this.renderConfirm()}
      </Modal>
    );
  };

  selectItem = id => {
    this.setState({
      newAdmin: id,
      modalVisible: true,
      popupVisible: false,
    });
  };

  renderMembers = () => {
    const { customerInfo } = this.props;
    const { newAdmin } = this.state;
    const members =
      (customerInfo && customerInfo.team && customerInfo.team.members) || [];
    return (
      <div className="infinite-members-container">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          // loadMore={this.handleInfiniteOnLoad}
          loadMore={() => {}}
          useWindow={false}
        >
          <List
            className="customer-list"
            dataSource={members}
            renderItem={item => {
              const { _id, fullName, avt, email } = item.userId || {};
              return (
                <List.Item
                  key={_id}
                  onClick={() => this.selectItem(_id)}
                  className="customer-item"
                >
                  <List.Item.Meta
                    avatar={<UserAvatar fullName={fullName} avatar={avt} status={true} />}
                    title={fullName}
                    description={email}
                  />
                  {newAdmin === item.userId._id ? (
                    <Icon className="icon-check" type="check" />
                  ) : null}
                </List.Item>
              );
            }}
          ></List>
        </InfiniteScroll>
      </div>
    );
  };

  renderConfirm = () => (
    <div className="text-center">
      <h6 className="confirm-heading">
        Are you sure you want to transfer the admin team role to this account?
      </h6>
      <div className="btn-container">
        <Button className="btn-primary-0 btn-cancel" onClick={this.closeModal}>
          No
        </Button>
        <Button className="btn-primary-0 btn-ok" onClick={this.tranferAdmin}>
          Yes
        </Button>
      </div>
    </div>
  );

  handleVisibleChange = popupVisible => {
    this.setState({ popupVisible });
  };

  renderTransferAdmin = () => {
    const { customerInfo } = this.props;
    const { popupVisible } = this.state;
    if (customerInfo && customerInfo.isAdmin) {
      return (
        <Popover
          content={this.renderMembers()}
          trigger="click"
          visible={popupVisible}
          placement="left"
          onVisibleChange={this.handleVisibleChange}
          className="members-popup"
        >
          <Button className="btn-transfer" onClick={() => this.openPopup('members')}>
            Transfer Admin to
            <Icon type="down" />
          </Button>
        </Popover>
      );
    }
    return null;
  };

  renderStatus = () => {
    const { customerInfo } = this.props;
    const { status } = customerInfo;
    return (
      <Button disabled className="btn-status">
        {status.charAt(0).toUpperCase() + status.slice(1)}{' '}
        <Icon className="icon-status" type="down" />
      </Button>
    );
  };

  render() {
    const { customerInfo } = this.props;
    const { isAdmin, userId } = customerInfo || {};

    const { avt, fullName } = userId || {};
    return (
      <div className="customer-info-container">
        <UserAvatar
          fullName={fullName}
          avatar={avt}
          status={true}
          iconSrc={isAdmin && AdminIcon}
        />
        <div className="info-container">
          <span className="account-id">
            {customerInfo &&
              customerInfo.userId &&
              customerInfo.userId._id.substring(customerInfo.userId._id.length - 4)}
          </span>
          <span className="name">
            {customerInfo && customerInfo.userId && customerInfo.userId.fullName}
          </span>
          <div className="email-container">
            <img src={EmailIcon} alt="email" className="email-icon" />
            <a
              className="email"
              href={
                'mailto:' + customerInfo &&
                customerInfo.userId &&
                customerInfo.userId.email
              }
            >
              {customerInfo && customerInfo.userId && customerInfo.userId.email}
            </a>
          </div>
          <div className="phone-container">
            <img src={PhoneIcon} alt="phone" className="phone-icon" />
            <a
              className="phone"
              href={
                'tel:' + customerInfo &&
                customerInfo.userId &&
                customerInfo.userId.phoneNumber
              }
            >
              {customerInfo && customerInfo.userId && customerInfo.userId.phoneNumber}
            </a>
          </div>
          {this.renderTransferAdmin()}
          {this.renderModal()}
        </div>
        <div className="status-container">
          {this.renderStatus()}
          <div className="date-container">
            <span className="date-title">Date created</span>
            <span className="date">
              {moment(customerInfo.createdAt).format('MMM DD, YYYY [at] HH:mm')}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerInfo;
