import React, { forwardRef, memo, useEffect, useMemo, useState } from 'react';
import queryString from 'query-string';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { getS3CDN } from 'src/core/utils/commonUtils';
import { Trans } from 'react-i18next';
import UserAvatar from 'src/components/common/userAvatar';
import RouterHelper from 'src/core/utils/router';

const NotificationItem = (props, ref) => {
  const history = useHistory();
  const location = useLocation();
  const {
    notification = {},
    onSeenNotification: propSeenNotification = () => null,
  } = props;
  const [unRead, setUnRead] = useState(notification.unRead || 0);

  const { fullName, avatar, email } = useMemo(() => {
    try {
      return {
        fullName: notification.senderId.fullName,
        avatar: getS3CDN(notification.senderId.avt),
        email: notification.senderId.email,
      };
    } catch (e) {}

    return {};
  }, [notification]);

  const { receiveAvt, receiveFullName } = useMemo(() => {
    try {
      return {
        receiveFullName: notification.receiveId.fullName,
        receiveAvt: getS3CDN(notification.receiveId.avt),
      };
    } catch (e) {}

    return {};
  }, [notification]);

  const getProject = useMemo(() => {
    try {
      const projectId = notification.projectId._id;
      return !!notification.projectId.alias
        ? notification.projectId.alias
        : projectId.substring(projectId.length - 6, projectId.length);
    } catch (e) {}
  }, [notification]);

  const getProjectTitle = useMemo(() => {
    try {
      return !!notification.projectId.title
        ? notification.projectId.title
        : 'Untitled Project';
    } catch (e) {}

    return 'Untitled Project';
  }, [notification]);

  const getTopic = useMemo(() => {
    try {
      return notification.topicId.title;
    } catch (e) {}
  }, [notification]);

  const getTopicName = useMemo(() => {
    try {
      return notification.title;
    } catch (e) {}
  }, [notification]);

  const getTeam = useMemo(() => {
    try {
      return notification.teamId.name;
    } catch (e) {}
  }, [notification]);

  const getLink = useMemo(() => {
    try {
      let url = '#';
      if (
        notification.requestTeamId &&
        notification.contentType === 'request_to_join_team'
      ) {
        const { senderId: sender, requestTeamId } = notification;
        url = RouterHelper.requestJoinTeam({
          email: sender.email,
          requestTeamId,
        });
      } else if (notification.topicId) {
        const { _id: topicId } = notification.topicId;
        const { _id: projectId } = notification.projectId;

        url = RouterHelper.topicWithId({ topicId, projectId });
      } else if (notification.projectId) {
        const { _id: projectId } = notification.projectId;

        url = RouterHelper.projectWithId({ projectId });
      } else if (notification.contentType === 'creative_sign_up_superadmin') {
        const { creativeId} = notification;
        url = RouterHelper.creativeProfile({ creativeId });
      } else if (notification.contentType === 'customer_sign_up_superadmin') {
        const { customerId } = notification;
        url = RouterHelper.customerProfile({ customerId });
      }
      return url;
    } catch (e) {}
  }, [notification]);

  const getUserAffected = useMemo(() => {
    try {
      return notification.userAffected.fullName;
    } catch (e) {}

    return '';
  }, [notification]);

  const checkOwner = useMemo(() => {
    try {
      return notification.userAffected._id === notification.receiveId._id ? '_owner' : '';
    } catch (e) {}

    return '';
  }, [notification]);

  const onSeenNotification = () => {
    let link = getLink;
    if (location.search.indexOf("status") !== -1) {
      let params = queryString.parse(location.search);
      link = link + `&status=${params.status}`
    }
    history.push(link);
    propSeenNotification(notification);
  };

  const unReadClass = useMemo(() => {
    return unRead ? `notification-unread` : '';
  }, [unRead]);

  useEffect(() => {
    setUnRead(notification.unRead);
  }, [notification]);

  const getProjectCreator = useMemo(() => {
    try {
      return notification.senderId.fullName;
    } catch (e) {}

    return '';
  }, [notification]);

  return (
    <div onClick={onSeenNotification} className={`notification-item px-2 ${unReadClass}`}>
      <div className="d-flex justify-items-start pt-2 border-top border-dark-black">
        <UserAvatar
          avatar={!!fullName ? avatar : receiveAvt}
          fullName={fullName || receiveFullName}
          status={true}
          // className="rounded-circle flex-shrink-0"
        />
        <figure className="ml-2">
          <Trans
            i18nKey={`notifications.${notification.contentType}${checkOwner}`}
            components={[
              <strong key={`STRONG_1`}>value1</strong>,
              <Link key={`HREF_1`} to={getLink}>
                value2
              </Link>,
            ]}
            values={{
              email,
              user: fullName,
              projectId: getProject,
              projectTitle: getProjectTitle,
              topicId: getTopic,
              topic: getTopicName,
              team: getTeam,
              userAffectedName: getUserAffected,
              projectCreator: getProjectCreator,
            }}
          />
        </figure>
      </div>
    </div>
  );
};

export default memo(forwardRef(NotificationItem));
