import { put, call, takeLatest } from 'redux-saga/effects';

import {
  GET_TEAMS_FAILED,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_REQUEST,
  LOAD_MORE_TEAMS_FAILED,
  LOAD_MORE_TEAMS_REQUEST,
  LOAD_MORE_TEAMS_SUCCESS,
  GET_INVITATION_REQUEST,
  GET_INVITATION_FAILED,
  GET_INVITATION_SUCCESS,
  GET_TEAM_DETAIL_FAILED,
  GET_TEAM_DETAIL_SUCCESS,
  GET_TEAM_DETAIL_REQUEST,
} from '../../actions/typeAction';
import {
  getTeamsService,
  getInvitationsService,
  getTeamDetailService,
} from '../../../core/services/modules/teamsService';
import { has } from 'lodash';

export function* getTeamsSaga(action) {
  const response = yield call(getTeamsService, action.payload);
  if (response.data.error) {
    yield put({
      type: GET_TEAMS_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: GET_TEAMS_SUCCESS,
      payload: data,
    });
  }
}

export function* loadMoreTeamsSaga(action) {
  const response = yield call(getTeamsService, action.payload);
  if (response.data.error) {
    yield put({
      type: LOAD_MORE_TEAMS_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: LOAD_MORE_TEAMS_SUCCESS,
      payload: data,
    });
  }
}

export function* getInvitationsSaga(action) {
  const response = yield call(getInvitationsService, action.payload);
  if (response.data.error) {
    yield put({
      type: GET_INVITATION_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: GET_INVITATION_SUCCESS,
      payload: data,
    });
  }
}

export function* getTeamDetailSaga(action) {
  const response = yield call(getTeamDetailService, action.payload);
  if (response.data.error || !has(response, 'data.data._id')) {
    yield put({
      type: GET_TEAM_DETAIL_FAILED,
      payload: response.data.error || {},
    });
  } else {
    const data = response.data;
    yield put({
      type: GET_TEAM_DETAIL_SUCCESS,
      payload: data,
    });
  }
}

export function* watchTeams() {
  yield takeLatest(GET_TEAMS_REQUEST, getTeamsSaga);
  yield takeLatest(LOAD_MORE_TEAMS_REQUEST, loadMoreTeamsSaga);
  yield takeLatest(GET_INVITATION_REQUEST, getInvitationsSaga);
  yield takeLatest(GET_TEAM_DETAIL_REQUEST, getTeamDetailSaga);
}
