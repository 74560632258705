import React, { useState, memo } from 'react';
import { connect } from 'react-redux';
import { Form, Empty, Avatar, Button, List, Divider, Tooltip } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { getEnv } from 'src/core/configs/envConfig';
import { isAdmin, isSuperAdmin } from 'src/core/utils/commonUtils';
import { updateProjects } from 'src/redux/actions/admin/projectsAction';
import { getCreatives, loadMoreCreatives } from 'src/redux/actions/admin/creativesAction';
import { getEditors, loadMoreEditors } from 'src/redux/actions/admin/editorsAction';
import { getAdmins, loadMoreAdmins } from 'src/redux/actions/admin/adminsAction';
import ModalData from 'src/components/common/newModal/newModal';
import ScrollList from 'src/components/common/scrollList/scrollList';
import UserAvatar from 'src/components/common/userAvatar';
import { iAsana, iHubspot, iProposify, iXero } from 'src/assets/images/icons/project';
import iVidops from 'src/assets/images/icons/vidops-icon.svg';

import './projectActions.scss';

const TYPE_LINK = {
  ASANA: 'ASANA',
  HUBSPOT: 'HUBSPOT',
  PROPOSIFY: 'PROPOSIFY',
  XERO: 'XERO',
  VIDOPS_APP: 'VIDOPS_APP',
};

const RenderLink = memo(function RenderLink({
  textLink,
  type,
  link,
  handler,
  textDefault,
  textDone,
  ...props
}) {
  const [text, setText] = useState(textDefault);
  const callback = () => {
    setText(textDone);
    setTimeout(() => {
      setText(textDefault);
    }, 2000);
  };

  const renderIcon = () => {
    switch (type) {
      case TYPE_LINK.ASANA:
        return iAsana;
      case TYPE_LINK.HUBSPOT:
        return iHubspot;
      case TYPE_LINK.PROPOSIFY:
        return iProposify;
      case TYPE_LINK.XERO:
        return iXero;
      case TYPE_LINK.VIDOPS_APP:
        return iVidops;
      default:
        return iAsana;
    }
  };

  return (
    <Tooltip placement="top" title={text}>
      <div onClick={() => handler(link, type, callback)} className="link-render">
        <img src={renderIcon()} alt="icon" />
        <h6 className="text-link">{textLink}</h6>
      </div>
    </Tooltip>
  );
});

class ProjectActions extends React.Component {
  isCopiable = false;
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      removeItem: null,
      isRemoveModalOpen: false,
      isModalOpen: false,
      isDisplayCreatives: false,
      isDisplayEditors: false,
      isDisplayAdmins: false,
      isDisplayDatePicker: false,
      creativesPage: 1,
      editorsPage: 1,
      editorsLimit: '',
      adminsPage: 1,
      adminsLimit: 10,
      productionDateItem: null,
      productionDatesCreate: [],
      canRemove: false,
    };
  }

  componentDidMount() {
    this.isCopiable = !!navigator.clipboard && !!navigator.clipboard.writeText;
  }

  onUpdate = (property, value) => {
    const { currentProject } = this.props;
    const valueData = typeof value === 'string' ? value.trim() : value;
    this.props.onUpdateProject({
      id: currentProject._id,
      [property]: valueData,
    });
  };

  showRemoveModal = (item, type) => {
    this.setState({
      isRemoveModalOpen: true,
      removeItem: { ...item, type },
    });
  };

  userRender = (arr, type) => {
    const { hasAssigned } = this.props;
    if (!arr || !arr.length) {
      return null;
    }
    if (type === 'admins') {
      return arr.map((item, index) => {
        const { userId } = item || {};
        const { avt, fullName } = userId || {};
        // return (
        //   <Col key={index} className="mgb-12" xs={5}>
        //     <UserAvatar
        //       status={hasAssigned}
        //       avatar={avt}
        //       onClick={() => this.showRemoveModal({ ...item, status: false }, type)}
        //       fullName={fullName}
        //       size="small"
        //     />
        //   </Col>
        // );
        return (
          <UserAvatar
            key={index}
            status={hasAssigned}
            avatar={avt}
            onClick={() => this.showRemoveModal({ ...item, status: false }, type)}
            fullName={fullName}
            size="small"
          />
        );
      });
    }
    return arr.map((item, index) => {
      const { avt, fullName } = item && item.id && item.id.userId;

      if (hasAssigned) {
        const status =
          item.status === 'ACCEPT' || (item.id && item.id.status === 'active');
        // return (
        //   <Col key={index} className="mgb-12" xs={5}>
        //     <UserAvatar
        //       status={!status}
        //       avatar={avt}
        //       onClick={() => this.showRemoveModal({ ...item.id, status: false }, type)}
        //       fullName={fullName}
        //       size="small"
        //     />
        //   </Col>
        // );
        return (
          <UserAvatar
            key={index}
            status={!status}
            avatar={avt}
            onClick={() => this.showRemoveModal({ ...item.id, status: false }, type)}
            fullName={fullName}
            size="small"
          />
        );
      }
      // return (
      //   <Col key={index} className="mgb-12" xs={5}>
      //     <UserAvatar status={false} avatar={avt} fullName={fullName} size="small" />
      //   </Col>
      // );
      return (
        <UserAvatar
          key={index}
          status={false}
          avatar={avt}
          fullName={fullName}
          size="small"
        />
      );
    });
  };

  renderCreativesModal = () => {
    const { isModalOpen, isDisplayCreatives } = this.state;
    const { currentProject, creatives, isCreativesLoadingMore } = this.props;
    const creativeIds =
      (currentProject &&
        currentProject.creativeId &&
        currentProject.creativeId.length &&
        currentProject.creativeId.map(item => item.id._id)) ||
      null;
    return (
      <ModalData
        className="creatives-modal"
        title="Members"
        visible={isModalOpen && isDisplayCreatives}
        onCloseModal={this.closeModalHandler}
        footer={null}
        dataSource={
          <ScrollList
            ids={creativeIds}
            data={creatives ? creatives : <Empty />}
            loadMore={this.loadMoreCreativesHandler}
            isLoading={isCreativesLoadingMore}
            type="creativeId"
            onSelectItem={this.updateUsersHandler}
          />
        }
      />
    );
  };

  renderEditorsModal = () => {
    const { isModalOpen, isDisplayEditors } = this.state;
    const { currentProject, editors, isEditorsLoadingMore } = this.props;
    const editorIds =
      (currentProject &&
        currentProject.editorId &&
        currentProject.editorId.length &&
        currentProject.editorId.map(item => item.id._id)) ||
      null;
    return (
      <ModalData
        className="editors-modal"
        title="Members"
        visible={isModalOpen && isDisplayEditors}
        onCloseModal={this.closeModalHandler}
        footer={null}
        dataSource={
          <ScrollList
            ids={editorIds}
            data={editors ? editors : <Empty />}
            loadMore={this.loadMoreEditorsHandler}
            isLoading={isEditorsLoadingMore}
            type="editorId"
            onSelectItem={this.updateUsersHandler}
          />
        }
      />
    );
  };

  renderAdminsModal = () => {
    const { isModalOpen, isDisplayAdmins } = this.state;
    const { currentProject, admins, isAdminsLoadingMore } = this.props;
    const adminsId =
      (currentProject &&
        currentProject.admins &&
        currentProject.admins.length &&
        currentProject.admins.map(item => item._id)) ||
      null;
    return (
      <ModalData
        className="admins-modal"
        title="Members"
        visible={isModalOpen && isDisplayAdmins}
        onCloseModal={this.closeModalHandler}
        footer={null}
        dataSource={
          <ScrollList
            ids={adminsId}
            data={admins ? admins : <Empty />}
            loadMore={this.loadMoreAdminsHandler}
            isLoading={isAdminsLoadingMore}
            type="admins"
            onSelectItem={this.updateUsersHandler}
          />
        }
      />
    );
  };

  // productionDatesRender = () => {
  //   const { productionDatesCreate } = this.state;
  //   const { currentProject, hasAssigned } = this.props;
  //   const productionDates = currentProject
  //     ? cloneDeep(currentProject.productionDates)
  //     : productionDatesCreate;
  //   if (productionDates && productionDates.length) {
  //     return productionDates.map((item, index) => (
  //       <div key={index} className="production-date-item">
  //         <DatePicker
  //           key={`startDate${item.startDate}`}
  //           defaultValue={moment(item.startDate)}
  //           showTime={{ format: 'HH:mm' }}
  //           placeholder="Select Time"
  //           onChange={(value, dateString) => {
  //             productionDates[index].startDate = dateString;
  //           }}
  //           format='YYYY-MM-DD HH:mm'
  //           onOk={() => {
  //             if (currentProject) {
  //               let oldValue = null;
  //               let value = new Date(productionDates[index].startDate);

  //               try {
  //                 oldValue = currentProject.productionDates[index].startDate;
  //               } catch (e) { }

  //               let isValueChanged = value.getTime() !== new Date(oldValue).getTime();

  //               if (!isValueChanged) return null;

  //               this.props.onUpdateProject({
  //                 id: currentProject._id,
  //                 productionDates,
  //               });
  //             } else {
  //               this.props.onInputFields('productionDates', productionDates);
  //             }
  //           }}
  //           disabled={!hasAssigned && !!currentProject}
  //         />
  //         <InputNumber
  //           step={0.01}
  //           min={0}
  //           value={item.duration}
  //           className="production-date-duration mx-1"
  //           onChange={value => {
  //             if (!value) return;
  //             productionDates[index].duration = value;
  //           }}
  //           onBlur={evt => {
  //             if (currentProject) {
  //               if (!evt.target.value) return;
  //               let oldValue = null;
  //               let value = Number(evt.target.value);

  //               try {
  //                 oldValue = currentProject.productionDates[index].duration;
  //               } catch (e) { }

  //               let isValueChanged = value !== oldValue;

  //               if (!isValueChanged) return null;

  //               this.props.onUpdateProject({
  //                 id: currentProject._id,
  //                 productionDates,
  //               });
  //             } else {
  //               this.props.onInputFields('productionDates', productionDates);
  //             }
  //           }}
  //           disabled={!hasAssigned && !!currentProject}
  //         />{' '}
  //         h(s)
  //         {!hasAssigned && !!currentProject ? (
  //           <Icon className="icon-delete deactive" type="delete" />
  //         ) : (
  //           <Icon
  //             className="icon-delete"
  //             type="delete"
  //             onClick={() => this.removeProductionDate(item)}
  //           />
  //         )}
  //       </div>
  //     ));
  //   }
  //   return null;
  // };

  // renderDatePickerModal = () => {
  //   const { isModalOpen, errors, isDisplayDatePicker } = this.state;
  //   const calendarRender = (
  //     <div className="calendar-container">
  //       <div className="start-date-container">
  //         Time:{' '}
  //         <DatePicker
  //           allowClear={false}
  //           showTime={{ format: 'HH:mm' }}
  //           className="date-picker"
  //           onChange={this.onDateChange}
  //           showToday={false}
  //           format='YYYY-MM-DD HH:mm'
  //         />
  //         {errors && errors.includes('startDate') ? (
  //           <div className="ant-form-explain">Please select Start Time</div>
  //         ) : null}
  //       </div>
  //       <div className="duration-container">
  //         Duration(hours):{' '}
  //         <InputNumber
  //           step={0.01}
  //           min={0}
  //           defaultValue={1}
  //           onChange={this.onDurationChange}
  //         />
  //       </div>
  //     </div>
  //   );
  //   return (
  //     <ModalData
  //       className="date-modal"
  //       title="Date"
  //       visible={isModalOpen && isDisplayDatePicker}
  //       onCloseModal={this.closeModalHandler}
  //       dataSource={calendarRender}
  //       onOkModal={() => this.updateProductionDate()}
  //     />
  //   );
  // };

  showCreatives = () => {
    this.props.onGetCreatives({
      page: 1,
      limit: '',
      status: 'active',
      tiers: '',
    });
    this.setState({
      isModalOpen: true,
      isDisplayCreatives: true,
    });
  };

  showEditors = () => {
    const { editorsLimit } = this.state;
    this.props.onGetEditors({
      page: 1,
      limit: editorsLimit,
      // status: 'active',
      tiers: '',
    });
    this.setState({
      isModalOpen: true,
      isDisplayEditors: true,
    });
  };

  showAdmins = () => {
    const { adminsLimit } = this.state;
    this.props.onGetAdmins({
      page: 1,
      limit: adminsLimit,
      // status: 'active',
    });
    this.setState({
      isModalOpen: true,
      isDisplayAdmins: true,
    });
  };

  // showDatepicker = () => {
  //   this.setState({
  //     isModalOpen: true,
  //     isDisplayDatePicker: true,
  //     productionDateItem: null,
  //   });
  // };

  closeModalHandler = () => {
    const { errors } = this.state;
    this.setState({
      isModalOpen: false,
      isDisplayCreatives: false,
      isDisplayEditors: false,
      isDisplayAdmins: false,
      isDisplayDatePicker: false,
      creativesPage: 1,
      editorsPage: 1,
      errors: errors.filter(item => item !== 'startDate'),
    });
  };

  closeRemoveModalHandler = () => {
    this.setState({
      isRemoveModalOpen: false,
    });
  };

  loadMoreAdminsHandler = () => {
    const { admins } = this.props;
    const totalPage = admins.pages;
    const { adminsPage } = this.state;

    if (adminsPage < totalPage) {
      this.setState(
        prevState => {
          return { adminsPage: prevState.adminsPage + 1 };
        },
        () => {
          this.props.onLoadMoreAdmins({
            page: this.state.adminsPage,
            limit: this.state.adminsLimit,
            // status: 'active'
          });
        }
      );
    }
  };

  loadMoreCreativesHandler = () => {
    const { creatives } = this.props;
    const totalPage = creatives.pages;
    const { creativesPage } = this.state;
    if (creativesPage < totalPage) {
      this.setState(
        prevState => ({
          creativesPage: prevState.creativesPage + 1,
        }),
        () => {
          this.props.onLoadMoreCreatives({
            page: this.state.creativesPage,
            limit: '',
            status: 'active',
          });
        }
      );
    }
  };

  loadMoreEditorsHandler = () => {
    const { editors } = this.props;
    const totalPage = editors.pages;
    const { editorsPage } = this.state;
    if (editorsPage < totalPage) {
      this.setState(
        prevState => ({
          editorsPage: prevState.editorsPage + 1,
        }),
        () => {
          this.props.onLoadMoreEditors({
            page: this.state.editorsPage,
            limit: this.state.editorsLimit,
            // status: 'active',
          });
        }
      );
    }
  };

  updateUsersHandler = (id, property) => {
    const { currentProject } = this.props;
    this.props.onUpdateProject({
      id: currentProject._id,
      [property]: id,
    });
  };

  // onDateChange = (date, startDate) => {
  //   let { productionDateItem, errors } = this.state;
  //   productionDateItem = {
  //     ...productionDateItem,
  //     startDate: new Date(startDate).toUTCString(),
  //   };
  //   this.setState({
  //     productionDateItem,
  //     errors: errors.filter(item => item !== 'startDate'),
  //   });
  // };

  // onDurationChange = duration => {
  //   let { productionDateItem } = this.state;
  //   productionDateItem = {
  //     ...productionDateItem,
  //     duration,
  //   };
  //   this.setState({
  //     productionDateItem,
  //   });
  // };

  // updateProductionDate = () => {
  //   let { productionDateItem, errors } = this.state;
  //   const { currentProject } = this.props;
  //   const productionDates =
  //     currentProject && currentProject.productionDates
  //       ? currentProject.productionDates
  //       : [];
  //   if (!productionDateItem || !productionDateItem.duration) {
  //     productionDateItem = {
  //       ...productionDateItem,
  //       duration: 1,
  //     };
  //   }
  //   if (!productionDateItem || !productionDateItem.startDate) {
  //     productionDateItem = {
  //       ...productionDateItem,
  //     };
  //     this.setState({
  //       errors: errors.concat('startDate'),
  //     });
  //     return;
  //   }
  //   const productionDatesUpdate = [...productionDates, productionDateItem];
  //   this.setState(
  //     {
  //       isDisplayDatePicker: false,
  //       isModalOpen: false,
  //     },
  //     () => {
  //       const { productionDatesCreate } = this.state;
  //       if (currentProject) {
  //         this.props.onUpdateProject({
  //           id: currentProject._id,
  //           productionDates: productionDatesUpdate,
  //         });
  //       } else {
  //         this.setState({
  //           productionDatesCreate: [
  //             ...productionDatesCreate,
  //             ...productionDatesUpdate,
  //           ].map(item => ({ ...item, id: uuidv4() })),
  //         });
  //         this.props.onInputFields('productionDates', [
  //           ...productionDatesCreate,
  //           ...productionDatesUpdate,
  //         ]);
  //       }
  //     }
  //   );
  // };

  // removeProductionDate = item => {
  //   const { productionDatesCreate } = this.state;
  //   const { currentProject } = this.props;
  //   if (currentProject) {
  //     const { productionDates } = currentProject;
  //     this.props.onUpdateProject({
  //       id: currentProject._id,
  //       productionDates: productionDates.filter(el => el._id !== item._id),
  //     });
  //   } else {
  //     // remove Production date item
  //     const filterProductionDates =
  //       productionDatesCreate.length &&
  //       productionDatesCreate.filter(el => el.id !== item.id);
  //     this.setState({
  //       productionDatesCreate: filterProductionDates,
  //     });
  //     this.props.onInputFields('productionDates', filterProductionDates);
  //   }
  // };

  removeUser = () => {
    const { removeItem } = this.state;
    this.updateUsersHandler(removeItem._id, removeItem.type);
    this.setState({
      isRemoveModalOpen: false,
    });
  };

  checkCanRemoveAdmin = () => {
    const { removeItem } = this.state;
    const { currentProject, currentUser } = this.props;
    const admins = (currentProject && currentProject.admins) || null;
    let canRemove;
    if (isAdmin(currentUser)) {
      canRemove =
        admins &&
        admins.length > 1 &&
        removeItem &&
        removeItem.userId &&
        removeItem.userId._id === currentUser._id;
      return canRemove;
    }
    canRemove = admins && admins.length > 1;
    return canRemove;
  };

  removeModalRender = () => {
    const { isRemoveModalOpen, removeItem } = this.state;
    const user = removeItem && removeItem.userId;
    const status = removeItem && removeItem.status;
    if (!user) return;

    const dataRender = (
      <>
        <List
          dataSource={[user]}
          renderItem={item => {
            const { _id, avt, fullName } = item || {};
            return (
              <List.Item key={_id}>
                <List.Item.Meta
                  avatar={
                    <UserAvatar
                      fullName={fullName}
                      avatar={avt}
                      status={!status}
                      key={_id}
                    />
                  }
                  title={item.fullName}
                  description={item.email}
                />
              </List.Item>
            );
          }}
        ></List>
        <Divider />
        <div className="actions-btn-container">
          <Button
            className="cancel-btn btn-primary-0"
            onClick={() => {
              this.setState({
                isRemoveModalOpen: false,
              });
            }}
          >
            Cancel
          </Button>
          <Button
            className="remove-btn btn-primary-0"
            onClick={() => this.removeUser()}
            disabled={
              removeItem && removeItem.type === 'admins'
                ? !this.checkCanRemoveAdmin()
                : false
            }
          >
            Remove
          </Button>
        </div>
      </>
    );
    return (
      <ModalData
        className="remove-modal"
        title=""
        visible={isRemoveModalOpen && removeItem ? true : false}
        onCloseModal={this.closeRemoveModalHandler}
        dataSource={dataRender}
        footer={null}
      />
    );
  };

  handleCopyLink = async (link, type, cb) => {
    if (link && type !== TYPE_LINK.VIDOPS_APP) {
      return window.open(link, '_blank');
    }
    if (!this.isCopiable) {
      return window.open(link, '_blank');
    }
    try {
      await navigator.clipboard.writeText(link);
      if (cb && typeof cb === 'function') {
        cb();
      }
    } catch {
      window.alert('Auto copy failed, please do it manually: ' + link);
    }
  };

  renderPlusIcon = type => {
    const { currentProject, hasAssigned, currentUser } = this.props;
    let event;
    if (!currentProject) return <Avatar className="icon-plus deactive" icon="plus" />;
    if (type === 'Admins') {
      event = this.showAdmins;
      return isSuperAdmin(currentUser) ? (
        // <Avatar className="icon-plus" icon="plus" onClick={event} />
        <Button type="primary" shape="circle" icon="plus" onClick={event} />
      ) : (
        // <Avatar className="icon-plus deactive" icon="plus" />
        <Button type="primary" shape="circle" icon="plus" disabled />
      );
    } else {
      event = type === 'Creatives' ? this.showCreatives : this.showEditors;
      if (isSuperAdmin(currentUser)) {
        // return <Avatar className="icon-plus" icon="plus" onClick={event} />;
        return <Button type="primary" shape="circle" icon="plus" onClick={event} />;
      } else {
        return !hasAssigned ? (
          // <Avatar className="icon-plus deactive" icon="plus" />
          <Button type="primary" shape="circle" icon="plus" disabled />
        ) : (
          // <Avatar className="icon-plus" icon="plus" onClick={event} />
          <Button type="primary" shape="circle" icon="plus" onClick={event} />
        );
      }
    }
  };

  // renderPlusIconProductionDates = () => {
  //   const { currentProject, hasAssigned } = this.props;
  //   if (currentProject) {
  //     if (!hasAssigned) {
  //       return <Avatar className="icon-plus deactive" icon="plus" />;
  //     }
  //     return <Avatar className="icon-plus" icon="plus" onClick={this.showDatepicker} />;
  //   }
  //   return <Avatar className="icon-plus" icon="plus" onClick={this.showDatepicker} />;
  // };

  renderCreatives = () => {
    const { currentProject } = this.props;
    const creativeId = (currentProject && currentProject.creativeId) || null;
    const creativeRender = this.userRender(creativeId, 'creativeId');
    return (
      <>
        <span className="project-sub-title">Creatives</span>
        {/* {creativeRender && <Row>{creativeRender}</Row>} */}
        {creativeRender}
        {/* {this.renderPlusIcon('Creatives')}
        {this.renderCreativesModal()} */}
      </>
    );
  };

  renderEditors = () => {
    const { currentProject } = this.props;
    const editorId = (currentProject && currentProject.editorId) || null;
    const editorsRender = this.userRender(editorId, 'editorId');
    return (
      <>
        <span className="project-sub-title">Editors</span>
        {/* {editorsRender && <Row>{editorsRender}</Row>} */}
        {editorsRender}
        {/* {this.renderPlusIcon('Editors')}
        {this.renderEditorsModal()} */}
      </>
    );
  };

  renderAdmins = () => {
    const { currentProject } = this.props;
    const admins = (currentProject && currentProject.admins) || null;
    const adminsRender = this.userRender(admins, 'admins');
    return (
      <>
        <span className="project-sub-title">Admins</span>
        {/* {adminsRender && <Row>{adminsRender}</Row>} */}
        {adminsRender}
        {/* {this.renderPlusIcon('Admins')}
        {this.renderAdminsModal()} */}
      </>
    );
  };

  // renderProductionDates = () => {
  //   return (
  //     <>
  //       <p className="project-sub-title">Production Date</p>
  //       <div>{this.productionDatesRender()}</div>
  //       {this.renderPlusIconProductionDates()}
  //       {this.renderDatePickerModal()}
  //     </>
  //   );
  // };

  getLink = type => {
    const { currentProject } = this.props;
    if (!currentProject || !currentProject._id) {
      return '';
    }
    return (currentProject.linkThirdParty || []).find(e => e.type === type)?.link ?? '';
  };
  renderLinks = () => {
    const { currentProject } = this.props;
    if (!currentProject || !currentProject._id) {
      return;
    }
    const textDefault = this.isCopiable ? 'Click to copy' : 'Open link in new tab';
    const textDone = 'Copied!';

    const appLink = getEnv('APP_SERVER') + '/projects/' + currentProject._id;

    return (
      <>
        <RenderLink
          textLink={'Vidops App'}
          type={TYPE_LINK.VIDOPS_APP}
          link={appLink}
          textDefault={textDefault}
          textDone={textDone}
          handler={this.handleCopyLink}
        >
          VidOps App
        </RenderLink>
        <RenderLink
          textLink={'Asana'}
          type={TYPE_LINK.ASANA}
          link={this.getLink(TYPE_LINK.ASANA)}
          handler={this.handleCopyLink}
        />
        <RenderLink
          textLink={'HubSpot'}
          type={TYPE_LINK.HUBSPOT}
          link={this.getLink(TYPE_LINK.HUBSPOT)}
          handler={this.handleCopyLink}
        />
        <RenderLink
          textLink={'Proposify'}
          type={TYPE_LINK.PROPOSIFY}
          link={''}
          textDefault={'Coming soon!'}
          textDone={'Coming soon!'}
          handler={this.handleCopyLink}
        />
        <RenderLink
          textLink={'Xero'}
          type={TYPE_LINK.XERO}
          link={''}
          textDefault={'Coming soon!'}
          textDone={'Coming soon!'}
          handler={this.handleCopyLink}
        />
      </>
    );
  };

  render() {
    // return (
    //   <Row className="actions-container">
    //     <Col xs={2}>{this.renderCreatives()}</Col>
    //     <Col xs={2}>{this.renderEditors()}</Col>
    //     <Col xs={2}>{this.renderAdmins()}</Col>
    //     <Col className="production-date-container" xs={6}>
    //       {this.renderProductionDates()}
    //     </Col>
    //     {this.removeModalRender()}
    //   </Row>
    // );
    return (
      <div className="container actions-container">
        <Row>{this.renderCreatives()}</Row>
        <Row>{this.renderEditors()}</Row>
        <Row>{this.renderAdmins()}</Row>
        <Row>
          <span className="project-sub-title">View in</span>
        </Row>
        <Row>{this.renderLinks()}</Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    creatives: state.creatives.data,
    isCreativesLoadingMore: state.creatives.isLoadingMore,
    editors: state.editors.data,
    isEditorsLoadingMore: state.editors.isLoadingMore,
    admins: state.admins.data,
    isAdminsLoadingMore: state.admins.isLoadingMore,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateProject: data => {
      dispatch(updateProjects(data));
    },
    onGetCreatives: data => {
      dispatch(getCreatives(data));
    },
    onLoadMoreCreatives: data => {
      dispatch(loadMoreCreatives(data));
    },
    onGetEditors: data => {
      dispatch(getEditors(data));
    },
    onLoadMoreEditors: data => {
      dispatch(loadMoreEditors(data));
    },
    onGetAdmins: data => {
      dispatch(getAdmins(data));
    },
    onLoadMoreAdmins: data => {
      dispatch(loadMoreAdmins(data));
    },
  };
};

const WrappedProjectActions = Form.create({ name: 'normal_project_actions' })(
  connect(mapStateToProps, mapDispatchToProps)(ProjectActions)
);

export default WrappedProjectActions;
