import { put, call, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../../actions/typeAction';
import {
  getUserInfo,
  updateUserInfo,
  uploadAvatar,
  updatePassword,
} from '../../../core/services/modules/userService';
import { sendLogoutAction } from 'src/core/utils/event_emitter/handler';

export function* getUserInfoTask(action) {
  try {
    const response = yield call(getUserInfo);
    if (response.status === 200 && response.data.success) {
      yield put({
        type: actionTypes.GET_USER_INFO_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_USER_INFO_FAILED,
        payload: response.data.error,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.GET_USER_INFO_FAILED,
      payload: err,
    });
  }
}

export function* updateUserInfoTask(action) {
  try {
    const response = yield call(updateUserInfo, action.payload);
    if (response.status === 200 && response.data.success) {
      yield put({
        type: actionTypes.UPDATE_USER_INFO_SUCCESS,
        payload: response.data.data,
      });
      // yield put({
      //   type: actionTypes.GET_USER_INFO_REQUEST,
      // });
    } else {
      yield put({
        type: actionTypes.UPDATE_USER_INFO_FAILED,
        payload: response.data.error,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.UPDATE_USER_INFO_FAILED,
      payload: err,
    });
  }
}

export function* uploadAvatarTask(action) {
  try {
    const response = yield call(uploadAvatar, action.payload);
    if (response.status === 200 && response.data.success) {
      yield put({
        type: actionTypes.UPLOAD_AVATAR_SUCCESS,
        payload: response.avatar,
      });
      yield put({
        type: actionTypes.GET_USER_INFO_REQUEST,
      });
    } else {
      yield put({
        type: actionTypes.UPLOAD_AVATAR_FAILED,
        payload: response.data.error,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.UPLOAD_AVATAR_FAILED,
      payload: err,
    });
  }
}

export function* updatePasswordTask(action) {
  try {
    const response = yield call(updatePassword, action.payload);
    if (response.status === 200 && response.data.success) {
      yield put({
        type: actionTypes.UPDATE_PASSWORD_SUCCESS,
        payload: response,
      });
      setTimeout(() => {
        sendLogoutAction();
      }, 500);
    } else {
      yield put({
        type: actionTypes.UPDATE_PASSWORD_FAILED,
        payload: response.data.error,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.UPDATE_PASSWORD_FAILED,
      payload: err,
    });
  }
}

export function* watchUser() {
  yield takeLatest(actionTypes.GET_USER_INFO_REQUEST, getUserInfoTask);
  yield takeLatest(actionTypes.UPDATE_USER_INFO_REQUEST, updateUserInfoTask);
  yield takeLatest(actionTypes.UPLOAD_AVATAR_REQUEST, uploadAvatarTask);
  yield takeLatest(actionTypes.UPDATE_PASSWORD_REQUEST, updatePasswordTask);
}
