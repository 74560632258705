import { getEnv } from './envConfig';

export const BaseConfig = {
  END_POINT: {
    S3: {
      SIGNED_URL: `${getEnv('API_SERVER')}/api/presigned-url`,
      GET_SIGNED_URL: `${getEnv('API_SERVER')}/api/get-signed-url`,
    },
    AUTH: {
      LOGIN: `${getEnv('API_SERVER')}/api/login`,
      LOGOUT: `${getEnv('API_SERVER')}/api/logout`,
      CUSTOMER_REGISTER: `${getEnv('API_SERVER')}/api/customer_register`,
      FORGOT_PASSWORD: `${getEnv('API_SERVER')}/api/forgot_password-admin`,
      CHANGE_PASSWORD: `${getEnv('API_SERVER')}/api/change_password`,
      CREATIVE_REGISTER: `${getEnv('API_SERVER')}/api/creative_register`,
      EMAIL_VERIFICATION: `${getEnv('API_SERVER')}/api/confirm_email`,
      REFRESH_TOKEN: `${getEnv('API_SERVER')}/api/access_token`,
      RESEND_VERIFICATION_CODE: `${getEnv('API_SERVER')}/api/resend_confirm_email`,
      UPDATE_PASSWORD: `${getEnv('API_SERVER')}/api/update_password`,
      AVATAR: `${getEnv('API_SERVER')}/api/avatar`,
      RESET_PASSWORD: `${getEnv('API_SERVER')}/api/reset-password-admin`,
    },
    SEO: {
      UPDATE_KEYWORDS: `${getEnv('API_SERVER')}/api/seo/refresh/keywords`,
      UPDATE_SITEMAPS: `${getEnv('API_SERVER')}/api/seo/refresh/sitemaps`,
    },
    ADMIN: {
      UPDATE_BANNER: `${getEnv('API_SERVER')}/api/update_banner`,
      GET_BANNER: `${getEnv('API_SERVER')}/api/get_banner`,
      REVIEWS: `${getEnv('API_SERVER')}/api/reviews`,
      HOWITWORK: `${getEnv('API_SERVER')}/api/how_it_work`,
      OUR_TEAM: `${getEnv('API_SERVER')}/api/our_team`,
      OUR_TEAM_MEMBERS: `${getEnv('API_SERVER')}/api/team`,
      CUSTOMER_STORIES: `${getEnv('API_SERVER')}/api/customer_story`,
      STORIES: `${getEnv('API_SERVER')}/api/story`,
      CASE_STUDIES: `${getEnv('API_SERVER')}/api/case_study`,
      HOUR_RATE: `${getEnv('API_SERVER')}/api/hour_rate`,
      SKILLS: `${getEnv('API_SERVER')}/api/skill`,
      PRIVACY: `${getEnv('API_SERVER')}/api/privacy`,
      POLICIES: `${getEnv('API_SERVER')}/api/policies`,
      CREATIVES: `${getEnv('API_SERVER')}/api/creatives`,
      EDITORS: `${getEnv('API_SERVER')}/api/editor`,
      PRODUCTION_TIME: `${getEnv('API_SERVER')}/api/production_time`,
      SYNC_USER: `${getEnv('API_SERVER')}/api/synchronize/user-status`,
      SYNC_NOTIFICATION: `${getEnv('API_SERVER')}/api/notifications/sync-count`,
      // SERVICE_TYPE: `${getEnv('API_SERVER')}/api/production-types-temp`,
      // SERVICE_TYPE: `${getEnv('API_SERVER')}/api/service-types-temp`,
      PRODUCT_TYPES: `${getEnv('API_SERVER')}/api/product-types`,
      SERVICE_TYPE: `${getEnv('API_SERVER')}/api/service-types`,
      BUDGET: `${getEnv('API_SERVER')}/api/budget`,
      ITEMS: `${getEnv('API_SERVER')}/api/items`,
      CATEGORIES: `${getEnv('API_SERVER')}/api/category`,
      ADD_ON: `${getEnv('API_SERVER')}/api/add_on`,
      PACKAGE: `${getEnv('API_SERVER')}/api/package`,
      PACKAGE_ADMIN: `${getEnv('API_SERVER')}/api/package_admin`,
      ADD_HOC: `${getEnv('API_SERVER')}/api/add_hoc`,
      ORDER: `${getEnv('API_SERVER')}/api/update_order`,
      PROJECTS: `${getEnv('API_SERVER')}/api/projects`,
      PROJECTS_NUMBER: `${getEnv('API_SERVER')}/api/projects-number`,
      PROJECTS_V3: `${getEnv('API_SERVER')}/api/v3/project`,
      PROJECT_ADMIN: `${getEnv('API_SERVER')}/api/project-admin`,
      PROJECT_UPLOADS_ADMIN: `${getEnv('API_SERVER')}/api/project-upload-admin`,
      SUB_CATEGORIES: `${getEnv('API_SERVER')}/api/sub_categories`,
      UNITS: `${getEnv('API_SERVER')}/api/measure_units`,
      SERVICES: `${getEnv('API_SERVER')}/api/services`,
      ACTIVITIES: `${getEnv('API_SERVER')}/api/history`,
      TOPICS: `${getEnv('API_SERVER')}/api/topic`,
      FILES_AND_LINKS: `${getEnv('API_SERVER')}/api/files-and-links`,
      FILES_TO_ASSETS: `${getEnv('API_SERVER')}/api/asset_files`,
      RESOURCE: `${getEnv('API_SERVER')}/api/resource`,
      RESOURCES: `${getEnv('API_SERVER')}/api/resources`,
      SIGN_URL: `${getEnv('API_SERVER')}/api/sign_url`,
      ASSET_FOLDER: `${getEnv('API_SERVER')}/api/asset_folder`,
      GALLERY: `${getEnv('API_SERVER')}/api/gallery`,
      MESSAGE: `${getEnv('API_SERVER')}/api/message`,
      UPLOAD_FILE: `${getEnv('API_SERVER')}/api/upload_file`,
      MESSAGE_FILE_PRESIGNED: `${getEnv('API_SERVER')}/api/v2/upload-presigned`,
      MESSAGE_FILE_UPLOAD: `${getEnv('API_SERVER')}/api/v2/upload-files`,
      MESSAGE_SEND_QUOTE_FILE: `${getEnv('API_SERVER')}/api/v2/send-quotes-email`,
      NOTIFICATION: {
        COUNT: `${getEnv('API_SERVER')}/api/count_notification`,
        LIST: `${getEnv('API_SERVER')}/api/notifications`,
        READ_ALL: `${getEnv('API_SERVER')}/api/notifications/read-all`,
      },
      FAQS: `${getEnv('API_SERVER')}/api/faqs`,
      CUSTOMERS: `${getEnv('API_SERVER')}/api/customers`,
      CUSTOMER: `${getEnv('API_SERVER')}/api/customer`,
      TEAMS: `${getEnv('API_SERVER')}/api/team_work`,
      CUSTOMER_TEAMWORK: `${getEnv('API_SERVER')}/api/team-work/customer`,
      TEAM: `${getEnv('API_SERVER')}/api/teams`,
      ADMINS: `${getEnv('API_SERVER')}/api/admins`,
      ROLES: `${getEnv('API_SERVER')}/api/roles`,
      USER: `${getEnv('API_SERVER')}/api/user`,
      OUR_STORIES: `${getEnv('API_SERVER')}/api/our-stories`,
      YOUTUBE_LINK: `${getEnv('API_SERVER')}/api/youtube-email`,
      INVITE_TEAM: `${getEnv('API_SERVER')}/api/v3/admin/invite_team`,
      INVITATIONS: `${getEnv('API_SERVER')}/api/v3/admin/list_invite_team`,
      DELETE_INVITATION: `${getEnv('API_SERVER')}/api/v3/admin/delete_invite_team`,
      RESEND_INVITE_TEAM: `${getEnv('API_SERVER')}/api/v3/admin/resend_invite_team`,
      DELETE_PROJECT: `${getEnv('API_SERVER')}/api/v3/project`,
      SEARCH_PROJECT: `${getEnv('API_SERVER')}/api/v3/search_project`,
      CREATE_TEAM: {
        BUSINESS_TYPE: `${getEnv('API_SERVER')}/api/business_type`,
        GET_ALL_CUSTOMER_HAVE_NOT_TEAM: `${getEnv(
          'API_SERVER'
        )}/api/admin/team-work/have-not-team`,
        CREATE_NEW_TEAM: `${getEnv('API_SERVER')}/api/admin/team-work/create`,
      },
      ASSIGN_TO_TEAM: `${getEnv('API_SERVER')}/api/admin/team-work/assign`,
      REMOVE_MEMBER_TEAM: `${getEnv('API_SERVER')}/api/admin/team-work/remove-member`,
    },
    OAUTH: {
      GOOGLE_DRIVE: `${getEnv('API_SERVER')}/api/oauth2/google-drive`,
      GOOGLE_CALENDAR: `${getEnv('API_SERVER')}/api/oauth2/google-calendar`,
      VERIFY_TOKEN_DRIVE: `${getEnv('API_SERVER')}/api/oauth2/token/google-drive`,
      VERIFY_TOKEN_CALENDAR: `${getEnv('API_SERVER')}/api/oauth2/token/google-calendar`,
      LOGOUT_DRIVE: `${getEnv('API_SERVER')}/api/oauth2/google-drive/logout`,
      LOGOUT_CALENDAR: `${getEnv('API_SERVER')}/api/oauth2/google-calendar/logout`,
      SET_PUBLIC_ALL_FILES: `${getEnv('API_SERVER')}/api/google-drive/set-public-file`,
      GET_LIST_FILES: `${getEnv('API_SERVER')}/api/google-drive/get-list-files`,
      FRAME_IO: `${getEnv('API_SERVER')}/api/oauth2/frame-io`,
      VERIFY_TOKEN_FRAME_IO: `${getEnv('API_SERVER')}/api/oauth2/token/frame-io`,
      LOGOUT_FRAME_IO: `${getEnv('API_SERVER')}/api/oauth2/frame-io/logout`,
      ASANA: `${getEnv('API_SERVER')}/api/oauth2/asana`,
      VERIFY_TOKEN_ASANA: `${getEnv('API_SERVER')}/api/oauth2/token/asana`,
      LOGOUT_ASANA: `${getEnv('API_SERVER')}/api/oauth2/asana/logout`,
      HUBSPOT: `${getEnv('API_SERVER')}/api/oauth2/hubspot`,
      VERIFY_TOKEN_HUBSPOT: `${getEnv('API_SERVER')}/api/oauth2/token/hubspot`,
      LOGOUT_HUBSPOT: `${getEnv('API_SERVER')}/api/oauth2/hubspot/logout`,
    },
  },
};
