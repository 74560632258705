import React, { forwardRef, memo, useCallback, useState } from "react";
import { Col, Container, Jumbotron, Row } from "react-bootstrap";
import { Button } from "antd";
import { openNotification } from "../../../common/notification/notification";
import { httpService } from "src/core/services/httpService";
import { BaseConfig } from "src/core/configs/baseConfig";
import { DOCUMENT_TYPE } from "src/core/constants/constants";

const UpdateButton = memo(props => {
  const { title, buttonTitle = "Update", onSubmit = () => null } = props;
  const [loading, setLoading] = useState(false);

  const onClick = useCallback(async () => {
    setLoading(true);
    await onSubmit();
    setLoading(false);
  }, [onSubmit]);

  return (
    <>
      <h4>{title}</h4>
      <Button
        disabled={loading}
        loading={loading}
        onClick={onClick}
        className="btn-primary-0"
        type="primary"
      >
        {buttonTitle}
      </Button>
    </>
  );
});

const SeoScreen = (props, ref) => {
  const refreshKeywords = useCallback(async () => {
    let status = false;
    try {
      let responses = await Promise.all([
        httpService._post(BaseConfig.END_POINT.SEO.UPDATE_KEYWORDS, {
          type: DOCUMENT_TYPE.LATEST_WORK
        }),
        httpService._post(BaseConfig.END_POINT.SEO.UPDATE_KEYWORDS, {
          type: DOCUMENT_TYPE.CASE_STUDIES
        })
      ]);

      responses.forEach(res => {
        const { data: result } = res.data;
        status = result;
      });

      if (status) {
        return openNotification("success", "Update keywords successfully");
      }

      throw new Error("Update failed");
    } catch (e) {
      openNotification("error", "Update keywords failed");
    }
  }, []);

  const refreshSitemap = useCallback(async () => {
    try {
      try {
        const { data: response } = await httpService._post(
          BaseConfig.END_POINT.SEO.UPDATE_SITEMAPS
        );
        const { data: result } = response;

        if (result) {
          openNotification("success", "Update sitemaps successfully");
        }
      } catch (e) {
        openNotification("error", "Update sitemaps failed");
      }
    } catch (e) {}
  }, []);

  return (
    <main role="main" className="page-content seo-screen">
      <Jumbotron>
        <h2 className="sub-heading">SEO</h2>
        <p className="description">
          Force update metadata keywords and sitemaps
        </p>
        <div className="divider" />

        <Container>
          <Row>
            <Col md="6">
              <UpdateButton
                onSubmit={refreshKeywords}
                title="Update cache keywords"
              />
            </Col>
            <Col className="mt-md-0 mt-3" md="6">
              <UpdateButton onSubmit={refreshSitemap} title="Update sitemaps" />
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </main>
  );
};

export default memo(forwardRef(SeoScreen));
