import {
    SEND_MESSAGE_FAILED,
    SEND_MESSAGE_SUCCESS,
    SEND_MESSAGE_REQUEST,
    GET_MESSAGE_REQUEST,
    GET_MESSAGE_FAILED,
    GET_MESSAGE_SUCCESS,
    UPLOAD_FILE_AND_LINK_REQUEST,
    UPLOAD_FILE_AND_LINK_SUCCESS,
    UPLOAD_FILE_AND_LINK_FAILED,
    RESET_MESSAGE
} from '../../actions/typeAction';

const defaultState = {
    dataSuccess : {},
    dataError: {},
    isLoading: false,
    isError: false,
    isSuccess: false
};

const messageReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_MESSAGE_REQUEST:
        case SEND_MESSAGE_REQUEST:
        case UPLOAD_FILE_AND_LINK_REQUEST: {
			return {
                ...state,
                dataSuccess : {},
                dataError: {},
                isLoading: true,
                isError: false,
                isSuccess: false
			};
		}
		case GET_MESSAGE_SUCCESS:
        case SEND_MESSAGE_SUCCESS:
        case UPLOAD_FILE_AND_LINK_SUCCESS: {
			return {
				...state,
				dataSuccess : action.payload,
                dataError: {},
                isLoading: false,
                isError: false,
                isSuccess: true
			};
		}
		case GET_MESSAGE_FAILED:
        case SEND_MESSAGE_FAILED:
        case UPLOAD_FILE_AND_LINK_FAILED: {
			return {
				...state,
				dataSuccess : {},
                dataError: action.payload,
                isLoading: false,
                isError: true,
                isSuccess: false
			};
        }
        case RESET_MESSAGE: {
			return {
				...state,
				dataSuccess : {},
                dataError: {},
                isLoading: false,
                isError: false,
                isSuccess: false
			};
		}
		default:
			return state;
	}
};

export default messageReducer;
