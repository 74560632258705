import React, { PureComponent } from 'react';
import './topicActivities.scss';
import { Form, List, Button, Icon } from 'antd';
import { ACTIVITY_TOPIC_FIELDS } from 'src/core/constants/constants';
import { getTopicActivities } from 'src/core/services/modules/topicsService';
import UserAvatar from 'src/components/common/userAvatar/index.js';

import moment from 'moment';

class TopicActivities extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      docs: [],
      page: 1,
      pages: 1,
      isLoading: false,
    };
  }

  renderChanged = item => {
    let { oldData, newData, oldParticipant, newParticipant, action, field } = item;
    if (action === 'added') {
      if (field === 'topic') {
        return newData;
      }
      if (field === 'participant') {
        const { fullName } = newParticipant;
        return fullName;
      }
    }
    if (action === 'removed') {
      if (field === 'participant') {
        const { fullName } = oldParticipant;
        return fullName;
      }
    }
    if (action === 'changed') {
      if (field === 'status') {
        oldData = oldData === 'true' ? 'OPEN' : 'CLOSED';
        newData = newData === 'true' ? 'OPEN' : 'CLOSED';
      }
    }
    return (
      <div>
        <span>{oldData}</span>
        <Icon className="arrow-change" type="arrow-right" />
        <span className="new-data">{newData}</span>
      </div>
    );
  };

  renderFieldText = item => {
    const fieldIndex = ACTIVITY_TOPIC_FIELDS.findIndex(el => el.field === item.field);
    return fieldIndex > -1 ? ACTIVITY_TOPIC_FIELDS[fieldIndex].text : item.field;
  };

  renderFields = item => {
    const { createdBy, action, createdAt } = item;
    const { fullName, avt } = createdBy || {};
    return (
      <div className="activities-container">
        <div className="mr-2">
          <UserAvatar key={item._id} fullName={fullName} avatar={avt} status={true} />
        </div>
        <div className="activities-content">
          <div className="content-title">
            <div>
              <span className="activities-title">{fullName}</span> {action}{' '}
              <span className="activities-title">{this.renderFieldText(item)}</span>
            </div>
            <div className="created-date">
              {moment(createdAt).format('YYYY/MM/DD HH:mm')}
            </div>
          </div>

          {this.renderChanged(item)}
        </div>
      </div>
    );
  };

  activitiesRender = () => {
    const { docs, page, pages } = this.state;
    const { isLoading } = this.props;
    return (
      <>
        <List dataSource={docs} renderItem={item => this.renderFields(item)}></List>
        {page < pages ? (
          <Button
            className="btn-load-more btn-primary-0"
            type="primary"
            loading={isLoading}
            onClick={this.handleLoadMore}
          >
            Load more
          </Button>
        ) : null}
      </>
    );
  };

  handleLoadMore = () => {
    const { page } = this.state;
    const params = {
      page: page + 1,
    };
    this.getTopicActivities(params);
  };

  componentDidMount() {
    const params = {
      page: 1,
    };
    this.getTopicActivities(params);
  }

  getTopicActivities = async params => {
    const { currentTopic } = this.props;
    const { docs: currentDocs } = this.state;
    this.setState({
      isLoading: true,
    });
    const res = await getTopicActivities(currentTopic._id, params);
    if (res) {
      this.setState({
        isLoading: false,
      });
    }
    const { data } = res || {};
    const { data: activities } = data || {};
    const { docs, page, pages } = activities || {};
    this.setState({
      docs: [...currentDocs, ...docs],
      page,
      pages,
    });
  };

  render() {
    return this.activitiesRender();
  }
}

const WrappedTopicActivities = Form.create({ name: 'normal_activity' })(TopicActivities);

export default WrappedTopicActivities;
