import {
	GET_CASE_STUDIES_REQUEST,
	CREATE_CASE_STUDIES_REQUEST,
	UPDATE_CASE_STUDIES_REQUEST,
	UPDATE_ORDER_CASE_STUDIES_SUCCESS
} from '../typeAction';

export const getCaseStudies = () => ({
	type: GET_CASE_STUDIES_REQUEST
});

export const createCaseStudies = data => ({
	type: CREATE_CASE_STUDIES_REQUEST,
	payload: data
});

export const updateCaseStudies = ({ data, id }) => ({
	type: UPDATE_CASE_STUDIES_REQUEST,
	payload: { data, id }
});

export const updateOrder = data => ({
	type: UPDATE_ORDER_CASE_STUDIES_SUCCESS,
	payload: data
});
