import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { List, Spin, Icon, Input } from 'antd';
import debounce from 'lodash/debounce';
import './teams.scss';
import {
	getTeams,
	loadMoreTeams
} from '../../../../../redux/actions/admin/teamsAction';
import InfiniteScroll from 'react-infinite-scroller';

class TeamsList extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			title: 'Admin Teams - VidOps',
			currentPage: 1,
			currentLimit: '',
			selectedIds: [],
			searchText: ''
		};
		this.searchTeamsHandler = debounce(value => {
			this.setState({
				currentPage: 1,
				searchText: value.trim()
			});
			this.props.onGetTeams({
				page: 1,
				searchText: value.trim()
			});
		}, 600);
	}

	handleInfiniteOnLoad = () => {
		const { teams } = this.props;
		const { pages } = teams;
		const { currentPage } = this.state;
		if (currentPage < pages) {
			this.setState(
				prevState => ({
					currentPage: prevState.currentPage + 1
				}),
				() => {
					this.props.onLoadMoreTeams({
						page: this.state.currentPage,
						limit: this.state.currentLimit,
						searchText: this.state.searchText
					});
				}
			);
		}
	};

	selectItem = id => {
		const { selectedIds } = this.state;
		const nextChoosenIds = selectedIds.includes(id)
			? selectedIds.filter(item => item !== id)
			: [...selectedIds, id];
		this.setState({
			selectedIds: nextChoosenIds
		});
	};

	componentDidMount() {
		this.props.onGetTeams({
			page: this.state.currentPage,
			limit: this.state.currentLimit
		});
	}

	render() {
		const {
			teams,
			isLoading,
			selectedKeys,
			setSelectedKeys,
			confirm,
			clearFilters
		} = this.props;
		const { docs, total } = teams;
		const { selectedIds } = this.state;
		return (
			<div className="customers-filter-container">
				<div className="input-container">
					<Input
						ref={node => {
							this.searchInput = node;
						}}
						placeholder={`Search Team`}
						value={selectedKeys[0]}
						onChange={e => {
							setSelectedKeys(
								e.target.value ? [e.target.value] : []
							);
							this.searchTeamsHandler(e.target.value);
						}}
						style={{ display: 'block' }}
					/>
				</div>
				<div className="infinite-container">
					<InfiniteScroll
						initialLoad={false}
						pageStart={0}
						loadMore={this.handleInfiniteOnLoad}
						hasMore={!isLoading && docs && docs.length < total}
						useWindow={false}
					>
						<List
							dataSource={docs}
							renderItem={item => (
								<List.Item
									key={item._id}
									onClick={() => this.selectItem(item._id)}
								>
									<List.Item.Meta title={item.name} />
									{selectedIds &&
									selectedIds.includes(item._id) ? (
										<Icon
											className="icon-check"
											type="check"
										/>
									) : null}
								</List.Item>
							)}
						>
							{isLoading && (
								<div className="loading-container">
									<Spin />
								</div>
							)}
						</List>
					</InfiniteScroll>
				</div>
				<div className="btn-filter-container">
					<span
						onClick={() => {
							const { selectedIds } = this.state;
							setSelectedKeys(selectedKeys ? [selectedKeys] : []);
							confirm();
							this.props.onFilterCustomerByTeam(selectedIds);
						}}
					>
						Ok
					</span>
					<span
						onClick={() => {
							clearFilters();
							confirm();
							this.setState({
								selectedIds: [],
								searchText: '',
								currentPage: 1
							});
							this.props.onResetFilter();
							this.props.onGetTeams({
								page: 1,
								limit: '',
								searchText: ''
							});
						}}
					>
						Reset
					</span>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		teams: state.teams.data,
		isLoading: state.teams.isLoading
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetTeams: params => {
			dispatch(getTeams(params));
		},
		onLoadMoreTeams: params => {
			dispatch(loadMoreTeams(params));
		}
	};
};

export default withTranslation('common')(
	connect(mapStateToProps, mapDispatchToProps)(TeamsList)
);
