import React from 'react';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import ReactPhoneInput from 'react-phone-input-2';
import { updateProjects } from '../../../../../redux/actions/admin/projectsAction';
import './onsiteContactInformation.scss';

const { TextArea } = Input;

class OnsiteContactInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onUpdate = (property, value) => {
    const { currentProject } = this.props;
    const valueData = typeof value === 'string' ? value.trim() : value;
    this.props.onUpdateProject({
      id: currentProject._id,
      [property]: valueData,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { disabled, currentProject, formData, onSiteContactPhoneNumber, hasAssigned } = this.props;
    const contact = (currentProject && currentProject.pointOfContact) || (formData && { ...formData }) || null;
    const fullName = (contact && contact.fullName) || '';
    const email = (contact && contact.email) || '';
    const phoneNumber = (contact && contact.phoneNumber) || '';
    const note = (contact && contact.note) || '';
    if (currentProject || formData) {
      return (
        <div className="information-section">
          <h6 className="information-title">On-site Contact Information</h6>
          <Form.Item label="Name" className="project-form-item">
            {getFieldDecorator('onsiteName', {
              initialValue: fullName,
            })(
              <Input
                onBlur={e => {
                  if ((fullName || '') !== e.target.value) {
                    this.onUpdate('fullName', e.target.value);
                  }
                }}
                onPressEnter={e => this.onUpdate('fullName', e.target.value)}
                placeholder="Name"
                size="large"
                disabled={disabled || !hasAssigned || currentProject.isDelete}
              />
            )}
          </Form.Item>
          <Form.Item label="Email" className="project-form-item">
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'E-mail is invalid !',
                },
              ],
              initialValue: email,
            })(
              <Input
                onBlur={e => {
                  if ((email || '') !== e.target.value) {
                    this.onUpdate('email', e.target.value);
                  }
                }}
                onPressEnter={e => this.onUpdate('email', e.target.value)}
                placeholder="Email"
                size="large"
                disabled={disabled || !hasAssigned || currentProject.isDelete}
              />
            )}
          </Form.Item>
          <Form.Item label="Phone" className="project-form-item">
            {phoneNumber ? (
              <ReactPhoneInput
                country={'gb'}
                onBlur={e => {
                  if ((phoneNumber || '') !== e.target.value) {
                    this.onUpdate('phoneNumber', e.target.value);
                  }
                }}
                value={phoneNumber}
                disabled={disabled || !hasAssigned || currentProject.isDelete}
              />
            ) : (
              <ReactPhoneInput
                country={'gb'}
                onBlur={e => {
                  if ((phoneNumber || '') !== e.target.value) {
                    this.onUpdate('phoneNumber', e.target.value);
                  }
                }}
                disabled={disabled || !hasAssigned || currentProject.isDelete}
              />
            )}
          </Form.Item>
          <Form.Item label="Note" className="project-form-item">
            {getFieldDecorator('note', {
              initialValue: note,
            })(
              <TextArea
                rows={4}
                disabled={disabled || !hasAssigned || currentProject.isDelete}
                onBlur={e => {
                  if ((note || '') !== e.target.value) {
                    this.onUpdate('note', e.target.value);
                  }
                }}
              />
            )}
          </Form.Item>
        </div>
      );
    }
    return (
      <div className="information-section">
        <strong>On-site Contact Information</strong>
        <Form.Item label="Name" className="project-form-item">
          {getFieldDecorator(
            'onSiteContactFullName',
            {}
          )(
            <Input
              onBlur={e => {
                this.props.onInputFields('onSiteContactFullName', e.target.value);
              }}
              onPressEnter={e =>
                this.props.onInputFields('onSiteContactFullName', e.target.value)
              }
              disabled={disabled}
              placeholder="Name"
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item label="Email" className="project-form-item">
          {getFieldDecorator('onSiteContactEmail', {
            rules: [
              {
                type: 'email',
                message: 'E-mail is invalid !',
              },
            ],
          })(
            <Input
              onBlur={e => {
                this.props.onInputFields('onSiteContactEmail', e.target.value);
              }}
              onPressEnter={e =>
                this.props.onInputFields('onSiteContactEmail', e.target.value)
              }
              disabled={disabled}
              placeholder="Email"
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item label="Phone" className="project-form-item">
          <ReactPhoneInput
            country={'gb'}
            onBlur={e => {
              this.props.onInputFields('onSiteContactPhoneNumber', e.target.value);
            }}
            value={onSiteContactPhoneNumber}
            disabled={disabled}
          />
        </Form.Item>
        <Form.Item label="Note" className="project-form-item">
          {getFieldDecorator(
            'onSiteContactNote',
            {}
          )(
            <TextArea
              rows={4}
              onBlur={e => {
                this.props.onInputFields('onSiteContactNote', e.target.value);
              }}
              disabled={disabled}
            />
          )}
        </Form.Item>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateProject: data => {
      dispatch(updateProjects(data));
    },
  };
};

const WrappedOnsiteContactInformation = Form.create({
  name: 'normal_onsite_contact_information',
})(connect(mapStateToProps, mapDispatchToProps)(OnsiteContactInformation));

export default WrappedOnsiteContactInformation;
