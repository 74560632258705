import {
	GET_HOWITWORK_REQUEST,
	DELETE_HOWITWORK_REQUEST,
	CREATE_HOWITWORK_REQUEST,
	UPDATE_HOWITWORK_REQUEST,
	UPDATE_ORDER_HOWITWORK_SUCCESS
} from '../typeAction';

export const getHowitwork = () => ({
	type: GET_HOWITWORK_REQUEST
});

export const deleteHowitwork = key => ({
	type: DELETE_HOWITWORK_REQUEST,
	payload: key
});

export const createHowitwork = data => ({
	type: CREATE_HOWITWORK_REQUEST,
	payload: data
});

export const updateHowitwork = ({ data, id }) => ({
	type: UPDATE_HOWITWORK_REQUEST,
	payload: { data, id }
});

export const updateOrder = data => ({
	type: UPDATE_ORDER_HOWITWORK_SUCCESS,
	payload: data
});
