import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { getTeamDetail, getTeams } from 'src/redux/actions/admin/teamsAction';
import TeamsScreen from 'src/components/screens/admin/teams/teamsScreen';

const mapStateToProps = state => {
  return {
    success: state.teams.success,
    error: state.teams.error,
    isLoading: state.teams.isLoading,
    data: state.teams.data,
    // detailData: state.teams.detailData,
    // chosenTeams: state.teams.chosenTeams,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllTeams: params => {
      dispatch(getTeams(params));
    },
    onGetTeamDetail: id => {
      dispatch(getTeamDetail(id));
    },
    // onClearCustomerDetail: () => {
    //   dispatch(clearCustomerDetail());
    // },
    // onGetCurrentUser: () => {
    //   dispatch(getCurrentUser());
    // },
  };
};

const TeamsContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(TeamsScreen);

export default TeamsContainer;
