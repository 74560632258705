import { connect } from 'react-redux';
import AdminsScreen from '../../../components/screens/admin/admins/adminsScreen';
import {
  getAdmins,
  updateAdmin,
  registerAdmin,
} from '../../../redux/actions/admin/adminsAction';
import { getRoles } from '../../../redux/actions/admin/rolesAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';

const mapStateToProps = state => {
  return {
    success: state.admins.success,
    error: state.admins.error,
    isLoading: state.admins.isLoading,
    admins: state.admins.data,
    isGettingDetail: state.admins.isGettingDetail,
    adminDetail: state.admins.detailData,
    isSendingInvitation: state.admins.isSendingInvitation,
    hasSentInvitation: state.admins.hasSentInvitation,
    roles: state.roles.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetRoles: () => {
      dispatch(getRoles());
    },
    onGetAdmins: params => {
      dispatch(getAdmins(params));
    },
    onUpdateAdmin: data => {
      dispatch(updateAdmin(data));
    },
    onRegisterAdmin: data => {
      dispatch(registerAdmin(data));
    },
    onGetCurrentUser: () => {
      dispatch(getCurrentUser());
    },
  };
};

const AdminsContainer = connect(mapStateToProps, mapDispatchToProps)(AdminsScreen);

export default AdminsContainer;
