import React from 'react';
import { Tag, Input, Tooltip } from 'antd';
import './editableTagGroup.css';

export default class EditableTagGroup extends React.Component {
    state = {
        inputVisible: false,
        inputValue: '',
    };

    handleClose = (removedTag) => {
        const tags = this.props.tags.filter((tag) => tag !== removedTag);
        this.props.onHandleTags(tags);
    };

    showInput = () => {
        this.setState({ inputVisible: true }, () => this.input.focus());
    };

    handleInputChange = (e) => {
        this.setState({ inputValue: e.target.value });
    };

    handleInputConfirm = () => {
        const { inputValue } = this.state;
        let { tags } = this.props;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags = [...tags, inputValue];
        }
        this.setState({
            inputVisible: false,
            inputValue: '',
        });
        this.props.onHandleTags(tags);
    };

    saveInputRef = (input) => (this.input = input);

    render() {
        const { tags } = this.props;
        const { inputVisible, inputValue } = this.state;
        return (
            <div className="edid-tag-group">
                {tags &&
                    tags.map((tag, index) => {
                        const isLongTag = tag.length > 20;
                        const tagElem = (
                            <Tag
                                key={tag}
                                closable={true}
                                onClose={() => this.handleClose(tag)}
                            >
                                <span>
                                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                </span>
                            </Tag>
                        );
                        return isLongTag ? (
                            <Tooltip title={tag} key={tag}>
                                {tagElem}
                            </Tooltip>
                        ) : (
                            tagElem
                        );
                    })}
                {inputVisible && (
                    <Input
                        ref={this.saveInputRef}
                        type="text"
                        size="large"
                        style={{ width: 100 }}
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                    />
                )}
                {!inputVisible && (
                    <Tag className="site-tag-plus" onClick={this.showInput}>
                        + New Tag
                    </Tag>
                )}
            </div>
        );
    }
}
