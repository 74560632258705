import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const getOurTeamMembersService = () => {
	const url = BaseConfig.END_POINT.ADMIN.OUR_TEAM_MEMBERS;
	const response = httpService._get(url);
	return response;
};

export const createOurTeamMembersService = data => {
	const url = BaseConfig.END_POINT.ADMIN.OUR_TEAM_MEMBERS;
	const response = httpService._post(url, data);
	return response;
};

export const updateOurTeamMembersService = ({ data, id }) => {
	const url = BaseConfig.END_POINT.ADMIN.OUR_TEAM_MEMBERS;
	const response = httpService._put(`${url}/${id}`, data);
	return response;
};
