import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const sendEmailService = data => {
  const url = BaseConfig.END_POINT.AUTH.FORGOT_PASSWORD;
  const response = httpService._post(url, data);
  return response;
};

export const resetPasswordService = password => {
  const url = BaseConfig.END_POINT.AUTH.RESET_PASSWORD;
  const response = httpService._post(url, password);
  return response;
};
