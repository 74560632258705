import queryString from 'query-string';
import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const getEditorsService = params => {
  const queryParams = queryString.stringify(params);
  const url = BaseConfig.END_POINT.ADMIN.EDITORS;
  const response = httpService._get(`${url}?${queryParams}`);
  return response;
};

export const getEditorsDetailService = id => {
  const url = BaseConfig.END_POINT.ADMIN.EDITORS;
  const response = httpService._get(`${url}/${id}`);
  return response;
};

export const searchEditorsService = data => {
  const url = BaseConfig.END_POINT.ADMIN.EDITORS;
  const response = httpService._get(
    `${url}?status=${data.status}&search=${data.searchText}`
  );
  return response;
};

export const updateEditorService = ({ id, ...rest }) => {
  const url = BaseConfig.END_POINT.ADMIN.EDITORS;
  const response = httpService._put(`${url}/${id}`, rest);
  return response;
};

export const sendInvitationService = data => {
  const url = BaseConfig.END_POINT.ADMIN.EDITORS;
  const response = httpService._post(`${url}_register`, data);
  return response;
};

export const resendInvitationService = data => {
  const url = BaseConfig.END_POINT.ADMIN.EDITORS;
  const response = httpService._post(`${url}_resend`, data);
  return response;
};
