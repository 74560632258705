export const CUSTOMER_REGISTER = 'CUSTOMER_REGISTER';
export const CREATIVE_REGISTER = 'CREATIVE_REGISTER';
export const ADMIN_REGISTER = 'ADMIN_REGISTER';
export const EDITOR_REGISTER = 'EDITOR_REGISTER';

export const NOTIFICATION_CONTENT_TYPE = {
  UPDATED_PRODUCTION_DATE: 'update_production_date',
  UPDATED_CUSTOMER_INFO: 'update_customer_info',
  UPDATED_ONSITE_CONTACT: 'update_onsite_contact',
  UPDATED_PROJECT_INFO: 'update_project_info',
  PRE_PROJECTS_TO_PRE_PRODUCTION: 'pre_project_to_pre_production',
  PRE_PRODUCTION_TO_PRODUCTION: 'pre_production_to_production',
  PRODUCTION_TO_POST_PRODUCTION: 'production_to_post_production',
  POST_PRODUCTION_TO_CLOSE: 'post_production_closed',
  TOPIC: 'create_topic',
  MESSAGE: 'send_message',
  REQUEST_JOIN_TEAM: 'request_to_join_team',
  ACCEPT_REQUEST_JOIN_TEAM: 'accept_request_to_join_team',
  REJECT_REQUEST_JOIN_TEAM: 'reject_request_to_join_team',
  MEMBER_JOIN_TEAM: 'member_join_team',
  CHANGE_ADMIN_TEAM: 'change_admin_team',
  PROJECT_INVITATION: 'project_invitation',
  ADD_PARTICIPANT: 'add participant',
  REMOVE_PARTICIPANT: 'remove participant',
  CHANGE_PROJECT_STATUS: 'change status',
  CHANGE_PROJECT_TITLE: 'change title',
};

export const ORDER_KEYS = {
  HOW_IT_WORK: 'HOW_IT_WORK',
  CUSTOMER_STORY: 'CUSTOMER_STORY',
  HOUR_RATE: 'HOUR_RATE',
  SKILL: 'SKILL',
  SERVICE_TYPE: 'SERVICE_TYPE',
  BUDGET: 'BUDGET',
  PRICE: 'PRICE',
  CATEGORY: 'CATEGORY',
  ADD_ON: 'ADD_ON',
  PACKAGE: 'PACKAGE',
  MEASURE_UNIT: 'MEASURE_UNIT',
  SUB_CATEGORY: 'SUB_CATEGORY',
  PRODUCT_TYPE: 'PRODUCT_TYPE',
  STORY: 'STORY',
  TEAM: 'TEAM',
  BENEFIT: 'BENEFIT',
  CASE_STUDY: 'CASE_STUDY',
  FAQ: 'FAQ',
};

export const PROJECT_STATUS = [
  {
    text: 'Pre-Project',
    value: 'Pre-Project',
  },
  {
    text: 'Pre-Production',
    value: 'Pre-Production',
  },
  {
    text: 'Production-Ready',
    value: 'Production-Ready',
  },
  {
    text: 'Post-Production',
    value: 'Post-Production',
  },
  {
    text: 'Closed',
    value: 'Closed',
  },
];

export const PROJECT_PRIORITY = ['NORMAL', 'HIGH'];

export const ACTIVITY_FIELDS = [
  {
    field: 'isUrgent',
    text: 'Priority',
  },
  { field: 'title', text: 'Title' },
  {
    field: 'equipments',
    text: 'Equipments',
  },
  {
    field: 'brief',

    text: 'Brief',
  },
  {
    field: 'quickNote',
    text: 'Project Notes',
  },
  {
    field: 'productionDates',
    text: 'Production Dates',
  },
  {
    field: 'inspirationLink',
    text: 'Inspiration Url',
  },
  {
    field: 'email',
    text: 'Email',
  },
  {
    field: 'phoneNumberOfCustomer',
    text: 'Phone Of Customer Information',
  },
  {
    field: 'phoneNumber',
    text: 'Phone number (Point of contact)',
  },
  {
    field: 'status',
    text: 'Status',
  },
  {
    field: 'hotLineOfCustomer',
    text: 'HotLine',
  },
  {
    field: 'address',
    text: 'Location',
  },
  {
    field: 'note',
    text: 'Note (Point of contact)',
  },
  {
    field: 'creative',
    text: 'Creative',
  },
  {
    field: 'editor',
    text: 'Editor',
  },
  {
    field: 'asset',
    text: 'Asset',
  },
  {
    field: 'gallery',
    text: 'Gallery',
  },
  {
    field: 'isPublic',
    text: 'Public',
  },
  {
    field: 'serviceTypeId',
    text: 'Service Type',
  },
  {
    field: 'productTypeId',
    text: 'Product Type',
  },
  {
    field: 'packages',
    text: 'Package',
  },
  {
    field: 'addressNote',
    text: 'Address Note',
  },
  {
    field: 'otherPayment',
    text: 'Other Payment',
  },
  {
    field: 'editorPayment',
    text: 'Editor Payment',
  },
  {
    field: 'customerPayment',
    text: 'Customer Payment',
  },
  {
    field: 'creativePayment',
    text: 'Creative Payment',
  },
  {
    field: 'estimationPrice',
    text: 'Estimation Price',
  },
  {
    field: 'budget',
    text: 'Budget',
  },
  {
    field: 'fullName',
    text: 'Full Name (Point of contact)',
  },
];

export const CUSTOMER_STATUS = [
  {
    label: 'Not verified',
    value: 'not verified',
  },
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
];

export const CREATIVES_STATUS = [
  {
    label: 'Not verified',
    value: 'not verified',
  },
  {
    label: 'Pending Vetting',
    value: 'pending',
  },
  {
    label: 'Rejected',
    value: 'rejected',
  },
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
];

export const POLICIES_TYPE = [
  { label: 'Terms & Conditions', value: 'term' },
  { label: 'Privacy Policy', value: 'privacy' },
  { label: 'Cookie Policy', value: 'cookie' },
  { label: 'Customer Policy', value: 'customer' },
  { label: 'Creative Agreement', value: 'creatives' },
];

export const EDITOR_STATUS = [
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
];

export const EDITOR_TIERS = [
  {
    label: 'Bronze',
    value: 'bronze',
  },
  {
    label: 'Silver',
    value: 'silver',
  },
  {
    label: 'Gold',
    value: 'gold',
  },
];

export const CREATIVES_TIERS = [
  {
    label: 'Bronze',
    value: 'bronze',
  },
  {
    label: 'Silver',
    value: 'silver',
  },
  {
    label: 'Gold',
    value: 'gold',
  },
];

export const ADMIN_ROLES = [
  {
    label: 'Super Admin',
    value: 'superadmin',
  },
  {
    label: 'Admin',
    value: 'admin',
  },
];

export const DOCUMENT_TYPE = {
  LATEST_WORK: 'LATEST_WORK',
  CASE_STUDIES: 'CASE_STUDIES',
};

export const S3_FOLDERS = {
  CMS: {
    LANDING_PAGE: {
      BANNER: 'CMS/LandingPage/Banner',
      TRUSTED_BY: 'CMS/LandingPage/TrustedBy',
    },
    OUR_TEAM: {
      BANNER: 'CMS/OurTeam/Banner',
      MEMBERS: 'CMS/OurTeam/Members',
    },
    STORIES: {
      LATEST_WORK: 'CMS/Stories/LatestWork',
      CASE_STUDY: 'CMS/Stories/CaseStudy',
    },
    SIGN_UP: {
      PRODUCT_TYPE: 'CMS/SignUp/ProductTypes',
    },
    PRICING: {
      SERVICE_TYPE: 'CMS/Pricing/ServiceTypes',
    },
  },
  PROJECT: {
    STORY_BOARD: 'Story_Board',
  },
};

export const ADMIN_STATUS = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Blocked',
    value: 'blocked',
  },
];

export const VIDOPS_LOCATION = {
  lat: 51.52143780000001,
  lng: -0.2031284,
  place_id: 'ChIJeX85xKUcdkgRVLES5giEi78',
  formatted_address: 'Westbourne Studios, 242 Acklam Rd, London W10 5JJ, UK',
  label: 'VidOps | Streamlined Media Creation, Acklam Road, London, UK',
};

export const ACTIVITY_TOPIC_FIELDS = [
  {
    field: 'topic',
    text: 'Topic',
  },
  {
    field: 'participant',
    text: 'Participant',
  },
  {
    field: 'status',
    text: 'Status',
  },
];

export const S3_UPLOAD_TYPE = {
  USER_AVATAR: 'UPLOAD_USER_AVATAR',
};

export const IMAGES_TYPE = ['.jpg', '.jpeg', '.svg', '.png'];
export const VIDEO_TYPES = ['.mp4', '.ogg', '.webm'];
export const VIDEO_STREAM_TYPES = ['.mp4', '.webm'];

export const FILES_TYPE = [
  '.xlsx',
  '.xls',
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
  '.txt',
  '.pdf',
  '.mp4',
  '.gif',
  '.mov',
  '.avi',
  '.flv',
  '.wmv',
  '.mp3',
  '.csv',
  '.ods',
  '.mpg',
  '.mpeg',
  '.pps',
  '.odt',
  '.mkv',
];

export const CKEDITOR = {
  toolbar: [
    'heading',
    'alignment',
    'bold',
    'italic',
    'link',
    'numberedList',
    'bulletedList',
    'imageUpload',
    'blockQuote',
    'insertTable',
    'mediaEmbed'
  ],
  heading: {
    options: [
      {
        model: 'paragraph',
        title: 'Paragraph',
        class: 'ck-heading_paragraph',
      },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Heading 1',
        class: 'ck-heading_heading1',
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2',
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Heading 3',
        class: 'ck-heading_heading3',
      },
      {
        model: 'heading4',
        view: 'h4',
        title: 'Heading 4',
        class: 'ck-heading_heading4',
      },
    ],
  }
};
