import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../../actions/typeAction';
import {
	getBudgetService,
	createBudgetService,
	updateBudgetService
} from '../../../core/services/modules/budgetService';
import { updateOrderService } from '../../../core/services/modules/orderService';
import { openNotification } from '../../../components/common/notification/notification';

export function* getBudgetSaga() {
	const response = yield call(getBudgetService);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_BUDGET_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_BUDGET_SUCCESS,
			payload: data
		});
	}
}

export function* createBudgetSaga(action) {
	const response = yield call(createBudgetService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.CREATE_BUDGET_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.CREATE_BUDGET_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateBudgetSaga(action) {
	const response = yield call(updateBudgetService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_BUDGET_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.UPDATE_BUDGET_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateOrderSaga(action) {
	const response = yield call(updateOrderService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_ORDER_BUDGET_FAILED,
			payload: response.data.error
		});
	} else {
		openNotification('success', 'Update successful');
	}
}

export function* watchBudget() {
	yield takeLatest(actionTypes.GET_BUDGET_REQUEST, getBudgetSaga);
	yield takeLatest(actionTypes.CREATE_BUDGET_REQUEST, createBudgetSaga);
	yield takeLatest(actionTypes.UPDATE_BUDGET_REQUEST, updateBudgetSaga);
	yield takeLatest(actionTypes.UPDATE_ORDER_BUDGET_SUCCESS, updateOrderSaga);
}
