import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import renderHTML from 'react-render-html';
import { Collapse, Button, Modal } from 'antd';
import {
  getBriefHistoryProjects,
  updateProjects,
} from 'src/redux/actions/admin/projectsAction';
import { BriefEditor } from '../../projectCreateForm/briefsForm';
import BriefHistoryModal from './BriefHistoryModal';

const renderHTMLContents = contents => {
  if (!contents || !contents.length) {
    return '';
  }

  let contentsReplace = '';
  contentsReplace = contents.replace(
    /<a/g,
    `<a target="_blank" rel="noopener noreferrer"`
  );

  contentsReplace = contentsReplace.replace(/href="(https?:\/\/)?/g, `href="//`);

  return renderHTML(contentsReplace);
};

function BriefModal(props) {
  const { item, title, visible, onClose } = props;
  const [fieldData, setFieldData] = useState((item && item.fieldData) || null);

  return (
    <Modal
      className="brief-modal"
      title={'Project brief: ' + title}
      visible={visible}
      width="90%"
      onOk={() => onClose(fieldData)}
      onCancel={onClose}
    >
      <BriefEditor
        fieldData={fieldData}
        onInputFields={(_fieldName, updatedFieldData) => {
          setFieldData(updatedFieldData);
        }}
      />
    </Modal>
  );
}

const { Panel } = Collapse;

export default function ProjectBriefs(props) {
  const { brief, briefSchedule, briefShotlist, briefScript, briefTechnical } =
    props.project;
  const dispatch = useDispatch();

  const [editItem, setEditItem] = useState(null);
  const [showHistory, setShowHistory] = useState(false);

  const editButton = props => (
    <div>
      <Button
        type="link"
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          handleGetHistories(props.fieldName);
        }}
      >
        History
      </Button>
      <Button
        type="link"
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          setEditItem(props);
        }}
      >
        Edit
      </Button>
    </div>
  );

  const handleGetHistories = fieldName => {
    dispatch(
      getBriefHistoryProjects({
        projectId: props.project._id,
        briefType: fieldName,
      })
    );
    setTimeout(() => setShowHistory(true), 1000);
  };

  const handleClose = updateData => {
    if (!updateData || typeof updateData !== 'string') {
      setEditItem(null);
      return;
    }

    const { fieldName } = editItem;
    dispatch(
      updateProjects({
        id: props.project._id,
        [fieldName]: updateData,
      })
    );
    setEditItem(null);
  };

  return (
    <>
      <Collapse defaultActiveKey={['0']} expandIconPosition="left">
        <Panel
          header="Vision (Legacy Brief)"
          key="0"
          extra={editButton({
            title: 'Vision (Legacy Brief)',
            fieldData: brief,
            fieldName: 'brief',
          })}
        >
          {renderHTMLContents(brief) || <em>TBD</em>}
        </Panel>
        <Panel
          header="Schedule"
          key="1"
          extra={editButton({
            title: 'Schedule',
            fieldData: briefSchedule,
            fieldName: 'briefSchedule',
          })}
        >
          {renderHTMLContents(briefSchedule) || <em>TBD</em>}
        </Panel>
        <Panel
          header="Shotlist"
          key="2"
          extra={editButton({
            title: 'Shotlist',
            fieldData: briefShotlist,
            fieldName: 'briefShotlist',
          })}
        >
          {renderHTMLContents(briefShotlist) || <em>TBD</em>}
        </Panel>
        <Panel
          header="Script & interview questions"
          key="3"
          extra={editButton({
            title: 'Script & interview questions',
            fieldData: briefScript,
            fieldName: 'briefScript',
          })}
        >
          {renderHTMLContents(briefScript) || <em>TBD</em>}
        </Panel>
        <Panel
          header="Technical settings"
          key="4"
          extra={editButton({
            title: 'Technical settings',
            fieldData: briefTechnical,
            fieldName: 'briefTechnical',
          })}
        >
          {renderHTMLContents(briefTechnical) || <em>TBD</em>}
        </Panel>
      </Collapse>
      {!!editItem && (
        <BriefModal
          visible={!!editItem}
          item={editItem}
          title={editItem ? editItem.title : 'Brief'}
          onClose={handleClose}
        />
      )}
      {props.briefHistories && (
        <BriefHistoryModal
          customers={props.briefHistories}
          visibleModal={showHistory}
          onCloseModal={() => setShowHistory(false)}
        />
      )}
    </>
  );
}
