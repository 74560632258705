import { Modal } from 'antd';
const { confirm } = Modal;

const confirmModal = ({ onOk, onCancel }) => {
  confirm({
    title: 'Are you sure you want to leave, your file upload is incomplete?',
    content: '',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    okButtonProps: {
      className: 'btn-confirm-ok',
    },
    cancelButtonProps: {
      className: 'btn-confirm-cancel',
    },
    onOk,
    onCancel,
  });
};

export default confirmModal;
