import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { shallowEqual } from 'react-redux';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { Button, Pagination, Drawer, Icon, Tag, Form, Input, Typography } from 'antd';
import { Jumbotron, Container } from 'react-bootstrap';

import DataTable from '../../../common/dragTable/dragTable';
import TeamMembersModal from './Members';
import TeamProjectsModal from './Projects';
import StatusFilter from './statusFilter/statusFilter';
import { joinArr } from 'src/core/utils/commonUtils';
import qs from 'src/core/utils/querystring';
import Teams from './teams/teams';

import './teamsScreen.scss';
import CreateNewTeam from './createNewTeam';
import WrappedCustomerInvitationModal from 'src/components/common/form/projectsDetail/customerInformation/customerInvitationModal/customerInvitationModal';
const { Search } = Input;

class TeamsScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Admin Customers - VidOps',
      currentPage: 1,
      currentLimit: '',
      visibleDrawer: false,
      visibleModal: false,
      hasTeamsListDisplayed: false,
      selectedTeamIds: [],
      selectedStatus: [],
      currentCustomerId: '',
      textSearchTeam: '',
      forceClearFilter: false,
      isSearch: false,
      // create new team
      visibleCreateTeam: false,
      currentTeam: null,
      visibleMembers: false,
      visibleProjects: false,
      visibleInvite: false,
    };
    this.getCustomersListener = null;
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: props => {
      return dataIndex === 'teamName' ? (
        <Teams
          {...props}
          onFilterCustomerByTeam={this.handleFilterByTeam}
          onResetFilter={this.resetFilterByTeam}
        />
      ) : (
        <StatusFilter
          {...props}
          forceClearFilter={this.state.forceClearFilter}
          onFilterCustomerByTeam={this.handleFilterByStatus}
          onResetFilter={this.resetFilterByStatus}
        />
      );
    },
    filterIcon: filtered => {
      return (
        <Icon
          type="filter"
          theme="filled"
          style={{ color: filtered && !this.state.isSearch ? '#1890ff' : undefined }}
        />
      );
    },
  });

  componentDidMount() {
    // this.props.onGetCurrentUser();
    this._getTeams({
      page: this.state.currentPage,
      limit: this.state.currentLimit,
      status: joinArr(this.state.selectedStatus),
    });

    // this.onInitCustomer();
    // this.getCustomersListener = getCustomers(() =>
    //   this.onChangePage(this.state.currentPage)
    // );
  }

  componentWillUnmount() {
    if (this.getCustomersListener) {
      this.getCustomersListener();
    }
  }

  _getTeams = params => {
    this.props.onGetAllTeams(params);
  };

  onInitCustomer() {
    const location = this.props.location;
    const search = location.search || '';
    const queryString = search.replace('?', '');
    const queryData = qs.parse(queryString);
    const { customerId } = queryData || {};

    if (!customerId) return null;

    this.setState({
      currentCustomerId: customerId,
      visibleDrawer: true,
    });
  }

  componentDidUpdate(prevProps) {
    if (!shallowEqual(this.props.location, prevProps.location)) {
      this.onInitCustomer();
    }

    // if (this.props.match.isExact) {
    //   this.props.onClearCustomerDetail();
    // }
  }

  formatColumns = () => {
    return [
      {
        title: 'Team',
        dataIndex: 'name',
        width: 240,
      },
      {
        title: 'Company',
        dataIndex: 'customerUser.company',
      },
      {
        title: 'Business Type',
        dataIndex: 'businesstypes.name',
      },
      {
        title: 'Members',
        dataIndex: 'numbers',
        render: (text, record) => {
          return (
            <div className="team-id">
              <Typography
                onClick={() => {
                  if (record.members.length === 0) return;

                  this.setState({
                    currentTeam: record,
                    visibleMembers: true,
                  });
                }}
                className="team-name"
                style={{
                  textDecoration: record.members.length > 0 ? 'underline' : 'none',
                }}
              >
                {record.members.length}{' '}
                <span>{record.members.length > 0 ? '(View)' : ''}</span>
              </Typography>
            </div>
          );
        },
      },
      {
        title: 'Projects',
        dataIndex: 'projects',
        render: (text, record) => {
          return (
            <div className="team-id">
              <Typography
                onClick={() => {
                  if (record.projects.length === 0) return;

                  this.setState({
                    currentTeam: record,
                    visibleProjects: true,
                  });
                }}
                className="team-name"
                style={{
                  textDecoration: record.projects.length > 0 ? 'underline' : 'none',
                }}
              >
                {record.projects.length}{' '}
                <span>{record.projects.length > 0 ? '(View)' : ''}</span>
              </Typography>
            </div>
          );
        },
      },
      {
        title: 'Invite',
        dataIndex: 'invite',
        render: (userId, record) => {
          return (
            <Button
              className="btn-primary-0"
              onClick={() => {
                this.setState({
                  currentTeam: record,
                  visibleInvite: true,
                });
              }}
              type="primary"
            >
              Invite
            </Button>
          );
        },
      },
    ];
  };

  onChangePage = page => {
    this.setState(
      {
        currentPage: page,
      },
      () => {
        this._getTeams({
          searchText: this.state.textSearchTeam,
          page: this.state.currentPage,
          limit: this.state.currentLimit,
          status: joinArr(this.state.selectedStatus),
          teams: joinArr(this.state.selectedTeamIds),
        });
      }
    );
  };

  closeDrawer = () => {
    const { path } = this.props.match;
    this.props.history.push({
      pathname: path,
    });
  };

  openInvitationModal = () => this.setState({ visibleModal: true });

  openCreateTeamModal = () => this.setState({ visibleCreateTeam: true });

  onCloseModal = () => this.setState({ visibleModal: false });

  onCloseModalCreateTeam = () => this.setState({ visibleCreateTeam: false });
  handleSearchProject = debounce(async text => {
    const textSearch = text.trim();
    this._getTeams({
      page: 1,
      searchText: textSearch,
      limit: this.state.currentLimit,
      selectedStatus: [],
      status: joinArr(this.state.currentStatus),
    });
    this.setState({ selectedStatus: [] });
    this.setState({ forceClearFilter: true, isSearch: true });
  }, 400);

  formatData = data => {
    if (data && data.length) {
      return data.map((item, index) => {
        return {
          ...item,
          key: item._id,
        };
      });
    }
  };

  render() {
    const { path } = this.props.match;
    const { data } = this.props;

    const { textSearchTeam, currentPage, visibleCreateTeam } = this.state;
    let dataSource = [];
    const columns = this.formatColumns();
    if (data && data.docs) {
      dataSource = this.formatData(data.docs);
    }

    const customerIds = () => {
      if (
        this.state.currentTeam.customerMembers &&
        Array.isArray(this.state.currentTeam.customerMembers)
      ) {
        return this.state.currentTeam.customerMembers.reduce((result, item) => {
          result = { ...result, [item.userId]: item._id };
          return result;
        }, {});
      }
      return [];
    };

    return (
      <main role="main" className="page-content teams-screen">
        <Jumbotron className="mgt-40">
          <h2 className="sub-heading">All Teams</h2>
          <p className="description">Manage all teams</p>
          <div className="divider"></div>
          <Container>
            <Button
              className="btn-primary-0"
              onClick={this.openCreateTeamModal}
              type="primary"
            >
              Add new
            </Button>

            {visibleCreateTeam && (
              <CreateNewTeam
                visibleModal={visibleCreateTeam}
                onCloseModal={this.onCloseModalCreateTeam}
              />
            )}

            {this.state.currentTeam && (
              <TeamMembersModal
                customers={this.state.currentTeam.userMembers}
                visibleModal={this.state.visibleMembers}
                customerIds={customerIds()}
                onCloseModal={() => this.setState({ visibleMembers: false })}
              />
            )}

            {this.state.currentTeam && (
              <TeamProjectsModal
                projects={this.state.currentTeam.projects}
                visibleModal={this.state.visibleProjects}
                onCloseModal={() => this.setState({ visibleProjects: false })}
              />
            )}

            {this.state.currentTeam && (
              <WrappedCustomerInvitationModal
                requirePhone={true}
                modalVisible={this.state.visibleInvite}
                onCloseModal={() => this.setState({ visibleInvite: false })}
                teamId={this.state.currentTeam._id}
              />
            )}

            <div className="control-container">
              <Form>
                <Form.Item>
                  <Search
                    placeholder="search..."
                    allowClear
                    value={textSearchTeam}
                    onChange={e => this.setState({ textSearchTeam: e.target.value })}
                    onSearch={this.handleSearchProject}
                    style={{ width: 300, height: 42 }}
                  />
                </Form.Item>
              </Form>
              {!!data.total && (
                <Pagination
                  className="customer-pagination"
                  total={data.total}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  pageSize={10}
                  simple={false}
                  onChange={this.onChangePage}
                  current={currentPage}
                />
              )}
            </div>
            <DataTable
              columns={columns}
              dataSource={dataSource}
              onChangeHandler={this.handleChangeTable}
              hiddenUpdateOrder={true}
            />
            <Route
              exact
              path={`${path}/:itemId`}
              render={routeProps => (
                <Drawer
                  className="drawer-detail-customer"
                  width={790}
                  placement="right"
                  closable={true}
                  onClose={this.closeDrawer}
                  visible={true}
                  destroyOnClose={true}
                >
                  {/* <CustomersForm
                    {...routeProps}
                    currentCustomerId={currentCustomerId}
                    onClose={this.closeDrawer}
                  /> */}
                </Drawer>
              )}
            />
          </Container>
        </Jumbotron>
      </main>
    );
  }
}

export default withTranslation('common')(TeamsScreen);
