import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../../actions/typeAction';
import {
	updateBannerService,
	getBannerService,
	getReviewsService
} from '../../../core/services/modules/bannerService';

export function* updateBannerSaga(action) {
	const response = yield call(updateBannerService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_BANNER_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.UPDATE_BANNER_SUCCESS,
			payload: response.data
		});
	}
}

export function* getBannerSaga() {
	const response = yield call(getBannerService);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_BANNER_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_BANNER_SUCCESS,
			payload: data
		});
	}
}

export function* getReviewsSaga() {
	const response = yield call(getReviewsService);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_REVIEWS_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_REVIEWS_SUCCESS,
			payload: data
		});
	}
}

export function* watchBanner() {
	yield takeLatest(actionTypes.UPDATE_BANNER_REQUEST, updateBannerSaga);
	yield takeLatest(actionTypes.GET_BANNER_REQUEST, getBannerSaga);
	yield takeLatest(actionTypes.GET_REVIEWS_REQUEST, getReviewsSaga);
}
