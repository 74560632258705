import React from 'react';
import moment from 'moment/moment';
import { Icon, List, Row, Skeleton } from 'antd';
import {
  getListAssets,
  getListProjectByTeam,
  getListShareProjectByAccount,
  getListTeams,
  verifyTokenFrameIO,
} from 'src/core/services/modules/frameIOService';
import iFiles from 'src/assets/images/icons/extentions/files.svg';
import iFolder from 'src/assets/images/icons/extentions/folder.svg';
import { getListAssetsHandler } from 'src/core/utils/event_emitter/handler';

import './assetFromV2.scss';

class AssetFormV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      projectAlias: {},
      listAssets: [],
      listTeams: [],
      totalAssets: 0,
      teamId: '',
      info: {},
      rootAssetId: '',
      assetLink: {
        parent: [],
        children: '',
      },
    };
  }

  async componentDidMount() {
    getListAssetsHandler(this.getListAsset);
    const { currentProject } = this.props;
    await this.getListAsset(currentProject.alias);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.assetLink.children !== this.state.assetLink.children) {
      const listAssets = await getListAssets(this.state.assetLink.children, true);
      this.setState({
        listAssets,
        isLoading: false,
      });
    }
  }

  getListAsset = async alias => {
    this.setState({
      isLoading: true,
    });
    const { info, teamId } = await verifyTokenFrameIO();
    const listTeams = await getListTeams();
    this.setState({
      info,
      teamId,
      listTeams: listTeams.map(team => {
        return {
          ...team,
          selected: team.owner_id === info.id,
          isOwner: team.owner_id === info.id,
        };
      }),
    });
    if (alias) {
      // Get list asset by project alias
      const projectAlias = await getListProjectByTeam(teamId, alias);
      if (projectAlias) {
        const listAssets = await getListAssets(projectAlias.root_asset.id, true);
        this.setState({
          listAssets,
          projectAlias,
          rootAssetId: projectAlias.root_asset.id,
        });
      } else {
        this.setState({
          listAssets: [],
        });
      }
    }
    this.setState({
      isLoading: false,
    });
  };

  getChildrenAsset = item => {
    if (item.type === 'file') {
      window.open(
        `https://app.frame.io/player/${item.id}`,
        '_blank',
        'toolbar=yes,scrollbars=yes,resizable=yes,top=200,left=200,width=1200,height=600'
      );
    } else {
      this.setState({
        isLoading: true,
        assetLink: {
          parent: [item.id, ...this.state.assetLink.parent],
          children: item.id,
        },
      });
    }
  };

  goBack = () => {
    this.setState({
      isLoading: true,
      listAssets: [],
      assetLink: {
        parent: [...this.state.assetLink.parent].filter(
          e => e !== this.state.assetLink.children
        ),
        children:
          this.state.assetLink.parent.length === 1
            ? this.state.rootAssetId
            : [...this.state.assetLink.parent].filter(
                e => e !== this.state.assetLink.children
              )[0],
      },
    });
  };

  goTeam = async item => {
    this.setState({
      isLoading: true,
    });
    const { currentProject } = this.props;
    const { listTeams, teamId } = this.state;
    const { alias } = currentProject;
    this.setState({
      listTeams: listTeams.map(team => {
        return {
          ...team,
          selected: item.id === team.id,
        };
      }),
    });

    if (alias) {
      const projectAlias = item.isOwner
        ? await getListProjectByTeam(teamId, alias)
        : await getListShareProjectByAccount(item.id, alias);
      if (projectAlias) {
        const listAssets = await getListAssets(projectAlias.root_asset.id, true);
        this.setState({
          listAssets,
          projectAlias,
          rootAssetId: projectAlias.root_asset.id,
        });
      } else {
        this.setState({
          listAssets: [],
        });
      }
    }
    this.setState({
      isLoading: false,
    });
  };
  renderItemAsset = item => {
    const type = item._type;
    return (
      <List.Item className="item-asset" onClick={() => this.getChildrenAsset(item)}>
        <Skeleton avatar title={false} loading={this.state.isLoading} active>
          <List.Item.Meta
            avatar={
              <img
                width="24px"
                height="24px"
                src={type === 'folder' ? iFolder : iFiles}
                alt="icon"
              />
            }
            title={item.name}
          />
          <div>{moment(item.updated_at).format('MMM DD, YYYY')}</div>
        </Skeleton>
      </List.Item>
    );
  };

  renderTeam = item => {
    const { info } = this.state;
    return (
      <List.Item className="item-team" onClick={() => this.goTeam(item)}>
        <Skeleton title={false} loading={this.state.isLoading} active>
          <div style={{ display: 'flex', fontWeight: item.selected ? 'bold' : 'normal' }}>
            <div>{item.display_name}</div>
            <div style={{ marginLeft: 2 }}>
              {item.owner_id === info.id ? '- owner' : ''}
            </div>
          </div>
        </Skeleton>
      </List.Item>
    );
  };
  render() {
    const { listAssets, isLoading, assetLink, listTeams } = this.state;
    if (isLoading) return <div>Loading.....</div>;
    return (
      <>
        <div className="asset-form-wrapper">
          {listTeams.length ? (
            <div>
              <div style={{ padding: '12px', fontWeight: 'bold' }}>List teams:</div>
              <Row style={{ marginBottom: 4, cursor: 'pointer' }}>
                <List
                  itemLayout="horizontal"
                  dataSource={listTeams}
                  renderItem={item => this.renderTeam(item)}
                />
              </Row>
            </div>
          ) : null}
        </div>
        <div className="asset-form-wrapper">
          <div style={{ padding: '12px', fontWeight: 'bold' }}>List assets:</div>
          {assetLink.parent.length ? (
            <Row
              style={{ padding: '12px', cursor: 'pointer' }}
              onClick={() => this.goBack()}
            >
              <Icon type="left" style={{ marginRight: 4 }} />
              Back
            </Row>
          ) : null}
          <List
            itemLayout="horizontal"
            dataSource={listAssets}
            renderItem={item => this.renderItemAsset(item)}
          />
        </div>
      </>
    );
  }
}

export default AssetFormV2;
