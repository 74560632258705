import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../../actions/typeAction';
import {
	getHourRateService,
	createHourRateService,
	updateHourRateService
} from '../../../core/services/modules/hourRateService';
import { updateOrderService } from '../../../core/services/modules/orderService';
import { openNotification } from '../../../components/common/notification/notification';

export function* getHourRateSaga() {
	const response = yield call(getHourRateService);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_HOUR_RATE_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_HOUR_RATE_SUCCESS,
			payload: data
		});
	}
}

export function* createHourRateSaga(action) {
	const response = yield call(createHourRateService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.CREATE_HOUR_RATE_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.CREATE_HOUR_RATE_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateHourRateSaga(action) {
	const response = yield call(updateHourRateService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_HOUR_RATE_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.UPDATE_HOUR_RATE_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateOrderSaga(action) {
	const response = yield call(updateOrderService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_ORDER_HOUR_RATE_FAILED,
			payload: response.data.error
		});
	} else {
		openNotification('success', 'Update successful');
	}
}

export function* watchHourRate() {
	yield takeLatest(actionTypes.GET_HOUR_RATE_REQUEST, getHourRateSaga);
	yield takeLatest(actionTypes.CREATE_HOUR_RATE_REQUEST, createHourRateSaga);
	yield takeLatest(actionTypes.UPDATE_HOUR_RATE_REQUEST, updateHourRateSaga);
	yield takeLatest(
		actionTypes.UPDATE_ORDER_HOUR_RATE_SUCCESS,
		updateOrderSaga
	);
}
