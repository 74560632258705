import { connect } from 'react-redux';
import CategoriesScreen from '../../../components/screens/admin/categories/categoriesScreen';
import {
	getCategories,
	createCategories,
	updateCategories,
	updateOrder
} from '../../../redux/actions/admin/categoriesAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';

const mapStateToProps = state => {
	return {
		success: state.categories.success,
		error: state.categories.error,
		isLoading: state.categories.isLoading,
		data: state.categories.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetCategories: () => {
			dispatch(getCategories());
		},
		onCreateCategories: data => {
			dispatch(createCategories(data));
		},
		onUpdateCategories: ({ data, id }) => {
			dispatch(updateCategories({ data, id }));
		},
		onUpdateOrder: data => {
			dispatch(updateOrder(data));
		},
		onGetCurrentUser: () => {
			dispatch(getCurrentUser());
		}
	};
};

const CategoriesContainer = connect(mapStateToProps, mapDispatchToProps)(CategoriesScreen);

export default CategoriesContainer;
