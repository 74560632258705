import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Icon } from 'antd';
import { updateProjects } from '../../../../../redux/actions/admin/projectsAction';
import { validateAlias } from 'src/core/utils/customValidator.js';
import { storageService } from 'src/core/services/storageService.js';
import './projectHeader.scss';
import { sendGetListAssetAction } from 'src/core/utils/event_emitter/handler';
class ProjectHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      user: {},
    };
  }

  componentDidMount() {
    const user = storageService.getCurrentUser();
    this.setState({ user });
  }

  onUpdate = e => {
    const { value } = e.target;
    const { currentProject } = this.props;
    const alias = currentProject && currentProject.alias;

    if (alias !== value && !validateAlias(value)) {
      const { currentProject } = this.props;
      const valueData = typeof value === 'string' ? value.trim() : value;
      this.props.onUpdateProject({
        id: currentProject._id,
        alias: valueData,
      });
      sendGetListAssetAction(valueData);
    }
  };

  render() {
    const { currentProject, hasAssigned } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { errors } = this.state;
    const alias = (currentProject && currentProject.alias) || null;
    // const id = (currentProject && currentProject._id) || null;

    return (
      <div className="project-header-container">
        <Form.Item className="mgr-10">
          <Icon
            type="close"
            className="btn-close-project"
            onClick={this.props.onCloseDrawerClick}
          />
        </Form.Item>
        {currentProject && (
          <>
            {/* <Form.Item>
              <span>{id && id.substring(id.length - 6)}</span>
            </Form.Item> */}
            {/* <Form.Item className="mgl-10">
              <span>/</span>
            </Form.Item> */}
            <Form.Item className="mgl-10">
              {getFieldDecorator('alias', {
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      const msg = validateAlias(value);

                      if (msg) {
                        callback(msg);
                      } else {
                        callback();
                      }
                    },
                  },
                ],
                initialValue: alias || null,
              })(
                <Input
                  className="project-alias"
                  onBlur={this.onUpdate}
                  onPressEnter={this.onUpdate}
                  placeholder="Alias"
                  size="small"
                  maxLength={8}
                  minLength={3}
                  disabled={!hasAssigned}
                />
              )}
              {errors && errors.includes('keyLength') ? (
                <div className="ant-form-explain">Min length is 3 characters</div>
              ) : null}
            </Form.Item>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateProject: data => {
      dispatch(updateProjects(data));
    },
  };
};

const WrappedProjectHeader = Form.create({ name: 'normal_project_header' })(
  connect(mapStateToProps, mapDispatchToProps)(ProjectHeader)
);

export default WrappedProjectHeader;
