import { connect } from 'react-redux';
import ServiceTypeScreen from '../../../components/screens/admin/serviceType/serviceTypeScreen';
import {
	getServiceType,
	createServiceType,
	updateServiceType,
	updateOrder
} from '../../../redux/actions/admin/serviceTypeAction';
import { getProductTypes } from '../../../redux/actions/admin/productTypesAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';

const mapStateToProps = state => {
	return {
		success: state.serviceType.success,
		error: state.serviceType.error,
		isLoading: state.serviceType.isLoading,
		data: state.serviceType.data,
		productTypes: state.productType.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetServiceType: () => {
			dispatch(getServiceType());
		},
		onCreateServiceType: data => {
			dispatch(createServiceType(data));
		},
		onUpdateServiceType: ({ data, id }) => {
			dispatch(updateServiceType({ data, id }));
		},
		onUpdateOrder: data => {
			dispatch(updateOrder(data));
		},
		onGetProductTypes: () => {
			dispatch(getProductTypes());
		},
		onGetCurrentUser: () => {
			dispatch(getCurrentUser());
		}
	};
};

const CustomerStoriesContainer = connect(mapStateToProps, mapDispatchToProps)(ServiceTypeScreen);

export default CustomerStoriesContainer;
