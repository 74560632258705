//import library
import { LOGIN_REQUEST, LOGIN_RESET } from '../typeAction';

export const login = (data) => ({
    type: LOGIN_REQUEST,
    payload: data
});

export const loginReset = () => ({
    type: LOGIN_RESET,
    payload: {}
})