import {
	GET_EDITORS_REQUEST,
	GET_EDITORS_DETAIL_REQUEST,
	SEARCH_EDITORS_REQUEST,
	LOAD_MORE_EDITORS_REQUEST,
	SEND_INVITATION_EDITORS_REQUEST,
	UPDATE_EDITORS_REQUEST,
	RESEND_INVITATION_EDITORS_REQUEST
} from '../typeAction';

export const getEditors = data => ({
	type: GET_EDITORS_REQUEST,
	payload: data
});

export const loadMoreEditors = data => ({
	type: LOAD_MORE_EDITORS_REQUEST,
	payload: data
});

export const getEditorsDetail = id => ({
	type: GET_EDITORS_DETAIL_REQUEST,
	payload: id
});

export const searchEditors = data => ({
	type: SEARCH_EDITORS_REQUEST,
	payload: data
});

export const updateEditor = data => ({
	type: UPDATE_EDITORS_REQUEST,
	payload: data
});

export const sendInvitation = data => ({
	type: SEND_INVITATION_EDITORS_REQUEST,
	payload: data
});

export const resendInvitation = data => ({
	type: RESEND_INVITATION_EDITORS_REQUEST,
	payload: data
});
