import { put, call, takeLatest } from 'redux-saga/effects';
import {
	GET_TOPICS_FAILED,
	GET_TOPICS_SUCCESS,
	GET_TOPICS_REQUEST,
	CREATE_TOPICS_FAILED,
	CREATE_TOPICS_SUCCESS,
	CREATE_TOPICS_REQUEST,
	UPDATE_TOPICS_FAILED,
	UPDATE_TOPICS_SUCCESS,
	UPDATE_TOPICS_REQUEST,
	GET_TOPIC_BY_ID_FAILED,
	GET_TOPIC_BY_ID_SUCCESS,
	GET_TOPIC_BY_ID_REQUEST,
	SEND_QUOTE_FAILED,
	SEND_QUOTE_SUCCESS,
	SEND_QUOTE_REQUEST
} from '../../actions/typeAction';
import {
	getTopicsService,
	createTopicService,
	updateTopicService,
	getTopicByIdService,
	sendQuoteService
} from '../../../core/services/modules/topicsService';

export function* getTopicsSaga(action) {
	const response = yield call(getTopicsService, action.payload);
	if (response.data.error) {
		yield put({
			type: GET_TOPICS_FAILED,
			payload: response.data.error
		});
	} else {
		const responseData = response.data;
		yield put({
			type: GET_TOPICS_SUCCESS,
			payload: { responseData, params: action.payload }
		});
	}
}

export function* createTopicSaga(action) {
	const response = yield call(createTopicService, action.payload);
	if (response.data.error) {
		yield put({
			type: CREATE_TOPICS_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: CREATE_TOPICS_SUCCESS,
			payload: response.data
		});
	}
}

export function* sendQuoteSaga(action) {
	const response = yield call(sendQuoteService, action.payload);
	if (response.data.error) {
		yield put({
			type: SEND_QUOTE_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: SEND_QUOTE_SUCCESS,
			payload: data
		});
	}
}

export function* updateTopicSaga(action) {
	const response = yield call(updateTopicService, action.payload);
	if (response.data.error) {
		yield put({
			type: UPDATE_TOPICS_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: UPDATE_TOPICS_SUCCESS,
			payload: response.data
		});
	}
}

export function* getTopicByIdSaga(action) {
	const response = yield call(getTopicByIdService, action.payload);
	if (response.data.error) {
		yield put({
			type: GET_TOPIC_BY_ID_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: GET_TOPIC_BY_ID_SUCCESS,
			payload: response.data
		});
	}
}

export function* watchTopics() {
	yield takeLatest(GET_TOPICS_REQUEST, getTopicsSaga);
	yield takeLatest(CREATE_TOPICS_REQUEST, createTopicSaga);
	yield takeLatest(UPDATE_TOPICS_REQUEST, updateTopicSaga);
	yield takeLatest(GET_TOPIC_BY_ID_REQUEST, getTopicByIdSaga);
	yield takeLatest(SEND_QUOTE_REQUEST, sendQuoteSaga);
}
