import React, { useEffect, useState } from 'react';
import { Empty, Button } from 'antd';
import ProductionDate from './item';
import ProductionDateForm from './form';
import './style.scss';

/**
 * @typedef {{
 *  _id: string
 *   startDate: string
 *   duration: number
 *   address: Object
 *   contact: Object
 *   creatives: string[]
 * }} ProductionDate
 */

/**
 * @param {{
 *   project: {
 *     productionDates: ProductionDate[]
 *   }
 * }} props
 */
export default function ProductionDates(props) {
  const { formData, onInputFields } = props;
  const [dates, setDates] = useState(formData || []);
  const [editItem, setEditItem] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      onInputFields('productionDates', dates);
    });
  }, [dates, onInputFields])

  const handleEdit = (item, order) => {
    if (!item) {
      setEditItem({ order: null });
      return;
    }
    setEditItem({ ...item, order });
  };

  const handleClose = (data) => {
    const { order } = editItem;
    const index = order - 1;
    setEditItem(null)
    if (!data) {
      return;
    }

    if (!order || index < 0) {
      setDates([
        ...dates,
        {...data}
      ]);
      return;
    }

    const nextDates = dates.map((item, idx) => {
      if (index !== idx) {
        return item;
      }

      return data;
    });

    setDates(nextDates);
  }

  return (<>
    <Button className="mb-3" type="primary" onClick={() => handleEdit()}>Add production date</Button>
    {!dates.length && (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No shooting dates" />)}
    {dates.map((detail, idx) => (
      <ProductionDate key={idx} detail={detail} order={idx + 1} onEdit={() => handleEdit(detail, idx + 1)} />
    ))}
    {!!editItem && (
      <ProductionDateForm detail={editItem} visible={!!editItem} onClose={handleClose} />
    )}
  </>);
};
