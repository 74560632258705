import { put, call, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../../actions/typeAction';
import {
  postAttachLink,
  getListAssets,
  postSignUrl,
  postCreateFolder,
  getGalleryFile,
  updateResource,
  deleteResource,
  deleteAllResources,
} from '../../../core/services/modules/assetService';
import { openNotification } from 'src/components/common/notification/notification';

export function* listAssetTask(action) {
  try {
    const data = action.payload;

    const response = yield call(getListAssets, data);
    if (response.status === 200 && response.data.success) {
      yield put({
        type: actionTypes.LIST_ASSET_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: actionTypes.LIST_ASSET_FAILED,
        payload: response.data.error,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.LIST_ASSET_FAILED,
      payload: err,
    });
  }
}

export function* attachLinkTask(action) {
  try {
    const data = action.payload;

    const response = yield call(postAttachLink, data);
    if (response.status === 200 && response.data.success) {
      yield put({
        type: actionTypes.ATTACH_LINK_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: actionTypes.ATTACH_LINK_FAILED,
        payload: response.data.error,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.ATTACH_LINK_FAILED,
      payload: err,
    });
  }
}

export function* signUrlTask(action) {
  try {
    const data = action.payload;

    const response = yield call(postSignUrl, data);
    if (response.status === 200 && response.data.success) {
      yield put({
        type: actionTypes.SIGN_URL_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: actionTypes.SIGN_URL_FAILED,
        payload: response.data.error,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.SIGN_URL_FAILED,
      payload: err,
    });
  }
}

export function* createFolderTask(action) {
  try {
    const data = action.payload;

    const response = yield call(postCreateFolder, data);
    if (response.status === 200 && response.data.success) {
      yield put({
        type: actionTypes.CREATE_FOLDER_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: actionTypes.CREATE_FOLDER_FAILED,
        payload: response.data.error,
      });
      openNotification('error', response.data.error.message);
    }
  } catch (err) {
    yield put({
      type: actionTypes.CREATE_FOLDER_FAILED,
      payload: err,
    });
  }
}

export function* deleteResourceTask(action) {
  try {
    const data = action.payload;

    const response = yield call(deleteResource, data);
    if (response.status === 200 && response.data.success) {
      yield put({
        type: actionTypes.DELETE_RESOURCE_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: actionTypes.DELETE_RESOURCE_FAILED,
        payload: response.data.error,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.DELETE_RESOURCE_FAILED,
      payload: err,
    });
  }
}

export function* updateResourceTask(action) {
  try {
    const data = action.payload;

    const response = yield call(updateResource, data);
    if (response.status === 200 && response.data.success) {
      yield put({
        type: actionTypes.UPDATE_RESOURCE_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: actionTypes.UPDATE_RESOURCE_FAILED,
        payload: response.data.error,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.UPDATE_RESOURCE_FAILED,
      payload: err,
    });
  }
}

export function* getFileGalleryTask(action) {
  try {
    const data = action.payload;
    const response = yield call(getGalleryFile, data);
    if (response.status === 200 && response.data.success) {
      yield put({
        type: actionTypes.GET_GALLERY_FILE_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_GALLERY_FILE_FAILED,
        payload: response.data.error,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.GET_GALLERY_FILE_FAILED,
      payload: err,
    });
  }
}

export function* deleteAllResourcesTask(action) {
  try {
    const data = action.payload;

    const response = yield call(deleteAllResources, data);
    if (response.status === 200 && response.data.success) {
      yield put({
        type: actionTypes.DELETE_ALL_RESOURCES_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: actionTypes.DELETE_ALL_RESOURCES_FAILED,
        payload: response.data.error,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.DELETE_ALL_RESOURCES_FAILED,
      payload: err,
    });
  }
}

export function* renameImageGalleryTask(action) {
  try {
    const data = action.payload;

    const response = yield call(updateResource, data);
    if (response.status === 200 && response.data.success) {
      yield put({
        type: actionTypes.RENAME_IMAGE_GALLERY_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: actionTypes.RENAME_IMAGE_GALLERY_FAILED,
        payload: response.data.error,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.RENAME_IMAGE_GALLERY_FAILED,
      payload: err,
    });
  }
}

export function* watchAssets() {
  yield takeLatest(actionTypes.LIST_ASSET_REQUEST, listAssetTask);
  yield takeLatest(actionTypes.ATTACH_LINK_REQUEST, attachLinkTask);
  yield takeLatest(actionTypes.SIGN_URL_REQUEST, signUrlTask);
  yield takeLatest(actionTypes.CREATE_FOLDER_REQUEST, createFolderTask);
  yield takeLatest(actionTypes.GET_GALLERY_FILE_REQUEST, getFileGalleryTask);
  yield takeLatest(actionTypes.DELETE_RESOURCE_REQUEST, deleteResourceTask);
  yield takeLatest(actionTypes.UPDATE_RESOURCE_REQUEST, updateResourceTask);
  yield takeLatest(actionTypes.DELETE_ALL_RESOURCES_REQUEST, deleteAllResourcesTask);
  yield takeLatest(actionTypes.RENAME_IMAGE_GALLERY_REQUEST, renameImageGalleryTask);
}
