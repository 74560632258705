import {
	GET_CATEGORIES_REQUEST,
	CREATE_CATEGORIES_REQUEST,
	UPDATE_CATEGORIES_REQUEST,
	UPDATE_ORDER_CATEGORIES_SUCCESS,
	GET_CATEGORIES_BY_ID_REQUEST
} from '../typeAction';

export const getCategories = () => ({
	type: GET_CATEGORIES_REQUEST
});

export const getCategoriesById = id => ({
	type: GET_CATEGORIES_BY_ID_REQUEST,
	payload: id
});

export const createCategories = data => ({
	type: CREATE_CATEGORIES_REQUEST,
	payload: data
});

export const updateCategories = ({ data, id }) => ({
	type: UPDATE_CATEGORIES_REQUEST,
	payload: { data, id }
});

export const updateOrder = data => ({
	type: UPDATE_ORDER_CATEGORIES_SUCCESS,
	payload: data
});
