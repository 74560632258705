import {
	GET_TOPICS_REQUEST,
	GET_TOPICS_SUCCESS,
	GET_TOPICS_FAILED,
	CREATE_TOPICS_REQUEST,
	CREATE_TOPICS_SUCCESS,
	CREATE_TOPICS_FAILED,
	UPDATE_TOPICS_REQUEST,
	UPDATE_TOPICS_SUCCESS,
	UPDATE_TOPICS_FAILED,
	GET_TOPIC_BY_ID_REQUEST,
	GET_TOPIC_BY_ID_SUCCESS,
	GET_TOPIC_BY_ID_FAILED,
	SEND_QUOTE_FAILED,
	SEND_QUOTE_SUCCESS,
	SEND_QUOTE_REQUEST
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';

const defaultState = {
	success: false,
	error: null,
	data: null,
	isLoading: false,
	detailData: null
};

const topicsReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_TOPICS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case GET_TOPICS_SUCCESS: {
			const docs = state.data && state.data.docs ? state.data.docs : [];
			if (action.payload.params.page === 1) {
				return {
					...state,
					...action.payload.responseData,
					isLoading: false
				};
			}
			return {
				...state,
				...action.payload.responseData,
				data: {
					...action.payload.responseData.data,
					docs: [...docs].concat(action.payload.responseData.data.docs)
				},
				isLoading: false
			};
		}
		case GET_TOPICS_FAILED: {
			return {
				...state,
				error: action.payload,
				isLoading: false
			};
		}
		case CREATE_TOPICS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case CREATE_TOPICS_SUCCESS: {
			openNotification('success', 'Your new topic has been created');
			return {
				...state,
				isLoading: false,
				data: {
					...state.data,
					docs: [action.payload.data, ...state.data.docs]
				},
				detailData: action.payload.data
			};
		}
		case CREATE_TOPICS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case SEND_QUOTE_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case SEND_QUOTE_SUCCESS: {
			return {
				...state,
				isLoading: false,
				detailData: action.payload.data
			};
		}
		case SEND_QUOTE_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_TOPICS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case UPDATE_TOPICS_SUCCESS: {
			openNotification('success', 'Update successful');
			return {
				...state,
				isLoading: false,
				detailData: action.payload.data
			};
		}
		case UPDATE_TOPICS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case GET_TOPIC_BY_ID_REQUEST: {
			return {
				...state,
				detailData: null
			};
		}
		case GET_TOPIC_BY_ID_SUCCESS: {
			return {
				...state,
				detailData: action.payload.data
			};
		}
		case GET_TOPIC_BY_ID_FAILED: {
			return {
				...state,
				error: action.payload
			};
		}
		default:
			return state;
	}
};

export default topicsReducer;
