import {
	GET_SUB_CATEGORIES_REQUEST,
	CREATE_SUB_CATEGORIES_REQUEST,
	UPDATE_SUB_CATEGORIES_REQUEST,
	UPDATE_ORDER_SUB_CATEGORIES_SUCCESS
} from '../typeAction';

export const getSubCategories = () => ({
	type: GET_SUB_CATEGORIES_REQUEST
});

export const createSubCategories = data => ({
	type: CREATE_SUB_CATEGORIES_REQUEST,
	payload: data
});

export const updateSubCategories = ({ data, id }) => ({
	type: UPDATE_SUB_CATEGORIES_REQUEST,
	payload: { data, id }
});

export const updateOrder = data => ({
	type: UPDATE_ORDER_SUB_CATEGORIES_SUCCESS,
	payload: data
});
