const ENV = {
  DEV: {
    API_SERVER: 'http://vidops.xenia.tech:1995',
    APP_SERVER: 'http://vidops.xenia.tech:4600',
    SEO_SERVER: 'http://vidops.xenia.tech:4600',
    RECAPTCHA_KEY: '',
    TRACKING_ID: '117089241613',
    APP_DEV: 'DEV',
    URL_S3: 'https://vidops-dev.s3.eu-west-2.amazonaws.com',
    URL_CDN: 'https://dd8h92nc4c4fr.cloudfront.net',
  },
  SIT: {
    API_SERVER: 'http://vidops.xenia.tech:1990',
    APP_SERVER: 'http://vidops.xenia.tech:1990',
    SEO_SERVER: 'http://vidops.xenia.tech:1990',
    RECAPTCHA_KEY: '',
    TRACKING_ID: '117089241613',
    APP_DEV: 'SIT',
    URL_S3: 'https://vidops-sit.s3.eu-west-2.amazonaws.com',
    URL_CDN: 'https://d10sx2au1raxzp.cloudfront.net',
  },
  UAT: {
    API_SERVER: 'https://apiuat.vidops.io',
    APP_SERVER: 'https://uat.vidops.io',
    SEO_SERVER: 'https://uat.vidops.io',
    RECAPTCHA_KEY: '',
    TRACKING_ID: '117089241613',
    APP_DEV: 'UAT',
    URL_S3: 'https://vidops-uat.s3.eu-west-2.amazonaws.com',
    URL_CDN: 'https://d22m0ai37tp0d2.cloudfront.net',
  },
  PROD: {
    API_SERVER: 'https://api.vidops.io',
    APP_SERVER: 'https://app.vidops.io',
    SEO_SERVER: 'https://vidops.io',
    RECAPTCHA_KEY: '',
    TRACKING_ID: '117089241613',
    APP_DEV: 'PROD',
    URL_S3: 'https://vidops-production.s3.eu-west-2.amazonaws.com',
    URL_CDN: 'https://d1qr4916qx85ml.cloudfront.net',
  },
};

/**
 * @param {keyof typeof ENV['PROD']} name
 */
const getEnv = (name, defaultValue) => {
  return (
    process.env[name] || ENV[process.env.REACT_APP_STAGE || 'DEV'][name] || defaultValue
  );
};

export { getEnv };
