import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './creativesForm.scss';
import { Form, Spin } from 'antd';
import NotFound from '../../../screens/404/404';
import CreativesContact from './creativesContact/creativesContact';
import CreativesInfo from './creativesInfo/creativesInfo';

class CreativesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    const { params } = props.match;
    if (props.onInit && params.itemId) {
      props.onInit(params.itemId);
    }
  }

  render() {
    const { params } = this.props.match;
    const { creativesDetail, creativesError, onClose } = this.props;

    const isLoading = (!creativesDetail || creativesDetail._id !== params.itemId) && !creativesError;
    if (isLoading) {
      return <Spin />;
    }

    const isNotfound = !creativesDetail && !!creativesError
    if (isNotfound) {
      return <NotFound embedded={true} />;
    }

    return (
      <div className="creatives-drawer-container">
        <>
          <CreativesContact creatives={creativesDetail} />
          <CreativesInfo creatives={creativesDetail} onClose={onClose} />
        </>
      </div>
    );
  }
}

const WrappedCreativesForm = Form.create({ name: 'normal_creativess' })(CreativesForm);

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(WrappedCreativesForm)
);
