import React, { useState, useEffect, useRef } from 'react';
import { Player, BigPlayButton } from 'video-react';
import { Modal } from 'antd';
import Lightbox from 'react-image-lightbox';
import { IMAGES_TYPE } from 'src/core/constants/constants.js';
import {
  getFileType,
  getS3CDN,
  encodeURIThumbnail,
  youtubeLinkParser,
} from 'src/core/utils/commonUtils';
import './fileDetail.scss';
import { isYoutubeLink } from 'src/core/utils/customValidator.js';

const FileDetail = ({ detail, onCancel, autoPlay = false }) => {
  // const [isShow, setShow] = useState(false);
  const [pathDetail, setDetail] = useState(detail && detail.path);
  const fileType = '.' + getFileType(detail && detail.path);
  const isVideo = ['.mp4'].includes(fileType);
  const isImg = IMAGES_TYPE.includes(fileType);
  const isYoutube = isYoutubeLink(detail && detail.path);

  const thumb = encodeURIThumbnail(detail && detail.pathThumbnail);
  const videoRef = useRef();
  const windowWidth = window.innerWidth;

  useEffect(() => {
    let path = detail && detail.path;
    if (isVideo) {
      path = getS3CDN(path);
    }
    setDetail(path);
  }, [detail, isVideo]);

  if (!detail) return '';

  const handTypeDetail = () => {
    switch (true) {
      case isVideo:
        return (
          <Player
            ref={videoRef}
            autoPlay={autoPlay}
            poster={thumb}
            src={pathDetail}
            onPlay={() => {
              if (windowWidth > 576) return;
              videoRef.current && videoRef.current.toggleFullscreen();
            }}
          >
            <BigPlayButton position="center" />
          </Player>
        );
      case isYoutube:
        return (
          <div
            className="video"
            style={{
              position: 'relative',
              paddingBottom: '56.25%' /* 16:9 */,
              paddingTop: 25,
              height: 0,
            }}
          >
            <iframe
              title="Youtube player"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              src={`https://www.youtube.com/embed/${youtubeLinkParser(
                detail && detail.path
              )}`}
              frameBorder="0"
            />
          </div>
        );
      default:
        return (
          <p className="file-not-support">
            File type not supported for streaming/ viewing, please download file to your
            device to play/ view offline
          </p>
        );
    }
  };

  return isImg && pathDetail ? (
    <Lightbox mainSrc={pathDetail} onCloseRequest={onCancel} />
  ) : (
    <Modal
      wrapClassName="assets-detail-wrapper"
      className="assets-detail"
      title={null}
      footer={null}
      visible={!!detail}
      onCancel={onCancel}
      maskStyle={{
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
      }}
      width="50vw"
      centered
    >
      {handTypeDetail()}
    </Modal>
  );
};

export default FileDetail;
