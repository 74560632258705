import { put, call, takeLatest } from 'redux-saga/effects';

import { GET_ROLES_FAILED, GET_ROLES_SUCCESS, GET_ROLES_REQUEST } from '../../actions/typeAction';
import { getRolesService } from '../../../core/services/modules/rolesService';

export function* getRolesSaga(action) {
	const response = yield call(getRolesService, action.payload);
	if (response.data.error) {
		yield put({
			type: GET_ROLES_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: GET_ROLES_SUCCESS,
			payload: data
		});
	}
}

export function* watchRoles() {
	yield takeLatest(GET_ROLES_REQUEST, getRolesSaga);
}
