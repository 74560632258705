import React from 'react';
import PropTypes from 'prop-types';

export default class InfiniteScroll extends React.PureComponent {
  state = {};
  listSelector = React.createRef();
  loaderSelector = React.createRef();
  currentOffset = 0;

  static propTypes = {
    loadMorePlacement: PropTypes.string,
    className: PropTypes.string,
    loadMoreComponent: PropTypes.func,
    onLoadEnd: PropTypes.func,
    offsetThreshold: PropTypes.number,
    useListHeightThreshold: PropTypes.bool,
  };

  static defaultProps = {
    onLoadEnd: () => null,
    offsetThreshold: 100,
    loadMorePlacement: 'bottom',
  };
  /**
   * Component life cycle
   **/
  componentDidMount() {
    this.listSelector.current.addEventListener('scroll', this.handleListScroll);
  }

  componentWillUnmount() {
    this.listSelector.current.removeEventListener('scroll', this.handleListScroll);
  }

  /**
   * Handler
   **/
  handleListScroll = () => {
    const listHeight = this.listSelector.current.clientHeight;
    const { y: scrollTop } = this.loaderSelector.current.getBoundingClientRect();
    const indicatorHeight = 60;
    const scrollOffset = scrollTop - listHeight - indicatorHeight;
    const offsetThreshold = this.props.useListHeightThreshold
      ? listHeight
      : this.props.offsetThreshold;
    // Detect scroll up or down
    const isScrollDown =
      this.props.loadMorePlacement === 'bottom'
        ? scrollOffset < this.currentOffset
        : scrollOffset > this.currentOffset;

    if (isScrollDown && Math.abs(scrollOffset) < offsetThreshold) {
      this.props.onLoadEnd();
    }

    this.currentOffset = scrollOffset;
  };

  /**
   * Renderer
   **/
  render() {
    return (
      <div ref={this.listSelector} className={this.props.className}>
        {this.props.children}
        {!this.props.loadMoreComponent ? (
          <div ref={this.loaderSelector} className="loader-milestone" />
        ) : (
          this.props.loadMoreComponent(this.loaderSelector)
        )}
      </div>
    );
  }
}
