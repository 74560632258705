import React from 'react';
import 'antd/dist/antd.css';
import './budgetForm.scss';
import { Form, Input, Button, Select } from 'antd';
import { validatePositive } from '../../../../core/utils/customValidator';

const { Option } = Select;

class BudgetForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const name = values.name.trim();
				const status = values.status.trim() === 'Active' ? true : false;
				const value = Number(values.value);
				const data = {
					name,
					value,
					status
				};
				this.props.isCreate
					? this.props.onCreateBudget(data)
					: this.props.onUpdateBudget(data);
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { isCreate, itemUpdate, isLoading } = this.props;
		if (isCreate) {
			return (
				<Form className="budget-form">
					<Form.Item label="Name">
						{getFieldDecorator('name', {
							rules: [
								{
									required: true,
									message: 'Please input budget name'
								},
								{
									whitespace: true,
									message:
										'Please do not input whitespace only'
								}
							],
							initialValue: null
						})(<Input placeholder="Name" size="large" />)}
					</Form.Item>
					<Form.Item label="Value">
						{getFieldDecorator('value', {
							rules: [
								{
									required: true,
									message: 'Please input value number'
								},
								{
									validator: (rule, value, callback) => {
										const msg = validatePositive(
											value,
											'Value'
										);
										if (msg) {
											callback(msg);
										} else {
											callback();
										}
									}
								}
							],
							initialValue: null
						})(
							<Input
								type="number"
								placeholder="Value"
								size="large"
								step={0.01}
							/>
						)}
					</Form.Item>
					<Form.Item label="Status" className="status-item">
						{getFieldDecorator('status', {
							rules: [
								{
									required: true,
									message: 'Please select budget status'
								}
							],
							initialValue: 'Active'
						})(
							<Select>
								<Option value="Active">Active</Option>
								<Option value="Inactive">Inactive</Option>
							</Select>
						)}
					</Form.Item>
					<Form.Item>
						<Button
							type="primary"
							loading={isLoading}
							onClick={this.handleSubmit}
							className="budget-form-button btn-primary-0"
							size="large"
							disabled={isLoading}
						>
							Create
						</Button>
					</Form.Item>
				</Form>
			);
		}
		return (
			<Form className="budget-form">
				<Form.Item label="Name">
					{getFieldDecorator('name', {
						rules: [
							{
								required: true,
								message: 'Please input budget name'
							},
							{
								whitespace: true,
								message: 'Please do not input whitespace only'
							}
						],
						initialValue: itemUpdate.name
					})(<Input placeholder="Name" size="large" />)}
				</Form.Item>
				<Form.Item label="Value">
					{getFieldDecorator('value', {
						rules: [
							{
								required: true,
								message: 'Please input value number'
							},
							{
								validator: (rule, value, callback) => {
									const msg = validatePositive(
										value,
										'Value'
									);
									if (msg) {
										callback(msg);
									} else {
										callback();
									}
								}
							}
						],
						initialValue: itemUpdate.value.toString()
					})(
						<Input
							type="number"
							placeholder="Value"
							size="large"
							step={0.01}
						/>
					)}
				</Form.Item>
				<Form.Item label="Status" className="status-item">
					{getFieldDecorator('status', {
						rules: [
							{
								required: true,
								message: 'Please select budget status'
							}
						],
						initialValue: itemUpdate.statusText
					})(
						<Select>
							<Option value="Active">Active</Option>
							<Option value="Inactive">Inactive</Option>
						</Select>
					)}
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						loading={isLoading}
						onClick={this.handleSubmit}
						className="budget-form-button btn-primary-0"
						size="large"
						disabled={isLoading}
					>
						Update
					</Button>
				</Form.Item>
			</Form>
		);
	}
}

const WrappedBudgetForm = Form.create({ name: 'normal_budget' })(BudgetForm);

export default WrappedBudgetForm;
