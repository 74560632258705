import {
	GET_OUR_TEAM_MEMBERS_REQUEST,
	CREATE_OUR_TEAM_MEMBERS_REQUEST,
	UPDATE_OUR_TEAM_MEMBERS_REQUEST,
	UPDATE_ORDER_OUR_TEAM_MEMBERS_SUCCESS
} from '../typeAction';

export const getOurTeamMembers = () => ({
	type: GET_OUR_TEAM_MEMBERS_REQUEST
});

export const createOurTeamMembers = data => ({
	type: CREATE_OUR_TEAM_MEMBERS_REQUEST,
	payload: data
});

export const updateOurTeamMembers = ({ data, id }) => ({
	type: UPDATE_OUR_TEAM_MEMBERS_REQUEST,
	payload: { data, id }
});

export const updateOrder = data => ({
	type: UPDATE_ORDER_OUR_TEAM_MEMBERS_SUCCESS,
	payload: data
});
