import React from 'react';
import './forgotPasswordForm.scss';
import { Form, Input, Button } from 'antd';
import { Container, Row } from 'react-bootstrap';
import { validateEmail } from 'src/core/utils/customValidator';
import { getEnv } from 'src/core/configs/envConfig';
class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Forgotten password?',
      email: '',
      success: props.success,
      isInvalid: false,
    };
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      success: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.success !== prevProps.success && this.props.success) {
      this.setState({
        success: this.props.success,
      });
    }
  }

  handleSubmit = async e => {
    e.preventDefault();
    const { email } = this.state;
    if (validateEmail(email, 'Email')) {
      this.setState({
        isInvalid: true,
      });
    } else {
      this.props.onSendEmail({ email: email.trim() });
    }
  };

  handleResend = e => {
    e.preventDefault();
    this.props.onResendEmail({ email: this.state.email.trim() });
  };

  handleChangeEmail = e => {
    const { value } = e.target;
    this.setState({ email: value || '', isInvalid: false });
  };

  render() {
    const { title, isInvalid, email } = this.state;
    const checkEmail = isInvalid && validateEmail(email, 'Email');
    if (!this.state.success) {
      return (
        <>
          <Form
            className={`forgot-password-form ${this.props.isShowModal ? 'border-0' : ''}`}
          >
            <h3 className="text-center title-5">{title}</h3>
            <p>
              No problem - we’ll send a reset link to the email you signed up with (p.s.
              please check your spam + junk folders if your email gets lost)
            </p>
            <Form.Item
              label="Email"
              validateStatus={checkEmail ? 'error' : ''}
              help={checkEmail}
            >
              <Input
                placeholder="Email"
                size="large"
                onChange={this.handleChangeEmail}
                name="email"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                loading={this.props.isLoading}
                onClick={this.handleSubmit}
                className="forgot-password-form-button btn-primary-0"
                size="large"
                disabled={this.props.isLoading}
              >
                Send
              </Button>
            </Form.Item>
          </Form>
        </>
      );
    }
    return (
      <section
        className={`forgot-password-sent ${this.props.isShowModal ? 'bg-white' : ''}`}
      >
        <Container>
          <h1 className="text-center mgb-30">Please check your email</h1>
          <Row className="sub-title">
            <Button
              type="primary"
              loading={this.props.isLoading}
              onClick={this.handleResend}
              className="forgot-password-form-button btn-primary-0 btn-resend"
              size="large"
              // disabled={this.props.isLoading && this.props.resendSuccess}
            >
              Resend
            </Button>
          </Row>
        </Container>
      </section>
    );
  }
}

const WrappedForgotPasswordForm = Form.create({
  name: 'normal_forgotPassword',
})(ForgotPasswordForm);

export default WrappedForgotPasswordForm;
