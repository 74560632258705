import {
  GET_CREATIVES_REQUEST,
  GET_CREATIVES_SUCCESS,
  GET_CREATIVES_FAILED,
  GET_CREATIVES_DETAIL_REQUEST,
  GET_CREATIVES_DETAIL_SUCCESS,
  GET_CREATIVES_DETAIL_FAILED,
  CLEAR_CREATIVES_DETAIL,
  SEARCH_CREATIVES_REQUEST,
  SEARCH_CREATIVES_SUCCESS,
  SEARCH_CREATIVES_FAILED,
  LOAD_MORE_CREATIVES_REQUEST,
  LOAD_MORE_CREATIVES_FAILED,
  LOAD_MORE_CREATIVES_SUCCESS,
  SEND_INVITATION_CREATIVES_REQUEST,
  SEND_INVITATION_CREATIVES_SUCCESS,
  SEND_INVITATION_CREATIVES_FAILED,
  UPDATE_CREATIVES_SUCCESS,
  UPDATE_CREATIVES_REQUEST,
  UPDATE_CREATIVES_FAILED,
  RESEND_INVITATION_CREATIVES_REQUEST,
  RESEND_INVITATION_CREATIVES_SUCCESS,
  RESEND_INVITATION_CREATIVES_FAILED,
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';

const updateCreativesHelper = (state, action) => {
  const index = state.data.list.findIndex(item => item._id === action.payload.data._id);
  const currentList = [...state.data.list];
  currentList[index] = { ...currentList[index], ...action.payload.data };
  return { ...state.data, list: currentList };
};

const loadMoreHelper = (state, action) => {
  const currentList =
    state.data && state.data.list && state.data.list.length
      ? [...state.data.list, ...action.payload.data.list]
      : [...action.payload.data.list];
  return { ...state.data, list: currentList };
};

const defaultState = {
  success: false,
  error: null,
  data: [],
  isLoading: false,
  detailData: null,
  detailError: null,
  isLoadingMore: false,
  isSendingInvitation: false,
  hasSentInvitation: false,
};

const creativesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_CREATIVES_REQUEST: {
      return {
        ...state,
      };
    }
    case GET_CREATIVES_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case GET_CREATIVES_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        error: action.payload,
      };
    }
    case LOAD_MORE_CREATIVES_REQUEST: {
      return {
        ...state,
        isLoadingMore: true,
      };
    }
    case LOAD_MORE_CREATIVES_SUCCESS: {
      return {
        ...state,
        isLoadingMore: false,
        data: loadMoreHelper(state, action),
      };
    }
    case LOAD_MORE_CREATIVES_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        error: action.payload,
        isLoadingMore: false,
      };
    }
    case GET_CREATIVES_DETAIL_REQUEST: {
      return {
        ...state,
        detailError: null
      };
    }
    case GET_CREATIVES_DETAIL_SUCCESS: {
      return {
        ...state,
        detailData: { ...action.payload.data },
        detailError: null
      };
    }
    case GET_CREATIVES_DETAIL_FAILED: {
      if (action.payload.message) {
        openNotification('error', action.payload.message);
      }

      return {
        ...state,
        detailError: action.payload,
      };
    }
    case CLEAR_CREATIVES_DETAIL: {
      return {
        ...state,
        detailData: null,
        detailError: null
      };
    }
    case SEARCH_CREATIVES_REQUEST: {
      return {
        ...state,
      };
    }
    case SEARCH_CREATIVES_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SEARCH_CREATIVES_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        error: action.payload,
      };
    }
    case UPDATE_CREATIVES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPDATE_CREATIVES_SUCCESS: {
      openNotification('success', 'Update successful');
      return {
        ...state,
        isLoading: false,
        data: updateCreativesHelper(state, action),
        detailData: action.payload.data,
      };
    }
    case UPDATE_CREATIVES_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case SEND_INVITATION_CREATIVES_REQUEST: {
      return {
        ...state,
        isSendingInvitation: true,
      };
    }
    case SEND_INVITATION_CREATIVES_SUCCESS: {
      openNotification('success', 'Invitation sent successfully');
      return {
        ...state,
        isSendingInvitation: false,
        hasSentInvitation: true,
        data: { ...state.data, list: [...state.data.list, action.payload.data] },
      };
    }
    case SEND_INVITATION_CREATIVES_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isSendingInvitation: false,
        hasSentInvitation: false,
        error: action.payload,
      };
    }
    case RESEND_INVITATION_CREATIVES_REQUEST: {
      return {
        ...state,
        isSendingInvitation: true,
      };
    }
    case RESEND_INVITATION_CREATIVES_SUCCESS: {
      openNotification('success', 'Invitation sent successfully');
      return {
        ...state,
        isSendingInvitation: false,
        hasSentInvitation: true,
      };
    }
    case RESEND_INVITATION_CREATIVES_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isSendingInvitation: false,
        hasSentInvitation: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export default creativesReducer;
