import socketInstance from './index';

export default () => {
  const { socket } = socketInstance();

  function unregisterEvent() {
    socket.off('ReceivedMessage');
    socket.off('SendMessage');
    socket.off('MessageSent');
    socket.off('InitConversation');
    socket.off('SendNotification');
    socket.off('ReadNotification');
    socket.off('LeaveConversation');
    socket.off('joinProject');
    socket.off('updateProject');
    socket.off('leaveProject');
    socket.off('updateProjectSuccess');
  }

  function onInitUserLogin(data) {
    socket.emit('InitUserLogin', data);
  }

  function onLeaveConversation(data) {
    socket.emit('LeaveConversation', data);
  }

  function onJoinConversation(conversation) {
    socket.emit('JoinConversation', conversation);
  }

  function onSendMessage(message) {
    socket.emit('SendMessage', message);
  }

  function onReceivedMessage(callback) {
    socket.on('ReceivedMessage', callback);
  }

  function onMessageSent(callback) {
    socket.on('MessageSent', callback);
  }

  function onSendNotification(callback) {
    socket.on('SendNotification', callback);
  }

  function onReadNotification(callback) {
    socket.on('ReadNotification', callback);
  }

  // project
  function joinProject(projectId) {
    socket.emit('joinProject', projectId);
  }

  function updateProject(project) {
    socket.emit('updateProject', project);
  }

  function leaveProject(project) {
    socket.emit('leaveProject', project);
  }

  function updateProjectSuccess(callback) {
    socket.on('updateProjectSuccess', callback);
  }

  return {
    unregisterEvent,
    onJoinConversation,
    onSendMessage,
    onReceivedMessage,
    onMessageSent,
    onSendNotification,
    onLeaveConversation,
    onInitUserLogin,
    onReadNotification,
    joinProject,
    updateProject,
    updateProjectSuccess,
    leaveProject
  };
};
