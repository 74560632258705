import React from 'react';
import './ourTeamMembersForm.scss';
import {
    Form,
    Icon,
    Input,
    Button,
    Upload,
    Avatar,
    message,
    Select,
} from 'antd';
import { extractFileExtension, uuid } from 'src/core/utils/commonUtils';
import { formDataConfig, dummyRequest } from '../../../../core/utils/commonUtils';
import { S3_FOLDERS } from "src/core/constants/constants";
import { httpService } from "src/core/services/httpService";

const { Option } = Select;
const { TextArea } = Input;

class OurTeamMembersForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            isPhotosRequired: false,
            displayImage: !this.props.isCreate,
            isBtnDisabled: false,
            isLoading: false
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.state.fileList.length && this.props.isCreate) {
            this.setState({
                isPhotosRequired: true,
            });
            return;
        }
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                const { fileList } = this.state;
                const name = values.name.trim();
                const position = values.position.trim();
                const linkedinUrl = values.linkedinUrl.trim();
                const description = values.description.trim();
                const status = values.status.trim() === 'Active' ? true : false;
                let data;
                let avatarParams;
                this.setState({
                    isLoading: true
                })
                if (this.props.isCreate) {
                    data = {
                        name,
                        position,
                        linkedinUrl,
                        description,
                        status,
                    };
                    avatarParams = {
						filename: `${S3_FOLDERS.CMS.OUR_TEAM.MEMBERS}/${uuid()}${extractFileExtension({ filename: fileList[0].originFileObj.name })}`,
						file: fileList[0].originFileObj
					};
					const res = await httpService._upload({ photos: [avatarParams] });
					if (res && res.length) {
						data.image = res[0];
					} 
                } else {
                    if (fileList.length) {
                        data = {
                            name,
                            position,
                            linkedinUrl,
                            description,
                            status,
                        }
                        avatarParams = {
                            filename: `${S3_FOLDERS.CMS.OUR_TEAM.MEMBERS}/${uuid()}${extractFileExtension({ filename: fileList[0].originFileObj.name })}`,
                            file: fileList[0].originFileObj
                        };
                        const res = await httpService._upload({ photos: [avatarParams] });
                        if (res && res.length) {
                            data.image = res[0];
                        } 
                    } else {
                        data = {
                            name,
                            position,
                            linkedinUrl,
                            description,
                            status
                        }
                    }
                }
                const formData = formDataConfig(data);
                this.props.isCreate
                    ? this.props.onCreateOurTeamMembers(formData)
                    : this.props.onUpdateOurTeamMembers(formData);
            }
        });
    };

    componentDidUpdate(prevProps) {
        if (this.props.ourTeamMembers !== prevProps.ourTeamMembers) {
            this.setState({
                isLoading: false
            })
        }
    }

    handleChange = ({ fileList }) => {
        fileList = [...fileList].slice(-1);
        if (fileList.length) {
            const lastImage = fileList[fileList.length - 1];
            lastImage.status !== 'done'
                ? this.setState({
                      isBtnDisabled: true,
                  })
                : this.setState({
                      isBtnDisabled: false,
                  });
            if (lastImage.originFileObj) {
                const isJpgOrPng = [
                    'image/jpeg',
                    'image/png',
                    'image/svg+xml',
                ].includes(lastImage.type);
                if (!isJpgOrPng) {
                    message.error('You can only upload JPG/PNG/SVG file');
                    fileList.pop();
                } else {
                    this.setState({
                        isPhotosRequired: false,
                        displayImage: false,
                    });
                }
            }
        }
        this.setState({ fileList });
    };

    onRemove = (file) => {
        this.setState({
            isBtnDisabled: false,
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { isCreate, itemUpdate } = this.props;
        const { fileList, displayImage, isBtnDisabled, isLoading } = this.state;
        const propsImage = {
            customRequest: dummyRequest,
            listType: 'picture',
            fileList: [...fileList],
            className: 'upload-list-inline',
            onRemove: this.onRemove,
        };
        if (isCreate) {
            return (
                <Form className="ourTeamMembers-form">
                    <Form.Item label="Name">
                        {getFieldDecorator('name', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input name',
                                },
                                {
                                    whitespace: true,
                                    message:
                                        'Please do not input whitespace only',
                                },
                            ],
                            initialValue: null,
                        })(<Input placeholder="Name" size="large" />)}
                    </Form.Item>
                    <Form.Item label="Position">
                        {getFieldDecorator('position', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input position',
                                },
                                {
                                    whitespace: true,
                                    message:
                                        'Please do not input whitespace only',
                                },
                            ],
                            initialValue: null,
                        })(<Input placeholder="Position" size="large" />)}
                    </Form.Item>
                    <Form.Item label="LinkedIn">
                        {getFieldDecorator('linkedinUrl', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input in link',
                                },
                                {
                                    whitespace: true,
                                    message:
                                        'Please do not input whitespace only',
                                },
                            ],
                            initialValue: null,
                        })(<Input placeholder="LinkedIn" size="large" />)}
                    </Form.Item>
                    <Form.Item label="Description">
                        {getFieldDecorator('description', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please input description',
                                },
                                {
                                    whitespace: true,
                                    message:
                                        'Please do not input whitespace only',
                                },
                            ],
                            initialValue: null,
                        })(<TextArea placeholder="Description" rows={4} />)}
                    </Form.Item>
                    {displayImage && (
                        <Avatar
                            className="thumbnail-container"
                            src={itemUpdate.url}
                        />
                    )}
                    <Form.Item>
                        <Upload {...propsImage} onChange={this.handleChange}>
                            <Button>
                                <Icon type="upload" />
                                Upload images
                            </Button>
                        </Upload>
                        {this.state.isPhotosRequired && (
                            <div className="ant-form-explain">
                                Please upload an image!
                            </div>
                        )}
                    </Form.Item>
                    <Form.Item label="Status" className="status-item">
                        {getFieldDecorator('status', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please select status',
                                },
                            ],
                            initialValue: 'Active',
                        })(
                            <Select>
                                <Option value="Active">Active</Option>
                                <Option value="Inactive">Inactive</Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            loading={isLoading}
                            onClick={this.handleSubmit}
                            className="ourTeam-form-button btn-primary-0"
                            size="large"
                            disabled={isBtnDisabled}
                        >
                            Create
                        </Button>
                    </Form.Item>
                </Form>
            );
        }
        return (
            <Form className="ourTeamMembers-form">
                <Form.Item label="Name">
                    {getFieldDecorator('name', {
                        rules: [
                            { required: true, message: 'Please input name' },
                            {
                                whitespace: true,
                                message: 'Please do not input whitespace only',
                            },
                        ],
                        initialValue: itemUpdate.name,
                    })(<Input placeholder="Name" size="large" />)}
                </Form.Item>
                <Form.Item label="Position">
                    {getFieldDecorator('position', {
                        rules: [
                            {
                                required: true,
                                message: 'Please input position',
                            },
                            {
                                whitespace: true,
                                message: 'Please do not input whitespace only',
                            },
                        ],
                        initialValue: itemUpdate.position,
                    })(<Input placeholder="Position" size="large" />)}
                </Form.Item>
                <Form.Item label="LinkedIn">
                    {getFieldDecorator('linkedinUrl', {
                        rules: [
                            {
                                required: true,
                                message: 'Please input in link',
                            },
                            {
                                whitespace: true,
                                message: 'Please do not input whitespace only',
                            },
                        ],
                        initialValue: itemUpdate.linkedinUrl,
                    })(<Input placeholder="LinkedIn" size="large" />)}
                </Form.Item>
                <Form.Item label="Description">
                    {getFieldDecorator('description', {
                        rules: [
                            {
                                required: true,
                                message: 'Please input description',
                            },
                            {
                                whitespace: true,
                                message: 'Please do not input whitespace only',
                            },
                        ],
                        initialValue: itemUpdate.description,
                    })(<TextArea placeholder="Description" rows={4} />)}
                </Form.Item>
                <Form.Item>
                    <Upload {...propsImage} onChange={this.handleChange}>
                        <Button>
                            <Icon type="upload" />
                            Change image
                        </Button>
                    </Upload>
                    {this.state.isPhotosRequired && (
                        <div className="ant-form-explain">
                            Please upload an image!
                        </div>
                    )}
                </Form.Item>
                {displayImage && (
                    <Form.Item>
                        <Avatar
                            className="thumbnail-container"
                            src={itemUpdate.url}
                        />
                    </Form.Item>
                )}
                <Form.Item label="Status" className="status-item">
                    {getFieldDecorator('status', {
                        rules: [
                            {
                                required: true,
                                message: 'Please select budget status',
                            },
                        ],
                        initialValue: itemUpdate.status ? 'Active' : 'Inactive',
                    })(
                        <Select>
                            <Option value="Active">Active</Option>
                            <Option value="Inactive">Inactive</Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        loading={isLoading}
                        onClick={this.handleSubmit}
                        className="ourTeam-form-button btn-primary-0"
                        size="large"
                        disabled={isBtnDisabled}
                    >
                        Update
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

const WrappedOurTeamMembersForm = Form.create({
    name: 'normal_ourTeamMembers',
})(OurTeamMembersForm);

export default WrappedOurTeamMembersForm;
