import {
	GET_POLICIES_REQUEST,
	GET_POLICIES_SUCCESS,
	GET_POLICIES_FAILED,
	CREATE_POLICIES_REQUEST,
	CREATE_POLICIES_SUCCESS,
	CREATE_POLICIES_FAILED,
	UPDATE_POLICIES_REQUEST,
	UPDATE_POLICIES_SUCCESS,
	UPDATE_POLICIES_FAILED
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';
import {
	updateHelper,
	updatePoliciesHelper
} from '../../../core/utils/commonUtils';

const defaultState = {
	success: false,
	error: null,
	data: [],
	isLoading: false
};

const policiesReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_POLICIES_REQUEST: {
			return {
				...state
			};
		}
		case GET_POLICIES_SUCCESS: {
			return {
				...state,
				...action.payload
			};
		}
		case GET_POLICIES_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				error: action.payload
			};
		}
		case CREATE_POLICIES_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case CREATE_POLICIES_SUCCESS: {
			openNotification('success', 'Create successfully');
			return {
				...state,
				isLoading: false,
				data: [...state.data, action.payload.data]
			};
		}
		case CREATE_POLICIES_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_POLICIES_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case UPDATE_POLICIES_SUCCESS: {
			openNotification('success', 'Update successful');
			return {
				...state,
				isLoading: false,
				data: updatePoliciesHelper(
					updateHelper(state, action),
					action.payload.data
				)
			};
		}
		case UPDATE_POLICIES_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		default:
			return state;
	}
};

export default policiesReducer;
