import React from 'react';
import { Row, Col } from 'react-bootstrap';
import 'antd/dist/antd.css';
import './caseStudiesForm.scss';
import {
  dataURLtoBlob,
  formDataConfig,
  isImageOrVideo,
  dummyRequest
} from '../../../../core/utils/commonUtils';
import { Form, Icon, Input, Button, Upload, Select } from 'antd';
import { openNotification } from '../../notification/notification';
import { getS3CDN, detectFile } from '../../../../core/utils/commonUtils';
import { isYoutubeLink } from '../../../../core/utils/customValidator';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import EditableTagGroup from '../../../common/editableTagGroup/editableTagGroup';
import VideoThumbnailExtractor from 'src/components/common/videoThumbnailExtractor';
import { S3_FOLDERS } from 'src/core/constants/constants';
import { httpService } from 'src/core/services/httpService';
import { extractFileExtension, uuid } from 'src/core/utils/commonUtils';
import { CKEDITOR } from 'src/core/constants/constants';

const { Option } = Select;

class CaseStudiesForm extends React.Component {
  constructor(props) {
    super(props);
    this.videoThumbnailExtractorRef = React.createRef();
    this.state = {
      content: !this.props.itemUpdate ? '' : this.props.itemUpdate.featureDes,
      errors: [],
      fileList: [],
      hasChangedFile: false,
      hasChangedYoutubeUrl: false,
      typeError: '',
      filesInputName: '',
      customerStoriesIds: [],
      hasChangedCustomerStories: false,
      tags: [],
      firstChange: false,
      submitting: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.itemUpdate &&
      Array.isArray(props.itemUpdate.metadata) &&
      !state.firstChange
    ) {
      return {
        firstChange: !state.firstChange,
        tags: props.itemUpdate.metadata
      };
    }
    return null;
  }

  // Submit
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      const errorsArr = [];
      const { itemUpdate } = this.props;
      const {
        fileList,
        hasChangedFile,
        hasChangedYoutubeUrl,
        customerStoriesIds,
        hasChangedCustomerStories,
        tags
      } = this.state;
      const content = this.state.content.trim();
      const serviceTypeId = values.serviceTypeId;
      const status = values.status.trim() === 'Active';
      const s3Folder = S3_FOLDERS.CMS.STORIES.CASE_STUDY;
      if (!content) {
        errorsArr.push('content');
      }
      if (!tags.length) {
        errorsArr.push('metadata');
      }

      // Loading
      this.setState({ submitting: true })

      if (!itemUpdate) {
        const youtubeUrl = values.youtubeUrl ? values.youtubeUrl.trim() : null;
        if (youtubeUrl && !isYoutubeLink(youtubeUrl)) {
          errorsArr.push('youtubeLink');
        }
        if ((!fileList || !fileList.length) && !youtubeUrl) {
          errorsArr.push('files');
        }
        this.setState({
          errors: errorsArr
        });
        if (err || errorsArr.length) {
          return this.setState({ submitting: false });
        } else {
          const title = values.title.trim();
          const file = fileList[0] ? fileList[0].originFileObj : youtubeUrl;
          const type = fileList[0] ? detectFile(fileList[0]) : 'video';
          const data = {
            title,
            type,
            metadata: tags,
            serviceTypeId,
            feature: file,
            featureDes: content,
            customerStories: customerStoriesIds,
            status
          };

          let promises = [];

          if (file && !isYoutubeLink(file)) {
            if (type === 'video') {
              let thumbnail = await this.videoThumbnailExtractorRef.current.onExtractThumbnail(
                file
              );
              thumbnail = dataURLtoBlob(thumbnail, file.name);
              promises.push(
                new Promise(async (resolve, reject) => {
                  try {
                    const [thumbnailUrl] = await httpService._upload({
                      photos: [
                        {
                          filename: `${s3Folder}/${uuid()}.png`,
                          file: thumbnail
                        }
                      ]
                    });
                    Object.assign(data, { thumbnail: thumbnailUrl });
                    resolve(true);
                  } catch (e) {
                    reject(e);
                  }
                })
              );
            }

            promises.push(
              new Promise(async (resolve, reject) => {
                try {
                  const [featureUrl] = await httpService._upload({
                    photos: [{ filename: `${s3Folder}/${uuid()}${extractFileExtension({ filename: file.name })}`, file }]
                  });

                  Object.assign(data, { feature: featureUrl });
                  resolve(true);
                } catch (e) {
                  reject(e);
                }
              })
            );
          }

          try {
            await Promise.all(promises);
          } catch (e) {
            openNotification('error', 'Upload files failed');
          }

          const formData = formDataConfig(data);
          this.props.onCreateCaseStudies(formData);
        }
      } else {
        let file;
        let type;
        const youtubeUrl = values.youtubeUrl ? values.youtubeUrl.trim() : null;
        if (youtubeUrl && !isYoutubeLink(youtubeUrl)) {
          errorsArr.push('youtubeLink');
        }
        if (hasChangedFile || hasChangedYoutubeUrl) {
          if ((!fileList || !fileList.length) && !youtubeUrl) {
            errorsArr.push('files');
          } else {
            file = fileList[0] ? fileList[0].originFileObj : youtubeUrl;
            type = detectFile(fileList[0]) ? detectFile(fileList[0]) : 'video';
          }
        } else {
          file = [itemUpdate.featureUrl];
          type = itemUpdate.type;
        }
        this.setState({
          errors: errorsArr
        });
        if (err || errorsArr.length) {
          return this.setState({ submitting: false });
        } else {
          const title = values.title.trim();
          let customerStories;
          if (hasChangedCustomerStories) {
            customerStories = customerStoriesIds;
          } else {
            customerStories =
              customerStoriesIds && customerStoriesIds.length
                ? customerStoriesIds
                : itemUpdate.customerStories.map(item => item._id);
          }
          const data = {
            title,
            type,
            metadata: tags,
            serviceTypeId,
            feature: file,
            featureDes: content,
            customerStories,
            status
          };

          let promises = [];

          if (file && !isYoutubeLink(file) && hasChangedFile) {
            if (type === 'video') {
              let thumbnail = await this.videoThumbnailExtractorRef.current.onExtractThumbnail(
                file
              );
              thumbnail = dataURLtoBlob(thumbnail, file.name);

              promises.push(
                new Promise(async (resolve, reject) => {
                  try {
                    const [thumbnailUrl] = await httpService._upload({
                      photos: [
                        {
                          filename: `${s3Folder}/${uuid()}.png`,
                          file: thumbnail
                        }
                      ]
                    });
                    Object.assign(data, { thumbnail: thumbnailUrl });
                    resolve(true);
                  } catch (e) {
                    reject(e);
                  }
                })
              );
            }

            promises.push(
              new Promise(async (resolve, reject) => {
                try {
                  const [featureUrl] = await httpService._upload({
                    photos: [{ filename: `${s3Folder}/${uuid()}${extractFileExtension({ filename: file.name })}`, file }]
                  });

                  Object.assign(data, { feature: featureUrl });
                  resolve(true);
                } catch (e) {
                  reject(e);
                }
              })
            );
          }

          try {
            await Promise.all(promises);
          } catch (e) {
            openNotification('error', 'Upload files failed');
          }

          const formData = formDataConfig(data);
          this.props.onUpdateCaseStudies(formData);
        }
      }

      this.setState({ submitting: false })
    });
  };

  // Content CKEditor Change
  contentChange = (event, editor) => {
    const content = editor.getData();
    if (content) {
      this.setState({
        errors: this.state.errors.filter(item => item !== 'content')
      });
    }
    this.setState({
      content
    });
  };

  // File handler
  onFileChange = info => {
    let { errors } = this.state;
    if (info && info.file && !isImageOrVideo(info.file)) {
      errors.push('fileType');
      errors = errors.filter(item => item !== 'files');
      this.setState({
        errors
      });
      return;
    } else {
      errors = errors.filter(item => item !== 'fileType');
      this.setState({
        errors
      });
    }
    if (this.state.fileList && this.state.fileList.length) {
      this.setState({
        errors: this.state.errors.filter(item => item !== 'files'),
        hasChangedFile: true,
        filesInputName: 'fileUpload'
      });
    }
    if (!info.fileList.length) {
      this.setState({
        filesInputName: ''
      });
    }
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    if (info.file.status === 'done') {
      openNotification('success', 'Upload file successfully');
    }
    if (info.file.status === 'error') {
      openNotification('error', 'Upload file upload failed');
    }
    this.setState({ fileList });
  };

  formatFile = file => {
    const arr = [];
    if (file) {
      arr.push({
        uid: file,
        name: file,
        status: 'done',
        url: getS3CDN(file)
      });
    }
    return arr;
  };

  urlYoutubeChange = e => {
    let errorsArr = [];
    const url = e.target.value;
    this.setState({
      filesInputName: url ? 'urlYoutube' : ''
    });
    if (url && !isYoutubeLink(url)) {
      errorsArr.push('youtubeLink');
    } else {
      const indexLink = errorsArr.indexOf('youtubeLink');
      errorsArr.splice(indexLink, 1);
    }
    this.setState({
      errors: errorsArr,
      hasChangedYoutubeUrl: true
    });
  };

  componentDidMount() {
    const { itemUpdate } = this.props;
    if (itemUpdate && itemUpdate.images && itemUpdate.images.length) {
      this.setState({
        deletePhotos: itemUpdate.images
      });
    }
  }

  handleChangeCustomerStories = values => {
    const { errors } = this.state;
    const { itemUpdate } = this.props;
    const indexError = errors.indexOf('customerStories');
    if (indexError > -1) {
      errors.splice(indexError, 1);
    }
    if (itemUpdate) {
      this.setState({
        errors,
        customerStoriesIds: values,
        hasChangedCustomerStories: true
      });
    } else {
      this.setState({
        errors,
        customerStoriesIds: values
      });
    }
  };

  handleTags = tags => {
    this.setState({ tags });
  };

  onChangeProductType = value => {
    this.props.onGetServiceType(value);
    this.props.form.setFieldsValue({ serviceTypeId: '' });
  };

  render() {
    const {
      fileList,
      hasChangedFile,
      filesInputName,

      tags
    } = this.state;

    const { getFieldDecorator } = this.props.form;
    const { itemUpdate, customerStories, productType, serviceType } = this.props;
    const props = {
      customRequest: dummyRequest,
      onChange: this.onFileChange,
      multiple: true,
      fileList: fileList
    };
    const customerStoriesRender = (
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        onChange={this.handleChangeCustomerStories}
        tokenSeparators={[',']}
        defaultValue={
          itemUpdate && itemUpdate.customerStories.length
            ? itemUpdate.customerStories.map(item => item._id)
            : []
        }
      >
        {customerStories.map(
          item => item.status && <Option key={item._id}>{item.title}</Option>
        )}
      </Select>
    );
    return (
      <>
        <VideoThumbnailExtractor ref={this.videoThumbnailExtractorRef} />
        <Form className="customerstories-form">
          <Form.Item label="Title">
            {getFieldDecorator('title', {
              rules: [
                {
                  required: true,
                  message: 'Please input title'
                },
                {
                  whitespace: true,
                  message: 'Please do not input whitespace only'
                }
              ],
              initialValue: itemUpdate ? itemUpdate.title : ''
            })(<Input placeholder="Title" size="large" />)}
          </Form.Item>
          <Row>
            <Col xs={6}>
              <Form.Item label="Files">
                <Upload {...props}>
                  <Button disabled={filesInputName === 'urlYoutube'}>
                    <Icon type="upload" /> Upload
                  </Button>
                </Upload>
                {this.state.errors.includes('files') ? (
                  <div className="ant-form-explain content-error">
                    Please upload file!
                  </div>
                ) : null}
                {this.state.errors.includes('fileType') ? (
                  <div className="ant-form-explain content-error">
                    Please upload image or video
                  </div>
                ) : null}
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item label="Url Youtube">
                {getFieldDecorator('youtubeUrl', {
                  initialValue: null
                })(
                  <Input
                    onChange={this.urlYoutubeChange}
                    placeholder="Files"
                    size="large"
                    disabled={filesInputName === 'fileUpload'}
                  />
                )}
                {this.state.errors.includes('youtubeLink') ? (
                  <div className="ant-form-explain content-error">
                    Please upload youtube link!
                  </div>
                ) : null}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>{!hasChangedFile && itemUpdate && itemUpdate.featureUrl}</Form.Item>
          <Form.Item label="Description">
            <CKEditor
              onInit={editor => {
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                  );
              }}
              onChange={(event, editor) => this.contentChange(event, editor)}
              editor={DecoupledEditor}
              config={{
                link: { addTargetToExternalLinks: true },
                toolbar: CKEDITOR.toolbar,
                heading: CKEDITOR.heading
              }}
              data={itemUpdate && itemUpdate.featureDes}
            />
            {this.state.errors.includes('content') ? (
              <div className="ant-form-explain content-error">Please input content!</div>
            ) : null}
          </Form.Item>
          <Row>
            <Col>
              <Form.Item label="Product type" className="">
                {getFieldDecorator('productTypeId', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select product type'
                    }
                  ],
                  initialValue: itemUpdate
                    ? itemUpdate.serviceTypeId.productTypeId._id
                    : ''
                })(
                  <Select onChange={this.onChangeProductType}>
                    {productType &&
                      productType.map(pt => (
                        <Option value={pt._id} key={pt._id}>
                          {pt.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Service type">
                {getFieldDecorator('serviceTypeId', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select service type'
                    }
                  ],
                  initialValue: itemUpdate ? itemUpdate.serviceTypeId._id : ''
                })(
                  <Select>
                    {serviceType &&
                      serviceType.map(st => (
                        <Option value={st._id} key={st._id}>
                          {st.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Keywords" required>
            <EditableTagGroup tags={tags} onHandleTags={this.handleTags} />
            {this.state.errors.includes('metadata') ? (
              <div className="ant-form-explain text-red-0">Please add least one key!</div>
            ) : null}
          </Form.Item>
          <Form.Item className="customer-stories-ids" label="Customer stories">
            {customerStoriesRender}
          </Form.Item>
          <Form.Item label="Status" className="status-item">
            {getFieldDecorator('status', {
              rules: [
                {
                  required: true,
                  message: 'Please select status'
                }
              ],
              initialValue: !itemUpdate
                ? 'Active'
                : itemUpdate.status
                ? 'Active'
                : 'Inactive'
            })(
              <Select>
                <Option value="Active">Active</Option>
                <Option value="Inactive">Inactive</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              loading={this.state.submitting}
              onClick={this.handleSubmit}
              className="customerstories-form-button btn-primary-0 w-25"
              size="large"
              disabled={this.state.isBtnDisabled}
            >
              {!itemUpdate ? 'Save' : 'Edit story'}
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

const WrappedCaseStudiesForm = Form.create({
  name: 'normal_customerstories'
})(CaseStudiesForm);

export default WrappedCaseStudiesForm;
