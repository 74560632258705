import {
	GET_ADMINS_REQUEST,
	GET_ADMINS_SUCCESS,
	GET_ADMINS_FAILED,
	UPDATE_ADMINS_SUCCESS,
	UPDATE_ADMINS_REQUEST,
	UPDATE_ADMINS_FAILED,
	REGISTER_ADMIN_REQUEST,
	REGISTER_ADMIN_SUCCESS,
	REGISTER_ADMIN_FAILED,
	LOAD_MORE_ADMINS_REQUEST,
	LOAD_MORE_ADMINS_SUCCESS,
	LOAD_MORE_ADMINS_FAILED,
	GET_ADMINS_DETAIL_REQUEST,
	GET_ADMINS_DETAIL_SUCCESS,
	GET_ADMINS_DETAIL_FAILED
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';

const updateAdminHelper = (state, action) => {
	const index = state.data.list.findIndex(item => item._id === action.payload.data._id);
	const currentList = [...state.data.list];
	currentList[index] = { ...currentList[index], ...action.payload.data };
	return { ...state.data, list: currentList };
};

const loadMoreHelper = (state, action) => {
	const currentList =
		state.data && state.data.list && state.data.list.length
			? [...state.data.list, ...action.payload.data.list]
			: [...action.payload.data.list];
	return { ...state.data, list: currentList };
};

const defaultState = {
	success: false,
	error: null,
	data: [],
	isLoading: false,
	detailData: null,
	isLoadingMore: false,
	isSendingInvitation: false,
	hasSentInvitation: false
};

const adminsReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_ADMINS_REQUEST: {
			return {
				...state
			};
		}
		case GET_ADMINS_SUCCESS: {
			return {
				...state,
				...action.payload
			};
		}
		case GET_ADMINS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				error: action.payload
			};
		}
		case LOAD_MORE_ADMINS_REQUEST: {
			return {
				...state,
				isLoadingMore: true
			};
		}
		case LOAD_MORE_ADMINS_SUCCESS: {
			return {
				...state,
				isLoadingMore: false,
				data: loadMoreHelper(state, action)
			};
		}
		case LOAD_MORE_ADMINS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				error: action.payload,
				isLoadingMore: false
			};
		}
		case GET_ADMINS_DETAIL_REQUEST: {
			return {
				...state
			};
		}
		case GET_ADMINS_DETAIL_SUCCESS: {
			return {
				...state,
				detailData: { ...action.payload.data }
			};
		}
		case GET_ADMINS_DETAIL_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				error: action.payload
			};
		}
		case UPDATE_ADMINS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case UPDATE_ADMINS_SUCCESS: {
			openNotification('success', 'Update successful');
			return {
				...state,
				isLoading: false,
				data: updateAdminHelper(state, action),
				detailData: action.payload.data
			};
		}
		case UPDATE_ADMINS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case REGISTER_ADMIN_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case REGISTER_ADMIN_SUCCESS: {
			openNotification('success', 'Create successfully');
			return {
				...state,
				isLoading: false,
				data: {
					...state.data,
					list: [...state.data.list, action.payload.data]
				}
			};
		}
		case REGISTER_ADMIN_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		default:
			return state;
	}
};

export default adminsReducer;
