const reg_url = /^(ftp|http|https):\/\/[^ "]+$/;
// eslint-disable-next-line no-control-regex
const reg_email_dev = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
const reg_email_prod = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,12})+$/;
const reg_email = process.env.REACT_APP_STAGE !== 'PROD' ? reg_email_dev : reg_email_prod;
const not_allow_whitespace = /^(?!\s*$)[-a-zA-Z0-9_:,.' ']{1,100}$/; // /^\S$|^\S[ \S]*\S$/;
const reg_alias = /^([\w]{3,8})?$/i;

// eslint-disable-next-line
const reg_youtube_url = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
const reg_password = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9_@./\\!@#$%^&*()+-]{8,}$/;

/**
 * @author Tuannk
 * @param {*} value Input value
 */
export const validateEmail = (value, field) => {
  if (value) {
    if (!reg_email.test(value)) {
      return `${field} is invalid!`;
    }
  } else {
    return `Please input your ${field}!`;
  }
};

/**
 * @author Tuanha
 * @param {*} value Input value
 */
export const validatePositive = (value, field) => {
  if (value < 0) {
    return `${field} must be positive number!`;
  }
};

/**
 * @author Tuanha
 * @param {*} value Input value
 */
export const isEmailValidate = value => {
  if (value) {
    return reg_email.test(value);
  }
};

/**
 * @author Tuanha
 * @param {*} value Input value
 */
export const isYoutubeLink = value => {
  if (value) {
    return reg_youtube_url.test(value);
  }
};

/**
 * @author Tuannk
 * @param {*} value Input value
 * @param {*} min Minimum of the input
 * @param {*} max Maximum of the input
 * @param {*} field e.g (Password, Fullname, etc...)
 */
export const validateInput = (value, min, max, field) => {
  if (value) {
    if (value.length < min) {
      return `${field} must be at least ${min} characters!`;
    // TODO: correct whitespace validation
    // } else if (value.length >= 1) {
    //   if (!not_allow_whitespace.test(value)) {
    //     return `${field} is not allowed whitespace!`;
    //   }
    } else if (value.length > max) {
      return `${field} cannot be longer than ${max} characters!`;
    }
  } else {
    return `Please input your ${field}!`;
  }
};

export const validateInputNotRequired = (value, min, max, field) => {
  if (value.length < min) {
    return `${field} must be at least ${min} characters!`;
  } else if (value.length >= 1) {
    if (!not_allow_whitespace.test(value)) {
      return `${field} is not allowed whitespace!`;
    }
  } else if (value.length > max) {
    return `${field} cannot be longer than ${max} characters!`;
  }
};

/**
 * @author Tuannk
 * @param {*} url URL or Hyperlink
 * @param {*} field e.g (Password, Fullname, Links, etc...)
 */
export const validateUrl = (url, field) => {
  if (url) {
    if (!reg_url.test(url)) {
      return `${field} is not valid!`;
    }
  } else {
    return `Please input your ${field}!`;
  }
};

/**
 * @author Tuannk
 * @param {*} value Input value
 * @param {*} field e.g (Password)
 */
export const validatePassword = (value, field, check = true) => {
  if (!check) return;
  if (value) {
    if (!reg_password.test(value)) {
      return `Your password must be at least 8 characters long, contain at least one number and have a mixture of uppercase and lowercase letters.`;
    }
  } else {
    return `Please input your ${field}!`;
  }
};

/**
 * @author HoangNL
 * @param {*} value Input value
 * @param {*} field e.g (Password)
 */
export const validateAlias = (value, field) => {
  if (!reg_alias.test(value)) {
    return 'Alias is invalid';
  }
  return '';
};
