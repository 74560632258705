import {
	GET_HOWITWORK_REQUEST,
	GET_HOWITWORK_SUCCESS,
	GET_HOWITWORK_FAILED,
	DELETE_HOWITWORK_REQUEST,
	DELETE_HOWITWORK_SUCCESS,
	DELETE_HOWITWORK_FAILED,
	CREATE_HOWITWORK_REQUEST,
	CREATE_HOWITWORK_SUCCESS,
	CREATE_HOWITWORK_FAILED,
	UPDATE_HOWITWORK_REQUEST,
	UPDATE_HOWITWORK_SUCCESS,
	UPDATE_HOWITWORK_FAILED,
	UPDATE_ORDER_HOWITWORK_FAILED,
	UPDATE_ORDER_HOWITWORK_SUCCESS
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';
import { updateHelper, compare } from '../../../core/utils/commonUtils';

const defaultState = {
	success: false,
	error: null,
	data: [],
	isLoading: false
};

const howitworkReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_HOWITWORK_REQUEST: {
			return {
				...state
			};
		}
		case GET_HOWITWORK_SUCCESS: {
			return {
				...state,
				...action.payload
			};
		}
		case GET_HOWITWORK_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				error: action.payload
			};
		}
		case DELETE_HOWITWORK_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case DELETE_HOWITWORK_SUCCESS: {
			openNotification('success', 'Delete successfully');
			return {
				...state,
				isLoading: false,
				data: state.data.filter(item => item._id !== action.payload)
			};
		}
		case DELETE_HOWITWORK_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case CREATE_HOWITWORK_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case CREATE_HOWITWORK_SUCCESS: {
			openNotification('success', 'Create successfully');
			return {
				...state,
				isLoading: false,
				data: [action.payload.data, ...state.data].sort(compare)
			};
		}
		case CREATE_HOWITWORK_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_HOWITWORK_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case UPDATE_HOWITWORK_SUCCESS: {
			openNotification('success', 'Update successful');
			return {
				...state,
				isLoading: false,
				data: updateHelper(state, action)
			};
		}
		case UPDATE_HOWITWORK_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_ORDER_HOWITWORK_SUCCESS: {
			return {
				...state,
				isLoading: false,
				data: action.payload.data
			};
		}
		case UPDATE_ORDER_HOWITWORK_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		default:
			return state;
	}
};

export default howitworkReducer;
