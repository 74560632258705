import { all } from 'redux-saga/effects';
import { watchBanner } from './admin/bannerSaga';
import { watchLogin } from './auth/loginSaga';
import { watchHowitwork } from './admin/howitworkSaga';
import { watchCustomerStories } from './admin/customerStoriesSaga';
import { watchHourRate } from './admin/hourRateSaga';
import { watchSkills } from './admin/skillsSaga';
import { watchPolicies } from './admin/policiesSaga';
import { watchServiceType } from './admin/serviceTypeSaga';
import { watchBudget } from './admin/budgetSaga';
import { watchItems } from './admin/itemsSaga';
import { watchCategories } from './admin/categoriesSaga';
import { watchPackage } from './admin/packageSaga';
import { watchAddhoc } from './admin/addhocSaga';
import { watchProjects } from './admin/projectsSaga';
import { watchSubCategories } from './admin/subCategoriesSaga';
import { watchUnits } from './admin/unitsSaga';
import { watchAddons } from './admin/addonsSaga';
import { watchProductTypes } from './admin/productTypesSaga';
import { watchCaseStudies } from './admin/caseStudiesSaga';
import { watchStories } from './admin/storiesSaga';
import { watchOurTeam } from './admin/ourTeamSaga';
import { watchOurTeamMembers } from './admin/ourTeamMembersSaga';
import { watchEditors } from './admin/editorsSaga';
import { watchActivities } from './admin/activitiesSaga';
import { watchAssets } from './admin/assetsSaga';
import { watchTopics } from './admin/topicsSaga';
import { watchMessage } from './admin/messageSaga';
import { watchNotification } from './admin/notificationSaga';
import { watchFilesAndLinks } from './admin/filesAndLinksSaga';
import { watchFaqs } from './admin/faqsSaga';
import { watchCustomers } from './admin/customersSaga';
import { watchTeams } from './admin/teamsSaga';
import { watchCreatives } from './admin/creativesSaga';
import { watchAdmins } from './admin/adminsSaga';
import { watchRoles } from './admin/rolesSaga';
import { watchUser } from './admin/userSaga';
import { watchEmailTemplate } from './admin/emailTemplateSaga';
import { watchForgotPassword } from './auth/forgotPasswordSaga';

export default function* rootSaga() {
  yield all([
    watchBanner(),
    watchLogin(),
    watchHowitwork(),
    watchCustomerStories(),
    watchCaseStudies(),
    watchHourRate(),
    watchSkills(),
    watchPolicies(),
    watchEditors(),
    watchServiceType(),
    watchBudget(),
    watchItems(),
    watchCategories(),
    watchPackage(),
    watchAddhoc(),
    watchProjects(),
    watchSubCategories(),
    watchUnits(),
    watchAddons(),
    watchProductTypes(),
    watchStories(),
    watchOurTeam(),
    watchOurTeamMembers(),
    watchActivities(),
    watchAssets(),
    watchTopics(),
    watchMessage(),
    watchNotification(),
    watchFilesAndLinks(),
    watchFaqs(),
    watchCustomers(),
    watchTeams(),
    watchCreatives(),
    watchAdmins(),
    watchRoles(),
    watchUser(),
    watchEmailTemplate(),
    watchForgotPassword(),
  ]);
}
