import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import LoginForm from '../../common/form/loginForm/loginForm';
import { Container } from 'react-bootstrap';
import './loginScreen.scss';
import { ADMIN_ROLES } from '../../../core/constants/constants';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: 'Login - VidOps'
		};
	}

	componentDidMount() {
		document.getElementById('sidebar-wrapper').style.display = 'none';
		document.getElementById('navbar-admin').style.display = 'none';
		document.getElementById('page-content-wrapper').style.background = '#ffffff';
	}

	onAuthHandler = data => {
		this.props.onAuth(data);
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.error && nextProps.error.code === 1051) {
			this.props.onReset();
		}
		if (nextProps.data !== this.props.data) {
			const foundRole = ADMIN_ROLES.find(item => item.value === nextProps.data.role);
			if (foundRole) {
				this.props.history.push('/');
			} else {
				this.props.history.push('/404');
			}
		}
	}

	componentWillUnmount() {
		document.getElementById('sidebar-wrapper').style.display = '';
		document.getElementById('navbar-admin').style.display = '';
		document.getElementById('page-content-wrapper').style.background = '#dddde0';
	}

	render() {
		return (
			<div className="login-page">
				<main role="main" className="page-content">
					<Container>
						<LoginForm
							onAuthHandler={this.onAuthHandler}
							isLoading={this.props.isLoading}
						/>
					</Container>
				</main>
			</div>
		);
	}
}

export default withTranslation('common')(Login);
