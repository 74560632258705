import { combineReducers } from 'redux';
import loginReducer from './auth/loginReducer';
import bannerReducer from './admin/bannerReducer';
import howitworkReducer from './admin/howitworkReducer';
import customerStoriesReducer from './admin/customerStoriesReducer';
import skillsReducer from './admin/skillsReducer';
import hourRateReducer from './admin/hourRateReducer';
import policiesReducer from './admin/policiesReducer';
import creativesReducer from './admin/creativesReducer';
import editorsReducer from './admin/editorsReducer';
import serviceTypeReducer from './admin/serviceTypeReducer';
import budgetReducer from './admin/budgetReducer';
import itemsReducer from './admin/itemsReducer';
import categoriesReducer from './admin/categoriesReducer';
import packageReducer from './admin/packageReducer';
import addhocReducer from './admin/addhocReducer';
import projectsReducer from './admin/projectsReducer';
import subCategoriesReducer from './admin/subCategoriesReducer';
import unitsReducer from './admin/unitsReducer';
import addonsReducer from './admin/addonsReducer';
import productTypeReducer from './admin/productTypesReducer';
import caseStudiesReducer from './admin/caseStudiesReducer';
import ourTeamMembersReducer from './admin/ourTeamMembersReducer';
import ourTeamReducer from './admin/ourTeamReducer';
import activitiesReducer from './admin/activitiesReducer';
import assetsReducer from './admin/assetsReducer';
import topicsReducer from './admin/topicsReducer';
import messageReducer from './admin/messageReducer';
import filesAndLinksReducer from './admin/filesAndLinksReducer';
import notificationReducer from './admin/notificationReducer';
import faqsReducer from './admin/faqsReducer';
import customersReducer from './admin/customersReducer';
import teamsReducer from './admin/teamsReducer';
import adminsReducer from './admin/adminsReducer';
import rolesReducer from './admin/rolesReducer';
import userReducer from './admin/userReducer';
import storiesReducer from './admin/storiesReducer';
import emailTemplateReducer from './admin/emailTemplateReducer';
import invitationsReducer from './admin/invitationsReducer';
import forgotPasswordReducer from './auth/forgotPasswordReducer';

const rootReducer = combineReducers({
  invitation: invitationsReducer,
  login: loginReducer,
  banner: bannerReducer,
  howitwork: howitworkReducer,
  customerStories: customerStoriesReducer,
  caseStudies: caseStudiesReducer,
  hour_rate: hourRateReducer,
  skills: skillsReducer,
  policies: policiesReducer,
  creatives: creativesReducer,
  editors: editorsReducer,
  serviceType: serviceTypeReducer,
  budget: budgetReducer,
  items: itemsReducer,
  categories: categoriesReducer,
  packages: packageReducer,
  addhoc: addhocReducer,
  projects: projectsReducer,
  subCategories: subCategoriesReducer,
  units: unitsReducer,
  addons: addonsReducer,
  productType: productTypeReducer,
  ourTeam: ourTeamReducer,
  ourTeamMembers: ourTeamMembersReducer,
  activities: activitiesReducer,
  assets: assetsReducer,
  topics: topicsReducer,
  message: messageReducer,
  notification: notificationReducer,
  filesAndLinks: filesAndLinksReducer,
  faqs: faqsReducer,
  customers: customersReducer,
  teams: teamsReducer,
  admins: adminsReducer,
  roles: rolesReducer,
  user: userReducer,
  stories: storiesReducer,
  emailTemplate: emailTemplateReducer,
  forgotPassword: forgotPasswordReducer,
});

export default rootReducer;
