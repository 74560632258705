import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../../actions/typeAction';
import {
  getCustomerStoriesService,
  deleteCustomerStoriesService,
  createCustomerStoriesService,
  updateCustomerStoriesService,
  getCustomerStoriesByHightLight,
} from '../../../core/services/modules/customerStoriesService';
import { updateOrderService } from '../../../core/services/modules/orderService';
import { openNotification } from '../../../components/common/notification/notification';

export function* getCustomerStoriesSaga() {
  const response = yield call(getCustomerStoriesService);
  if (response.data.error) {
    yield put({
      type: actionTypes.GET_CUSTOMER_STORIES_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: actionTypes.GET_CUSTOMER_STORIES_SUCCESS,
      payload: data,
    });
  }
}

export function* deleteCustomerStoriesSaga(action) {
  const response = yield call(deleteCustomerStoriesService, action.payload);
  if (response.data.error) {
    yield put({
      type: actionTypes.DELETE_CUSTOMER_STORIES_FAILED,
      payload: response.data.error,
    });
  } else {
    yield put({
      type: actionTypes.DELETE_CUSTOMER_STORIES_SUCCESS,
      payload: action.payload,
    });
  }
}

export function* createCustomerStoriesSaga(action) {
  const response = yield call(createCustomerStoriesService, action.payload);
  if (response.data.error) {
    yield put({
      type: actionTypes.CREATE_CUSTOMER_STORIES_FAILED,
      payload: response.data.error,
    });
  } else {
    yield put({
      type: actionTypes.CREATE_CUSTOMER_STORIES_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: actionTypes.GET_STORIES_HIGHT_LIGHT_REQUEST,
    });
    yield put({
      type: actionTypes.GET_CUSTOMER_STORIES_REQUEST,
    });
  }
}

export function* updateCustomerStoriesSaga(action) {
  const response = yield call(updateCustomerStoriesService, action.payload);
  if (response.data.error) {
    yield put({
      type: actionTypes.UPDATE_CUSTOMER_STORIES_FAILED,
      payload: response.data.error,
    });
  } else {
    yield put({
      type: actionTypes.UPDATE_CUSTOMER_STORIES_SUCCESS,
      payload: response.data,
    });
    yield put({
      type: actionTypes.GET_STORIES_HIGHT_LIGHT_REQUEST,
    });
    yield put({
      type: actionTypes.GET_CUSTOMER_STORIES_REQUEST,
    });
  }
}

export function* updateOrderSaga(action) {
  const response = yield call(updateOrderService, action.payload);
  if (response.data.error) {
    yield put({
      type: actionTypes.UPDATE_ORDER_CUSTOMER_STORIES_FAILED,
      payload: response.data.error,
    });
  } else {
    yield put({
      type: actionTypes.UPDATE_ORDER_CUSTOMER_STORIES_SUCCESS,
      payload: response.data,
    });
    openNotification('success', 'Update successful');
  }
}

export function* getStoriesHightLightSaga() {
  const ourStoriesLandingPage = yield call(getCustomerStoriesByHightLight, {
    isOurStoriesLandingPage: true,
  });
  const ourStoriesPage = yield call(getCustomerStoriesByHightLight, {
    isOurStoriesPage: true,
  });
  const latestWorkLandingPage = yield call(getCustomerStoriesByHightLight, {
    isLatestWorkLandingPage: true,
  });

  if (
    ourStoriesLandingPage.data.error ||
    ourStoriesPage.data.error ||
    latestWorkLandingPage.data.error
  ) {
    yield put({
      type: actionTypes.GET_STORIES_HIGHT_LIGHT_FAILED,
      payload: ourStoriesLandingPage.data.error,
    });
  } else {
    const data = {
      ourStoriesLandingPage: ourStoriesLandingPage.data.data,
      ourStoriesPage: ourStoriesPage.data.data,
      latestWorkLandingPage: latestWorkLandingPage.data.data,
    };

    yield put({
      type: actionTypes.GET_STORIES_HIGHT_LIGHT_SUCCESS,
      payload: data,
    });
  }
}

export function* watchCustomerStories() {
  yield takeLatest(actionTypes.GET_CUSTOMER_STORIES_REQUEST, getCustomerStoriesSaga);
  yield takeLatest(
    actionTypes.DELETE_CUSTOMER_STORIES_REQUEST,
    deleteCustomerStoriesSaga
  );
  yield takeLatest(
    actionTypes.CREATE_CUSTOMER_STORIES_REQUEST,
    createCustomerStoriesSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_CUSTOMER_STORIES_REQUEST,
    updateCustomerStoriesSaga
  );
  yield takeLatest(actionTypes.UPDATE_ORDER_CUSTOMER_STORIES_REQUEST, updateOrderSaga);
  yield takeLatest(actionTypes.GET_STORIES_HIGHT_LIGHT_REQUEST, getStoriesHightLightSaga);
}
