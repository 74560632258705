import { put, call, takeLatest } from 'redux-saga/effects';

import { GET_ACTIVITIES_FAILED, GET_ACTIVITIES_SUCCESS, GET_ACTIVITIES_REQUEST } from '../../actions/typeAction';
import { getActivitiesService } from '../../../core/services/modules/activitiesService';

export function* getActivitiesSaga(action) {
	const response = yield call(getActivitiesService, action.payload);
	if (response.data.error) {
		yield put({
			type: GET_ACTIVITIES_FAILED,
			payload: response.data.error
		});
	} else {
		const responseData = response.data;
		yield put({
			type: GET_ACTIVITIES_SUCCESS,
			payload: { responseData, params: action.payload }
		});
	}
}

export function* watchActivities() {
	yield takeLatest(GET_ACTIVITIES_REQUEST, getActivitiesSaga);
}
