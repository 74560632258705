import React, { PureComponent } from 'react';
import { Pagination, Menu, Button, Dropdown, Icon, Modal } from 'antd';
import { Jumbotron, Container } from 'react-bootstrap';
import { storageService } from '../../../../core/services/storageService';
import moment from 'moment';
import DataTable from '../../../common/dragTable/dragTable';
import { ADMIN_ROLES, ADMIN_STATUS } from '../../../../core/constants/constants';
import AdminInvitationModal from './adminInvitationModal/adminInvitationModal';
import ConfirmUpdateModal from './confirmUpdateModal/confirmUpdateModal';
import AdminFullName from './adminFullName';
import { deleteAdminService } from '../../../../core/services/modules/adminsService';
import { openNotification } from '../../../common/notification/notification';
import './adminsScreen.scss';
const { confirm } = Modal;

class AdminsScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Admins - VidOps',
      currentPage: 1,
      currentLimit: '',
      modalVisible: false,
      modalType: '',
      dataUpdate: null,
    };
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
      modalType: '',
    });
  };

  openModal = type => {
    this.setState({
      modalVisible: true,
      modalType: type,
    });
  };

  componentDidMount() {
    const { currentPage, currentLimit } = this.state;
    this.props.onGetCurrentUser();
    this.getAdmins({
      page: currentPage,
      limit: currentLimit,
      status: '',
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.admins !== this.props.admins) {
      this.closeModal();
    }
  }

  getAdmins = params => {
    this.props.onGetRoles();
    this.props.onGetAdmins(params);
  };

  renderRoleName = name => {
    const indexName = ADMIN_ROLES.findIndex(item => item.value === name);
    return indexName > -1 ? ADMIN_ROLES[indexName].label : null;
  };

  renderMenuDropdown = (arr, record, field) => {
    if (field === 'role') {
      return (
        <Menu>
          {arr &&
            arr.length &&
            arr.map(item => (
              <Menu.Item key={item._id}>
                <span
                  onClick={() => {
                    this.props.onUpdateAdmin({
                      id: record._id,
                      roleId: item._id,
                    });
                  }}
                >
                  {this.renderRoleName(item.name)}
                </span>
              </Menu.Item>
            ))}
        </Menu>
      );
    }
    return (
      <Menu>
        {arr &&
          arr.length &&
          arr.map(item => (
            <Menu.Item key={item.value}>
              <span
                onClick={() => {
                  if (item.value === 'blocked') {
                    this.setState({
                      dataUpdate: {
                        id: record._id,
                        status: item.value,
                      },
                    });
                    this.openModal('confirm');
                  } else {
                    this.props.onUpdateAdmin({
                      id: record._id,
                      status: item.value,
                    });
                  }
                }}
              >
                {item.label}
              </span>
            </Menu.Item>
          ))}
      </Menu>
    );
  };

  renderDropdown = (arr, record, currentItem, field) => {
    return (
      <Dropdown overlay={this.renderMenuDropdown(arr, record, field)} trigger={['click']}>
        <span className="ant-dropdown-link tier-text" onClick={e => e.preventDefault()}>
          {currentItem.label || null} <Icon type="down" />
        </span>
      </Dropdown>
    );
  };

  renderRolesDropdown = record => {
    const { roles } = this.props;
    const { userId } = record;
    const roleId = (userId && userId.roleId) || null;
    const roleName = (roleId && roleId.name) || null;
    const otherAdminRoles =
      roles &&
      roles.length &&
      roles.filter(item => {
        const itemIndex = ADMIN_ROLES.findIndex(el => el.value === item.name);
        return itemIndex > -1 && item.name !== roleName;
      });
    const currentRole = ADMIN_ROLES.find(item => item.value === roleName);
    return this.renderDropdown(otherAdminRoles, record, currentRole, 'role');
  };

  renderStatusDropdown = record => {
    const status = record.status || null;
    const otherStatus = ADMIN_STATUS.filter(item => item.value !== status);
    const currentStatus = ADMIN_STATUS.find(item => item.value === status);
    return this.renderDropdown(otherStatus, record, currentStatus, 'status');
  };

  onHandleDeleteUser = async record => {
    const { userId } = record;
    try {
      const body = {
        id: userId._id,
        isDelete: true,
      };
      const res = await deleteAdminService(body);
      const { data } = res;
      if (data.error) {
        return openNotification('error', data.error.message);
      } else if (data.success) {
        const { currentPage, currentLimit } = this.state;
        this.getAdmins({
          page: currentPage,
          limit: currentLimit,
          status: '',
        });
        return openNotification('success', 'Delete successfully');
      }
    } catch (e) {}
  };

  formatColumns = () => {
    return [
      {
        title: 'Full Name',
        dataIndex: 'userId[fullName]',
        render: (dataIndex, record) => {
          const { avt } = (record && record.userId) || {};
          return <AdminFullName fullName={dataIndex} avt={avt} />;
        },
      },
      {
        title: 'Email',
        dataIndex: 'userId[email]',
      },
      {
        title: 'Role',
        dataIndex: 'userId',
        render: (dataIndex, record) => {
          const { userId } = record;
          const roleId = (userId && userId.roleId) || null;
          const roleName = (roleId && roleId.name) || null;
          const foundRole = ADMIN_ROLES.find(item => item.value === roleName);
          return foundRole ? this.renderRolesDropdown(record) : null;
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status, record) => {
          const statusIndex = ADMIN_STATUS.findIndex(item => item.value === status);
          return statusIndex > -1 ? this.renderStatusDropdown(record) : null;
        },
      },
      {
        title: 'Date Joined',
        dataIndex: 'createdAt',
        render: (createdAt, record) =>
          createdAt && moment(createdAt).format('DD/MM/YYYY'),
      },
      {
        title: 'Action',
        render: (createdAt, record) => {
          const currentAdmin = storageService.getCurrentUser();
          const isShowDeleteButon =
            currentAdmin.role === 'superadmin' &&
            record &&
            record.userId &&
            record.userId.roleId &&
            record.userId.roleId.name !== 'superadmin';

          return isShowDeleteButon ? (
            <Button
              className="btn-primary-0"
              onClick={() =>
                confirm({
                  title: 'Delete account',
                  content: 'Are you sure want to delete this account ?',
                  okText: 'No',
                  cancelText: 'Yes',
                  className: 'confirm-delete-account',
                  onCancel: () => this.onHandleDeleteUser(record),
                })
              }
              type="primary"
            >
              Delete
            </Button>
          ) : null;
        },
      },
    ];
  };

  formatData = data => {
    if (data && data.length) {
      return data.map((item, index) => {
        return {
          ...item,
          key: item._id,
        };
      });
    }
  };

  onChangePage = page => {
    const { currentLimit } = this.state;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        this.props.onGetAdmins({
          page,
          limit: currentLimit,
          status: '',
        });
      }
    );
  };

  getAdminsRoles = () => {
    const { roles } = this.props;
    return (
      roles &&
      roles.length &&
      roles.filter(item => {
        const itemIndex = ADMIN_ROLES.findIndex(el => el.value === item.name);
        return itemIndex > -1;
      })
    );
  };

  confirmHandler = () => {
    const { dataUpdate } = this.state;
    this.props.onUpdateAdmin(dataUpdate);
  };

  render() {
    const { modalVisible, modalType } = this.state;
    const { admins } = this.props;
    let dataSource = [];
    const columns = this.formatColumns();
    if (admins && admins.list) {
      dataSource = this.formatData(admins.list);
    }

    return (
      <main role="main" className="page-content admins-screen">
        <Jumbotron className="mgt-40">
          <h2 className="sub-heading">All Admins</h2>
          <p className="description">Manage all admins</p>
          <div className="divider"></div>
          <Container>
            <Button
              className="btn-primary-0"
              onClick={() => this.openModal('create')}
              type="primary"
              style={{ marginBottom: 16 }}
            >
              Add
            </Button>
            <div className="control-container">
              {!!admins.total && (
                <Pagination
                  className="customer-pagination"
                  total={admins.total}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  pageSize={10}
                  simple={false}
                  onChange={this.onChangePage}
                />
              )}
            </div>
            <DataTable
              columns={columns}
              dataSource={dataSource}
              onChangeHandler={this.handleChangeTable}
              hiddenUpdateOrder={true}
            />
            <AdminInvitationModal
              modalVisible={modalVisible && modalType === 'create'}
              onCloseModal={this.closeModal}
              adminRoles={this.getAdminsRoles()}
            />
            <ConfirmUpdateModal
              modalVisible={modalVisible && modalType === 'confirm'}
              onCloseModal={this.closeModal}
              onConfirm={this.confirmHandler}
            />
          </Container>
        </Jumbotron>
      </main>
    );
  }
}

export default AdminsScreen;
