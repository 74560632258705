import {
	GET_SUB_CATEGORIES_REQUEST,
	GET_SUB_CATEGORIES_SUCCESS,
	GET_SUB_CATEGORIES_FAILED,
	CREATE_SUB_CATEGORIES_REQUEST,
	CREATE_SUB_CATEGORIES_SUCCESS,
	CREATE_SUB_CATEGORIES_FAILED,
	UPDATE_SUB_CATEGORIES_REQUEST,
	UPDATE_SUB_CATEGORIES_SUCCESS,
	UPDATE_SUB_CATEGORIES_FAILED,
	UPDATE_ORDER_SUB_CATEGORIES_SUCCESS,
	UPDATE_ORDER_SUB_CATEGORIES_FAILED
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';
import { updateHelper, compare } from '../../../core/utils/commonUtils';

const defaultState = {
	success: false,
	error: null,
	data: [],
	isLoading: false
};

const subCategoriesReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_SUB_CATEGORIES_REQUEST: {
			return {
				...state
			};
		}
		case GET_SUB_CATEGORIES_SUCCESS: {
			return {
				...state,
				...action.payload
			};
		}
		case GET_SUB_CATEGORIES_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				error: action.payload
			};
		}
		case CREATE_SUB_CATEGORIES_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case CREATE_SUB_CATEGORIES_SUCCESS: {
			openNotification('success', 'Create successfully');
			return {
				...state,
				isLoading: false,
				data: [action.payload.data, ...state.data].sort(compare)
			};
		}
		case CREATE_SUB_CATEGORIES_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_SUB_CATEGORIES_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case UPDATE_SUB_CATEGORIES_SUCCESS: {
			openNotification('success', 'Update successful');
			return {
				...state,
				isLoading: false,
				data: updateHelper(state, action)
			};
		}
		case UPDATE_SUB_CATEGORIES_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_ORDER_SUB_CATEGORIES_SUCCESS: {
			return {
				...state,
				isLoading: false,
				data: action.payload.data
			};
		}
		case UPDATE_ORDER_SUB_CATEGORIES_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		default:
			return state;
	}
};

export default subCategoriesReducer;
