import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';
import qs from 'src/core/utils/querystring';

export const getNotification = (page, limit) => {
  const queryString = qs.stringify(
    JSON.parse(
      JSON.stringify({
        page,
        limit,
      })
    )
  );
  const url = BaseConfig.END_POINT.ADMIN.NOTIFICATION.LIST;
  const response = httpService._get(`${url}?${queryString}`);
  return response;
};

export const readNotification = id => {
  const url = BaseConfig.END_POINT.ADMIN.NOTIFICATION.LIST;
  const response = httpService._put(`${url}/${id}`);
  return response;
};

export const readAllNotifications = () => {
  const url = BaseConfig.END_POINT.ADMIN.NOTIFICATION.READ_ALL;
  return httpService._post(url);
};

export const countNotification = () => {
  const url = BaseConfig.END_POINT.ADMIN.NOTIFICATION.COUNT;
  const response = httpService._get(url);
  return response;
};

export const syncNotificationCount = () => {
  const url = BaseConfig.END_POINT.ADMIN.SYNC_NOTIFICATION;
  const response = httpService._post(url)
  return response;
}