import React from 'react';
import 'antd/dist/antd.css';
import './itemsForm.scss';
import { Form, Input, Button, Select, Icon, InputNumber } from 'antd';
import { validatePositive } from '../../../../core/utils/customValidator';

const { Option } = Select;
const { TextArea } = Input;
let id = 0;
class ItemsForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	formatSubContent = (subName, subDescription) => {
		const subContent = [];
		if (subName && subDescription && subName.length && subDescription.length) {
			for (let i = 0; i < subName.length; i++) {
				subContent.push({
					name: subName[i],
					description: subDescription[i]
				});
			}
			return subContent;
		}
		return [];
	};

	filterSubcontent = subContent => {
		if (subContent && subContent.length) {
			return subContent.filter(item => item);
		}
		return [];
	};

	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const { isCreate, itemUpdate, currentService } = this.props;
				const names = this.filterSubcontent(values.subName);
				const descriptions = this.filterSubcontent(values.subDescription);
				const subContent = this.formatSubContent(names, descriptions);
				const name = values.name.trim();
				const status = values.status.trim() === 'Active' ? true : false;
				const description = values.description.trim();
				const measureUnitId = values.unit;
				let prices;
				let order;
				if (isCreate) {
					order = 0;
					prices = currentService.packages.map(item => ({
						packageId: item._id,
						name: item.name,
						price: values[`price-${item.name}`] !== null ? Number(values[`price-${item.name}`]) : null
					}));
				} else {
					order = values.order;
					prices = itemUpdate.packages.map(item => ({
						...item,
						price:
							values[`price-${item.name}`] !== null && values[`price-${item.name}`] !== ''
								? Number(values[`price-${item.name}`])
								: null
					}));
				}
				const data = {
					name,
					status,
					subContent,
					prices,
					description,
					measureUnitId,
					order
				};
				this.props.isCreate ? this.props.onCreateItems(data) : this.props.onUpdateItems(data);
			}
		});
	};

	addInput = () => {
		const { form } = this.props;
		const keys = form.getFieldValue('keys');
		const nextKeys = !keys.length ? keys.concat(id++) : keys.concat(Math.max(...keys) + 1000);
		form.setFieldsValue({
			keys: nextKeys
		});
	};

	removeInput = k => {
		const { form } = this.props;
		const keys = form.getFieldValue('keys');
		form.setFieldsValue({
			keys: keys.filter(key => key !== k)
		});
	};

	renderDynamicForm() {
		const { getFieldDecorator, getFieldValue } = this.props.form;
		const { itemUpdate, isCreate } = this.props;
		const numberKeys =
			!isCreate && itemUpdate
				? itemUpdate.subContent.map((item, index) => {
						return index;
				  })
				: [];
		getFieldDecorator('keys', {
			initialValue: itemUpdate && itemUpdate.subContent.length > 0 ? numberKeys : []
		});
		const keys = getFieldValue('keys');
		return (
			<React.Fragment>
				<Form.Item className="mgb-unset text-dark-0 mgb-8">Sub-items</Form.Item>
				{keys.map((k, index) => (
					<React.Fragment key={k}>
						<Form.Item required={false} className="unset-line-height mgb-unset subcontent-form-item">
							{getFieldDecorator(`subName[${k}]`, {
								validateTrigger: ['onChange', 'onBlur'],
								initialValue: isCreate
									? ''
									: itemUpdate.subContent[k]
									? itemUpdate.subContent[k].name
									: '',
								rules: [
									{
										required: true,
										message: 'Please input name'
									},
									{
										whitespace: true,
										message: 'Please do not input whitespace only'
									}
								]
							})(<Input placeholder="Name" className="custom-antd-2" />)}
						</Form.Item>
						<Form.Item required={false} className="unset-line-height mgb-unset subcontent-form-item">
							{getFieldDecorator(`subDescription[${k}]`, {
								validateTrigger: ['onChange', 'onBlur'],
								initialValue: isCreate
									? ''
									: itemUpdate.subContent[k]
									? itemUpdate.subContent[k].description
									: '',
								rules: [
									{
										required: true,
										message: 'Please input description'
									},
									{
										whitespace: true,
										message: 'Please do not input whitespace only'
									}
								]
							})(<Input placeholder="Description" className="custom-antd-2" />)}
						</Form.Item>
						<Form.Item className="delete-container">
							<Icon placeholder="Description" type="close-circle" onClick={() => this.removeInput(k)} />
						</Form.Item>
					</React.Fragment>
				))}
				<Form.Item>
					<Button type="dashed" onClick={this.addInput} style={{ width: '45%' }}>
						<Icon type="plus" /> Add
					</Button>
				</Form.Item>
			</React.Fragment>
		);
	}

	renderSelect = arr => {
		if (arr && arr.length) {
			return arr.map(item => (
				<Option key={item} value={item._id}>
					{item.name}
				</Option>
			));
		}
	};

	onProductTypesChangeHandler = value => {
		this.props.onProductTypesChange(value);
	};

	renderPackages = () => {
		const { getFieldDecorator } = this.props.form;
		const { currentService } = this.props;
		const packages = currentService.packages && currentService.packages.length ? currentService.packages : [];
		if (packages && packages.length) {
			return (
				<div className="prices-container">
					{packages.map(item => (
						<Form.Item key={item._id} className="items-price" label={item.name}>
							{getFieldDecorator(`price-${item.name}`, {
								rules: [
									{
										validator: (rule, value, callback) => {
											const msg = validatePositive(value, 'Price');
											if (msg) {
												callback(msg);
											} else {
												callback();
											}
										}
									}
								],
								initialValue: null
							})(<Input type="number" placeholder="Price" size="large" step={0.01} />)}
						</Form.Item>
					))}
				</div>
			);
		}
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { isCreate, itemUpdate, isLoading, units } = this.props;
		const unitRender = units.map(item => (
			<Option key={item._id} value={item._id}>
				{item.name}
			</Option>
		));
		if (isCreate) {
			return (
				<Form className="items-form">
					<Form.Item label="Name">
						{getFieldDecorator('name', {
							rules: [
								{
									required: true,
									message: 'Please input items name'
								},
								{
									whitespace: true,
									message: 'Please do not input whitespace only'
								}
							],
							initialValue: null
						})(<Input placeholder="Name" size="large" />)}
					</Form.Item>
					<Form.Item label="Unit" className="unit-item">
						{getFieldDecorator('unit', {
							rules: [
								{
									required: true,
									message: 'Please select unit'
								}
							],
							initialValue: units[0]._id
						})(<Select>{unitRender}</Select>)}
					</Form.Item>
					{this.renderPackages()}
					<Form.Item label="Description">
						{getFieldDecorator('description', {
							rules: [
								{
									required: true,
									message: 'Please input price description'
								},
								{
									whitespace: true,
									message: 'Please do not input whitespace only'
								}
							],
							initialValue: null
						})(<TextArea rows={4} />)}
					</Form.Item>
					{this.renderDynamicForm()}
					<Form.Item label="Status" className="status-item">
						{getFieldDecorator('status', {
							rules: [
								{
									required: true,
									message: 'Please select items status'
								}
							],
							initialValue: 'Active'
						})(
							<Select>
								<Option value="Active">Active</Option>
								<Option value="Inactive">Inactive</Option>
							</Select>
						)}
					</Form.Item>
					<Form.Item>
						<Button
							type="primary"
							loading={isLoading}
							onClick={this.handleSubmit}
							className="items-form-button btn-primary-0"
							size="large"
							disabled={isLoading}
						>
							Create
						</Button>
					</Form.Item>
				</Form>
			);
		} else {
			return (
				<Form className="items-form">
					<Form.Item label="Name">
						{getFieldDecorator('name', {
							rules: [
								{
									required: true,
									message: 'Please input items name'
								},
								{
									whitespace: true,
									message: 'Please do not input whitespace only'
								}
							],
							initialValue: itemUpdate.name
						})(<Input placeholder="Name" size="large" />)}
					</Form.Item>
					<Form.Item label="Unit" className="unit-item">
						{getFieldDecorator('unit', {
							rules: [
								{
									required: true,
									message: 'Please select unit'
								}
							],
							initialValue: itemUpdate.measureUnitId._id
						})(<Select>{unitRender}</Select>)}
					</Form.Item>
					<div className="prices-container">
						{itemUpdate.packages.map(item => (
							<Form.Item key={item.packageId} className="items-price" label={item.name}>
								{getFieldDecorator(`price-${item.name}`, {
									rules: [
										{
											validator: (rule, value, callback) => {
												const msg = validatePositive(value, 'Price');
												if (msg) {
													callback(msg);
												} else {
													callback();
												}
											}
										}
									],
									initialValue: item.price !== null ? item.price.toString() : ''
								})(<Input type="number" placeholder="Price" size="large" step={0.01} />)}
							</Form.Item>
						))}
					</div>
					<Form.Item label="Description">
						{getFieldDecorator('description', {
							rules: [
								{
									required: true,
									message: 'Please input price description'
								},
								{
									whitespace: true,
									message: 'Please do not input whitespace only'
								}
							],
							initialValue: itemUpdate.description
						})(<TextArea rows={4} />)}
					</Form.Item>
					{this.renderDynamicForm()}
					<Form.Item label="Order">
						{getFieldDecorator('order', {
							rules: [
								{
									required: true,
									message: 'Please input price order'
								}
							],
							initialValue: itemUpdate.order
						})(<InputNumber min={0} />)}
					</Form.Item>
					<Form.Item label="Status" className="status-item">
						{getFieldDecorator('status', {
							rules: [
								{
									required: true,
									message: 'Please select items status'
								}
							],
							initialValue: itemUpdate.status ? 'Active' : 'Inactive'
						})(
							<Select>
								<Option value="Active">Active</Option>
								<Option value="Inactive">Inactive</Option>
							</Select>
						)}
					</Form.Item>
					<Form.Item>
						<Button
							type="primary"
							loading={isLoading}
							onClick={this.handleSubmit}
							className="items-form-button btn-primary-0"
							size="large"
							disabled={isLoading}
						>
							Update
						</Button>
					</Form.Item>
				</Form>
			);
		}
	}
}

const WrappedItemsForm = Form.create({ name: 'normal_items' })(ItemsForm);

export default WrappedItemsForm;
