import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Avatar, Select } from 'antd';
import ReactPhoneInput from 'react-phone-input-2';
import debounce from 'lodash/debounce';
import { updateProjects } from '../../../../../redux/actions/admin/projectsAction';
import { getTeams, loadMoreTeams } from '../../../../../redux/actions/admin/teamsAction';
import {
  getCustomers,
  loadMoreCustomers,
} from '../../../../../redux/actions/admin/customersAction';
import './customerInformation.scss';
import SelectFilter from '../../../selectFilter/selectFilter';
import { renderRequireLabel } from '../../../../../core/utils/commonUtils';
import CustomerInvitationModal from './customerInvitationModal/customerInvitationModal';
import FilledWarningIcon from '../../../../../assets/images/icons/common/filled-warning.svg';

class CustomerInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      currentTeamPage: 1,
      searchTextTeam: '',
      currentCustomerPage: 1,
      searchTextCustomer: '',
      hasChooseTeam: false,
      modalVisible: false,
      selected: null, // set to reset-select to fix choose teamwork to reset select customer
      teams: null, // to search customer by team in select customer
    };
    this.searchTeamsHandler = debounce(value => {
      this.setState({
        currentTeamPage: 1,
        searchTextTeam: value.trim(),
      });
      this.props.onGetTeams({
        page: 1,
        limit: 10,
        searchText: value.trim(),
      });
    }, 500);
    this.searchCustomersHandler = debounce(value => {
      this.setState({
        currentCustomerPage: 1,
        searchTextCustomer: value.trim(),
      });
      this.props.onGetCustomers({
        page: 1,
        limit: 10,
        searchText: value.trim(),
        teams: this.state.teams,
      });
    }, 600);
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
  };

  openModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  onUpdate = (property, value) => {
    const { currentProject } = this.props;
    const valueData = typeof value === 'string' ? value.trim() : value;
    this.props.onUpdateProject({
      id: currentProject._id,
      [property]: valueData,
    });
  };

  handleLoadMoreTeams = ({ page }) => {
    const { searchTextTeam } = this.state;
    this.props.onLoadMoreTeams({
      page,
      limit: 10,
      searchText: searchTextTeam,
    });
  };

  handleLoadMoreCustomers = ({ page }) => {
    const { searchTextCustomer } = this.state;
    this.props.onLoadMoreCustomers({
      page,
      limit: 10,
      searchText: searchTextCustomer,
    });
  };

  handleSelectTeamName = name => {
    const { currentCustomerPage } = this.state;
    const filterParams = {
      page: currentCustomerPage,
      limit: 10,
      teams: name,
      // status: 'active',
    };
    // reset select customer when select team name
    this.setState({ selected: 'reset-select', teams: name });
    this.props.onGetCustomers(filterParams);
    this.props.onGetTeams({
      page: 1,
      limit: 10,
    });
  };

  handleSelectCustomer = value => {
    this.setState({ selected: null }, () =>
      this.props.onInputFields('customerId', value)
    );
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.customers !== this.props.customers ||
      prevProps.invitedCustomer !== this.props.invitedCustomer
    ) {
      this.setState({
        hasChooseTeam: true,
      });
    }
    if (prevProps.invitedCustomer !== this.props.invitedCustomer) {
      this.setState({
        hasInvitedCustomer: true,
      });
      this.props.onInputFields('customerId', this.props.invitedCustomer._id);
    }
  }

  componentDidMount() {
    const { currentProject } = this.props;
    const { currentTeamPage } = this.state;
    if (!currentProject) {
      this.props.onGetTeams({
        page: currentTeamPage,
        limit: 10,
      });
    }
  }

  renderCustomersByTeam = () => {
    const { modalVisible } = this.state;
    const { teams, customers } = this.props;
    const { hasChooseTeam } = this.state;
    const customersList = (customers && customers.list) || [];
    const customersData = {
      ...customers,
      list: customersList.length
        ? customersList.map(item => ({
            ...item,
            name: item.userId && item.userId.fullName,
          }))
        : customersList,
    };
    return (
      <div className="customer-assign-container">
        <Form.Item label="Team Name" className="project-form-item">
          <SelectFilter
            data={teams}
            onSearchHandler={this.searchTeamsHandler}
            onLoadMoreHandler={this.handleLoadMoreTeams}
            onSelect={this.handleSelectTeamName}
            type="team"
          />
        </Form.Item>
        <Form.Item
          label={renderRequireLabel('Project Owner')}
          className="project-form-item"
        >
          <SelectFilter
            data={customersData}
            onSearchHandler={this.searchCustomersHandler}
            onLoadMoreHandler={this.handleLoadMoreCustomers}
            onSelect={this.handleSelectCustomer}
            disabled={!hasChooseTeam}
            selected={this.state.selected}
          />
          <Avatar className="icon-plus" icon="plus" onClick={this.openModal} />
        </Form.Item>
        <CustomerInvitationModal
          requirePhone={true}
          modalVisible={modalVisible}
          onCloseModal={this.closeModal}
        />
      </div>
    );
  };

  renderInvitedCustomer = () => {
    const { modalVisible } = this.state;
    const { invitedCustomer } = this.props;
    const fullName = (invitedCustomer.userId && invitedCustomer.userId.fullName) || null;
    return (
      <div className="customer-assign-container">
        <Form.Item label="Team Name" className="project-form-item">
          <Select disabled defaultValue={''}></Select>
        </Form.Item>
        <Form.Item
          label={renderRequireLabel('Project Owner')}
          className="project-form-item"
        >
          <Select disabled defaultValue={fullName}></Select>
          <img src={FilledWarningIcon} alt="Warning Icon" className="warning-icon" />{' '}
          <span className="warning-text">Account sign-up pending</span>
        </Form.Item>
        <CustomerInvitationModal
          requirePhone={true}
          modalVisible={modalVisible}
          onCloseModal={this.closeModal}
        />
      </div>
    );
  };

  renderCustomerInfomation = () => {
    const { getFieldDecorator } = this.props.form;
    const { currentProject, hasAssigned } = this.props;
    const customer = (currentProject && currentProject.customerId) || null;
    const phoneNumberOfCustomer =
      (customer && customer.userId && customer.userId.phoneNumber) || '';
    const hotLineOfCustomer =
      (customer && customer.userId && customer.userId.hotLine) || '';
    const fullName = (customer && customer.userId && customer.userId.fullName) || '';
    const email = (customer && customer.userId && customer.userId.email) || '';
    const company = (customer && customer.userId && customer.userId.company) || '';
    const isProjectDeleted = currentProject && currentProject.isDelete;
    const isArchive = currentProject && currentProject.isArchive;
    return (
      !isArchive && (
        <div className="information-section">
          <h6 className="information-title">Customer Information</h6>
          <Form.Item label="Customer Name" className="project-form-item">
            {getFieldDecorator('customerName', {
              initialValue: fullName,
            })(
              <Input
                onPressEnter={e => this.onUpdate('customerName', e.target.value)}
                placeholder="Name"
                size="large"
                disabled={true}
              />
            )}
          </Form.Item>
          {company && (
            <Form.Item label="Company" className="project-form-item">
              {getFieldDecorator('company', {
                initialValue: company,
              })(<Input placeholder="Company" size="large" disabled={true} />)}
            </Form.Item>
          )}
          <Form.Item label="Email" className="project-form-item">
            {getFieldDecorator('customerEmail', {
              initialValue: email,
            })(
              <Input
                onPressEnter={e => this.onUpdate('customerEmail', e.target.value)}
                placeholder="Email"
                size="large"
                disabled={true}
              />
            )}
          </Form.Item>
          <Form.Item label="Phone" className="project-form-item">
            {phoneNumberOfCustomer ? (
              <ReactPhoneInput
                country={'gb'}
                onBlur={e => {
                  if (phoneNumberOfCustomer !== e.target.value) {
                    this.onUpdate('phoneNumberOfCustomer', e.target.value);
                  }
                }}
                value={phoneNumberOfCustomer}
                disabled={!hasAssigned || isProjectDeleted}
              />
            ) : (
              <ReactPhoneInput
                country={'gb'}
                onBlur={e => {
                  if (phoneNumberOfCustomer !== e.target.value) {
                    this.onUpdate('phoneNumberOfCustomer', e.target.value);
                  }
                }}
                disabled={!hasAssigned || isProjectDeleted}
              />
            )}
          </Form.Item>
          <Form.Item label="Hotline" className="project-form-item">
            {hotLineOfCustomer ? (
              <ReactPhoneInput
                country={'gb'}
                onBlur={e => {
                  if (hotLineOfCustomer !== e.target.value) {
                    this.onUpdate('hotLineOfCustomer', e.target.value);
                  }
                }}
                value={hotLineOfCustomer}
                disabled={!hasAssigned || isProjectDeleted}
              />
            ) : (
              <ReactPhoneInput
                country={'gb'}
                onBlur={e => {
                  if (hotLineOfCustomer !== e.target.value) {
                    this.onUpdate('hotLineOfCustomer', e.target.value);
                  }
                }}
                disabled={!hasAssigned || isProjectDeleted}
              />
            )}
          </Form.Item>
        </div>
      )
    );
  };

  render() {
    const { hasInvitedCustomer } = this.state;
    const { currentProject, isArchive } = this.props;
    if (currentProject) {
      return this.renderCustomerInfomation();
    } else if (isArchive) {
      return null;
    } else {
      return !hasInvitedCustomer
        ? this.renderCustomersByTeam()
        : this.renderInvitedCustomer();
    }
  }
}

const mapStateToProps = state => {
  return {
    teams: state.teams.data,
    customers: state.customers.data,
    invitedCustomer: state.customers.detailData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateProject: data => {
      dispatch(updateProjects(data));
    },
    onGetTeams: params => {
      dispatch(getTeams(params));
    },
    onLoadMoreTeams: params => {
      dispatch(loadMoreTeams(params));
    },
    onGetCustomers: params => {
      dispatch(getCustomers(params));
    },
    onLoadMoreCustomers: params => {
      dispatch(loadMoreCustomers(params));
    },
  };
};

const WrappedCustomerInformation = Form.create({ name: 'normal_customer_information' })(
  connect(mapStateToProps, mapDispatchToProps)(CustomerInformation)
);

export default WrappedCustomerInformation;
