import {
	GET_OUR_TEAM_MEMBERS_REQUEST,
	GET_OUR_TEAM_MEMBERS_SUCCESS,
	GET_OUR_TEAM_MEMBERS_FAILED,
	CREATE_OUR_TEAM_MEMBERS_REQUEST,
	CREATE_OUR_TEAM_MEMBERS_SUCCESS,
	CREATE_OUR_TEAM_MEMBERS_FAILED,
	UPDATE_OUR_TEAM_MEMBERS_REQUEST,
	UPDATE_OUR_TEAM_MEMBERS_SUCCESS,
	UPDATE_OUR_TEAM_MEMBERS_FAILED,
	UPDATE_ORDER_OUR_TEAM_MEMBERS_FAILED,
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';
import { updateHelper, compare } from '../../../core/utils/commonUtils';

const defaultState = {
	success: false,
	error: null,
	data: [],
	isLoading: false
};

const ourTeamMembersReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_OUR_TEAM_MEMBERS_REQUEST: {
			return {
				...state
			};
		}
		case GET_OUR_TEAM_MEMBERS_SUCCESS: {
			return {
				...state,
				...action.payload
			};
		}
		case GET_OUR_TEAM_MEMBERS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				error: action.payload
			};
		}
		case CREATE_OUR_TEAM_MEMBERS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case CREATE_OUR_TEAM_MEMBERS_SUCCESS: {
			openNotification('success', 'Create successfully');
			return {
				...state,
				isLoading: false,
				data: [action.payload.data, ...state.data].sort(compare)
			};
		}
		case CREATE_OUR_TEAM_MEMBERS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_OUR_TEAM_MEMBERS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case UPDATE_OUR_TEAM_MEMBERS_SUCCESS: {
			openNotification('success', 'Update successful');
			return {
				...state,
				isLoading: false,
				data: updateHelper(state, action)
			};
		}
		case UPDATE_OUR_TEAM_MEMBERS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_ORDER_OUR_TEAM_MEMBERS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		default:
			return state;
	}
};

export default ourTeamMembersReducer;
