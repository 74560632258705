import {
  SEND_EMAIL_FAILED,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESEND_EMAIL_FAILED,
  RESEND_EMAIL_REQUEST,
  RESEND_EMAIL_SUCCESS,
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';

const defaultState = {
  success: false,
  error: null,
  data: [],
  isLoading: false,
  resetSuccess: false,
  resendSuccess: false,
};

const skillsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SEND_EMAIL_REQUEST: {
      return {
        ...state,
        success: false,
        isLoading: true,
      };
    }
    case SEND_EMAIL_SUCCESS: {
      openNotification('success', 'Password reset email sent');

      return {
        ...state,
        success: true,
        isLoading: false,
        ...action.payload,
      };
    }
    case SEND_EMAIL_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        success: false,
        isLoading: false,
        error: action.payload,
      };
    }
    case RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      openNotification('success', 'Reset password successfully');
      return {
        ...state,
        isLoading: false,
        resetSuccess: action.payload.success,
      };
    }
    case RESET_PASSWORD_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case RESEND_EMAIL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RESEND_EMAIL_SUCCESS: {
      openNotification('success', 'Password reset email sent');
      return {
        ...state,
        isLoading: false,
        resendSuccess: action.payload.success,
      };
    }
    case RESEND_EMAIL_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export default skillsReducer;
