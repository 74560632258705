import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Modal, Table, Pagination, Select, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { deleteInvitationService } from '../../../../../core/services/modules/teamsService';
import { resendInviteJoinTeamService } from '../../../../../redux/actions/admin/customersAction';
import { getInvitations } from '../../../../../redux/actions/admin/teamsAction';
import { openNotification } from '../../../../common/notification/notification';

import './invitationModal.scss';

const { confirm } = Modal;
// import {
//   renderRequireLabel,
//   filterActiveItems,
//   filterItemsByType,
// } from '../../../../../../core/utils/commonUtils';
const { Option } = Select;

const LIMIT = 5;

const InvitationModal = props => {
  const [filter, setFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const { visibleModal, onCloseModal } = props;

  const invitations = useSelector(state => state.invitation.data.result);
  const total = useSelector(state => state.invitation.data.total);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getInvitations({
        filter,
        page: currentPage,
        limit: LIMIT,
      })
    );
  }, [currentPage, dispatch, filter]);

  const columns = [
    {
      title: <strong>Team</strong>,
      dataIndex: 'teamWorkId[name]',
    },
    {
      title: <strong>Email</strong>,
      dataIndex: 'email',
    },
    {
      title: <strong>Invite Date</strong>,
      dataIndex: 'createdAt',
      width: 150,
      render: date => {
        return <span>{moment(date).format('DD/MM/YYYY')}</span>;
      },
    },
    {
      title: <strong>Full Name</strong>,
      dataIndex: 'fullName',
    },
    {
      title: <strong>Status</strong>,
      width: 100,
      dataIndex: 'status',
      render: status =>
        status === 'pending' ? (
          <Tag color="volcano">Pending</Tag>
        ) : (
            <Tag color="green">Accepted</Tag>
          ),
    },
    {
      title: <strong>Phone Number</strong>,
      width: 150,
      dataIndex: 'phoneNumber',
    },
    {
      title: <strong>Sender</strong>,
      dataIndex: 'sender[fullName]',
    },
    {
      title: <strong>Action(s)</strong>,
      width: '18%',
      render: (action, record) => {
        const onResend = () => {
          const { teamWorkId, fullName, email, phoneNumber, _id } = record;
          const data = {
            id: _id,
            email: email && email.trim(),
            teamWorkId: (teamWorkId && teamWorkId._id) || '',
            fullName: fullName && fullName.trim(),
            phoneNumber: phoneNumber && phoneNumber.trim(),
            filter
          };
          dispatch(resendInviteJoinTeamService(data));
        };

        const onHandleDelete = async rec => {
          const { _id } = rec;
          try {
            const res = await deleteInvitationService({ id: _id, filter });
            const { data } = res;
            if (data.error) {
              return openNotification('error', data.error.message);
            } else if (data.success) {
              dispatch(
                getInvitations({
                  filter,
                  page: currentPage,
                  limit: LIMIT,
                })
              );
              return openNotification('success', 'Deleted successfully');
            }
          } catch (e) { }
        };
        return (
          <>
            <Button
              disabled={record.status === 'pending' ? false : true}
              className="btn-primary-0 mr-1"
              type="primary"
              onClick={onResend}
            >
              Resend
            </Button>

            <Button
              disabled={record.status === 'pending' ? false : true}
              className="btn-primary-0"
              type="primary"
              onClick={() =>
                confirm({
                  title: 'Delete Invitation',
                  content: 'Are you sure want to delete this invitation ?',
                  okText: 'No',
                  cancelText: 'Yes',
                  className: 'confirm-delete-account',
                  onCancel: () => onHandleDelete(record),
                })
              }
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  const formatData = data => {
    if (data && data.length) {
      return data.map((item, index) => {
        return {
          ...item,
          key: item._id,
        };
      });
    }
  };

  const onChangePage = page => setCurrentPage(page);

  const onFilterStatus = type => {
    setFilter(type);
    setCurrentPage(1);
  };

  return (
    <Modal
      visible={visibleModal}
      onCancel={onCloseModal}
      className="invitation-modal"
      footer={null}
      closable={true}
      destroyOnClose={true}
    >
      <h2 className="invitation-title text-center">Invitations</h2>
      <div className="d-flex justify-content-between align-items-start mb-3">
        <Select defaultValue="All Types" style={{ width: 300 }} onChange={onFilterStatus}>
          <Option value="">All Types</Option>
          <Option value="pending">Pending</Option>
          <Option value="accept">Accepted</Option>
          <Option value="admin-invite-create-project">Invited In Project</Option>
        </Select>
        {!!invitations && (
          <Pagination
            className="customer-pagination"
            total={total}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            pageSize={LIMIT}
            simple={false}
            onChange={onChangePage}
            current={currentPage}
          />
        )}
      </div>

      <Table
        bordered
        columns={columns}
        dataSource={formatData(invitations)}
        pagination={false}
      />
    </Modal>
  );
};

export default InvitationModal;
