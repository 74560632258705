import {
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILED,
  GET_PROJECTS_DETAIL_REQUEST,
  GET_PROJECTS_DETAIL_SUCCESS,
  GET_PROJECTS_DETAIL_FAILED,
  SEARCH_PROJECTS_REQUEST,
  SEARCH_PROJECTS_SUCCESS,
  SEARCH_PROJECTS_FAILED,
  UPDATE_STATUS_PROJECTS_FAILED,
  UPDATE_STATUS_PROJECTS_REQUEST,
  UPDATE_STATUS_PROJECTS_SUCCESS,
  UPDATE_PROJECTS_FAILED,
  UPDATE_PROJECTS_REQUEST,
  UPDATE_PROJECTS_SUCCESS,
  CLEAR_PROJECTS_DETAIL,
  ADMIN_CREATE_PROJECT_REQUEST,
  ADMIN_CREATE_PROJECT_SUCCESS,
  ADMIN_CREATE_PROJECT_FAILED,
  GET_NUMBER_OF_PROJECTS_REQUEST,
  GET_NUMBER_OF_PROJECTS_SUCCESS,
  GET_NUMBER_OF_PROJECTS_FAILED,
  GET_BRIEF_HISTORY_SUCCESS,
  GET_BRIEF_HISTORY_FAIL,
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';

const updateProjectHelper = (state, action) => {
  const index = state.data.list.findIndex(item => item._id === action.payload.data._id);
  const currentList = [...state.data.list];
  currentList[index] = { ...currentList[index], ...action.payload.data };
  return { ...state.data, list: currentList };
};

const defaultState = {
  isProjectDeleted: true,
  success: false,
  error: null,
  data: [],
  isLoading: false,
  detailData: null,
  detailError: null,
  detailLoading: false,
  isInviting: false,
  inviteSuccess: false,
  numberOfProject: {
    allProject: 0,
    preProject: 0,
    preProduction: 0,
    productionReady: 0,
    postProduction: 0,
    closed: 0,
  },
  briefHistories: [],
};

const projectsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_PROJECTS_REQUEST: {
      return {
        ...state,
      };
    }
    case GET_PROJECTS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case GET_PROJECTS_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        error: action.payload,
      };
    }
    case GET_PROJECTS_DETAIL_REQUEST: {
      return {
        ...state,
        success: false,
        detailError: null,
        detailLoading: true,
      };
    }
    case GET_PROJECTS_DETAIL_SUCCESS: {
      if (action.payload.data.isDelete) {
        openNotification('error', 'This project was deleted');
        // CASE PROJECT WAS DELETED
        return {
          ...state,
          isProjectDeleted: true,
          success: false,
          error: null,
          detailData: null,
          detailError: { requestingId: action.payload.data._id },
          detailLoading: false,
        };
      }
      return {
        ...state,
        success: true,
        detailData: { ...action.payload.data },
        detailError: null,
        detailLoading: false,
      };
    }
    case GET_PROJECTS_DETAIL_FAILED: {
      if (action.payload.message) {
        openNotification('error', action.payload.message);
      }

      return {
        ...state,
        success: false,
        detailError: action.payload,
        detailLoading: false,
      };
    }
    case ADMIN_CREATE_PROJECT_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADMIN_CREATE_PROJECT_SUCCESS: {
      openNotification('success', 'Project Created Successfully');
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          list: [...state.data.list, action.payload.data],
        },
        detailData: action.payload.data,
      };
    }
    case ADMIN_CREATE_PROJECT_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case SEARCH_PROJECTS_REQUEST: {
      return {
        ...state,
      };
    }
    case SEARCH_PROJECTS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SEARCH_PROJECTS_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        error: action.payload,
      };
    }
    case UPDATE_STATUS_PROJECTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPDATE_STATUS_PROJECTS_SUCCESS: {
      openNotification('success', 'Update Successful');
      return {
        ...state,
        isLoading: false,
        data: updateProjectHelper(state, action),
      };
    }
    case UPDATE_STATUS_PROJECTS_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case UPDATE_PROJECTS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPDATE_PROJECTS_SUCCESS: {
      openNotification('success', 'Update Successful');
      return {
        ...state,
        isLoading: false,
        data: updateProjectHelper(state, action),
        detailData: { ...action.payload.data },
      };
    }
    case UPDATE_PROJECTS_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case CLEAR_PROJECTS_DETAIL: {
      return {
        ...state,
        detailData: null,
      };
    }
    case GET_NUMBER_OF_PROJECTS_REQUEST: {
      return {
        ...state,
      };
    }
    case GET_NUMBER_OF_PROJECTS_SUCCESS: {
      return {
        ...state,
        numberOfProject: action.payload.data,
      };
    }
    case GET_NUMBER_OF_PROJECTS_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        error: action.payload,
      };
    }
    case GET_BRIEF_HISTORY_SUCCESS: {
      return {
        ...state,
        briefHistories: action.payload.data,
      };
    }
    case GET_BRIEF_HISTORY_FAIL: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export default projectsReducer;
