import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';
import qs from '../../../core/utils/querystring';

export const getCustomersService = params => {
  const url = BaseConfig.END_POINT.ADMIN.CUSTOMERS;
  const queryString = qs.stringify(JSON.parse(JSON.stringify(params)));
  const response = httpService._get(`${url}?${queryString}`);

  return response;
};

export const getCustomersDetailService = id => {
  const url = BaseConfig.END_POINT.ADMIN.CUSTOMERS;
  const response = httpService._get(`${url}/${id}`);
  return response;
};

export const transferAdminService = ({ customerId, teamId, newAdmin }) => {
  const url = BaseConfig.END_POINT.ADMIN.TEAM;
  const response = httpService._put(`${url}/${teamId}`, {
    customerId,
    newAdmin,
  });
  return response;
};

export const searchCustomersService = data => {
  const url = BaseConfig.END_POINT.ADMIN.CUSTOMERS;
  const response = httpService._get(
    `${url}?status=${data.status}&search=${data.searchText}`
  );
  return response;
};

export const updateCustomersService = ({ id, ...rest }) => {
  const url = BaseConfig.END_POINT.ADMIN.CUSTOMERS;
  const response = httpService._put(`${url}/${id}`, rest);
  return response;
};

export const updateStatusService = data => {
  const url = BaseConfig.END_POINT.ADMIN.CUSTOMERS;
  const response = httpService._put(`${url}/${data.id}`, {
    status: data.status,
  });
  return response;
};

export const customerRegisterService = data => {
  const url = BaseConfig.END_POINT.ADMIN.CUSTOMERS;
  const response = httpService._post(`${url}/admin/invite`, data);
  return response;
};

export const inviteJoinTeamService = data => {
  const url = BaseConfig.END_POINT.ADMIN.INVITE_TEAM;
  const response = httpService._post(url, data);
  return response;
};

export const resendInviteJoinTeamService = data => {
  const url = BaseConfig.END_POINT.ADMIN.RESEND_INVITE_TEAM;
  const response = httpService._post(`${url}`, data);
  return response;
};
