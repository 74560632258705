import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Card, Modal, message } from 'antd';
import './EmailTemplateScreen.css';
import AddYoutubeLink from './AddYoutubeLink';
import { Row, Col } from 'react-bootstrap';
import { getS3CDN } from '../../../../core/utils/commonUtils';
const { Meta } = Card;
const { confirm } = Modal;

class EmailTemplateScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Admin Policies - VidOps',
      data: [],
      key: '',
      itemUpdate: null,
      isModalOpen: false,
      isCreate: false,
      policyType: '',
      isOpenModal: false,
      detail: {},
    };
  }

  componentDidMount() {
    this.props.ongetYoutubeLinks();
  }

  onRemoveLink = id => {
    const { data } = this.props;
    const { docs } = data || {};
    if (docs && docs.length < 2) {
      return message.warning('We need at least one link youtube for email template!');
    }
    confirm({
      title: 'Are you sure you want to delete this item?',
      onOk: () => {
        this.props.onRemoveYoutubeLinks(id);
      },
      onCancel() {},
    });
  };

  onEditLink = detail => {
    this.setState({ detail, isOpenModal: true });
  };

  render() {
    const { data } = this.props;
    const { docs } = data || {};
    const { isOpenModal, detail } = this.state;
    return (
      <main role="main" className="page-content policies-screen">
        <h2 className="text-left">Youtube Links</h2>

        <Button
          className="mb-2"
          type="primary"
          onClick={() => this.setState({ isOpenModal: true })}
        >
          Add link
        </Button>
        <Row>
          {docs &&
            docs.map(d => (
              <Col xs={6} md={6} key={d.id} className="youtube-link-item">
                <Card
                  cover={
                    <img
                      className="email-thumbnail"
                      alt="example"
                      src={getS3CDN(d.thumbnail)}
                    />
                  }
                  actions={[
                    <div key="ellipsis" onClick={() => this.onEditLink(d)}>
                      Edit
                    </div>,
                    <div key="Delete" onClick={() => this.onRemoveLink(d.id)}>
                      Delete
                    </div>,
                  ]}
                >
                  <Meta
                    description={
                      <a
                        className="text-primary-0"
                        href={d.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {d.url}
                      </a>
                    }
                  />
                </Card>
              </Col>
            ))}
        </Row>

        {isOpenModal && (
          <AddYoutubeLink
            visible={isOpenModal}
            detail={detail}
            handleCancel={() => this.setState({ isOpenModal: false, detail: {} })}
          />
        )}
      </main>
    );
  }
}

export default withTranslation('common')(EmailTemplateScreen);
