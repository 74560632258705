import {
    GET_SERVICE_TYPE_REQUEST,
	GET_SERVICE_TYPE_BY_PRODUCT_TYPE_REQUEST,
	GET_SERVICE_TYPE_BY_ID_REQUEST,
	CREATE_SERVICE_TYPE_REQUEST,
	UPDATE_SERVICE_TYPE_REQUEST,
	UPDATE_ORDER_SERVICE_TYPE_SUCCESS
} from '../typeAction';

export const getServiceType = (params) => ({
    type: GET_SERVICE_TYPE_REQUEST,
    payload: params,
});

export const getServiceTypeByProductType = (id) => ({
    type: GET_SERVICE_TYPE_BY_PRODUCT_TYPE_REQUEST,
    payload: id,
});

export const getServiceTypeById = id => ({
	type: GET_SERVICE_TYPE_BY_ID_REQUEST,
	payload: id
});

export const createServiceType = data => ({
	type: CREATE_SERVICE_TYPE_REQUEST,
	payload: data
});

export const updateServiceType = ({ data, id }) => ({
	type: UPDATE_SERVICE_TYPE_REQUEST,
	payload: { data, id }
});

export const updateOrder = data => ({
	type: UPDATE_ORDER_SERVICE_TYPE_SUCCESS,
	payload: data
});
