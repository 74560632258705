import React from 'react';
import 'antd/dist/antd.css';
import './policiesForm.scss';
import { Form, Input, Button, Select } from 'antd';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

const { Option } = Select;

class PoliciesForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			itemUpdate: this.props.itemUpdate,
			isBtnDisabled: false,
			content: this.props.isCreate ? '' : this.props.itemUpdate.content,
			contentError: ''
		};
	}

	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const { type } = this.props;
				const name = values.name.trim();
				const content = this.state.content.trim();
				if (!content) {
					this.setState({
						contentError: 'Please input content of policies'
					});
					return;
				}
				const version = values.version.trim();
				const data = {
					name,
					content,
					version,
					type
				};
				this.props.isCreate
					? this.props.onCreatePolicies(data)
					: this.props.onUpdatePolicies({
							...data,
							status:
								values.status.trim() === 'Active' ? true : false
					  });
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { isCreate, isLoading } = this.props;
		const { itemUpdate } = this.state;
		return (
			<Form className="policies-form">
				<Form.Item label="Name">
					{getFieldDecorator('name', {
						rules: [
							{
								required: true,
								message: 'Please input your name'
							},
							{
								whitespace: true,
								message: 'Please do not input whitespace only'
							}
						],
						initialValue: isCreate ? null : itemUpdate.name
					})(<Input placeholder="Name" size="large" />)}
				</Form.Item>
				<span className="content-label">Content:</span>
				<CKEditor
					onInit={editor => {
						editor.ui
							.getEditableElement()
							.parentElement.insertBefore(
								editor.ui.view.toolbar.element,
								editor.ui.getEditableElement()
							);
					}}
					onChange={(event, editor) => {
						const data = editor.getData();
						this.setState({
							content: data,
							contentError: ''
						});
					}}
					editor={DecoupledEditor}
					data={isCreate ? null : itemUpdate.content}
				/>
				{this.state.contentError ? (
					<div className="ant-form-explain content-error">
						{this.state.contentError}
					</div>
				) : null}
				{!isCreate && (
					<Form.Item label="Status" className="status-item">
						{getFieldDecorator('status', {
							rules: [
								{
									required: true,
									message: 'Please select your status'
								}
							],
							initialValue: itemUpdate.statusText
						})(
							<Select>
								<Option value="Active">Active</Option>
								<Option value="Inactive">Inactive</Option>
							</Select>
						)}
					</Form.Item>
				)}
				<Form.Item label="Version">
					{getFieldDecorator('version', {
						rules: [
							{
								required: true,
								message: 'Please input your version'
							},
							{
								whitespace: true,
								message: 'Please do not input whitespace only'
							}
						],
						initialValue: isCreate ? null : itemUpdate.version
					})(<Input placeholder="Version" size="large" />)}
				</Form.Item>
				{isCreate && (
					<Form.Item>
						<Button
							type="primary"
							loading={isLoading}
							onClick={this.handleSubmit}
							className="policies-form-button btn-primary-0"
							size="large"
							disabled={isLoading}
						>
							Create
						</Button>
					</Form.Item>
				)}
				{!isCreate && (
					<Form.Item>
						<Button
							type="primary"
							loading={isLoading}
							onClick={this.handleSubmit}
							className="policies-form-button btn-primary-0"
							size="large"
							disabled={isLoading}
						>
							Update
						</Button>
					</Form.Item>
				)}
			</Form>
		);
	}
}

const WrappedPoliciesForm = Form.create({ name: 'normal_policies' })(
	PoliciesForm
);

export default WrappedPoliciesForm;
