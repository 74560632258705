import {
	GET_ADD_ONS_REQUEST,
	CREATE_ADD_ONS_REQUEST,
	UPDATE_ADD_ONS_REQUEST,
	UPDATE_ORDER_ADD_ONS_SUCCESS
} from '../typeAction';

export const getAddons = () => ({
	type: GET_ADD_ONS_REQUEST
});

export const createAddons = data => ({
	type: CREATE_ADD_ONS_REQUEST,
	payload: data
});

export const updateAddons = ({ data, id }) => ({
	type: UPDATE_ADD_ONS_REQUEST,
	payload: { data, id }
});

export const updateOrder = data => ({
	type: UPDATE_ORDER_ADD_ONS_SUCCESS,
	payload: data
});
