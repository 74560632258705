import { connect } from 'react-redux';
import StoriesScreen from '../../../components/screens/admin/stories/storiesScreen';
import { updateOrder } from '../../../redux/actions/admin/storiesAction';
import { getCustomerStories } from '../../../redux/actions/admin/customerStoriesAction';
import { getCaseStudies } from '../../../redux/actions/admin/caseStudiesAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';
import { getOurStories } from '../../../redux/actions/admin/storiesAction';

const mapStateToProps = state => {
	return {
		customerStories: state.customerStories.data,
		caseStudies: state.caseStudies.data,
		stories: state.stories.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetCustomerStories: () => {
			dispatch(getCustomerStories());
		},
		onGetCaseStudies: () => {
			dispatch(getCaseStudies());
		},
		onUpdateOrder: data => {
			dispatch(updateOrder(data));
		},
		onGetCurrentUser: () => {
			dispatch(getCurrentUser());
		},
		onGetOurStories: () => {
			dispatch(getOurStories());
		}
	};
};

const CustomerStoriesContainer = connect(mapStateToProps, mapDispatchToProps)(StoriesScreen);

export default CustomerStoriesContainer;
