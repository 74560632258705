import {
  GET_YOUTUBE_LINK_REQUEST,
  CREATE_YOUTUBE_LINK_REQUEST,
  REMOVE_YOUTUBE_LINK_REQUEST,
} from '../typeAction';
import { UPDATE_YOUTUBE_LINK_REQUEST } from 'src/redux/actions/typeAction';

export const getYoutubeLinks = () => ({
  type: GET_YOUTUBE_LINK_REQUEST,
});

export const createYoutubeLinks = data => ({
  type: CREATE_YOUTUBE_LINK_REQUEST,
  payload: data,
});

export const updateYoutubeLink = data => ({
  type: UPDATE_YOUTUBE_LINK_REQUEST,
  payload: data,
});

export const removeYoutubeLinks = data => ({
  type: REMOVE_YOUTUBE_LINK_REQUEST,
  payload: data,
});
