import { connect } from 'react-redux';
import EmailTemplateScreen from '../../../components/screens/admin/emailTemplate/EmailTemplateScreen';
import {
  getYoutubeLinks,
  removeYoutubeLinks,
} from '../../../redux/actions/admin/emailTemplateAction';

const mapStateToProps = state => {
  return {
    success: state.emailTemplate.success,
    error: state.policies.error,
    isLoading: state.policies.isLoading,
    data: state.emailTemplate.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ongetYoutubeLinks: () => {
      dispatch(getYoutubeLinks());
    },
    onRemoveYoutubeLinks: id => {
      dispatch(removeYoutubeLinks(id));
    },
  };
};

const EmailTemplateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailTemplateScreen);

export default EmailTemplateContainer;
