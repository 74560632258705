import React, { forwardRef, memo, useImperativeHandle, useRef } from 'react';
import './style.scss';
import { uuid } from 'src/core/utils/commonUtils';

const VideoThumbnailExtractor = (props, ref) => {
  const videoExtractorRef = useRef(uuid());
  const canvasExtractorRef = useRef(uuid());

  const draw = video => {
    const canvas = document.getElementById(canvasExtractorRef.current);
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL();
  };

  const onExtractThumbnail = file =>
    new Promise(resolve => {
      const video = document.getElementById(videoExtractorRef.current);
      const canvas = document.getElementById(canvasExtractorRef.current);
      const fileMime = file.type;
      const fileReader = new FileReader();

      fileReader.onload = function (e) {
        const blob = new Blob([e.target.result], {
          type: fileMime,
        });
        const url = URL.createObjectURL(blob);

        video.preload = 'metadata';
        video.addEventListener('loadedmetadata', () => {
          canvas.height = video.videoHeight;
          canvas.width = video.videoWidth;
          // Timeout for seeking video
          setTimeout(() => {
            resolve(draw(video, canvas));
          }, 500);
        });
        video.addEventListener('error', () => {
          console.error('video thumbnail unsupported');
          resolve(null);
        });

        video.src = url;
        video.currentTime = 1; // seek to 1 secs
      };

      fileReader.readAsArrayBuffer(file);
    });

  useImperativeHandle(ref, () => {
    return {
      onExtractThumbnail,
    };
  });

  return (
    <>
      <video
        className="visible-none"
        id={videoExtractorRef.current}
        autoPlay={true}
        muted={true}
        controls={true}
      />
      <canvas className="visible-none" id={canvasExtractorRef.current} />
    </>
  );
};

export default memo(forwardRef(VideoThumbnailExtractor));
