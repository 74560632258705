import React from 'react';
import { withTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import './statusFilter.scss';
import { CUSTOMER_STATUS } from '../../../../../core/constants/constants';

class StatusFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStatus: [],
    };
  }

  componentDidMount() {
    if (this.props.forceClearFilter) {
      this.props.clearFilters();
      this.props.confirm();
      this.setState({
        selectedStatus: [],
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.forceClearFilter &&
      this.props.forceClearFilter !== prevProps.forceClearFilter
    ) {
      this.setState({ selectedStatus: [] });
    }
  }

  selectStatusHandler = checkedValues => {
    this.setState({
      selectedStatus: checkedValues,
    });
  };

  renderStatusFilter = () => (
    <Checkbox.Group
      className="status-checkbox"
      value={this.state.selectedStatus}
      options={CUSTOMER_STATUS}
      onChange={this.selectStatusHandler}
    />
  );

  render() {
    const {
      forceClearFilter,
      selectedKeys,
      setSelectedKeys,
      confirm,
      clearFilters,
    } = this.props;

    return (
      <div className="customers-status-filter-container">
        <div className="status-container">{this.renderStatusFilter()}</div>
        <div className="btn-filter-container">
          <span
            onClick={() => {
              const { selectedStatus } = this.state;
              setSelectedKeys(!forceClearFilter && selectedKeys ? [selectedKeys] : []);
              confirm();
              this.props.onFilterCustomerByTeam(selectedStatus);
            }}
          >
            Ok
          </span>
          <span
            onClick={() => {
              clearFilters();
              confirm();
              this.setState({
                selectedStatus: [],
              });
              this.props.onResetFilter();
            }}
          >
            Reset
          </span>
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(StatusFilter);
