import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../../actions/typeAction';
import {
	getOurTeamService,
	updateOurTeamService
} from '../../../core/services/modules/ourTeamService';

export function* getOurTeamSaga() {
	const response = yield call(getOurTeamService);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_OUR_TEAM_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_OUR_TEAM_SUCCESS,
			payload: data
		});
	}
}

export function* updateOurTeamSaga(action) {
	const response = yield call(updateOurTeamService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_OUR_TEAM_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.UPDATE_OUR_TEAM_SUCCESS,
			payload: response.data
		});
	}
}

export function* watchOurTeam() {
	yield takeLatest(actionTypes.GET_OUR_TEAM_REQUEST, getOurTeamSaga);
	yield takeLatest(actionTypes.UPDATE_OUR_TEAM_REQUEST, updateOurTeamSaga);
}
