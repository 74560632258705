import { connect } from 'react-redux';
import {
  sendEmail,
  resetPassword,
  resendEmail,
} from '../../redux/actions/auth/forgotPasswordAction';
import ForgotPassword from 'src/components/screens/forgotPassword/forgotPassword';

const mapStateToProps = state => {
  return {
    success: state.forgotPassword.success,
    error: state.forgotPassword.error,
    isLoading: state.forgotPassword.isLoading,
    data: state.forgotPassword.data,
    resetSuccess: state.forgotPassword.resetSuccess,
    resendSuccess: state.forgotPassword.resendSuccess,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSendEmail: email => {
      dispatch(sendEmail(email));
    },
    onResetPassword: password => {
      dispatch(resetPassword(password));
    },
    onResendEmail: email => {
      dispatch(resendEmail(email));
    },
  };
};
const ForgotPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPassword);

export default ForgotPasswordContainer;
