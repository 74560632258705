import {
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_DETAIL_REQUEST,
  SEARCH_CUSTOMERS_REQUEST,
  UPDATE_STATUS_CUSTOMERS_REQUEST,
  UPDATE_CUSTOMERS_REQUEST,
  TRANSFER_ADMIN_REQUEST,
  LOAD_MORE_CUSTOMERS_REQUEST,
  REGISTER_CUSTOMER_REQUEST,
  RESEND_INVITATION_TEAM_REQUEST,
  SEND_INVITATION_TEAM_REQUEST,
  CLEAR_CUSTOMERS_DETAIL
} from '../typeAction';

export const getCustomers = params => ({
  type: GET_CUSTOMERS_REQUEST,
  payload: params,
});

export const loadMoreCustomers = params => ({
  type: LOAD_MORE_CUSTOMERS_REQUEST,
  payload: params,
});

export const getCustomersDetail = id => ({
  type: GET_CUSTOMERS_DETAIL_REQUEST,
  payload: id,
});

export const clearCustomerDetail = id => ({
  type: CLEAR_CUSTOMERS_DETAIL,
  payload: id,
});

export const transferAdmin = body => ({
  type: TRANSFER_ADMIN_REQUEST,
  payload: body,
});

export const searchCustomers = data => ({
  type: SEARCH_CUSTOMERS_REQUEST,
  payload: data,
});

export const updateStatus = data => ({
  type: UPDATE_STATUS_CUSTOMERS_REQUEST,
  payload: data,
});

export const registerCustomer = data => ({
  type: REGISTER_CUSTOMER_REQUEST,
  payload: data,
});

export const adminInviteJoinTeam = data => ({
  type: SEND_INVITATION_TEAM_REQUEST,
  payload: data,
});

export const updateCustomers = data => ({
  type: UPDATE_CUSTOMERS_REQUEST,
  payload: data,
});

export const resendInviteJoinTeamService = data => ({
  type: RESEND_INVITATION_TEAM_REQUEST,
  payload: data,
});
