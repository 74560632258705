import {
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILED,
  UPDATE_USER_INFO_REQUEST,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAILED,
  UPLOAD_AVATAR_REQUEST,
  UPLOAD_AVATAR_SUCCESS,
  UPLOAD_AVATAR_FAILED,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILED,
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';
import { storageService } from 'src/core/services/storageService';

const defaultState = {
  success: false,
  error: null,
  data: null,
  avatarUser: null,
  avtLoading: false,
  isLoading: false,
};

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_USER_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case GET_USER_INFO_FAILED:
      openNotification('error', action.payload ? action.payload.message : 'err');
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UPLOAD_AVATAR_REQUEST:
      return {
        ...state,
        avtLoading: true,
      };
    case UPLOAD_AVATAR_SUCCESS:
      storageService.setAvatar(action.payload);
      return {
        ...state,
        avtLoading: false,
        avatarUser: action.payload,
      };
    case UPLOAD_AVATAR_FAILED:
      openNotification('error', action.payload ? action.payload.message : 'err');
      return {
        ...state,
        avtLoading: false,
        error: action.payload,
      };

    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_PASSWORD_SUCCESS:
      openNotification('success', 'New changes saved');
      storageService.logout();
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_PASSWORD_FAILED:
      openNotification('error', action.payload ? action.payload.message : 'err');
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case UPDATE_USER_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_USER_INFO_SUCCESS:
      openNotification('success', 'New changes saved');

      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case UPDATE_USER_INFO_FAILED:
      openNotification('error', action.payload ? action.payload.message : 'err');
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
