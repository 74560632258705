import React from 'react';
import 'antd/dist/antd.css';
import './packageForm.scss';
import { Form, Input, Button, Select, Icon } from 'antd';

const { Option } = Select;
const { TextArea } = Input;
let id = 0;
class PackageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  remove = k => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    form.setFieldsValue({
      keys: keys.filter(key => key !== k)
    });
  };

  add = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    const nextKeys = !keys.length
      ? keys.concat(id++)
      : keys.concat(Math.max(...keys) + 10000);
    form.setFieldsValue({
      keys: nextKeys
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { isCreate, productTypeId, itemUpdate } = this.props;
        const name = values.name.trim();
        const status = values.status.trim() === 'Active' ? true : false;
        const description = values.description.trim();
        const isBasic = values.isBasic === 'True' ? true : false;
        const data = {
          name,
          description,
          status,
          productTypeId: isCreate ? productTypeId : itemUpdate.productTypeId._id,
          isBasic
        };
        this.props.isCreate
          ? this.props.onCreatePackage(data)
          : this.props.onUpdatePackage(data);
      }
    });
  };

  handleSelectItems = checkedValues => {};

  handleSelectAddons = value => {};

  renderDynamicForm() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { itemUpdate, isCreate } = this.props;
    const numberKeys =
      !isCreate && itemUpdate
        ? itemUpdate.alsoContent.map((item, index) => {
            return index;
          })
        : [];
    getFieldDecorator('keys', {
      initialValue:
        itemUpdate && itemUpdate.alsoContent.length > 0 ? numberKeys : []
    });
    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => (
      <Form.Item required={false} key={k}>
        {getFieldDecorator(`alsoContentTemp[${k}]`, {
          validateTrigger: ['onChange', 'onBlur'],
          initialValue: isCreate ? '' : itemUpdate.alsoContent[k],
          rules: [
            {
              required: true,
              whitespace: true,
              message: 'Please input also require name'
            }
          ]
        })(
          <Input
            placeholder="Require name"
            style={{ width: '95%', marginRight: 8 }}
          />
        )}
        <Icon
          className="dynamic-delete-button"
          type="minus-circle-o"
          onClick={() => this.remove(k)}
        />
      </Form.Item>
    ));
    return (
      <React.Fragment>
        {formItems}
        <Form.Item>
          <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
            <Icon type="plus" /> Add requirement
          </Button>
        </Form.Item>
      </React.Fragment>
    );
  }

  filterActive = arr => {
    if (arr && arr.length) {
      return arr.filter(item => item.status);
    }
  };

  renderSelect = arr => {
    if (arr && arr.length) {
      return arr.map(item => (
        <Option key={item} value={item._id}>
          {item.name}
        </Option>
      ));
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isCreate, itemUpdate, isLoading } = this.props;
    let packageRender;
    if (isCreate) {
      packageRender = (
        <Form className="package-form">
          <Form.Item label="Name">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Please input package name'
                },
                {
                  whitespace: true,
                  message: 'Please do not input whitespace only'
                }
              ],
              initialValue: null
            })(<Input placeholder="Name" size="large" />)}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator('description', {
              rules: [
                {
                  required: true,
                  message: 'Please input package description'
                },
                {
                  whitespace: true,
                  message: 'Please do not input whitespace only'
                }
              ],
              initialValue: null
            })(<TextArea placeholder="Description" rows={4} />)}
          </Form.Item>
          <Form.Item label="Basic package" className="isBasic-item">
            {getFieldDecorator('isBasic', {
              rules: [
                {
                  required: true,
                  message: 'Please input package isBasic'
                }
              ],
              initialValue: 'False'
            })(
              <Select>
                <Option value="True">True</Option>
                <Option value="False">False</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Status" className="status-item">
            {getFieldDecorator('status', {
              rules: [
                {
                  required: true,
                  message: 'Please select package status'
                }
              ],
              initialValue: 'Active'
            })(
              <Select>
                <Option value="Active">Active</Option>
                <Option value="Inactive">Inactive</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              loading={isLoading}
              onClick={this.handleSubmit}
              className="package-form-button btn-primary-0"
              size="large"
              disabled={isLoading}
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      );
    } else {
      packageRender = (
        <Form className="package-form">
          <Form.Item label="Name">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Please input package name'
                },
                {
                  whitespace: true,
                  message: 'Please do not input whitespace only'
                }
              ],
              initialValue: isCreate ? null : itemUpdate.name
            })(<Input placeholder="Name" size="large" />)}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator('description', {
              rules: [
                {
                  required: true,
                  message: 'Please input package description'
                },
                {
                  whitespace: true,
                  message: 'Please do not input whitespace only'
                }
              ],
              initialValue: itemUpdate.description
            })(<TextArea placeholder="Description" rows={4} />)}
          </Form.Item>
          <Form.Item label="Basic package" className="isBasic-item">
            {getFieldDecorator('isBasic', {
              rules: [
                {
                  required: true,
                  message: 'Please input package isBasic'
                }
              ],
              initialValue: itemUpdate.isBasic ? 'True' : 'False'
            })(
              <Select>
                <Option value="True">True</Option>
                <Option value="False">False</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Status" className="status-item">
            {getFieldDecorator('status', {
              rules: [
                {
                  required: true,
                  message: 'Please select package status'
                }
              ],
              initialValue: itemUpdate.status ? 'Active' : 'Inactive'
            })(
              <Select>
                <Option value="Active">Active</Option>
                <Option value="Inactive">Inactive</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              loading={isLoading}
              onClick={this.handleSubmit}
              className="items-form-button btn-primary-0"
              size="large"
              disabled={isLoading}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      );
    }
    return packageRender;
  }
}

const WrappedPackageForm = Form.create({ name: 'normal_package' })(PackageForm);

export default WrappedPackageForm;
