import React from 'react';
import { withTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import './filterOptions.scss';

class FilterOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptions: [],
    };
  }

  componentDidMount() {
    if (this.props.forceClearFilter) {
      this.props.clearFilters();
      this.props.confirm();
      this.setState({
        selectedOptions: [],
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.forceClearFilter &&
      this.props.forceClearFilter !== prevProps.forceClearFilter
    ) {
      this.setState({ selectedOptions: [] });
    }
  }

  selectedOptionsHandler = selectedOptions => {
    this.setState({
      selectedOptions,
    });
  };

  renderOptions = () => {
    const { options } = this.props;
    return (
      <Checkbox.Group
        className="filter-options-checkbox"
        value={this.state.selectedOptions}
        options={options}
        onChange={this.selectedOptionsHandler}
      />
    );
  };

  render() {
    const { confirm, clearFilters } = this.props;
    return (
      <div className="options-filter-container">
        {this.renderOptions()}
        <div className="btn-filter-container">
          <span
            onClick={() => {
              confirm();
              const { selectedOptions } = this.state;
              this.props.onSelectTiers(selectedOptions);
            }}
          >
            Ok
          </span>
          <span
            onClick={() => {
              clearFilters();
              this.setState({ selectedOptions: [] });
              this.props.onSelectTiers([]);
            }}
          >
            Reset
          </span>
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(FilterOptions);
