import React from 'react';
import 'antd/dist/antd.css';
import './dragTable.scss';
import { Table, Form, Button } from 'antd';
import { DndProvider } from 'react-dnd-cjs';
import HTML5Backend from 'react-dnd-html5-backend-cjs';
import update from 'immutability-helper';
import DragableBodyRow from './bodyRow/bodyRow';
import { isYoutubeLink } from '../../../core/utils/customValidator';

class DragSortingTable extends React.Component {
  state = {
    data: [],
  };

  components = {
    body: {
      row: DragableBodyRow,
    },
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];

    this.setState(
      update(this.state, {
        data: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        },
      })
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.dataSource !== this.props.dataSource) {
      this.setState({
        data: nextProps.dataSource,
      });
    }
  }

  filterIds = data => data.map(item => item._id);

  formatUrl = url => {
    const urlArr = url.split('/uploads');
    return `/uploads/${urlArr[1]}`;
  };

  formatVideoUrl = url => {
    const urlArr = url.split('/uploads');
    return `/uploads${urlArr[1]}`;
  };

  formatData = data => {
    return data.map(item => {
      if (item.url) {
        return {
          ...item,
          url: !isYoutubeLink(item.url) ? this.formatUrl(item.url) : item.url,
        };
      }
      if (item.featureUrl) {
        if (item.type && item.type === 'video' && !isYoutubeLink(item.featureUrl)) {
          return {
            ...item,
            featureUrl: this.formatVideoUrl(item.featureUrl),
          };
        }
        return {
          ...item,
          featureUrl: !isYoutubeLink(item.featureUrl)
            ? this.formatUrl(item.featureUrl)
            : item.featureUrl,
        };
      }
      return item;
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const filterIds = this.filterIds(this.state.data);
    const orderData = {
      ids: filterIds,
      data: this.formatData(this.state.data),
    };
    this.props.onUpdateOrder(orderData);
  };

  render() {
    const { columns, scrollX, onChangeHandler } = this.props;
    return (
      <Form>
        <Form.Item>
          <DndProvider backend={HTML5Backend}>
            <Table
              columns={columns}
              dataSource={this.state.data}
              components={this.components}
              onRow={(record, index) => ({
                index,
                moveRow: this.moveRow,
              })}
              bordered
              pagination={false}
              scroll={scrollX}
              onChange={onChangeHandler}
            />
          </DndProvider>
        </Form.Item>
        <Form.Item>
          {!this.props.hiddenUpdateOrder && (
            <Button
              type="primary"
              onClick={this.handleSubmit}
              className="howitwork-form-button btn-primary-0"
              size="large"
              disabled={!this.state.data || !this.state.data.length}
            >
              Update order
            </Button>
          )}
        </Form.Item>
      </Form>
    );
  }
}

export default DragSortingTable;
