import React, { memo, useCallback, useState } from 'react';
import { Col, Container, Jumbotron, Row } from 'react-bootstrap';
import { Button } from 'antd';
import { syncUserStatus } from 'src/core/services/modules/userService';
import { syncNotificationCount } from 'src/core/services/modules/notificationService';
import { openNotification } from 'src/components/common/notification/notification';

const UpdateButton = memo(props => {
  const { title, buttonTitle = 'Update', onSubmit = () => null } = props;
  const [loading, setLoading] = useState(false);

  const onClick = useCallback(async () => {
    setLoading(true);
    await onSubmit();
    setLoading(false);
  }, [onSubmit]);

  return (
    <>
      <h4>{title}</h4>
      <Button
        disabled={loading}
        loading={loading}
        onClick={onClick}
        className="btn-primary-0"
        type="primary"
      >
        {buttonTitle}
      </Button>
    </>
  );
});

const CacheSynchronize = memo(props => {
  const onReloadUserStatus = async () => {
    try {
      await syncUserStatus();
      openNotification('success', 'Update user status successfully');
    } catch (e) {
      openNotification('success', e.message);
      console.error(e.stack);
    }
  };

  const onReloadNotification = async () => {
    try {
      await syncNotificationCount();
      openNotification('success', 'Update notification count successfully');
    } catch (e) {
      openNotification('success', e.message);
      console.error(e.stack);
    }
  };

  return (
    <main role="main" className="page-content synchronize-screen">
      <Jumbotron>
        <h2 className="sub-heading">Cache Synchronize</h2>
        <p className="description">Force update cache for notification and user status</p>
        <div className="divider" />

        <Container>
          <Row>
            <Col md="6">
              <UpdateButton
                onSubmit={onReloadUserStatus}
                title="Update cache user status"
              />
            </Col>
            <Col className="mt-md-0 mt-3" md="6">
              <UpdateButton
                onSubmit={onReloadNotification}
                title="Update cache notification"
              />
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </main>
  );
});

export default CacheSynchronize;
