import React from 'react';
import './howitworkForm.scss';
import { Form, Icon, Input, Button, Upload, Avatar, message } from 'antd';
import { formDataConfig, dummyRequest } from '../../../../core/utils/commonUtils';

class HowitworkForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fileList: [],
			isPhotosRequired: false,
			displayImage: !this.props.isCreate,
			isBtnDisabled: false
		};
	}

	handleSubmit = e => {
		e.preventDefault();
		if (!this.state.fileList.length && this.props.isCreate) {
			this.setState({
				isPhotosRequired: true
			});
			return;
		}
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const title = values.title.trim();
				const subTitle = values.subTitle.trim();
				const detail = values.detail.trim();
				let data;
				if (this.props.isCreate) {
					data = {
						title,
						subTitle,
						detail,
						image: this.state.fileList[0].originFileObj
					};
				} else {
					this.state.fileList.length
						? (data = {
								title,
								subTitle,
								detail,
								image: this.state.fileList[0].originFileObj
						  })
						: (data = {
								title,
								subTitle,
								detail
						  });
				}
				const formData = formDataConfig(data);
				this.props.isCreate
					? this.props.onCreateHowitwork(formData)
					: this.props.onUpdateHowitwork(formData);
			}
		});
	};

	handleChange = ({ fileList }) => {
		fileList = [...fileList].slice(-1);
		if (fileList.length) {
			const lastImage = fileList[fileList.length - 1];
			lastImage.status !== 'done'
				? this.setState({
						isBtnDisabled: true
				  })
				: this.setState({
						isBtnDisabled: false
				  });
			if (lastImage.originFileObj) {
				const isJpgOrPng = [
					'image/jpeg',
					'image/png',
					'image/svg+xml'
				].includes(lastImage.type);
				if (!isJpgOrPng) {
					message.error('You can only upload JPG/PNG/SVG file');
					fileList.pop();
				} else {
					this.setState({
						isPhotosRequired: false,
						displayImage: false
					});
				}
			}
		}
		this.setState({ fileList });
	};

	onRemove = file => {
		this.setState({
			isBtnDisabled: false
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { isCreate, itemUpdate } = this.props;
		const { fileList, displayImage, isBtnDisabled } = this.state;
		const propsImage = {
			customRequest: dummyRequest,
			listType: 'picture',
			fileList: [...fileList],
			className: 'upload-list-inline',
			onRemove: this.onRemove
		};
		if (isCreate) {
			return (
				<Form className="howitwork-form">
					<Form.Item label="Title">
						{getFieldDecorator('title', {
							rules: [
								{
									required: true,
									message: 'Please input title'
								},
								{
									whitespace: true,
									message:
										'Please do not input whitespace only'
								}
							],
							initialValue: null
						})(<Input placeholder="Title" size="large" />)}
					</Form.Item>
					<Form.Item label="Subtitle">
						{getFieldDecorator('subTitle', {
							rules: [
								{
									required: true,
									message: 'Please input subtitle'
								},
								{
									whitespace: true,
									message:
										'Please do not input whitespace only'
								}
							],
							initialValue: null
						})(<Input placeholder="SubTitle" size="large" />)}
					</Form.Item>
					<Form.Item label="Detail">
						{getFieldDecorator('detail', {
							rules: [
								{
									required: true,
									message: 'Please input detail'
								},
								{
									whitespace: true,
									message:
										'Please do not input whitespace only'
								}
							],
							initialValue: null
						})(<Input placeholder="Detail" size="large" />)}
					</Form.Item>
					{displayImage && (
						<Avatar
							className="thumbnail-container"
							src={itemUpdate.url}
						/>
					)}
					<Form.Item>
						{!this.state.fileList.length &&
							!this.state.isPhotosRequired && (
								<div>Upload image!</div>
							)}
						{this.state.isPhotosRequired && (
							<div className="ant-form-explain">
								Please upload an image!
							</div>
						)}
						<Upload {...propsImage} onChange={this.handleChange}>
							<Button>
								<Icon type="upload" />
								Upload
							</Button>
						</Upload>
					</Form.Item>
					<Form.Item>
						<Button
							type="primary"
							loading={this.props.isLoading}
							onClick={this.handleSubmit}
							className="howitwork-form-button btn-primary-0"
							size="large"
							disabled={isBtnDisabled}
						>
							Create
						</Button>
					</Form.Item>
				</Form>
			);
		}
		return (
			<Form className="howitwork-form">
				<Form.Item label="Title">
					{getFieldDecorator('title', {
						rules: [
							{ required: true, message: 'Please input title' },
							{
								whitespace: true,
								message: 'Please do not input whitespace only'
							}
						],
						initialValue: itemUpdate.title
					})(<Input placeholder="Title" size="large" />)}
				</Form.Item>
				<Form.Item label="Subtitle">
					{getFieldDecorator('subTitle', {
						rules: [
							{
								required: true,
								message: 'Please input subtitle'
							},
							{
								whitespace: true,
								message: 'Please do not input whitespace only'
							}
						],
						initialValue: itemUpdate.subTitle
					})(<Input placeholder="SubTitle" size="large" />)}
				</Form.Item>
				<Form.Item label="Detail">
					{getFieldDecorator('detail', {
						rules: [
							{ required: true, message: 'Please input detail' },
							{
								whitespace: true,
								message: 'Please do not input whitespace only'
							}
						],
						initialValue: itemUpdate.detail
					})(<Input placeholder="Detail" size="large" />)}
				</Form.Item>
				{displayImage && (
					<Avatar
						className="thumbnail-container"
						src={itemUpdate.url}
					/>
				)}
				<Form.Item>
					{this.state.isPhotosRequired && (
						<div className="ant-form-explain">
							Please upload an image!
						</div>
					)}
					<Upload {...propsImage} onChange={this.handleChange}>
						<Button>
							<Icon type="upload" />
							Change image
						</Button>
					</Upload>
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						loading={this.props.isLoading}
						onClick={this.handleSubmit}
						className="howitwork-form-button btn-primary-0"
						size="large"
						disabled={isBtnDisabled}
					>
						Update
					</Button>
				</Form.Item>
			</Form>
		);
	}
}

const WrappedHowitworkForm = Form.create({ name: 'normal_howitwork' })(
	HowitworkForm
);

export default WrappedHowitworkForm;
