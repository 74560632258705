import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Modal } from 'antd';
import ReactPhoneInput from 'react-phone-input-2';

import {
  registerCustomer,
  adminInviteJoinTeam,
} from '../../../../../../redux/actions/admin/customersAction';
import { getPolicies } from '../../../../../../redux/actions/admin/policiesAction';
import { validateEmail } from '../../../../../../core/utils/customValidator';
import {
  renderRequireLabel,
  filterActiveItems,
  filterItemsByType,
} from '../../../../../../core/utils/commonUtils';

import './customerInvitationModal.scss';

class CustomerInvitationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      phoneNumber: '',
      phoneNumberRequired: false,
      termPolicies: [],
      privacyPolicies: [],
    };
  }

  componentDidUpdate(prevProps) {
    const types = ['term', 'privacy'];
    if (prevProps.policies !== this.props.policies) {
      const policies = this.props.policies;
      const activePolicies = filterActiveItems(policies);
      types.forEach(type => {
        this.setState({
          [`${type}Policies`]: filterItemsByType(activePolicies, type),
        });
      });
    }
    if (prevProps.invitedCustomer !== this.props.invitedCustomer) {
      this.props.onCloseModal();
    }
  }

  componentDidMount() {
    this.props.onGetPolicies();
  }

  handleSubmit = e => {
    const { teamId } = this.props;
    const { phoneNumber, termPolicies, privacyPolicies } = this.state;

    e.preventDefault();
    //  If have teamId property: case Admin invite new user into an exist team
    //  Else: case Admin invite new user own a new project
    if (!teamId) {
      this.setState({
        phoneNumberRequired: !phoneNumber ? true : false,
      });
    }
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const termConditionAccepted = termPolicies.length ? termPolicies[0]._id : '';
        const privacyAccepted = privacyPolicies.length ? privacyPolicies[0]._id : '';
        const data = {
          teamWorkId: teamId,
          fullName: values.fullName && values.fullName.trim(),
          email: values.email && values.email.trim(),
          phoneNumber,
          termConditionAccepted,
          privacyAccepted,
        };
        if (!teamId && phoneNumber) {
          // add noti config when create user doc
          const customerReceiveNoti = {
            isRealtimeNoti: true,
            isProjectStatusUpdate: false,
            isTopicUpdate: false,
            isUnseenProjectUpdate: false,
            isUnseenWorkspaceUpdate: false,
          };
          const dataUser = {
            ...data,
            customerReceiveNoti,
          };
          this.props.onSendInvitation(dataUser);
        } else if (teamId) {
          this.props.onAdminSendJoinTeam(data);
          this.props.onCloseModal();
        }
      }
    });
  };

  renderModal = () => {
    const { phoneNumberRequired, phoneNumber } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { teamId, isLoading, modalVisible } = this.props;
    return (
      <Modal
        visible={modalVisible}
        onCancel={this.props.onCloseModal}
        className="customer-invitation-modal"
        footer={null}
        closable={true}
        destroyOnClose={true}
      >
        <h1 className="invitation-title">
          {!teamId ? 'Create and Invite' : 'Invite user to a team'}
        </h1>
        <>
          <span className="invitation-description first-description">
            {!teamId
              ? 'Now you can create the project and the customers will be invited to the project!'
              : 'Send an invitation for the user to join an existing team'}
          </span>
          <span className="invitation-description">
            {!teamId &&
              "Note - when you create and invite new members to a project they can only see workspaces and topics they're invited to."}
          </span>
        </>

        <Form className="invitation-form">
          <Form.Item label="Full Name">
            {getFieldDecorator('fullName', {
              rules: [
                {
                  required: true,
                  message: 'Please input Full name',
                },
                {
                  whitespace: true,
                  message: 'Please do not input whitespace only',
                },
              ],
              initialValue: null,
            })(<Input size="large" />)}
          </Form.Item>
          <Form.Item label={renderRequireLabel('Email')}>
            {getFieldDecorator('email', {
              rules: [
                {
                  validator: (rule, value, callback) => {
                    const msg = validateEmail(value, 'Email');
                    if (msg) {
                      callback(msg);
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(<Input placeholder="Email" size="large" />)}
          </Form.Item>
          <Form.Item label={teamId ? 'Phone Number' : renderRequireLabel('Phone Number')}>
            <ReactPhoneInput
              country={'gb'}
              className="phone-number"
              required={!teamId}
              onChange={value => {
                if (value) {
                  this.setState({
                    phoneNumberRequired: false,
                  });
                }
                this.setState({ phoneNumber: value });
              }}
              value={phoneNumber}
            />
            {phoneNumberRequired && (
              <div className="ant-form-explain">Please input phone number!</div>
            )}
          </Form.Item>

          <div className="buttons-container">
            <div className="btn-item-container">
              <Button
                type="primary"
                onClick={() => {
                  this.setState({
                    phoneNumber: '',
                    phoneNumberRequired: false,
                  });
                  this.props.onCloseModal();
                }}
                className="btn font-weight-bold"
                size="large"
              >
                Cancel
              </Button>
            </div>
            <div className="btn-item-container">
              <Button
                type="primary"
                loading={isLoading}
                onClick={this.handleSubmit}
                className="btn btn-create btn-primary-0"
                size="large"
                disabled={isLoading}
              >
                {!teamId ? 'Create' : 'Invite'}
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    );
  };

  render() {
    return this.renderModal();
  }
}

const mapStateToProps = state => {
  return {
    policies: state.policies.data,
    isLoading: state.customers.isLoading,
    invitedCustomer: state.customers.detailData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAdminSendJoinTeam: data => {
      dispatch(adminInviteJoinTeam(data));
    },
    onSendInvitation: data => {
      dispatch(registerCustomer(data));
    },
    onGetPolicies: type => {
      dispatch(getPolicies(type));
    },
  };
};

const WrappedCustomerInvitationModal = Form.create({
  name: 'normal_project_invitation_customer_modal',
})(connect(mapStateToProps, mapDispatchToProps)(CustomerInvitationModal));

export default WrappedCustomerInvitationModal;
