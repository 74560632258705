import React, { PureComponent } from 'react';
import './titleForm.scss';
import { Form, Input, Tooltip } from 'antd';
import { updateTopic } from '../../../../../../redux/actions/admin/topicsAction';
import { connect } from 'react-redux';

class TitleForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateTopicHandler = data => {
    const { currentTopic } = this.props;
    const body = {
      id: currentTopic._id,
      ...data,
    };
    this.props.onUpdateTopic(body);
  };

  render() {
    const { currentTopic } = this.props;
    const alias = (currentTopic && currentTopic.alias) || null;
    const title = (currentTopic && currentTopic.title) || null;
    return (
      <div className="conversation-title-update">
        <span className="alias-topic">{alias}</span>
        <Tooltip title={title}>
          <Input
            key={`title${title}`}
            defaultValue={title}
            className="topics-title"
            onPressEnter={e => {
              if (e.target.value.trim() && title && title !== e.target.value.trim()) {
                this.updateTopicHandler({
                  title: e.target.value,
                });
              }
            }}
            onBlur={e => {
              if (e.target.value.trim() && title && title !== e.target.value.trim()) {
                this.updateTopicHandler({
                  title: e.target.value,
                });
              }
            }}
            placeholder="Title"
            size="large"
          />
        </Tooltip>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateTopic: body => {
      dispatch(updateTopic(body));
    },
  };
};

const WrappedTitleForm = Form.create({ name: 'normal_title' })(TitleForm);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedTitleForm);
