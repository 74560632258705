import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../../actions/typeAction';
import {
	getPoliciesService,
	createPoliciesService,
	updatePoliciesService
} from '../../../core/services/modules/policiesService';

export function* getPoliciesSaga(action) {
	const response = yield call(getPoliciesService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_POLICIES_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_POLICIES_SUCCESS,
			payload: data
		});
	}
}

export function* createPoliciesSaga(action) {
	const response = yield call(createPoliciesService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.CREATE_POLICIES_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.CREATE_POLICIES_SUCCESS,
			payload: response.data
		});
	}
}

export function* updatePoliciesSaga(action) {
	const response = yield call(updatePoliciesService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_POLICIES_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.UPDATE_POLICIES_SUCCESS,
			payload: response.data
		});
	}
}

export function* watchPolicies() {
	yield takeLatest(actionTypes.GET_POLICIES_REQUEST, getPoliciesSaga);
	yield takeLatest(actionTypes.CREATE_POLICIES_REQUEST, createPoliciesSaga);
	yield takeLatest(actionTypes.UPDATE_POLICIES_REQUEST, updatePoliciesSaga);
}
