import axios from 'axios';
import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';
import { storageService } from 'src/core/services/storageService';

const instance = axios.create({
  baseURL: 'https://api.frame.io',
});

export const hasTokenFrameIO = () => {
  return !!localStorage.getItem('tokenFrameIO');
};
export const verifyTokenFrameIO = async () => {
  const url = BaseConfig.END_POINT.OAUTH.VERIFY_TOKEN_FRAME_IO;
  const { data } = await httpService._get(url);
  const {
    data: { accessToken = null, info = null },
  } = data;
  if (accessToken) {
    storageService.setAuthTokenFrameIo(accessToken);
    instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }
  if (info) {
    const teamId = (await getTeamAccount(info.account_id)) || '';
    return {
      accessToken,
      info,
      teamId,
    };
  }
  return {
    accessToken,
    info,
  };
};

export const getTeamAccount = async accountId => {
  const { data } = await instance.get(`/v2/accounts/${accountId}/teams`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN_FRAME_IO')}`,
    },
  });
  if (data) return data[0].id;
  return null;
};
export const logoutFrameIO = () => {
  const url = BaseConfig.END_POINT.OAUTH.LOGOUT_FRAME_IO;
  return httpService._post(url);
};

export const getListProjectByTeam = async (teamId, alias = '') => {
  const query = new URLSearchParams({
    'filter[archived]': 'all',
  }).toString();
  const { data: projects } = await instance.get(`/v2/teams/${teamId}/projects?${query}`);
  return projects.find(project => project.name.includes(alias));
};

export const getListAssets = async (assetId, children = false) => {
  let url = `/v2/assets/${assetId}`;
  if (children) url = `v2/assets/${assetId}/children`;
  const { data: listAssets } = await instance.get(url);
  return listAssets;
};

export const getListTeams = async () => {
  const { data } = await instance.get(`/v2/accounts?join=collaborator,reviewer`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN_FRAME_IO')}`,
    },
  });
  return data || [];
};

export const getListShareProjectByAccount = async (account_id, alias = '') => {
  const { data: projects } = await instance.get(
    `/v2/projects/shared?filter[account_id]=${account_id}`
  );
  return projects.find(project => project.name.includes(alias));
};
