import React from 'react';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import './assetForm.scss';
import { Form } from 'antd';
import ListAssets from './listAssets/listAssets';
import AssetGallery from './assetGallery/assetGallery';
import {
  signUrlRequest,
  attachLinkRequest,
  listAssetRequest,
  resetAssets,
  createFolderRequest,
  deleteResourceRequest,
  getGalleryFileRequest,
  updateResourceRequest,
  deleteAllResourcesRequest,
} from 'src/redux/actions/admin/assetsAction';

class AssetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isListAssetSession: true,
      isAssetGallerySession: false,
      dataAssets: [],
      totalAssets: 0,
      galleryImage: null,
    };
  }

  componentDidMount() {
    const { limit, page } = this.state;
    const { currentProject } = this.props;
    const projectId = currentProject && currentProject._id; // localStorage.getItem('PROJECT_ID');
    const data = {
      projectId,
      limit,
      page,
      status: true,
      isAsset: true,
    };
    this._getListData(data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.assets.dataSuccess && nextProps.assets.dataSuccess.docs) {
      this.setState(
        {
          dataAssets: nextProps.assets.dataSuccess.docs,
          totalAssets: nextProps.assets.dataSuccess.total,
        },
        () => {
          this.props.onResetAsset();
        }
      );
    }
  }

  _getListData = data => {
    this.props.onGetListAsset(data);
  };

  _viewGallery = (gal, files, id) => {
    if (gal) {
      this.setState({
        isListAssetSession: false,
        isAssetGallerySession: true,
        galleryImage: id,
      });
    }
  };

  _goBack = () => {
    const { limit, page } = this.state;
    const { currentProject } = this.props;
    const projectId = currentProject && currentProject._id; // localStorage.getItem('PROJECT_ID');
    const data = {
      projectId,
      limit,
      page,
      status: true,
      isAsset: true,
    };
    this._getListData(data);
    this.setState({
      isListAssetSession: true,
      isAssetGallerySession: false,
    });
  };

  _onHandleChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  renderListAssets() {
    const {
      onSignUrl,
      onAttachLink,
      onCreateFolder,
      onResetAsset,
      onDeleteResource,
      onUpdateResource,
      assets,
      onRemoveAllResources,
      hasAssigned,
      currentProject,
    } = this.props;
    const { dataAssets, totalAssets } = this.state;
    return (
      <ListAssets
        viewGallery={this._viewGallery}
        _onSignUrl={onSignUrl}
        _onAttachLink={onAttachLink}
        _onCreateFolder={onCreateFolder}
        _onResetAsset={onResetAsset}
        _onGetListAsset={this._getListData}
        _onDeleteResource={onDeleteResource}
        _onUpdateResource={onUpdateResource}
        assets={assets}
        dataAssets={dataAssets}
        totalAssets={totalAssets}
        onRemoveAllResources={onRemoveAllResources}
        hasAssigned={hasAssigned}
        currentProject={currentProject}
        onPreventBackClick={this.props.onPreventBackClick}
        onAllowBackClick={this.props.onAllowBackClick}
        onChange={this._onHandleChange}
      />
    );
  }

  renderAssetGallery() {
    const {
      onSignUrl,
      onResetAsset,
      onGetFileGallery,
      assets,
      onRemoveAllResources,
      currentProject,
      onUpdateResource,
    } = this.props;
    const { galleryImage } = this.state;
    return (
      <AssetGallery
        goBack={this._goBack}
        _onSignUrl={onSignUrl}
        _onResetAsset={onResetAsset}
        _onGetListAsset={this._getListData}
        galleryImage={galleryImage}
        _viewGallery={this._viewGallery}
        _onGetFileGallery={onGetFileGallery}
        assets={assets}
        onRemoveFromGallery={onRemoveAllResources}
        currentProject={currentProject}
        onPreventBackClick={this.props.onPreventBackClick}
        onAllowBackClick={this.props.onAllowBackClick}
        _onUpdateResource={onUpdateResource}
      />
    );
  }

  render() {
    const { isListAssetSession, isAssetGallerySession } = this.state;
    return (
      <div className="asset-form-wrapper">
        {isListAssetSession && this.renderListAssets()}
        {isAssetGallerySession && this.renderAssetGallery()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    assets: state.assets,
    currentProject: state.projects.detailData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSignUrl: data => {
      dispatch(signUrlRequest(data));
    },
    onAttachLink: data => {
      dispatch(attachLinkRequest(data));
    },
    onGetListAsset: projectId => {
      dispatch(listAssetRequest(projectId));
    },
    onCreateFolder: data => {
      dispatch(createFolderRequest(data));
    },
    onDeleteResource: assetId => {
      dispatch(deleteResourceRequest(assetId));
    },
    onUpdateResource: data => {
      dispatch(updateResourceRequest(data));
    },
    onGetFileGallery: galleryId => {
      dispatch(getGalleryFileRequest(galleryId));
    },
    onResetAsset: () => {
      dispatch(resetAssets());
    },
    onRemoveAllResources: data => {
      dispatch(deleteAllResourcesRequest(data));
    },
  };
};

const WrappedAssetForm = Form.create({ name: 'normal_projects' })(
  connect(mapStateToProps, mapDispatchToProps)(AssetForm)
);

export default WrappedAssetForm;
