import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Tooltip } from 'antd';
import './projectTitle.scss';
import { updateProjects } from 'src/redux/actions/admin/projectsAction';

class ProjectTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      title: (this.props.currentProject && this.props.currentProject.title) || null,
    };
  }

  onUpdate = (property, value) => {
    const { currentProject } = this.props;
    const valueData = typeof value === 'string' ? value.trim() : value;
    this.props.onUpdateProject({
      id: currentProject._id,
      [property]: valueData,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.currentProject &&
      nextProps.currentProject._id !== this.props.currentProject._id
    ) {
      this.setState({
        title: nextProps.currentProject.title,
      });
    }
  }

  render() {
    const { currentProject, hasAssigned } = this.props;
    const oldTitle = currentProject && currentProject.title;
    if (currentProject) {
      return (
        <div className="project-title-container">
          <Tooltip title={this.state.title}>
            <Input
              className="project-title"
              value={this.state.title}
              onChange={e => {
                this.setState({
                  title: e.target.value,
                });
              }}
              onBlur={e => {
                if (e.target.value && oldTitle !== e.target.value.trim()) {
                  this.onUpdate('title', e.target.value);
                }
              }}
              onPressEnter={e => {
                if (e.target.value && oldTitle !== e.target.value.trim()) {
                  this.onUpdate('title', e.target.value);
                }
              }}
              placeholder="Title"
              size="large"
              disabled={!hasAssigned}
            />
          </Tooltip>
        </div>
      );
    }
    return (
      <div className="project-title-container">
        <Tooltip title={this.state.title}>
          <Input
            className="project-title"
            value={this.state.title}
            onChange={e => {
              this.setState({ title: e.target.value });
            }}
            onBlur={e => {
              this.props.onInputFields('title', e.target.value);
            }}
            onPressEnter={e => {
              this.props.onInputFields('title', e.target.value);
            }}
            placeholder="Title"
            size="large"
          />
        </Tooltip>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    // assets: state.assets
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateProject: data => {
      dispatch(updateProjects(data));
    },
  };
};

const WrappedProjectTitle = Form.create({ name: 'normal_project_title' })(
  connect(mapStateToProps, mapDispatchToProps)(ProjectTitle)
);

export default WrappedProjectTitle;
