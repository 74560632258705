import { connect } from 'react-redux';
import CustomerStoriesScreen from '../../../components/screens/admin/customerStories/customerStoriesScreen';
import {
    getCustomerStories,
    deleteCustomerStories,
    createCustomerStories,
    updateCustomerStories,
    updateOrder,
    getStoriesHightLight,
} from '../../../redux/actions/admin/customerStoriesAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';
import { getProductTypes } from '../../../redux/actions/admin/productTypesAction';
import { getServiceTypeByProductType } from '../../../redux/actions/admin/serviceTypeAction';

const mapStateToProps = (state) => {
    return {
        success: state.customerStories.success,
        error: state.customerStories.error,
        isLoading: state.customerStories.isLoading,
        data: state.customerStories.data,
        storyHightLight: state.customerStories.hightLight,
        productType: state.productType.data,
        serviceType: state.serviceType.serviceTypeByProductType,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetCustomerStories: () => {
            dispatch(getCustomerStories());
        },
        onDeleteCustomerStories: (key) => {
            dispatch(deleteCustomerStories(key));
        },
        onCreateCustomerStories: (data) => {
            dispatch(createCustomerStories(data));
        },
        onUpdateCustomerStories: ({ data, id }) => {
            dispatch(updateCustomerStories({ data, id }));
        },
        onUpdateOrder: (data) => {
            dispatch(updateOrder(data));
        },
        onGetCurrentUser: () => {
            dispatch(getCurrentUser());
        },
        onGetProductTypes: () => {
            dispatch(getProductTypes());
        },
        onGetServiceType: (id) => {
            dispatch(getServiceTypeByProductType(id));
        },
        onGetStoriesHightLight: () => {
            dispatch(getStoriesHightLight());
        },
    };
};

const CustomerStoriesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerStoriesScreen);

export default CustomerStoriesContainer;
