import {
  GET_FILES_AND_LINKS_REQUEST,
  GET_FILES_AND_LINKS_FAILED,
  GET_FILES_AND_LINKS_SUCCESS,
  UPDATE_FILES_AND_LINKS_SUCCESS,
  UPDATE_FILES_AND_LINKS_FAILED,
  UPDATE_FILES_AND_LINKS_REQUEST,
  MOVE_FILES_TO_ASSETS_REQUEST,
  MOVE_FILES_TO_ASSETS_SUCCESS,
  MOVE_FILES_TO_ASSETS_FAILED,
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';

const updateHelper = (state, action) => {
  const currentData = state.data.docs.map(item => {
    if (item._id === action.payload.data._id) {
      return {
        ...action.payload.data,
        createdBy: item.createdBy,
      };
    }
    return item;
  });
  return currentData;
};

const defaultState = {
  success: false,
  error: null,
  data: null,
  isLoading: false,
  hasMoved: false,
};

const filesAndLinksReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_FILES_AND_LINKS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_FILES_AND_LINKS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    }
    case GET_FILES_AND_LINKS_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case UPDATE_FILES_AND_LINKS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPDATE_FILES_AND_LINKS_SUCCESS: {
      openNotification('success', 'Update successful');
      return {
        ...state,
        isLoading: false,
        data: { ...state.data, docs: updateHelper(state, action) },
      };
    }
    case UPDATE_FILES_AND_LINKS_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case MOVE_FILES_TO_ASSETS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        hasMoved: false,
      };
    }
    case MOVE_FILES_TO_ASSETS_SUCCESS: {
      openNotification('success', 'Move successfully');
      return {
        ...state,
        isLoading: false,
        hasMoved: true,
      };
    }
    case MOVE_FILES_TO_ASSETS_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        hasMoved: false,
      };
    }
    default:
      return state;
  }
};

export default filesAndLinksReducer;
