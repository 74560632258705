import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../../actions/typeAction';
import {
	getProductTypesService,
	createProductTypesService,
	updateProductTypesService
} from '../../../core/services/modules/productTypesService';
import { updateOrderService } from '../../../core/services/modules/orderService';
import { openNotification } from '../../../components/common/notification/notification';

export function* getProductTypesSaga(action) {
	const response = yield call(getProductTypesService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_PRODUCT_TYPES_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_PRODUCT_TYPES_SUCCESS,
			payload: data
		});
	}
}

export function* getProductTypesByIdSaga(action) {
	const response = yield call(getProductTypesService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_PRODUCT_TYPES_BY_ID_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_PRODUCT_TYPES_BY_ID_SUCCESS,
			payload: data
		});
	}
}

export function* createProductTypesSaga(action) {
	const response = yield call(createProductTypesService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.CREATE_PRODUCT_TYPES_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.CREATE_PRODUCT_TYPES_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateProductTypesSaga(action) {
	const response = yield call(updateProductTypesService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_PRODUCT_TYPES_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.UPDATE_PRODUCT_TYPES_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateOrderSaga(action) {
	const response = yield call(updateOrderService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_ORDER_PRODUCT_TYPES_FAILED,
			payload: response.data.error
		});
	} else {
		openNotification('success', 'Update successful');
	}
}

export function* watchProductTypes() {
	yield takeLatest(actionTypes.GET_PRODUCT_TYPES_REQUEST, getProductTypesSaga);
	yield takeLatest(actionTypes.CREATE_PRODUCT_TYPES_REQUEST, createProductTypesSaga);
	yield takeLatest(actionTypes.UPDATE_PRODUCT_TYPES_REQUEST, updateProductTypesSaga);
	yield takeLatest(actionTypes.UPDATE_ORDER_PRODUCT_TYPES_SUCCESS, updateOrderSaga);
	yield takeLatest(actionTypes.GET_PRODUCT_TYPES_BY_ID_REQUEST, getProductTypesByIdSaga);
}
