import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Jumbotron, Container } from 'react-bootstrap';
import './caseStudiesScreen.scss';
import DataTable from '../../../common/dragTable/dragTable';
import ModalData from '../../../common/newModal/newModal';
import { getS3CDN } from '../../../../core/utils/commonUtils';
import CaseStudiesForm from '../../../common/form/caseStudiesForm/caseStudiesForm';
import { ORDER_KEYS } from '../../../../core/constants/constants';
import { isYoutubeLink } from '../../../../core/utils/customValidator';

class CaseStudiesScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Admin Customer Stories - VidOps',
      data: [],
      key: '',
      itemUpdate: null,
      isModalOpen: false,
      isCreate: false,
    };
  }

  updateBannerHandler = (updateData) => {
    this.props.onUpdateBanner(updateData);
  };

  componentDidMount() {
    this.props.onGetCurrentUser();
    this.props.onGetCustomerStories();
    this.props.onGetCaseStudies();
    this.props.onGetProductTypes();
  }

  handleDelete = (key) => {
    this.props.onDeleteCaseStudies(key);
  };

  handleUpdate = (key) => {
    const { data } = this.props;
    if (data) {
      const itemUpdate = data.find((item) => item._id === key);
      if (itemUpdate._id === key) {
        this.props.onGetServiceType({ productTypeId: itemUpdate.serviceTypeId.productTypeId._id, status: true });
      }
      this.setState({
        itemUpdate,
        isModalOpen: true,
        isCreate: false,
        key,
      });
    }
  };

  formatColumns = () => {
    return [
      {
        title: 'Title',
        dataIndex: 'title',
      },
      {
        title: 'Media',
        dataIndex: 'featureUrl',
        render: (text, record) => {
          if (record.type && record.type === 'image') {
            return <img className="thumbnail" src={getS3CDN(record.featureUrl)} alt="media" />;
          }
          return (
            <span>
              {record.thumbnailUrl ? (
                <img className="thumbnail" src={getS3CDN(record.thumbnailUrl)} alt="media" />
              ) : (
                  <video className="thumbnail">
                    <source src={record.featureUrl} type="video/mp4" />
                    <source src={record.featureUrl} type="video/ogg" />
                    <source src={record.featureUrl} type="video/webm" />
                  Your browser does not support the video tag.
                  </video>
                )}
            </span>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (status) => {
          if (status) {
            return <span>Active</span>;
          }
          return <span>Inactive</span>;
        },
        filters: [
          {
            text: 'Active',
            value: 'Active',
          },
          {
            text: 'Inactive',
            value: 'Inactive',
          },
        ],
        onFilter: (value, record) => {
          const status = record.status ? 'Active' : 'Inactive';
          return status.indexOf(value) === 0;
        },
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        render: (text, record) => {
          return (
            <div className="operation-container">
              <Button
                onClick={() => this.handleUpdate(record.key)}
                type="primary"
                className="btn-primary-0"
                icon="edit"
              ></Button>
            </div>
          );
        },
      },
    ];
  };

  formatData = (data) => {
    if (data && data.length) {
      return data.map((item, index) => {
        return {
          ...item,
          key: item._id,
          featureUrl: isYoutubeLink(item.featureUrl)
            ? item.featureUrl
            : getS3CDN(item.featureUrl),
          images:
            item.images && item.images.length
              ? item.images.map((el) => getS3CDN(el))
              : [],
        };
      });
    }
  };

  handleAdd = () => {
    this.setState({
      isModalOpen: true,
      isCreate: true,
      itemUpdate: null,
    });
  };

  createHandler = (data) => {
    this.props.onCreateCaseStudies(data);
  };

  updateHandler = (data) => {
    const payload = {
      data,
      id: this.state.key,
    };
    this.props.onUpdateCaseStudies(payload);
  };

  closeModalHandler = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        isModalOpen: false,
      });
    }
  }

  updateOrderHandler = (orderData) => {
    const updateOrderData = {
      key: ORDER_KEYS.CASE_STUDY,
      ...orderData,
    };
    this.props.onUpdateOrder(updateOrderData);
  };

  getServiceType = (id) => {
    this.props.onGetServiceType({ productTypeId: id, status: true });
  };

  render() {
    const { data, customerStories, productType, serviceType } = this.props;

    const { isModalOpen, isCreate, key, itemUpdate } = this.state;
    const columns = this.formatColumns();
    const dataSource = this.formatData(data);
    return (
      <main role="main" className="page-content customer-stories-screen">
        <Jumbotron className="mgt-40">
          <h2 className="sub-heading">Case studies</h2>
          <p className="description">Manage Case studies</p>
          <div className="divider"></div>
          <Container>
            <Button
              className="btn-primary-0"
              onClick={this.handleAdd}
              type="primary"
              style={{ marginBottom: 16 }}
            >
              Add new
            </Button>
            <DataTable
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              onUpdateOrder={this.updateOrderHandler}
              hiddenUpdateOrder={true}
            />
            <ModalData
              className="big-modal"
              title={isCreate ? 'Create case studies' : 'Update case studies'}
              visible={isModalOpen}
              onCloseModal={this.closeModalHandler}
              dataSource={
                <CaseStudiesForm
                  onCreateCaseStudies={this.createHandler}
                  onUpdateCaseStudies={this.updateHandler}
                  onGetServiceType={this.getServiceType}
                  productType={productType}
                  serviceType={serviceType}
                  isLoading={this.props.isLoading}
                  isCreate={isCreate}
                  itemUpdate={itemUpdate}
                  key={key}
                  customerStories={customerStories}
                />
              }
              footer={null}
            />
          </Container>
        </Jumbotron>
      </main>
    );
  }
}

export default withTranslation('common')(CaseStudiesScreen);
