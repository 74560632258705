import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './creativesContact.scss';
import { Icon, Dropdown, Menu, Select } from 'antd';
import PhoneIcon from '../../../../../assets/images/icons/common/phone.svg';
import EmailIcon from '../../../../../assets/images/icons/common/email.svg';
import LocationIcon from '../../../../../assets/images/icons/common/location.svg';
import BirthDateIcon from '../../../../../assets/images/icons/common/birth-date.svg';
import {
  CREATIVES_STATUS,
  CREATIVES_TIERS,
} from '../../../../../core/constants/constants';
import {
  updateCreatives,
  resendInvitation,
} from '../../../../../redux/actions/admin/creativesAction';
import { renderTierIcon } from '../../../../../core/utils/commonUtils';
import UserAvatar from 'src/components/common/userAvatar/index.js';

const { Option } = Select;

class CreativesContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderStatus = () => {
    const { creatives } = this.props || {};
    const { status, userId } = creatives || {};
    const filterStatus = CREATIVES_STATUS.filter(item => item.value !== status);
    const statusRender = CREATIVES_STATUS.find(item => item.value === status);
    return (
      <Select
        defaultValue={statusRender.label}
        style={{ width: 120 }}
        disabled={userId.isDelete}
        onChange={value =>
          this.props.onUpdateCreatives({
            id: creatives && creatives._id,
            status: value,
          })
        }
      >
        {filterStatus.map(item => (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    );
  };

  renderTier = () => {
    const { creatives } = this.props || {};
    const { userId, tier } = creatives || {};
    const filterTier = CREATIVES_TIERS.filter(item => item.value !== tier);
    const menu = (
      <Menu>
        {filterTier.map(item => (
          <Menu.Item key={item.value}>
            <span
              onClick={() =>
                this.props.onUpdateCreatives({
                  id: creatives && creatives._id,
                  tier: item.value,
                })
              }
            >
              {item.label}
            </span>
          </Menu.Item>
        ))}
      </Menu>
    );
    const tierRender = CREATIVES_TIERS.find(item => item.value === tier);
    return (
      <Dropdown
        overlay={menu}
        trigger={['click']}
        disabled={userId.isDelete}
        className={userId.isDelete ? 'c-nodrop' : ''}
      >
        <span className="ant-dropdown-link tier-text" onClick={e => e.preventDefault()}>
          {tierRender.label || null} <Icon type="down" />
        </span>
      </Dropdown>
    );
  };

  renderAvatar = creatives => {
    const { userId, tier } = creatives || {};
    const { avt, fullName } = userId || {};
    return (
      <UserAvatar
        fullName={fullName}
        avatar={avt}
        status={true}
        iconSrc={renderTierIcon(tier)}
      />
    );
  };

  renderAccountId = creatives => (
    <span className="account-id">
      {creatives.userId._id.substring(creatives.userId._id.length - 4)}
    </span>
  );

  renderHeader = creatives => (
    <div className="header-container">
      <span className="name">{creatives.userId.fullName}</span>
      <div className="divider"></div>
      {this.renderTier()}
    </div>
  );

  renderBody = creatives => (
    <div className="body-container">
      <div className="birth-date-container">
        <img src={BirthDateIcon} alt="birth-date" className="birth-date-icon" />
        <span className="birth-date">{creatives.userId.birthday}</span>
      </div>
      <div className="email-container">
        <img src={EmailIcon} alt="email" className="email-icon" />
        <a className="email" href={'mailto:' + creatives.userId.email}>
          {creatives.userId.email}
        </a>
      </div>
    </div>
  );

  renderFooter = creatives => {
    return (
      <div className="footer-container">
        <div className="phone-container">
          <img src={PhoneIcon} alt="phone" className="phone-icon" />
          <a className="phone" href={'tel:' + creatives.userId.phoneNumber}>
            {creatives.userId.phoneNumber}
          </a>
        </div>
        <div className="location-container">
          <img src={LocationIcon} alt="location" className="location-icon" />
          {creatives.userId.city ? `${creatives.userId.city}, ` : null}
          {creatives.userId.country || null}
        </div>
        {this.renderStatus(creatives)}
      </div>
    );
  };

  render() {
    const { creatives } = this.props;
    return (
      <div className="creatives-contact-container">
        {this.renderAvatar(creatives)}
        <div className="contact-container">
          {this.renderAccountId(creatives)}
          {this.renderHeader(creatives)}
          {this.renderBody(creatives)}
          {this.renderFooter(creatives)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isSendingInvitation: state.creatives.isSendingInvitation,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateCreatives: data => {
      dispatch(updateCreatives(data));
    },
    onResendInvitation: data => {
      dispatch(resendInvitation(data));
    },
  };
};

export default withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(CreativesContact)
);
