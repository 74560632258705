import {
  ATTACH_LINK_REQUEST,
  LIST_ASSET_REQUEST,
  SIGN_URL_REQUEST,
  CREATE_FOLDER_REQUEST,
  GET_GALLERY_FILE_REQUEST,
  DELETE_RESOURCE_REQUEST,
  UPDATE_RESOURCE_REQUEST,
  RESET_ASSETS,
  DELETE_ALL_RESOURCES_REQUEST,
  RENAME_IMAGE_GALLERY_REQUEST,
} from '../typeAction';

export const listAssetRequest = projectId => ({
  type: LIST_ASSET_REQUEST,
  payload: projectId,
});

export const attachLinkRequest = data => ({
  type: ATTACH_LINK_REQUEST,
  payload: data,
});

export const signUrlRequest = data => ({
  type: SIGN_URL_REQUEST,
  payload: data,
});

export const createFolderRequest = data => ({
  type: CREATE_FOLDER_REQUEST,
  payload: data,
});

export const deleteResourceRequest = projectId => ({
  type: DELETE_RESOURCE_REQUEST,
  payload: projectId,
});

export const updateResourceRequest = data => ({
  type: UPDATE_RESOURCE_REQUEST,
  payload: data,
});

export const getGalleryFileRequest = params => ({
  type: GET_GALLERY_FILE_REQUEST,
  payload: params,
});

export const resetAssets = () => ({
  type: RESET_ASSETS,
  payload: {},
});

export const deleteAllResourcesRequest = data => ({
  type: DELETE_ALL_RESOURCES_REQUEST,
  payload: data,
});

export const renameImageGalleryRequest = data => ({
  type: RENAME_IMAGE_GALLERY_REQUEST,
  payload: data,
});
