import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import logoLight from '../../../assets/images/logo/Horizontal/horizontal-logo.svg';
import { Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Menu, Icon } from 'antd';
import './sidebar.scss';
import {
  logoutActionListener,
  getNumberProject,
} from 'src/core/utils/event_emitter/handler';
import { storageService } from 'src/core/services/storageService';
import { loginReset } from 'src/redux/actions/auth/loginAction';
import { getNumberOfProject } from 'src/redux/actions/admin/projectsAction';

const { SubMenu } = Menu;

const subMenuKeys = ['cms-5', 'cms-6', 'cms-7', 'cms-8', 'cms-9'];
const statusProject = {
  All: 'project-1',
  'Pre-Project': 'project-2',
  'Pre-Production': 'project-3',
  'Production-Ready': 'project-4',
  'Post-Production': 'project-5',
  Closed: 'project-6',
};
class SideBar extends Component {
  constructor(props) {
    super(props);
    this.logoutListener = null;
    this.getNumberProject = null;
    this.activeMenuPaths = {
      '/admins': {
        activeMenu: ['admins'],
        activeMenuItems: ['all-admins'],
      },
      '/customers': {
        activeMenu: ['customer'],
        activeMenuItems: ['all-customers'],
      },
      '/editors': {
        activeMenu: ['editors'],
        activeMenuItems: ['all-editors'],
      },
      '/creatives': {
        activeMenu: ['creative'],
        activeMenuItems: ['creative-1'],
      },
      '/seo': {
        activeMenu: ['cms', 'cms-9'],
        activeMenuItems: ['cms-94'],
      },
      '/banner': {
        activeMenu: ['cms', 'cms-5'],
        activeMenuItems: ['cms-51'],
      },
      '/our-team': {
        activeMenu: ['cms', 'cms-5'],
        activeMenuItems: ['cms-53'],
      },
      '/faqs': {
        activeMenu: ['cms', 'cms-5'],
        activeMenuItems: ['cms-54'],
      },
      '/stories': {
        activeMenu: ['cms', 'cms-9'],
        activeMenuItems: ['cms-93'],
      },
      '/case-studies': {
        activeMenu: ['cms', 'cms-9'],
        activeMenuItems: ['cms-92'],
      },
      '/customer-stories': {
        activeMenu: ['cms', 'cms-9'],
        activeMenuItems: ['cms-91'],
      },
      '/hour-rate': {
        activeMenu: ['cms', 'cms-6'],
        activeMenuItems: ['cms-61'],
      },
      '/budget': {
        activeMenu: ['cms', 'cms-6'],
        activeMenuItems: ['cms-64'],
      },
      '/skills': {
        activeMenu: ['cms', 'cms-6'],
        activeMenuItems: ['cms-62'],
      },
      '/product-types': {
        activeMenu: ['cms', 'cms-6'],
        activeMenuItems: ['cms-82'],
      },
      '/service-types': {
        activeMenu: ['cms', 'cms-8'],
        activeMenuItems: ['cms-63'],
      },
      '/packages': {
        activeMenu: ['cms', 'cms-8'],
        activeMenuItems: ['cms-83'],
      },
      '/categories': {
        activeMenu: ['cms', 'cms-8'],
        activeMenuItems: ['cms-85'],
      },
      '/measure-units': {
        activeMenu: ['cms', 'cms-8'],
        activeMenuItems: ['cms-84'],
      },
      '/sub-categories': {
        activeMenu: ['cms', 'cms-8'],
        activeMenuItems: ['cms-86'],
      },
      '/items': {
        activeMenu: ['cms', 'cms-8'],
        activeMenuItems: ['cms-87'],
      },
      '/add-ons': {
        activeMenu: ['cms', 'cms-8'],
        activeMenuItems: ['cms-88'],
      },
      '/add-hoc': {
        activeMenu: ['cms', 'cms-8'],
        activeMenuItems: ['cms-89'],
      },
      '/policies?type=privacy': {
        activeMenu: ['cms', 'cms-7'],
        activeMenuItems: ['policies-privacy'],
      },
      '/policies?type=term': {
        activeMenu: ['cms', 'cms-7'],
        activeMenuItems: ['policies-term'],
      },
      '/policies?type=customer': {
        activeMenu: ['cms', 'cms-7'],
        activeMenuItems: ['policies-customer'],
      },
      '/policies?type=creatives': {
        activeMenu: ['cms', 'cms-7'],
        activeMenuItems: ['policies-creatives'],
      },
      '/policies?type=cookie': {
        activeMenu: ['cms', 'cms-7'],
        activeMenuItems: ['policies-cookie'],
      },
      '/email-template': {
        activeMenu: ['cms', 'cms-95'],
      },
    };
    this.state = {
      openMenuKeys: ['project'],
      selectedKeys: ['project-1'],
    };
  }

  UNSAFE_componentWillMount() {
    this.onChangeRoute();
  }

  componentDidMount() {
    this.logoutListener = logoutActionListener(this.onLogout);
    this.getNumberProject = getNumberProject(this.getNumberOfProject);
    this.props.currentUser && this.getNumberOfProject();
    const params = queryString.parse(this.props.location.search);
    if (params.status) {
      this.setState({
        selectedKeys: [statusProject[params['status']]],
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.currentUser !== nextProps.currentUser) {
      this.getNumberOfProject();
    }
  }

  getNumberOfProject = async () => {
    await this.props.getNumberProjectAction();
  };

  componentWillUnmount() {
    if (this.logoutListener) {
      this.logoutListener();
    }
    if (this.getNumberProject) {
      this.getNumberProject();
    }
  }

  onLogout = async () => {
    // Remove credentials and reset sidebar status
    storageService.logout();
    this.props.logout();
    this.props.history.replace('/login');
    this.setState({ openMenuKeys: ['project'], selectedKeys: ['project-1'] });
  };

  onChangeRoute = (props = this.props) => {
    const { location } = props;
    const { pathname, search } = location;
    const fullPathName = `${pathname}${search}`;
    const activeMenuPath = this.activeMenuPaths[fullPathName];

    if (activeMenuPath) {
      this.setState({
        openMenuKeys: activeMenuPath.activeMenu,
        selectedKeys: activeMenuPath.activeMenuItems,
      });
      return;
    }

    for (const key of Object.keys(this.activeMenuPaths)) {
      if (pathname.startsWith(key)) {
        const menuItem = this.activeMenuPaths[key];
        this.setState({
          openMenuKeys: menuItem.activeMenu,
          selectedKeys: menuItem.activeMenuItems,
        });
        break;
      }
    }
  };

  onOpenChangeMenu = openKeys => {
    let latestOpenKey = openKeys[openKeys.length - 1];

    if (subMenuKeys.includes(latestOpenKey)) {
      const getSubKeys = openKeys.filter(key => subMenuKeys.includes(key));

      latestOpenKey = openKeys.slice(0, openKeys.length - getSubKeys.length);
      latestOpenKey = [...latestOpenKey, getSubKeys[getSubKeys.length - 1]];
    } else {
      latestOpenKey = openKeys.slice(openKeys.length - 1, openKeys.length);
    }

    this.setState({ openMenuKeys: latestOpenKey });
  };

  onMenuSelectKey = ({ key }) => {
    this.setState({
      selectedKeys: [key],
    });
  };

  renderCMS = () => {
    return (
      <SubMenu
        key="cms"
        title={
          <span>
            <Icon type="folder-open" />
            <span>CMS</span>
          </span>
        }
      >
        <SubMenu
          key="cms-5"
          title={
            <span>
              <span>Home Page</span>
            </span>
          }
        >
          <Menu.Item key="cms-51">
            <Link to="/banner">Banner</Link>
          </Menu.Item>
          <Menu.Item key="cms-53">
            <Link to="/our-team">Our Team</Link>
          </Menu.Item>
          <Menu.Item key="cms-54">
            <Link to="/faqs">FAQs</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="cms-9"
          title={
            <span>
              <span>Customer Stories</span>
            </span>
          }
        >
          <Menu.Item key="cms-93">
            <Link to="/stories">Our Stories</Link>
          </Menu.Item>
          <Menu.Item key="cms-92">
            <Link to="/case-studies">Case Studies</Link>
          </Menu.Item>
          <Menu.Item key="cms-91">
            <Link to="/customer-stories">Latest work</Link>
          </Menu.Item>
          <Menu.Item key="cms-94">
            <Link to="/seo">SEO</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="cms-6"
          title={
            <span>
              <span>Sign Up</span>
            </span>
          }
        >
          <Menu.Item key="cms-61">
            <Link to="/hour-rate">Creative Hourly Rates</Link>
          </Menu.Item>
          <Menu.Item key="cms-62">
            <Link to="/skills">Creative Skills</Link>
          </Menu.Item>
          <Menu.Item key="cms-82">
            <Link to="/product-types">Booking Production Types</Link>
          </Menu.Item>
          <Menu.Item key="cms-64">
            <Link to="/budget">Booking Budget</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="cms-8"
          title={
            <span>
              <span>Pricing</span>
            </span>
          }
        >
          <Menu.Item key="cms-63">
            <Link to="/service-types">Services</Link>
          </Menu.Item>
          <Menu.Item key="cms-83">
            <Link to="/packages">Packages</Link>
          </Menu.Item>
          <Menu.Item key="cms-84">
            <Link to="/measure-units">Measure Units</Link>
          </Menu.Item>
          <Menu.Item key="cms-85">
            <Link to="/categories">Categories</Link>
          </Menu.Item>
          <Menu.Item key="cms-86">
            <Link to="/sub-categories">Sub-categories</Link>
          </Menu.Item>
          <Menu.Item key="cms-87">
            <Link to="/items">Items</Link>
          </Menu.Item>
          <Menu.Item key="cms-88">
            <Link to="/add-ons">Add-ons</Link>
          </Menu.Item>
          <Menu.Item key="cms-89">
            <Link to="/add-hoc">Add-hocs</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="cms-7"
          title={
            <span>
              <span>Policy</span>
            </span>
          }
        >
          <Menu.Item key="policies-privacy">
            <Link to="/policies?type=privacy">Privacy Policy</Link>
          </Menu.Item>
          <Menu.Item key="policies-cookie">
            <Link to="/policies?type=cookie">Cookie Policy</Link>
          </Menu.Item>
          <Menu.Item key="policies-term">
            <Link to="/policies?type=term">Term & Conditions</Link>
          </Menu.Item>
          <Menu.Item key="policies-customer">
            <Link to="/policies?type=customer">Customer Policy</Link>
          </Menu.Item>
          <Menu.Item key="policies-creatives">
            <Link to="/policies?type=creatives">Creative Agreement</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="cms-95">
          <Link to="/email-template">Email Template</Link>
        </Menu.Item>
      </SubMenu>
    );
  };

  render() {
    const { openMenuKeys } = this.state;
    const { currentUser, numberOfProject } = this.props;
    // const { history } = this.props;
    const {
      allProject,
      preProject,
      preProduction,
      productionReady,
      postProduction,
      closed,
    } = numberOfProject;
    const currentUserRole =
      (currentUser && currentUser.roleId && currentUser.roleId.name) || null;
    return (
      <div className="bg-dark border-right" id="sidebar-wrapper">
        <div className="sidebar-heading bg-light">
          <Link to="/">
            <img src={logoLight} alt="" className="sidebar-logo" />
          </Link>
        </div>
        <br />
        <div className="list-group list-group-flush">
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={this.state.selectedKeys}
            openKeys={openMenuKeys}
            onSelect={this.onMenuSelectKey}
            onOpenChange={this.onOpenChangeMenu}
          >
            <SubMenu
              key="project"
              title={
                <span>
                  <Icon type="folder-open" />
                  <span>Project Management</span>
                </span>
              }
            >
              <Menu.Item key="project-1">
                <Link to="/?status=All">{`All Projects (${allProject})`}</Link>
              </Menu.Item>
              <Menu.Item key="project-2">
                <Link to="/?status=Pre-Project">{`Pre-Project (${preProject})`}</Link>
              </Menu.Item>
              <Menu.Item key="project-3">
                <Link to="/?status=Pre-Production">{`Pre-Production (${preProduction})`}</Link>
              </Menu.Item>
              <Menu.Item key="project-4">
                <Link to="/?status=Production-Ready">{`Production-Ready (${productionReady})`}</Link>
              </Menu.Item>
              <Menu.Item key="project-5">
                <Link to="/?status=Post-Production">{`Post-Production (${postProduction})`}</Link>
              </Menu.Item>
              <Menu.Item key="project-6">
                <Link to="/?status=Closed">{`Closed (${closed})`}</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="creative"
              title={
                <span>
                  <Icon type="folder-open" />
                  <span>Creatives Management</span>
                </span>
              }
            >
              <Menu.Item key="creative-1">
                <Link to="/creatives">Creatives</Link>
              </Menu.Item>
            </SubMenu>
            {/*<SubMenu*/}
            {/*key="payment"*/}
            {/*title={*/}
            {/*<span>*/}
            {/*<Icon type="folder-open" />*/}
            {/*<span>Payment Management</span>*/}
            {/*</span>*/}
            {/*}*/}
            {/*>*/}
            {/*<Menu.Item key="payment-1">Team 1</Menu.Item>*/}
            {/*<Menu.Item key="payment-2">Team 2</Menu.Item>*/}
            {/*</SubMenu>*/}
            <SubMenu
              key="customer"
              title={
                <span>
                  <Icon type="folder-open" />
                  <span>Customer Management</span>
                </span>
              }
            >
              <Menu.Item key="all-customers">
                <Link to="/customers">All Customers</Link>
              </Menu.Item>
              <Menu.Item key="all-teams">
                <Link to="/teams">All Teams</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="editors"
              title={
                <span>
                  <Icon type="folder-open" />
                  <span>Editors Management</span>
                </span>
              }
            >
              <Menu.Item key="all-editors">
                <Link to="/editors">All Editors</Link>
              </Menu.Item>
            </SubMenu>
            {currentUserRole && currentUserRole === 'superadmin' && (
              <SubMenu
                key="admins"
                title={
                  <span>
                    <Icon type="folder-open" />
                    <span>User Management</span>
                  </span>
                }
              >
                <Menu.Item key="all-admins">
                  <Link to="/admins">All Admins</Link>
                </Menu.Item>
              </SubMenu>
            )}
            {currentUserRole && currentUserRole === 'superadmin' && this.renderCMS()}
          </Menu>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.user.data,
    numberOfProject: state.projects.numberOfProject,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      return dispatch(loginReset());
    },
    getNumberProjectAction: () => {
      return dispatch(getNumberOfProject());
    },
  };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SideBar);
