import {
	GET_TEAMS_REQUEST,
	GET_TEAMS_SUCCESS,
	GET_TEAMS_FAILED,
	LOAD_MORE_TEAMS_REQUEST,
	LOAD_MORE_TEAMS_SUCCESS,
	LOAD_MORE_TEAMS_FAILED
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';

const loadMoreHelper = (state, action) => {
	const currentList =
		state.data && state.data.docs && state.data.docs.length
			? [...state.data.docs, ...action.payload.data.docs]
			: [...action.payload.data.docs];
	return { ...state.data, ...action.payload.data, docs: currentList };
};

const defaultState = {
	success: false,
	error: null,
	data: [],
	isLoading: false,
	detailData: null,
	chosenTeams: [],
	teams: []
};

const teamsReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_TEAMS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case GET_TEAMS_SUCCESS: {
			return {
				...state,
				isLoading: false,
				...action.payload
			};
		}
		case GET_TEAMS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case LOAD_MORE_TEAMS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case LOAD_MORE_TEAMS_SUCCESS: {
			return {
				...state,
				isLoading: false,
				data: loadMoreHelper(state, action)
			};
		}
		case LOAD_MORE_TEAMS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		default:
			return state;
	}
};

export default teamsReducer;
