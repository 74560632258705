import React from 'react';
import 'antd/dist/antd.css';
import './faqsForm.scss';
import { Form, Input, Button, Select } from 'antd';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

const { Option } = Select;

class FaqsForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			answer: this.props.isCreate ? '' : this.props.itemUpdate.answer
		};
	}

	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			const answer = this.state.answer.trim();
			if (!answer) {
				this.setState({
					answerError: 'Please input answer'
				});
				return;
			}
			if (!err) {
				const question = values.question.trim();
				const status = values.status.trim() === 'Active' ? true : false;
				const data = {
					question,
					status,
					answer
				};
				this.props.isCreate
					? this.props.onCreateFaqs(data)
					: this.props.onUpdateFaqs(data);
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { isCreate, itemUpdate, isLoading } = this.props;
		if (isCreate) {
			return (
				<Form className="faqs-form">
					<Form.Item label="Question">
						{getFieldDecorator('question', {
							rules: [
								{
									required: true,
									message: 'Please input question'
								},
								{
									whitespace: true,
									message:
										'Please do not input whitespace only'
								}
							],
							initialValue: null
						})(<Input placeholder="Question" size="large" />)}
					</Form.Item>
					<Form.Item label="Answer">
						<CKEditor
							onInit={editor => {
								editor.ui
									.getEditableElement()
									.parentElement.insertBefore(
										editor.ui.view.toolbar.element,
										editor.ui.getEditableElement()
									);
							}}
							onChange={(event, editor) => {
								const data = editor.getData();
								this.setState({
									answer: data,
									answerError: ''
								});
							}}
							editor={DecoupledEditor}
							data={null}
						/>
						{this.state.answerError && (
							<div className="ant-form-explain content-error">
								{this.state.answerError}
							</div>
						)}
					</Form.Item>
					<Form.Item label="Status" className="status-item">
						{getFieldDecorator('status', {
							rules: [
								{
									required: true,
									message: 'Please select faqs status'
								}
							],
							initialValue: 'Active'
						})(
							<Select>
								<Option value="Active">Active</Option>
								<Option value="Inactive">Inactive</Option>
							</Select>
						)}
					</Form.Item>
					<Form.Item>
						<Button
							type="primary"
							loading={isLoading}
							onClick={this.handleSubmit}
							className="faqs-form-button btn-primary-0"
							size="large"
							disabled={isLoading}
						>
							Create
						</Button>
					</Form.Item>
				</Form>
			);
		}
		return (
			<Form className="faqs-form">
				<Form.Item label="Question">
					{getFieldDecorator('question', {
						rules: [
							{
								required: true,
								message: 'Please input question'
							},
							{
								whitespace: true,
								message: 'Please do not input whitespace only'
							}
						],
						initialValue: itemUpdate.question
					})(<Input placeholder="Question" size="large" />)}
				</Form.Item>
				<Form.Item label="Answer">
					<CKEditor
						onInit={editor => {
							editor.ui
								.getEditableElement()
								.parentElement.insertBefore(
									editor.ui.view.toolbar.element,
									editor.ui.getEditableElement()
								);
						}}
						onChange={(event, editor) => {
							const data = editor.getData();
							this.setState({
								answer: data,
								answerError: ''
							});
						}}
						editor={DecoupledEditor}
						data={itemUpdate.answer}
					/>
					{this.state.answerError && (
						<div className="ant-form-explain content-error">
							{this.state.answerError}
						</div>
					)}
				</Form.Item>
				<Form.Item label="Status" className="status-item">
					{getFieldDecorator('status', {
						rules: [
							{
								required: true,
								message: 'Please select faqs status'
							}
						],
						initialValue: itemUpdate.statusText
					})(
						<Select>
							<Option value="Active">Active</Option>
							<Option value="Inactive">Inactive</Option>
						</Select>
					)}
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						loading={isLoading}
						onClick={this.handleSubmit}
						className="faqs-form-button btn-primary-0"
						size="large"
						disabled={isLoading}
					>
						Update
					</Button>
				</Form.Item>
			</Form>
		);
	}
}

const WrappedFaqsForm = Form.create({ name: 'normal_faqs' })(FaqsForm);

export default WrappedFaqsForm;
