import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../../actions/typeAction';
import {
	getItemsService,
	createItemsService,
	updateItemsService
} from '../../../core/services/modules/itemsService';
// import { getPackageService } from '../../../core/services/modules/packageService';
import { updateOrderService } from '../../../core/services/modules/orderService';
import { openNotification } from '../../../components/common/notification/notification';

export function* getItemsSaga() {
	const response = yield call(getItemsService);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_ITEMS_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_ITEMS_SUCCESS,
			payload: data
		});
	}
}

export function* createItemsSaga(action) {
	const response = yield call(createItemsService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.CREATE_ITEMS_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.CREATE_ITEMS_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateItemsSaga(action) {
	const response = yield call(updateItemsService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_ITEMS_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.UPDATE_ITEMS_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateOrderSaga(action) {
	const response = yield call(updateOrderService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_ORDER_ITEMS_FAILED,
			payload: response.data.error
		});
	} else {
		openNotification('success', 'Update successful');
	}
}

export function* watchItems() {
	yield takeLatest(actionTypes.GET_ITEMS_REQUEST, getItemsSaga);
	yield takeLatest(actionTypes.CREATE_ITEMS_REQUEST, createItemsSaga);
	yield takeLatest(actionTypes.UPDATE_ITEMS_REQUEST, updateItemsSaga);
	yield takeLatest(actionTypes.UPDATE_ORDER_ITEMS_SUCCESS, updateOrderSaga);
}
