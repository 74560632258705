import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../../actions/typeAction';
import {
	getOurTeamMembersService,
	createOurTeamMembersService,
	updateOurTeamMembersService
} from '../../../core/services/modules/ourTeamMembersService';
import { updateOrderService } from '../../../core/services/modules/orderService';
import { openNotification } from '../../../components/common/notification/notification';

export function* getOurTeamMembersSaga() {
	const response = yield call(getOurTeamMembersService);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_OUR_TEAM_MEMBERS_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_OUR_TEAM_MEMBERS_SUCCESS,
			payload: data
		});
	}
}

export function* createOurTeamMembersSaga(action) {
	const response = yield call(createOurTeamMembersService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.CREATE_OUR_TEAM_MEMBERS_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.CREATE_OUR_TEAM_MEMBERS_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateOurTeamMembersSaga(action) {
	const response = yield call(updateOurTeamMembersService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_OUR_TEAM_MEMBERS_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.UPDATE_OUR_TEAM_MEMBERS_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateOrderSaga(action) {
	const response = yield call(updateOrderService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_ORDER_OUR_TEAM_MEMBERS_FAILED,
			payload: response.data.error
		});
	} else {
		openNotification('success', 'Update successful');
	}
}

export function* watchOurTeamMembers() {
	yield takeLatest(
		actionTypes.GET_OUR_TEAM_MEMBERS_REQUEST,
		getOurTeamMembersSaga
	);
	yield takeLatest(
		actionTypes.CREATE_OUR_TEAM_MEMBERS_REQUEST,
		createOurTeamMembersSaga
	);
	yield takeLatest(
		actionTypes.UPDATE_OUR_TEAM_MEMBERS_REQUEST,
		updateOurTeamMembersSaga
	);
	yield takeLatest(
		actionTypes.UPDATE_ORDER_OUR_TEAM_MEMBERS_SUCCESS,
		updateOrderSaga
	);
}
