import React from 'react';
import { connect, shallowEqual } from 'react-redux';
import './assetGallery.scss';
import {
  Form,
  Menu,
  Dropdown,
  Checkbox,
  Button,
  Icon,
  notification,
  Modal,
  Input,
  Spin,
  Pagination,
} from 'antd';
import { Row, Col } from 'react-bootstrap';
// import ImgGal from '../../../../../../assets/images/gallery.jpeg';
import { preventClosing, encodeURIThumbnail, isImage } from 'src/core/utils/commonUtils';
import Lightbox from 'react-image-lightbox';
import compressImage from 'src/core/utils/imageCompressor/index';
import confirmModal from 'src/components/common/confirmModal/confirmModal';
import { IMAGES_TYPE } from 'src/core/constants/constants.js';
import {
  renameImageGalleryRequest,
  getGalleryFileRequest,
} from 'src/redux/actions/admin/assetsAction';
import AssetFileProgress from 'src/components/common/form/projectsDetail/assetForm/assetFileProgress/assetFileProgress';
import FileExtentions from 'src/components/common/fileExtentions';

const { confirm } = Modal;

class AssetGallery extends React.Component {
  constructor(props) {
    super(props);
    this.fileUploaded = [];
    this.state = {
      originFileUploads: [],
      uploadType: 'upload_gallery',
      fileChoose: [],
      imgGallery: [],
      fileUploads: [],
      loading: true,
      fileProgress: [],
      selectedImages: [],
      selectedRows: [],
      isModalPhotoOpen: false,
      currentImageSrc: '',
      indexImage: 1,
      isLoading: false,
      modalVisible: false,
      currentImage: null,
      isImageNameRequired: false,
      page: 1,
      total: 0,
      galleryId: null,
    };
  }

  openRenameModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  closeRenameModal = () => {
    this.setState({
      modalVisible: false,
      currentImage: null,
      newImageName: null,
    });
  };

  confirmModalHandler = () => {
    this.props.goBack();
  };

  closeConfirmModalHandler = () => {
    this.closeAllModal();
  };

  closeAllModal = () => {
    Modal.destroyAll();
  };

  openConfirmModal = () => {
    confirmModal({
      onOk: this.confirmModalHandler,
      onCancel: this.closeConfirmModalHandler,
    });
  };

  downloadFiles = () => {
    const { selectedRows } = this.state;
    if (selectedRows && selectedRows.length) {
      selectedRows.forEach(item => {
        window.open(item.path);
      });
    }
  };

  componentDidMount() {
    if (this.props.galleryImage) {
      this.setState(
        {
          imgGallery: [],
          uploadType: 'get_gallery',
          page: 1,
          galleryId: this.props.galleryImage,
        },
        () => this.props._onGetFileGallery({ id: this.props.galleryImage, page: 1 })
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { page } = this.state;
    const gallery_id = localStorage.getItem('ASSET_ID');
    if (
      this.props.assets.hasRemovedAll &&
      this.props.assets.hasRemovedAll !== prevProps.assets.hasRemovedAll
    ) {
      this.setState({
        selectedImages: [],
        uploadType: 'get_gallery',
      });
      this.props._onGetFileGallery({ id: gallery_id, page });
    }
    if (
      this.props.assets.hasRenamedImage &&
      this.props.assets.hasRenamedImage !== prevProps.assets.hasRenamedImage
    ) {
      this.setState({
        uploadType: 'get_gallery',
      });
      this.props._onGetFileGallery({ id: gallery_id, page });
      this.closeRenameModal();
    }
  }

  onUploadFiles = async props => {
    const { assets } = props;
    const { originFileUploads, thumbnails } = this.state;
    const { dataSuccess } = assets;
    const { originalUrls, thumbnailUrls } = dataSuccess || {};
    const fileProgress = [...originFileUploads].map((originalFile, index) => {
      const thumbnailFile = thumbnails[index];
      const originalUrl = originalUrls[index];
      const thumbnailUrl = thumbnailUrls[index];

      return {
        origin: originalFile,
        thumbnail: thumbnailFile,
        originalUrl,
        thumbnailUrl,
        fileName: originalFile.name,
      };
    });

    this.setState({ fileProgress: [...this.state.fileProgress, ...fileProgress] });
  };

  onFinishedUpload = async fileUploaded => {
    this.fileUploaded.push(fileUploaded);

    if (this.fileUploaded.length === this.state.fileProgress.length) {
      const gallery_id = localStorage.getItem('ASSET_ID');
      const { page } = this.state;

      this.setState(
        {
          uploadType: 'get_gallery',
          fileProgress: [],
        },
        () => {
          this.props._onGetFileGallery({ id: gallery_id, page });
          this.props._onResetAsset();
          window.removeEventListener('beforeunload', preventClosing);
          this.props.onAllowBackClick();
          this.closeAllModal();
          this.fileUploaded = [];
          this.setState({ isLoading: false });
        }
      );
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const gallery_id = localStorage.getItem('ASSET_ID');
    const { uploadType, page } = this.state;
    const { assets } = nextProps;

    if (
      assets &&
      assets.dataSuccess &&
      uploadType === 'upload_gallery' &&
      !shallowEqual(this.props.assets, assets)
    ) {
      const { dataSuccess } = assets;
      const { originalUrls, thumbnailUrls } = dataSuccess || {};
      if (originalUrls && originalUrls.length) {
        this.setState({
          isLoading: true,
        });
        window.addEventListener('beforeunload', preventClosing);
        this.props.onPreventBackClick();
      }

      if (originalUrls && thumbnailUrls) {
        this.onUploadFiles(nextProps);
      }
    } else if (assets && assets.dataSuccess.docs && uploadType === 'get_gallery') {
      if (assets.dataSuccess.docs.length === 0 && page > 1) {
        const newPage = page - 1;
        this.setState(
          {
            uploadType: 'get_gallery',
            page: newPage,
          },
          () => this.props._onGetFileGallery({ id: gallery_id, page: newPage })
        );
      } else {
        this.setState({
          imgGallery: assets.dataSuccess.docs,
          total: assets.dataSuccess.total,
        });
      }
    }
  }

  openNotificationWithIcon = (type, message, content) => {
    notification[type]({
      message: message,
      description: content,
    });
  };

  handleUpload = async () => {
    let file = this.uploadInput.files;
    const { currentProject } = this.props;
    const projectId = currentProject && currentProject._id; // localStorage.getItem('PROJECT_ID');
    const gallery = localStorage.getItem('GALLERY_TITLE');
    const arrFile = [];
    const arrOriginFile = [];
    let thumbnails = [];
    if (file && file.length) {
      const promises = [];
      for (let i = 0; i < file.length; i++) {
        const currentFile = file[i];
        promises.push(
          new Promise(async resolve => {
            if (isImage(currentFile)) {
              const fileThumbnail = await compressImage(currentFile);
              thumbnails.push(fileThumbnail);
            }

            arrFile.push({
              fileName: file[i].name,
              fileSize: file[i].size,
              isPublic: false,
              type: file[i].type,
            });
            arrOriginFile.push(file[i]);

            resolve();
          })
        );
      }

      await Promise.all(promises);
      this.setState(
        {
          fileUploads: arrFile,
          originFileUploads: arrOriginFile,
          uploadType: 'upload_gallery',
          thumbnails,
        },
        () => {
          const data = {
            fileUploads: arrFile,
            fileName: arrFile[0].name,
            fileSize: arrFile[0].size,
            gallery,
            projectId: projectId,
            isPublic: false,
            isGallery: true,
            type: arrFile[0].type,
          };
          this.props._onSignUrl(data);
        }
      );
    }
  };

  _handleChooseFile(file, val) {
    const { selectedImages, selectedRows } = this.state;
    if (val.target.checked) {
      this.setState({
        selectedImages: [...selectedImages, file._id],
        selectedRows: [...selectedRows, file],
      });
    } else {
      this.setState({
        selectedImages: selectedImages.filter(s => s !== file._id),
        selectedRows: selectedRows.filter(s => s._id !== file._id),
      });
    }

    // if (arrFiles.length) {
    //   if (!val.target.checked) {
    //     arrFiles.forEach((item, index) => {
    //       if (item === file.path) {
    //         arrFiles.splice(index, 1);
    //       }
    //     });
    //   } else {
    //     const checkExist = arrFiles.includes(file.path);
    //     if (!checkExist) {
    //       arrFiles.push(file.path);
    //     }
    //   }
    // } else {
    //   arrFiles.push(file.path);
    // }
  }

  removeFromGallery = () => {
    const { selectedImages } = this.state;
    if (selectedImages && selectedImages.length) {
      confirm({
        title: `Are you sure that you want to delete the selected item(s)?`,
        content: '',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          this.setState(
            {
              uploadType: 'delete',
            },
            () => this.props.onRemoveFromGallery({ data: selectedImages })
          );
        },
      });
    }
  };

  previewImage = (src, indexImage) => {
    this.setState({
      isModalPhotoOpen: true,
      currentImageSrc: src,
      indexImage,
    });
  };

  selectRenameFile = file => {
    if (file && file.name) {
      this.setState({ currentImage: file });
    }
  };

  renameImage = () => {
    const { currentImage, newImageName, isImageNameRequired } = this.state;
    if (!newImageName) return this.closeRenameModal();

    const { _id: assetId, name: currentImageName } = currentImage || {};
    const fileExt = currentImageName.substring(currentImageName.lastIndexOf('.') + 1);
    const fileName = `${newImageName}.${fileExt}`;
    if (fileName === currentImageName) {
      return this.closeRenameModal();
    }

    if (!isImageNameRequired) {
      this.setState({ currentImage: { ...currentImage, fullImageName: fileName } });
      this.props.onRename({ assetId, fileName });
    }
  };

  getFileNameRename = () => {
    const { currentImage } = this.state;
    const { name: fileName } = currentImage || {};
    if (fileName) {
      return fileName.substring(0, fileName.lastIndexOf('.'));
    }
    return '';
  };

  renderRenameModal = () => {
    const { modalVisible, isImageNameRequired } = this.state;
    const defaultImageName = this.getFileNameRename();
    return (
      <Modal
        visible={modalVisible}
        onCancel={this.closeRenameModal}
        className="rename-modal"
        title="Rename Image"
        onOk={this.renameImage}
        okText="Update"
        centered
        destroyOnClose={true}
      >
        <Input
          className="rename-input"
          onChange={e => {
            if (e.target.value && e.target.value.trim()) {
              this.setState({
                newImageName: e.target.value,
                isImageNameRequired: false,
              });
            } else {
              this.setState({
                isImageNameRequired: true,
              });
            }
          }}
          defaultValue={defaultImageName}
        />
        {isImageNameRequired && (
          <div className="ant-form-explain">
            Please enter title that you want to update
          </div>
        )}
      </Modal>
    );
  };
  _onChangePage = p => {
    this.setState({ page: p, uploadType: 'get_gallery' }, () => {
      this.props._onGetFileGallery({ id: this.props.galleryImage, page: p });
    });
  };

  render() {
    const { assets } = this.props;
    const { isRemoving } = assets || {};
    const { page, total, selectedImages } = this.state;
    const {
      imgGallery,
      fileProgress,
      isModalPhotoOpen,
      indexImage,
      isLoading,
    } = this.state;
    const images = imgGallery.map(f => f.path);
    const menu = (
      <Menu>
        <Menu.Item key="1" onClick={this.downloadFiles}>
          Download
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={this.removeFromGallery} key="2">
          Delete
        </Menu.Item>
      </Menu>
    );
    const menuRename = (
      <Menu>
        <Menu.Item key="1" onClick={this.openRenameModal}>
          Rename
        </Menu.Item>
      </Menu>
    );
    return (
      <React.Fragment>
        <div className="mgb-10">
          <Icon type="left" />{' '}
          <span
            className="add-cursor"
            onClick={() => {
              if (isRemoving || isLoading) {
                this.openConfirmModal();
              } else {
                this.props.goBack();
              }
            }}
          >
            Gallery
          </span>
        </div>
        <div className="flex-gallery-btn-upload">
          <div className="upload-btn-wrapper">
            <Button
              type="primary"
              icon="link"
              className="btn-primary-0"
              loading={isRemoving || isLoading}
            >
              Upload
            </Button>
            <input
              className="input-upload-invisible"
              onChange={this.handleUpload}
              ref={ref => {
                this.uploadInput = ref;
              }}
              type="file"
              accept={IMAGES_TYPE.join(',')}
              multiple
            />
          </div>
          <div>
            <Dropdown overlay={menu} trigger={['click']}>
              <Icon type="ellipsis" key="ellipsis" className="add-cursor" />
            </Dropdown>
          </div>
        </div>
        <div className="upload-progress-wrapper">
          {fileProgress.length > 0 &&
            fileProgress.map((item, index) => (
              <AssetFileProgress
                file={item}
                onUploaded={this.onFinishedUpload}
                key={`FILE_UPLOAD_GALLERY${index}`}
              />
            ))}
        </div>
        <div className="mgt-20">
          <Spin spinning={assets.isLoading}>
            <Row>
              {imgGallery && imgGallery.length > 0 ? (
                imgGallery.map((item, index) => {
                  const thumbnailUrl = encodeURIThumbnail(item.pathThumbnail, item.path);
                  return (
                    <Col md={4} className="mgb-10" key={index}>
                      <div className="gallery-item-wrapper">
                        <FileExtentions
                          fileName={thumbnailUrl}
                          path={item.path}
                          onclick={() => this.previewImage(item.path, index)}
                          className="gallery-item-background"
                        />
                        <div className="gallery-item-checkbox">
                          <Checkbox
                            checked={selectedImages.includes(item._id)}
                            onChange={val => this._handleChooseFile(item, val)}
                          />
                        </div>
                        <div className="rename-menu-container">
                          <Dropdown overlay={menuRename} trigger={['click']}>
                            <Icon
                              type="ellipsis"
                              key="ellipsis"
                              className="add-cursor"
                              onClick={() => this.selectRenameFile(item)}
                            />
                          </Dropdown>
                        </div>
                      </div>
                      <div className="text-center">{item.name}</div>
                    </Col>
                  );
                })
              ) : (
                <Col md={12} className="mgb-10">
                  <div>There is no image for this gallery.</div>
                </Col>
              )}
            </Row>
          </Spin>
          <Pagination
            total={total}
            current={page}
            onChange={this._onChangePage}
            className="text-right mt-2"
            pageSize={12}
          />
        </div>
        {isModalPhotoOpen && (
          <Lightbox
            mainSrc={images[indexImage]}
            nextSrc={images[(indexImage + 1) % images.length]}
            prevSrc={images[(indexImage + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isModalPhotoOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                indexImage: (indexImage + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                indexImage: (indexImage + 1) % images.length,
              })
            }
          />
        )}
        {this.renderRenameModal()}
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', preventClosing);
  }
}

const mapStateToProps = state => {
  return {
    assets: state.assets,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRename: data => {
      dispatch(renameImageGalleryRequest(data));
    },
    onGetFileGallery: params => {
      dispatch(getGalleryFileRequest(params));
    },
  };
};

const WrappedAssetGallery = Form.create({ name: 'normal_projects' })(
  connect(mapStateToProps, mapDispatchToProps)(AssetGallery)
);

export default WrappedAssetGallery;
