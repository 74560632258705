import {
  GET_CUSTOMER_STORIES_REQUEST,
  DELETE_CUSTOMER_STORIES_REQUEST,
  CREATE_CUSTOMER_STORIES_REQUEST,
  UPDATE_CUSTOMER_STORIES_REQUEST,
  UPDATE_ORDER_CUSTOMER_STORIES_REQUEST,
  GET_STORIES_HIGHT_LIGHT_REQUEST,
} from '../typeAction';

export const getStoriesHightLight = () => ({
  type: GET_STORIES_HIGHT_LIGHT_REQUEST,
});

export const getCustomerStories = () => ({
  type: GET_CUSTOMER_STORIES_REQUEST,
});

export const deleteCustomerStories = key => ({
  type: DELETE_CUSTOMER_STORIES_REQUEST,
  payload: key,
});

export const createCustomerStories = data => {
  return {
    type: CREATE_CUSTOMER_STORIES_REQUEST,
    payload: data,
  };
};

export const updateCustomerStories = ({ data, id }) => ({
  type: UPDATE_CUSTOMER_STORIES_REQUEST,
  payload: { data, id },
});

export const updateOrder = data => ({
  type: UPDATE_ORDER_CUSTOMER_STORIES_REQUEST,
  payload: data,
});
