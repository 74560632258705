import {
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILED,
  COUNT_NOTIFICATION_REQUEST,
  COUNT_NOTIFICATION_SUCCESS,
  COUNT_NOTIFICATION_FAILED,
  READ_NOTIFICATION_REQUEST,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_FAILED,
} from '../../actions/typeAction';
import {
  READ_ALL_NOTIFICATION_SUCCESS,
  RESET_NOTIFICATION,
} from 'src/redux/actions/typeAction';

const defaultState = {
  dataSuccess: {},
  dataError: {},
  page: 1,
  isLoading: false,
  isError: false,
  isSuccess: false,
};

const notificationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_REQUEST:
    case COUNT_NOTIFICATION_REQUEST:
    case READ_NOTIFICATION_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
      };
    }
    case READ_ALL_NOTIFICATION_SUCCESS: {
      const { docs: notifications } = state.dataSuccess;

      return {
        ...state,
        dataSuccess: {
          ...state.dataSuccess,
          docs: notifications.map(notification => ({ ...notification, unRead: 0 })),
          total: 0,
        },
      };
    }
    case READ_NOTIFICATION_SUCCESS: {
      try {
        let { docs: notifications, total } = state.dataSuccess;
        const newNotificationData = notifications.map(item => ({ ...item }));
        const notificationIndex = newNotificationData.findIndex(
          notification => notification._id === action.payload.notificationId
        );

        if (notificationIndex !== -1) {
          newNotificationData[notificationIndex].unRead = 0;
          total = total > 0 ? total - 1 : 0;
        }
        return {
          ...state,
          dataSuccess: {
            ...state.dataSuccess,
            docs: newNotificationData,
            total,
          },
        };
      } catch (e) {
        console.error(e.stack);
      }
      return {
        ...state,
      };
    }
    case GET_NOTIFICATION_SUCCESS: {
      const { docs } = action.payload;
      const { docs: notifications = [] } = state.dataSuccess;
      const page = state.page;

      return {
        ...state,
        page: !!docs.length ? page + 1 : page,
        dataSuccess: {
          ...state.dataSuccess,
          docs: [...notifications, ...docs],
        },
        dataError: {},
        isLoading: false,
        isError: false,
        isSuccess: true,
      };
    }
    case COUNT_NOTIFICATION_SUCCESS: {
      const total = action.payload;
      return {
        ...state,
        dataSuccess: {
          ...state.dataSuccess,
          total,
        },
        dataError: {},
        isLoading: false,
        isError: false,
        isSuccess: true,
      };
    }
    case GET_NOTIFICATION_FAILED:
    case COUNT_NOTIFICATION_FAILED:
    case READ_NOTIFICATION_FAILED: {
      return {
        ...state,
        dataSuccess: {},
        dataError: action.payload,
        isLoading: false,
        isError: true,
        isSuccess: false,
      };
    }
    case RESET_NOTIFICATION: {
      return defaultState;
    }
    default:
      return state;
  }
};

export default notificationReducer;
