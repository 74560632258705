import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../../actions/typeAction';
import {
	getPackageService,
	createPackageService,
	updatePackageService,
	getPackageByParams,
	getPackageByIdService
} from '../../../core/services/modules/packageService';
import { updateOrderService } from '../../../core/services/modules/orderService';
import { openNotification } from '../../../components/common/notification/notification';

export function* getPackageSaga(action) {
	let type = action.payload;
	const response = yield call(getPackageService, type);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_PACKAGE_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_PACKAGE_SUCCESS,
			payload: data
		});
	}
}

export function* getPackageByParamsSaga(action) {
	const response = yield call(getPackageByParams, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_PACKAGE_BY_PARAMS_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_PACKAGE_BY_PARAMS_SUCCESS,
			payload: data
		});
	}
}

export function* getPackageByIdTask(action) {
	const response = yield call(getPackageByIdService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_PACKAGE_BY_ID_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_PACKAGE_BY_ID_SUCCESS,
			payload: data
		});
	}
}

export function* createPackageSaga(action) {
	const response = yield call(createPackageService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.CREATE_PACKAGE_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.CREATE_PACKAGE_SUCCESS,
			payload: response.data
		});
	}
}

export function* updatePackageSaga(action) {
	const response = yield call(updatePackageService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_PACKAGE_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.UPDATE_PACKAGE_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateOrderSaga(action) {
	const response = yield call(updateOrderService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_ORDER_PACKAGE_FAILED,
			payload: response.data.error
		});
	} else {
		openNotification('success', 'Update successful');
	}
}

export function* watchPackage() {
	yield takeLatest(actionTypes.GET_PACKAGE_REQUEST, getPackageSaga);
	yield takeLatest(actionTypes.GET_PACKAGE_BY_PARAMS_REQUEST, getPackageByParamsSaga);
	yield takeLatest(actionTypes.CREATE_PACKAGE_REQUEST, createPackageSaga);
	yield takeLatest(actionTypes.UPDATE_PACKAGE_REQUEST, updatePackageSaga);
	yield takeLatest(actionTypes.UPDATE_ORDER_PACKAGE_SUCCESS, updateOrderSaga);
	yield takeLatest(actionTypes.GET_PACKAGE_BY_ID_REQUEST, getPackageByIdTask);
}
