import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../../actions/typeAction';
import {
	getCategoriesService,
	createCategoriesService,
	updateCategoriesService,
	getCategoriesByIdService
} from '../../../core/services/modules/categoriesService';
import { updateOrderService } from '../../../core/services/modules/orderService';
import { openNotification } from '../../../components/common/notification/notification';

export function* getCategoriesSaga() {
	const response = yield call(getCategoriesService);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_CATEGORIES_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_CATEGORIES_SUCCESS,
			payload: data
		});
	}
}

export function* getCategoriesByIdSaga(action) {
	const response = yield call(getCategoriesByIdService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_CATEGORIES_BY_ID_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_CATEGORIES_BY_ID_SUCCESS,
			payload: data
		});
	}
}

export function* createCategoriesSaga(action) {
	const response = yield call(createCategoriesService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.CREATE_CATEGORIES_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.CREATE_CATEGORIES_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateCategoriesSaga(action) {
	const response = yield call(updateCategoriesService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_CATEGORIES_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.UPDATE_CATEGORIES_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateOrderSaga(action) {
	const response = yield call(updateOrderService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_ORDER_CATEGORIES_FAILED,
			payload: response.data.error
		});
	} else {
		openNotification('success', 'Update successful');
	}
}

export function* watchCategories() {
	yield takeLatest(actionTypes.GET_CATEGORIES_REQUEST, getCategoriesSaga);
	yield takeLatest(
		actionTypes.CREATE_CATEGORIES_REQUEST,
		createCategoriesSaga
	);
	yield takeLatest(
		actionTypes.UPDATE_CATEGORIES_REQUEST,
		updateCategoriesSaga
	);
	yield takeLatest(
		actionTypes.UPDATE_ORDER_CATEGORIES_SUCCESS,
		updateOrderSaga
	);
	yield takeLatest(
		actionTypes.GET_CATEGORIES_BY_ID_REQUEST,
		getCategoriesByIdSaga
	);
}
