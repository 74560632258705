import {
	GET_PACKAGE_REQUEST,
	CREATE_PACKAGE_REQUEST,
	UPDATE_PACKAGE_REQUEST,
	UPDATE_ORDER_PACKAGE_SUCCESS,
	GET_PACKAGE_BY_PARAMS_REQUEST,
	GET_PACKAGE_BY_ID_REQUEST
} from '../typeAction';

export const getPackage = type => ({
	type: GET_PACKAGE_REQUEST,
	payload: type
});

export const getPackageByParams = params => ({
	type: GET_PACKAGE_BY_PARAMS_REQUEST,
	payload: params
});

export const getPackageById = id => ({
	type: GET_PACKAGE_BY_ID_REQUEST,
	payload: id
});

export const createPackage = data => ({
	type: CREATE_PACKAGE_REQUEST,
	payload: data
});

export const updatePackage = ({ data, id }) => ({
	type: UPDATE_PACKAGE_REQUEST,
	payload: { data, id }
});

export const updateOrder = data => ({
	type: UPDATE_ORDER_PACKAGE_SUCCESS,
	payload: data
});
