import { connect } from 'react-redux';
import AddhocScreen from '../../../components/screens/admin/addhoc/addhocScreen';
import { getAddhoc, createAddhoc, updateAddhoc } from '../../../redux/actions/admin/addhocAction';
import { getUnits } from '../../../redux/actions/admin/unitsAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';

const mapStateToProps = state => {
	return {
		success: state.addhoc.success,
		error: state.addhoc.error,
		isLoading: state.addhoc.isLoading,
		data: state.addhoc.data,
		units: state.units.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetAddhoc: () => {
			dispatch(getAddhoc());
		},
		onCreateAddhoc: data => {
			dispatch(createAddhoc(data));
		},
		onUpdateAddhoc: ({ data, id }) => {
			dispatch(updateAddhoc({ data, id }));
		},
		onGetUnits: () => {
			dispatch(getUnits());
		},
		onGetCurrentUser: () => {
			dispatch(getCurrentUser());
		}
	};
};

const CustomerStoriesContainer = connect(mapStateToProps, mapDispatchToProps)(AddhocScreen);

export default CustomerStoriesContainer;
