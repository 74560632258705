import React from 'react';
import 'antd/dist/antd.css';
import './addhocForm.scss';
import { Form, Input, Button, Select } from 'antd';
import { validatePositive } from '../../../../core/utils/customValidator';

const { Option } = Select;
const { TextArea } = Input;

class AddhocForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const price = Number(values.price);
				const name = values.name.trim();
				const status = values.status.trim() === 'Active' ? true : false;
				const description = values.description.trim();
				const measureUnitId = values.unit;
				const data = {
					name,
					status,
					price,
					description,
					measureUnitId
				};
				this.props.isCreate
					? this.props.onCreateAddhoc(data)
					: this.props.onUpdateAddhoc(data);
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { isCreate, itemUpdate, isLoading, units } = this.props;
		const unitRender = units.map(item => (
			<Option key={item._id} value={item._id}>
				{item.name}
			</Option>
		));
		if (isCreate) {
			return (
				<Form className="addhoc-form">
					<Form.Item label="Name">
						{getFieldDecorator('name', {
							rules: [
								{
									required: true,
									message: 'Please input addhoc name'
								},
								{
									whitespace: true,
									message:
										'Please do not input whitespace only'
								}
							],
							initialValue: null
						})(<Input placeholder="Name" size="large" />)}
					</Form.Item>
					<Form.Item label="Price">
						{getFieldDecorator('price', {
							rules: [
								{
									required: true,
									message: 'Please input price number'
								},
								{
									validator: (rule, value, callback) => {
										const msg = validatePositive(
											value,
											'Price'
										);
										if (msg) {
											callback(msg);
										} else {
											callback();
										}
									}
								}
							],
							initialValue: null
						})(
							<Input
								type="number"
								placeholder="Price"
								size="large"
								step={0.01}
							/>
						)}
					</Form.Item>
					<Form.Item label="Description">
						{getFieldDecorator('description', {
							rules: [
								{
									required: true,
									message: 'Please input addhoc description'
								},
								{
									whitespace: true,
									message:
										'Please do not input whitespace only'
								}
							],
							initialValue: null
						})(<TextArea rows={4} />)}
					</Form.Item>
					<Form.Item label="Unit" className="unit-item">
						{getFieldDecorator('unit', {
							rules: [
								{
									required: true,
									message: 'Please select addhoc unit'
								}
							],
							initialValue: units[0]._id
						})(<Select>{unitRender}</Select>)}
					</Form.Item>
					<Form.Item label="Status" className="status-item">
						{getFieldDecorator('status', {
							rules: [
								{
									required: true,
									message: 'Please select addhoc status'
								}
							],
							initialValue: 'Active'
						})(
							<Select>
								<Option value="Active">Active</Option>
								<Option value="Inactive">Inactive</Option>
							</Select>
						)}
					</Form.Item>
					<Form.Item>
						<Button
							type="primary"
							loading={isLoading}
							onClick={this.handleSubmit}
							className="addhoc-form-button btn-primary-0"
							size="large"
							disabled={isLoading}
						>
							Create
						</Button>
					</Form.Item>
				</Form>
			);
		} else {
			return (
				<Form className="addhoc-form">
					<Form.Item label="Name">
						{getFieldDecorator('name', {
							rules: [
								{
									required: true,
									message: 'Please input addhoc name'
								},
								{
									whitespace: true,
									message:
										'Please do not input whitespace only'
								}
							],
							initialValue: itemUpdate.name
						})(<Input placeholder="Name" size="large" />)}
					</Form.Item>
					<Form.Item label="Price">
						{getFieldDecorator('price', {
							rules: [
								{
									required: true,
									message: 'Please input price number'
								},
								{
									validator: (rule, value, callback) => {
										const msg = validatePositive(
											value,
											'Price'
										);
										if (msg) {
											callback(msg);
										} else {
											callback();
										}
									}
								}
							],
							initialValue: itemUpdate.price.toString()
						})(
							<Input
								type="number"
								placeholder="Price"
								size="large"
								step={0.01}
							/>
						)}
					</Form.Item>
					<Form.Item label="Description">
						{getFieldDecorator('description', {
							rules: [
								{
									required: true,
									message: 'Please input price description'
								},
								{
									whitespace: true,
									message:
										'Please do not input whitespace only'
								}
							],
							initialValue: itemUpdate.description
						})(<TextArea rows={4} />)}
					</Form.Item>
					<Form.Item label="Unit" className="unit-item">
						{getFieldDecorator('unit', {
							rules: [
								{
									required: true,
									message: 'Please select addhoc unit'
								}
							],
							initialValue: itemUpdate.measureUnitId._id
						})(<Select>{unitRender}</Select>)}
					</Form.Item>
					<Form.Item label="Status" className="status-item">
						{getFieldDecorator('status', {
							rules: [
								{
									required: true,
									message: 'Please select addhoc status'
								}
							],
							initialValue: itemUpdate.status
								? 'Active'
								: 'Inactive'
						})(
							<Select>
								<Option value="Active">Active</Option>
								<Option value="Inactive">Inactive</Option>
							</Select>
						)}
					</Form.Item>
					<Form.Item>
						<Button
							type="primary"
							loading={isLoading}
							onClick={this.handleSubmit}
							className="addhoc-form-button btn-primary-0"
							size="large"
							disabled={isLoading}
						>
							Update
						</Button>
					</Form.Item>
				</Form>
			);
		}
	}
}

const WrappedAddhocForm = Form.create({ name: 'normal_addhoc' })(AddhocForm);

export default WrappedAddhocForm;
