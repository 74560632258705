import React from 'react';
import { Icon } from 'antd';
import './uploadList.scss';

const UploadList = props => {
	const { url, title } = props;
	return (
		<div className="upload-file-item-custom">
			<img className="upload-file-image" src={url} alt={title}/>
			<span className="upload-file-title">{title}</span>
			<Icon className="upload-file-icon" type="delete" onClick={props.onDelete}/>
		</div>
	)	
}

export default UploadList;
