import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const loginService = authData => {
  const url = BaseConfig.END_POINT.AUTH.LOGIN;
  const response = httpService._post(url, authData);
  return response;
};

export const logoutService = () => {
  const url = BaseConfig.END_POINT.AUTH.LOGOUT;
  const response = httpService._post(url);
  return response;
};

export const refreshToken = () => {
  const url = BaseConfig.END_POINT.AUTH.REFRESH_TOKEN;
  const response = httpService._get(url);
  return response;
};
