import { put, call, takeLatest } from 'redux-saga/effects';

import {
  GET_ADMINS_FAILED,
  GET_ADMINS_SUCCESS,
  GET_ADMINS_REQUEST,
  UPDATE_ADMINS_SUCCESS,
  UPDATE_ADMINS_FAILED,
  UPDATE_ADMINS_REQUEST,
  REGISTER_ADMIN_FAILED,
  REGISTER_ADMIN_SUCCESS,
  REGISTER_ADMIN_REQUEST,
  GET_ADMINS_DETAIL_FAILED,
  GET_ADMINS_DETAIL_SUCCESS,
  LOAD_MORE_ADMINS_FAILED,
  LOAD_MORE_ADMINS_SUCCESS,
  LOAD_MORE_ADMINS_REQUEST,
  GET_ADMINS_DETAIL_REQUEST,
} from '../../actions/typeAction';
import {
  getAdminsService,
  updateAdminService,
  registerAdminService,
  getAdminDetailService,
} from '../../../core/services/modules/adminsService';

export function* getAdminsSaga(action) {
  const response = yield call(getAdminsService, action.payload);
  if (response.data.error) {
    yield put({
      type: GET_ADMINS_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: GET_ADMINS_SUCCESS,
      payload: data,
    });
  }
}

export function* loadMoreAdminsSaga(action) {
  const response = yield call(getAdminsService, action.payload);
  if (response.data.error) {
    yield put({
      type: LOAD_MORE_ADMINS_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: LOAD_MORE_ADMINS_SUCCESS,
      payload: data,
    });
  }
}

export function* getAdminDetailSaga(action) {
  const response = yield call(getAdminDetailService, action.payload);
  if (response.data.error) {
    yield put({
      type: GET_ADMINS_DETAIL_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: GET_ADMINS_DETAIL_SUCCESS,
      payload: data,
    });
  }
}

export function* updateAdminSaga(action) {
  const response = yield call(updateAdminService, action.payload);
  if (response.data.error) {
    yield put({
      type: UPDATE_ADMINS_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: UPDATE_ADMINS_SUCCESS,
      payload: data,
    });
  }
}

export function* registerAdminSaga(action) {
  const response = yield call(registerAdminService, action.payload);
  if (response.data.error) {
    yield put({
      type: REGISTER_ADMIN_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: REGISTER_ADMIN_SUCCESS,
      payload: data,
    });
    yield put({
      type: GET_ADMINS_REQUEST,
      payload: { page: 1, limit: '', status: '' },
    });
  }
}

export function* watchAdmins() {
  yield takeLatest(GET_ADMINS_REQUEST, getAdminsSaga);
  yield takeLatest(UPDATE_ADMINS_REQUEST, updateAdminSaga);
  yield takeLatest(REGISTER_ADMIN_REQUEST, registerAdminSaga);
  yield takeLatest(LOAD_MORE_ADMINS_REQUEST, loadMoreAdminsSaga);
  yield takeLatest(GET_ADMINS_DETAIL_REQUEST, getAdminDetailSaga);
}
