import {
  GET_TEAMS_REQUEST,
  LOAD_MORE_TEAMS_REQUEST,
  GET_INVITATION_REQUEST,
  GET_TEAM_DETAIL_REQUEST,
} from '../typeAction';

export const getTeams = params => ({
  type: GET_TEAMS_REQUEST,
  payload: params,
});

export const getTeamDetail = id => ({
  type: GET_TEAM_DETAIL_REQUEST,
  payload: id,
});

export const loadMoreTeams = params => ({
  type: LOAD_MORE_TEAMS_REQUEST,
  payload: params,
});

export const getInvitations = data => ({
  type: GET_INVITATION_REQUEST,
  payload: data,
});
