import {
	GET_UNITS_REQUEST,
	CREATE_UNITS_REQUEST,
	UPDATE_UNITS_REQUEST,
	UPDATE_ORDER_UNITS_SUCCESS
} from '../typeAction';

export const getUnits = () => ({
	type: GET_UNITS_REQUEST
});

export const createUnits = data => ({
	type: CREATE_UNITS_REQUEST,
	payload: data
});

export const updateUnits = ({ data, id }) => ({
	type: UPDATE_UNITS_REQUEST,
	payload: { data, id }
});

export const updateOrder = data => ({
	type: UPDATE_ORDER_UNITS_SUCCESS,
	payload: data
});
