/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, memo, useEffect, useMemo, useRef, useState } from 'react';
import { Icon, Tooltip } from 'antd';
import {
  dataURLtoBlob,
  encodeURIThumbnail,
  getS3CDN,
  isDeactiveUser,
  isVideoFile,
  preventClosing,
  isFileHasThumbnail,
  getIdLinkDrive,
  getThumbnailLinkDrive
} from 'src/core/utils/commonUtils';
import moment from 'moment';
import {
  getPresignedMessageFileUrl,
  uploadMessage,
} from 'src/core/services/modules/messageService';
import compressImage from 'src/core/utils/imageCompressor';
import axios from 'axios';
import UserAvatar from 'src/components/common/userAvatar';
import VideoThumbnailExtractor from 'src/components/common/videoThumbnailExtractor';
import FileExtentions from 'src/components/common/fileExtentions';
import FileDetail from 'src/components/common/fileDetail';
import MessageLinkifier from 'src/components/common/form/workspace/topicsScreen/messageItem/messageLinkify';
import IconImageThumbnail from 'src/assets/images/google-login/icons8-google-drive-thumbnail.svg';
import './styles.scss'

const MessageItem = (props, ref) => {
  const {
    message,
    index,
    onUpdateMessage = () => null,
    // onPressImage = () => null,
  } = props;
  const [percent, setPercent] = useState(0);
  const videoThumbnailExtractorRef = useRef();
  const [mediaDetail, setMedia] = useState(null);

  // const typeFiles = useMemo(() => {
  //   return ['xlsx', 'xls', 'doc', 'docx', 'ppt', 'pptx', 'txt', 'pdf'];
  // }, []);

  const getAuthor = useMemo(() => {
    try {
      return message.senderId.fullName;
    } catch (e) {}
    return '';
  }, [message]);

  const getAvatar = useMemo(() => {
    try {
      if (message.senderId.avt) {
        return getS3CDN(message.senderId.avt);
      }
    } catch (e) {}
  }, [message]);

  const getMessageTime = useMemo(() => {
    try {
      return moment(message.createdAt).subtract(1, 'days').format('YYYY-MM-DD HH:mm');
    } catch (e) {}
  }, [message]);

  const getMessageTimeStamp = useMemo(() => {
    try {
      return moment(message.createdAt).fromNow();
    } catch (e) {}
  }, [message]);

  const getMessageFileName = useMemo(() => {
    try {
      return message.files[0].name;
    } catch (e) {}

    return '';
  }, [message]);

  const checkIsActiveUser = useMemo(() => {
    try {
      return isDeactiveUser(message.senderId);
    } catch (e) {}

    return false;
  }, [message]);

  // const isFile = fileName => {
  //   const fileNameSplit = fileName.split('.');
  //   const fileType = fileNameSplit[fileNameSplit.length - 1];
  //   return typeFiles.includes(fileType);
  // };

  const onUploadImage = async () => {
    const { files, uploadType, isVideo } = message;
    const [currentFile] = files;

    try {
      window.addEventListener('beforeunload', preventClosing);
      props.onPreventBackClick();
      const { data: responseData } = await getPresignedMessageFileUrl({
        data: {
          fileName: currentFile.name,
          isPublic: currentFile.isSendQuote || false,
          uploadType,
        },
        topicId: message.topicId._id,
      });
      const { path, originalFile, thumbnailFile } = responseData;
      let promises = [];
      promises.push(
        axios.put(originalFile, currentFile, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => {
            let uploadPercentage = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );

            setPercent(uploadPercentage);
          },
        })
      );

      try {
        if (thumbnailFile) {
          let thumbnail = currentFile;

          if (isVideo) {
            let videoThumb = await videoThumbnailExtractorRef.current.onExtractThumbnail(
              currentFile
            );
            if (videoThumb) thumbnail = dataURLtoBlob(videoThumb);
          }

          thumbnail = await compressImage(thumbnail);

          promises.push(
            axios.put(thumbnailFile, thumbnail, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
          );
        }
      } catch (e) {}
      await Promise.all(promises);

      const { data: uploadResponse } = await uploadMessage({
        fileName: currentFile.name,
        isPublic: currentFile.isSendQuote,
        fileSize: currentFile.size,
        isSendQuote: currentFile.isSendQuote || false,
        filePath: path,
        topicId: message.topicId._id,
      });

      if (uploadResponse) {
        window.removeEventListener('beforeunload', preventClosing);
        props.onAllowBackClick();
      }

      const { data } = uploadResponse;
      const { message: responseMessage } = data;
      onUpdateMessage({ message: responseMessage, index, localMessage: message });
    } catch (e) {
      console.error('error - ', e.message, e.stack);
    }
  };

  useEffect(() => {
    if (message && message.type === 'upload') {
      onUploadImage();
    }
  }, [message]);

  const renderMessage = () => {
    return <MessageLinkifier content={message.content} />;
  };

  const renderUploadMessage = () => {
    return (
      <div className="content-img">
        <div className="content-img-item-wrapper-temp">
          <div className="content-img-item-temp">
            <div className="file-name-temp">{getMessageFileName}</div>
            <Icon type="loading" style={{ fontSize: '16px' }} />
          </div>
          <div className="overlay-temp" style={{ width: `${percent}%` }} />
        </div>
      </div>
    );
  };

  const renderImage = () => {
    const { files } = message;
    const { path, url, fileName, name } = (files && files[0]) || {};
    const thumbnail = encodeURIThumbnail(path);
    const checkIsVideo = isVideoFile({ filename: path });
    const checkHasThumnail = isFileHasThumbnail(path);
    return (
      <div>
        <FileExtentions
          fileName={thumbnail}
          path={path}
          onclick={() => {
            if (checkHasThumnail) {
              setMedia({ path: url, pathThumbnail: url });
            } else {
              window.open(url);
            }
          }}
          className={checkIsVideo ? 'comment-content-video' : 'comment-content-image'}
          name={fileName || name}
        />
      </div>
    );
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = IconImageThumbnail
  }


  const renderDriveMessage = () => {
    const idUrl = getIdLinkDrive(message.content);
    const thumbnail = getThumbnailLinkDrive(idUrl);
    return (
      <>
        <div style={{ fontSize: 12, color: '#6c757d' }}>
          {(message.files[0] && message.files[0].name) || ''}
        </div>
        <div className="drive-link">
          <img src={thumbnail}
            onError={addDefaultSrc}
            alt=''
          />
          <img src={IconImageThumbnail} className="position-absolute watermark" alt=""/>
          <a className="d-block" href={message.content} target="_blank" rel="noopener noreferrer">{message.content}</a>
        </div>
      </>

    )
  }

  // const renderFile = () => {
  //   const { files } = message;
  //   const { url, fileName, name } = (files && files[0]) || {};

  //   return <a href={url}>{name || fileName}</a>;
  // };

  const getContent = () => {
    try {
      switch (message.type) {
        case 'message':
          return renderMessage();
        case 'file': {
          return renderImage();
        }
        case 'upload':
          return renderUploadMessage();
        case 'drive':
          return renderDriveMessage();
        default:
          return renderImage();
      }
    } catch (e) {
      console.error(e.message, e.stack);
    }
  };

  return (
    <>
      <VideoThumbnailExtractor ref={videoThumbnailExtractorRef} />
      <div className="ant-comment">
        <div className="ant-comment-inner">
          <div className="ant-comment-avatar">
            <UserAvatar
              fullName={getAuthor}
              avatar={getAvatar}
              status={!checkIsActiveUser}
              showTooltip={false}
              size="small"
            />
          </div>

          <div className="ant-comment-content">
            <div className="ant-comment-content-author">
              <div className="ant-comment-content-author-name">{getAuthor}</div>

              <div className="ant-comment-content-author-time ml-2">
                <Tooltip title={getMessageTime}>
                  <span>{getMessageTimeStamp}</span>
                </Tooltip>
              </div>
            </div>

            <div className="ant-comment-content-detail">{getContent()}</div>
            <FileDetail
              autoPlay={true}
              detail={mediaDetail}
              onCancel={() => setMedia(null)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(forwardRef(MessageItem));
