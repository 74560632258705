import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'antd';
import DataTable from 'src/components/common/dataTable/dataTable';
import { Link } from 'react-router-dom';

class TeamMembersModal extends PureComponent {
  render() {
    const { visibleModal, customers, customerIds } = this.props;

    const columns = [
      {
        title: 'Full Name',
        render: (_, record) => {
          return (
            <Link
              className="project-id"
              target="_blank"
              to={{ pathname: '/customers/' + customerIds[record._id] }}
            >
              {record.fullName}
            </Link>
          );
        },
        width: 240,
      },
      {
        title: 'Email',
        dataIndex: 'email',
      },
    ];

    return (
      <Modal
        className="modal-create-team"
        visible={visibleModal}
        footer={null}
        title={'MEMBERS OF THE TEAM'}
        width={600}
        onCancel={() => this.props.onCloseModal()}
        destroyOnClose={true}
        closable={true}
        maskClosable={false}
      >
        <DataTable
          columns={columns}
          dataSource={customers}
          onChangeHandler={this.handleChangeTable}
          hiddenUpdateOrder={true}
        />
      </Modal>
    );
  }
}

const WrapCreateTeam = Form.create({ name: 'normal_get_started' })(TeamMembersModal);

export default withTranslation('common')(WrapCreateTeam);
