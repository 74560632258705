// @flow
import React, { memo, useEffect, useState } from 'react';
import Popover from 'react-popover';
import { Icon } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import NotificationItem from 'src/components/common/notificationDropdown/item';
import InfiniteScroll from 'src/components/common/infiniteScroll';
import { Translation } from 'react-i18next';
import './notificationDropdown.css';
import {
  getNotification,
  readAllNotifications,
  readNotification,
} from 'src/redux/actions/admin/notificationAction';

let isLoadMore = false;
const NotificationDropdown = (props, ref) => {
  const dispatch = useDispatch();
  const { notifications, total } = useSelector(state => {
    const { docs: notifications = [], total = 0 } =
      (state.notification || {}).dataSuccess || {};
    return {
      notifications,
      total,
    };
  }, shallowEqual);

  const page = useSelector(state => state.notification.page, shallowEqual);
  const isLoading = useSelector(state => state.notification.isLoading, shallowEqual);
  const [visible, setVisible] = useState(false);

  // Handler
  const onTogglePopover = (state = !visible) => () => {
    setVisible(state);
  };

  const onSeenNotification = notification => {
    const { _id: notificationId } = notification;

    dispatch(readNotification(notificationId));
  };

  const onReadAllNotification = () => {
    dispatch(readAllNotifications());
  };

  const onLoadMoreNotification = () => {
    if (!isLoadMore) {
      isLoadMore = true;
      dispatch(getNotification(page));
    }
  };

  useEffect(() => {
    if (isLoadMore && !isLoading) {
      isLoadMore = false;
    }
  }, [isLoading]);

  // Renderer
  const renderNotificationItem = notification => {
    return (
      <NotificationItem
        key={notification._id}
        onSeenNotification={onSeenNotification}
        notification={notification}
      />
    );
  };

  const renderListNotification = () => {
    return (
      <InfiniteScroll
        onLoadEnd={onLoadMoreNotification}
        className="notification-container-dropdown shadow"
      >
        {notifications.length ? (
          <>
            <div className="d-flex align-items-center justify-content-end">
              <Translation>
                {t => (
                  <button
                    onClick={onReadAllNotification}
                    className="notification-read-all btn-link text-primary-0"
                  >
                    {t('notifications.read_all_notifications')}
                  </button>
                )}
              </Translation>
            </div>
            {notifications.map(renderNotificationItem)}
          </>
        ) : (
          <Translation>
            {t => <h6 className="text-center my-2">{t('notifications.no_data')}</h6>}
          </Translation>
        )}
      </InfiniteScroll>
    );
  };

  return (
    <Popover
      preferPlace="below"
      body={renderListNotification()}
      onOuterAction={onTogglePopover(false)}
      isOpen={visible}
    >
      <div
        onClick={onTogglePopover()}
        className="notification-container unread-container-1 add-cursor"
      >
        <Icon type="bell" className="ring-bell-notification" />
        <div
          className="unread-number-container-1"
          style={{ background: `${total > 0 ? '#f46036' : ''}` }}
        >
          {!!total && (
            <span className="unread-number-1">{total > 99 ? '99+' : total}</span>
          )}
        </div>
      </div>
    </Popover>
  );
};

export default memo(NotificationDropdown);
