import { connect } from 'react-redux';
import SkillsScreen from '../../../components/screens/admin/skills/skillsScreen';
import {
	getSkills,
	createSkills,
	updateSkills,
	updateOrder
} from '../../../redux/actions/admin/skillsAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';

const mapStateToProps = state => {
	return {
		success: state.skills.success,
		error: state.skills.error,
		isLoading: state.skills.isLoading,
		data: state.skills.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetSkills: () => {
			dispatch(getSkills());
		},
		onCreateSkills: data => {
			dispatch(createSkills(data));
		},
		onUpdateSkills: ({ data, id }) => {
			dispatch(updateSkills({ data, id }));
		},
		onUpdateOrder: data => {
			dispatch(updateOrder(data));
		},
		onGetCurrentUser: () => {
			dispatch(getCurrentUser());
		}
	};
};

const CustomerStoriesContainer = connect(mapStateToProps, mapDispatchToProps)(SkillsScreen);

export default CustomerStoriesContainer;
