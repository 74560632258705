import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Icon, Input, Dropdown, Menu, Select, Button, Modal, notification } from 'antd';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { CREATIVES_STATUS } from '../../../../../core/constants/constants';
import { updateCreatives } from '../../../../../redux/actions/admin/creativesAction';
import { getSkills } from '../../../../../redux/actions/admin/skillsAction';
import { getHourRate } from '../../../../../redux/actions/admin/hourRateAction';
import { deleteAccount } from 'src/core/services/modules/userService.js';
import { getCreatives } from 'src/redux/actions/admin/creativesAction.js';
import './creativesInfo.scss';

const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

class CreativesInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleRedirect = () => {
    const { creatives } = this.props;
    const url = `/?creativesId=${creatives._id}`;
    this.props.history.push(url);
  };

  renderStatus = () => {
    const { creatives } = this.props;
    const { userId, status } = creatives || {};
    const filterStatus = CREATIVES_STATUS.filter(item => item.value !== status);
    const menu = (
      <Menu>
        {filterStatus.map(item => (
          <Menu.Item key={item.value}>
            <span
              onClick={() =>
                this.props.onUpdateCreatives({
                  id: creatives._id,
                  status: item.value,
                })
              }
            >
              {item.label}
            </span>
          </Menu.Item>
        ))}
      </Menu>
    );
    const statusRender = CREATIVES_STATUS.find(item => item.value === status);
    return (
      <Dropdown
        overlay={menu}
        trigger={['click']}
        disabled={userId.isDelete}
        className={userId.isDelete ? 'c-nodrop' : ''}
      >
        <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
          {statusRender.label || null} <Icon type="down" />
        </span>
      </Dropdown>
    );
  };

  renderSkills = () => {
    const { creatives, allSkills } = this.props;
    const { skills } = creatives || {};
    const allSkillsName =
      (allSkills && allSkills.length && allSkills.map(item => item.name)) || [];
    const otherIndex =
      skills && skills.length && skills.findIndex(item => !allSkillsName.includes(item));
    skills.push(skills.splice(otherIndex, 1)[0]);
    const skillsRender =
      (skills &&
        skills.length &&
        skills.map(item => {
          if (!allSkillsName.includes(item)) {
            return (
              <div key={item} className="other-skills-container">
                <span key={item} className="skills-item">
                  Others
                </span>
                <TextArea rows={4} defaultValue={item} disabled />
              </div>
            );
          }
          return (
            <span key={item} className="skills-item">
              {item}
            </span>
          );
        })) ||
      null;
    return (
      <div className="skills-container container-item">
        <span className="info-title">Skills</span>
        <div className="skills-list">{skillsRender}</div>
      </div>
    );
  };

  renderEquipment = () => {
    const { creatives } = this.props;
    return (
      <div className="equipment-container container-item">
        <span className="info-title">Equipment</span>
        <TextArea rows={4} defaultValue={creatives.equipment} disabled />
      </div>
    );
  };

  renderPostalCode = () => {
    const { creatives } = this.props;
    const { userId } = creatives || {};
    if (!userId.postalCode) return null;
    return (
      <div className="equipment-container container-item">
        <span className="info-title">Postal Code</span>
        <Input defaultValue={userId.postalCode} disabled />
      </div>
    );
  };

  renderDateCreated = () => {
    const { creatives } = this.props;
    return (
      <div className="date-created-container container-item">
        <span className="info-title">Date Created</span>
        <Input
          defaultValue={moment(creatives.createdAt).format('MMM DD, YYYY [at] HH:mm')}
          disabled
        />
      </div>
    );
  };

  renderHourRate = () => {
    const { allHourRates } = this.props;
    const { creatives } = this.props;
    const { userId } = creatives || {};
    return (
      <div className="hour-rate-container container-item">
        <span className="info-title">Hourly rate</span>
        <Select
          disabled={userId.isDelete}
          className="hour-rate-select"
          defaultValue={
            creatives.hourRate ||
            (allHourRates && allHourRates.length && allHourRates[0].name)
          }
          onChange={value =>
            this.props.onUpdateCreatives({
              id: creatives._id,
              hourRate: value,
            })
          }
        >
          {allHourRates &&
            allHourRates.length &&
            allHourRates.map(item => {
              return item.status ? (
                <Option key={item._id} value={item.name}>
                  {item.name}
                </Option>
              ) : null;
            })}
        </Select>
      </div>
    );
  };

  renderPortfolioLinks = () => {
    const { creatives } = this.props;
    const { linkProfile } = creatives;
    return (
      <div className="portfolios-container container-item">
        <span className="info-title">Portfolio links</span>
        {(linkProfile &&
          linkProfile.length &&
          linkProfile.map(item => {
            return (
              <a
                key={item}
                href={item.includes('http') ? item : `//${item}`}
                className="d-block portfolio-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                {item}
              </a>
            );
          })) ||
          null}
      </div>
    );
  };

  renderSpecialSation = () => {
    const { creatives } = this.props;
    const { specialSation } = creatives;
    return (
      <div className="specialSation-container container-item">
        <span className="info-title">Specialisations</span>
        <Input defaultValue={specialSation} disabled />
      </div>
    );
  };

  renderNote = () => {
    const { creatives } = this.props;
    const { note, userId } = creatives || {};
    return (
      <div className="note-container container-item">
        <span className="info-title">Notes</span>
        <TextArea
          disabled={userId.isDelete}
          rows={4}
          defaultValue={note}
          onBlur={e => {
            if (e.target.value !== note) {
              this.props.onUpdateCreatives({
                id: creatives && creatives._id,
                note: e.target.value,
              });
            }
          }}
        />
      </div>
    );
  };

  renderTrade = () => {
    const { creatives } = this.props;
    const { trade, userId } = creatives || {};
    return (
      <div className="trade-container container-item">
        <span className="info-title">Trade</span>
        <TextArea
          disabled={userId.isDelete}
          rows={4}
          defaultValue={trade}
          onBlur={e => {
            if (e.target.value !== trade) {
              this.props.onUpdateCreatives({
                id: creatives && creatives._id,
                trade: e.target.value,
              });
            }
          }}
        />
      </div>
    );
  };

  componentDidMount() {
    this.props.onGetSkills();
    this.props.onGetHourRate();
  }

  handleDeleteAcount = () => {
    const { creatives, onGetCreatives, onClose } = this.props;
    const { userId } = creatives || {};
    confirm({
      title: 'Delete account',
      content: 'Are you sure want to delete this account ?',
      okText: 'No',
      cancelText: 'Yes',
      className: 'confirm-delete-account',
      onCancel: async () => {
        try {
          const res = await deleteAccount(userId._id);
          const { data } = res;
          if (data.success) {
            notification['success']({
              message: 'Delete success',
            });
            onGetCreatives();

            return onClose();
          }
          notification['warning']({
            message: data.error.message,
          });
        } catch (error) {
          notification['warning']({
            message: 'Something wrong!',
          });
        }
      },
      onOk: () => {
        // this.props.onClose();
      },
    });
  };

  checkDeleteAccount = () => {
    const { currentUser, creatives } = this.props;
    const { roleId } = currentUser || {};
    const deteledUser = creatives && creatives.userId && creatives.userId.isDelete;
    const canDeleted = roleId && roleId.name === 'superadmin' && !deteledUser;
    return canDeleted;
  };

  render() {
    const { creatives } = this.props;
    return (
      <div className="creatives-info-container">
        <div className="info-item">
          {this.renderTrade()}
          {this.renderSkills()}
          {this.renderEquipment()}
          {this.renderPostalCode()}
          <span onClick={() => this.handleRedirect()} className="projects-link">
            {creatives.userId && creatives.userId.fullName}'s projects
          </span>
        </div>
        <div className="info-item">
          {this.renderNote()}
          {this.renderHourRate()}
          {this.renderPortfolioLinks()}
          {this.renderSpecialSation()}
          {this.renderDateCreated()}
          <Button
            type="primary"
            className="d-block ml-auto"
            onClick={this.handleDeleteAcount}
            disabled={!this.checkDeleteAccount()}
          >
            Delete account
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allSkills: state.skills.data,
    allHourRates: state.hour_rate.data,
    currentUser: state.user.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetSkills: () => {
      dispatch(getSkills());
    },
    onUpdateCreatives: data => {
      dispatch(updateCreatives(data));
    },
    onGetHourRate: () => {
      dispatch(getHourRate());
    },
    onGetCreatives: () => {
      dispatch(getCreatives({ page: 1, limit: 10 }));
    },
  };
};

export default withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(CreativesInfo))
);
