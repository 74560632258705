import { trimObject } from 'src/core/utils/commonUtils';
import {
  SEND_EMAIL_REQUEST,
  RESET_PASSWORD_REQUEST,
  RESEND_EMAIL_REQUEST,
} from '../typeAction';

export const sendEmail = email => ({
  type: SEND_EMAIL_REQUEST,
  payload: trimObject(email),
});

export const resetPassword = password => ({
  type: RESET_PASSWORD_REQUEST,
  payload: trimObject(password),
});

export const resendEmail = email => ({
  type: RESEND_EMAIL_REQUEST,
  payload: trimObject(email),
});
