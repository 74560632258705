import { GET_ROLES_REQUEST, GET_ROLES_SUCCESS, GET_ROLES_FAILED } from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';

const defaultState = {
	success: false,
	error: null,
	data: [],
	isLoading: false
};

const rolesReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_ROLES_REQUEST: {
			return {
				...state
			};
		}
		case GET_ROLES_SUCCESS: {
			return {
				...state,
				...action.payload
			};
		}
		case GET_ROLES_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				error: action.payload
			};
		}
		default:
			return state;
	}
};

export default rolesReducer;
