import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal, Typography } from 'antd';
import DataTable from 'src/components/common/dataTable/dataTable';
import { Link } from 'react-router-dom';
import moment from 'moment';

class BriefHistoryModal extends PureComponent {
  render() {
    const { visibleModal, customers } = this.props;

    const columns = [
      {
        title: 'Full Name',
        render: (_, record) => {
          return (
            <Link
              className="project-id"
              target="_blank"
              to={{ pathname: '/customers/' + record.customerId }}
            >
              {record.fullName}
            </Link>
          );
        },
        width: 240,
      },
      {
        title: 'Time',
        dataIndex: 'editedAt',
        render: (_, record) => {
          return (
            <Typography>
              {record.editedAt ? moment(record.editedAt).format('DD/MM/YYYY') : ''}
            </Typography>
          );
        },
      },
    ];

    return (
      <Modal
        className="modal-brief-history"
        visible={visibleModal}
        footer={null}
        title={'BRIEF HISTORY'}
        width={600}
        onCancel={() => this.props.onCloseModal()}
        destroyOnClose={true}
        closable={true}
        maskClosable={false}
      >
        <DataTable
          columns={columns}
          dataSource={customers}
          onChangeHandler={this.handleChangeTable}
          hiddenUpdateOrder={true}
        />
      </Modal>
    );
  }
}

const WrapHistoryModal = Form.create({ name: 'normal_get_started' })(BriefHistoryModal);

export default withTranslation('common')(WrapHistoryModal);
