import React from 'react';
import { Divider } from 'antd';
import moment from 'moment';
import './projectInfoPreview.scss';
import IconProductionDates from '../../../../../assets/images/icons/common/calendar.svg';
import IconStatus from '../../../../../assets/images/icons/common/flag.svg';
import IconAddress from '../../../../../assets/images/icons/common/location.svg';
import IconProductType from '../../../../../assets/images/icons/common/camera.svg';
import IconServiceType from '../../../../../assets/images/icons/common/tag.svg';
import renderHTML from 'react-render-html';
import UserAvatar from 'src/components/common/userAvatar/index.js';

import IconBudget from '../../../../../assets/images/icons/common/price.svg';
import IconPackage from '../../../../../assets/images/icons/common/package.svg';
import IconBrief from '../../../../../assets/images/icons/common/brief.svg';
import IconLinks from '../../../../../assets/images/icons/common/global.svg';
import IconFiles from '../../../../../assets/images/icons/common/files.svg';
import IconDownloadDisabled from '../../../../../assets/images/icons/common/download-disabled.svg';
import IconEstimationPrice from '../../../../../assets/images/icons/common/estimation.svg';

class ProjectInfoPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderProductionDates = productionDates => {
    if (productionDates && productionDates.length) {
      return productionDates.map(item => (
        <div key={item._id} className="production-date-item">
          <span className="start-date">
            {moment(item.startDate).format('MMM DD, YYYY')}
          </span>
          <span className="duration">
            {moment(item.startDate).format('h:mm A')} -{' '}
            {moment(item.startDate).add(item.duration, 'h').format('h:mm A')}
          </span>
        </div>
      ));
    }
  };

  renderFiles = files => {
    if (files && files.length) {
      return files.map(item => (
        <span key={item._id} className="file-item">
          <a href={item.path} className="file-link" download>
            {item.name}
          </a>
          <a href={item.path} download>
            <img src={IconDownloadDisabled} alt="download" />
          </a>
        </span>
      ));
    }
  };

  getDuration = () => {
    const { currentProject } = this.props;
    const productionDates = (currentProject && currentProject.productionDates) || null;
    if (productionDates && productionDates.length) {
      return productionDates.reduce((sum, cur) => {
        return cur.duration ? sum + cur.duration : sum;
      }, 0);
    }
    return 0;
  };

  isAnimationProductType = productType => {
    return productType && productType.name && productType.name === 'Animation';
  };

  render() {
    const { currentProject } = this.props;
    const { customerId } = currentProject || {};
    const { userId } = customerId || {};
    const { fullName, avt } = userId || null;
    const {
      productionDates,
      status,
      address,
      productTypeId,
      serviceTypeId,
      budget,
      estimationPrice,
    } = currentProject || null;
    const label = (address && address.label) || '';
    const { name: serviceTypeName } = serviceTypeId || {};
    const packages = (currentProject && currentProject.packages) || null;
    // const budget = (currentProject && currentProject.budget) || 0;
    // const estimationPrice = (currentProject && currentProject.estimationPrice) || 0;
    const brief = (currentProject && currentProject.brief) || null;
    const inspirationLink = (currentProject && currentProject.inspirationLink) || null;
    const resourceId = (currentProject && currentProject.resourceId) || null;
    const isAnimationProductType = this.isAnimationProductType(productTypeId);

    if (currentProject) {
      return (
        <div className="project-preview-container">
          <div className="project-header">Project Info</div>
          <Divider className="project-divider" />
          <div className="project-info-content">
            <div className="content-item">
              <div className="content-item-icon">
                <UserAvatar fullName={fullName} status={true} avatar={avt} size="small" />
              </div>
              <div className="creator-info">
                <span className="creator-name">{fullName}</span>
                <span className="user-role">Project Owner</span>
              </div>
            </div>
            {!isAnimationProductType && (
              <div className="content-item production-date-wrapper">
                <div className="content-item-icon">
                  <img
                    className="content-img"
                    src={IconProductionDates}
                    alt="production dates"
                  />
                </div>
                <div className="production-date-container">
                  {this.renderProductionDates(productionDates)}
                </div>
              </div>
            )}
            <div className="content-item">
              <div className="content-item-icon">
                <img className="content-img" src={IconStatus} alt="status" />
              </div>
              <span>{status}</span>
            </div>
            {!isAnimationProductType && (
              <div className="content-item">
                <div className="content-item-icon">
                  <img className="content-img" src={IconAddress} alt="address" />
                </div>
                <span>{label}</span>
              </div>
            )}
            <div className="content-item">
              <div className="content-item-icon">
                <img
                  className="content-img"
                  src={IconProductType}
                  alt="production type"
                />
              </div>
              <span>{productTypeId && productTypeId.name}</span>
            </div>
            {!isAnimationProductType && (
              <div className="content-item">
                <div className="content-item-icon">
                  <img className="content-img" src={IconServiceType} alt="service type" />
                </div>
                <span>{serviceTypeName}</span>
              </div>
            )}
            <div className="content-item">
              <div className="content-item-icon">
                <img className="content-img" src={IconBudget} alt="Budget" />
              </div>
              <span>
                £{' '}
                {(typeof budget === 'number' && budget >= 0) || !!budget ? budget : 'N/A'}
              </span>
            </div>
            <div className="content-item">
              <div className="content-item-icon">
                <img
                  className="content-img"
                  src={IconEstimationPrice}
                  alt="Estimation Price"
                />
              </div>
              <span>
                £{' '}
                {(typeof estimationPrice === 'number' && estimationPrice >= 0) ||
                !!estimationPrice
                  ? estimationPrice
                  : 'N/A'}
              </span>
            </div>
            {!isAnimationProductType && (
              <div className="content-item">
                <div className="content-item-icon">
                  <img className="content-img" src={IconPackage} alt="Package" />
                </div>
                <span>{packages && packages.name}</span>
              </div>
            )}
            <div className="content-item">
              <div className="content-item-icon">
                <img className="content-img" src={IconBrief} alt="Brief" />
              </div>
              <span style={{ width: 'calc(100% - 50px)' }}>
                {brief && renderHTML(brief)}
              </span>
            </div>
            <div className="content-item">
              <div className="content-item-icon">
                <img className="content-img" src={IconLinks} alt="Links" />
              </div>
              <a
                className="inspiration-link"
                href={inspirationLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {inspirationLink}
              </a>
            </div>
            <div className="content-item files-wrapper">
              <div className="content-item-icon">
                <img className="content-img" src={IconFiles} alt="Files" />
              </div>
              <div className="files-container">{this.renderFiles(resourceId)}</div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default ProjectInfoPreview;
