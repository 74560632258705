import { connect } from 'react-redux';
import AddonsScreen from '../../../components/screens/admin/addons/addonsScreen';
import {
	getAddons,
	createAddons,
	updateAddons,
	updateOrder
} from '../../../redux/actions/admin/addonsAction';
import { getCategories, getCategoriesById } from '../../../redux/actions/admin/categoriesAction';
import { getPackage } from '../../../redux/actions/admin/packageAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';

const mapStateToProps = state => {
	return {
		success: state.addons.success,
		error: state.addons.error,
		isLoading: state.addons.isLoading,
		data: state.addons.data,
		categories: state.categories.data,
		subCategories: state.subCategories.data,
		detailCategories: state.categories.detailData,
		packages: state.packages.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetAddons: () => {
			dispatch(getAddons());
		},
		onCreateAddons: data => {
			dispatch(createAddons(data));
		},
		onUpdateAddons: ({ data, id }) => {
			dispatch(updateAddons({ data, id }));
		},
		onGetCategoriesById: id => {
			dispatch(getCategoriesById(id));
		},
		onUpdateOrder: data => {
			dispatch(updateOrder(data));
		},
		onGetCategories: () => {
			dispatch(getCategories());
		},
		onGetPackages: () => {
			dispatch(getPackage());
		},
		onGetCurrentUser: () => {
			dispatch(getCurrentUser());
		}
	};
};

const AddonsContainer = connect(mapStateToProps, mapDispatchToProps)(AddonsScreen);

export default AddonsContainer;
