import React from 'react';
import './ourTeamForm.scss';
import { Button, Form, Icon, Input, Upload } from 'antd';
import {
  extractFileExtension,
  uuid,
  detectFile,
  dummyRequest,
  formDataConfig,
  isImageOrVideo,
} from 'src/core/utils/commonUtils';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { S3_FOLDERS } from 'src/core/constants/constants';
import { httpService } from 'src/core/services/httpService';

const { TextArea } = Input;

class OurTeamForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: [],
      isBannerRequired: false,
      displayImage: !this.props.isCreate,
      isBtnDisabled: false,
      errors: [],
      content: '',
      isLoading: false,
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      const { ourTeam } = this.props;
      const { errors, banner, content } = this.state;
      if (!banner.length && !ourTeam) {
        errors.push('banner');
      } else {
        const errorIndex = errors.findIndex(item => item === 'banner');
        if (errorIndex > -1) errors.splice(errorIndex, 1);
      }
      if (!content.trim() && !ourTeam) {
        errors.push('content');
      }
      this.setState({ errors, isLoading: true });
      if (err || errors.length) {
        return;
      } else {
        const title = values.title && values.title.trim();
        const subTitle = values.subTitle && values.subTitle.trim();
        let bannerParams;
        const data = {
          title,
          subTitle,
          description: content.trim() ? content.trim() : ourTeam.description,
          type:
            banner.length && banner[0].originFileObj
              ? detectFile(banner[0])
              : ourTeam.type,
        };

        // Upload banner to s3
        if (banner.length && banner[0].originFileObj) {
          bannerParams = {
            filename: `${S3_FOLDERS.CMS.OUR_TEAM.BANNER}/${uuid()}${extractFileExtension({
              filename: banner[0].originFileObj.name,
            })}`,
            file: banner[0].originFileObj,
          };
          const res = await httpService._upload({ photos: [bannerParams] });
          if (res && res.length) {
            data.banner = res[0];
          }
        } else {
          data.banner = ourTeam.urlBanner;
        }

        const formData = formDataConfig(data);
        this.props.onUpdateOurTeam(formData);
      }
    });
  };

  // Banner handler
  onBannerChange = info => {
    const { ourTeam } = this.props;
    let { errors } = this.state;
    if (info && info.file && !isImageOrVideo(info.file)) {
      errors = errors.filter(item => item !== 'banner');
      errors.push('bannerType');
      this.setState({ errors });
      return;
    } else {
      errors = errors.filter(item => item !== 'bannerType');
      this.setState({ errors });
    }
    if (!info.fileList.length && !ourTeam) {
      errors.push('banner');
    } else {
      const errorIndex = errors.findIndex(item => item === 'banner');
      if (errorIndex > -1) errors.splice(errorIndex, 1);
    }
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    this.setState({ banner: fileList, errors });
  };

  // Content CKEditor Change
  contentChange = (event, editor) => {
    let { errors } = this.state;
    const content = editor.getData();
    if (content.trim()) {
      errors = errors.filter(item => item !== 'content');
    } else {
      errors.push('content');
    }
    this.setState({
      content,
      errors,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.ourTeam !== this.props.ourTeam) {
      this.setState({
        banner: [],
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.ourTeam !== prevProps.ourTeam) {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { ourTeam } = this.props;
    const { banner, isBtnDisabled, errors, isLoading } = this.state;
    const bannerProps = {
      customRequest: dummyRequest,
      onChange: this.onBannerChange,
      multiple: true,
      fileList: banner,
    };
    return (
      <Form className="ourTeam-form">
        <Form.Item label="Title">
          {getFieldDecorator('title', {
            initialValue: (ourTeam && ourTeam.title) || '',
          })(<Input placeholder="Title" size="large" />)}
        </Form.Item>
        <Form.Item label="Sub-title">
          {getFieldDecorator('subTitle', {
            initialValue: (ourTeam && ourTeam.subTitle) || '',
          })(<TextArea placeholder="Sub-title" rows={4} />)}
        </Form.Item>
        <Form.Item label="Banner (image/video)">
          <Upload {...bannerProps}>
            <Button>
              <Icon type="upload" /> Upload Banner
            </Button>
          </Upload>
          {!banner.length && ourTeam && ourTeam.urlBanner ? ourTeam.urlBanner : null}
          {errors.includes('banner') ? (
            <div className="ant-form-explain content-error">Please upload banner!</div>
          ) : null}
          {this.state.errors.includes('bannerType') ? (
            <div className="ant-form-explain content-error">
              Please upload image or video
            </div>
          ) : null}
        </Form.Item>
        <Form.Item label="Description">
          <CKEditor
            onInit={editor => {
              editor.ui
                .getEditableElement()
                .parentElement.insertBefore(
                  editor.ui.view.toolbar.element,
                  editor.ui.getEditableElement()
                );
            }}
            onChange={(event, editor) => this.contentChange(event, editor)}
            editor={DecoupledEditor}
            data={ourTeam && ourTeam.description ? ourTeam.description : null}
          />
          {errors.includes('content') ? (
            <div className="ant-form-explain content-error">Please input content!</div>
          ) : null}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            loading={isLoading}
            onClick={this.handleSubmit}
            className="ourTeam-form-button btn-primary-0"
            size="large"
            disabled={isBtnDisabled}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedOurTeamForm = Form.create({
  name: 'normal_ourTeam',
})(OurTeamForm);

export default WrappedOurTeamForm;
