import React, { PureComponent } from 'react';
import 'antd/dist/antd.css';
import './settingForm.scss';
import { Form, Avatar, Empty, Select } from 'antd';
import ModalData from '../../../../newModal/newModal';
import ScrollList from '../../../../scrollList/scrollList';
import { updateTopic } from '../../../../../../redux/actions/admin/topicsAction';
import { connect, shallowEqual } from 'react-redux';
import {
  isSuperAdmin,
  isAdmin,
  filterAcceptedMembers,
} from '../../../../../../core/utils/commonUtils';
import UserAvatar from 'src/components/common/userAvatar/index.js';
import { getCustomerTeamWork } from 'src/core/services/modules/teamsService';

const { Option } = Select;

class SettingForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      teamMembers: [],
      teamAdmin: []
    };
  }

  componentDidMount() {
    this.getCustomerTeam();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!shallowEqual(this.props.currentProject, nextProps.currentProject)) {
      this.getCustomerTeam(nextProps);
    }
  }

  openModalHandler = () => {
    this.setState({
      modalStatus: true,
    });
  };

  closeModalHandler = () => {
    this.setState({
      modalStatus: false,
    });
  };

  getCustomerTeam = async (props = this.props) => {
    try {
      const { currentProject } = props;
      const customerId = currentProject.customerId && currentProject.customerId._id;
      const { data: responseData } = await getCustomerTeamWork({ customerId });
      const { data: team } = responseData;
      const members = (team || {}).members || [];
      const teamAdmin = team.admin || []
      if(teamAdmin.userId) this.setState({ teamAdmin: [teamAdmin.userId] })
      this.setState({ teamMembers: members.map(item => item.userId) });
    } catch (e) {}
  };

  filterUserIds = arr => {
    if (arr && arr.length) {
      return arr
        .filter(item => item.status && item.status === 'active')
        .map(item => item.userId);
    }
    return [];
  };

  updateParticipantHandler = (id, type) => {
    this.updateTopicHandler({ [type]: id });
  };

  getCreator = currentTopic => {
    if (currentTopic && currentTopic.creator && currentTopic.creator['editorId']) {
      return currentTopic.creator['editorId'];
    }
    if (currentTopic && currentTopic.creator && currentTopic.creator['creativeId']) {
      return currentTopic.creator['creativeId'];
    }
    if (currentTopic && currentTopic.creator && currentTopic.creator['customerId']) {
      return currentTopic.creator['customerId'];
    }
    if (currentTopic && currentTopic.creator && currentTopic.creator['adminId']) {
      return currentTopic.creator['adminId'];
    }
    return null;
  };

  getCustomerMembers = () => {
    const { currentProject, currentTopic } = this.props;
    const { participants } = currentTopic;
    const customer = currentProject.customerId && currentProject.customerId.userId;
    if (participants && participants.length) {
      return participants.filter(item => item.customerId && item._id !== customer._id);
    }
    return [];
  };

  renderAllMembersModal = () => {
    const { modalStatus, teamMembers: members } = this.state;
    const { currentProject, currentTopic } = this.props;
    const { participants } = currentTopic;
    const customer =
      currentProject.customerId && currentProject.customerId.userId
        ? [currentProject.customerId.userId]
        : [];
    const editors =
      currentProject.editorId && currentProject.editorId.length
        ? this.filterUserIds(filterAcceptedMembers(currentProject.editorId))
        : [];
    const creatives =
      currentProject.creativeId && currentProject.creativeId.length
        ? this.filterUserIds(filterAcceptedMembers(currentProject.creativeId))
        : [];

    let allMemberData = [...customer, ...editors, ...creatives, ...members, ...this.state.teamAdmin]
    if(allMemberData.length) allMemberData = allMemberData.filter((thing, index, self) =>
      index === self.findIndex((t) => (
        t._id === thing._id
      ))
    )

    const allMembers = {
      list: allMemberData,
      total: allMemberData.length,
    };
    // Remove creator from modal list members
    const participantIds =
      participants && participants.length ? participants.map(item => item._id) : [];
    const listRender = (
      <ModalData
        className="participant-modal"
        title="Participants"
        visible={modalStatus}
        onCloseModal={this.closeModalHandler}
        footer={null}
        dataSource={
          <ScrollList
            ids={participantIds}
            data={allMembers ? allMembers : <Empty />}
            type="participant"
            onSelectItem={this.updateParticipantHandler}
          />
        }
      />
    );
    return listRender;
  };

  renderParticipants = () => {
    const { currentTopic } = this.props;
    const { participants } = currentTopic;
    if (currentTopic && participants && participants.length) {
      return (
        <div className="d-flex flex-wrap">
          {participants.map(item => {
            const isUserActive = this.checkUserActive(item);
            const { avt, fullName, _id } = item || {};
            return (
              <UserAvatar
                fullName={fullName}
                avatar={avt}
                status={isUserActive}
                size="small"
                key={_id}
              />
            );
          })}
        </div>
      );
    }
  };

  updateTopicHandler = data => {
    const { currentTopic } = this.props;
    const body = {
      id: currentTopic._id,
      ...data,
    };
    this.props.onUpdateTopic(body);
  };

  isAssignedAdmin = (currentUser, currentProject) => {
    const { admins } = currentProject;
    if (admins && admins.length && isAdmin(currentUser)) {
      const adminsUserIds = admins.map(item => item.userId && item.userId._id);
      return adminsUserIds.includes(currentUser._id);
    }
    return false;
  };

  renderPlusIcon = () => {
    const { currentUser, currentProject } = this.props;
    return (
      (isSuperAdmin(currentUser) ||
        this.isAssignedAdmin(currentUser, currentProject)) && (
        <Avatar
          className="icon-plus"
          icon="plus"
          onClick={() => this.openModalHandler()}
        />
      )
    );
  };

  checkUserActive = user => {
    const isUserNotActive =
      user &&
      ((user.creativeId && user.creativeId.status !== 'active') ||
        (user.customerId && user.customerId.status !== 'active') ||
        (user.editorId && user.editorId.status !== 'active'));
    return !isUserNotActive;
  };

  render() {
    const { currentTopic } = this.props;
    const { creator } = currentTopic;
    const { avt, fullName } = creator || {};
    const isCreatorActive = this.checkUserActive(creator);
    if (currentTopic) {
      return (
        <>
          <Form.Item className="status-topic">
            <div className="lh-20 setting-title">Status</div>
            <Select
              key={`status${currentTopic.status ? 'Open' : 'Closed'}`}
              defaultValue={currentTopic.status ? 'Open' : 'Closed'}
              onChange={value =>
                this.updateTopicHandler({
                  status: value === 'Open' ? true : false,
                })
              }
            >
              <Option value="Open">Open</Option>
              <Option value="Closed">Closed</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <div className="lh-20 setting-title">Creator</div>
            <UserAvatar
              fullName={fullName}
              avatar={avt}
              status={isCreatorActive}
              size="small"
            />
          </Form.Item>
          <Form.Item>
            <div className="lh-20 setting-title">Participants</div>
            {this.renderParticipants()}
            {this.renderAllMembersModal()}
            {this.renderPlusIcon()}
          </Form.Item>
        </>
      );
    }
    return null;
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.user.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateTopic: body => {
      dispatch(updateTopic(body));
    },
  };
};

const WrappedSettingForm = Form.create({ name: 'normal_setting' })(SettingForm);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedSettingForm);
