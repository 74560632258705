import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Jumbotron, Container } from 'react-bootstrap';
import './packageScreen.scss';
import DataTable from '../../../common/dragTable/dragTable';
import ModalData from '../../../common/newModal/newModal';
import PackageForm from '../../../common/form/packageForm/packageForm';
import { ORDER_KEYS } from '../../../../core/constants/constants';
import { chain } from 'lodash';

class PackageScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: 'Admin Package - VidOps',
			data: [],
			key: '',
			itemUpdate: null,
			isModalOpen: false,
			isCreate: false,
			productTypeId: null
		};
	}

	componentDidMount() {
		this.props.onGetCurrentUser();
		this.props.onGetProductTypes();
		this.props.onGetPackage();
	}

	handleDelete = key => {
		this.props.onDeletePackage(key);
	};

	handleUpdate = key => {
		const { data } = this.props;
		if (data && data.length) {
			this.setState({
				itemUpdate: data.find(item => item._id === key)
			});
		}
		this.setState({
			isModalOpen: true,
			isCreate: false,
			key
		});
	};

	formatColumns = () => {
		return [
			{
				title: 'Name',
				dataIndex: 'name'
			},
			{
				title: 'Service',
				dataIndex: 'productTypeId[name]'
			},
			{
				title: 'Description',
				dataIndex: 'description'
			},
			{
				title: 'Basic package',
				dataIndex: 'isBasic',
				render: status => {
					if (status) {
						return <span>True</span>;
					}
					return <span>False</span>;
				},
				filters: [
					{
						text: 'True',
						value: 'True'
					},
					{
						text: 'False',
						value: 'False'
					}
				],
				onFilter: (value, record) => {
					const isBasic = record.isBasic ? 'True' : 'False';
					return isBasic.indexOf(value) === 0;
				}
			},
			{
				title: 'Status',
				dataIndex: 'status',
				render: status => {
					if (status) {
						return <span>Active</span>;
					}
					return <span>Inactive</span>;
				},
				filters: [
					{
						text: 'Active',
						value: 'Active'
					},
					{
						text: 'Inactive',
						value: 'Inactive'
					}
				],
				onFilter: (value, record) => {
					const status = record.status ? 'Active' : 'Inactive';
					return status.indexOf(value) === 0;
				}
			},
			{
				title: 'Operation',
				dataIndex: 'operation',
				render: (text, record) => {
					return (
						<div className="operation-container">
							<Button
								onClick={() => this.handleUpdate(record.key)}
								type="primary"
								className="btn-primary-0"
								icon="edit"
							></Button>
						</div>
					);
				}
			}
		];
	};

	handleAdd = productTypeId => {
		this.setState({
			isModalOpen: true,
			isCreate: true,
			productTypeId
		});
	};

	createHandler = data => {
		this.props.onCreatePackage(data);
	};

	updateHandler = data => {
		const payload = {
			data,
			id: this.state.key
		};
		this.props.onUpdatePackage(payload);
	};

	closeModalHandler = () => {
		this.setState({
			isModalOpen: false
		});
	};

	updateOrderHandler = orderData => {
		const updateOrderData = {
			key: ORDER_KEYS.PACKAGE,
			...orderData
		};
		this.props.onUpdateOrder(updateOrderData);
	};

	componentDidUpdate(prevProps) {
		if (this.props.data !== prevProps.data) {
			this.setState({
				isModalOpen: false
			});
		}
		if (this.props.packages !== prevProps.packages) {
			this.props.onGetPackage();
		}
	}

	renderPackages = () => {
		const columns = this.formatColumns();
		const groupData = this.groupByServiceId();
		if (groupData && groupData.length) {
			return groupData.map(item => (
				<Jumbotron key={item.productTypeId} className="mgt-40">
					<h2 className="sub-heading">Package {item.packages[0].productTypeId.name}</h2>
					<p className="description">Manage Package {item.packages[0].productTypeId.name}</p>
					<div className="divider"></div>
					<Container>
						<Button
							className="btn-primary-0"
							onClick={() => this.handleAdd(item.productTypeId)}
							type="primary"
							style={{ marginBottom: 16 }}
						>
							Add new
						</Button>
						<DataTable
							columns={columns}
							dataSource={item.packages}
							pagination={false}
							onUpdateOrder={this.updateOrderHandler}
						/>
					</Container>
				</Jumbotron>
			));
		}
	};

	groupByServiceId = () => {
		const { data } = this.props;
		if (data && data.length) {
			// Group by name and add key for table row
			const groupArr = chain(data)
				.groupBy('productTypeId[_id]')
				.map((value, key) => ({
					productTypeId: key,
					packages:
						value && value.length ? value.map(item => ({ ...item, key: item._id })) : []
				}))
				.value();
			return groupArr;
		}
	};

	render() {
		const { isLoading, productTypes } = this.props;
		const { isModalOpen, isCreate, key, itemUpdate, productTypeId } = this.state;
		return (
			<main role="main" className="page-content package-screen">
				{this.renderPackages()}
				<ModalData
					className="big-modal"
					title={isCreate ? 'Create Package' : 'Update Package'}
					visible={isModalOpen}
					onCloseModal={this.closeModalHandler}
					dataSource={
						<PackageForm
							onCreatePackage={this.createHandler}
							onUpdatePackage={this.updateHandler}
							isLoading={isLoading}
							isCreate={isCreate}
							itemUpdate={itemUpdate}
							key={key}
							productTypes={productTypes}
							productTypeId={productTypeId}
						/>
					}
					footer={null}
				/>
			</main>
		);
	}
}

export default withTranslation('common')(PackageScreen);
