import {
    GET_SERVICE_TYPE_REQUEST,
    GET_SERVICE_TYPE_SUCCESS,
    GET_SERVICE_TYPE_FAILED,
    GET_SERVICE_TYPE_BY_PRODUCT_TYPE_SUCCESS,
    GET_SERVICE_TYPE_BY_PRODUCT_TYPE_FAILED,
    GET_SERVICE_TYPE_BY_ID_REQUEST,
    GET_SERVICE_TYPE_BY_ID_SUCCESS,
    GET_SERVICE_TYPE_BY_ID_FAILED,
    CREATE_SERVICE_TYPE_REQUEST,
    CREATE_SERVICE_TYPE_SUCCESS,
    CREATE_SERVICE_TYPE_FAILED,
    UPDATE_SERVICE_TYPE_REQUEST,
    UPDATE_SERVICE_TYPE_SUCCESS,
    UPDATE_SERVICE_TYPE_FAILED,
    UPDATE_ORDER_SERVICE_TYPE_SUCCESS,
UPDATE_ORDER_SERVICE_TYPE_FAILED
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';
import { updateHelper, compare } from '../../../core/utils/commonUtils';

const defaultState = {
    success: false,
    error: null,
    data: [],
    isLoading: false,
    detailData: null,
    serviceTypeByProductType: [],
};

const serviceTypeReducer = (state = defaultState, action) => {
    switch (action.type) {
        case GET_SERVICE_TYPE_REQUEST: {
            return {
                ...state,
            };
        }
        case GET_SERVICE_TYPE_SUCCESS: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case GET_SERVICE_TYPE_FAILED: {
            openNotification('error', action.payload.message);
            return {
                ...state,
                error: action.payload,
            };
        }
        case GET_SERVICE_TYPE_BY_PRODUCT_TYPE_SUCCESS: {
            return {
                ...state,
                serviceTypeByProductType: action.payload.data,
            };
        }
        case GET_SERVICE_TYPE_BY_PRODUCT_TYPE_FAILED: {
            openNotification('error', action.payload.message);
            return {
                ...state,
                error: action.payload,
            };
        }
        case GET_SERVICE_TYPE_BY_ID_REQUEST: {
			return {
				...state
			};
		}
		case GET_SERVICE_TYPE_BY_ID_SUCCESS: {
			return {
				...state,
				detailData: action.payload.data
			};
		}
		case GET_SERVICE_TYPE_BY_ID_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				error: action.payload
			};
		}
		case CREATE_SERVICE_TYPE_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case CREATE_SERVICE_TYPE_SUCCESS: {
			openNotification('success', 'Create successfully');
			return {
				...state,
				isLoading: false,
				data: [action.payload.data, ...state.data].sort(compare)
			};
		}
		case CREATE_SERVICE_TYPE_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_SERVICE_TYPE_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case UPDATE_SERVICE_TYPE_SUCCESS: {
			openNotification('success', 'Update successful');
			return {
				...state,
				isLoading: false,
				data: updateHelper(state, action)
			};
		}
		case UPDATE_SERVICE_TYPE_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_ORDER_SERVICE_TYPE_SUCCESS: {
			return {
				...state,
				isLoading: false,
				data: action.payload.data
			};
		}
		case UPDATE_ORDER_SERVICE_TYPE_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
        default:
            return state;
    }
};

export default serviceTypeReducer;
