import { connect } from 'react-redux';
import CustomersScreen from '../../../components/screens/admin/customers/customersScreen';
import {
  getCustomers,
  getCustomersDetail,
  clearCustomerDetail
} from 'src/redux/actions/admin/customersAction';
import { getCurrentUser } from 'src/redux/actions/admin/userAction';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

const mapStateToProps = state => {
  return {
    success: state.customers.success,
    error: state.customers.error,
    isLoading: state.customers.isLoading,
    data: state.customers.data,
    isGettingDetail: state.customers.isGettingDetail,
    detailData: state.customers.detailData,
    chosenTeams: state.teams.chosenTeams,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetCustomers: params => {
      dispatch(getCustomers(params));
    },
    onGetCustomersDetail: id => {
      dispatch(getCustomersDetail(id));
    },
    onClearCustomerDetail: () => {
      dispatch(clearCustomerDetail());
    },
    onGetCurrentUser: () => {
      dispatch(getCurrentUser());
    },
  };
};

const CustomersContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(CustomersScreen);

export default CustomersContainer;
