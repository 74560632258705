import {
	GET_ITEMS_REQUEST,
	CREATE_ITEMS_REQUEST,
	UPDATE_ITEMS_REQUEST,
	UPDATE_ORDER_ITEMS_SUCCESS
} from '../typeAction';

export const getItems = () => ({
	type: GET_ITEMS_REQUEST
});

export const createItems = data => ({
	type: CREATE_ITEMS_REQUEST,
	payload: data
});

export const updateItems = ({ data, id }) => ({
	type: UPDATE_ITEMS_REQUEST,
	payload: { data, id }
});

export const updateOrder = data => ({
	type: UPDATE_ORDER_ITEMS_SUCCESS,
	payload: data
});
