import { put, call, takeLatest } from 'redux-saga/effects';
import {
	GET_SUB_CATEGORIES_FAILED,
	GET_SUB_CATEGORIES_SUCCESS,
	CREATE_SUB_CATEGORIES_FAILED,
	CREATE_SUB_CATEGORIES_SUCCESS,
	UPDATE_SUB_CATEGORIES_FAILED,
	UPDATE_SUB_CATEGORIES_SUCCESS,
	UPDATE_ORDER_SUB_CATEGORIES_FAILED,
	GET_SUB_CATEGORIES_REQUEST,
	CREATE_SUB_CATEGORIES_REQUEST,
	UPDATE_SUB_CATEGORIES_REQUEST,
	UPDATE_ORDER_SUB_CATEGORIES_SUCCESS
} from '../../actions/typeAction';
import {
	getSubCategoriesService,
	createSubCategoriesService,
	updateSubCategoriesService
} from '../../../core/services/modules/subCategoriesService';
import { updateOrderService } from '../../../core/services/modules/orderService';
import { openNotification } from '../../../components/common/notification/notification';

export function* getSubCategoriesSaga() {
	const response = yield call(getSubCategoriesService);
	if (response.data.error) {
		yield put({
			type: GET_SUB_CATEGORIES_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: GET_SUB_CATEGORIES_SUCCESS,
			payload: data
		});
	}
}

export function* createSubCategoriesSaga(action) {
	const response = yield call(createSubCategoriesService, action.payload);
	if (response.data.error) {
		yield put({
			type: CREATE_SUB_CATEGORIES_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: CREATE_SUB_CATEGORIES_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateSubCategoriesSaga(action) {
	const response = yield call(updateSubCategoriesService, action.payload);
	if (response.data.error) {
		yield put({
			type: UPDATE_SUB_CATEGORIES_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: UPDATE_SUB_CATEGORIES_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateOrderSaga(action) {
	const response = yield call(updateOrderService, action.payload);
	if (response.data.error) {
		yield put({
			type: UPDATE_ORDER_SUB_CATEGORIES_FAILED,
			payload: response.data.error
		});
	} else {
		openNotification('success', 'Update successful');
	}
}

export function* watchSubCategories() {
	yield takeLatest(GET_SUB_CATEGORIES_REQUEST, getSubCategoriesSaga);
	yield takeLatest(CREATE_SUB_CATEGORIES_REQUEST, createSubCategoriesSaga);
	yield takeLatest(UPDATE_SUB_CATEGORIES_REQUEST, updateSubCategoriesSaga);
	yield takeLatest(UPDATE_ORDER_SUB_CATEGORIES_SUCCESS, updateOrderSaga);
}
