// LOGIN
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_RESET = 'LOGIN_RESET';

// ADMIN UPDATE_BANNER
export const UPDATE_BANNER_REQUEST = 'UPDATE_BANNER_REQUEST';
export const UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS';
export const UPDATE_BANNER_FAILED = 'UPDATE_BANNER_FAILED';

// ADMIN GET_BANNER
export const GET_BANNER_REQUEST = 'GET_BANNER_REQUEST';
export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_FAILED = 'GET_BANNER_FAILED';

// ADMIN GET_REVIEWS
export const GET_REVIEWS_REQUEST = 'GET_REVIEWS_REQUEST';
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const GET_REVIEWS_FAILED = 'GET_REVIEWS_FAILED';

// ADMIN GET_HOWITWORK
export const GET_HOWITWORK_REQUEST = 'GET_HOWITWORK_REQUEST';
export const GET_HOWITWORK_SUCCESS = 'GET_HOWITWORK_SUCCESS';
export const GET_HOWITWORK_FAILED = 'GET_HOWITWORK_FAILED';

// ADMIN DELETE_HOWITWORK
export const DELETE_HOWITWORK_REQUEST = 'DELETE_HOWITWORK_REQUEST';
export const DELETE_HOWITWORK_SUCCESS = 'DELETE_HOWITWORK_SUCCESS';
export const DELETE_HOWITWORK_FAILED = 'DELETE_HOWITWORK_FAILED';

// ADMIN CREATE_HOWITWORK
export const CREATE_HOWITWORK_REQUEST = 'CREATE_HOWITWORK_REQUEST';
export const CREATE_HOWITWORK_SUCCESS = 'CREATE_HOWITWORK_SUCCESS';
export const CREATE_HOWITWORK_FAILED = 'CREATE_HOWITWORK_FAILED';

// ADMIN UPDATE_HOWITWORK
export const UPDATE_HOWITWORK_REQUEST = 'UPDATE_HOWITWORK_REQUEST';
export const UPDATE_HOWITWORK_SUCCESS = 'UPDATE_HOWITWORK_SUCCESS';
export const UPDATE_HOWITWORK_FAILED = 'UPDATE_HOWITWORK_FAILED';

// ADMIN UPDATE_ORDER_HOWITWORK
export const UPDATE_ORDER_HOWITWORK_REQUEST = 'UPDATE_ORDER_HOWITWORK_REQUEST';
export const UPDATE_ORDER_HOWITWORK_SUCCESS = 'UPDATE_ORDER_HOWITWORK_SUCCESS';
export const UPDATE_ORDER_HOWITWORK_FAILED = 'UPDATE_ORDER_HOWITWORK_FAILED';

// ADMIN GET_STORIES_HIGHT_LIGHT
export const GET_STORIES_HIGHT_LIGHT_REQUEST = 'GET_STORIES_HIGHT_LIGHT_REQUEST';
export const GET_STORIES_HIGHT_LIGHT_SUCCESS = 'GET_STORIES_HIGHT_LIGHT_SUCCESS';
export const GET_STORIES_HIGHT_LIGHT_FAILED = 'GET_STORIES_HIGHT_LIGHT_FAILED';

// ADMIN GET_CUSTOMER_STORIES
export const GET_CUSTOMER_STORIES_REQUEST = 'GET_CUSTOMER_STORIES_REQUEST';
export const GET_CUSTOMER_STORIES_SUCCESS = 'GET_CUSTOMER_STORIES_SUCCESS';
export const GET_CUSTOMER_STORIES_FAILED = 'GET_CUSTOMER_STORIES_FAILED';

// ADMIN DELETE_CUSTOMER_STORIES
export const DELETE_CUSTOMER_STORIES_REQUEST = 'DELETE_CUSTOMER_STORIES_REQUEST';
export const DELETE_CUSTOMER_STORIES_SUCCESS = 'DELETE_CUSTOMER_STORIES_SUCCESS';
export const DELETE_CUSTOMER_STORIES_FAILED = 'DELETE_CUSTOMER_STORIES_FAILED';

// ADMIN CREATE_CUSTOMER_STORIES
export const CREATE_CUSTOMER_STORIES_REQUEST = 'CREATE_CUSTOMER_STORIES_REQUEST';
export const CREATE_CUSTOMER_STORIES_SUCCESS = 'CREATE_CUSTOMER_STORIES_SUCCESS';
export const CREATE_CUSTOMER_STORIES_FAILED = 'CREATE_CUSTOMER_STORIES_FAILED';

// ADMIN UPDATE_CUSTOMER_STORIES
export const UPDATE_CUSTOMER_STORIES_REQUEST = 'UPDATE_CUSTOMER_STORIES_REQUEST';
export const UPDATE_CUSTOMER_STORIES_SUCCESS = 'UPDATE_CUSTOMER_STORIES_SUCCESS';
export const UPDATE_CUSTOMER_STORIES_FAILED = 'UPDATE_CUSTOMER_STORIES_FAILED';

// ADMIN UPDATE_ORDER_CUSTOMER_STORIES
export const UPDATE_ORDER_CUSTOMER_STORIES_REQUEST =
  'UPDATE_ORDER_CUSTOMER_STORIES_REQUEST';
export const UPDATE_ORDER_CUSTOMER_STORIES_SUCCESS =
  'UPDATE_ORDER_CUSTOMER_STORIES_SUCCESS';
export const UPDATE_ORDER_CUSTOMER_STORIES_FAILED =
  'UPDATE_ORDER_CUSTOMER_STORIES_FAILED';

// ADMIN GET_HOUR_RATE
export const GET_HOUR_RATE_REQUEST = 'GET_HOUR_RATE_REQUEST';
export const GET_HOUR_RATE_SUCCESS = 'GET_HOUR_RATE_SUCCESS';
export const GET_HOUR_RATE_FAILED = 'GET_HOUR_RATE_FAILED';

// ADMIN CREATE_HOUR_RATE
export const CREATE_HOUR_RATE_REQUEST = 'CREATE_HOUR_RATE_REQUEST';
export const CREATE_HOUR_RATE_SUCCESS = 'CREATE_HOUR_RATE_SUCCESS';
export const CREATE_HOUR_RATE_FAILED = 'CREATE_HOUR_RATE_FAILED';

// ADMIN UPDATE_HOUR_RATE
export const UPDATE_HOUR_RATE_REQUEST = 'UPDATE_HOUR_RATE_REQUEST';
export const UPDATE_HOUR_RATE_SUCCESS = 'UPDATE_HOUR_RATE_SUCCESS';
export const UPDATE_HOUR_RATE_FAILED = 'UPDATE_HOUR_RATE_FAILED';

// ADMIN UPDATE_ORDER_HOUR_RATE
export const UPDATE_ORDER_HOUR_RATE_REQUEST = 'UPDATE_ORDER_HOUR_RATE_REQUEST';
export const UPDATE_ORDER_HOUR_RATE_SUCCESS = 'UPDATE_ORDER_HOUR_RATE_SUCCESS';
export const UPDATE_ORDER_HOUR_RATE_FAILED = 'UPDATE_ORDER_HOUR_RATE_FAILED';

// ADMIN GET_SKILLS
export const GET_SKILLS_REQUEST = 'GET_SKILLS_REQUEST';
export const GET_SKILLS_SUCCESS = 'GET_SKILLS_SUCCESS';
export const GET_SKILLS_FAILED = 'GET_SKILLS_FAILED';

// ADMIN CREATE_SKILLS
export const CREATE_SKILLS_REQUEST = 'CREATE_SKILLS_REQUEST';
export const CREATE_SKILLS_SUCCESS = 'CREATE_SKILLS_SUCCESS';
export const CREATE_SKILLS_FAILED = 'CREATE_SKILLS_FAILED';

// ADMIN UPDATE_SKILLS
export const UPDATE_SKILLS_REQUEST = 'UPDATE_SKILLS_REQUEST';
export const UPDATE_SKILLS_SUCCESS = 'UPDATE_SKILLS_SUCCESS';
export const UPDATE_SKILLS_FAILED = 'UPDATE_SKILLS_FAILED';

// ADMIN UPDATE_ORDER_SKILLS
export const UPDATE_ORDER_SKILLS_REQUEST = 'UPDATE_ORDER_SKILLS_REQUEST';
export const UPDATE_ORDER_SKILLS_SUCCESS = 'UPDATE_ORDER_SKILLS_SUCCESS';
export const UPDATE_ORDER_SKILLS_FAILED = 'UPDATE_ORDER_SKILLS_FAILED';

// ADMIN GET_PRIVACY
export const GET_PRIVACY_REQUEST = 'GET_PRIVACY_REQUEST';
export const GET_PRIVACY_SUCCESS = 'GET_PRIVACY_SUCCESS';
export const GET_PRIVACY_FAILED = 'GET_PRIVACY_FAILED';

// ADMIN CREATE_PRIVACY
export const CREATE_PRIVACY_REQUEST = 'CREATE_PRIVACY_REQUEST';
export const CREATE_PRIVACY_SUCCESS = 'CREATE_PRIVACY_SUCCESS';
export const CREATE_PRIVACY_FAILED = 'CREATE_PRIVACY_FAILED';

// ADMIN UPDATE_PRIVACY
export const UPDATE_PRIVACY_REQUEST = 'UPDATE_PRIVACY_REQUEST';
export const UPDATE_PRIVACY_SUCCESS = 'UPDATE_PRIVACY_SUCCESS';
export const UPDATE_PRIVACY_FAILED = 'UPDATE_PRIVACY_FAILED';

// ADMIN GET_POLICIES_TERM_PRIVACY
export const GET_POLICIES_TERM_PRIVACY_REQUEST = 'GET_POLICIES_TERM_PRIVACY_REQUEST';
export const GET_POLICIES_TERM_PRIVACY_SUCCESS = 'GET_POLICIES_TERM_PRIVACY_SUCCESS';
export const GET_POLICIES_TERM_PRIVACY_FAILED = 'GET_POLICIES_TERM_PRIVACY_FAILED';

// ADMIN GET_TERM
export const GET_TERM_REQUEST = 'GET_TERM_REQUEST';
export const GET_TERM_SUCCESS = 'GET_TERM_SUCCESS';
export const GET_TERM_FAILED = 'GET_TERM_FAILED';

// ADMIN CREATE_TERM
export const CREATE_TERM_REQUEST = 'CREATE_TERM_REQUEST';
export const CREATE_TERM_SUCCESS = 'CREATE_TERM_SUCCESS';
export const CREATE_TERM_FAILED = 'CREATE_TERM_FAILED';

// ADMIN UPDATE_TERM
export const UPDATE_TERM_REQUEST = 'UPDATE_TERM_REQUEST';
export const UPDATE_TERM_SUCCESS = 'UPDATE_TERM_SUCCESS';
export const UPDATE_TERM_FAILED = 'UPDATE_TERM_FAILED';

// ADMIN GET_POLICIES
export const GET_POLICIES_REQUEST = 'GET_POLICIES_REQUEST';
export const GET_POLICIES_SUCCESS = 'GET_POLICIES_SUCCESS';
export const GET_POLICIES_FAILED = 'GET_POLICIES_FAILED';

// ADMIN CREATE_POLICIES
export const CREATE_POLICIES_REQUEST = 'CREATE_POLICIES_REQUEST';
export const CREATE_POLICIES_SUCCESS = 'CREATE_POLICIES_SUCCESS';
export const CREATE_POLICIES_FAILED = 'CREATE_POLICIES_FAILED';

// ADMIN UPDATE_POLICIES
export const UPDATE_POLICIES_REQUEST = 'UPDATE_POLICIES_REQUEST';
export const UPDATE_POLICIES_SUCCESS = 'UPDATE_POLICIES_SUCCESS';
export const UPDATE_POLICIES_FAILED = 'UPDATE_POLICIES_FAILED';

// ADMIN GET_CREATIVES
export const GET_CREATIVES_REQUEST = 'GET_CREATIVES_REQUEST';
export const GET_CREATIVES_SUCCESS = 'GET_CREATIVES_SUCCESS';
export const GET_CREATIVES_FAILED = 'GET_CREATIVES_FAILED';

export const CLEAR_CREATIVES_DETAIL = 'CLEAR_CREATIVES_DETAIL';

// ADMIN LOAD_MORE_CREATIVES
export const LOAD_MORE_CREATIVES_REQUEST = 'LOAD_MORE_CREATIVES_REQUEST';
export const LOAD_MORE_CREATIVES_SUCCESS = 'LOAD_MORE_CREATIVES_SUCCESS';
export const LOAD_MORE_CREATIVES_FAILED = 'LOAD_MORE_CREATIVES_FAILED';

// ADMIN GET_CREATIVES_DETAIL
export const GET_CREATIVES_DETAIL_REQUEST = 'GET_CREATIVES_DETAIL_REQUEST';
export const GET_CREATIVES_DETAIL_SUCCESS = 'GET_CREATIVES_DETAIL_SUCCESS';
export const GET_CREATIVES_DETAIL_FAILED = 'GET_CREATIVES_DETAIL_FAILED';

// ADMIN SEARCH_CREATIVES
export const SEARCH_CREATIVES_REQUEST = 'SEARCH_CREATIVES_REQUEST';
export const SEARCH_CREATIVES_SUCCESS = 'SEARCH_CREATIVES_SUCCESS';
export const SEARCH_CREATIVES_FAILED = 'SEARCH_CREATIVES_FAILED';

// ADMIN SEND_INVITATION_CREATIVES
export const SEND_INVITATION_CREATIVES_REQUEST = 'SEND_INVITATION_CREATIVES_REQUEST';
export const SEND_INVITATION_CREATIVES_SUCCESS = 'SEND_INVITATION_CREATIVES_SUCCESS';
export const SEND_INVITATION_CREATIVES_FAILED = 'SEND_INVITATION_CREATIVES_FAILED';

// ADMIN UPDATE_CREATIVES
export const UPDATE_CREATIVES_REQUEST = 'UPDATE_CREATIVES_REQUEST';
export const UPDATE_CREATIVES_SUCCESS = 'UPDATE_CREATIVES_SUCCESS';
export const UPDATE_CREATIVES_FAILED = 'UPDATE_CREATIVES_FAILED';

// ADMIN RESEND_INVITATION_CREATIVES
export const RESEND_INVITATION_CREATIVES_REQUEST = 'RESEND_INVITATION_CREATIVES_REQUEST';
export const RESEND_INVITATION_CREATIVES_SUCCESS = 'RESEND_INVITATION_CREATIVES_SUCCESS';
export const RESEND_INVITATION_CREATIVES_FAILED = 'RESEND_INVITATION_CREATIVES_FAILED';

// ADMIN UPDATE_STATUS_CREATIVES
export const UPDATE_STATUS_CREATIVES_REQUEST = 'UPDATE_STATUS_CREATIVES_REQUEST';
export const UPDATE_STATUS_CREATIVES_SUCCESS = 'UPDATE_STATUS_CREATIVES_SUCCESS';
export const UPDATE_STATUS_CREATIVES_FAILED = 'UPDATE_STATUS_CREATIVES_FAILED';

// ADMIN GET_PRODUCTION_TIME
export const GET_PRODUCTION_TIME_REQUEST = 'GET_PRODUCTION_TIME_REQUEST';
export const GET_PRODUCTION_TIME_SUCCESS = 'GET_PRODUCTION_TIME_SUCCESS';
export const GET_PRODUCTION_TIME_FAILED = 'GET_PRODUCTION_TIME_FAILED';

// ADMIN CREATE_PRODUCTION_TIME
export const CREATE_PRODUCTION_TIME_REQUEST = 'CREATE_PRODUCTION_TIME_REQUEST';
export const CREATE_PRODUCTION_TIME_SUCCESS = 'CREATE_PRODUCTION_TIME_SUCCESS';
export const CREATE_PRODUCTION_TIME_FAILED = 'CREATE_PRODUCTION_TIME_FAILED';

// ADMIN UPDATE_PRODUCTION_TIME
export const UPDATE_PRODUCTION_TIME_REQUEST = 'UPDATE_PRODUCTION_TIME_REQUEST';
export const UPDATE_PRODUCTION_TIME_SUCCESS = 'UPDATE_PRODUCTION_TIME_SUCCESS';
export const UPDATE_PRODUCTION_TIME_FAILED = 'UPDATE_PRODUCTION_TIME_FAILED';

// ADMIN GET_SERVICE_TYPE
export const GET_SERVICE_TYPE_REQUEST = 'GET_SERVICE_TYPE_REQUEST';
export const GET_SERVICE_TYPE_SUCCESS = 'GET_SERVICE_TYPE_SUCCESS';
export const GET_SERVICE_TYPE_FAILED = 'GET_SERVICE_TYPE_FAILED';

// ADMIN GET_SERVICE_TYPE_BY_PRODUCT_TYPE
export const GET_SERVICE_TYPE_BY_PRODUCT_TYPE_REQUEST =
  'GET_SERVICE_TYPE_BY_PRODUCT_TYPE_REQUEST';
export const GET_SERVICE_TYPE_BY_PRODUCT_TYPE_SUCCESS =
  'GET_SERVICE_TYPE_BY_PRODUCT_TYPE_SUCCESS';
export const GET_SERVICE_TYPE_BY_PRODUCT_TYPE_FAILED =
  'GET_SERVICE_TYPE_BY_PRODUCT_TYPE_FAILED';

// GET_SERVICE_TYPE_BY_ID
export const GET_SERVICE_TYPE_BY_ID_REQUEST = 'GET_SERVICE_TYPE_BY_ID_REQUEST';
export const GET_SERVICE_TYPE_BY_ID_SUCCESS = 'GET_SERVICE_TYPE_BY_ID_SUCCESS';
export const GET_SERVICE_TYPE_BY_ID_FAILED = 'GET_SERVICE_TYPE_BY_ID_FAILED';

// ADMIN CREATE_SERVICE_TYPE
export const CREATE_SERVICE_TYPE_REQUEST = 'CREATE_SERVICE_TYPE_REQUEST';
export const CREATE_SERVICE_TYPE_SUCCESS = 'CREATE_SERVICE_TYPE_SUCCESS';
export const CREATE_SERVICE_TYPE_FAILED = 'CREATE_SERVICE_TYPE_FAILED';

// ADMIN UPDATE_SERVICE_TYPE
export const UPDATE_SERVICE_TYPE_REQUEST = 'UPDATE_SERVICE_TYPE_REQUEST';
export const UPDATE_SERVICE_TYPE_SUCCESS = 'UPDATE_SERVICE_TYPE_SUCCESS';
export const UPDATE_SERVICE_TYPE_FAILED = 'UPDATE_SERVICE_TYPE_FAILED';

// ADMIN UPDATE_ORDER_SERVICE_TYPE
export const UPDATE_ORDER_SERVICE_TYPE_REQUEST = 'UPDATE_ORDER_SERVICE_TYPE_REQUEST';
export const UPDATE_ORDER_SERVICE_TYPE_SUCCESS = 'UPDATE_ORDER_SERVICE_TYPE_SUCCESS';
export const UPDATE_ORDER_SERVICE_TYPE_FAILED = 'UPDATE_ORDER_SERVICE_TYPE_FAILED';

// ADMIN GET_BUDGET
export const GET_BUDGET_REQUEST = 'GET_BUDGET_REQUEST';
export const GET_BUDGET_SUCCESS = 'GET_BUDGET_SUCCESS';
export const GET_BUDGET_FAILED = 'GET_BUDGET_FAILED';

// ADMIN CREATE_BUDGET
export const CREATE_BUDGET_REQUEST = 'CREATE_BUDGET_REQUEST';
export const CREATE_BUDGET_SUCCESS = 'CREATE_BUDGET_SUCCESS';
export const CREATE_BUDGET_FAILED = 'CREATE_BUDGET_FAILED';

// ADMIN UPDATE_BUDGET
export const UPDATE_BUDGET_REQUEST = 'UPDATE_BUDGET_REQUEST';
export const UPDATE_BUDGET_SUCCESS = 'UPDATE_BUDGET_SUCCESS';
export const UPDATE_BUDGET_FAILED = 'UPDATE_BUDGET_FAILED';

// ADMIN UPDATE_ORDER_BUDGET
export const UPDATE_ORDER_BUDGET_REQUEST = 'UPDATE_ORDER_BUDGET_REQUEST';
export const UPDATE_ORDER_BUDGET_SUCCESS = 'UPDATE_ORDER_BUDGET_SUCCESS';
export const UPDATE_ORDER_BUDGET_FAILED = 'UPDATE_ORDER_BUDGET_FAILED';

// ADMIN GET_ITEMS
export const GET_ITEMS_REQUEST = 'GET_ITEMS_REQUEST';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_FAILED = 'GET_ITEMS_FAILED';

// ADMIN CREATE_ITEMS
export const CREATE_ITEMS_REQUEST = 'CREATE_ITEMS_REQUEST';
export const CREATE_ITEMS_SUCCESS = 'CREATE_ITEMS_SUCCESS';
export const CREATE_ITEMS_FAILED = 'CREATE_ITEMS_FAILED';

// ADMIN UPDATE_ITEMS
export const UPDATE_ITEMS_REQUEST = 'UPDATE_ITEMS_REQUEST';
export const UPDATE_ITEMS_SUCCESS = 'UPDATE_ITEMS_SUCCESS';
export const UPDATE_ITEMS_FAILED = 'UPDATE_ITEMS_FAILED';

// ADMIN UPDATE_ORDER_ITEMS
export const UPDATE_ORDER_ITEMS_REQUEST = 'UPDATE_ORDER_ITEMS_REQUEST';
export const UPDATE_ORDER_ITEMS_SUCCESS = 'UPDATE_ORDER_ITEMS_SUCCESS';
export const UPDATE_ORDER_ITEMS_FAILED = 'UPDATE_ORDER_ITEMS_FAILED';

// ADMIN GET_CATEGORIES
export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILED = 'GET_CATEGORIES_FAILED';

// ADMIN CREATE_CATEGORIES
export const CREATE_CATEGORIES_REQUEST = 'CREATE_CATEGORIES_REQUEST';
export const CREATE_CATEGORIES_SUCCESS = 'CREATE_CATEGORIES_SUCCESS';
export const CREATE_CATEGORIES_FAILED = 'CREATE_CATEGORIES_FAILED';

// ADMIN UPDATE_CATEGORIES
export const UPDATE_CATEGORIES_REQUEST = 'UPDATE_CATEGORIES_REQUEST';
export const UPDATE_CATEGORIES_SUCCESS = 'UPDATE_CATEGORIES_SUCCESS';
export const UPDATE_CATEGORIES_FAILED = 'UPDATE_CATEGORIES_FAILED';

// ADMIN UPDATE_ORDER_CATEGORIES
export const UPDATE_ORDER_CATEGORIES_REQUEST = 'UPDATE_ORDER_CATEGORIES_REQUEST';
export const UPDATE_ORDER_CATEGORIES_SUCCESS = 'UPDATE_ORDER_CATEGORIES_SUCCESS';
export const UPDATE_ORDER_CATEGORIES_FAILED = 'UPDATE_ORDER_CATEGORIES_FAILED';

// ADMIN GET_ADD_ON
export const GET_ADD_ON_REQUEST = 'GET_ADD_ON_REQUEST';
export const GET_ADD_ON_SUCCESS = 'GET_ADD_ON_SUCCESS';
export const GET_ADD_ON_FAILED = 'GET_ADD_ON_FAILED';

// ADMIN CREATE_ADD_ON
export const CREATE_ADD_ON_REQUEST = 'CREATE_ADD_ON_REQUEST';
export const CREATE_ADD_ON_SUCCESS = 'CREATE_ADD_ON_SUCCESS';
export const CREATE_ADD_ON_FAILED = 'CREATE_ADD_ON_FAILED';

// ADMIN UPDATE_ADD_ON
export const UPDATE_ADD_ON_REQUEST = 'UPDATE_ADD_ON_REQUEST';
export const UPDATE_ADD_ON_SUCCESS = 'UPDATE_ADD_ON_SUCCESS';
export const UPDATE_ADD_ON_FAILED = 'UPDATE_ADD_ON_FAILED';

// ADMIN UPDATE_ORDER_ADD_ON
export const UPDATE_ORDER_ADD_ON_REQUEST = 'UPDATE_ORDER_ADD_ON_REQUEST';
export const UPDATE_ORDER_ADD_ON_SUCCESS = 'UPDATE_ORDER_ADD_ON_SUCCESS';
export const UPDATE_ORDER_ADD_ON_FAILED = 'UPDATE_ORDER_ADD_ON_FAILED';

// ADMIN GET_PACKAGE
export const GET_PACKAGE_REQUEST = 'GET_PACKAGE_REQUEST';
export const GET_PACKAGE_SUCCESS = 'GET_PACKAGE_SUCCESS';
export const GET_PACKAGE_FAILED = 'GET_PACKAGE_FAILED';

// ADMIN GET_PACKAGE_BY_PARAMS
export const GET_PACKAGE_BY_PARAMS_REQUEST = 'GET_PACKAGE_BY_PARAMS_REQUEST';
export const GET_PACKAGE_BY_PARAMS_SUCCESS = 'GET_PACKAGE_BY_PARAMS_SUCCESS';
export const GET_PACKAGE_BY_PARAMS_FAILED = 'GET_PACKAGE_BY_PARAMS_FAILED';

// ADMIN GET_PACKAGE_BY_ID
export const GET_PACKAGE_BY_ID_REQUEST = 'GET_PACKAGE_BY_ID_REQUEST';
export const GET_PACKAGE_BY_ID_SUCCESS = 'GET_PACKAGE_BY_ID_SUCCESS';
export const GET_PACKAGE_BY_ID_FAILED = 'GET_PACKAGE_BY_ID_FAILED';

// ADMIN CREATE_PACKAGE
export const CREATE_PACKAGE_REQUEST = 'CREATE_PACKAGE_REQUEST';
export const CREATE_PACKAGE_SUCCESS = 'CREATE_PACKAGE_SUCCESS';
export const CREATE_PACKAGE_FAILED = 'CREATE_PACKAGE_FAILED';

// ADMIN UPDATE_PACKAGE
export const UPDATE_PACKAGE_REQUEST = 'UPDATE_PACKAGE_REQUEST';
export const UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_FAILED = 'UPDATE_PACKAGE_FAILED';

// ADMIN UPDATE_ORDER_PACKAGE
export const UPDATE_ORDER_PACKAGE_REQUEST = 'UPDATE_ORDER_PACKAGE_REQUEST';
export const UPDATE_ORDER_PACKAGE_SUCCESS = 'UPDATE_ORDER_PACKAGE_SUCCESS';
export const UPDATE_ORDER_PACKAGE_FAILED = 'UPDATE_ORDER_PACKAGE_FAILED';

// ADMIN GET_ADD_HOC
export const GET_ADD_HOC_REQUEST = 'GET_ADD_HOC_REQUEST';
export const GET_ADD_HOC_SUCCESS = 'GET_ADD_HOC_SUCCESS';
export const GET_ADD_HOC_FAILED = 'GET_ADD_HOC_FAILED';

// ADMIN CREATE_ADD_HOC
export const CREATE_ADD_HOC_REQUEST = 'CREATE_ADD_HOC_REQUEST';
export const CREATE_ADD_HOC_SUCCESS = 'CREATE_ADD_HOC_SUCCESS';
export const CREATE_ADD_HOC_FAILED = 'CREATE_ADD_HOC_FAILED';

// ADMIN UPDATE_ADD_HOC
export const UPDATE_ADD_HOC_REQUEST = 'UPDATE_ADD_HOC_REQUEST';
export const UPDATE_ADD_HOC_SUCCESS = 'UPDATE_ADD_HOC_SUCCESS';
export const UPDATE_ADD_HOC_FAILED = 'UPDATE_ADD_HOC_FAILED';

// CREATE VIDEO PROJECT
export const CREATE_VIDEO_PROJECT_REQUEST = 'CREATE_VIDEO_PROJECT_REQUEST';
export const CREATE_VIDEO_PROJECT_SUCCESS = 'CREATE_VIDEO_PROJECT_SUCCESS';
export const CREATE_VIDEO_PROJECT_FAILED = 'CREATE_VIDEO_PROJECT_FAILED';
export const CREATE_VIDEO_PROJECT_RESET = 'CREATE_VIDEO_PROJECT_RESET';

// ADMIN GET_PROJECTS
export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILED = 'GET_PROJECTS_FAILED';

// ADMIN GET_PROJECTS_DETAIL
export const GET_PROJECTS_DETAIL_REQUEST = 'GET_PROJECTS_DETAIL_REQUEST';
export const GET_PROJECTS_DETAIL_SUCCESS = 'GET_PROJECTS_DETAIL_SUCCESS';
export const GET_PROJECTS_DETAIL_FAILED = 'GET_PROJECTS_DETAIL_FAILED';

// ADMIN SEARCH_PROJECTS
export const SEARCH_PROJECTS_REQUEST = 'SEARCH_PROJECTS_REQUEST';
export const SEARCH_PROJECTS_SUCCESS = 'SEARCH_PROJECTS_SUCCESS';
export const SEARCH_PROJECTS_FAILED = 'SEARCH_PROJECTS_FAILED';

// ADMIN UPDATE_PROJECTS
export const UPDATE_PROJECTS_REQUEST = 'UPDATE_PROJECTS_REQUEST';
export const UPDATE_PROJECTS_SUCCESS = 'UPDATE_PROJECTS_SUCCESS';
export const UPDATE_PROJECTS_FAILED = 'UPDATE_PROJECTS_FAILED';

// ADMIN UPDATE_PROJECTS
export const GET_BRIEF_HISTORY_REQUEST = 'GET_BRIEF_HISTORY_REQUEST';
export const GET_BRIEF_HISTORY_SUCCESS = 'GET_BRIEF_HISTORY_SUCCESS';
export const GET_BRIEF_HISTORY_FAIL = 'GET_BRIEF_HISTORY_FAIL';

export const CLEAR_PROJECTS_DETAIL = 'CLEAR_PROJECTS_DETAIL';

// ADMIN UPDATE_STATUS_PROJECTS
export const UPDATE_STATUS_PROJECTS_REQUEST = 'UPDATE_STATUS_PROJECTS_REQUEST';
export const UPDATE_STATUS_PROJECTS_SUCCESS = 'UPDATE_STATUS_PROJECTS_SUCCESS';
export const UPDATE_STATUS_PROJECTS_FAILED = 'UPDATE_STATUS_PROJECTS_FAILED';

// ADMIN ADMIN_CREATE_PROJECT
export const ADMIN_CREATE_PROJECT_REQUEST = 'ADMIN_CREATE_PROJECT_REQUEST';
export const ADMIN_CREATE_PROJECT_SUCCESS = 'ADMIN_CREATE_PROJECT_SUCCESS';
export const ADMIN_CREATE_PROJECT_FAILED = 'ADMIN_CREATE_PROJECT_FAILED';

// SEND_QUOTE
export const SEND_QUOTE_REQUEST = 'SEND_QUOTE_REQUEST';
export const SEND_QUOTE_SUCCESS = 'SEND_QUOTE_SUCCESS';
export const SEND_QUOTE_FAILED = 'SEND_QUOTE_FAILED';

// ADMIN GET SUB_CATEGORIES
export const GET_SUB_CATEGORIES_REQUEST = 'GET_SUB_CATEGORIES_REQUEST';
export const GET_SUB_CATEGORIES_SUCCESS = 'GET_SUB_CATEGORIES_SUCCESS';
export const GET_SUB_CATEGORIES_FAILED = 'GET_SUB_CATEGORIES_FAILED';

// ADMIN CREATE_SUB_CATEGORIES
export const CREATE_SUB_CATEGORIES_REQUEST = 'CREATE_SUB_CATEGORIES_REQUEST';
export const CREATE_SUB_CATEGORIES_SUCCESS = 'CREATE_SUB_CATEGORIES_SUCCESS';
export const CREATE_SUB_CATEGORIES_FAILED = 'CREATE_SUB_CATEGORIES_FAILED';

// ADMIN UPDATE_SUB_CATEGORIES
export const UPDATE_SUB_CATEGORIES_REQUEST = 'UPDATE_SUB_CATEGORIES_REQUEST';
export const UPDATE_SUB_CATEGORIES_SUCCESS = 'UPDATE_SUB_CATEGORIES_SUCCESS';
export const UPDATE_SUB_CATEGORIES_FAILED = 'UPDATE_SUB_CATEGORIES_FAILED';

// ADMIN GET CATEGORIES_BY_ID
export const GET_CATEGORIES_BY_ID_REQUEST = 'GET_CATEGORIES_BY_ID_REQUEST';
export const GET_CATEGORIES_BY_ID_SUCCESS = 'GET_CATEGORIES_BY_ID_SUCCESS';
export const GET_CATEGORIES_BY_ID_FAILED = 'GET_CATEGORIES_BY_ID_FAILED';

// ADMIN UPDATE_ORDER_SUB_CATEGORIES
export const UPDATE_ORDER_SUB_CATEGORIES_REQUEST = 'UPDATE_ORDER_SUB_CATEGORIES_REQUEST';
export const UPDATE_ORDER_SUB_CATEGORIES_SUCCESS = 'UPDATE_ORDER_SUB_CATEGORIES_SUCCESS';
export const UPDATE_ORDER_SUB_CATEGORIES_FAILED = 'UPDATE_ORDER_SUB_CATEGORIES_FAILED';

// ADMIN GET_UNITS
export const GET_UNITS_REQUEST = 'GET_UNITS_REQUEST';
export const GET_UNITS_SUCCESS = 'GET_UNITS_SUCCESS';
export const GET_UNITS_FAILED = 'GET_UNITS_FAILED';

// ADMIN CREATE_UNITS
export const CREATE_UNITS_REQUEST = 'CREATE_UNITS_REQUEST';
export const CREATE_UNITS_SUCCESS = 'CREATE_UNITS_SUCCESS';
export const CREATE_UNITS_FAILED = 'CREATE_UNITS_FAILED';

// ADMIN UPDATE_UNITS
export const UPDATE_UNITS_REQUEST = 'UPDATE_UNITS_REQUEST';
export const UPDATE_UNITS_SUCCESS = 'UPDATE_UNITS_SUCCESS';
export const UPDATE_UNITS_FAILED = 'UPDATE_UNITS_FAILED';

// ADMIN UPDATE_ORDER_UNITS
export const UPDATE_ORDER_UNITS_REQUEST = 'UPDATE_ORDER_UNITS_REQUEST';
export const UPDATE_ORDER_UNITS_SUCCESS = 'UPDATE_ORDER_UNITS_SUCCESS';
export const UPDATE_ORDER_UNITS_FAILED = 'UPDATE_ORDER_UNITS_FAILED';

// ADMIN GET_FAQS
export const GET_FAQS_REQUEST = 'GET_FAQS_REQUEST';
export const GET_FAQS_SUCCESS = 'GET_FAQS_SUCCESS';
export const GET_FAQS_FAILED = 'GET_FAQS_FAILED';

// ADMIN CREATE_FAQS
export const CREATE_FAQS_REQUEST = 'CREATE_FAQS_REQUEST';
export const CREATE_FAQS_SUCCESS = 'CREATE_FAQS_SUCCESS';
export const CREATE_FAQS_FAILED = 'CREATE_FAQS_FAILED';

// ADMIN UPDATE_FAQS
export const UPDATE_FAQS_REQUEST = 'UPDATE_FAQS_REQUEST';
export const UPDATE_FAQS_SUCCESS = 'UPDATE_FAQS_SUCCESS';
export const UPDATE_FAQS_FAILED = 'UPDATE_FAQS_FAILED';

// ADMIN UPDATE_ORDER_FAQS
export const UPDATE_ORDER_FAQS_REQUEST = 'UPDATE_ORDER_FAQS_REQUEST';
export const UPDATE_ORDER_FAQS_SUCCESS = 'UPDATE_ORDER_FAQS_SUCCESS';
export const UPDATE_ORDER_FAQS_FAILED = 'UPDATE_ORDER_FAQS_FAILED';

// ADMIN GET_ADD_ONS
export const GET_ADD_ONS_REQUEST = 'GET_ADD_ONS_REQUEST';
export const GET_ADD_ONS_SUCCESS = 'GET_ADD_ONS_SUCCESS';
export const GET_ADD_ONS_FAILED = 'GET_ADD_ONS_FAILED';

// ADMIN CREATE_ADD_ONS
export const CREATE_ADD_ONS_REQUEST = 'CREATE_ADD_ONS_REQUEST';
export const CREATE_ADD_ONS_SUCCESS = 'CREATE_ADD_ONS_SUCCESS';
export const CREATE_ADD_ONS_FAILED = 'CREATE_ADD_ONS_FAILED';

// ADMIN UPDATE_ADD_ONS
export const UPDATE_ADD_ONS_REQUEST = 'UPDATE_ADD_ONS_REQUEST';
export const UPDATE_ADD_ONS_SUCCESS = 'UPDATE_ADD_ONS_SUCCESS';
export const UPDATE_ADD_ONS_FAILED = 'UPDATE_ADD_ONS_FAILED';

// ADMIN UPDATE_ORDER_ADD_ONS
export const UPDATE_ORDER_ADD_ONS_REQUEST = 'UPDATE_ORDER_ADD_ONS_REQUEST';
export const UPDATE_ORDER_ADD_ONS_SUCCESS = 'UPDATE_ORDER_ADD_ONS_SUCCESS';
export const UPDATE_ORDER_ADD_ONS_FAILED = 'UPDATE_ORDER_ADD_ONS_FAILED';

// GET PRICING GUIDE DATA
export const PRICING_GUIDE_REQUEST = 'PRICING_GUIDE_REQUEST';
export const PRICING_GUIDE_SUCCESS = 'PRICING_GUIDE_SUCCESS';
export const PRICING_GUIDE_FAILED = 'PRICING_GUIDE_FAILED';

// ADMIN GET_BENEFITS
export const GET_BENEFITS_REQUEST = 'GET_BENEFITS_REQUEST';
export const GET_BENEFITS_SUCCESS = 'GET_BENEFITS_SUCCESS';
export const GET_BENEFITS_FAILED = 'GET_BENEFITS_FAILED';

// ADMIN DELETE_BENEFITS
export const DELETE_BENEFITS_REQUEST = 'DELETE_BENEFITS_REQUEST';
export const DELETE_BENEFITS_SUCCESS = 'DELETE_BENEFITS_SUCCESS';
export const DELETE_BENEFITS_FAILED = 'DELETE_BENEFITS_FAILED';

// ADMIN CREATE_BENEFITS
export const CREATE_BENEFITS_REQUEST = 'CREATE_BENEFITS_REQUEST';
export const CREATE_BENEFITS_SUCCESS = 'CREATE_BENEFITS_SUCCESS';
export const CREATE_BENEFITS_FAILED = 'CREATE_BENEFITS_FAILED';

// ADMIN UPDATE_BENEFITS
export const UPDATE_BENEFITS_REQUEST = 'UPDATE_BENEFITS_REQUEST';
export const UPDATE_BENEFITS_SUCCESS = 'UPDATE_BENEFITS_SUCCESS';
export const UPDATE_BENEFITS_FAILED = 'UPDATE_BENEFITS_FAILED';

// ADMIN UPDATE_ORDER_BENEFITS
export const UPDATE_ORDER_BENEFITS_REQUEST = 'UPDATE_ORDER_BENEFITS_REQUEST';
export const UPDATE_ORDER_BENEFITS_SUCCESS = 'UPDATE_ORDER_BENEFITS_SUCCESS';
export const UPDATE_ORDER_BENEFITS_FAILED = 'UPDATE_ORDER_BENEFITS_FAILED';

// ADMIN GET_PRODUCT_TYPES
export const GET_PRODUCT_TYPES_REQUEST = 'GET_PRODUCT_TYPES_REQUEST';
export const GET_PRODUCT_TYPES_SUCCESS = 'GET_PRODUCT_TYPES_SUCCESS';
export const GET_PRODUCT_TYPES_FAILED = 'GET_PRODUCT_TYPES_FAILED';

// ADMIN GET_PRODUCT_TYPES_BY_ID
export const GET_PRODUCT_TYPES_BY_ID_REQUEST = 'GET_PRODUCT_TYPES_BY_ID_REQUEST';
export const GET_PRODUCT_TYPES_BY_ID_SUCCESS = 'GET_PRODUCT_TYPES_BY_ID_SUCCESS';
export const GET_PRODUCT_TYPES_BY_ID_FAILED = 'GET_PRODUCT_TYPES_BY_ID_FAILED';

// ADMIN CREATE_PRODUCT_TYPES
export const CREATE_PRODUCT_TYPES_REQUEST = 'CREATE_PRODUCT_TYPES_REQUEST';
export const CREATE_PRODUCT_TYPES_SUCCESS = 'CREATE_PRODUCT_TYPES_SUCCESS';
export const CREATE_PRODUCT_TYPES_FAILED = 'CREATE_PRODUCT_TYPES_FAILED';

// ADMIN UPDATE_PRODUCT_TYPES
export const UPDATE_PRODUCT_TYPES_REQUEST = 'UPDATE_PRODUCT_TYPES_REQUEST';
export const UPDATE_PRODUCT_TYPES_SUCCESS = 'UPDATE_PRODUCT_TYPES_SUCCESS';
export const UPDATE_PRODUCT_TYPES_FAILED = 'UPDATE_PRODUCT_TYPES_FAILED';

// ADMIN UPDATE_ORDER_PRODUCT_TYPES
export const UPDATE_ORDER_PRODUCT_TYPES_REQUEST = 'UPDATE_ORDER_PRODUCT_TYPES_REQUEST';
export const UPDATE_ORDER_PRODUCT_TYPES_SUCCESS = 'UPDATE_ORDER_PRODUCT_TYPES_SUCCESS';
export const UPDATE_ORDER_PRODUCT_TYPES_FAILED = 'UPDATE_ORDER_PRODUCT_TYPES_FAILED';

// ADMIN GET_CASE_STUDIES
export const GET_CASE_STUDIES_REQUEST = 'GET_CASE_STUDIES_REQUEST';
export const GET_CASE_STUDIES_SUCCESS = 'GET_CASE_STUDIES_SUCCESS';
export const GET_CASE_STUDIES_FAILED = 'GET_CASE_STUDIES_FAILED';

// ADMIN CREATE_CASE_STUDIES
export const CREATE_CASE_STUDIES_REQUEST = 'CREATE_CASE_STUDIES_REQUEST';
export const CREATE_CASE_STUDIES_SUCCESS = 'CREATE_CASE_STUDIES_SUCCESS';
export const CREATE_CASE_STUDIES_FAILED = 'CREATE_CASE_STUDIES_FAILED';

// ADMIN UPDATE_CASE_STUDIES
export const UPDATE_CASE_STUDIES_REQUEST = 'UPDATE_CASE_STUDIES_REQUEST';
export const UPDATE_CASE_STUDIES_SUCCESS = 'UPDATE_CASE_STUDIES_SUCCESS';
export const UPDATE_CASE_STUDIES_FAILED = 'UPDATE_CASE_STUDIES_FAILED';

// ADMIN UPDATE_ORDER_CASE_STUDIES
export const UPDATE_ORDER_CASE_STUDIES_REQUEST = 'UPDATE_ORDER_CASE_STUDIES_REQUEST';
export const UPDATE_ORDER_CASE_STUDIES_SUCCESS = 'UPDATE_ORDER_CASE_STUDIES_SUCCESS';
export const UPDATE_ORDER_CASE_STUDIES_FAILED = 'UPDATE_ORDER_CASE_STUDIES_FAILED';

// ADMIN UPDATE_ORDER_STORIES
export const UPDATE_ORDER_STORIES_REQUEST = 'UPDATE_ORDER_STORIES_REQUEST';
export const UPDATE_ORDER_STORIES_SUCCESS = 'UPDATE_ORDER_STORIES_SUCCESS';
export const UPDATE_ORDER_STORIES_FAILED = 'UPDATE_ORDER_STORIES_FAILED';

// ADMIN GET_OUR_STORIES
export const GET_OUR_STORIES_REQUEST = 'GET_OUR_STORIES_REQUEST';
export const GET_OUR_STORIES_SUCCESS = 'GET_OUR_STORIES_SUCCESS';
export const GET_OUR_STORIES_FAILED = 'GET_OUR_STORIES_FAILED';

// ADMIN GET_OUR_TEAM_MEMBERS
export const GET_OUR_TEAM_MEMBERS_REQUEST = 'GET_OUR_TEAM_MEMBERS_REQUEST';
export const GET_OUR_TEAM_MEMBERS_SUCCESS = 'GET_OUR_TEAM_MEMBERS_SUCCESS';
export const GET_OUR_TEAM_MEMBERS_FAILED = 'GET_OUR_TEAM_MEMBERS_FAILED';

// ADMIN DELETE_OUR_TEAM_MEMBERS
export const DELETE_OUR_TEAM_MEMBERS_REQUEST = 'DELETE_OUR_TEAM_MEMBERS_REQUEST';
export const DELETE_OUR_TEAM_MEMBERS_SUCCESS = 'DELETE_OUR_TEAM_MEMBERS_SUCCESS';
export const DELETE_OUR_TEAM_MEMBERS_FAILED = 'DELETE_OUR_TEAM_MEMBERS_FAILED';

// ADMIN CREATE_OUR_TEAM_MEMBERS
export const CREATE_OUR_TEAM_MEMBERS_REQUEST = 'CREATE_OUR_TEAM_MEMBERS_REQUEST';
export const CREATE_OUR_TEAM_MEMBERS_SUCCESS = 'CREATE_OUR_TEAM_MEMBERS_SUCCESS';
export const CREATE_OUR_TEAM_MEMBERS_FAILED = 'CREATE_OUR_TEAM_MEMBERS_FAILED';

// ADMIN UPDATE_OUR_TEAM_MEMBERS
export const UPDATE_OUR_TEAM_MEMBERS_REQUEST = 'UPDATE_OUR_TEAM_MEMBERS_REQUEST';
export const UPDATE_OUR_TEAM_MEMBERS_SUCCESS = 'UPDATE_OUR_TEAM_MEMBERS_SUCCESS';
export const UPDATE_OUR_TEAM_MEMBERS_FAILED = 'UPDATE_OUR_TEAM_MEMBERS_FAILED';

// ADMIN UPDATE_ORDER_OUR_TEAM_MEMBERS
export const UPDATE_ORDER_OUR_TEAM_MEMBERS_REQUEST =
  'UPDATE_ORDER_OUR_TEAM_MEMBERS_REQUEST';
export const UPDATE_ORDER_OUR_TEAM_MEMBERS_SUCCESS =
  'UPDATE_ORDER_OUR_TEAM_MEMBERS_SUCCESS';
export const UPDATE_ORDER_OUR_TEAM_MEMBERS_FAILED =
  'UPDATE_ORDER_OUR_TEAM_MEMBERS_FAILED';

// ADMIN GET_OUR_TEAM
export const GET_OUR_TEAM_REQUEST = 'GET_OUR_TEAM_REQUEST';
export const GET_OUR_TEAM_SUCCESS = 'GET_OUR_TEAM_SUCCESS';
export const GET_OUR_TEAM_FAILED = 'GET_OUR_TEAM_FAILED';

// ADMIN UPDATE_OUR_TEAM
export const UPDATE_OUR_TEAM_REQUEST = 'UPDATE_OUR_TEAM_REQUEST';
export const UPDATE_OUR_TEAM_SUCCESS = 'UPDATE_OUR_TEAM_SUCCESS';
export const UPDATE_OUR_TEAM_FAILED = 'UPDATE_OUR_TEAM_FAILED';

// ADMIN GET_EDITORS
export const GET_EDITORS_REQUEST = 'GET_EDITORS_REQUEST';
export const GET_EDITORS_SUCCESS = 'GET_EDITORS_SUCCESS';
export const GET_EDITORS_FAILED = 'GET_EDITORS_FAILED';

// ADMIN LOAD_MORE_EDITORS
export const LOAD_MORE_EDITORS_REQUEST = 'LOAD_MORE_EDITORS_REQUEST';
export const LOAD_MORE_EDITORS_SUCCESS = 'LOAD_MORE_EDITORS_SUCCESS';
export const LOAD_MORE_EDITORS_FAILED = 'LOAD_MORE_EDITORS_FAILED';

// ADMIN GET_EDITORS_DETAIL
export const GET_EDITORS_DETAIL_REQUEST = 'GET_EDITORS_DETAIL_REQUEST';
export const GET_EDITORS_DETAIL_SUCCESS = 'GET_EDITORS_DETAIL_SUCCESS';
export const GET_EDITORS_DETAIL_FAILED = 'GET_EDITORS_DETAIL_FAILED';

// ADMIN SEARCH_EDITORS
export const SEARCH_EDITORS_REQUEST = 'SEARCH_EDITORS_REQUEST';
export const SEARCH_EDITORS_SUCCESS = 'SEARCH_EDITORS_SUCCESS';
export const SEARCH_EDITORS_FAILED = 'SEARCH_EDITORS_FAILED';

// ADMIN UPDATE_EDITORS
export const UPDATE_EDITORS_REQUEST = 'UPDATE_EDITORS_REQUEST';
export const UPDATE_EDITORS_SUCCESS = 'UPDATE_EDITORS_SUCCESS';
export const UPDATE_EDITORS_FAILED = 'UPDATE_EDITORS_FAILED';

// ADMIN SEND INVITATION
export const SEND_INVITATION_EDITORS_REQUEST = 'SEND_INVITATION_EDITORS_REQUEST';
export const SEND_INVITATION_EDITORS_SUCCESS = 'SEND_INVITATION_EDITORS_SUCCESS';
export const SEND_INVITATION_EDITORS_FAILED = 'SEND_INVITATION_EDITORS_FAILED';

// ADMIN RESEND INVITATION
export const RESEND_INVITATION_EDITORS_REQUEST = 'RESEND_INVITATION_EDITORS_REQUEST';
export const RESEND_INVITATION_EDITORS_SUCCESS = 'RESEND_INVITATION_EDITORS_SUCCESS';
export const RESEND_INVITATION_EDITORS_FAILED = 'RESEND_INVITATION_EDITORS_FAILED';

// ADMIN GET_ACTIVITIES
export const GET_ACTIVITIES_REQUEST = 'GET_ACTIVITIES_REQUEST';
export const GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS';
export const GET_ACTIVITIES_FAILED = 'GET_ACTIVITIES_FAILED';

// ADMIN ASSETS MANAGEMENT - GET LIST ASSET
export const LIST_ASSET_REQUEST = 'LIST_ASSET_REQUEST';
export const LIST_ASSET_SUCCESS = 'LIST_ASSET_SUCCESS';
export const LIST_ASSET_FAILED = 'LIST_ASSET_FAILED';

// ADMIN ASSETS MANAGEMENT - ATTACH A LINK
export const ATTACH_LINK_REQUEST = 'ATTACH_LINK_REQUEST';
export const ATTACH_LINK_SUCCESS = 'ATTACH_LINK_SUCCESS';
export const ATTACH_LINK_FAILED = 'ATTACH_LINK_FAILED';

// ADMIN ASSETS MANAGEMENT - UPLOAD FILES
export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILED = 'UPLOAD_FILE_FAILED';

// ADMIN ASSETS MANAGEMENT - CREATE FOLDER
export const CREATE_FOLDER_REQUEST = 'CREATE_FOLDER_REQUEST';
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS';
export const CREATE_FOLDER_FAILED = 'CREATE_FOLDER_FAILED';

// ADMIN ASSETS MANAGEMENT - SIGN URL
export const SIGN_URL_REQUEST = 'SIGN_URL_REQUEST';
export const SIGN_URL_SUCCESS = 'SIGN_URL_SUCCESS';
export const SIGN_URL_FAILED = 'SIGN_URL_FAILED';

// ADMIN ASSETS MANAGEMENT - DELETE RESOURCE
export const DELETE_RESOURCE_REQUEST = 'DELETE_RESOURCE_REQUEST';
export const DELETE_RESOURCE_SUCCESS = 'DELETE_RESOURCE_SUCCESS';
export const DELETE_RESOURCE_FAILED = 'DELETE_RESOURCE_FAILED';

// ADMIN DELETE ALL RESOURCES
export const DELETE_ALL_RESOURCES_REQUEST = 'DELETE_ALL_RESOURCES_REQUEST';
export const DELETE_ALL_RESOURCES_SUCCESS = 'DELETE_ALL_RESOURCES_SUCCESS';
export const DELETE_ALL_RESOURCES_FAILED = 'DELETE_ALL_RESOURCES_FAILED';

// ADMIN ASSETS MANAGEMENT - VIEW IMAGE IN GALLERY
export const GET_GALLERY_FILE_REQUEST = 'GET_GALLERY_FILE_REQUEST';
export const GET_GALLERY_FILE_SUCCESS = 'GET_GALLERY_FILE_SUCCESS';
export const GET_GALLERY_FILE_FAILED = 'GET_GALLERY_FILE_FAILED';

// ADMIN ASSETS MANAGEMENT - UPDATE RESOURCE
export const UPDATE_RESOURCE_REQUEST = 'UPDATE_RESOURCE_REQUEST';
export const UPDATE_RESOURCE_SUCCESS = 'UPDATE_RESOURCE_SUCCESS';
export const UPDATE_RESOURCE_FAILED = 'UPDATE_RESOURCE_FAILED';

// ADMIN ASSETS MANAGEMENT - RENAME_IMAGE_GALLERY
export const RENAME_IMAGE_GALLERY_REQUEST = 'RENAME_IMAGE_GALLERY_REQUEST';
export const RENAME_IMAGE_GALLERY_SUCCESS = 'RENAME_IMAGE_GALLERY_SUCCESS';
export const RENAME_IMAGE_GALLERY_FAILED = 'RENAME_IMAGE_GALLERY_FAILED';

export const RESET_ASSETS = 'RESET_ASSETS';
export const RESET_FILES_AND_LINKS = 'RESET_FILES_AND_LINKS';

// ADMIN GET_TOPICS
export const GET_TOPICS_REQUEST = 'GET_TOPICS_REQUEST';
export const GET_TOPICS_SUCCESS = 'GET_TOPICS_SUCCESS';
export const GET_TOPICS_FAILED = 'GET_TOPICS_FAILED';

// ADMIN GET_TOPIC_BY_ID
export const GET_TOPIC_BY_ID_REQUEST = 'GET_TOPIC_BY_ID_REQUEST';
export const GET_TOPIC_BY_ID_SUCCESS = 'GET_TOPIC_BY_ID_SUCCESS';
export const GET_TOPIC_BY_ID_FAILED = 'GET_TOPIC_BY_ID_FAILED';

// ADMIN GET_FILES_AND_LINKS
export const GET_FILES_AND_LINKS_REQUEST = 'GET_FILES_AND_LINKS_REQUEST';
export const GET_FILES_AND_LINKS_SUCCESS = 'GET_FILES_AND_LINKS_SUCCESS';
export const GET_FILES_AND_LINKS_FAILED = 'GET_FILES_AND_LINKS_FAILED';

// ADMIN UPDATE_FILES_AND_LINKS
export const UPDATE_FILES_AND_LINKS_REQUEST = 'UPDATE_FILES_AND_LINKS_REQUEST';
export const UPDATE_FILES_AND_LINKS_SUCCESS = 'UPDATE_FILES_AND_LINKS_SUCCESS';
export const UPDATE_FILES_AND_LINKS_FAILED = 'UPDATE_FILES_AND_LINKS_FAILED';

// ADMIN MOVE_FILES_TO_ASSETS
export const MOVE_FILES_TO_ASSETS_REQUEST = 'MOVE_FILES_TO_ASSETS_REQUEST';
export const MOVE_FILES_TO_ASSETS_SUCCESS = 'MOVE_FILES_TO_ASSETS_SUCCESS';
export const MOVE_FILES_TO_ASSETS_FAILED = 'MOVE_FILES_TO_ASSETS_FAILED';

// ADMIN CREATE_TOPICS
export const CREATE_TOPICS_REQUEST = 'CREATE_TOPICS_REQUEST';
export const CREATE_TOPICS_SUCCESS = 'CREATE_TOPICS_SUCCESS';
export const CREATE_TOPICS_FAILED = 'CREATE_TOPICS_FAILED';

// ADMIN UPDATE_TOPICS
export const UPDATE_TOPICS_REQUEST = 'UPDATE_TOPICS_REQUEST';
export const UPDATE_TOPICS_SUCCESS = 'UPDATE_TOPICS_SUCCESS';
export const UPDATE_TOPICS_FAILED = 'UPDATE_TOPICS_FAILED';
// CONVERSATION

export const GET_MESSAGE_REQUEST = 'GET_MESSAGE_REQUEST';
export const GET_MESSAGE_SUCCESS = 'GET_MESSAGE_SUCCESS';
export const GET_MESSAGE_FAILED = 'GET_MESSAGE_FAILED';

export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILED = 'SEND_MESSAGE_FAILED';

export const UPLOAD_FILE_AND_LINK_REQUEST = 'UPLOAD_FILE_AND_LINK_REQUEST';
export const UPLOAD_FILE_AND_LINK_SUCCESS = 'UPLOAD_FILE_AND_LINK_SUCCESS';
export const UPLOAD_FILE_AND_LINK_FAILED = 'UPLOAD_FILE_AND_LINK_FAILED';

export const RESET_MESSAGE = 'RESET_MESSAGE';

// END - CONVERSATION

// NOTIFICATION
export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILED = 'GET_NOTIFICATION_FAILED';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';

export const COUNT_NOTIFICATION_REQUEST = 'COUNT_NOTIFICATION_REQUEST';
export const COUNT_NOTIFICATION_SUCCESS = 'COUNT_NOTIFICATION_SUCCESS';
export const COUNT_NOTIFICATION_FAILED = 'COUNT_NOTIFICATION_FAILED';

export const READ_ALL_NOTIFICATION_REQUEST = 'READ_ALL_NOTIFICATION_REQUEST';
export const READ_ALL_NOTIFICATION_SUCCESS = 'READ_ALL_NOTIFICATION_SUCCESS';
export const READ_ALL_NOTIFICATION_FAILED = 'READ_ALL_NOTIFICATION_FAILED';

export const READ_NOTIFICATION_REQUEST = 'READ_NOTIFICATION_REQUEST';
export const READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS';
export const READ_NOTIFICATION_FAILED = 'READ_NOTIFICATION_FAILED';

// END - NOTIFICATION

// ADMIN GET_CUSTOMERS
export const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST';
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_FAILED = 'GET_CUSTOMERS_FAILED';

// ADMIN LOAD_MORE_CUSTOMERS
export const LOAD_MORE_CUSTOMERS_REQUEST = 'LOAD_MORE_CUSTOMERS_REQUEST';
export const LOAD_MORE_CUSTOMERS_SUCCESS = 'LOAD_MORE_CUSTOMERS_SUCCESS';
export const LOAD_MORE_CUSTOMERS_FAILED = 'LOAD_MORE_CUSTOMERS_FAILED';

// ADMIN GET_CUSTOMERS_DETAIL
export const GET_CUSTOMERS_DETAIL_REQUEST = 'GET_CUSTOMERS_DETAIL_REQUEST';
export const GET_CUSTOMERS_DETAIL_SUCCESS = 'GET_CUSTOMERS_DETAIL_SUCCESS';
export const GET_CUSTOMERS_DETAIL_FAILED = 'GET_CUSTOMERS_DETAIL_FAILED';

export const CLEAR_CUSTOMERS_DETAIL = 'CLEAR_CUSTOMERS_DETAIL';

// ADMIN SEARCH_CUSTOMERS
export const SEARCH_CUSTOMERS_REQUEST = 'SEARCH_CUSTOMERS_REQUEST';
export const SEARCH_CUSTOMERS_SUCCESS = 'SEARCH_CUSTOMERS_SUCCESS';
export const SEARCH_CUSTOMERS_FAILED = 'SEARCH_CUSTOMERS_FAILED';

// ADMIN UPDATE_CUSTOMERS (NOW: ONLY COMPANY)
export const UPDATE_CUSTOMERS_REQUEST = 'UPDATE_CUSTOMERS_REQUEST';
export const UPDATE_CUSTOMERS_SUCCESS = 'UPDATE_CUSTOMERS_SUCCESS';
export const UPDATE_CUSTOMERS_FAILED = 'UPDATE_CUSTOMERS_FAILED';

// ADMIN REGISTER_CUSTOMER
export const REGISTER_CUSTOMER_REQUEST = 'REGISTER_CUSTOMER_REQUEST';
export const REGISTER_CUSTOMER_SUCCESS = 'REGISTER_CUSTOMER_SUCCESS';
export const REGISTER_CUSTOMER_FAILED = 'REGISTER_CUSTOMER_FAILED';

// ADMIN UPDATE_STATUS_CUSTOMERS
export const UPDATE_STATUS_CUSTOMERS_REQUEST = 'UPDATE_STATUS_CUSTOMERS_REQUEST';
export const UPDATE_STATUS_CUSTOMERS_SUCCESS = 'UPDATE_STATUS_CUSTOMERS_SUCCESS';
export const UPDATE_STATUS_CUSTOMERS_FAILED = 'UPDATE_STATUS_CUSTOMERS_FAILED';

// ADMIN GET_TEAMS
export const GET_TEAMS_REQUEST = 'GET_TEAMS_REQUEST';
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const GET_TEAMS_FAILED = 'GET_TEAMS_FAILED';

// ADMIN LOAD_MORE_TEAMS
export const LOAD_MORE_TEAMS_REQUEST = 'LOAD_MORE_TEAMS_REQUEST';
export const LOAD_MORE_TEAMS_SUCCESS = 'LOAD_MORE_TEAMS_SUCCESS';
export const LOAD_MORE_TEAMS_FAILED = 'LOAD_MORE_TEAMS_FAILED';

// ADMIN TRANSFER_ADMIN
export const TRANSFER_ADMIN_REQUEST = 'TRANSFER_ADMIN_REQUEST';
export const TRANSFER_ADMIN_SUCCESS = 'TRANSFER_ADMIN_SUCCESS';
export const TRANSFER_ADMIN_FAILED = 'TRANSFER_ADMIN_FAILED';

// ADMIN GET_ADMINS
export const GET_ADMINS_REQUEST = 'GET_ADMINS_REQUEST';
export const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS';
export const GET_ADMINS_FAILED = 'GET_ADMINS_FAILED';

// ADMIN LOAD_MORE_ADMINS
export const LOAD_MORE_ADMINS_REQUEST = 'LOAD_MORE_ADMINS_REQUEST';
export const LOAD_MORE_ADMINS_SUCCESS = 'LOAD_MORE_ADMINS_SUCCESS';
export const LOAD_MORE_ADMINS_FAILED = 'LOAD_MORE_ADMINS_FAILED';

// ADMIN GET_ADMINS_DETAIL
export const GET_ADMINS_DETAIL_REQUEST = 'GET_ADMINS_DETAIL_REQUEST';
export const GET_ADMINS_DETAIL_SUCCESS = 'GET_ADMINS_DETAIL_SUCCESS';
export const GET_ADMINS_DETAIL_FAILED = 'GET_ADMINS_DETAIL_FAILED';

// ADMIN UPDATE_ADMINS
export const UPDATE_ADMINS_REQUEST = 'UPDATE_ADMINS_REQUEST';
export const UPDATE_ADMINS_SUCCESS = 'UPDATE_ADMINS_SUCCESS';
export const UPDATE_ADMINS_FAILED = 'UPDATE_ADMINS_FAILED';

// ADMIN GET_ROLES
export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILED = 'GET_ROLES_FAILED';

// ADMIN REGISTER_ADMIN
export const REGISTER_ADMIN_REQUEST = 'REGISTER_ADMIN_REQUEST';
export const REGISTER_ADMIN_SUCCESS = 'REGISTER_ADMIN_SUCCESS';
export const REGISTER_ADMIN_FAILED = 'REGISTER_ADMIN_FAILED';

// ADMIN GET_YOUTUBE_LINK
export const GET_YOUTUBE_LINK_REQUEST = 'GET_YOUTUBE_LINK_REQUEST';
export const GET_YOUTUBE_LINK_SUCCESS = 'GET_YOUTUBE_LINK_SUCCESS';
export const GET_YOUTUBE_LINK_FAILED = 'GET_YOUTUBE_LINK_FAILED';

// ADMIN CREATE_YOUTUBE_LINK
export const CREATE_YOUTUBE_LINK_REQUEST = 'CREATE_YOUTUBE_LINK_REQUEST';
export const CREATE_YOUTUBE_LINK_SUCCESS = 'CREATE_YOUTUBE_LINK_SUCCESS';
export const CREATE_YOUTUBE_LINK_FAILED = 'CREATE_YOUTUBE_LINK_FAILED';

// ADMIN UPDATE_YOUTUBE_LINK
export const UPDATE_YOUTUBE_LINK_REQUEST = 'UPDATE_YOUTUBE_LINK_REQUEST';
export const UPDATE_YOUTUBE_LINK_SUCCESS = 'UPDATE_YOUTUBE_LINK_SUCCESS';
export const UPDATE_YOUTUBE_LINK_FAILED = 'UPDATE_YOUTUBE_LINK_FAILED';

// ADMIN REMOVE_YOUTUBE_LINK
export const REMOVE_YOUTUBE_LINK_REQUEST = 'REMOVE_YOUTUBE_LINK_REQUEST';
export const REMOVE_YOUTUBE_LINK_SUCCESS = 'REMOVE_YOUTUBE_LINK_SUCCESS';
export const REMOVE_YOUTUBE_LINK_FAILED = 'REMOVE_YOUTUBE_LINK_FAILED';

// GET USER INFORMATION
export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILED = 'GET_USER_INFO_FAILED';

// UPDATE USER INFORMATION
export const UPDATE_USER_INFO_REQUEST = 'UPDATE_USER_INFO_REQUEST';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAILED = 'UPDATE_USER_INFO_FAILED';

// Upload Avatar
export const UPLOAD_AVATAR_REQUEST = 'UPLOAD_AVATAR_REQUEST';
export const UPLOAD_AVATAR_SUCCESS = 'UPLOAD_AVATAR_SUCCESS';
export const UPLOAD_AVATAR_FAILED = 'UPLOAD_AVATAR_FAILED';

// Update User password
export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILED = 'UPDATE_PASSWORD_FAILED';
export const UPDATE_PASSWORD_RESET = 'UPDATE_PASSWORD_RESET';

// Get number of project
export const GET_NUMBER_OF_PROJECTS_REQUEST = 'GET_NUMBER_OF_PROJECTS_REQUEST';
export const GET_NUMBER_OF_PROJECTS_SUCCESS = 'GET_NUMBER_OF_PROJECTS_SUCCESS';
export const GET_NUMBER_OF_PROJECTS_FAILED = 'GET_NUMBER_OF_PROJECTS_FAILED';

// ADMIN SEND_INVITATION_JOIN_TEAM
export const SEND_INVITATION_TEAM_REQUEST = 'SEND_INVITATION_TEAM_REQUEST';
export const SEND_INVITATION_TEAM_SUCCESS = 'SEND_INVITATION_TEAM_SUCCESS';
export const SEND_INVITATION_TEAM_FAILED = 'SEND_INVITATION_TEAM_FAILED';

// ADMIN RESEND_INVITATION_JOIN_TEAM
export const RESEND_INVITATION_TEAM_REQUEST = 'RESEND_INVITATION_TEAM_REQUEST';
export const RESEND_INVITATION_TEAM_SUCCESS = 'RESEND_INVITATION_TEAM_SUCCESS';
export const RESEND_INVITATION_TEAM_FAILED = 'RESEND_INVITATION_TEAM_FAILED';

// GET_INVITATIONS
export const GET_INVITATION_REQUEST = 'GET_INVITATION_REQUEST';
export const GET_INVITATION_SUCCESS = 'GET_INVITATION_SUCCESS';
export const GET_INVITATION_FAILED = 'GET_INVITATION_FAILED';

// ADMIN GET TEAM DETAIL
export const GET_TEAM_DETAIL_REQUEST = 'GET_TEAM_DETAIL_REQUEST';
export const GET_TEAM_DETAIL_SUCCESS = 'GET_TEAM_DETAIL_SUCCESS';
export const GET_TEAM_DETAIL_FAILED = 'GET_TEAM_DETAIL_FAILED';

// SEND_EMAIL
export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILED = 'SEND_EMAIL_FAILED';

// RESET_PASSWORD
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

// RESEND_EMAIL
export const RESEND_EMAIL_REQUEST = 'RESEND_EMAIL_REQUEST';
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_FAILED = 'RESEND_EMAIL_FAILED';
