import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Table, Icon } from 'antd';
import { formatBytes, encodeURIThumbnail, isDeactiveUser, getS3CDN } from 'src/core/utils/commonUtils';
import { getListAssets } from 'src/core/services/modules/assetService';
import FileExtentions from 'src/components/common/fileExtentions';
import UserAvatar from 'src/components/common/userAvatar';

export default function CreativePayment(props) {
  const { project } = props;
  const [isLoading, setLoading] = useState(false)
  const [files, setFiles] = useState(null);

  useEffect(() => {
    if (files) {
      return;
    }

    const fetchList = async () => {
      setLoading(true);
      const params = {
        projectId: project._id,
        status: true,
        isAsset: true,
        isDocument: true,
      }
      const { data: httpResp } = await getListAssets(params);
      if (httpResp && httpResp.data) {
        setFiles(httpResp.data.docs);
      }
      setLoading(false);
    };

    fetchList();
  }, [files, project._id]);

  const getDownloadPath = (item) => {
    return item.cdnSignedUrl ? item.cdnSignedUrl : item.path;
  };

  const formatColumns = [
    {
      title: '',
      dataIndex: '',
      // width: 50,
      render: (title, record) => {
        const filePath = encodeURIThumbnail(record.pathThumbnail, record.path);

        return (
          <FileExtentions
            fileName={record.isLink ? 'link' : filePath}
            path={record.path}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: 'title',
      // width: 300,
      render: (title, record) => {
        return <span className="asset-title">{record.customTitle || title}</span>;
      },
    },
    {
      title: '',
      dataIndex: 'createdBy',
      className: 'assets-avatar',
      // width: 50,
      render: (createdBy, record) => {
        return (
          <UserAvatar
            fullName={record.fullName}
            avatar={record.avatar}
            status={!isDeactiveUser(record.createdBy)}
            size="small"
          />
        );
      },
    },
    {
      title: '',
      dataIndex: 'createdDate',
      // width: 150,
    },
    {
      title: '',
      dataIndex: 'size',
      // width: 130,
    },
    {
      title: '',
      dataIndex: 'status',
      // width: 50,
      render: (status, record) => {
        return (
          <a href={getS3CDN(getDownloadPath(record))} download={record.title}>
            <Icon type="download" className="icon-download" />
          </a>
        );
      },
    },
  ];

  const formatData = (dataAssets = []) => {
    return dataAssets.map(data => ({
      key: data._id,
      title: data.name,
      createdDate: moment(data.createdAt).format('MMM DD, YYYY'),
      size: data.fileSize ? formatBytes(data.fileSize) : '-',
      status: data.isPublic,
      isGallery: data.isGallery,
      path: data.path,
      isLink: data.isLink,
      files: [],
      assetId: data._id,
      isAsset: data.isAsset,
      _id: data._id,
      isMessage: data.isMessage,
      topicId: data.topicId,
      isStoryBoard: data.isStoryBoard,
      pathThumbnail: data.pathThumbnail,
      filePath: data.filePath,
      cdnSignedUrl: data.cdnSignedUrl,
      customTitle: data.customTitle,
      ...(data.createdBy || {})
    }));
  };

  return (
    <Table
      columns={formatColumns}
      loading={isLoading}
      dataSource={formatData(files || [])}
      pagination={false}
    />
  );
};
