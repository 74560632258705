import { UPDATE_ORDER_STORIES_SUCCESS, GET_OUR_STORIES_REQUEST } from '../typeAction';

export const getOurStories = () => ({
	type: GET_OUR_STORIES_REQUEST
});

export const updateOrder = data => ({
	type: UPDATE_ORDER_STORIES_SUCCESS,
	payload: data
});
