import React, { forwardRef, memo } from 'react';
import WaveHandIcon from "src/assets/images/icons/topic/wave-hand.svg";

const GreetingPlaceholder = (props, ref) => {
  return (
    <div
      className="text-center"
      style={{ fontStyle: 'oblique', marginTop: '10vh' }}
    >
      <img src={WaveHandIcon} alt="" />
      <div
        style={{
          fontSize: '20px',
          fontWeight: 'bold',
          margin: '10px 0 0 0',
          color: '#000000',
        }}
      >
        Hi!
      </div>
      <div
        style={{
          fontSize: '10px',
          color: '#979797',
          fontWeight: '500',
        }}
      >
        Send an instant message to your production team at VidOps about
        anything and everything to do with your project...
      </div>
    </div>
  )
}

export default memo(forwardRef(GreetingPlaceholder))
