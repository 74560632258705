import queryString from 'query-string';
import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const getTopicsService = payload => {
  const url = BaseConfig.END_POINT.ADMIN.TOPICS;
  const response = httpService._get(
    `${url}?projectId=${payload.id}&page=${payload.page}&limit=${payload.limit}`
  );
  return response;
};

export const createTopicService = data => {
  const url = BaseConfig.END_POINT.ADMIN.TOPICS;
  const response = httpService._post(url, data);
  return response;
};

export const sendQuoteService = data => {
  const url = BaseConfig.END_POINT.ADMIN.PROJECTS;
  const response = httpService._post(`${url}/${data.projectId}/topics`, data);
  return response;
};

export const updateTopicService = ({ id, ...rest }) => {
  const url = BaseConfig.END_POINT.ADMIN.TOPICS;
  const response = httpService._put(`${url}/${id}`, rest);
  return response;
};

export const getTopicByIdService = id => {
  const url = BaseConfig.END_POINT.ADMIN.TOPICS;
  const response = httpService._get(`${url}/${id}`);
  return response;
};

export const getTopicActivities = (topicId, params) => {
  const queryParams = queryString.stringify(params);
  const url = BaseConfig.END_POINT.ADMIN.TOPICS;
  const response = httpService._get(`${url}/${topicId}/activities?${queryParams}`);
  return response;
};
