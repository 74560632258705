/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Upload, message, Button } from 'antd';
import {
  checkYoutubeLink,
  dummyRequest,
  getS3CDN,
} from '../../../../core/utils/commonUtils';
import { useDispatch } from 'react-redux';
import { createYoutubeLinks } from 'src/redux/actions/admin/emailTemplateAction.js';
import { httpService } from 'src/core/services/httpService.js';
import { getEnv } from 'src/core/configs/envConfig.js';
import { getCurrentUser } from 'src/redux/actions/admin/userAction';
import { updateYoutubeLink } from 'src/redux/actions/admin/emailTemplateAction';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error('Image must smaller than 5MB!');
  }
  return isJpgOrPng && isLt2M;
}

const AddYoutubeLink = ({ visible, handleCancel, detail }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(detail.thumbnail);
  const [youtubeUrl, setYoutubeUrl] = useState(detail.url);
  const [checkValid, setCheckValid] = useState(true);
  const [file, setFile] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setImageUrl(getS3CDN(detail.thumbnail));
    setYoutubeUrl(detail.url);
  }, [detail]);

  const handleChange = info => {
    if (info.file.status === 'uploading') {
      setTimeout(() => {
        getBase64(info.file.originFileObj, imageUrl => {
          setLoading(false);
          setImageUrl(imageUrl);
          setFile(info.file.originFileObj);
        });
      }, 300);
    }
  };

  const handleOk = async () => {
    if (!checkYoutubeLink(youtubeUrl)) return setCheckValid(false);
    setLoading(true);
    if (!file) {
      handleCancel();
      setImageUrl(null);
      setLoading(false);

      if (detail._id) {
        return dispatch(
          updateYoutubeLink({
            url: youtubeUrl,
            id: detail._id,
          })
        );
      } else {
        return dispatch(
          createYoutubeLinks({
            url: youtubeUrl,
          })
        );
      }
    }

    try {
      const url = getEnv('API_SERVER') + '/api/youtube-email/thumbnail/presigned-url';
      const res = await httpService._post(url, {
        photo: file.name,
        contentType: file.type,
      });

      const { signedUrl, filePath } = res.data.data;

      await httpService._uploadPresignUrl({
        signedUrl,
        filename: file.name,
        file,
      });

      if (detail._id) {
        dispatch(
          updateYoutubeLink({
            id: detail._id,
            url: youtubeUrl,
            thumbnail: filePath,
          })
        );
      } else {
        dispatch(
          createYoutubeLinks({
            url: youtubeUrl,
            thumbnail: filePath,
          })
        );
      }

      setImageUrl(null);
      setLoading(false);
      handleCancel();
    } catch (error) {
      console.error(error);
    }
  };
  const onChangeLink = e => {
    setCheckValid(true);
    setYoutubeUrl(e.target.value);
  };

  const validateLink = () => {
    if (checkValid) return '';
    if (!youtubeUrl) return 'Youtube link is required';
    if (!checkYoutubeLink(youtubeUrl)) return 'Youtube link is invalid';
  };

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);
  return (
    <Modal title={null} visible={visible} footer={null} onCancel={handleCancel}>
      <Form.Item name="" label="Youtube url:" rules={[{ required: true }]}>
        <Input
          onChange={onChangeLink}
          value={youtubeUrl}
          onBlur={() => setCheckValid(checkYoutubeLink(youtubeUrl))}
        />
        <p className="text-error">{validateLink()}</p>
      </Form.Item>
      <Form.Item name="" label="Youtube thumbnail:" rules={[{ required: true }]}>
        <Upload
          name="avatar"
          listType="picture-card"
          className="email-thumb-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          customRequest={dummyRequest}
          accept=".jpeg, .png"
        >
          {imageUrl ? (
            <img className="img-temp" src={imageUrl} alt="avatar" />
          ) : (
            <p>Upload</p>
          )}
        </Upload>
      </Form.Item>
      <div className="d-flex justify-content-end">
        <Button onClick={handleCancel} className="mr-1">
          Cancel
        </Button>
        <Button
          loading={loading}
          onClick={handleOk}
          disabled={!checkYoutubeLink(youtubeUrl)}
          className={!checkYoutubeLink(youtubeUrl) ? 'btn-cancel' : 'ant-btn-primary'}
        >
          OK
        </Button>
      </div>
    </Modal>
  );
};

export default AddYoutubeLink;
