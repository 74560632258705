import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';
import qs from '../../../core/utils/querystring'

export const getProductTypesService = (params = {}) => {
	const queryString = qs.stringify(JSON.parse(JSON.stringify(params)));
	const url = BaseConfig.END_POINT.ADMIN.PRODUCT_TYPES;
	const response = httpService._get(`${url}?${queryString}`);
	return response;
};

export const createProductTypesService = data => {
	const url = BaseConfig.END_POINT.ADMIN.PRODUCT_TYPES;
	const response = httpService._post(url, data);
	return response;
};

export const updateProductTypesService = ({ data, id }) => {
	const url = BaseConfig.END_POINT.ADMIN.PRODUCT_TYPES;
	const response = httpService._put(`${url}/${id}`, data);
	return response;
};
