import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Empty, Button } from 'antd';
import { updateProjects } from 'src/redux/actions/admin/projectsAction';
import ProductionDate from './item';
// import ProductionDateForm from './form';
import ProductionDateForm from '../../projectCreateForm/productionDatesForm/form';

import './style.scss';

/**
 * @typedef {{
 *   _id: string
 *   startDate: string
 *   duration: number
 *   address: Object
 *   contact: Object
 *   creatives: string[]
 * }} ProductionDate
 */

/**
 * @param {{
 *   project: {
 *     _id: string
 *     productionDates: ProductionDate[]
 *   }
 * }} props
 */
export default function ProductionDates(props) {
  const { project } = props;
  const { productionDates } = project;
  const dispatch = useDispatch();
  const [editItem, setEditItem] = useState(null);

  const handleUpdate = updatePayload => {
    dispatch(
      updateProjects({
        id: project._id,
        productionDates: updatePayload,
      })
    );
  };

  const handleEdit = (item, order) => {
    if (!item) {
      setEditItem({ order: null });
      return;
    }

    const patchedItem = {
      ...item,
      address: item.address || null,
      addressNote: item.addressNote || null,
      contact: item.contact || {
        fullName: null,
        email: null,
        phoneNumber: null,
        note: null,
      },
    };
    // Previous version doesn't contain `creatives` in each production date,
    // now, we need to retain project-level creatives if edit them.
    if (item.creatives == null || !item.creatives.length) {
      patchedItem.creatives = (project.creativeId || []).map(creative => creative.id._id);
    }

    setEditItem({ ...patchedItem, order });
  };

  const handleRemove = (item, index) => {
    const updatePayload = [...productionDates].map((date, idx) => {
      if (date._id === item._id || index === idx) {
        return {
          ...date,
          isDelete: true,
        };
      }
      return date;
    });

    handleUpdate(updatePayload);
  };

  const handleClose = data => {
    if (!data) {
      setEditItem(null);
      return;
    }
    const updatePayload = [...productionDates];
    const { order } = editItem;
    const index = order - 1;
    console.log(index);
    if (!order || index < 0) {
      updatePayload.push({ ...data, isChange: true });
    } else {
      updatePayload.splice(index, 1, { ...data, isChange: true });
    }
    handleUpdate(updatePayload);
    setEditItem(null);
  };

  return (
    <>
      <Button className="mb-3" type="primary" onClick={() => handleEdit()}>
        Add production date
      </Button>
      {!productionDates.length && (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No shooting dates" />
      )}
      {!!productionDates.length &&
        productionDates.map((detail, idx) => (
          <ProductionDate
            key={detail._id || idx}
            project={project}
            detail={detail}
            order={idx + 1}
            onEdit={() => handleEdit(detail, idx + 1)}
            onRemove={() => handleRemove(detail, idx)}
          />
        ))}
      {!!editItem && (
        <ProductionDateForm
          detail={editItem}
          visible={!!editItem}
          onClose={handleClose}
        />
      )}
    </>
  );
}
