import React from 'react';
import 'antd/dist/antd.css';
import './dataTable.scss';
import { Table } from 'antd';

class DataTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleDelete = key => {
		const dataSource = [...this.state.dataSource];
		this.setState({
			dataSource: dataSource.filter(item => item.key !== key)
		});
	};

	handleSave = row => {
		const newData = [...this.state.dataSource];
		const index = newData.findIndex(item => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, {
			...item,
			...row
		});
		this.setState({ dataSource: newData });
	};

	render() {
		const { dataSource, columns } = this.props;
		return (
			<div>
				<Table
					rowClassName={() => 'editable-row'}
					bordered
					dataSource={dataSource}
					columns={columns}
					pagination={false}
				/>
			</div>
		);
	}
}

export default DataTable;
