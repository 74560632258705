import {
	GET_CREATIVES_REQUEST,
	GET_CREATIVES_DETAIL_REQUEST,
	SEARCH_CREATIVES_REQUEST,
	LOAD_MORE_CREATIVES_REQUEST,
	SEND_INVITATION_CREATIVES_REQUEST,
	UPDATE_CREATIVES_REQUEST,
	RESEND_INVITATION_CREATIVES_REQUEST,
	CLEAR_CREATIVES_DETAIL
} from '../typeAction';

export const getCreatives = data => ({
	type: GET_CREATIVES_REQUEST,
	payload: data
});

export const loadMoreCreatives = data => ({
	type: LOAD_MORE_CREATIVES_REQUEST,
	payload: data
});

export const getCreativesDetail = id => ({
	type: GET_CREATIVES_DETAIL_REQUEST,
	payload: id
});

export const clearCreativesDetail = () => ({
	type: CLEAR_CREATIVES_DETAIL
});

export const searchCreatives = data => ({
	type: SEARCH_CREATIVES_REQUEST,
	payload: data
});

export const updateCreatives = data => ({
	type: UPDATE_CREATIVES_REQUEST,
	payload: data
});

export const sendInvitation = data => ({
	type: SEND_INVITATION_CREATIVES_REQUEST,
	payload: data
});

export const resendInvitation = data => ({
	type: RESEND_INVITATION_CREATIVES_REQUEST,
	payload: data
});
