import React from 'react';

import { withTranslation } from 'react-i18next';
/* Components */
import AppRoute from './routes';
import Header from '../components/layouts/header/header';
import Sidebar from '../components/layouts/sidebar/sidebar';
import '../assets/stylesheets/admin.scss';
import 'react-image-lightbox/style.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Welcome',
    };
  }

  render() {
    return (
      <div className="d-flex" id="admin-wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <Header />
          <div className="container-fluid">
            <AppRoute />
          </div>
        </div>
      </div>
    );
  }
}

const AppWithTranslation = withTranslation('common')(App);

export default AppWithTranslation;
