import {
	GET_ITEMS_REQUEST,
	GET_ITEMS_SUCCESS,
	GET_ITEMS_FAILED,
	CREATE_ITEMS_REQUEST,
	CREATE_ITEMS_SUCCESS,
	CREATE_ITEMS_FAILED,
	UPDATE_ITEMS_REQUEST,
	UPDATE_ITEMS_SUCCESS,
	UPDATE_ITEMS_FAILED,
	UPDATE_ORDER_ITEMS_SUCCESS,
	UPDATE_ORDER_ITEMS_FAILED
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';

const defaultState = {
	success: false,
	error: null,
	data: [],
	isLoading: false
};

const itemsReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_ITEMS_REQUEST: {
			return {
				...state
			};
		}
		case GET_ITEMS_SUCCESS: {
			return {
				...state,
				...action.payload
			};
		}
		case GET_ITEMS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				error: action.payload
			};
		}
		case CREATE_ITEMS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case CREATE_ITEMS_SUCCESS: {
			openNotification('success', 'Create successfully');
			return {
				...state,
				isLoading: false,
				data: action.payload.data
			};
		}
		case CREATE_ITEMS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_ITEMS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case UPDATE_ITEMS_SUCCESS: {
			openNotification('success', 'Update successful');
			return {
				...state,
				isLoading: false,
				data: action.payload.data
			};
		}
		case UPDATE_ITEMS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_ORDER_ITEMS_SUCCESS: {
			return {
				...state,
				isLoading: false,
				data: action.payload.data
			};
		}
		case UPDATE_ORDER_ITEMS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		default:
			return state;
	}
};

export default itemsReducer;
