import React from 'react';
import { Tag, Modal } from 'antd';
import IconGoogleDrive from 'src/assets/images/google-login/icons8-google-drive.svg';
import {
  // oauthGoogleDrive,
  verifyTokenDrive,
  logoutDrive,
} from 'src/core/services/modules/googleDriveService';
import { BaseConfig } from 'src/core/configs/baseConfig';
import './styles.scss';

const { confirm } = Modal;
export default class GoogleDriveAuth extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: null,
      info: {},
    };
    this.externalWindow = null;
    this.codeCheck = null;
  }

  componentDidMount() {
    this.verifyToken();
  }

  componentWillUnmount() {
    if (this.externalWindow) {
      this.externalWindow.close();
    }
  }

  verifyToken = async () => {
    try {
      const { data: result } = await verifyTokenDrive();
      this.setState({
        accessToken: result.data.accessToken,
        info: result.data.info,
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  login = () => {
    const url = BaseConfig.END_POINT.OAUTH.GOOGLE_DRIVE;
    this.externalWindow = window.open(url);

    this.codeCheck = setInterval(() => {
      try {
        const params = new URL(this.externalWindow.location).searchParams;
        const code = params.get('response');
        if (!code) return;
        clearInterval(this.codeCheck);
        this.externalWindow.close();
        window.location.reload();
      } catch (err) {
        console.log('err', err);
      }
    }, 100);

    this.externalWindow.onbeforeunload = () => clearInterval(this.codeCheck);
  };

  logout = async () => {
    try {
      confirm({
        title: 'Disconnect Google Drive?',
        content: 'Are you sure you want to disconnect to Google Drive?',
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: async () => {
          const result = await logoutDrive();
          if (result.data) {
            this.setState({
              accessToken: null,
              info: {},
            });
          }
        },
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  renderLogin = () => {
    return (
      <button
        className="mt-3 button-drive-login d-flex align-items-center justify-content-center"
        onClick={() => this.login()}
      >
        <img src={IconGoogleDrive} alt='' /> Login with Google Drive
      </button>
    );
  };

  renderLogout = () => {
    const { info } = this.state;
    return (
      <>
        <Tag color="green">Authenticated as {info.email}</Tag>
        <button
          className="mt-3 button-drive-logout d-flex align-items-center justify-content-center"
          onClick={() => this.logout()}
        >
          <span>
            <img src={IconGoogleDrive} alt='' />
            Disconnect with Google Drive
          </span>
        </button>
      </>
    );
  };

  render() {
    const { accessToken } = this.state;
    return (
      <div className="google-drive mt-2">
        {!accessToken ? this.renderLogin() : this.renderLogout()}
      </div>
    );
  }
}
