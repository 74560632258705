import { connect } from 'react-redux';
import UnitsScreen from '../../../components/screens/admin/units/unitsScreen';
import {
	getUnits,
	createUnits,
	updateUnits,
	updateOrder
} from '../../../redux/actions/admin/unitsAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';

const mapStateToProps = state => {
	return {
		success: state.units.success,
		error: state.units.error,
		isLoading: state.units.isLoading,
		data: state.units.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetUnits: () => {
			dispatch(getUnits());
		},
		onCreateUnits: data => {
			dispatch(createUnits(data));
		},
		onUpdateUnits: ({ data, id }) => {
			dispatch(updateUnits({ data, id }));
		},
		onUpdateOrder: data => {
			dispatch(updateOrder(data));
		},
		onGetCurrentUser: () => {
			dispatch(getCurrentUser());
		}
	};
};

const UnitsContainer = connect(mapStateToProps, mapDispatchToProps)(UnitsScreen);

export default UnitsContainer;
