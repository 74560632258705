import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';
import qs from '../../../core/utils/querystring'

export const getServiceTypeService = (params = {}) => {
	const queryString = qs.stringify(JSON.parse(JSON.stringify(params)));
    const url = BaseConfig.END_POINT.ADMIN.SERVICE_TYPE;
    const response = httpService._get(`${url}?${queryString}`);
    return response;
};

export const getServiceTypeByIdProductType = (id) => {
    const url = BaseConfig.END_POINT.ADMIN.SERVICE_TYPE;
    const response = httpService._get(`${url}/product-types/${id}`);
    return response;
};

export const getServiceTypeByIdService = id => {
	const url = BaseConfig.END_POINT.ADMIN.SERVICE_TYPE;
	const response = httpService._get(`${url}/${id}`);
	return response;
};

export const createServiceTypeService = data => {
	const url = BaseConfig.END_POINT.ADMIN.SERVICE_TYPE;
	const response = httpService._post(url, data);
	return response;
};

export const updateServiceTypeService = ({ data, id }) => {
	const url = BaseConfig.END_POINT.ADMIN.SERVICE_TYPE;
	const response = httpService._put(`${url}/${id}`, data);
	return response;
};