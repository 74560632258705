import axios from 'axios';
import { requestService } from './requestService';
import { storageService } from '../../core/services/storageService';
import { notification } from 'antd';
import { BaseConfig } from 'src/core/configs/baseConfig';
import { sendLogoutAction } from 'src/core/utils/event_emitter/handler';

function openNotificationWithIcon(type, message, content) {
  notification[type]({
    message: message,
    description: content,
    duration: 60,
  });
}

class HttpService {
  constructor() {
    axios.interceptors.response.use(
      response => {
        const responseCode = response.data.error && response.data.error.code;
        if (responseCode && responseCode === 1020) {
          // return resetTokenAndReattemptRequest(response);
          sendLogoutAction();
        }
        // If the request succeeds, we don't have to do anything and just return the response
        return response;
      },
      error => {
        const { data, status } = error.response;
        const { error: errorResponse } = data;
        const { code } = errorResponse;

        if (code === 1011 || code === 1020 || code === 1021 || status === 401) {
          sendLogoutAction();
          return error.response;
        }
        return Promise.reject(error);
      }
    );
  }

  _get(endpoint, contentType) {
    return new Promise(async (resolve, reject) => {
      axios
        .get(endpoint, await requestService.getAuthHeaders(contentType))
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(this.handleError(error));
        });
    });
  }

  _post(endpoint, _data, contentType) {
    return new Promise(async (resolve, reject) => {
      axios
        .post(endpoint, _data, await requestService.getAuthHeaders(contentType))
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(this.handleError(error));
        });
    });
  }

  _put(endpoint, _data, contentType) {
    return new Promise(async (resolve, reject) => {
      axios
        .put(endpoint, _data, await requestService.getAuthHeaders(contentType))
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(this.handleError(error));
        });
    });
  }

  _delete(endpoint, contentType) {
    return new Promise(async (resolve, reject) => {
      axios
        .delete(endpoint, await requestService.getAuthHeaders(contentType))
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(this.handleError(error));
        });
    });
  }

  _uploadPresignUrl = async ({ signedUrl, file, filename }) => {
    await axios.put(signedUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });

    return filename;
  };

  async _upload({ photos, typeUpload }) {
    const presignFiles = photos.map(photo => {
      const { file, filename, path, type } = photo;
      return {
        filename: filename,
        path,
        contentType: file.type,
        type,
      };
    });

    const getPresignedUrl = async () => {
      const url =
        typeUpload === 'avatar'
          ? BaseConfig.END_POINT.S3.GET_SIGNED_URL
          : BaseConfig.END_POINT.S3.SIGNED_URL;
      const { data: response } = await this._post(
        url,
        {
          photos: presignFiles,
        },
        'json'
      );

      return response.data;
    };

    const presignedUrls = await getPresignedUrl();
    const promises = presignedUrls.map(({ signedUrl, filePath }, index) => {
      const { file } = photos[index];

      return this._uploadPresignUrl({ signedUrl, filename: filePath, file });
    });

    return await Promise.all(promises);
  }

  handleError(error) {
    if (
      error &&
      ((error.data &&
        error.data.error &&
        error.data.error.code &&
        error.data.error.code === 1024) ||
        (error.code && error.code === 1020))
    ) {
      openNotificationWithIcon(
        'error',
        'Your session is expired, please login again',
        ''
      );
      storageService.removeAuthToken();
      storageService.removeCurrentUser();
      window.location.href = '/login';
    }
  }
}

export const httpService = new HttpService();
