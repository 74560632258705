import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Jumbotron, Container } from 'react-bootstrap';
import './serviceTypeScreen.scss';
import DataTable from '../../../common/dragTable/dragTable';
import ModalData from '../../../common/newModal/newModal';
import ServiceTypeForm from '../../../common/form/serviceTypeForm/serviceTypeForm';
import { ORDER_KEYS } from '../../../../core/constants/constants';

class ServiceTypeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Admin ServiceType - VidOps',
      data: [],
      key: '',
      itemUpdate: null,
      isModalOpen: false,
      isCreate: false,
    };
  }

  updateBannerHandler = updateData => {
    this.props.onUpdateBanner(updateData);
  };

  componentDidMount() {
    this.props.onGetCurrentUser();
    this.props.onGetProductTypes();
    this.props.onGetServiceType();
  }

  handleDelete = key => {
    this.props.onDeleteServiceType(key);
  };

  handleUpdate = key => {
    const data = this.formatData(this.props.data);
    if (data) {
      this.setState({
        itemUpdate: data.find(item => item._id === key),
      });
    }
    this.setState({
      isModalOpen: true,
      isCreate: false,
      key,
    });
  };

  formatColumns = () => {
    const { productTypes } = this.props;
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        width: '30%',
      },
      {
        title: 'Product Type',
        dataIndex: 'productTypeId[name]',
        filters:
          productTypes && productTypes.length
            ? productTypes.map(item => ({ text: item.name, value: item.name }))
            : [],
        onFilter: (value, record) => record.productTypeId.name.indexOf(value) === 0,
      },
      {
        title: 'Status',
        dataIndex: 'statusText',
        filters: [
          {
            text: 'Active',
            value: 'Active',
          },
          {
            text: 'Inactive',
            value: 'Inactive',
          },
        ],
        onFilter: (value, record) => record.statusText.indexOf(value) === 0,
      },
      {
        title: 'Operation',
        dataIndex: 'operation',
        render: (text, record) => {
          return (
            <div className="operation-container">
              <Button
                onClick={() => this.handleUpdate(record.key)}
                type="primary"
                className="btn-primary-0"
                icon="edit"
              ></Button>
            </div>
          );
        },
      },
    ];
  };

  handleAdd = () => {
    this.setState({
      isModalOpen: true,
      isCreate: true,
    });
  };

  createHandler = data => {
    this.props.onCreateServiceType(data);
  };

  updateHandler = data => {
    const payload = {
      data,
      id: this.state.key,
    };
    this.props.onUpdateServiceType(payload);
  };

  closeModalHandler = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  formatData = data => {
    if (data && data.length) {
      return data.map((item, index) => {
        if (item.status) {
          return {
            ...item,
            key: item._id,
            statusText: 'Active',
          };
        }
        return {
          ...item,
          key: item._id,
          statusText: 'Inactive',
        };
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        isModalOpen: false,
      });
    }
  }

  updateOrderHandler = orderData => {
    const updateOrderData = {
      key: ORDER_KEYS.SERVICE_TYPE,
      ...orderData,
    };
    this.props.onUpdateOrder(updateOrderData);
  };

  render() {
    const { data, isLoading, productTypes } = this.props;
    const { isModalOpen, isCreate, key, itemUpdate } = this.state;
    const columns = this.formatColumns();
    const dataSource = this.formatData(data);
    return (
      <main role="main" className="page-content serviceType-screen">
        <Jumbotron className="mgt-40">
          <h2 className="sub-heading">Service Type</h2>
          <p className="description">Manage Service Type</p>
          <div className="divider"></div>
          <Container>
            <Button
              className="btn-primary-0"
              onClick={this.handleAdd}
              type="primary"
              style={{ marginBottom: 16 }}
            >
              Add new
            </Button>
            <DataTable
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              onUpdateOrder={this.updateOrderHandler}
            />
            <ModalData
              className="serviceType-modal"
              title={isCreate ? 'Create Service Type' : 'Update Service Type'}
              visible={isModalOpen}
              onCloseModal={this.closeModalHandler}
              dataSource={
                <ServiceTypeForm
                  onCreateServiceType={this.createHandler}
                  onUpdateServiceType={this.updateHandler}
                  isLoading={isLoading}
                  isCreate={isCreate}
                  itemUpdate={itemUpdate}
                  key={key}
                  productTypes={productTypes}
                  serviceTypes={data}
                />
              }
              footer={null}
            />
          </Container>
        </Jumbotron>
      </main>
    );
  }
}

export default withTranslation('common')(ServiceTypeScreen);
