import { put, call, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../../actions/typeAction';
import {
  getNotification,
  countNotification,
  readNotification,
  readAllNotifications,
} from 'src/core/services/modules/notificationService';

export function* getNotificationTask(action) {
  try {
    const { page, limit } = action.payload;
    const response = yield call(getNotification, page, limit);
    if (response.status === 200 && response.data.success) {
      yield put({
        type: actionTypes.GET_NOTIFICATION_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_NOTIFICATION_FAILED,
        payload: response.data.error,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.GET_NOTIFICATION_FAILED,
      payload: err,
    });
  }
}

export function* readNotificationTask(action) {
  try {
    const id = action.payload;
    const response = yield call(readNotification, id);
    if (response.status === 200 && response.data.success) {
      yield put({
        type: actionTypes.READ_NOTIFICATION_SUCCESS,
        payload: { notificationId: id },
      });
    } else {
      yield put({
        type: actionTypes.READ_NOTIFICATION_FAILED,
        payload: response.data.error,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.READ_NOTIFICATION_FAILED,
      payload: err,
    });
  }
}

function* readAllNotificationsTask() {
  try {
    const response = yield call(readAllNotifications);
    if (response.status === 200 && response.data.success) {
      yield put({
        type: actionTypes.READ_ALL_NOTIFICATION_SUCCESS,
      });
    } else {
      yield put({
        type: actionTypes.READ_ALL_NOTIFICATION_FAILED,
        payload: response.data.error,
      });
    }
  } catch (e) {
    yield put({
      type: actionTypes.READ_ALL_NOTIFICATION_FAILED,
      payload: e,
    });
  }
}

export function* countNotificationTask(action) {
  try {
    const response = yield call(countNotification);
    if (response.status === 200 && response.data.success) {
      yield put({
        type: actionTypes.COUNT_NOTIFICATION_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: actionTypes.COUNT_NOTIFICATION_FAILED,
        payload: response.data.error,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.COUNT_NOTIFICATION_FAILED,
      payload: err,
    });
  }
}

export function* watchNotification() {
  yield takeLatest(actionTypes.GET_NOTIFICATION_REQUEST, getNotificationTask);
  yield takeLatest(actionTypes.COUNT_NOTIFICATION_REQUEST, countNotificationTask);
  yield takeLatest(actionTypes.READ_NOTIFICATION_REQUEST, readNotificationTask);
  yield takeLatest(actionTypes.READ_ALL_NOTIFICATION_REQUEST, readAllNotificationsTask);
}
