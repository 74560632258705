import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const getAdminsService = payload => {
  const url = BaseConfig.END_POINT.ADMIN.ADMINS;
  const params = new URLSearchParams('?');
  if (payload.page != null) {
    params.set('page', payload.page)
  }
  if (payload.limit != null) {
    params.set('limit', payload.limit)
  }
  if (payload.status != null) {
    params.set('status', payload.status)
  }
  const response = httpService._get(
    `${url}?${params.toString()}`
  );
  return response;
};

export const getAdminDetailService = id => {
  const url = BaseConfig.END_POINT.ADMIN.ADMINS;
  const response = httpService._get(`${url}/${id}`);
  return response;
};

export const updateAdminService = ({ id, ...rest }) => {
  const url = BaseConfig.END_POINT.ADMIN.ADMINS;
  const response = httpService._put(`${url}/${id}`, rest);
  return response;
};

export const registerAdminService = data => {
  const url = BaseConfig.END_POINT.ADMIN.ADMINS;
  const response = httpService._post(`${url}`, data);
  return response;
};

export const deleteAdminService = ({ id, ...rest }) => {
  const url = BaseConfig.END_POINT.ADMIN.USER;
  const response = httpService._put(`${url}/${id}`, rest);
  return response;
};
