import { connect } from 'react-redux';
import PackageScreen from '../../../components/screens/admin/package/packageScreen';
import {
	getPackage,
	createPackage,
	updatePackage,
	updateOrder
} from '../../../redux/actions/admin/packageAction';
import { getProductTypes } from '../../../redux/actions/admin/productTypesAction';
import { getItems } from '../../../redux/actions/admin/itemsAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';

const mapStateToProps = state => {
	return {
		success: state.packages.success,
		error: state.packages.error,
		isLoading: state.packages.isLoading,
		data: state.packages.data,
		items: state.items.data,
		productTypes: state.productType.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetPackage: () => {
			dispatch(getPackage());
		},
		onCreatePackage: data => {
			dispatch(createPackage(data));
		},
		onUpdatePackage: ({ data, id }) => {
			dispatch(updatePackage({ data, id }));
		},
		onGetItems: () => {
			dispatch(getItems());
		},
		onUpdateOrder: data => {
			dispatch(updateOrder(data));
		},
		onGetProductTypes: () => {
			dispatch(getProductTypes());
		},
		onGetCurrentUser: () => {
			dispatch(getCurrentUser());
		}
	};
};

const PackageContainer = connect(mapStateToProps, mapDispatchToProps)(PackageScreen);

export default PackageContainer;
