import {
	UPDATE_ORDER_STORIES_FAILED,
	GET_OUR_STORIES_REQUEST,
	GET_OUR_STORIES_SUCCESS,
	GET_OUR_STORIES_FAILED
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';

const defaultState = {
	success: false,
	error: null,
	data: [],
	isLoading: false
};

const storiesReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_OUR_STORIES_REQUEST: {
			return {
				...state
			};
		}
		case GET_OUR_STORIES_SUCCESS: {
			return {
				...state,
				...action.payload
			};
		}
		case GET_OUR_STORIES_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				error: action.payload
			};
		}
		case UPDATE_ORDER_STORIES_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		default:
			return state;
	}
};

export default storiesReducer;
