import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './editorContact.scss';
import { Icon, Button, Dropdown, Menu, Select } from 'antd';
import PhoneIcon from '../../../../../assets/images/icons/common/phone.svg';
import EmailIcon from '../../../../../assets/images/icons/common/email.svg';
import LocationIcon from '../../../../../assets/images/icons/common/location.svg';
import CakeIcon from '../../../../../assets/images/icons/common/cake.svg';
import { EDITOR_STATUS, EDITOR_TIERS } from '../../../../../core/constants/constants';
import {
  updateEditor,
  resendInvitation,
} from '../../../../../redux/actions/admin/editorsAction';
import { renderTierIcon } from '../../../../../core/utils/commonUtils';
import UserAvatar from 'src/components/common/userAvatar/index.js';

const { Option } = Select;

class EditorContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderStatus = () => {
    const { editor } = this.props;
    const { status } = editor;
    const filterStatus = EDITOR_STATUS.filter(item => item.value !== status);
    const statusRender = EDITOR_STATUS.find(item => item.value === status);
    return (
      <Select
        defaultValue={statusRender.label}
        style={{ width: 120 }}
        onChange={value =>
          this.props.onUpdateEditor({
            id: editor && editor._id,
            status: value,
          })
        }
      >
        {filterStatus.map(item => (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    );
  };

  renderTier = () => {
    const { editor } = this.props;
    const { tier } = editor;
    const filterTier = EDITOR_TIERS.filter(item => item.value !== tier);
    const menu = (
      <Menu>
        {filterTier.map(item => (
          <Menu.Item key={item.value}>
            <span
              onClick={() =>
                this.props.onUpdateEditor({
                  id: editor && editor._id,
                  tier: item.value,
                })
              }
            >
              {item.label}
            </span>
          </Menu.Item>
        ))}
      </Menu>
    );
    const tierRender = EDITOR_TIERS.find(item => item.value === tier);
    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <span className="ant-dropdown-link tier-text" onClick={e => e.preventDefault()}>
          {tierRender.label || null} <Icon type="down" />
        </span>
      </Dropdown>
    );
  };

  render() {
    const { editor, isSendingInvitation } = this.props;
    const { userId, tier } = editor || {};
    const { avt, fullName } = userId || {};

    return (
      <div className="editor-contact-container">
        <UserAvatar
          fullName={fullName}
          avatar={avt}
          status={true}
          iconSrc={renderTierIcon(tier)}
        />
        <div className="contact-container">
          <span className="account-id">
            {editor.userId._id.substring(editor.userId._id.length - 4)}
          </span>
          <div className="header-container">
            <span className="name">{editor.userId.fullName}</span>
            <div className="divider"></div>
            {this.renderTier()}
          </div>
          <div className="body-container">
            <div className="birth-date-container">
              <img src={CakeIcon} alt="phone" className="cake-icon" />
              <span className="birth-date">{editor.userId.birthday}</span>
            </div>
            <div className="phone-container">
              <img src={PhoneIcon} alt="phone" className="phone-icon" />
              <a className="phone" href={'tel:' + editor.userId.phoneNumber}>
                {editor.userId.phoneNumber}
              </a>
            </div>
            {this.renderStatus()}
          </div>
          <div className="footer-container">
            <div className="location-container">
              <img src={LocationIcon} alt="location" className="location-icon" />
              {editor.userId.city ? `${editor.userId.city}, ` : null}
              {editor.userId.country || null}
            </div>
            <div className="email-container">
              <img src={EmailIcon} alt="email" className="email-icon" />
              <a className="email" href={'mailto:' + editor.userId.email}>
                {editor.userId.email}
              </a>
            </div>
            {editor.status === 'pending' && (
              <Button
                className="btn-primary-0 btn-resend-invite"
                onClick={() =>
                  this.props.onResendInvitation({
                    email: editor.userId.email && editor.userId.email.trim(),
                    fullName: editor.userId.fullName && editor.userId.fullName.trim(),
                  })
                }
                type="primary"
                loading={isSendingInvitation}
              >
                Resend Invite
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isSendingInvitation: state.editors.isSendingInvitation,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateEditor: data => {
      dispatch(updateEditor(data));
    },
    onResendInvitation: data => {
      dispatch(resendInvitation(data));
    },
  };
};

export default withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(EditorContact)
);
