import {
	GET_FAQS_REQUEST,
	CREATE_FAQS_REQUEST,
	UPDATE_FAQS_REQUEST,
	UPDATE_ORDER_FAQS_SUCCESS
} from '../typeAction';

export const getFaqs = () => ({
	type: GET_FAQS_REQUEST
});

export const createFaqs = data => ({
	type: CREATE_FAQS_REQUEST,
	payload: data
});

export const updateFaqs = ({ data, id }) => ({
	type: UPDATE_FAQS_REQUEST,
	payload: { data, id }
});

export const updateOrder = data => ({
	type: UPDATE_ORDER_FAQS_SUCCESS,
	payload: data
});
