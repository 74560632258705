import React from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Modal, Select } from 'antd';
import './adminInvitationModal.scss';
import { registerAdmin } from '../../../../../redux/actions/admin/adminsAction';
import {
  validateEmail,
  validatePassword,
} from '../../../../../core/utils/customValidator';
import {
  renderRequireLabel,
  filterActiveItems,
  filterItemsByType,
} from '../../../../../core/utils/commonUtils';
import { ADMIN_ROLES } from '../../../../../core/constants/constants';

const { Option } = Select;

class AdminInvitationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) return null;

      const data = {
        fullName: values.fullName && values.fullName.trim(),
        email: values.email && values.email.trim(),
        password: values.password && values.password.trim(),
        roleId: values.roleId,
      };
      this.props.onRegisterAdmin(data);
    });
  };

  renderModal = () => {
    const { getFieldDecorator } = this.props.form;
    const { isLoading, modalVisible, adminRoles } = this.props;
    return (
      <Modal
        visible={modalVisible}
        onCancel={this.props.onCloseModal}
        className="admin-invitation-modal"
        footer={null}
        closable={false}
        destroyOnClose={true}
      >
        <h1 className="invitation-title">Create New Staff User</h1>
        <span className="invitation-description first-description">
          Create a new staff account for VidOps
        </span>
        <Form className="invitation-form">
          <Form.Item label="Full Name">
            {getFieldDecorator('fullName', {
              rules: [
                {
                  required: true,
                  message: 'Please input Full name',
                },
                {
                  whitespace: true,
                  message: 'Please do not input whitespace only',
                },
              ],
              initialValue: null,
            })(<Input size="large" />)}
          </Form.Item>
          <Form.Item label="Role">
            {getFieldDecorator('roleId', {
              initialValue: adminRoles && adminRoles.length && adminRoles[0]._id,
            })(
              <Select className="roles-select" size="small">
                {adminRoles &&
                  adminRoles.length &&
                  adminRoles.map(item => {
                    const findItem = ADMIN_ROLES.find(el => el.value === item.name);
                    if (findItem) {
                      return (
                        <Option key={item._id} value={item._id}>
                          {findItem.label}
                        </Option>
                      );
                    }
                    return null;
                  })}
              </Select>
            )}
          </Form.Item>
          <Form.Item label={renderRequireLabel('Email')}>
            {getFieldDecorator('email', {
              rules: [
                {
                  validator: (rule, value, callback) => {
                    const msg = validateEmail(value, 'Email');
                    if (msg) {
                      callback(msg);
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(<Input placeholder="Email" size="large" />)}
          </Form.Item>
          <Form.Item label={renderRequireLabel('Password')}>
            {getFieldDecorator('password', {
              rules: [
                {
                  validator: (rule, value, callback) => {
                    const msg = validatePassword(value, 'Password');
                    if (msg) {
                      callback(msg);
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(<Input type="password" placeholder="Password" size="large" />)}
          </Form.Item>
          <div className="buttons-container">
            <div className="btn-item-container">
              <Button
                type="primary"
                onClick={() => this.props.onCloseModal()}
                className="btn font-weight-bold"
                size="large"
              >
                Cancel
              </Button>
            </div>
            <div className="btn-item-container">
              <Button
                type="primary"
                loading={isLoading}
                onClick={this.handleSubmit}
                className="btn btn-create btn-primary-0"
                size="large"
                disabled={isLoading}
              >
                Create
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    );
  };

  componentDidUpdate(prevProps) {
    const types = ['term', 'privacy'];
    if (prevProps.policies !== this.props.policies) {
      const policies = this.props.policies;
      const activePolicies = filterActiveItems(policies);
      types.forEach(type => {
        this.setState({
          [`${type}Policies`]: filterItemsByType(activePolicies, type),
        });
      });
    }
    if (prevProps.invitedAdmin !== this.props.invitedAdmin) {
      this.props.onCloseModal();
    }
  }

  render() {
    return this.renderModal();
  }
}

const mapStateToProps = state => {
  return {
    policies: state.policies.data,
    isLoading: state.admins.isLoading,
    invitedAdmin: state.admins.detailData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRegisterAdmin: data => {
      dispatch(registerAdmin(data));
    },
  };
};

const WrappedAdminInvitationModal = Form.create({
  name: 'normal_project_invitation_admin_modal',
})(connect(mapStateToProps, mapDispatchToProps)(AdminInvitationModal));

export default WrappedAdminInvitationModal;
