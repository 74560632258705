import { put, call, takeLatest } from 'redux-saga/effects';

import {
  GET_YOUTUBE_LINK_REQUEST,
  GET_YOUTUBE_LINK_SUCCESS,
  GET_YOUTUBE_LINK_FAILED,
  CREATE_YOUTUBE_LINK_REQUEST,
  CREATE_YOUTUBE_LINK_SUCCESS,
  CREATE_YOUTUBE_LINK_FAILED,
  REMOVE_YOUTUBE_LINK_REQUEST,
  REMOVE_YOUTUBE_LINK_SUCCESS,
  REMOVE_YOUTUBE_LINK_FAILED,
  UPDATE_YOUTUBE_LINK_REQUEST,
} from '../../actions/typeAction';
import {
  getYoutubeLinksService,
  createYoutubeLinksService,
  removeYoutubeLinksService,
} from '../../../core/services/modules/emailTemplateService';
import {
  UPDATE_YOUTUBE_LINK_FAILED,
  UPDATE_YOUTUBE_LINK_SUCCESS,
} from 'src/redux/actions/typeAction';
import { updateYoutubeService } from 'src/core/services/modules/emailTemplateService';

export function* getYoutubeLinksSaga() {
  const response = yield call(getYoutubeLinksService);
  if (response.data.error) {
    yield put({
      type: GET_YOUTUBE_LINK_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: GET_YOUTUBE_LINK_SUCCESS,
      payload: data,
    });
  }
}

export function* createYoutubeLinksSaga(action) {
  const response = yield call(createYoutubeLinksService, action.payload);
  if (response.data.error) {
    yield put({
      type: CREATE_YOUTUBE_LINK_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: CREATE_YOUTUBE_LINK_SUCCESS,
      payload: data,
    });
    yield put({
      type: GET_YOUTUBE_LINK_REQUEST,
    });
  }
}

export function* updateYoutubeLinkSaga(action) {
  const response = yield call(updateYoutubeService, action.payload);
  if (response.data.error) {
    yield put({
      type: UPDATE_YOUTUBE_LINK_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: UPDATE_YOUTUBE_LINK_SUCCESS,
      payload: data,
    });
    yield put({
      type: GET_YOUTUBE_LINK_REQUEST,
    });
  }
}

export function* removeYoutubeLinksSaga(action) {
  const response = yield call(removeYoutubeLinksService, action.payload);
  if (response.data.error) {
    yield put({
      type: REMOVE_YOUTUBE_LINK_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: REMOVE_YOUTUBE_LINK_SUCCESS,
      payload: data,
    });
    yield put({
      type: GET_YOUTUBE_LINK_REQUEST,
    });
  }
}

export function* watchEmailTemplate() {
  yield takeLatest(GET_YOUTUBE_LINK_REQUEST, getYoutubeLinksSaga);
  yield takeLatest(CREATE_YOUTUBE_LINK_REQUEST, createYoutubeLinksSaga);
  yield takeLatest(UPDATE_YOUTUBE_LINK_REQUEST, updateYoutubeLinkSaga);
  yield takeLatest(REMOVE_YOUTUBE_LINK_REQUEST, removeYoutubeLinksSaga);
}
