import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';
import { Jumbotron, Container } from 'react-bootstrap';
import './subCategoriesScreen.scss';
import DataTable from '../../../common/dragTable/dragTable';
import ModalData from '../../../common/newModal/newModal';
import SubCategoriesForm from '../../../common/form/subCategoriesForm/subCategoriesForm';
import { ORDER_KEYS } from '../../../../core/constants/constants';

class SubCategoriesScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: 'Admin SubCategories - VidOps',
			data: [],
			key: '',
			itemUpdate: null,
			isModalOpen: false,
			isCreate: false
		};
	}

	updateBannerHandler = updateData => {
		this.props.onUpdateBanner(updateData);
	};

	componentDidMount() {
		this.props.onGetCurrentUser();
		this.props.onGetUnits();
		this.props.onGetCategories();
		this.props.onGetSubCategories();
		this.props.onGetProductTypes();
	}

	handleDelete = key => {
		this.props.onDeleteSubCategories(key);
	};

	handleUpdate = key => {
		const data = this.formatData(this.props.data);
		if (data) {
			this.setState({
				itemUpdate: data.find(item => item._id === key)
			});
		}
		this.setState({
			isModalOpen: true,
			isCreate: false,
			key
		});
	};

	formatColumns = () => {
		return [
			{
				title: 'Name',
				dataIndex: 'name'
			},
			{
				title: 'Categories',
				dataIndex: 'categoryId[name]'
			},
			{
				title: 'Measure Unit',
				dataIndex: 'measureUnitId[name]'
			},
			{
				title: 'Status',
				dataIndex: 'status',
				filters: [
					{
						text: 'Active',
						value: 'Active'
					},
					{
						text: 'Inactive',
						value: 'Inactive'
					}
				],
				render: status => {
					if (status) {
						return <span>Active</span>;
					}
					return <span>Inactive</span>;
				},
				onFilter: (value, record) => {
					const status = record.status ? 'Active' : 'Inactive';
					return status.indexOf(value) === 0;
				}
			},
			{
				title: 'Operation',
				dataIndex: 'operation',
				render: (text, record) => {
					return (
						<div className="operation-container">
							<Button
								onClick={() => this.handleUpdate(record.key)}
								type="primary"
								className="btn-primary-0"
								icon="edit"
							></Button>
						</div>
					);
				}
			}
		];
	};

	handleAdd = () => {
		this.setState({
			isModalOpen: true,
			isCreate: true
		});
	};

	createHandler = data => {
		this.props.onCreateSubCategories(data);
	};

	updateHandler = data => {
		const payload = {
			data,
			id: this.state.key
		};
		this.props.onUpdateSubCategories(payload);
	};

	closeModalHandler = () => {
		this.setState({
			isModalOpen: false
		});
	};

	filterActive = data => {
		if (data && data.length) {
			return data.filter(
				item => item.categoryId && item.categoryId.status && item.measureUnitId.status
			);
		}
	};

	formatData = data => {
		if (data && data.length) {
			return data.map(item => {
				return {
					...item,
					key: item._id
				};
			});
		}
	};

	componentDidUpdate(prevProps) {
		if (this.props.data !== prevProps.data) {
			this.setState({
				isModalOpen: false
			});
		}
	}

	updateOrderHandler = orderData => {
		const updateOrderData = {
			key: ORDER_KEYS.SUB_CATEGORY,
			...orderData
		};
		this.props.onUpdateOrder(updateOrderData);
	};

	filterActiveCategories = categories => {
		return categories.filter(item => item.status);
	};

	render() {
		const { data, isLoading, categories, units, productTypes } = this.props;
		const { isModalOpen, isCreate, key, itemUpdate } = this.state;
		const columns = this.formatColumns();
		const dataSource = this.formatData(data);
		const activeSource = this.filterActive(dataSource);
		const categoriesFilter = this.filterActiveCategories(categories);
		return (
			<main role="main" className="page-content subCategories-screen">
				<Jumbotron className="mgt-40">
					<h2 className="sub-heading">SubCategories</h2>
					<p className="description">Manage SubCategories</p>
					<div className="divider"></div>
					<Container>
						<Button
							className="btn-primary-0"
							onClick={this.handleAdd}
							type="primary"
							style={{ marginBottom: 16 }}
						>
							Add new
						</Button>
						<DataTable
							columns={columns}
							dataSource={activeSource}
							pagination={false}
							onUpdateOrder={this.updateOrderHandler}
						/>
						<ModalData
							className="subCategories-modal"
							title={isCreate ? 'Create SubCategories' : 'Update SubCategories'}
							visible={isModalOpen}
							onCloseModal={this.closeModalHandler}
							dataSource={
								<SubCategoriesForm
									onCreateSubCategories={this.createHandler}
									onUpdateSubCategories={this.updateHandler}
									isLoading={isLoading}
									isCreate={isCreate}
									itemUpdate={itemUpdate}
									key={key}
									categories={categoriesFilter}
									units={units}
									productTypes={productTypes}
								/>
							}
							footer={null}
						/>
					</Container>
				</Jumbotron>
			</main>
		);
	}
}

export default withTranslation('common')(SubCategoriesScreen);
