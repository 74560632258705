import React, { useState } from 'react';
import moment from 'moment';
import GoogleMapReact from 'google-map-react';
import { Card, Button } from 'antd';
import { VIDOPS_LOCATION } from 'src/core/constants/constants';
import FlagIcon from 'src/assets/images/icons/common/maps-and-flags.svg';
import UserAvatar from 'src/components/common/userAvatar';
import OnsiteContactInformation from '../onsiteContactInformation/onsiteContactInformation';

const lat = VIDOPS_LOCATION.lat, lng = VIDOPS_LOCATION.lng;
const AnyReactComponent = () => (
  <div>
    <img alt="pin" style={{ width: '20px' }} src={FlagIcon} />
  </div>
);

const tabList = [
  {
    key: 'datetime',
    tab: 'Date & time',
  },
  {
    key: 'address',
    tab: 'Address',
  },
  {
    key: 'contact',
    tab: 'On-site contact',
  },
];

const tabContent = {
  /** @param {import('.').ProductionDate} detail */
  datetime: (detail, renderCreative) => (<>
    <div className="production-date-item">
      <p>
        <strong>Date:</strong>&nbsp;
        <span className="start-date">
          {moment(detail.startDate).format('MMM DD, YYYY')}
        </span>
      </p>
      <p>
        <strong>Duration:</strong>&nbsp;
        <span className="duration">
          {moment(detail.startDate).format('h:mm A')} -{' '}
          {moment(detail.startDate).add(detail.duration, 'h').format('h:mm A')}
        </span>
      </p>
    </div>
    <div className='production-date-item'>
      <strong>Creatives assignment:</strong>&nbsp;
      {(!detail.creatives || !detail.creatives.length) && <span>{'No creatives'}</span>}
      {detail.creatives && !!detail.creatives.length && detail.creatives.map(renderCreative)}
    </div>
  </>),
  /** @param {import('.').ProductionDate} detail */
  address: (detail) => (<>
    <p>
      <strong>Location:</strong>&nbsp;
      <span>{(detail.address && detail.address.label) || 'No locations'}</span>
    </p>
    <p>
      <strong>Address:</strong>&nbsp;
      <span>{(detail.address && detail.address.formatted_address) || 'No addresses'}</span>
    </p>
    {
      detail.address &&
      (<div className="map-wrapper">
        <GoogleMapReact
          bootstrapURLKeys={{
            lang: 'en',
          }}
          center={detail.address.location || { lat, lng }}
          zoom={13}
        >
          <AnyReactComponent
            lat={(detail.address.location && detail.address.location.lat) || lat}
            lng={(detail.address.location && detail.address.location.lng) || lng}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>)
    }
    <p>
      <strong>Note:</strong>&nbsp;
      <span>{detail.addressNote}</span>
    </p>
  </>),
  /** @param {import('.').ProductionDate} detail */
  contact: (detail) => (<OnsiteContactInformation formData={detail.contact} disabled={true} />)
};

/**
 * @param {{
 *   onEdit?: Function
 *   detail: import('.').ProductionDate
 *   order: number
 * }} props
 */
export default function ProductionDate(props) {
  const { onEdit, onRemove, project, detail, order } = props;
  const [tab, setTab] = useState(tabList[0].key);

  const creatives = (project.creativeId || []).map(item => ({
    _id: item.id._id,
    status: item.status === 'ACCEPT' || (item.id && item.id.status === 'active'),
    user: { ...item.id.userId }
  }));

  const patchedDetail = {
    ...detail,
    address: (!!detail.address && !!detail.address.formatted_address && detail.address) || project.address,
    addressNote: detail.addressNote || project.addressNote,
    contact: detail.contact || project.pointOfContact,
    creatives: (!!detail.creatives && !!detail.creatives.length && detail.creatives) || (project.creativeId || []).map(creative => creative.id._id)
  };

  const renderCreative = (id) => {
    if (!creatives.length) {
      return null;
    }

    const creative = creatives.find(item => item._id === id);
    if (!creative) {
      return null;
    }

    return <UserAvatar key={creative._id} status={creative.status} avatar={creative.user.avt} fullName={creative.user.fullName} size="small" />;
  };

  return (
    <Card
      className='production-date'
      title={'Shooting date #' + order}
      size='small'
      hoverable={true}
      tabList={tabList}
      activeTabKey={tab}
      onTabChange={key => setTab(key)}
      extra={(<>
        <Button type="link" onClick={onEdit}>Edit</Button>
        <Button type="link" className="ml-2" onClick={onRemove}>Remove</Button>
      </>)}
    >
      {tabContent[tab](patchedDetail, renderCreative)}
    </Card>
  );
};
