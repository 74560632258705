import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './editorForm.scss';
import { Form, Spin } from 'antd';
import EditorContact from './editorContact/editorContact';
import EditorInfo from './editorInfo/editorInfo';

class EditorForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { editorDetail, onClose } = this.props;
    return (
      <div className="editor-drawer-container">
        {editorDetail ? (
          <>
            <EditorContact editor={editorDetail} />
            <EditorInfo editor={editorDetail} onClose={onClose} />
          </>
        ) : (
          <Spin />
        )}
      </div>
    );
  }
}

const WrappedEditorForm = Form.create({ name: 'normal_editors' })(EditorForm);

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(WrappedEditorForm)
);
