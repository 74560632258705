import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Tabs, Switch } from 'antd';
import { createProject } from 'src/redux/actions/admin/projectsAction';
import { PROJECT_STATUS } from 'src/core/constants/constants';
import { VIDOPS_LOCATION } from 'src/core/constants/constants';
import { dataURLtoBlob, isVideoFile, uuid } from 'src/core/utils/commonUtils';
import { isImageType } from 'src/core/utils/imageCompressor/utils';
import VideoThumbnailExtractor from 'src/components/common/videoThumbnailExtractor';
import compressImage from 'src/core/utils/imageCompressor';
import ProjectHeader from '../projectsDetail/projectHeader/projectHeader';
import ProjectTitle from '../projectsDetail/projectTitle/projectTitle';
// import ProjectActions from '../projectsDetail/projectActions/projectActions';
// import OnsiteContactInformation from '../projectsDetail/onsiteContactInformation/onsiteContactInformation';
import ProjectInformation from '../projectsDetail/projectInformation/projectInfomation';
import CustomerInformation from '../projectsDetail/customerInformation/customerInformation';
import PaymentInformation from './paymentInformation/paymentInformation';
import ProductionDates from './productionDatesForm';
import ProjectBriefs from './briefsForm';
import './projectCreateForm.scss';

const { TabPane } = Tabs;

class ProjectCreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.videoThumbnailExtractor = React.createRef();
    this.state = {
      isArchive: false,
      title: '',
      productionDates: [],
      customerId: '',
      onSiteContactFullName: '',
      onSiteContactEmail: '',
      onSiteContactPhoneNumber: '',
      onSiteContactNote: '',
      address: null,
      status: PROJECT_STATUS[0].value,
      productTypeId: '',
      serviceTypeId: '',
      packages: null,
      isUrgent: false,
      brief: '',
      briefSchedule: '',
      briefShotlist: '',
      briefScript: '',
      briefTechnical: '',
      addressNote: '',
      equipments: '',
      inspirationLink: '',
      quickNote: '',
      uploads: [],
      uploadsSize: [],
      customerPayment: 0,
      creativePayment: 0,
      editorPayment: 0,
      otherPayment: 0,
      activitiesPage: 1,
      activitiesLimit: '',
      budget: 0,
      estimationPrice: 0,
    };
  }

  handleSubmit = async e => {
    e.preventDefault();
    const {
      isArchive,
      title,
      productionDates,
      customerId,
      onSiteContactFullName,
      onSiteContactEmail,
      onSiteContactPhoneNumber,
      onSiteContactNote,
      address,
      status,
      productTypeId,
      serviceTypeId,
      packages,
      isUrgent,
      brief,
      briefSchedule,
      briefShotlist,
      briefScript,
      briefTechnical,
      addressNote,
      equipments,
      inspirationLink,
      quickNote,
      uploads,
      uploadsSize,
      customerPayment,
      creativePayment,
      editorPayment,
      otherPayment,
      budget,
      estimationPrice,
    } = this.state;

    const projectS3Id = uuid();

    const payload = JSON.parse(
      JSON.stringify({
        title,
        isArchive,
        productionDates: JSON.stringify(productionDates),
        customerId,
        fullName: onSiteContactFullName,
        email: onSiteContactEmail,
        phoneNumber: onSiteContactPhoneNumber,
        note: onSiteContactNote,
        address: JSON.stringify(address),
        status,
        productTypeId,
        serviceTypeId: serviceTypeId || undefined,
        packages: JSON.stringify(packages),
        isUrgent,
        brief,
        briefSchedule,
        briefShotlist,
        briefScript,
        briefTechnical,
        addressNote,
        equipments,
        inspirationLink,
        quickNote,
        customerPayment,
        creativePayment,
        editorPayment,
        otherPayment,
        uploadsSize:
          uploadsSize && uploadsSize.length && uploadsSize.map(item => item.size),
        fileNames:
          uploads && uploads.length
            ? uploads.map(item => item.originFileObj && item.originFileObj.name)
            : [],
        projectS3Id,
        budget,
        estimationPrice,
      })
    );

    let uploadWithThumbs = uploads.map(async upload => {
      const { originFileObj } = upload;
      const checkIsVideo = isVideoFile({ filename: originFileObj.name });
      if (isImageType(originFileObj.type) || checkIsVideo) {
        let thumbnail = originFileObj;

        if (checkIsVideo) {
          let videoThumb = await this.videoThumbnailExtractor.current.onExtractThumbnail(
            thumbnail
          );
          if (videoThumb) thumbnail = dataURLtoBlob(videoThumb);
        }

        thumbnail = await compressImage(thumbnail);

        Object.assign(upload, { thumbType: thumbnail.type, thumbFile: thumbnail });
      }

      return upload;
    });

    uploadWithThumbs = await Promise.all(uploadWithThumbs);
    // const formData = formDataConfig(payload);
    this.props.onCreateProject(payload, uploadWithThumbs);
  };

  projectTabsRender = () => {
    const { currentProject, isLoading } = this.props;

    const {
      isArchive,
      productionDates,
      brief,
      briefSchedule,
      briefShotlist,
      briefScript,
      briefTechnical,
      quickNote,
    } = this.state;
    return (
      <Tabs
        className="project-tabs mgt-20"
        destroyInactiveTabPane={true}
        onChange={this.tabChange}
        type="card"
      >
        <TabPane tab="Information" key="1">
          {/* <div className="d-flex mb-4 align-items-center">
            <h6 className="mb-0 mr-2" style={{ color: '#f46036' }}>
              <strong>Archive</strong>
            </h6>
            <Switch className="mr-3" onChange={this.onToggleArchive} />
          </div> */}
          <CustomerInformation
            isArchive={isArchive}
            onInputFields={this.handleInputFields}
          />
          <ProjectInformation
            // brief={brief}
            // addressNote={addressNote}
            quickNote={quickNote}
            onInputFields={this.handleInputFields}
          />
          {/* <OnsiteContactInformation
            onSiteContactPhoneNumber={onSiteContactPhoneNumber}
            onSiteContactNote={onSiteContactNote}
            onInputFields={this.handleInputFields}
          /> */}
          <PaymentInformation onInputFields={this.handleInputFields} />
          <div className="btns-container">
            <Button
              className="btn-primary-0 btn-cancel"
              onClick={this.props.onCloseDrawer}
              type="primary"
            >
              Cancel
            </Button>
            <Button
              className="btn-primary-0 btn-submit"
              onClick={this.handleSubmit}
              type="primary"
              loading={isLoading}
            >
              Submit
            </Button>
          </div>
        </TabPane>
        <TabPane tab="Call sheet" key="callsheet">
          <ProductionDates
            formData={productionDates}
            onInputFields={this.handleInputFields}
          />
        </TabPane>
        <TabPane tab="Project brief" key="brief">
          <ProjectBriefs
            formData={{
              brief,
              briefSchedule,
              briefShotlist,
              briefScript,
              briefTechnical
            }}
            onInputFields={this.handleInputFields}
          />
        </TabPane>
        <TabPane disabled={!currentProject} tab="Assets" key="2"></TabPane>
        <TabPane disabled={!currentProject} tab="Activity" key="activity"></TabPane>
      </Tabs>
    );
  };

  handleInputFields = (property, value) => {
    const valueData = typeof value === 'string' ? value.trim() : value;
    this.setState({
      [property]: valueData,
    });
  };

  onToggleArchive = checked => {
    this.handleInputFields('isArchive', checked);
  };

  componentDidUpdate(prevProps) {
    if (this.props.currentProject !== prevProps.currentProject && this.props.currentProject?._id) {
      this.props.onCloseDrawer();
      const { search } = this.props.location;
      this.props.history.push({
        pathname: '/projects/' + this.props.currentProject._id,
        search
      });
    }
  }

  componentDidMount() {
    const address = {
      address_components: [],
      formatted_address: VIDOPS_LOCATION.formatted_address,
      location: {
        lat: VIDOPS_LOCATION.lat,
        lng: VIDOPS_LOCATION.lng,
      },
      place_id: VIDOPS_LOCATION.place_id,
      label: VIDOPS_LOCATION.label,
    };
    this.setState({
      address,
    });
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
      },
    };
    return (
      <>
        <VideoThumbnailExtractor ref={this.videoThumbnailExtractor} />
        <Form {...formItemLayout} className="projects-create-form">
          <ProjectHeader onCloseDrawerClick={this.props.onCloseDrawer} />
          <ProjectTitle onInputFields={this.handleInputFields} />
          {/* <ProjectActions onInputFields={this.handleInputFields} /> */}
          {this.projectTabsRender()}
        </Form>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentProject: state.projects.detailData,
    isLoading: state.projects.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCreateProject: (data, uploads) => {
      dispatch(createProject(data, uploads));
    },
  };
};

const WrappedProjectCreateForm = Form.create({ name: 'normal_projects_create' })(
  connect(mapStateToProps, mapDispatchToProps)(ProjectCreateForm)
);

export default WrappedProjectCreateForm;
