import { put, call, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../../actions/typeAction';
import {
    getMessages,
    sendMessage,
    sendFile
} from '../../../core/services/modules/messageService';

export function* getMessagesTask(action) {
    try {
        const data = action.payload;

        const response = yield call(getMessages, data);
        if (response.status === 200 && response.data.success) {
            yield put({
                type: actionTypes.GET_MESSAGE_SUCCESS,
                payload: response.data.data
            })
        } else {
            yield put({
                type: actionTypes.GET_MESSAGE_FAILED,
                payload: response.data.error
            })
        }
    } catch (err){
        yield put({
            type: actionTypes.GET_MESSAGE_FAILED,
            payload: err
        })
    }
}

export function* sendMessageTask(action) {
    try {
        const data = action.payload;

        const response = yield call(sendMessage, data);
        if (response.status === 200 && response.data.success) {
            yield put({
                type: actionTypes.SEND_MESSAGE_SUCCESS,
                payload: response.data.data
            })
        } else {
            yield put({
                type: actionTypes.SEND_MESSAGE_FAILED,
                payload: response.data.error
            })
        }
    } catch (err){
        yield put({
            type: actionTypes.SEND_MESSAGE_FAILED,
            payload: err
        })
    }
}

export function* sendFileTask(action) {
    try {
        const data = action.payload;

        const response = yield call(sendFile, data);
        if (response.status === 200 && response.data.success) {
            yield put({
                type: actionTypes.UPLOAD_FILE_AND_LINK_SUCCESS,
                payload: response.data.data
            })
        } else {
            yield put({
                type: actionTypes.UPLOAD_FILE_AND_LINK_FAILED,
                payload: response.data.error
            })
        }
    } catch (err){
        yield put({
            type: actionTypes.UPLOAD_FILE_AND_LINK_FAILED,
            payload: err
        })
    }
}

export function* watchMessage() {
    yield takeLatest(actionTypes.GET_MESSAGE_REQUEST, getMessagesTask);
    yield takeLatest(actionTypes.SEND_MESSAGE_REQUEST, sendMessageTask);
    yield takeLatest(actionTypes.UPLOAD_FILE_AND_LINK_REQUEST, sendFileTask);
}