import { connect } from 'react-redux';
import CreativesScreen from '../../../components/screens/admin/creatives/creativesScreen';
import {
  getCreatives,
  getCreativesDetail,
  sendInvitation,
  clearCreativesDetail
} from 'src/redux/actions/admin/creativesAction';
import { getCurrentUser } from 'src/redux/actions/admin/userAction';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

const mapStateToProps = state => {
  return {
    success: state.creatives.success,
    error: state.creatives.error,
    isLoading: state.creatives.isLoading,
    creatives: state.creatives.data,
    isGettingDetail: state.creatives.isGettingDetail,
    creativesDetail: state.creatives.detailData,
    creativesDetailError: state.creatives.detailError,
    isSendingInvitation: state.creatives.isSendingInvitation,
    hasSentInvitation: state.creatives.hasSentInvitation,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetCreatives: params => {
      dispatch(getCreatives(params));
    },
    onGetCreativesDetail: id => {
      dispatch(getCreativesDetail(id));
    },
    onClearCreativesDetail: () => {
      dispatch(clearCreativesDetail());
    },
    onSendInvitation: data => {
      dispatch(sendInvitation(data));
    },
    onGetCurrentUser: () => {
      dispatch(getCurrentUser());
    },
  };
};

const CreativesContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(CreativesScreen);

export default CreativesContainer;
