import {
	GET_ADD_HOC_REQUEST,
	CREATE_ADD_HOC_REQUEST,
	UPDATE_ADD_HOC_REQUEST
} from '../typeAction';

export const getAddhoc = () => ({
	type: GET_ADD_HOC_REQUEST
});

export const createAddhoc = data => ({
	type: CREATE_ADD_HOC_REQUEST,
	payload: data
});

export const updateAddhoc = ({ data, id }) => ({
	type: UPDATE_ADD_HOC_REQUEST,
	payload: { data, id }
});
