import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Select } from 'antd';
import { debounce } from 'src/core/utils/commonUtils';

const { Option } = Select;

class SelectFilterV2 extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selected: undefined,
    };
  }

  onChange = value => {
    this.setState({ selected: value });
    this.props.onSelect(value);
  };

  onSearch = val => {
    debounce(this.props.onSearchHandler(val));
  };

  handleScroll = event => {
    const { hasMore, page } = this.props;
    if (!hasMore) return;
    const { scrollTop, offsetHeight, scrollHeight } = event.target;
    if (!this.state.loading && scrollTop + offsetHeight === scrollHeight) {
      this.setState({ loading: true }, () => {
        setTimeout(() => {
          this.props.onLoadMoreHandler({ page: page + 1 });
          this.setState({ loading: false });
        }, 1000);
      });
    }
  };

  render() {
    const { placeholder, disabled, children, mode } = this.props;
    const { loading } = this.state;
    return (
      <Select
        allowClear={true}
        showSearch
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={this.onChange}
        onSearch={this.onSearch}
        onPopupScroll={this.handleScroll}
        disabled={disabled}
        className={`select-team ${mode}`}
        mode={mode}
        style={{ width: '100%' }}
        getPopupContainer={triggerNode => triggerNode.parentElement}
        value={this.props.selected ? this.props.selected : this.state.selected}
      >
        {!loading
          ? children
          : [
              ...children,
              <Option key="loading" disabled>
                Loading...
              </Option>,
            ]}
      </Select>
    );
  }
}

export default withTranslation('common')(SelectFilterV2);
