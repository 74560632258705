import { put, call, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../../actions/typeAction';
import { updateOrderService } from '../../../core/services/modules/orderService';
import { openNotification } from '../../../components/common/notification/notification';
import { getOurStoriesService } from '../../../core/services/modules/storiesService';

export function* getOurStoriesSaga(action) {
	const response = yield call(getOurStoriesService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_OUR_STORIES_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_OUR_STORIES_SUCCESS,
			payload: data
		});
	}
}

export function* updateOrderSaga(action) {
	const response = yield call(updateOrderService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_ORDER_STORIES_FAILED,
			payload: response.data.error
		});
	} else {
		openNotification('success', 'Update successful');
	}
}

export function* watchStories() {
	yield takeLatest(actionTypes.GET_OUR_STORIES_REQUEST, getOurStoriesSaga);
	yield takeLatest(actionTypes.UPDATE_ORDER_STORIES_SUCCESS, updateOrderSaga);
}
