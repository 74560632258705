import {
	GET_TOPICS_REQUEST,
	CREATE_TOPICS_REQUEST,
	UPDATE_TOPICS_REQUEST,
	GET_TOPIC_BY_ID_REQUEST,
	SEND_QUOTE_REQUEST
} from '../typeAction';

export const getTopics = params => ({
	type: GET_TOPICS_REQUEST,
	payload: params
});

export const createTopic = data => ({
	type: CREATE_TOPICS_REQUEST,
	payload: data
});

export const sendQuote = data => ({
	type: SEND_QUOTE_REQUEST,
	payload: data
});

export const updateTopic = data => {
	return {
		type: UPDATE_TOPICS_REQUEST,
		payload: data
	};
};

export const getTopicById = id => ({
	type: GET_TOPIC_BY_ID_REQUEST,
	payload: id
});
