import React from 'react';
import { connect } from 'react-redux';
import './messageSection.scss';
import { Icon, Spin } from 'antd';
import moment from 'moment';
import socket from 'src/core/sockets';
import { storageService } from 'src/core/services/storageService';
import Lightbox from 'react-image-lightbox';
import { isVideoFile, preventClosing, uuid } from 'src/core/utils/commonUtils';
import {
  getMessages,
  sendMessage,
  sendQuoteEmail,
} from 'src/core/services/modules/messageService';
import InfiniteScroll from 'src/components/common/infiniteScroll';
import ChatForm from 'src/components/common/form/workspace/topicsScreen/chatForm';
import GreetingPlaceholder from 'src/components/common/form/workspace/topicsScreen/greetingPlaceholder';
import MessageItem from 'src/components/common/form/workspace/topicsScreen/messageItem';

class MessageSection extends React.Component {
  constructor(props) {
    super(props);
    this.typeFiles = ['xlsx', 'xls', 'doc', 'docx', 'ppt', 'pptx', 'txt', 'pdf', 'md'];
    this.socketIO = socket();
    this.uploadType = 'image';
    this.limit = 10;
    this.isLoadMore = false;
    this.disableLoadMore = false;
    this.uploadedQuotes = [];
    this.state = {
      submitting: false,
      loading: true,
      isLoadMore: false,
      messages: [],
      topicId: props.currentTopic && props.currentTopic._id,
      userLoggedIn: storageService.getCurrentUser(),
      totalMsg: 0,
      fileUploads: [],
      originFileUploads: [],
      imageUploads: [],
      originImageUploads: [],
      socketIO: socket(),
      isModalPhotoOpen: false,
      currentImageSrc: null,
      fileProgress: [],
    };
  }

  isFile = fileName => {
    const fileNameSplit = fileName.split('.');
    const fileType = fileNameSplit[fileNameSplit.length - 1];
    return this.typeFiles.includes(fileType);
  };

  previewImage = src => {
    this.setState({
      isModalPhotoOpen: true,
      currentImageSrc: src,
    });
  };

  componentDidMount() {
    this.socketIO.onJoinConversation({
      user_id: this.state.userLoggedIn.userId,
      topic_id: this.state.topicId,
    });
    this.socketIO.onReceivedMessage(data => {
      this.setState({
        messages: [data, ...this.state.messages],
      });
    });

    this.onInitComponent();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', preventClosing);
    this.socketIO.onLeaveConversation({
      user_id: this.state.userLoggedIn.userId,
      topic_id: this.state.topicId,
    });
    this.socketIO.unregisterEvent();
  }

  async onInitComponent(props = this.props) {
    const data = {
      topicId: this.state.topicId,
      limit: this.limit,
    };

    const { quoteFiles } = props;
    if (quoteFiles && quoteFiles.length) {
      this.setState({ loading: false });
      for (const file of quoteFiles) {
        const checkIsFile = this.isFile(file.name);
        Object.assign(file, { isSendQuote: true });
        if (checkIsFile) {
          await this.onUploadFile('file')({ target: { files: [file] } });
        } else {
          await this.onUploadFile('image')({ target: { files: [file] } });
        }
      }
    } else {
      await this._getListMessage(data);
    }
  }

  _getListMessage = async (data, initData = this.state.messages) => {
    let messages = [];
    try {
      const { data: responseData } = await getMessages(data);
      const { data: fetchedMessages } = responseData;

      messages = fetchedMessages.docs || [];

      await this.setStateAsync({
        messages: [...initData, ...messages],
      });
    } catch (e) {
      console.warn(e.message, e.stack);
    }

    this.setState({
      loading: false,
    });

    return messages;
  };

  _handleSubmitMessage = async (messageText, type = 'message', fileName = '') => {
    const { currentProject } = this.props;
    const { topicId } = this.state;
    if (!messageText.trim()) {
      return;
    }

    const projectId = currentProject && currentProject._id;
    const data = {
      content: messageText.trim(),
      topicId,
      projectId,
      sender: this.state.userLoggedIn,
      createdAt: moment().format(),
      // type = drive add field fileName
      type,
      fileName
    };

    try {
      await sendMessage(data);
    } catch (e) {
      console.warn(e);
    }
  };

  onLoadMoreMessage = async () => {
    if (this.isLoadMore || this.disableLoadMore) return null;
    this.isLoadMore = true;
    const firstMessage = this.state.messages[this.state.messages.length - 1];
    const queryData = {
      topicId: this.state.topicId,
      limit: this.limit,
      cursor: firstMessage.createdAt,
    };
    this.setState({ isLoadMore: this.isLoadMore });
    let messages = await this._getListMessage(queryData, this.state.messages);

    if (!messages.length) {
      this.disableLoadMore = true;
    }

    this.isLoadMore = false;
    this.setState({ isLoadMore: this.isLoadMore });
  };

  setStateAsync(state) {
    return new Promise(async resolve => this.setState(state, resolve));
  }

  onUploadFile = uploadType => async e => {
    let files = e.target.files;
    let messages = [...this.state.messages];
    for (let index = 0; index < files.length; index++) {
      const currentFile = files[index];
      const checkIsVideo = isVideoFile({ filename: currentFile.name });

      if (checkIsVideo) {
        uploadType = 'image';
      }

      messages = [
        {
          _id: uuid(),
          senderId: this.state.userLoggedIn,
          projectId: this.props.currentProject,
          topicId: this.props.currentTopic,
          isVideo: checkIsVideo,
          type: 'upload',
          uploadType,
          files: [currentFile],
          createdAt: new Date(),
        },
        ...messages,
      ];
    }

    await this.setStateAsync({
      messages,
    });
  };

  onPressImage = url => {
    this.setState({
      currentImageSrc: url,
      isModalPhotoOpen: true,
    });
  };

  onUpdateMessage = ({ localMessage, message }) => {
    const messageData = [...this.state.messages];
    this.setState(
      {
        messages: messageData.filter(item => item._id !== localMessage._id),
      },
      async () => {
        const [currentFile] = localMessage.files || [];
        if (currentFile && currentFile.isSendQuote) {
          this.uploadedQuotes.push({ localMessage, message, currentFile });
          await this.onSendQuoteEmail();
        }
      }
    );
  };

  onSendQuoteEmail = async () => {
    try {
      if (
        this.props.quoteFiles &&
        this.uploadedQuotes.length === this.props.quoteFiles.length
      ) {
        const quoteFilePaths = this.uploadedQuotes.map(({ message }) => {
          const { files } = message;
          const [currentFile] = files;

          return currentFile.path;
        });

        await sendQuoteEmail({ topicId: this.state.topicId, quoteFilePaths });
      }
    } catch (e) {
      console.error(e);
    }
  };

  renderMessage = (message, index) => {
    return (
      <div key={message._id}>
        <MessageItem
          onPressImage={this.onPressImage}
          onUpdateMessage={this.onUpdateMessage}
          index={index}
          message={message}
          onPreventBackClick={this.props.onPreventBackClick}
          onAllowBackClick={this.props.onAllowBackClick}
        />
      </div>
    );
  };

  render() {
    const { messages, isModalPhotoOpen, currentImageSrc } = this.state;
    const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

    return (
      <React.Fragment>
        {isModalPhotoOpen && (
          <Lightbox
            mainSrc={currentImageSrc}
            onCloseRequest={() => this.setState({ isModalPhotoOpen: false })}
          />
        )}
        <InfiniteScroll
          loadMorePlacement="top"
          offsetThreshold={350}
          onLoadEnd={this.onLoadMoreMessage}
          id="conversation-body"
          className="conversation-body"
          loadMoreComponent={loaderSelector => (
            <div ref={loaderSelector} className="text-center mgb-10">
              <Spin indicator={antIcon} spinning={this.state.isLoadMore} />
            </div>
          )}
        >
          {this.state.loading ? (
            <Spin indicator={antIcon} spinning={true} />
          ) : (
            <React.Fragment>
              {messages.length > 0 && messages.map(this.renderMessage)}
              {messages.length === 0 && <GreetingPlaceholder />}
            </React.Fragment>
          )}
        </InfiniteScroll>

        <ChatForm
          onSubmitMessage={this._handleSubmitMessage}
          onUploadImage={this.onUploadFile('image')}
          onUploadFile={this.onUploadFile('file')}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    message: state.message,
    user: state.login,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageSection);
