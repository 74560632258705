import { put, call, takeLatest } from 'redux-saga/effects';
import {
	GET_FILES_AND_LINKS_FAILED,
	GET_FILES_AND_LINKS_SUCCESS,
	GET_FILES_AND_LINKS_REQUEST,
	UPDATE_FILES_AND_LINKS_FAILED,
	UPDATE_FILES_AND_LINKS_SUCCESS,
	UPDATE_FILES_AND_LINKS_REQUEST,
	MOVE_FILES_TO_ASSETS_FAILED,
	MOVE_FILES_TO_ASSETS_SUCCESS,
	MOVE_FILES_TO_ASSETS_REQUEST
} from '../../actions/typeAction';
import {
	getFilesAndLinksService,
	updateFilesAndLinksService,
	moveFilesToAssetsService
} from '../../../core/services/modules/filesAndLinksService';

export function* getFilesAndLinksSaga(action) {
	const response = yield call(getFilesAndLinksService, action.payload);
	if (response.data.error) {
		yield put({
			type: GET_FILES_AND_LINKS_FAILED,
			payload: response.data.error
		});
	} else {
		const responseData = response.data;
		yield put({
			type: GET_FILES_AND_LINKS_SUCCESS,
			payload: responseData
		});
	}
}

export function* updateFilesAndLinksSaga(action) {
	const response = yield call(updateFilesAndLinksService, action.payload);
	if (response.data.error) {
		yield put({
			type: UPDATE_FILES_AND_LINKS_FAILED,
			payload: response.data.error
		});
	} else {
		const responseData = response.data;
		yield put({
			type: UPDATE_FILES_AND_LINKS_SUCCESS,
			payload: responseData
		});
	}
}

export function* moveToAssetsSaga(action) {
	const response = yield call(moveFilesToAssetsService, action.payload);
	if (response.data.error) {
		yield put({
			type: MOVE_FILES_TO_ASSETS_FAILED,
			payload: response.data.error
		});
	} else {
		const responseData = response.data;
		yield put({
			type: MOVE_FILES_TO_ASSETS_SUCCESS,
			payload: responseData
		});
	}
}

export function* watchFilesAndLinks() {
	yield takeLatest(GET_FILES_AND_LINKS_REQUEST, getFilesAndLinksSaga);
	yield takeLatest(UPDATE_FILES_AND_LINKS_REQUEST, updateFilesAndLinksSaga);
	yield takeLatest(MOVE_FILES_TO_ASSETS_REQUEST, moveToAssetsSaga);
}
