// import { Base64 } from '../utils/base64';
let AUTH_TOKEN = 'AUTH_TOKEN';
let AUTH_TOKEN_FRAME_IO = 'AUTH_TOKEN_FRAME_IO';
let CURRENT_USER = 'CURRENT_USER';
let REFRESH_TOKEN = 'REFRESH_TOKEN';
let REMEMBER_ME = 'REMEMBER_ME';
let AVATAR_USER = 'AVATAR_USER';

class StorageService {
  setRememberMe(val) {
    localStorage.setItem(REMEMBER_ME, JSON.stringify(val));
  }

  setAuthToken(token, refresh_token) {
    localStorage.setItem(AUTH_TOKEN, token);
    if (refresh_token) {
      localStorage.setItem(REFRESH_TOKEN, refresh_token);
    }
  }

  setAuthTokenFrameIo(token) {
    localStorage.setItem(AUTH_TOKEN_FRAME_IO, token);
  }
  setAvatar(url) {
    localStorage.setItem(AVATAR_USER, url);
  }

  setCurrentUser(data) {
    localStorage.setItem(CURRENT_USER, JSON.stringify(data));
  }

  getRememberMe() {
    return JSON.parse(localStorage.getItem(REMEMBER_ME));
  }

  getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN);
  }

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  getCurrentUser() {
    if (localStorage.getItem(CURRENT_USER)) {
      return JSON.parse(localStorage.getItem(CURRENT_USER));
    }
    return null;
  }
  getAvatar() {
    return localStorage.getItem(AVATAR_USER);
  }

  removeAuthToken() {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
  }

  removeCurrentUser() {
    localStorage.removeItem(CURRENT_USER);
  }

  isLoggedIn() {
    if (this.getCurrentUser()) {
      return true;
    }
    return false;
  }

  logout() {
    this.removeAuthToken();
    this.removeCurrentUser();
    localStorage.removeItem('persist:root');
  }
}

export const storageService = new StorageService();
