import React from 'react';
import './resetPasswordForm.scss';
import { Form, Input, Button } from 'antd';
import { Container, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { validatePassword } from '../../../../core/utils/customValidator';

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmError: '',
      newPassword: '',
      newConfirmPassword: '',
      title: 'Reset Password',
    };
  }

  onChange = () => {
    this.setState({
      confirmError: '',
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const password = values.password.trim();
        this.props.onResetPassword({ newPassword: password });
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(`Your passwords don't match, try again`);
    } else {
      callback();
    }
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.success) {
      setTimeout(() => {
        // this.props.history.push('/login');
        window.location.href = '/#login';
      }, 1000);
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { title } = this.state;
    if (!this.props.success) {
      return (
        <Form className="reset-password-form">
          <h3 className="text-center title-5">{title}</h3>

          <Form.Item label="New Password">
            {getFieldDecorator('password', {
              rules: [
                {
                  validator: (rule, value, callback) => {
                    const msg = validatePassword(value, 'new password');
                    if (msg) {
                      callback(msg);
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(<Input type="password" placeholder="New password" size="large" />)}
          </Form.Item>
          <Form.Item
            className={this.state.confirmError ? 'mgb-0' : null}
            label="Confirm new password"
          >
            {getFieldDecorator('confirmPassword', {
              rules: [
                {
                  required: true,
                  message: 'Please input the confirmed new password',
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(
              <Input
                type="password"
                placeholder="Confirm new password"
                size="large"
                onBlur={this.handleConfirmBlur}
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              loading={this.props.iconLoading}
              onClick={this.handleSubmit}
              className="reset-password-form-button btn-primary-0"
              size="large"
              disabled={this.props.iconLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      );
    }
    return (
      <section className="reset-password-sent">
        <Container>
          <h1 className="text-center mgb-30">Password reset successful</h1>
          <Row className="sub-title">
            <h3>Redirect to login page!</h3>
          </Row>
        </Container>
      </section>
    );
  }
}

const WrappedResetPasswordForm = Form.create({
  name: 'normal_resetPassword',
})(ResetPasswordForm);

export default withRouter(WrappedResetPasswordForm);
