import React, { useState, useRef, useEffect } from 'react';
import { Form, Input } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserInfo, uploadAvatar } from '../../../../redux/actions/admin/userAction';
import { getS3CDN } from 'src/core/utils/commonUtils';
import { openNotification } from 'src/components/common/notification/notification';
import iCamera from 'src/assets/images/icons/account/camera.svg';
import iLoading from 'src/assets/images/icons/common/loading.svg';
// import './bookStep1.css';

const TopSession = ({ avt, fullName }) => {
  const user = useSelector(state => state.user.data);
  const avtLoading = useSelector(state => state.user.avtLoading);
  const [name, setName] = useState(fullName || '');
  const [isInvalid, setInvalid] = useState(false);
  let uploadInput = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    setName(fullName);
  }, [fullName]);

  const uploadIcon = avtLoading ? (
    <img src={iLoading} alt="" />
  ) : (
    <img src={iCamera} alt="" />
  );

  const onChangeName = () => {
    const { fullName } = user || {};
    const value = name.trim();
    if (value && value === fullName) {
      return;
    }
    if (value) {
      dispatch(updateUserInfo({ fullName: value }));
    } else {
      setInvalid(true);
    }
  };

  const handleUpload = () => {
    let reader = new FileReader();
    let file = uploadInput && uploadInput.current.files[0];
    if (!file) return;
    let fileSize = file.size;
    if (fileSize >= 3145728) {
      openNotification('warning', `File cannot be larger than 3MB`);
      return;
    }
    reader.onloadend = () => {
      dispatch(uploadAvatar({ file, user }));
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="box">
      <div
        className="admin-profile-avatar"
        style={
          !avt
            ? {
                backgroundColor: '#1b998b',
              }
            : {
                backgroundImage: `url(${getS3CDN(avt)})`,
              }
        }
      >
        <input
          disabled={avtLoading}
          className="change-avt"
          type="file"
          onChange={handleUpload}
          ref={uploadInput}
          accept="image/*"
        />
        <div className={`change-avt-ico ${avtLoading ? 'loading' : ''}`}>
          {uploadIcon}
        </div>
        {!avt && (
          <div className="avt-text">
            <span>{fullName && fullName.charAt(0).toUpperCase()}</span>
          </div>
        )}
      </div>
      <Form.Item
        label="Full name"
        required
        validateStatus={isInvalid ? 'error' : ''}
        help={isInvalid ? 'Please input you full name' : ''}
      >
        <Input
          size="large"
          placeholder="Full name"
          value={name}
          name="fullname"
          maxLength="50"
          onChange={e => {
            setName(e.target.value);
            setInvalid(false);
          }}
          onBlur={onChangeName}
          onPressEnter={onChangeName}
        />
      </Form.Item>
    </div>
  );
};

export default TopSession;
