import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const getOurTeamService = () => {
	const url = BaseConfig.END_POINT.ADMIN.OUR_TEAM;
	const response = httpService._get(url);
	return response;
};

export const updateOurTeamService = data => {
	const url = BaseConfig.END_POINT.ADMIN.OUR_TEAM;
	const response = httpService._put(`${url}`, data);
	return response;
};
