import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const getAddhocService = () => {
	const url = BaseConfig.END_POINT.ADMIN.ADD_HOC;
	const response = httpService._get(url);
	return response;
};

export const createAddhocService = data => {
	const url = BaseConfig.END_POINT.ADMIN.ADD_HOC;
	const response = httpService._post(url, data);
	return response;
};

export const updateAddhocService = ({ data, id }) => {
	const url = BaseConfig.END_POINT.ADMIN.ADD_HOC;
	const response = httpService._put(`${url}/${id}`, data);
	return response;
};
