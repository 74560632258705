import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';
import qs from '../../../core/utils/querystring';

export const getProjectsService = params => {
  const url = BaseConfig.END_POINT.ADMIN.PROJECTS;
  if (params.status === 'All') params.status = undefined;
  const queryString = qs.stringify(JSON.parse(JSON.stringify(params)));
  const response = httpService._get(`${url}?${queryString}`);
  return response;
};

export const getProjectsDetailService = id => {
  const url = BaseConfig.END_POINT.ADMIN.PROJECTS;
  const response = httpService._get(`${url}/${id}`);
  return response;
};

export const createProjectService = async (payload = {}) => {
  const { data, uploads } = payload;

  let uploadPayload;

  if (uploads && uploads.length) {
    uploadPayload = uploads.map(upload => {
      const fileOrigin = upload.originFileObj;

      return {
        name: fileOrigin.name,
        thumbType: upload.thumbType,
        type: fileOrigin.type,
      };
    });
  }

  let projectResponse;
  try {
    projectResponse = await httpService._post(BaseConfig.END_POINT.ADMIN.PROJECT_ADMIN, {
      ...data,
      uploads: uploadPayload,
    });
  } catch (e) {
    console.error('error = ', e.stack);
  }

  const { data: responseData } = projectResponse;

  if (responseData.error) return projectResponse;

  const { projectId, projectUploads, creator } = responseData.data;
  const promises = projectUploads.map(
    (projectUpload, index) =>
      new Promise(async (resolve, reject) => {
        try {
          const { signedUrl, filePath, signedUrlThumb, fileThumbPath } = projectUpload;
          const upload = uploads[index];
          const promises = [];

          promises.push(
            httpService._uploadPresignUrl({
              signedUrl,
              file: upload.originFileObj,
              filename: filePath,
            })
          );

          if (signedUrlThumb) {
            promises.push(
              httpService._uploadPresignUrl({
                signedUrl: signedUrlThumb,
                file: upload.thumbFile,
                filename: fileThumbPath,
              })
            );
          }

          let [response] = await Promise.all(promises);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      })
  );

  const storyBoardUploaded = await Promise.all(promises);

  if (storyBoardUploaded.length) {
    const url = BaseConfig.END_POINT.ADMIN.PROJECT_UPLOADS_ADMIN;
    return httpService._post(url, {
      projectId,
      uploads: storyBoardUploaded,
      uploadsSize: data.uploadsSize,
      fileNames: data.fileNames,
      creator,
    });
  }

  return projectResponse;
};

export const searchProjectsService = data => {
  if (data.status === 'All') data.status = '';
  const url = BaseConfig.END_POINT.ADMIN.SEARCH_PROJECT;
  const response = httpService._get(
    `${url}?status=${data.status}&search=${data.searchText}`
  );
  return response;
};

export const updateProjectsService = ({ id, ...rest }) => {
  const url = BaseConfig.END_POINT.ADMIN.PROJECTS_V3;
  const response = httpService._put(`${url}/${id}`, rest);
  return response;
};

export const updateStatusService = data => {
  const url = BaseConfig.END_POINT.ADMIN.PROJECTS_V3;
  const response = httpService._put(`${url}/${data.id}`, {
    status: data.status,
  });
  return response;
};

export const getNumberOfProjectService = () => {
  const url = BaseConfig.END_POINT.ADMIN.PROJECTS_NUMBER;
  const response = httpService._get(`${url}`);
  return response;
};

export const deleteProjectService = ({ projectId }) => {
  const url = BaseConfig.END_POINT.ADMIN.DELETE_PROJECT;
  const response = httpService._delete(`${url}/${projectId}`);
  return response;
};

export const getBriefHistoryProjectService = ({ projectId, briefType }) => {
  const url = `${BaseConfig.END_POINT.ADMIN.PROJECTS_V3}/get-brief-history/${projectId}?briefType=${briefType}`;
  const response = httpService._get(`${url}`);
  return response;
};
