import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../../actions/typeAction';
import {
	getAddonsService,
	createAddonsService,
	updateAddonsService
} from '../../../core/services/modules/addonsService';
import { updateOrderService } from '../../../core/services/modules/orderService';
import { openNotification } from '../../../components/common/notification/notification';

export function* getAddonsSaga() {
	const response = yield call(getAddonsService);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_ADD_ONS_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_ADD_ONS_SUCCESS,
			payload: data
		});
	}
}

export function* createAddonsSaga(action) {
	const response = yield call(createAddonsService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.CREATE_ADD_ONS_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.CREATE_ADD_ONS_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateAddonsSaga(action) {
	const response = yield call(updateAddonsService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_ADD_ONS_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.UPDATE_ADD_ONS_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateOrderSaga(action) {
	const response = yield call(updateOrderService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_ORDER_ADD_ONS_FAILED,
			payload: response.data.error
		});
	} else {
		openNotification('success', 'Update successful');
	}
}

export function* watchAddons() {
	yield takeLatest(actionTypes.GET_ADD_ONS_REQUEST, getAddonsSaga);
	yield takeLatest(actionTypes.CREATE_ADD_ONS_REQUEST, createAddonsSaga);
	yield takeLatest(actionTypes.UPDATE_ADD_ONS_REQUEST, updateAddonsSaga);
	yield takeLatest(actionTypes.UPDATE_ORDER_ADD_ONS_SUCCESS, updateOrderSaga);
}
