import { GET_ACTIVITIES_REQUEST, GET_ACTIVITIES_SUCCESS, GET_ACTIVITIES_FAILED } from '../../actions/typeAction';

const defaultState = {
	success: false,
	error: null,
	data: null,
	isLoading: false
};

const activitiesReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_ACTIVITIES_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case GET_ACTIVITIES_SUCCESS: {
			const docs = state.data && state.data.docs ? state.data.docs : [];
			if (action.payload.params.page === 1) {
				return {
					...state,
					...action.payload.responseData,
					isLoading: false
				};
			}
			return {
				...state,
				...action.payload.responseData,
				data: {
					...action.payload.responseData.data,
					docs: [...docs].concat(action.payload.responseData.data.docs)
				},
				isLoading: false
			};
		}
		case GET_ACTIVITIES_FAILED: {
			return {
				...state,
				error: action.payload,
				isLoading: false
			};
		}
		default:
			return state;
	}
};

export default activitiesReducer;
