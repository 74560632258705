import { connect } from 'react-redux';
import LoginScreen from '../../components/screens/login/loginScreen';
import { login, loginReset } from '../../redux/actions/auth/loginAction';


const mapStateToProps = (state) => {
    return {
        isLoading: state.login.loading,
        error: state.login.error,
        data: state.login.data,
        success: state.login.success
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAuth: (data) => {
            dispatch(login(data))
        },
        onReset: () => {
            dispatch(loginReset())
        }
    };
}

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(LoginScreen);

export default LoginContainer;
