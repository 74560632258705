import {
  ATTACH_LINK_REQUEST,
  ATTACH_LINK_SUCCESS,
  ATTACH_LINK_FAILED,
  LIST_ASSET_REQUEST,
  LIST_ASSET_SUCCESS,
  LIST_ASSET_FAILED,
  CREATE_FOLDER_REQUEST,
  CREATE_FOLDER_SUCCESS,
  CREATE_FOLDER_FAILED,
  SIGN_URL_REQUEST,
  SIGN_URL_SUCCESS,
  SIGN_URL_FAILED,
  DELETE_RESOURCE_REQUEST,
  DELETE_RESOURCE_SUCCESS,
  DELETE_RESOURCE_FAILED,
  GET_GALLERY_FILE_REQUEST,
  GET_GALLERY_FILE_SUCCESS,
  GET_GALLERY_FILE_FAILED,
  UPDATE_RESOURCE_REQUEST,
  UPDATE_RESOURCE_SUCCESS,
  UPDATE_RESOURCE_FAILED,
  RESET_ASSETS,
  DELETE_ALL_RESOURCES_REQUEST,
  DELETE_ALL_RESOURCES_SUCCESS,
  DELETE_ALL_RESOURCES_FAILED,
  RENAME_IMAGE_GALLERY_REQUEST,
  RENAME_IMAGE_GALLERY_SUCCESS,
  RENAME_IMAGE_GALLERY_FAILED,
} from '../../actions/typeAction';

const defaultState = {
  dataSuccess: {},
  dataError: {},
  isLoading: false,
  isError: false,
  isSuccess: false,
  isRemoving: false,
  hasRemovedAll: false,
  hasRenamedImage: false,
};

const assetsReducer = (state = defaultState, action) => {
  switch (action.type) {
    // list assets
    case LIST_ASSET_REQUEST:
    case CREATE_FOLDER_REQUEST:
    case ATTACH_LINK_REQUEST:
    case SIGN_URL_REQUEST:
    case DELETE_RESOURCE_REQUEST:
    case GET_GALLERY_FILE_REQUEST:
    case UPDATE_RESOURCE_REQUEST: {
      return {
        ...state,
        dataSuccess: {},
        dataError: {},
        isLoading: true,
        isError: false,
        isSuccess: false,
      };
    }
    case DELETE_ALL_RESOURCES_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isRemoving: true,
        hasRemovedAll: false,
      };
    }
    case RENAME_IMAGE_GALLERY_REQUEST: {
      return {
        ...state,
        dataSuccess: {},
        dataError: {},
        isLoading: true,
        isError: false,
        isSuccess: false,
        hasRenamedImage: false,
      };
    }
    case LIST_ASSET_SUCCESS:
    case CREATE_FOLDER_SUCCESS:
    case ATTACH_LINK_SUCCESS:
    case SIGN_URL_SUCCESS:
    case DELETE_RESOURCE_SUCCESS:
    case GET_GALLERY_FILE_SUCCESS:
    case UPDATE_RESOURCE_SUCCESS: {
      return {
        ...state,
        dataSuccess: action.payload,
        dataError: {},
        isLoading: false,
        isError: false,
        isSuccess: true,
      };
    }
    case DELETE_ALL_RESOURCES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isRemoving: false,
        hasRemovedAll: true,
      };
    }
    case RENAME_IMAGE_GALLERY_SUCCESS: {
      return {
        ...state,
        dataSuccess: action.payload,
        dataError: {},
        isLoading: false,
        isError: false,
        isSuccess: true,
        hasRenamedImage: true,
      };
    }
    case LIST_ASSET_FAILED:
    case CREATE_FOLDER_FAILED:
    case ATTACH_LINK_FAILED:
    case SIGN_URL_FAILED:
    case DELETE_RESOURCE_FAILED:
    case GET_GALLERY_FILE_FAILED:
    case UPDATE_RESOURCE_FAILED: {
      return {
        ...state,
        dataSuccess: {},
        dataError: action.payload,
        isLoading: false,
        isError: true,
        isSuccess: false,
      };
    }
    case DELETE_ALL_RESOURCES_FAILED: {
      return {
        ...state,
        isRemoving: false,
        hasRemovedAll: false,
      };
    }
    case RESET_ASSETS: {
      return {
        ...state,
        dataSuccess: {},
        dataError: {},
        isLoading: false,
        isError: false,
        isSuccess: false,
      };
    }
    case RENAME_IMAGE_GALLERY_FAILED: {
      return {
        ...state,
        dataSuccess: {},
        dataError: action.payload,
        isLoading: false,
        isError: true,
        isSuccess: false,
        hasRenamedImage: false,
      };
    }
    default:
      return state;
  }
};

export default assetsReducer;
