import { put, call, takeLatest } from 'redux-saga/effects';

import {
	GET_FAQS_FAILED,
	GET_FAQS_SUCCESS,
	CREATE_FAQS_FAILED,
	CREATE_FAQS_SUCCESS,
	UPDATE_FAQS_FAILED,
	UPDATE_FAQS_SUCCESS,
	UPDATE_ORDER_FAQS_FAILED,
	GET_FAQS_REQUEST,
	CREATE_FAQS_REQUEST,
	UPDATE_FAQS_REQUEST,
	UPDATE_ORDER_FAQS_SUCCESS
} from '../../actions/typeAction';
import {
	getFaqsService,
	createFaqsService,
	updateFaqsService
} from '../../../core/services/modules/faqsService';
import { updateOrderService } from '../../../core/services/modules/orderService';
import { openNotification } from '../../../components/common/notification/notification';

export function* getFaqsSaga() {
	const response = yield call(getFaqsService);
	if (response.data.error) {
		yield put({
			type: GET_FAQS_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: GET_FAQS_SUCCESS,
			payload: data
		});
	}
}

export function* createFaqsSaga(action) {
	const response = yield call(createFaqsService, action.payload);
	if (response.data.error) {
		yield put({
			type: CREATE_FAQS_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: CREATE_FAQS_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateFaqsSaga(action) {
	const response = yield call(updateFaqsService, action.payload);
	if (response.data.error) {
		yield put({
			type: UPDATE_FAQS_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: UPDATE_FAQS_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateOrderSaga(action) {
	const response = yield call(updateOrderService, action.payload);
	if (response.data.error) {
		yield put({
			type: UPDATE_ORDER_FAQS_FAILED,
			payload: response.data.error
		});
	} else {
		openNotification('success', 'Update successful');
	}
}

export function* watchFaqs() {
	yield takeLatest(GET_FAQS_REQUEST, getFaqsSaga);
	yield takeLatest(CREATE_FAQS_REQUEST, createFaqsSaga);
	yield takeLatest(UPDATE_FAQS_REQUEST, updateFaqsSaga);
	yield takeLatest(UPDATE_ORDER_FAQS_SUCCESS, updateOrderSaga);
}
