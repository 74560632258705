import { GET_OUR_TEAM_REQUEST, UPDATE_OUR_TEAM_REQUEST } from '../typeAction';

export const getOurTeam = () => ({
	type: GET_OUR_TEAM_REQUEST
});

export const updateOurTeam = data => ({
	type: UPDATE_OUR_TEAM_REQUEST,
	payload: data
});
