import { connect } from 'react-redux';
import ProjectsScreen from '../../../components/screens/admin/projects/projectsScreen';
import {
  getProjects,
  getProjectsDetail,
  searchProjects,
  updateStatus,
  updateProjects,
  clearProjectsDetail
} from '../../../redux/actions/admin/projectsAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';
import { getPackage } from '../../../redux/actions/admin/packageAction';
import { getActivities } from '../../../redux/actions/admin/activitiesAction';

const mapStateToProps = state => {
  return {
    isProjectDeleted: state.projects.isProjectDeleted,
    success: state.projects.success,
    detailData: state.projects.detailData,
    detailError: state.projects.detailError,
    detailLoading: state.projects.detailLoading,
    error: state.projects.error,
    isLoading: state.projects.isLoading,
    data: state.projects.data,
    isGettingDetail: state.projects.isGettingDetail,
    packages: state.packages.data,
    activities: state.activities.data,
    isActivitiesLoading: state.activities.isLoading,
    currentUser: state.user.data,
    currentTopic: state.topics.detailData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetProjects: data => {
      dispatch(getProjects(data));
    },
    onGetProjectsDetail: id => {
      dispatch(getProjectsDetail(id));
    },
    onSearchProjects: data => {
      dispatch(searchProjects(data));
    },
    onUpdateStatus: data => {
      dispatch(updateStatus(data));
    },
    onGetPackages: () => {
      dispatch(getPackage());
    },
    onUpdateProject: data => {
      dispatch(updateProjects(data));
    },
    onClearProjectDetail: () => {
      dispatch(clearProjectsDetail());
    },
    onGetActivities: params => {
      dispatch(getActivities(params));
    },
    onLoadMoreActivities: params => {
      dispatch(getActivities(params));
    },
    onGetCurrentUser: () => {
      dispatch(getCurrentUser());
    },
  };
};

const ProjectsContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectsScreen);

export default ProjectsContainer;
