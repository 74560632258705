import React from 'react';
import { getEnv } from '../../core/configs/envConfig';
import GoldIcon from '../../assets/images/icons/common/gold.svg';
import SilverIcon from '../../assets/images/icons/common/silver.svg';
import BronzeIcon from '../../assets/images/icons/common/bronze.svg';
import {
  VIDEO_STREAM_TYPES,
  VIDEO_TYPES,
  IMAGES_TYPE,
} from 'src/core/constants/constants';
import qs from 'src/core/utils/querystring';

export const urlServer = url => {
  try {
    if (url.includes('http://') || url.includes('https://')) return url;

    return `${getEnv('API_SERVER')}${url}`;
  } catch (e) {}

  return '';
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const formDataConfig = data => {
  let formData = new FormData();
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if (Array.isArray(data[key]) && data[key].length) {
        for (let i = 0; i < data[key].length; i++) {
          formData.append(key, data[key][i]);
        }
      } else {
        formData.append(key, data[key]);
      }
    }
  }
  return formData;
};

export const updateHelper = (state, action) => {
  const index = state.data.findIndex(item => item._id === action.payload.data._id);
  const currentData = [...state.data];
  currentData[index] = { ...currentData[index], ...action.payload.data };
  return currentData;
};

export const currencyFormat = num => {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const UNIT = {
  PER_HOUR: 'PER HOUR',
  PER_VIDEO: 'PER VIDEO',
  PER_SESSION: 'PER SESSION',
  PER_PROJECT: 'PER PROJECT',
};

export const compare = (a, b) => {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
};

export const updatePoliciesHelper = (arr, updateItem) => {
  return arr.map(item => {
    if (item._id !== updateItem._id) {
      return {
        ...item,
        status: !updateItem.status,
      };
    }
    return item;
  });
};

export const scrollTop = () => {
  window.scrollTo(0, 0);
};

export const isImageOrVideo = file => {
  if (file && file.type) {
    if (file.type.indexOf('image') > -1 || file.type.indexOf('video') > -1) {
      return true;
    }
  }
  return false;
};

export const isImage = file => {
  if (file && file.type) {
    return file.type.indexOf('image') > -1;
  }
};

export const isVideo = file => {
  if (file && file.type) {
    return file.type.indexOf('video') > -1;
  }
};

export const detectFile = file => {
  if (file && file.type) {
    if (file.type.indexOf('image') > -1) {
      return 'image';
    }
    if (file.type.indexOf('video') > -1) {
      return 'video';
    }
  }
};

export const extractFileExtension = ({ filename, customExtension }) => {
  if (customExtension) return `.${customExtension}`;

  const [ext] = filename.match(/\.[^/.]+$/, '') || [];
  return ext ? ext.toLowerCase() : '';
};

export const isDeactiveUser = field => {
  return (
    field &&
    ((field.creativeId && field.creativeId.status !== 'active') ||
      (field.customerId && field.customerId.status !== 'active') ||
      (field.editorId && field.editorId.status !== 'active'))
  );
};

export const preventClosing = e => {
  e.preventDefault();
  e.returnValue = '';
};

export const joinArr = arr => {
  if (arr && arr.length) {
    return arr.join(',');
  }
  return '';
};

export const renderTierIcon = tier => {
  switch (tier) {
    case 'bronze':
      return BronzeIcon;
    case 'silver':
      return SilverIcon;
    case 'gold':
      return GoldIcon;
    default:
      return null;
  }
};

export const renderRequireLabel = label => (
  <>
    <span className="icon-required">*</span>
    <span>{label}</span>
  </>
);

export const filterActiveItems = arr => {
  return arr && arr.length ? arr.filter(item => item.status) : [];
};

export const filterItemsByType = (arr, type) => {
  return arr && arr.length ? arr.filter(item => item.type === type) : [];
};

export const isAdmin = user => {
  return user && user.roleId && user.roleId.name === 'admin';
};

export const isSuperAdmin = user => {
  return user && user.roleId && user.roleId.name === 'superadmin';
};

export const filterAcceptedMembers = members => {
  if (members && members.length) {
    const acceptedMembers = members.filter(item => item.status === 'ACCEPT');
    return acceptedMembers && acceptedMembers.length
      ? acceptedMembers.map(item => item.id)
      : [];
  }
  return [];
};

export const getS3Url = url => {
  return `${getEnv('URL_S3')}/${url}`;
};

export const getS3CDN = url => {
  try {
    if (url.includes('http://') || url.includes('https://')) return url;
    return `${getEnv('URL_CDN')}/${url}`;
  } catch (e) {}

  return '';
};

export const debounce = (fn, ms = 0) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const uuid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

export const dataURLtoBlob = (dataURL, fileName) => {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataURL.split(',')[1]);

  // separate out the mime component
  const mime = dataURL.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const _ia = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i);
  }

  let dataView = new DataView(arrayBuffer);

  return new File([dataView.buffer], `${uuid()}.png`, { type: mime });
};

export const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

export const extractUrls = str => {
  if (!str) return [];
  return (
    str.match(
      new RegExp(
        /([--:\w?@%&+~#=]*\.[a-z]{2,4}\/{0,2})((?:[?&](?:\w+)=(?:\w+))+|[--:\w?@%&+~#=]+)?/g
      )
    ) || []
  );
};

export const youtubeLinkParser = url => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
  const match = url.match(regExp);
  if (match && match[2].length === 11) {
    return match[2];
  }

  return null;
};

export const checkYoutubeLink = url => {
  if (!url) return false;
  const Regex =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;
  const isUserAndChannelLink = new RegExp(/(\/user+|\/channel+)/gi).test(url);
  return url.match(Regex) && !isUserAndChannelLink;
};

export const generateYoutubeThumbnail = url => {
  const isYoutubeLink = checkYoutubeLink(url);
  if (isYoutubeLink) {
    const youtubeVideoId = youtubeLinkParser(url);
    return `https://img.youtube.com/vi/${youtubeVideoId}/hqdefault.jpg`;
  }
};

export const getThumbnailPath = url => {
  const check = /^Project\//.test(url);
  if (url && !check) {
    return `Project/${url}`;
  }
  return url;
};

export const isVideoStreamFile = ({ filename } = {}) => {
  const fileExt = extractFileExtension({ filename });
  return VIDEO_STREAM_TYPES.includes(fileExt);
};

export const isVideoFile = ({ filename } = {}) => {
  const fileExt = extractFileExtension({ filename });
  return VIDEO_TYPES.includes(fileExt);
};

const replaceFileExtension = ({ filename, extension }) => {
  return filename.replace(/\.[^/.]+$/gi, extension);
};

export const encodeURIThumbnail = (thumbnailUrl, originalUrl) => {
  if (thumbnailUrl) {
    const checkVideoFile = isVideoFile({ filename: thumbnailUrl });

    if (checkVideoFile) {
      thumbnailUrl = replaceFileExtension({ filename: thumbnailUrl, extension: '.png' });
    } else {
      thumbnailUrl = getThumbnailPath(thumbnailUrl);
    }

    return encodeURI(getS3CDN(thumbnailUrl))
      .replace(/\s/gi, '%20')
      .replace(/\(/gi, '%28')
      .replace(/\)/gi, '%29');
  }
  return '.' + getFileType(originalUrl);
};

export const getFileType = filename => {
  if (!filename) return '';
  const fileType =
    filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
  return fileType.toLowerCase();
};

export const isFileHasThumbnail = filename => {
  const fileTypes = [...IMAGES_TYPE, '.mp4'];
  const type = extractFileExtension({ filename });
  return fileTypes.includes(type);
};

/**
 * @author HoangNL
 * @param location
 */
export function isUrl(url) {
  const regex =
    /([--:\w?@%&+~#=]*\.[a-z]{2,4}\/{0,2})((?:[?&](?:\w+)=(?:\w+))+|[--:\w?@%&+~#=]+)?/g;
  return regex.test(url);
}

/**
 * @author DatND
 * @param url
 */

export const checkDriveLink = url => {
  if (!url) return false;
  return (
    url.includes('https://drive.google.com') ||
    url.includes('https://docs.google.com') ||
    url.includes('https://script.google.com')
  );
};

export function getIdLinkDrive(url) {
  try {
    return url.split('/')[5];
  } catch (err) {
    console.log('err', err);
    return '';
  }
}

/**
 * @author DatND
 * @param idUrl
 */
export function getThumbnailLinkDrive(idUrl) {
  try {
    return `https://drive.google.com/thumbnail?id=${idUrl}&sz=w400-h300-p-k-nu`;
  } catch (err) {
    console.log('err', err);
    return '';
  }
}

/*
 * @author DatND
 * @param iconLink
 */
export function getIconLinkDrive(iconLink, size) {
  try {
    if (!iconLink) return;
    return iconLink.replace('16', size);
  } catch (err) {
    console.log('err', err);
    return '';
  }
}

export function truncateText(text, maxLength) {
  if (!maxLength) maxLength = 15;
  if (text === undefined || text === null) return null;
  if (text.length > maxLength) {
    text = text.substr(0, maxLength) + '...';
  }
  return text;
}

export function trimObject(object) {
  if (object !== undefined && object.constructor === Object) {
    for (var propName in object) {
      if (typeof object[propName] === 'string') {
        object[propName] = object[propName].trim();
      }
    }
  }
  return object;
}

export function convertUrlParametersToObject(location) {
  try {
    if (!location) return '';

    let search = location.search.substring(1);
    return qs.parse(search);
  } catch (e) {}

  return '';
}
