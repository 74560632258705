import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { resetNotification } from 'src/redux/actions/admin/notificationAction';

const HeaderAuthChecker = (props, ref) => {
  const { onInitialSocket } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    onInitialSocket();

    return () => {
      dispatch(resetNotification());
    };
  }, [dispatch, onInitialSocket]);

  return null;
};

HeaderAuthChecker.propTypes = {
  onInitialSocket: PropTypes.func,
  logout: PropTypes.func,
};

export default HeaderAuthChecker;
