import React from 'react';
import './teamInfo.scss';
import { Row, Col, Input, Modal, Button, notification, Form } from 'antd';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import CustomerInvitationModal from '../../projectsDetail/customerInformation/customerInvitationModal/customerInvitationModal';
import { deleteAccount } from 'src/core/services/modules/userService.js';
import AssignToTeam from './assignToTeam';
import { BaseConfig } from '../../../../../core/configs/baseConfig';
import { httpService } from '../../../../../core/services/httpService';
import { openNotification } from '../../../notification/notification';
import { getCustomersDetail } from 'src/redux/actions/admin/customersAction';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { sendGetCustomersAction } from 'src/core/utils/event_emitter/handler';

const { TextArea } = Input;
const { confirm } = Modal;

class TeamInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      modalAssignToTeam: false,
    };
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
  };

  openModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  openRemoveTeamModal = () => {
    const { customerInfo } = this.props;
    let confirmModal = {
      title: 'Remove team member',
      okText: 'Yes',
      cancelText: 'No',
      closable: true,
      onCancel: () => {},
      onOk: async () => {
        try {
          const dataRemove = {
            idTeamWork: customerInfo.team._id,
            idCustomer: customerInfo._id,
            isAdmin: customerInfo.isAdmin
          };
          const url = BaseConfig.END_POINT.ADMIN.REMOVE_MEMBER_TEAM;
          const { data } = await httpService._post(url, dataRemove);
          if (!data.success) {
            openNotification('error', data.error.message);
          } else {
            openNotification('success', 'Successfully!');
            sendGetCustomersAction();
            this.props.onGetCustomersDetail(customerInfo._id);
          }
        } catch(err) {
          console.log('err', err);
        }
      },
    };
    let content = 'Are you sure to remove this team member?';
    if (customerInfo.isAdmin) {
      if (customerInfo.team.members.length) {
        content = 'Please transfer this team admin to other team member first';
        confirm({
          title: 'Remove team member',
          okText: 'Yes',
          okCancel: false,
          content,
          onCancel: () => {},
          onOk: () => {}
        });
        return;
      } else {
        content =
          'You are going to remove this team which has team admin only. Are you sure?';
      }
    }
    confirm({
      ...confirmModal,
      content,
    });
  };

  closeModalAssignToTeam = () => {
    this.setState({
      modalAssignToTeam: false,
    });
  };

  openModalAssignToTeam = () => {
    this.setState({
      modalAssignToTeam: true,
    });
  };

  handleRedirect = () => {
    const { customerInfo } = this.props;
    const team = customerInfo && customerInfo.team;
    const url = team ? `/?teamId=${team._id}` : `/?customerId=${customerInfo._id}`;
    this.props.history.push(url);
  };

  handleDeleteAcount = () => {
    const { customerInfo, onGetCustomers, onClose } = this.props;
    const { userId } = customerInfo || {};
    confirm({
      title: 'Delete account',
      content: 'Are you sure want to delete this account ?',
      okText: 'No',
      cancelText: 'Yes',
      className: 'confirm-delete-account',
      onCancel: async () => {
        if (this.checkDeleteAccount()) {
          try {
            const res = await deleteAccount(userId._id);
            const { data } = res;
            if (data.success) {
              notification['success']({
                message: 'Delete success',
              });
              onGetCustomers();
              return onClose();
            }
            notification['warning']({
              message: data.error.message,
            });
          } catch (error) {
            notification['warning']({
              message: 'Something wrong!',
            });
          }
        }
      },
      onOk: () => {
        // this.props.onClose();
      },
    });
  };

  checkDeleteAccount = () => {
    const { customerInfo, currentUser } = this.props;
    const { roleId } = currentUser || {};
    const { isAdmin, team } = customerInfo || {};
    const canDeleted =
      roleId &&
      roleId.name === 'superadmin' &&
      (!isAdmin || (isAdmin && team && team.members.length === 0)) &&
      customerInfo &&
      customerInfo.userId &&
      !customerInfo.userId.isDelete;

    return canDeleted;
  };

  onUpdate = (property, value) => {
    const { customerInfo } = this.props;
    const valueData = typeof value === 'string' ? value.trim() : value;
    this.props.onUpdateCompanyUser({
      id: customerInfo._id,
      [property]: valueData,
    });
  };

  render() {
    const { modalVisible, modalAssignToTeam } = this.state;
    const { customerInfo } = this.props;
    const { getFieldDecorator } = this.props.form;
    const user = customerInfo && customerInfo.userId;
    const team = customerInfo && customerInfo.team;
    const company =
      (customerInfo && customerInfo.userId && customerInfo.userId.company) || '';
    const name = team && team.name;
    const createdBy = team && team.createdBy;
    const address = team && team.address;
    const businessTypeId = team && team.businessTypeId;
    const createdAt = team && team.createdAt;
    const bio = team && team.bio;
    const teamId = team && team._id;

    return (
      <div className="team-info-container">
        <h6 className="heading">Team Information</h6>
        <Row className="info-item-container">
          <Col span={6} className="mt-1">
            <span className="item-title mt-2">Company</span>
          </Col>
          <Col span={12}>
            <Form.Item>
              {getFieldDecorator('company', {
                initialValue: company,
              })(
                <Input
                  onPressEnter={e => this.onUpdate('company', e.target.value)}
                  onBlur={e => {
                    if (company !== e.target.value) {
                      this.onUpdate('company', e.target.value);
                    }
                  }}
                  placeholder="Company"
                  size="default"
                />
              )}
            </Form.Item>
          </Col>
          <CustomerInvitationModal
            teamId={teamId}
            modalVisible={modalVisible}
            onCloseModal={this.closeModal}
          />
        </Row>
        <Row className="info-item-container">
          <Col span={6}>
            <span className="item-title">Team Name</span>
          </Col>
          <Col span={11}>
            <Input className="item-input" disabled value={name} />
          </Col>
          {teamId && (
            <>
              <Col span={3}>
                <Button
                  type="primary"
                  className="btn-primary-0"
                  disabled={user.isDelete}
                  onClick={this.openModal}
                >
                  Invite
                </Button>
              </Col>
              <Col span={3}>
                <Button
                  type="primary"
                  className="btn-primary-0"
                  disabled={user.isDelete}
                  onClick={this.openRemoveTeamModal}
                >
                  Remove
                </Button>
              </Col>
            </>
          )}
          {!teamId && (
            <Col span={3}>
              <Button
                type="primary"
                className="btn-primary-0"
                disabled={user.isDelete}
                onClick={this.openModalAssignToTeam}
              >
                Assign to team
              </Button>
            </Col>
          )}
        </Row>
        <Row className="info-item-container">
          <Col span={6}>
            <span className="item-title">Who created</span>
          </Col>
          <Col span={12}>
            <Input
              className="item-input"
              disabled
              value={createdBy && createdBy.fullName}
            />
          </Col>
        </Row>
        <Row className="info-item-container">
          <Col span={6}>
            <span className="item-title">Address</span>
          </Col>
          <Col span={12}>
            <Input className="item-input" disabled value={address} />
          </Col>
        </Row>
        <Row className="info-item-container">
          <Col span={6}>
            <span className="item-title">Business Type</span>
          </Col>
          <Col span={12}>
            <Input
              className="item-input"
              disabled
              value={businessTypeId && businessTypeId.name}
            />
          </Col>
        </Row>
        <Row className="info-item-container">
          <Col span={6}>
            <span className="item-title">Date Created</span>
          </Col>
          <Col span={12}>
            <Input
              className="item-input"
              disabled
              value={createdAt && moment(createdAt).format('MMM DD, YYYY [at] HH:mm')}
            />
          </Col>
        </Row>
        <Row className="info-item-container">
          <Col span={6}>
            <span className="item-title">Bio</span>
          </Col>
          <Col span={18}>
            <TextArea className="item-text-area" disabled value={bio} />
          </Col>
        </Row>
        <h6 className="heading info-link see-link" onClick={this.handleRedirect}>
          See all {user && user.fullName}'s Projects
        </h6>
        <Button
          type="primary"
          className="d-block ml-auto"
          onClick={this.handleDeleteAcount}
          disabled={!this.checkDeleteAccount()}
        >
          Delete account
        </Button>
        {
          modalAssignToTeam && <AssignToTeam
            customerInfo={customerInfo}
            visibleModal={modalAssignToTeam}
            onCloseModal={this.closeModalAssignToTeam}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetCustomersDetail: params => {
      dispatch(getCustomersDetail(params));
    },
  };
};

const WrappedFormInfo = Form.create({ name: 'team_info' })(TeamInfo);

export default withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(WrappedFormInfo))
);
