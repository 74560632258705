import React, { memo, useEffect, useRef, useState } from 'react';
import SendImgIcon from 'src/assets/images/icons/topic/send-img.svg';
import SendFileIcon from 'src/assets/images/icons/topic/send-file.svg';
import SendIcon from 'src/assets/images/icons/topic/send.svg';
import { IMAGES_TYPE, FILES_TYPE } from 'src/core/constants/constants.js';
import { debounce } from 'src/core/utils/commonUtils';
import { verifyTokenDrive } from 'src/core/services/modules/googleDriveService';
import { Icon, Menu, Dropdown, Modal, Input } from 'antd';
import ModalGoogleDrive from 'src/components/common/modalGoogleDrive';
import IconImageThumbnail from 'src/assets/images/google-login/icons8-google-drive-topic.svg';

const { TextArea } = Input;

const ChatForm = props => {
  const {
    onSubmitMessage = () => null,
    onUploadImage = () => null,
    onUploadFile = () => null,
  } = props;
  const [value, setValue] = useState('');
  const [isDisable, setIsDisable] = useState(false);
  const [visible, setVisible] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [visibleDrive, setVisibleDrive] = useState(false);
  // const [isExpand, setIsExpand] = useState(false);
  const isExpandState = useState(false);
  const setIsExpand = isExpandState[1];

  const textAreaRef = useRef();
  const inputImageRef = useRef();
  const inputFileRef = useRef();

  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.current.focus();
    }
    verifyToken();
  }, []);

  const verifyToken = async () => {
    try {
      const { data: result } = await verifyTokenDrive();
      setAccessToken(result.data.accessToken);
    } catch (err) {
      console.log('err', err);
    }
  };

  const onChange = e => {
    setValue(e.target.value);
  };

  const onSubmit = async e => {
    try {
      setIsDisable(true);
      e.preventDefault();
      setValue('');
      await onSubmitMessage(value);
      setIsDisable(false);
    } catch (err) {
      console.log('err', err);
    }
  };

  const onPressEnter = e => {
    if (e.key === 'Enter') {
      if (isDisable) return;
      if (!e.shiftKey) {
        e.preventDefault();
        debounce(onSubmit(e));
        setIsDisable(true);
        setIsExpand(false);
      } else {
        setIsExpand(true);
      }
    }
  };

  const openModalDrive = () => {
    if (!accessToken) {
      setVisibleDrive(true);
      return;
    }
    setVisible(true);
  };
  const closeModalDrive = () => {
    setVisible(false);
  };

  const menu = (
    <Menu>
      <Menu.Item className="p-2" onClick={() => inputFileRef.current.click()}>
        <div className="image-upload">
          <label htmlFor="file-upload-1">
            <Icon
              type="desktop"
              style={{
                fontSize: 25,
                color: '#f46036',
                marginRight: 7,
                marginLeft: 2,
                marginTop: -2,
              }}
            />
          </label>
          <input
            id="file-upload-1"
            type="file"
            onChange={onUploadFile}
            ref={inputFileRef}
            accept={FILES_TYPE.join(',')}
            style={{ display: 'none' }}
          />
          My Device
        </div>
      </Menu.Item>
      <div style={{ borderBottom: '1px solid #e8e8e8' }}></div>
      <Menu.Item className="p-2" onClick={openModalDrive}>
        <img src={IconImageThumbnail} alt='' /> Google Drive
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="conversation-editor">
      <div className={`conversation-compose`}>
        <TextArea
          autoSize={{ minRows: 1, maxRows: 5 }}
          ref={textAreaRef}
          className="input-msg"
          value={value}
          onChange={onChange}
          onKeyDown={onPressEnter}
          placeholder="Leave a comment..."
        />
        <div className="photo">
          <div className="upload-btn-wrapper add-cursor">
            <img src={SendImgIcon} alt="" width="25" height="25" />
            <input
              className="input-upload-invisible add-cursor"
              onChange={onUploadImage}
              ref={inputImageRef}
              type="file"
              accept={IMAGES_TYPE.join(',')}
            />
          </div>
          {/* <div className="upload-btn-wrapper add-cursor">
            <img
              src={SendFileIcon}
              alt=""
              width="20"
              height="20"
              className="add-cursor"
            />
            <input
              className="input-upload-invisible add-cursor"
              onChange={onUploadFile}
              ref={inputFileRef}
              type="file"
              accept={FILES_TYPE.join(',')}
            />
          </div> */}
          <Dropdown overlay={menu} placement="topRight" trigger="click">
            <img src={SendFileIcon} alt="" />
          </Dropdown>
          {visible && (
            <ModalGoogleDrive
              visible={visible}
              closeModalDrive={closeModalDrive}
              onSubmitMessage={onSubmitMessage}
            />
          )}
        </div>
        <button className="send" type="button" onClick={onSubmit} disabled={isDisable}>
          <div className="circle">
            <img src={SendIcon} alt="" />
          </div>
        </button>
      </div>
      <Modal
        visible={visibleDrive}
        footer={null}
        title={
          <div>
            <span>
              <img
                className="mr-2"
                src={IconImageThumbnail}
                alt=""
                style={{ width: 25 }}
              />
              Google Drive Authenticate?
            </span>
          </div>
        }
        width={500}
        onCancel={() => setVisibleDrive(false)}
        destroyOnClose={true}
        closable={true}
        maskClosable={false}
      >
        <div>
          Click <a href="/setting">here</a> to allow access to your Google Drive so you
          can upload files.
        </div>
      </Modal>
    </div>
  );
};

export default memo(ChatForm);
