import React, { PureComponent } from 'react';
import { Link, Route } from 'react-router-dom';
import { Pagination, Drawer, Button, Modal, Form, Input, Icon, Tag, Table } from 'antd';
import { Jumbotron, Container } from 'react-bootstrap';
import debounce from 'lodash/debounce';
import './creativesScreen.scss';
import { joinArr, renderTierIcon } from '../../../../core/utils/commonUtils';
import { CREATIVES_TIERS, CREATIVES_STATUS } from '../../../../core/constants/constants';
import CreativesForm from '../../../common/form/creativesForm/creativesForm';
import { validateEmail } from '../../../../core/utils/customValidator';
import ReactPhoneInput from 'react-phone-input-2';
import FilterIcon from '../../../../assets/images/icons/common/filter.svg';
import FilterOptions from '../../../common/filterOptions/filterOptions';
import UserAvatar from 'src/components/common/userAvatar';
import SearchColumn from '../../../common/searchColumn/searchColumn';
import qs from 'src/core/utils/querystring';
import { shallowEqual } from 'react-redux';

const { TextArea, Search } = Input;

class CreativesScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Admin Creatives - VidOps',
      currentPage: 1,
      currentLimit: '',
      visibleDrawer: false,
      selectedStatus: [],
      selectedTiers: [],
      modalVisible: false,
      phoneNumber: '',
      phoneNumberRequired: false,
      selectedLocation: '',
      selectedTrade: '',
      textSearchCreative: '',
      isSearch: false,
      forceClearFilter: false,
    };
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
      phoneNumberRequired: false,
      phoneNumber: '',
    });
  };

  openModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  renderRequireLabel = label => (
    <>
      <span className="icon-required">*</span>
      <span>{label}</span>
    </>
  );

  renderModal = () => {
    const { modalVisible, phoneNumberRequired, phoneNumber } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { isSendingInvitation } = this.props;
    return (
      <Modal
        visible={modalVisible}
        onCancel={this.closeModal}
        className="creatives-invitation-modal"
        footer={null}
        closable={false}
        destroyOnClose={true}
      >
        <h1 className="invitation-title">Invite Creatives to VidOps</h1>
        <span className="invitation-description">
          Send an invite to an creatives not already registered with us. When you invite
          new members to the project they can only see workspaces and topics they're
          invited to.
        </span>
        <Form className="invitation-form">
          <Form.Item label="Full name">
            {getFieldDecorator('fullName', {
              rules: [
                {
                  required: true,
                  message: 'Please input Full name',
                },
                {
                  whitespace: true,
                  message: 'Please do not input whitespace only',
                },
              ],
              initialValue: null,
            })(<Input size="large" />)}
          </Form.Item>
          <Form.Item label={this.renderRequireLabel('Email')}>
            {getFieldDecorator('email', {
              rules: [
                {
                  validator: (rule, value, callback) => {
                    const msg = validateEmail(value, 'Email');
                    if (msg) {
                      callback(msg);
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(<Input placeholder="Email" size="large" />)}
          </Form.Item>
          <Form.Item label={this.renderRequireLabel('Phone')}>
            <ReactPhoneInput
              country={'gb'}
              className="phone-number"
              required
              onChange={value => {
                if (value) {
                  this.setState({
                    phoneNumberRequired: false,
                  });
                }
                this.setState({ phoneNumber: value });
              }}
              value={phoneNumber}
            />
            {phoneNumberRequired && (
              <div className="ant-form-explain">Please input phone number!</div>
            )}
          </Form.Item>
          <Form.Item label="Note">
            {getFieldDecorator('note', {
              initialValue: null,
            })(<TextArea rows={4} />)}
          </Form.Item>
          <div className="buttons-container">
            <Form.Item>
              <Button
                type="primary"
                loading={isSendingInvitation}
                onClick={this.handleSubmit}
                className="btn-primary-0"
                size="large"
                disabled={isSendingInvitation}
              >
                Send Invitaion
              </Button>
              <Button
                type="primary"
                onClick={this.closeModal}
                className="btn btn-cancel"
                size="large"
              >
                Cancel
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    );
  };

  componentDidMount() {
    const { currentPage, currentLimit, selectedStatus, selectedTiers } = this.state;
    this.props.onGetCurrentUser();
    this.getCreatives({
      page: currentPage,
      limit: currentLimit,
      status: joinArr(selectedStatus),
      tiers: joinArr(selectedTiers),
    });

    this.onInitCreative();
  }

  onInitCreative() {
    const location = this.props.location;
    const search = location.search || '';
    const queryString = search.replace('?', '');
    const queryData = qs.parse(queryString);
    const { creativeId } = queryData || {};
    if (creativeId) {
      this.props.onGetCreativesDetail(creativeId);
    }

  }

  getCreatives = params => {
    this.props.onGetCreatives(params);
  };

  renderFilterIcon = () => {
    return <img src={FilterIcon} alt="Filter icon" className="filter-icon" />;
  };

  handleSelectTiers = selectedTiers => {
    const {
      currentLimit,
      selectedStatus,
      selectedLocation,
      selectedTrade,
      textSearchCreative,
    } = this.state;
    this.setState({
      selectedTiers,
      forceClearFilter: false,
      isSearch: false,
    });
    this.getCreatives({
      page: 1,
      limit: currentLimit,
      status: joinArr(selectedStatus),
      tiers: joinArr(selectedTiers),
      location: selectedLocation,
      trade: selectedTrade,
      search: textSearchCreative,
    });
  };

  handleSelectStatus = selectedStatus => {
    const {
      currentLimit,
      selectedTiers,
      selectedLocation,
      selectedTrade,
      textSearchCreative,
    } = this.state;
    this.setState({
      selectedStatus,
      forceClearFilter: false,
      isSearch: false,
    });
    this.getCreatives({
      page: 1,
      limit: currentLimit,
      status: joinArr(selectedStatus),
      tiers: joinArr(selectedTiers),
      location: selectedLocation,
      trade: selectedTrade,
      search: textSearchCreative,
    });
  };

  renderFilterCustom = dataIndex => {
    return {
      filterDropdown: ({ confirm, clearFilters }) => {
        switch (dataIndex) {
          case 'tier':
            return (
              <FilterOptions
                confirm={confirm}
                clearFilters={clearFilters}
                onSelectTiers={this.handleSelectTiers}
                options={CREATIVES_TIERS}
                forceClearFilter={this.state.forceClearFilter}
              />
            );
          case 'status':
            return (
              <FilterOptions
                confirm={confirm}
                clearFilters={clearFilters}
                onSelectTiers={this.handleSelectStatus}
                options={CREATIVES_STATUS}
                forceClearFilter={this.state.forceClearFilter}
              />
            );
          case 'location':
            return (
              <SearchColumn
                onChange={e => this.searchHandler(e.target.value, 'Location')}
              />
            );
          case 'trade':
            return (
              <SearchColumn onChange={e => this.searchHandler(e.target.value, 'Trade')} />
            );
          default:
            return null;
        }
      },
      filterIcon: filtered => {
        return (
          <Icon
            type="filter"
            theme="filled"
            style={{ color: filtered && !this.state.isSearch ? '#1890ff' : undefined }}
          />
        );
      },
    };
  };

  formatColumns = () => {
    const { url } = this.props.match;
    return [
      {
        title: '',
        dataIndex: 'tier',
        render: (tier, record) => {
          const { avt, fullName } = record || {};
          return (
            <Link
              className="creatives-tier"
              to={{ pathname: url + '/' + record._id }}
              // onClick={() => this.props.onGetCreativesDetail(record._id)}
            >
              <UserAvatar
                fullName={fullName}
                avatar={avt}
                status={true}
                size="small"
                iconSrc={renderTierIcon(tier)}
              />
            </Link>
          );
        },
        ...this.renderFilterCustom('tier'),
        filtered: !this.state.forceClearFilter && this.state.isSearch,
      },
      {
        title: 'Full Name',
        dataIndex: 'fullName',
        width: 235,
        // render: (fullName, record) => record && record.userId && record.userId.fullName
      },
      {
        title: 'Email',
        dataIndex: 'email',
        // dataIndex: 'userId[email]'
      },
      {
        title: 'Location',
        dataIndex: 'location',
        render: (dataIndex, record) => {
          const { city, country } = record;
          return city || country ? `${city}, ${country}` : null;
        },
      },
      {
        title: 'Vetting',
        dataIndex: 'status',
        render: (status, record) => {
          const { isDelete } = record;
          const isDeleteTag = isDelete && <Tag color="#f50">Deleted</Tag>;
          const statusIndex = CREATIVES_STATUS.findIndex(item => item.value === status);
          return statusIndex > -1 ? (
            <div className="creative-status-container">
              <span className="creative-status">
                {CREATIVES_STATUS[statusIndex].label}
              </span>
              {isDeleteTag}
            </div>
          ) : null;
        },
        ...this.renderFilterCustom('status'),
        filtered: !this.state.forceClearFilter && this.state.isSearch,
      },
      {
        title: 'Trade',
        dataIndex: 'trade',
      },
    ];
  };

  formatData = data => {
    if (data && data.length) {
      return data.map((item, index) => {
        return {
          ...item,
          key: item._id,
        };
      });
    }
  };

  onChangePage = page => {
    const {
      selectedTiers,
      selectedStatus,
      currentLimit,
      selectedTrade,
      textSearchCreative,
    } = this.state;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        this.props.onGetCreatives({
          page,
          limit: currentLimit,
          status: joinArr(selectedStatus),
          tiers: joinArr(selectedTiers),
          trade: selectedTrade,
          search: textSearchCreative,
        });
      }
    );
  };

  closeDrawer = (e) => {
    // this.setState({
    //   visibleDrawer: false,
    // });
    const { path } = this.props.match;
    this.props.history.push({
      pathname: path
    });
  };

  // openDrawer = () => {
  //   this.setState({
  //     visibleDrawer: true,
  //   });
  // };

  handleDetail = (creativeId) => {
    if (!creativeId) {
      return;
    }
    this.props.onGetCreativesDetail(creativeId);
  }

  handleSubmit = e => {
    const { phoneNumber } = this.state;
    e.preventDefault();
    this.setState({
      phoneNumberRequired: !phoneNumber ? true : false,
    });
    this.props.form.validateFields((err, values) => {
      if (!err && phoneNumber) {
        const data = {
          fullName: values.fullName && values.fullName.trim(),
          email: values.email && values.email.trim(),
          phoneNumber,
          note: values.note && values.note.trim(),
        };
        this.props.onSendInvitation(data);
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (!shallowEqual(this.props.location, prevProps.location)) {
      this.onInitCreative();
    }

    if (this.props.hasSentInvitation !== prevProps.hasSentInvitation) {
      this.closeModal();
    }

    if (this.props.match.isExact) {
      this.props.onClearCreativesDetail();
    }
    // if (this.props.creativesDetail !== prevProps.creativesDetail) {
    //   this.openDrawer();
    // }
  }

  handleSearchCreative = debounce(async text => {
    const textSearch = text.trim();
    this.getCreatives({
      page: 1,
      search: textSearch,
      limit: this.state.currentLimit,
      selectedStatus: [],
      status: joinArr(this.state.currentStatus),
    });
    this.setState({ selectedStatus: [], selectedTiers: [] });
    this.setState({ forceClearFilter: true, isSearch: true });
  }, 400);

  render() {
    const { path } = this.props.match;
    const { creatives, creativesDetail, creativesDetailError } = this.props;
    const { textSearchCreative } = this.state;
    let dataSource = [];
    const columns = this.formatColumns();
    if (creatives && creatives.list) {
      dataSource = this.formatData(creatives.list);
    }
    return (
      <main role="main" className="page-content creatives-screen">
        <Jumbotron className="mgt-40">
          <h2 className="sub-heading">All Creatives</h2>
          <p className="description">Manage all creatives</p>
          <div className="divider"></div>
          <Container>
            <div className="control-container">
              <Form>
                <Form.Item>
                  <Search
                    placeholder="search..."
                    allowClear
                    value={textSearchCreative}
                    onChange={e => this.setState({ textSearchCreative: e.target.value })}
                    onSearch={this.handleSearchCreative}
                    style={{ width: 300, height: 42 }}
                  />
                </Form.Item>
              </Form>
              {!!creatives.total && (
                <Pagination
                  className="customer-pagination"
                  total={creatives.total}
                  showTotal={(total, range) => {
                    return `${range[0]}-${range[1]} of ${total} items`;
                  }}
                  pageSize={10}
                  simple={false}
                  onChange={this.onChangePage}
                />
              )}
            </div>
            <Table
              columns={columns}
              dataSource={dataSource}
              bordered
              pagination={false}
              // rowClassName={record => record.isDelete && 'disabled-row'}
            />

            <Route exact path={`${path}/:itemId`}
              render={routeProps => (
                <Drawer
                  className="drawer-detail-creatives"
                  width={790}
                  placement="right"
                  closable={true}
                  onClose={this.closeDrawer}
                  visible={true}
                  destroyOnClose={true}
                >
                  <CreativesForm
                    {...routeProps}
                    creativesError={creativesDetailError}
                    creativesDetail={creativesDetail}
                    onInit={this.handleDetail}
                    onClose={this.closeDrawer}
                  />
                </Drawer>
              )}
            />

            {this.renderModal()}
          </Container>
        </Jumbotron>
      </main>
    );
  }
}

const WrappedCreativesScreen = Form.create({ name: 'normal_creatives' })(CreativesScreen);

export default WrappedCreativesScreen;
