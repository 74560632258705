import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import App from './core/app';
import * as serviceWorker from './serviceWorker';
import './core/configs/i18nConfig';
import 'font-awesome/css/font-awesome.min.css';
import './assets/stylesheets/app.css';
import 'react-phone-input-2/lib/style.css';

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      {/*<PersistGate loading={null} persistor={persistor}>*/}
      <BrowserRouter>
        <App />
      </BrowserRouter>
      {/*</PersistGate>*/}
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);

// Unregister worker
serviceWorker.unregister();
// process.env.REACT_APP_STAGE === 'UAT' || process.env.REACT_APP_STAGE === 'PROD'
//   ? serviceWorker.register()
//   : serviceWorker.unregister();
