import React from 'react';
import 'antd/dist/antd.css';
import './addonsForm.scss';
import { Form, Input, Button, Select, InputNumber } from 'antd';
import { validatePositive } from '../../../../core/utils/customValidator';

const { Option } = Select;
const { TextArea } = Input;
let id = 0;
class AddonsForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const { isCreate, packages, itemUpdate } = this.props;
				const name = values.name.trim();
				const status = values.status.trim() === 'Active' ? true : false;
				const description = values.description.trim();
				let subCategoryId;
				let prices;
				let order;
				if (isCreate) {
					order = 0;
					subCategoryId = this.props.subCategoryId;
					prices = packages.map(item => {
						return {
							packageId: item._id,
							name: item.name,
							price: Number(values[`price-${item.name}`])
						}
					});
				} else {
					order = values.order;
					subCategoryId = itemUpdate.subCategoryId;
					prices = itemUpdate.packages.map(item => ({
						...item,
						price: Number(values[`price-${item.name}`])
					}));
				}
				const data = {
					name,
					status,
					prices,
					description,
					subCategoryId,
					order
				};
				this.props.isCreate
					? this.props.onCreateAddons(data)
					: this.props.onUpdateAddons(data);
			}
		});
	};

	addInput = () => {
		const { form } = this.props;
		const keys = form.getFieldValue('keys');
		const nextKeys = !keys.length
			? keys.concat(id++)
			: keys.concat(Math.max(...keys) + 10000);
		form.setFieldsValue({
			keys: nextKeys
		});
	};

	removeInput = k => {
		const { form } = this.props;
		const keys = form.getFieldValue('keys');
		const nextKeys = [];
		keys.forEach((item, index) => {
			if (index !== k) {
				nextKeys.push(item);
			}
		});
		form.setFieldsValue({
			keys: nextKeys
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { isCreate, itemUpdate, isLoading, packages } = this.props;
		if (isCreate) {
			return (
				<Form className="addons-form">
					<Form.Item label="Name">
						{getFieldDecorator('name', {
							rules: [
								{
									required: true,
									message: 'Please input addons name'
								},
								{
									whitespace: true,
									message:
										'Please do not input whitespace only'
								}
							],
							initialValue: null
						})(<Input placeholder="Name" size="large" />)}
					</Form.Item>
					<div className="prices-container">
						{packages.map(item => (
							<Form.Item
								key={item._id}
								className="addons-price"
								label={item.name}
							>
								{getFieldDecorator(`price-${item.name}`, {
									rules: [
										{
											validator: (
												rule,
												value,
												callback
											) => {
												const msg = validatePositive(
													value,
													'Price'
												);
												if (msg) {
													callback(msg);
												} else {
													callback();
												}
											}
										}
									],
									initialValue: null
								})(
									<Input
										type="number"
										placeholder="Price"
										size="large"
										step={0.01}
									/>
								)}
							</Form.Item>
						))}
					</div>
					<Form.Item label="Description">
						{getFieldDecorator('description', {
							rules: [
								{
									required: true,
									message: 'Please input price description'
								},
								{
									whitespace: true,
									message:
										'Please do not input whitespace only'
								}
							],
							initialValue: null
						})(<TextArea rows={4} />)}
					</Form.Item>
					<Form.Item label="Status" className="status-item">
						{getFieldDecorator('status', {
							rules: [
								{
									required: true,
									message: 'Please select addons status'
								}
							],
							initialValue: 'Active'
						})(
							<Select>
								<Option value="Active">Active</Option>
								<Option value="Inactive">Inactive</Option>
							</Select>
						)}
					</Form.Item>
					<Form.Item>
						<Button
							type="primary"
							loading={isLoading}
							onClick={this.handleSubmit}
							className="addons-form-button btn-primary-0"
							size="large"
							disabled={isLoading}
						>
							Create
						</Button>
					</Form.Item>
				</Form>
			);
		} else {
			return (
				<Form className="addons-form">
					<Form.Item label="Name">
						{getFieldDecorator('name', {
							rules: [
								{
									required: true,
									message: 'Please input addons name'
								},
								{
									whitespace: true,
									message:
										'Please do not input whitespace only'
								}
							],
							initialValue: itemUpdate.name
						})(<Input placeholder="Name" size="large" />)}
					</Form.Item>
					<div className="prices-container">
						{itemUpdate.packages.map(item => (
							<Form.Item
								key={item.packageId}
								className="addons-price"
								label={item.name}
							>
								{getFieldDecorator(`price-${item.name}`, {
									rules: [
										{
											required: true,
											message:
												'Please input price number'
										},
										{
											validator: (
												rule,
												value,
												callback
											) => {
												const msg = validatePositive(
													value,
													'Price'
												);
												if (msg) {
													callback(msg);
												} else {
													callback();
												}
											}
										}
									],
									initialValue: item.price.toString()
								})(
									<Input
										type="number"
										placeholder="Price"
										size="large"
										step={0.01}
									/>
								)}
							</Form.Item>
						))}
					</div>
					<Form.Item label="Description">
						{getFieldDecorator('description', {
							rules: [
								{
									required: true,
									message: 'Please input price description'
								},
								{
									whitespace: true,
									message:
										'Please do not input whitespace only'
								}
							],
							initialValue: itemUpdate.description
						})(<TextArea rows={4} />)}
					</Form.Item>
					<Form.Item label="Order">
						{getFieldDecorator('order', {
							rules: [
								{
									required: true,
									message: 'Please input price order'
								}
							],
							initialValue: itemUpdate.order
						})(<InputNumber min={0} />)}
					</Form.Item>
					<Form.Item label="Status" className="status-item">
						{getFieldDecorator('status', {
							rules: [
								{
									required: true,
									message: 'Please select addons status'
								}
							],
							initialValue: itemUpdate.status
								? 'Active'
								: 'Inactive'
						})(
							<Select>
								<Option value="Active">Active</Option>
								<Option value="Inactive">Inactive</Option>
							</Select>
						)}
					</Form.Item>
					<Form.Item>
						<Button
							type="primary"
							loading={isLoading}
							onClick={this.handleSubmit}
							className="addons-form-button btn-primary-0"
							size="large"
							disabled={isLoading}
						>
							Update
						</Button>
					</Form.Item>
				</Form>
			);
		}
	}
}

const WrappedAddonsForm = Form.create({ name: 'normal_addons' })(AddonsForm);

export default WrappedAddonsForm;
