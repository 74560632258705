import {
	GET_ADD_ONS_REQUEST,
	GET_ADD_ONS_SUCCESS,
	GET_ADD_ONS_FAILED,
	CREATE_ADD_ONS_REQUEST,
	CREATE_ADD_ONS_SUCCESS,
	CREATE_ADD_ONS_FAILED,
	UPDATE_ADD_ONS_REQUEST,
	UPDATE_ADD_ONS_SUCCESS,
	UPDATE_ADD_ONS_FAILED,
	UPDATE_ORDER_ADD_ONS_SUCCESS,
	UPDATE_ORDER_ADD_ONS_FAILED
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';
// import { updateHelper, compare } from '../../../core/utils/commonUtils';

const defaultState = {
	success: false,
	error: null,
	data: [],
	isLoading: false
};

const addonsReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_ADD_ONS_REQUEST: {
			return {
				...state
			};
		}
		case GET_ADD_ONS_SUCCESS: {
			return {
				...state,
				...action.payload
			};
		}
		case GET_ADD_ONS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				error: action.payload
			};
		}
		case CREATE_ADD_ONS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case CREATE_ADD_ONS_SUCCESS: {
			openNotification('success', 'Create successfully');
			return {
				...state,
				isLoading: false,
				data: action.payload.data
			};
		}
		case CREATE_ADD_ONS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_ADD_ONS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case UPDATE_ADD_ONS_SUCCESS: {
			openNotification('success', 'Update successful');
			return {
				...state,
				isLoading: false,
				data: action.payload.data
			};
		}
		case UPDATE_ADD_ONS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_ORDER_ADD_ONS_SUCCESS: {
			return {
				...state,
				isLoading: false,
				data: action.payload.data
			};
		}
		case UPDATE_ORDER_ADD_ONS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		default:
			return state;
	}
};

export default addonsReducer;
