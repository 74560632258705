import React from 'react';
import { Divider, Avatar, Upload, message, Button, Modal } from 'antd';
import './quoteForm.scss';
import IconFiles from '../../../../../assets/images/icons/common/files.svg';
import { connect } from 'react-redux';
import { sendQuote } from '../../../../../redux/actions/admin/topicsAction';
import { dummyRequest } from '../../../../../core/utils/commonUtils';
import { FILES_TYPE, IMAGES_TYPE } from 'src/core/constants/constants.js';

class QuoteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      files: [],
      filesIsRequired: false,
      isLoading: false
    };
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
  };

  openModal = () => {
    const { files } = this.state;
    if (!files.length) {
      this.setState({
        filesIsRequired: true,
      });
    } else {
      this.setState({
        modalVisible: true,
      });
    }
  };

  handleUpload = info => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      if (info.fileList && info.fileList.length) {
        this.setState({
          filesIsRequired: false,
          files: info.fileList.map(item => item.originFileObj),
        });
      }
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  removeHandler = file => {
    const { files } = this.state;
    this.setState({
      files: files.length && files.filter(item => item.uid !== file.uid),
    });
  };

  renderUploadFiles = () => {
    const props = {
      name: 'file',
      accept: [...FILES_TYPE, ...IMAGES_TYPE].join(','),
      customRequest: dummyRequest,
      headers: {
        authorization: 'authorization-text',
      },
      onChange: this.handleUpload,
      onRemove: this.removeHandler,
    };
    return (
      <Upload {...props}>
        <Avatar className="icon-plus" icon="plus" />
      </Upload>
    );
  };

  sendQuoteHandler = () => {
    const { currentProject } = this.props;
    const customer = (currentProject && currentProject.customerId) || null;
    const participantId = customer && customer.userId && customer.userId._id;
    this.setState({
      isLoading: true
    });
    this.props.onSendQuote({
      projectId: currentProject && currentProject._id,
      participants: [participantId],
      customer,
    });
  };

  renderModalConfirm = () => {
    const { modalVisible, isLoading } = this.state;
    return (
      <Modal
        visible={modalVisible}
        onCancel={this.closeModal}
        className="confirm-send-quote-modal"
        footer={null}
      >
        <>
          <span className="modal-heading">Are you sure you want to send this quote?</span>
          <div className="confirm-btns-container">
            <Button
              className="btn-primary-0 btn btn-cancel"
              onClick={this.closeModal}
              type="default"
            >
              No
            </Button>
            <Button
              className="btn-primary-0 btn"
              loading={isLoading}
              onClick={this.sendQuoteHandler}
              type="primary"
            >
              Yes
            </Button>
          </div>
        </>
      </Modal>
    );
  };

  componentDidUpdate(prevProps) {
    const { files } = this.state;
    if (prevProps.currentTopic !== this.props.currentTopic) {
      this.props.onOpenWorkSpace(files);
    }
  }

  render() {
    const { filesIsRequired } = this.state;
    const { currentProject } = this.props;

    if (currentProject) {
      return (
        <>
          <div className="quote-container">
            <div className="project-header">Quote</div>
            <Divider className="project-divider" />
            <div className="project-info-content">
              <div className="content-item files-wrapper">
                <img className="content-img" src={IconFiles} alt="Files" />
                {this.renderUploadFiles()}
              </div>
              {filesIsRequired && (
                <div className="ant-form-explain">Please upload at least one file</div>
              )}
            </div>
          </div>
          <div className="action-container">
            <Button
              className="btn-primary-0 btn-cancel"
              onClick={() => this.props.onSetDrawerSource('projectInfo')}
              type="primary"
            >
              Cancel
            </Button>
            <Button
              className="btn-primary-0 btn-send"
              onClick={this.openModal}
              type="primary"
            >
              Send
            </Button>
          </div>
          {this.renderModalConfirm()}
        </>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    currentTopic: state.topics.detailData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSendQuote: data => {
      dispatch(sendQuote(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuoteForm);
