import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './customerStoriesForm.scss';
import { Form, Icon, Input, Button, Upload, Modal, Select, Radio, Checkbox } from 'antd';
import { formDataConfig, isImage, uuid } from '../../../../core/utils/commonUtils';
import {
  getBase64,
  isImageOrVideo,
  detectFile,
  getS3CDN,
  dummyRequest,
} from '../../../../core/utils/commonUtils';
import { openNotification } from '../../notification/notification';
import { isYoutubeLink } from '../../../../core/utils/customValidator';
import EditableTagGroup from '../../../common/editableTagGroup/editableTagGroup';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import iUpload from '../../../../assets/images/icons/upload-file.svg';
import VideoThumbnailExtractor from 'src/components/common/videoThumbnailExtractor';
import { dataURLtoBlob, extractFileExtension } from 'src/core/utils/commonUtils';
import { httpService } from 'src/core/services/httpService';
import { CKEDITOR, S3_FOLDERS } from 'src/core/constants/constants';

const { Option } = Select;
const { confirm } = Modal;

function showConfirm(title, content, funcOk, funcCancel) {
  confirm({
    title,
    content,
    onOk() {
      return funcOk && funcOk();
    },
    onCancel() {
      return funcCancel && funcCancel();
    },
  });
}

class CustomerStoriesForm extends React.Component {
  constructor(props) {
    super(props);
    this.videoThumbnailExtractorRef = React.createRef();
    this.state = {
      content: !this.props.itemUpdate ? '' : this.props.itemUpdate.featureDes,
      errors: [],
      previewVisible: false,
      previewImage: '',
      fileList: [],
      photos: [],
      remainPhotos: !this.props.itemUpdate ? [] : this.props.itemUpdate.images,
      hasChangedFile: false,
      hasChangedPhotos: false,
      hasChangedYoutubeUrl: false,
      photosUpdate: [],
      typeError: '',
      filesInputName: '',
      tags: [],
      firstChange: false,
      submitting: false,
    };
  }
  componentDidMount() {
    const { itemUpdate } = this.props;
    if (itemUpdate && itemUpdate.images && itemUpdate.images.length) {
      this.setState({
        remainPhotos: itemUpdate.images,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.itemUpdate &&
      Array.isArray(props.itemUpdate.metadata) &&
      !state.firstChange
    ) {
      return {
        firstChange: !state.firstChange,
        tags: props.itemUpdate.metadata,
      };
    }
    return null;
  }

  // Filter originfile
  filterOriginFile = arr => {
    if (arr && arr.length) {
      return arr.map(item => item.originFileObj);
    }
    return [];
  };

  // Filter file not empty
  filterEmptyPhotos = arr => {
    if (arr && arr.length) {
      return arr.filter(item => item);
    }
    return [];
  };

  // Submit

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      const errorsArr = [];
      const { itemUpdate } = this.props;
      const {
        fileList,
        photos,
        hasChangedFile,
        remainPhotos,
        photosUpdate,
        hasChangedYoutubeUrl,
        tags,
      } = this.state;
      const content = this.state.content.trim();
      const subDes = values.subDes.trim();
      const status = values.status.trim() === 'Active';
      const s3Folder = S3_FOLDERS.CMS.STORIES.LATEST_WORK;
      if (!content) {
        errorsArr.push('content');
      }
      if (!tags.length) {
        errorsArr.push('metadata');
      }
      // Loading
      this.setState({ submitting: true });

      if (!itemUpdate) {
        const youtubeUrl = values.youtubeUrl ? values.youtubeUrl.trim() : null;
        if (youtubeUrl && !isYoutubeLink(youtubeUrl)) {
          errorsArr.push('youtubeLink');
        }
        if ((!fileList || !fileList.length) && !youtubeUrl) {
          errorsArr.push('files');
        }
        this.setState({
          errors: errorsArr,
        });
        if (err || errorsArr.length) {
          return this.setState({ submitting: false });
        } else {
          const title = values.title.trim();
          const file = fileList[0] ? fileList[0].originFileObj : youtubeUrl;
          const type = fileList[0] ? detectFile(fileList[0]) : 'video';
          const images = this.filterOriginFile(photos);
          const data = {
            title,
            subDes,
            type,
            feature: file,
            featureDes: content,
            status,
            metadata: tags,
            serviceTypeId: values.serviceTypeId,
            isOurStoriesLandingPage: values.isOurStoriesLandingPage,
            isOurStoriesPage: values.isOurStoriesPage,
            isLatestWorkLandingPage: values.isLatestWorkLandingPage,
          };

          let promises = [];

          if (file && !isYoutubeLink(file)) {
            if (type === 'video') {
              let thumbnail = await this.videoThumbnailExtractorRef.current.onExtractThumbnail(
                file
              );
              thumbnail = dataURLtoBlob(thumbnail, file.name);

              promises.push(
                new Promise(async (resolve, reject) => {
                  try {
                    let [thumbnailUrl] = await httpService._upload({
                      photos: [
                        {
                          file: thumbnail,
                          filename: `${s3Folder}/${uuid()}.png`,
                        },
                      ],
                    });
                    Object.assign(data, {
                      thumbnail: thumbnailUrl,
                    });

                    resolve(true);
                  } catch (e) {
                    reject(e);
                  }
                })
              );
            }

            promises.push(
              new Promise(async (resolve, reject) => {
                try {
                  let [featureUrl] = await httpService._upload({
                    photos: [
                      {
                        filename: `${s3Folder}/${uuid()}${extractFileExtension({
                          filename: file.name,
                        })}`,
                        file,
                      },
                    ],
                  });

                  Object.assign(data, { feature: featureUrl });
                  resolve(true);
                } catch (e) {
                  reject(e);
                }
              })
            );
          }

          const imageFiles = images.map(image => ({
            path: 'cdn',
            file: image,
            filename: `${s3Folder}/${uuid()}-${extractFileExtension({
              filename: image.name,
            })}`,
          }));

          promises.push(
            new Promise(async (resolve, reject) => {
              try {
                const imageUrls = await httpService._upload({
                  photos: [...imageFiles],
                });

                if (imageUrls.length) {
                  Object.assign(data, {
                    image: imageUrls,
                  });
                }
                resolve(true);
              } catch (e) {
                reject(true);
              }
            })
          );

          try {
            await Promise.all(promises);
          } catch (e) {
            openNotification('error', 'Upload files failed');
          }

          const formData = formDataConfig(data);
          this.props.onCreateCustomerStories(formData);
        }
      } else {
        let file;
        let type;
        const youtubeUrl = values.youtubeUrl ? values.youtubeUrl.trim() : null;
        if (youtubeUrl && !isYoutubeLink(youtubeUrl)) {
          errorsArr.push('youtubeLink');
        }
        if (hasChangedFile || hasChangedYoutubeUrl) {
          if ((!fileList || !fileList.length) && !youtubeUrl) {
            errorsArr.push('files');
          } else {
            file = fileList[0] ? fileList[0].originFileObj : youtubeUrl;
            type = detectFile(fileList[0]) ? detectFile(fileList[0]) : 'video';
          }
        } else {
          file = [itemUpdate.featureUrl];
          type = itemUpdate.type;
        }
        this.setState({
          errors: errorsArr,
        });
        if (err || errorsArr.length) {
          return this.setState({ submitting: false });
        } else {
          const title = values.title.trim();
          const data = {
            title,
            subDes,
            type,
            feature: file,
            featureDes: content,
            status,
            metadata: tags,
            serviceTypeId: values.serviceTypeId,
            isOurStoriesLandingPage: values.isOurStoriesLandingPage,
            isOurStoriesPage: values.isOurStoriesPage,
            isLatestWorkLandingPage: values.isLatestWorkLandingPage,
          };

          if (remainPhotos && remainPhotos.length) {
            Object.assign(data, { images: remainPhotos });
          }

          let promises = [];
          const imageFiles = [
            ...this.filterEmptyPhotos(this.filterOriginFile(photosUpdate)),
          ].map(image => ({
            file: image,
            filename: `${s3Folder}/${uuid()}${extractFileExtension({
              filename: image.name,
            })}`,
          }));

          if (file && !isYoutubeLink(file) && hasChangedFile) {
            if (type === 'video') {
              let thumbnail = await this.videoThumbnailExtractorRef.current.onExtractThumbnail(
                file
              );
              thumbnail = dataURLtoBlob(thumbnail, file.name);
              promises.push(
                new Promise(async (resolve, reject) => {
                  try {
                    let [thumbnailUrl] = await httpService._upload({
                      photos: [
                        {
                          file: thumbnail,
                          filename: `${s3Folder}/${uuid()}.png`,
                        },
                      ],
                    });
                    Object.assign(data, {
                      thumbnail: thumbnailUrl,
                    });

                    resolve(true);
                  } catch (e) {
                    reject(e);
                  }
                })
              );
            }

            promises.push(
              new Promise(async (resolve, reject) => {
                try {
                  let [featureUrl] = await httpService._upload({
                    photos: [
                      {
                        filename: `${s3Folder}/${uuid()}${extractFileExtension({
                          filename: file.name,
                        })}`,
                        file,
                      },
                    ],
                  });

                  Object.assign(data, { feature: featureUrl });
                  resolve(true);
                } catch (e) {
                  reject(e);
                }
              })
            );
          }

          promises.push(
            new Promise(async (resolve, reject) => {
              try {
                const imageUrls = await httpService._upload({
                  photos: imageFiles,
                });

                const images = [...remainPhotos, ...imageUrls];

                if (images.length) {
                  Object.assign(data, {
                    images,
                  });
                }
                resolve(true);
              } catch (e) {
                reject(e);
              }
            })
          );

          try {
            await Promise.all(promises);
          } catch (e) {
            openNotification('error', 'Upload files failed');
          }

          const formData = formDataConfig(data);
          this.props.onUpdateCustomerStories(formData);
        }
      }

      this.setState({ submitting: false });
    });
  };

  // Content CKEditor Change
  contentChange = (event, editor) => {
    const content = editor.getData();
    if (content) {
      this.setState({
        errors: this.state.errors.filter(item => item !== 'content'),
      });
    }
    this.setState({
      content,
    });
  };

  // File handler
  onFileChange = info => {
    let { errors } = this.state;
    if (info && info.file && !isImageOrVideo(info.file)) {
      errors.push('fileType');
      errors = errors.filter(item => item !== 'files');
      this.setState({
        errors,
      });
      return;
    } else {
      errors = errors.filter(item => item !== 'fileType');
      this.setState({
        errors,
      });
    }
    if (this.state.fileList && this.state.fileList.length) {
      this.setState({
        errors: this.state.errors.filter(item => item !== 'files'),
        hasChangedFile: true,
        filesInputName: 'fileUpload',
      });
    }
    if (!info.fileList.length) {
      this.setState({
        filesInputName: '',
      });
    }
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    // if (info.file.status === 'done') {
    //   openNotification('success', 'Upload file successfully');
    // }
    // if (info.file.status === 'error') {
    //   openNotification('error', 'Upload file upload failed');
    // }
    this.setState({ fileList });
  };

  urlYoutubeChange = e => {
    let errorsArr = [];
    const url = e.target.value;
    this.setState({
      filesInputName: url ? 'urlYoutube' : '',
    });
    if (url && !isYoutubeLink(url)) {
      errorsArr.push('youtubeLink');
    } else {
      const indexLink = errorsArr.indexOf('youtubeLink');
      errorsArr.splice(indexLink, 1);
    }
    this.setState({
      errors: errorsArr,
      hasChangedYoutubeUrl: true,
    });
  };

  // Photos handler
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChangePhotos = info => {
    const { itemUpdate } = this.props;
    let { errors } = this.state;
    if (info && info.file && info.file.size && !isImage(info.file)) {
      errors.push('imageType');
      this.setState({ errors });
      return;
    } else {
      errors = errors.filter(item => item !== 'imageType');
      this.setState({ errors });
    }
    if (info && info.fileList && info.fileList.length) {
      this.setState({
        errors: this.state.errors.filter(item => item !== 'photos'),
      });
    }
    if (itemUpdate) {
      this.setState({
        photosUpdate: info.fileList,
        hasChangedPhotos: true,
      });
    } else {
      this.setState({ photos: info.fileList });
    }
  };

  onChangeProductType = value => {
    this.props.onGetServiceType(value);
    this.props.form.setFieldsValue({ serviceTypeId: '' });
    const { form, itemUpdate } = this.props;
    if (itemUpdate && value === itemUpdate.serviceTypeId.productTypeId._id) {
      form.resetFields([
        'isOurStoriesLandingPage',
        'isLatestWorkLandingPage',
        'isOurStoriesPage',
      ]);
    } else {
      form.setFieldsValue({
        isOurStoriesLandingPage: false,
        isLatestWorkLandingPage: false,
        isOurStoriesPage: false,
      });
    }
  };
  onRemove = file => {
    const { remainPhotos } = this.state;
    if (file && remainPhotos.length) {
      this.setState({
        remainPhotos: remainPhotos.filter(item => item !== file.name),
      });
    }
  };

  formatPhotos = photos => {
    return photos && photos.length
      ? photos.map((item, index) => {
          return {
            uid: index,
            name: item,
            status: 'done',
            url: item.includes(getS3CDN('')) ? item : getS3CDN(item),
          };
        })
      : [];
  };

  urlYoutubeChange = e => {
    let errorsArr = [];
    const url = e.target.value;
    this.setState({
      filesInputName: url ? 'urlYoutube' : '',
    });
    if (url && !isYoutubeLink(url)) {
      errorsArr.push('youtubeLink');
    } else {
      const indexLink = errorsArr.indexOf('youtubeLink');
      errorsArr.splice(indexLink, 1);
    }
    this.setState({
      errors: errorsArr,
      hasChangedYoutubeUrl: true,
    });
  };

  onChangeProductType = value => {
    this.props.onGetServiceType(value);
    this.props.form.setFieldsValue({ serviceTypeId: '' });
    const { form, itemUpdate } = this.props;
    if (itemUpdate && value === itemUpdate.serviceTypeId.productTypeId._id) {
      form.resetFields([
        'isOurStoriesLandingPage',
        'isLatestWorkLandingPage',
        'isOurStoriesPage',
      ]);
    } else {
      form.setFieldsValue({
        isOurStoriesLandingPage: false,
        isLatestWorkLandingPage: false,
        isOurStoriesPage: false,
      });
    }
  };

  checkStoryHightLight = name => {
    const { storyHightLight, form, itemUpdate } = this.props;

    if (!storyHightLight) return;
    if (
      name === 'ourStoriesLandingPage' &&
      storyHightLight[name].length &&
      !form.getFieldValue('isOurStoriesLandingPage') &&
      storyHightLight[name][0]
    ) {
      if (itemUpdate && itemUpdate.isOurStoriesLandingPage) return;

      showConfirm(
        'Wanning',
        `There is another story "${storyHightLight[name][0].title}" which is Highlight in "Our Stories" on Landing Page. If you click OK, Platform will override this story.`,
        undefined,
        () => {
          form.setFieldsValue({
            isOurStoriesLandingPage: false,
          });
        }
      );
    } else if (
      name === 'latestWorkLandingPage' &&
      storyHightLight[name].length &&
      !form.getFieldValue('isLatestWorkLandingPage')
    ) {
      let title, type;

      if (itemUpdate && itemUpdate.isLatestWorkLandingPage) return;
      if (!form.getFieldValue('productTypeId')) {
        showConfirm(
          'Wanning',
          `Please select product type !!!`,
          () => {
            form.setFieldsValue({
              isLatestWorkLandingPage: false,
            });
          },
          () => {
            form.setFieldsValue({
              isLatestWorkLandingPage: false,
            });
          }
        );
        return;
      }
      const story = storyHightLight[name].find(
        st => form.getFieldValue('productTypeId') === st.serviceTypeId.productTypeId._id
      );
      if (story) {
        title = story.title;
        type = story.serviceTypeId.productTypeId.name;
        showConfirm(
          'Wanning',
          `There is another story "${title}" which is Highlight in "${type}" on Landing Page. If you click OK, Platform will override this story.`,
          undefined,
          () => {
            form.setFieldsValue({
              isLatestWorkLandingPage: false,
            });
          }
        );
      }
      return;
    }
  };
  handleTags = tags => {
    this.setState({ tags });
  };

  render() {
    const {
      fileList,
      previewVisible,
      previewImage,
      photos,
      hasChangedFile,
      photosUpdate,
      hasChangedPhotos,
      filesInputName,
      tags,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { itemUpdate, productType, serviceType } = this.props;

    const props = {
      customRequest: dummyRequest,
      onChange: this.onFileChange,
      multiple: true,
      fileList,
    };
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <>
        <VideoThumbnailExtractor ref={this.videoThumbnailExtractorRef} />
        <Form className="customerstories-form">
          <Form.Item label="Title">
            {getFieldDecorator('title', {
              rules: [
                {
                  required: true,
                  message: 'Please input story title',
                },
                {
                  whitespace: true,
                  message: 'Please do not input whitespace only',
                },
              ],
              initialValue: itemUpdate && itemUpdate.title,
            })(<Input placeholder="Title" size="large" />)}
          </Form.Item>
          <Form.Item label="Sub Decription">
            {getFieldDecorator('subDes', {
              rules: [
                {
                  required: true,
                  message: 'Please input sub decription',
                },
                {
                  whitespace: true,
                  message: 'Please do not input whitespace only',
                },
              ],
              initialValue: itemUpdate && itemUpdate.subDes,
            })(<Input placeholder="" size="large" />)}
          </Form.Item>
          <Row>
            <Col xs={6}>
              <Form.Item label="Feature Image/Video">
                <Upload {...props}>
                  <Button
                    style={{ height: '40px' }}
                    disabled={filesInputName === 'urlYoutube'}
                    className="btn-story-upload px-3"
                  >
                    <img src={iUpload} alt="iUpload" />
                  </Button>
                </Upload>
                {this.state.errors.includes('files') ? (
                  <div className="ant-form-explain content-error">
                    Please upload file!
                  </div>
                ) : null}
                {this.state.errors.includes('fileType') ? (
                  <div className="ant-form-explain content-error">
                    Please upload image or video
                  </div>
                ) : null}
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item label="Url Youtube">
                {getFieldDecorator('youtubeUrl', {
                  initialValue:
                    itemUpdate && !hasChangedFile && isYoutubeLink(itemUpdate.featureUrl)
                      ? itemUpdate.featureUrl
                      : '',
                })(
                  <Input
                    onChange={this.urlYoutubeChange}
                    placeholder="Files"
                    size="large"
                    disabled={filesInputName === 'fileUpload'}
                  />
                )}
                {this.state.errors.includes('youtubeLink') ? (
                  <div className="ant-form-explain content-error">
                    Please upload youtube link!
                  </div>
                ) : null}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>{itemUpdate && !hasChangedFile && itemUpdate.featureUrl}</Form.Item>
          <Form.Item label="Description" required>
            <CKEditor
              onInit={editor => {
                editor.ui
                  .getEditableElement()
                  .parentElement.insertBefore(
                    editor.ui.view.toolbar.element,
                    editor.ui.getEditableElement()
                  );
              }}
              onChange={(event, editor) => this.contentChange(event, editor)}
              editor={DecoupledEditor}
              row="4"
              config={{
                toolbar: [
                  'heading',
                  'alignment',
                  'bold',
                  'italic',
                  'link',
                  'numberedList',
                  'bulletedList',
                ],
                heading: CKEDITOR.heading
              }}
              data={itemUpdate && itemUpdate.featureDes}
            />
            {this.state.errors.includes('content') ? (
              <div className="ant-form-explain text-red-0">Please input content!</div>
            ) : null}
          </Form.Item>
          <Form.Item label="Gallery">
            <Upload
              customRequest={dummyRequest}
              listType="picture-card"
              onPreview={this.handlePreview}
              onChange={this.handleChangePhotos}
              onRemove={this.onRemove}
              fileList={
                !itemUpdate
                  ? photos
                  : !hasChangedPhotos
                  ? this.formatPhotos(itemUpdate.images)
                  : photosUpdate
              }
            >
              {uploadButton}
            </Upload>
            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
            {this.state.errors.includes('imageType') ? (
              <div className="ant-form-explain text-red-0">Please upload image type</div>
            ) : null}
          </Form.Item>
          <Row>
            <Col>
              <Form.Item label="Product type" className="">
                {getFieldDecorator('productTypeId', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select product type status',
                    },
                  ],
                  initialValue: itemUpdate
                    ? itemUpdate.serviceTypeId.productTypeId._id
                    : '',
                })(
                  <Select onChange={this.onChangeProductType}>
                    {productType &&
                      productType.map(pt => (
                        <Option value={pt._id} key={pt._id}>
                          {pt.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Service type">
                {getFieldDecorator('serviceTypeId', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select service type status',
                    },
                  ],
                  initialValue: itemUpdate ? itemUpdate.serviceTypeId._id : '',
                })(
                  <Select>
                    {serviceType &&
                      serviceType.map(st => (
                        <Option value={st._id} key={st._id}>
                          {st.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Keywords" required>
            <EditableTagGroup tags={tags} onHandleTags={this.handleTags} />
            {this.state.errors.includes('metadata') ? (
              <div className="ant-form-explain text-red-0">Please add least one key!</div>
            ) : null}
          </Form.Item>
          <Form.Item label="Status" className="status-item">
            {getFieldDecorator('status', {
              rules: [
                {
                  required: true,
                  message: 'Please select status',
                },
              ],
              initialValue: !itemUpdate
                ? 'Active'
                : itemUpdate.status
                ? 'Active'
                : 'Inactive',
            })(
              <Radio.Group
              // onChange={this.onChange}
              >
                <Row>
                  <Col>
                    <Radio value="Active">Active on site</Radio>
                  </Col>
                  <Col>
                    <Radio value="Inactive">Inactive on site</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label="Highlight options" className="mb-0">
            {getFieldDecorator('isOurStoriesLandingPage', {
              valuePropName: 'checked',
              initialValue: itemUpdate && itemUpdate.isOurStoriesLandingPage,
            })(
              <Checkbox
                onChange={() => {
                  this.checkStoryHightLight('ourStoriesLandingPage');
                }}
              >
                Highlight in Our Stories on Landing page
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item className="mb-0">
            {getFieldDecorator('isOurStoriesPage', {
              valuePropName: 'checked',
              initialValue: itemUpdate ? itemUpdate.isOurStoriesPage : false,
            })(
              <Checkbox
                onChange={() => {
                  this.checkStoryHightLight('ourStoriesPage');
                }}
              >
                ChecHighlight in Our Stories page
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('isLatestWorkLandingPage', {
              valuePropName: 'checked',
              initialValue: itemUpdate && itemUpdate.isLatestWorkLandingPage,
            })(
              <Checkbox
                onChange={() => {
                  this.checkStoryHightLight('latestWorkLandingPage');
                }}
              >
                ChecHighlight in Latest Work on Landing page
              </Checkbox>
            )}
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              loading={this.state.submitting}
              onClick={this.handleSubmit}
              className="customerstories-form-button btn-primary-0 w-25"
              size="large"
              // disabled={!this.state.errors.length}
            >
              {!itemUpdate ? 'Save' : 'Edit story'}
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

const WrappedCustomerStoriesForm = Form.create({
  name: 'normal_customerstories',
})(CustomerStoriesForm);

export default WrappedCustomerStoriesForm;
