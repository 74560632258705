import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const getPackageService = type => {
	let url = BaseConfig.END_POINT.ADMIN.PACKAGE_ADMIN;
	if (type) {
		url = BaseConfig.END_POINT.ADMIN.PACKAGE;
	}
	const response = httpService._get(url);
	return response;
};

export const getPackageByParams = params => {
	let url = BaseConfig.END_POINT.ADMIN.PACKAGE_ADMIN;
	const response = httpService._get(`${url}?productTypeId=${params.productTypeId || ''}`);
	return response;
};

export const getPackageByIdService = id => {
	let url = BaseConfig.END_POINT.ADMIN.PACKAGE_ADMIN;
	const response = httpService._get(`${url}/${id}`);
	return response;
};

export const createPackageService = data => {
	const url = BaseConfig.END_POINT.ADMIN.PACKAGE;
	const response = httpService._post(url, data);
	return response;
};

export const updatePackageService = ({ data, id }) => {
	const url = BaseConfig.END_POINT.ADMIN.PACKAGE;
	const response = httpService._put(`${url}/${id}`, data);
	return response;
};
