import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../../actions/typeAction';
import {
	getCaseStudiesService,
	createCaseStudiesService,
	updateCaseStudiesService
} from '../../../core/services/modules/caseStudiesService';
import { updateOrderService } from '../../../core/services/modules/orderService';
import { openNotification } from '../../../components/common/notification/notification';

export function* getCaseStudiesSaga() {
	const response = yield call(getCaseStudiesService);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_CASE_STUDIES_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_CASE_STUDIES_SUCCESS,
			payload: data
		});
	}
}

export function* createCaseStudiesSaga(action) {
	const response = yield call(createCaseStudiesService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.CREATE_CASE_STUDIES_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.CREATE_CASE_STUDIES_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateCaseStudiesSaga(action) {
	const response = yield call(updateCaseStudiesService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_CASE_STUDIES_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.UPDATE_CASE_STUDIES_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateOrderSaga(action) {
	const response = yield call(updateOrderService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_ORDER_CASE_STUDIES_FAILED,
			payload: response.data.error
		});
	} else {
		openNotification('success', 'Update successful');
	}
}

export function* watchCaseStudies() {
	yield takeLatest(actionTypes.GET_CASE_STUDIES_REQUEST, getCaseStudiesSaga);
	yield takeLatest(
		actionTypes.CREATE_CASE_STUDIES_REQUEST,
		createCaseStudiesSaga
	);
	yield takeLatest(
		actionTypes.UPDATE_CASE_STUDIES_REQUEST,
		updateCaseStudiesSaga
	);
	yield takeLatest(
		actionTypes.UPDATE_ORDER_CASE_STUDIES_SUCCESS,
		updateOrderSaga
	);
}
