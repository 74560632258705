import React from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router';

// admin
import CMSBanner from '../../containers/admin/dashboard/dashboardContainer';
import HowItWork from '../../containers/admin/howitwork/howItWorkContainer';
import CustomerStories from '../../containers/admin/customerStories/customerStoriesContainer';
import Stories from '../../containers/admin/stories/storiesContainer';
import CaseStudies from '../../containers/admin/caseStudies/caseStudiesContainer';
import OurTeam from '../../containers/admin/ourTeam/ourTeamContainer';
import HourRate from '../../containers/admin/hourRate/hourRateContainer';
import Skills from '../../containers/admin/skills/skillsContainer';
import Policies from '../../containers/admin/policies/policiesContainer';
import EmailTemplate from '../../containers/admin/emailTemplate/emailTemplate';
import Freelancers from '../../containers/admin/creatives/creativesContainer';
import ServiceType from '../../containers/admin/serviceType/serviceTypeContainer';
import Budget from '../../containers/admin/budget/budgetContainer';
import Categories from '../../containers/admin/categories/categoriesContainer';
import SubCategories from '../../containers/admin/subCategories/subCategoriesContainer';
import Units from '../../containers/admin/units/unitsContainer';
import Addons from '../../containers/admin/addons/addonsContainer';
import Package from '../../containers/admin/package/packageContainer';
import Items from '../../containers/admin/items/itemsContainer';
import Addhoc from '../../containers/admin/addhoc/addhocContainer';
import Projects from '../../containers/admin/projects/projectsContainer';
import Customers from '../../containers/admin/customers/customersContainer';
import Editors from '../../containers/admin/editors/editorsContainer';
import Admins from '../../containers/admin/admins/adminsContainer';
import ProductTypes from '../../containers/admin/productTypes/productTypesContainer';
import notFoundPage from '../../components/screens/404/404';
import Login from '../../containers/login/loginContainer';
import Faqs from '../../containers/admin/faqs/faqsContainer';
import Seo from '../../components/screens/admin/seo/seoScreen';
import ProfileScreen from '../../containers/admin/profile/profileContainer';
import SettingScreen from '../../components/screens/admin/setting/settingScreen';
import TeamScreen from '../../containers/admin/teams/teamsContainer';
import ForgotPasswordScreen from '../../containers/forgotPassword/forgotPasswordContainer';

// routes
import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';

// storage service
import { storageService } from '../services/storageService';
import { scrollTop } from '../utils/commonUtils';
import { ADMIN_ROLES } from '../constants/constants';
import CacheSynchronize from '../../components/screens/admin/cacheSynchronize';

class AppRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: 'admin',
    };
  }

  componentDidMount() {
    if (!window.URLSearchParams) {
      return;
    }

    const { pathname, search } = this.props.location;
    const searchParams = new URLSearchParams(search);
    if (pathname === '/customers' && searchParams.has('customerId')) {
      return this.props.history.replace({
        pathname: '/customers/' + searchParams.get('customerId'),
      });
    }

    if (pathname === '/creatives' && searchParams.has('creativeId')) {
      return this.props.history.replace({
        pathname: '/creatives/' + searchParams.get('creativeId'),
      });
    }

    if (searchParams.has('projectId')) {
      return this.props.history.replace({
        pathname: '/projects/' + searchParams.get('projectId'),
      });
    }
  }

  checkPermissionRoute() {
    if (!storageService.getCurrentUser()) {
      return {
        isAuthenticated: false,
        isLoggedIn: false,
        pathName: '/login',
      };
    } else {
      const foundRole = ADMIN_ROLES.find(
        item => item.value === storageService.getCurrentUser().role
      );
      if (foundRole) {
        return {
          isAuthenticated: true,
          isLoggedIn: true,
          pathName: '/',
        };
      } else {
        return {
          isAuthenticated: false,
          isLoggedIn: true,
          pathName: '/404',
        };
      }
    }
  }

  checkRole() {
    if (!storageService.getCurrentUser()) {
      return {
        isAuthenticated: false,
        isLoggedIn: false,
        pathName: '/login',
      };
    } else {
      const userRole = storageService.getCurrentUser().role;
      const isSuperAdmin = userRole === 'superadmin';
      if (isSuperAdmin) {
        return {
          isAuthenticated: true,
          isLoggedIn: true,
          pathName: '/',
        };
      } else {
        return {
          isAuthenticated: false,
          isLoggedIn: true,
          pathName: '/404',
        };
      }
    }
  }

  render() {
    const checkPermission = this.checkPermissionRoute();
    const checkRole = this.checkRole();
    scrollTop();
    return (
      <Switch>
        <PublicRoute exact path="/login" component={Login} auth={checkPermission} />
        <PublicRoute
          exact
          auth={checkPermission}
          path="/auth/forgot-password"
          component={ForgotPasswordScreen}
        />
        <PublicRoute
          exact
          auth={checkPermission}
          path="/forgot-password"
          component={ForgotPasswordScreen}
        />
        <PrivateRoute exact path="/" component={Projects} auth={checkPermission} />

        <PrivateRoute path="/projects" component={Projects} auth={checkPermission} />
        <PrivateRoute
          exact
          path="/howitwork"
          component={HowItWork}
          auth={checkPermission}
        />
        <PrivateRoute
          exact
          path="/customer-stories"
          component={CustomerStories}
          auth={checkRole}
        />
        <PrivateRoute
          exact
          path="/case-studies"
          component={CaseStudies}
          auth={checkRole}
        />
        <PrivateRoute exact path="/seo" component={Seo} auth={checkRole} />
        <PrivateRoute exact path="/stories" component={Stories} auth={checkRole} />
        <PrivateRoute exact path="/our-team" component={OurTeam} auth={checkRole} />
        <PrivateRoute exact path="/hour-rate" component={HourRate} auth={checkRole} />
        <PrivateRoute exact path="/skills" component={Skills} auth={checkRole} />
        <PrivateRoute exact path="/policies" component={Policies} auth={checkRole} />
        <PrivateRoute
          exact
          path="/email-template"
          component={EmailTemplate}
          auth={checkRole}
        />
        <PrivateRoute path="/creatives" component={Freelancers} auth={checkPermission} />
        <PrivateRoute
          exact
          path="/service-types"
          component={ServiceType}
          auth={checkRole}
        />
        <PrivateRoute exact path="/budget" component={Budget} auth={checkRole} />
        <PrivateRoute exact path="/categories" component={Categories} auth={checkRole} />
        <PrivateRoute
          exact
          path="/sub-categories"
          component={SubCategories}
          auth={checkRole}
        />
        <PrivateRoute exact path="/measure-units" component={Units} auth={checkRole} />
        <PrivateRoute exact path="/add-ons" component={Addons} auth={checkRole} />
        <PrivateRoute exact path="/packages" component={Package} auth={checkRole} />
        <PrivateRoute exact path="/items" component={Items} auth={checkRole} />
        <PrivateRoute exact path="/add-hoc" component={Addhoc} auth={checkRole} />
        <PrivateRoute exact path="/banner" component={CMSBanner} auth={checkRole} />
        <PrivateRoute path="/customers" component={Customers} auth={checkPermission} />
        <PrivateRoute path="/teams" component={TeamScreen} auth={checkPermission} />
        <PrivateRoute exact path="/editors" component={Editors} auth={checkPermission} />
        <PrivateRoute exact path="/admins" component={Admins} auth={checkRole} />
        <PrivateRoute
          exact
          path="/product-types"
          component={ProductTypes}
          auth={checkRole}
        />
        <PrivateRoute exact path="/faqs" component={Faqs} auth={checkRole} />
        <PrivateRoute
          exact
          path="/cache/synchronize"
          component={CacheSynchronize}
          auth={checkRole}
        />
        <PrivateRoute path="/profile" component={ProfileScreen} auth={checkPermission} />
        <PrivateRoute path="/setting" component={SettingScreen} auth={checkPermission} />
        <Route exact path="/404" component={notFoundPage} />
        <Redirect to="/404" />
      </Switch>
    );
  }
}

export default withRouter(AppRoute);
