import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Popconfirm, Button } from 'antd';
import { Jumbotron, Container } from 'react-bootstrap';
import './howitworkScreen.scss';
import DataTable from '../../../common/dragTable/dragTable';
import ModalData from '../../../common/newModal/newModal';
import { urlServer } from '../../../../core/utils/commonUtils';
import HowitworkForm from '../../../common/form/howitworkForm/howitworkForm';
import { ORDER_KEYS } from '../../../../core/constants/constants';

class HowItWorkScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: 'Admin Howitwork - VidOps',
			data: [],
			key: '',
			itemUpdate: null,
			isModalOpen: false,
			isCreate: false
		};
	}

	updateBannerHandler = updateData => {
		this.props.onUpdateBanner(updateData);
	};

	componentDidMount() {
		this.props.onGetCurrentUser();
		this.props.onGetHowitwork();
	}

	handleDelete = key => {
		this.props.onDeleteHowitwork(key);
	};

	handleUpdate = key => {
		const data = this.formatData(this.props.data);
		if (data) {
			this.setState({
				itemUpdate: data.find(item => item._id === key)
			});
		}
		this.setState({
			isModalOpen: true,
			isCreate: false,
			key
		});
	};

	formatColumns = () => {
		return [
			{
				title: 'Title',
				dataIndex: 'title',
				width: '30%'
			},
			{
				title: 'Subtitle',
				dataIndex: 'subTitle'
			},
			{
				title: 'Detail',
				dataIndex: 'detail'
			},
			{
				title: 'Image',
				dataIndex: 'image',
				render: (text, record) =>
					record.url ? <img className="thumbnail" src={record.url} alt="logo" /> : null
			},
			{
				title: 'Operation',
				dataIndex: 'operation',
				render: (text, record) => {
					return (
						<div className="operation-container">
							<Button
								onClick={() => this.handleUpdate(record.key)}
								type="primary"
								className="btn-primary-0"
								icon="edit"
							></Button>
							<Popconfirm
								title="Sure to delete?"
								onConfirm={() => {
									return this.handleDelete(record.key);
								}}
							>
								<Button
									type="primary"
									className="btn-primary-0"
									icon="delete"
								></Button>
							</Popconfirm>
						</div>
					);
				}
			}
		];
	};

	formatData = data => {
		if (data && data.length) {
			return data.map((item, index) => {
				return {
					...item,
					key: item._id,
					url: urlServer(item.url)
				};
			});
		}
	};

	handleAdd = () => {
		this.setState({
			isModalOpen: true,
			isCreate: true
		});
	};

	createHandler = data => {
		this.props.onCreateHowitwork(data);
	};

	updateHandler = data => {
		const payload = {
			data,
			id: this.state.key
		};
		this.props.onUpdateHowitwork(payload);
	};

	closeModalHandler = () => {
		this.setState({
			isModalOpen: false
		});
	};

	componentDidUpdate(prevProps) {
		if (this.props.data !== prevProps.data) {
			this.setState({
				isModalOpen: false
			});
		}
	}

	updateOrderHandler = orderData => {
		const updateOrderData = {
			key: ORDER_KEYS.HOW_IT_WORK,
			...orderData
		};
		this.props.onUpdateOrder(updateOrderData);
	};

	render() {
		const { data, isLoading } = this.props;
		const { isModalOpen, isCreate, key, itemUpdate } = this.state;
		const columns = this.formatColumns();
		const dataSource = this.formatData(data);
		return (
			<main role="main" className="page-content howitwork-screen">
				<Jumbotron className="mgt-40">
					<h2 className="sub-heading">How it work</h2>
					<p className="description">Manage How it work</p>
					<div className="divider"></div>
					<Container>
						<Button
							className="btn-primary-0"
							onClick={this.handleAdd}
							type="primary"
							style={{ marginBottom: 16 }}
						>
							Add new
						</Button>
						<DataTable
							columns={columns}
							dataSource={dataSource}
							pagination={false}
							onUpdateOrder={this.updateOrderHandler}
						/>
						<ModalData
							className="big-modal"
							title={isCreate ? 'Create how it work' : 'Update how it work'}
							visible={isModalOpen}
							onCloseModal={this.closeModalHandler}
							dataSource={
								<HowitworkForm
									onCreateHowitwork={this.createHandler}
									onUpdateHowitwork={this.updateHandler}
									isLoading={isLoading}
									isCreate={isCreate}
									itemUpdate={itemUpdate}
									key={key}
								/>
							}
							footer={null}
						/>
					</Container>
				</Jumbotron>
			</main>
		);
	}
}

export default withTranslation('common')(HowItWorkScreen);
