import React, { memo, useMemo } from 'react';
import './listAssets.scss';
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Input,
  Menu,
  message,
  Modal,
  notification,
  Spin,
  Table,
} from 'antd';
import {
  dataURLtoBlob,
  encodeURIThumbnail,
  extractFileExtension,
  formatBytes,
  getFileType,
  getS3CDN,
  isDeactiveUser,
  isImage,
  preventClosing,
  isFileHasThumbnail,
  getIdLinkDrive,
  getThumbnailLinkDrive,
  checkDriveLink,
} from 'src/core/utils/commonUtils';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FILES_TYPE, IMAGES_TYPE } from 'src/core/constants/constants.js';
import UserAvatar from 'src/components/common/userAvatar/index.js';
import FileExtentions from 'src/components/common/fileExtentions';
import compressImage from 'src/core/utils/imageCompressor';
import { VIDEO_TYPES } from 'src/core/constants/constants';
import VideoThumbnailExtractor from 'src/components/common/videoThumbnailExtractor';
import FileDetail from 'src/components/common/fileDetail';
import AssetFileProgress from 'src/components/common/form/projectsDetail/assetForm/assetFileProgress/assetFileProgress';
import { shallowEqual } from 'react-redux';
import { isYoutubeLink } from 'src/core/utils/customValidator.js';
import IconImageThumbnail from 'src/assets/images/google-login/icons8-google-drive-thumbnail.svg';
import { verifyTokenDrive } from 'src/core/services/modules/googleDriveService';
import ModalGoogleDrive from 'src/components/common/modalGoogleDrive';

const { confirm } = Modal;

const success = e => {
  message.success('Link copied');
};

const AssetDownloadButton = memo(props => {
  const { asset } = props;

  // Getter
  const getFilePath = useMemo(() => {
    try {
      return getS3CDN(asset.cdnSignedUrl ? asset.cdnSignedUrl : asset.path);
    } catch (e) {}
  }, [asset]);

  // Renderer
  return (
    <>
      <a href={getFilePath} download={asset.title}>
        <span>Download</span>
      </a>
    </>
  );
});

class ListAssets extends React.Component {
  constructor(props) {
    super(props);
    this.videoThumbnailExtractorRef = React.createRef();
    this.fileUploaded = [];
    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      modalTitle: '',
      modalVisible: false,
      uploadType: 'folder',
      isUploading: false,
      fileUploads: [],
      originFileUploads: [],
      dataAssets: [],
      percent: 0,
      loading: true,
      assetId: null,
      fileExt: '',
      copied: false,
      fileProgress: [],
      assetDetail: null,
      page: 1,
      limit: 10,
      // google drive
      visible: false,
      accessToken: null,
      refreshAsset: false, // refresh list asset
      visibleDrive: false,
    };
  }

  onUploadFiles(props) {
    const { assets } = props;
    const { originFileUploads, thumbnails } = this.state;
    const { dataSuccess } = assets;
    const { originalUrls, thumbnailUrls } = dataSuccess || {};
    const fileProgress = [...originFileUploads].map((originalFile, index) => {
      const thumbnailFile = thumbnails[index];
      const originalUrl = originalUrls[index];
      const thumbnailUrl = thumbnailUrls[index];

      return {
        origin: originalFile,
        thumbnail: thumbnailFile,
        originalUrl,
        thumbnailUrl,
        fileName: originalFile.name,
      };
    });

    this.setState({ fileProgress: [...this.state.fileProgress, ...fileProgress] });
  }

  onFinishedUpload = fileUploaded => {
    this.fileUploaded.push(fileUploaded);

    if (this.fileUploaded.length === this.state.fileProgress.length) {
      const { currentProject } = this.props;
      const projectId = currentProject && currentProject._id;
      const { page, limit } = this.state;

      this.props._onGetListAsset({
        projectId,
        limit,
        page,
        status: true,
        isAsset: true,
      });
      this.props._onResetAsset();
      window.removeEventListener('beforeunload', preventClosing);
      this.props.onAllowBackClick();

      this.fileUploaded = [];
      this.setState({ isUploading: false, fileProgress: [] });
    }
  };

  componentDidMount() {
    this.verifyToken();
  }
  verifyToken = async () => {
    try {
      const { data: result } = await verifyTokenDrive();
      this.setState({ accessToken: result.data.accessToken });
    } catch (err) {
      console.log('err', err);
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { currentProject } = this.props;
    const projectId = currentProject && currentProject._id; // localStorage.getItem('PROJECT_ID');
    const { uploadType, page, limit } = this.state;
    const { assets } = nextProps;
    if (assets) {
      if (this.props.assets.hasRemovedAll !== assets.hasRemovedAll) {
        this.props._onGetListAsset({
          projectId,
          limit,
          page,
          isAsset: true,
        });
        this.props._onResetAsset();
        this.setState({
          selectedRowKeys: [],
        });
      }

      if (
        assets.dataSuccess &&
        uploadType === 'upload' &&
        !shallowEqual(this.props.assets, nextProps.assets)
      ) {
        const { dataSuccess } = assets;
        const { originalUrls, thumbnailUrls } = dataSuccess || {};

        if (originalUrls && originalUrls.length) {
          window.addEventListener('beforeunload', preventClosing);
          this.props.onPreventBackClick();
        }

        if (originalUrls && thumbnailUrls) {
          this.onUploadFiles(nextProps);
        }
      } else if (
        (!!assets.dataSuccess && assets.isSuccess && uploadType === 'delete') ||
        (assets.dataSuccess && uploadType === 'update') ||
        (assets.dataSuccess && assets.isSuccess && uploadType === 'rename')
      ) {
        this.setState(
          {
            uploadType: 'folder',
            modalVisible: false,
          },
          () => {
            this.props._onGetListAsset({
              projectId,
              limit,
              page,
              status: true,
              isAsset: true,
            });
            this.props._onResetAsset();
          }
        );
      } else if (
        assets.dataSuccess &&
        assets.dataSuccess.isGallery &&
        !assets.dataSuccess.isLink &&
        uploadType === 'folder'
      ) {
        this.setState(
          {
            modalVisible: false,
          },
          () => {
            this.props._onGetListAsset({
              projectId,
              limit,
              page,
              status: true,
              isAsset: true,
            });
            this.props._onResetAsset();
          }
        );
      } else if (
        assets.dataSuccess &&
        !assets.dataSuccess.isGallery &&
        assets.dataSuccess.isLink &&
        uploadType === 'link'
      ) {
        this.setState(
          {
            modalVisible: false,
          },
          () => {
            this.props._onGetListAsset({
              projectId,
              limit,
              page,
              status: true,
              isAsset: true,
            });
            this.props._onResetAsset();
          }
        );
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.refreshAsset !== this.state.refreshAsset) {
      const { currentProject } = this.props;
      const projectId = currentProject && currentProject._id;
      setTimeout(() => {
        this.props._onGetListAsset({
          projectId,
          limit: 10,
          page: 1,
          status: true,
          isAsset: true,
        });
        this.forceUpdate();
      }, 1000);
    }
  }

  _onChangePage = page => {
    const { limit } = this.state;
    const { currentProject } = this.props;
    const projectId = currentProject && currentProject._id;
    this.setState(
      {
        page,
      },
      () => {
        this.props._onGetListAsset({
          projectId,
          limit,
          page,
          status: true,
          isAsset: true,
        });
      }
    );
  };

  _handleUpload = item => {
    switch (item.key) {
      case 'folder':
        this.setState({
          page: 1,
          modalVisible: true,
          modalTitle: 'Create folder',
          uploadType: 'folder',
          nameOnly: '',
        });
        break;
      case 'link':
        this.setState({
          page: 1,
          modalVisible: true,
          modalTitle: 'Upload link',
          uploadType: 'link',
          nameOnly: '',
        });
        break;
      case 'rename':
        const fileName = item.item.props.asset.title;
        const ext = fileName.substring(fileName.lastIndexOf('.') + 1);
        const nameOnly = fileName.substring(0, fileName.lastIndexOf('.'));
        this.setState({
          page: 1,
          modalVisible: true,
          modalTitle: 'Rename file',
          uploadType: 'rename',
          assetId: item.item.props.asset.assetId,
          fileExt: ext,
          nameOnly,
        });
        break;
      default:
        break;
    }
  };

  handleOk = () => {
    const { uploadType, nameOnly } = this.state;
    const { currentProject } = this.props;
    const projectId = currentProject && currentProject._id; // localStorage.getItem('PROJECT_ID');
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (uploadType === 'folder') {
          const data = {
            gallery: values.folder,
            projectId: projectId,
            isPublic: false,
          };
          this.props._onCreateFolder(data);
        } else if (uploadType === 'link') {
          const data = {
            path: values.link,
            isPublic: true,
            projectId: projectId,
          };
          this.props._onAttachLink(data);
        } else if (uploadType === 'rename') {
          if (nameOnly === values.rename) return this.setState({ modalVisible: false });
          const data = {
            assetId: this.state.assetId,
            fileName: values.rename + '.' + this.state.fileExt,
          };
          this.props._onUpdateResource(data);
        }
      }
    });
  };

  _handleSelectDrive = (link, type = 'message', fileName = '') => {
    const { currentProject } = this.props;
    const projectId = currentProject && currentProject._id;
    const data = {
      path: link,
      isPublic: true,
      projectId: projectId,
      name: fileName
    };
    this.props._onAttachLink(data);
    this.setState({
      refreshAsset: true,
    });
  };

  openModalDrive = () => {
    if (!this.state.accessToken) {
      this.setState({ visibleDrive: true });
      return;
    }
    this.setState({ visible: true });
  };
  closeModalDrive = () => {
    this.setState({ visible: false });
  };

  handleCancel = e => {
    this.setState({
      modalVisible: false,
    });
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  _viewGallery(title, files, assetId) {
    if (typeof title === 'object') {
      localStorage.setItem('GALLERY_TITLE', title.title);
    } else {
      localStorage.setItem('GALLERY_TITLE', title);
    }
    localStorage.setItem('ASSET_ID', assetId);
    this.props.viewGallery(title, files, assetId);
  }

  _deleteResource(assetId, name, form) {
    if (!assetId) return;

    if (form === 'back') {
      this.setState(
        {
          uploadType: 'delete',
        },
        () => this.props._onDeleteResource(assetId)
      );
    } else {
      confirm({
        title: `Are you sure want to delete "${name}" ?`,
        content: '',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          this.setState(
            {
              uploadType: 'delete',
            },
            () => this.props._onDeleteResource(assetId)
          );
        },
      });
    }
  }

  _updateResource(item, isPublic, fileName) {
    if (item) {
      const data = {
        assetId: item.assetId,
        isPublic: isPublic,
      };
      this.props._onUpdateResource(data);
      this.setState({
        uploadType: 'update',
      });
    }
  }

  removeAll = () => {
    const { selectedRowKeys } = this.state;

    if (selectedRowKeys && selectedRowKeys.length) {
      confirm({
        title: `Are you sure that you want to delete the selected item(s)?`,
        content: '',
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        onOk: () => {
          this.props.onRemoveAllResources({ data: selectedRowKeys });
        },
      });
    }
  };

  downloadGallery = folder => {
    const { files } = folder;
    if (files && files.length) {
      files.forEach(item => {
        window.open(item.cdnSignedUrl ? item.cdnSignedUrl : item.path);
      });
    }
  };

  formatColumns = () => {
    const { hasAssigned, currentProject } = this.props;
    const menu = item => {
      // eslint-disable-next-line no-unused-vars
      const isVideo = item && getFileType(item.path) === 'mp4';
      let copyLink = getS3CDN(item.path);

      if (!item.isGallery && !item.isLink && !isVideo) {
        copyLink = copyLink.substring(0, copyLink.indexOf('?X-Amz-Algorithm'));
      }
      return (
        <Menu>
          {item.isGallery && (
            <Menu.Item
              key="download"
              className="files-links-item"
              onClick={e => this.downloadGallery(item)}
            >
              <span>Download</span>
            </Menu.Item>
          )}
          {item.isGallery && <Menu.Divider />}
          {((!item.isGallery && item.isLink) || isVideo) && (
            <Menu.Item key="copy" className="files-links-item">
              <CopyToClipboard text={copyLink} onCopy={success}>
                <span>Copy Link</span>
              </CopyToClipboard>
            </Menu.Item>
          )}
          {!item.isGallery && !item.isLink && item.status && (
            <Menu.Item key="copy" className="files-links-item">
              <CopyToClipboard
                text={
                  item.path &&
                  item.path.substring(
                    0,
                    item.path && item.path.indexOf('?X-Amz-Algorithm')
                  )
                }
                onCopy={success}
              >
                <span>Copy Link</span>
              </CopyToClipboard>
            </Menu.Item>
          )}
          {!item.isGallery && !item.isLink && item.status && <Menu.Divider />}
          {!item.isGallery && !item.isLink && (
            <Menu.Item key="rename" onClick={this._handleUpload} asset={item}>
              <span>Rename</span>
            </Menu.Item>
          )}
          {!item.isGallery && !item.isLink && <Menu.Divider />}

          {!item.isGallery && !item.isLink && (
            <Menu.Item key="download">
              <AssetDownloadButton asset={item} />
            </Menu.Item>
          )}

          {!item.isGallery && !item.isLink && !item.topicId && !item.isStoryBoard && (
            <Menu.Divider />
          )}
          {item.topicId && !item.isStoryBoard && <Menu.Divider />}
          {!item.topicId && !item.isStoryBoard && (
            <Menu.Item
              key="delete"
              onClick={() => this._deleteResource(item.assetId, item.title)}
            >
              <span>Delete</span>
            </Menu.Item>
          )}
          {item.topicId && (
            <Menu.Item
              key="remove"
              onClick={() => {
                this._deleteResource(item._id, item.title, 'back');
              }}
            >
              <span>Remove (back to chat)</span>
            </Menu.Item>
          )}
        </Menu>
      );
    };
    const privateMenu = item => {
      return (
        <Menu>
          <Menu.Item key="1" onClick={() => this._updateResource(item, false)}>
            <span>
              <Icon type="lock" /> Private
            </span>
          </Menu.Item>
        </Menu>
      );
    };
    const publicMenu = item => {
      return (
        <Menu>
          <Menu.Item key="0" onClick={() => this._updateResource(item, true)}>
            <span>
              <Icon type="global" /> Public
            </span>
          </Menu.Item>
        </Menu>
      );
    };
    return [
      {
        title: '',
        dataIndex: '',
        width: 50,
        render: (title, record) => {
          const filePath = encodeURIThumbnail(record.pathThumbnail, record.path);
          const checkHasThumnail =
            isFileHasThumbnail(record.path) || isYoutubeLink(record.path);

          if (checkDriveLink(record.path)) {
            const idUrl = getIdLinkDrive(record.path);
            const thumbnail = getThumbnailLinkDrive(idUrl);
            return (
              <div className="drive-link">
                <img
                  src={thumbnail}
                  onError={ev => {
                    ev.target.src = IconImageThumbnail;
                  }}
                  alt=""
                />
                <img src={IconImageThumbnail} className="position-absolute watermark" alt=""/>
              </div>
            );
          }

          if (record.isGallery) {
            return (
              <FileExtentions
                fileName={'folder'}
                onclick={() => this._viewGallery(title, record.files, record.assetId)}
              />
            );
          }
          return (
            <FileExtentions
              fileName={record.isLink ? 'link' : filePath}
              path={record.path}
              onclick={
                checkHasThumnail ? () => this.setState({ assetDetail: record }) : null
              }
            />
          );
        },
      },
      {
        title: '',
        dataIndex: 'title',
        width: 300,
        render: (title, record) => {
          if (record.isGallery) {
            return (
              <span
                className="asset-title add-cursor"
                onClick={() => this._viewGallery(title, record.files, record.assetId)}
              >
                {title}
              </span>
            );
          }
          return <span className="asset-title">{record.customTitle || title}</span>;
        },
      },
      {
        title: '',
        dataIndex: 'createdBy',
        className: 'assets-avatar',
        width: 50,
        render: (createdBy, record) => {
          return (
            <UserAvatar
              fullName={record.fullName}
              avatar={record.avatar}
              status={!isDeactiveUser(record.createdBy)}
              size="small"
            />
          );
        },
      },
      {
        title: '',
        dataIndex: 'createdDate',
        width: 150,
      },
      {
        title: '',
        dataIndex: 'size',
        width: 130,
      },
      {
        title: '',
        dataIndex: 'status',
        width: 80,
        render: (status, record) => {
          const isVideo = getFileType(record.path) === 'mp4';
          if (isVideo) return '';
          if (!record.isLink && !record.isGallery) {
            if (status) {
              return (
                <Dropdown
                  overlay={() => privateMenu(record)}
                  trigger={['click']}
                  disabled={!hasAssigned || currentProject.isDelete}
                >
                  <span className="ant-dropdown-link add-cursor">
                    <Icon type="global" /> <Icon type="down" />
                  </span>
                </Dropdown>
              );
            }
            return (
              <Dropdown
                overlay={() => publicMenu(record)}
                trigger={['click']}
                disabled={!hasAssigned || currentProject.isDelete}
              >
                <span className="ant-dropdown-link add-cursor">
                  <Icon type="lock" /> <Icon type="down" />
                </span>
              </Dropdown>
            );
          }
        },
      },
      {
        title: (
          <div>
            {!hasAssigned ? (
              <Icon type="delete" key="delete" className="add-cursor deactive" />
            ) : (
              <Icon
                type="delete"
                key="delete"
                className="add-cursor"
                onClick={this.removeAll}
              />
            )}
          </div>
        ),
        dataIndex: 'option',
        width: 30,
        render: (text, record) => {
          return (
            <Dropdown
              overlay={() => menu(record)}
              trigger={['click']}
              disabled={!hasAssigned || currentProject.isDelete}
            >
              <Icon type="ellipsis" key="ellipsis" className="add-cursor" />
            </Dropdown>
          );
        },
      },
    ];
  };

  checkTitle = (isGallery, isLink, asset) => {
    let title;
    if(checkDriveLink(asset.path)) {
      if(asset.name) return asset.name;
      else return asset.path
    }
    if (isGallery) {
      title = asset.name;
    } else {
      if (isLink) {
        title = asset.path;
      } else {
        title = asset.name;
      }
    }
    return title;
  };

  formatData = () => {
    const { dataAssets } = this.props;

    const data = [];
    for (let i = 0; i < dataAssets.length; i++) {
      const isGallery = dataAssets[i].isGallery;
      const isLink = dataAssets[i].isLink;
      const isPublic = dataAssets[i].isPublic;
      const path = dataAssets[i].path;
      const assetId = dataAssets[i]._id;
      const asset = dataAssets[i];
      const date = dataAssets[i].createdAt;
      const title = this.checkTitle(isGallery, isLink, asset);
      const files = dataAssets[i].files ? dataAssets[i].files : [];
      const isAsset = dataAssets[i].isAsset;
      const _id = dataAssets[i]._id;
      const isMessage = dataAssets[i].isMessage;
      const key = dataAssets[i]._id;
      const topicId = dataAssets[i].topicId;
      const isStoryBoard = dataAssets[i].isStoryBoard;
      const { avt, fullName } = dataAssets[i].createdBy || {};

      let size = 0;
      if (isGallery && files) {
        files.forEach(r => {
          size += r.fileSize;
        });
      } else {
        size = dataAssets[i].fileSize;
      }

      data.push({
        key,
        title: title,
        avatar: avt,
        createdDate: moment(date).format('MMM DD, YYYY'),
        size: size ? formatBytes(size) : '-',
        status: isPublic,
        isGallery,
        path,
        isLink,
        files,
        assetId,
        isAsset,
        _id,
        isMessage,
        topicId,
        isStoryBoard,
        fullName,
        pathThumbnail: dataAssets[i].pathThumbnail,
        filePath: dataAssets[i].filePath,
        cdnSignedUrl: dataAssets[i].cdnSignedUrl,
        customTitle: dataAssets[i].customTitle,
      });
    }
    return data;
  };

  handleUpload = async () => {
    this.setState({ isUploading: true });
    let file = this.uploadInput.files;
    const { currentProject } = this.props;
    const projectId = currentProject && currentProject._id;
    const arrFile = [];
    const arrOriginFile = [];
    let thumbnails = [];
    if (file && file.length) {
      for (let i = 0; i < file.length; i++) {
        const currentFile = file[i];
        const fileExt = extractFileExtension({ filename: currentFile.name });

        // Image thumbnail
        if (isImage(currentFile)) {
          const thumbnailImage = await compressImage(currentFile);
          thumbnails.push(thumbnailImage);
        } else if (VIDEO_TYPES.includes(fileExt)) {
          let videoThumbnail = await this.videoThumbnailExtractorRef.current.onExtractThumbnail(
            currentFile
          );

          if (videoThumbnail) {
            videoThumbnail = dataURLtoBlob(videoThumbnail, currentFile.name);
            videoThumbnail = await compressImage(videoThumbnail);
          }

          thumbnails.push(videoThumbnail);
        } else {
          thumbnails.push(undefined);
        }

        arrFile.push({
          fileName: currentFile.name,
          fileSize: currentFile.size,
          isPublic: false,
          type: currentFile.type,
        });
        arrOriginFile.push(currentFile);
      }
      this.setState(
        {
          fileUploads: arrFile,
          uploadType: 'upload',
          originFileUploads: arrOriginFile,
          thumbnails,
          page: 1,
        },
        () => {
          const data = {
            fileUploads: arrFile,
            fileName: arrFile[0].name,
            fileSize: arrFile[0].size,
            gallery: '',
            projectId: projectId,
            isPublic: false,
            isGallery: false,
            type: arrFile[0].type,
          };
          this.props._onSignUrl(data);
        }
      );
    }
  };

  openNotificationWithIcon = (type, message, content) => {
    notification[type]({
      message: message,
      description: content,
    });
  };

  closeProgressBar = event => {
    const { target } = event;
    if (target) {
      const parentElem = event.currentTarget.parentNode.parentNode;
      parentElem.style.display = 'none';
    }
  };

  confirmModalHandler = () => {
    this.props.goBack();
  };

  closeConfirmModalHandler = () => {
    this.closeAllModal();
  };

  closeAllModal = () => {
    Modal.destroyAll();
  };

  openConfirmModal = () => {
    confirm({
      title: 'Are you sure you want to leave, your file upload is incomplete?',
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: this.confirmModalHandler,
      onCancel: this.closeConfirmModalHandler,
    });
  };

  render() {
    const menu = (
      <Menu>
        <Menu.Item key="upload">
          <div className="upload-btn-wrapper">
            <div className="add-cursor">Upload file</div>
            <input
              className="input-upload-invisible add-cursor"
              onChange={this.handleUpload}
              ref={ref => {
                this.uploadInput = ref;
              }}
              type="file"
              accept={[...FILES_TYPE, ...IMAGES_TYPE].join(',')}
              multiple
            />
          </div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="folder" onClick={this._handleUpload}>
          Create folder
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="link" onClick={this._handleUpload}>
          Attach a link
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="drive" onClick={() => this.openModalDrive()}>
          Attach link Google Drive
        </Menu.Item>
      </Menu>
    );
    const { selectedRowKeys, fileProgress, assetDetail, page } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      getCheckboxProps: record => ({
        disabled: record.isStoryBoard || record.topicId ? true : false,
      }),
    };
    const columns = this.formatColumns();
    const dataSource = this.formatData();
    const { getFieldDecorator } = this.props.form;
    const { uploadType, modalTitle, modalVisible } = this.state;
    const { hasAssigned, totalAssets } = this.props;
    const isProjectDeleted = this.props.currentProject.isDelete || null;
    // const abc = fileProgress.length && fileProgress.map()
    return (
      <React.Fragment>
        <VideoThumbnailExtractor ref={this.videoThumbnailExtractorRef} />
        <Dropdown
          overlay={menu}
          trigger={['click']}
          disabled={!hasAssigned || this.state.isUploading}
        >
          <Button
            type="primary"
            icon="link"
            className="btn-primary-0"
            disabled={isProjectDeleted}
          >
            Upload
          </Button>
        </Dropdown>
        <br />
        <div className="upload-progress-wrapper">
          {fileProgress.length > 0 &&
            fileProgress.map((item, index) => (
              <AssetFileProgress
                onUploaded={this.onFinishedUpload}
                file={item}
                key={`FILE_PROGRESS_${index}`}
              />
            ))}
        </div>
        <Spin spinning={this.props.assets.isLoading || this.state.isUploading}>
          <div className="mgt-20">
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={dataSource}
              pagination={{
                total: totalAssets,
                onChange: this._onChangePage,
                current: page,
              }}
            />
          </div>
        </Spin>
        <Modal
          title={modalTitle}
          visible={modalVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText={uploadType === 'rename' ? 'Update' : 'Create'}
          centered
          destroyOnClose={true}
        >
          <Form>
            <Form.Item>
              {getFieldDecorator(uploadType, {
                rules: [
                  {
                    required: true,
                    message:
                      uploadType === 'folder'
                        ? 'Please enter name of folder'
                        : uploadType === 'rename'
                        ? 'Please enter title that you want to update'
                        : 'Please enter any link here',
                  },
                ],
                initialValue: this.state.nameOnly ? this.state.nameOnly : null,
              })(
                <Input
                  placeholder={
                    uploadType === 'folder'
                      ? 'Untitle Folder'
                      : uploadType === 'rename'
                      ? ''
                      : 'Paste any link here'
                  }
                  size="large"
                />
              )}
            </Form.Item>
          </Form>
        </Modal>
        <FileDetail
          detail={assetDetail}
          onCancel={() => {
            this.setState({
              assetDetail: null,
            });
          }}
        />
        {this.state.visible && (
          <ModalGoogleDrive
            visible={this.state.visible}
            closeModalDrive={this.closeModalDrive}
            onSubmitMessage={this._handleSelectDrive}
          />
        )}
        <Modal
          visible={this.state.visibleDrive}
          footer={null}
          title={
            <div>
              <span>
                <img
                  className="mr-2"
                  src={IconImageThumbnail}
                  alt=""
                  style={{ width: 25 }}
                />
                Google Drive Authenticate?
              </span>
            </div>
          }
          width={500}
          onCancel={() => this.setState({ visibleDrive: false })}
          destroyOnClose={true}
          closable={true}
          maskClosable={false}
        >
          <div>
            Click <a href="/setting">here</a> to allow access to your Google Drive so you
            can upload files.
          </div>
        </Modal>
      </React.Fragment>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', preventClosing);
  }
}

const WrappedListAssets = Form.create({ name: 'normal_projects' })(ListAssets);

export default WrappedListAssets;
