import { connect } from 'react-redux';
import profileScreen from '../../../components/screens/admin/profile/profileScreen';
import {
  getCurrentUser,
  uploadAvatar,
  updatePassword,
  updateUserInfo,
} from '../../../redux/actions/admin/userAction';

const mapStateToProps = state => {
  return {
    success: state.units.success,
    error: state.units.error,
    isLoading: state.units.isLoading,
    user: state.user.data,
    userLoading: state.user.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetCurrentUser: () => {
      dispatch(getCurrentUser());
    },
    _uploadAvatar: data => {
      dispatch(uploadAvatar(data));
    },
    _updatePassword: data => {
      dispatch(updatePassword(data));
    },
    _updateUserInfo: data => {
      dispatch(updateUserInfo(data));
    },
  };
};

const profileContainer = connect(mapStateToProps, mapDispatchToProps)(profileScreen);

export default profileContainer;
