import {
    GET_CUSTOMER_STORIES_REQUEST,
    GET_CUSTOMER_STORIES_SUCCESS,
    GET_CUSTOMER_STORIES_FAILED,
    DELETE_CUSTOMER_STORIES_REQUEST,
    DELETE_CUSTOMER_STORIES_SUCCESS,
    DELETE_CUSTOMER_STORIES_FAILED,
    CREATE_CUSTOMER_STORIES_REQUEST,
    CREATE_CUSTOMER_STORIES_SUCCESS,
    CREATE_CUSTOMER_STORIES_FAILED,
    UPDATE_CUSTOMER_STORIES_REQUEST,
    UPDATE_CUSTOMER_STORIES_SUCCESS,
    UPDATE_CUSTOMER_STORIES_FAILED,
    UPDATE_ORDER_CUSTOMER_STORIES_SUCCESS,
    UPDATE_ORDER_CUSTOMER_STORIES_FAILED,
    GET_STORIES_HIGHT_LIGHT_SUCCESS,
    GET_STORIES_HIGHT_LIGHT_FAILED
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';
import { updateHelper, compare } from '../../../core/utils/commonUtils';

const defaultState = {
    success: false,
    error: null,
    data: [],
    hightLight: [],
    isLoading: false
};

const customerStoriesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case GET_CUSTOMER_STORIES_REQUEST: {
            return {
                ...state
            };
        }
        case GET_CUSTOMER_STORIES_SUCCESS: {
            return {
                ...state,
                ...action.payload
            };
        }
        case GET_CUSTOMER_STORIES_FAILED: {
            openNotification('error', action.payload.message);
            return {
                ...state,
                error: action.payload
            };
        }
        case DELETE_CUSTOMER_STORIES_REQUEST: {
            return {
                ...state,
                isLoading: true
            };
        }
        case DELETE_CUSTOMER_STORIES_SUCCESS: {
            openNotification('success', 'Delete successfully');
            return {
                ...state,
                isLoading: false,
                data: state.data.filter(item => item._id !== action.payload)
            };
        }
        case DELETE_CUSTOMER_STORIES_FAILED: {
            openNotification('error', action.payload.message);
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        }
        case CREATE_CUSTOMER_STORIES_REQUEST: {
            return {
                ...state,
                isLoading: true
            };
        }
        case CREATE_CUSTOMER_STORIES_SUCCESS: {
            openNotification('success', 'Create successfully');
            return {
                ...state,
                isLoading: false,
                data: [action.payload.data, ...state.data].sort(compare)
            };
        }
        case CREATE_CUSTOMER_STORIES_FAILED: {
            openNotification('error', action.payload.message);
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        }
        case UPDATE_CUSTOMER_STORIES_REQUEST: {
            return {
                ...state,
                isLoading: true
            };
        }
        case UPDATE_CUSTOMER_STORIES_SUCCESS: {
            openNotification('success', 'Update successful');
            return {
                ...state,
                isLoading: false,
                data: updateHelper(state, action)
            };
        }
        case UPDATE_CUSTOMER_STORIES_FAILED: {
            openNotification('error', action.payload.message);
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        }
        case UPDATE_ORDER_CUSTOMER_STORIES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                data: action.payload.data
            };
        }
        case UPDATE_ORDER_CUSTOMER_STORIES_FAILED: {
            openNotification('error', action.payload.message);
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        }
        case GET_STORIES_HIGHT_LIGHT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                hightLight: action.payload
            };
        }
        case GET_STORIES_HIGHT_LIGHT_FAILED: {
            openNotification('error', action.payload.message);
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        }
        default:
            return state;
    }
};

export default customerStoriesReducer;
