import {
	GET_SKILLS_REQUEST,
	CREATE_SKILLS_REQUEST,
	UPDATE_SKILLS_REQUEST,
	UPDATE_ORDER_SKILLS_SUCCESS
} from '../typeAction';

export const getSkills = () => ({
	type: GET_SKILLS_REQUEST
});

export const createSkills = data => ({
	type: CREATE_SKILLS_REQUEST,
	payload: data
});

export const updateSkills = ({ data, id }) => ({
	type: UPDATE_SKILLS_REQUEST,
	payload: { data, id }
});

export const updateOrder = data => ({
	type: UPDATE_ORDER_SKILLS_SUCCESS,
	payload: data
});
