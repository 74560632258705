import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const verifyTokenAsana = async () => {
  const url = BaseConfig.END_POINT.OAUTH.VERIFY_TOKEN_ASANA;
  const { data } = await httpService._get(url);
  return data;
};

export const logoutAsana = () => {
  const url = BaseConfig.END_POINT.OAUTH.LOGOUT_ASANA;
  return httpService._post(url);
};
