import {
	GET_POLICIES_REQUEST,
	CREATE_POLICIES_REQUEST,
	UPDATE_POLICIES_REQUEST
} from '../typeAction';

export const getPolicies = type => ({
	type: GET_POLICIES_REQUEST,
	payload: type
});

export const createPolicies = data => ({
	type: CREATE_POLICIES_REQUEST,
	payload: data
});

export const updatePolicies = ({ data, id }) => ({
	type: UPDATE_POLICIES_REQUEST,
	payload: { data, id }
});
