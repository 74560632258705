import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Jumbotron, Container } from 'react-bootstrap';
import './storiesScreen.scss';
import DataTable from '../../../common/dragTable/dragTable';
import { ORDER_KEYS } from '../../../../core/constants/constants';
import { getS3CDN } from '../../../../core/utils/commonUtils';
import { isYoutubeLink } from '../../../../core/utils/customValidator';

class StoriesScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: 'Admin Our Stories - VidOps',
			data: []
		};
	}

	componentDidMount() {
		this.props.onGetCurrentUser();
		this.props.onGetOurStories();
	}

	formatColumns = () => {
		return [
			{
				title: 'Title',
				dataIndex: 'title'
			},
			{
				title: 'Media',
				dataIndex: 'featureUrl',
				render: (text, record) => {
					if (record.type && record.type === 'image') {
						return <img className="thumbnail" src={getS3CDN(record.featureUrl)} alt="media" />;
					}
					return (
						<span>
							{record.thumbnailUrl ? (
								<img className="thumbnail" src={getS3CDN(record.thumbnailUrl)} alt="media" />
							) : (
								record.featureUrl
							)}
						</span>
					);
				}
			},
			{
				title: 'Status',
				dataIndex: 'status',
				render: status => {
					if (status) {
						return <span>Active</span>;
					}
					return <span>Inactive</span>;
				},
				filters: [
					{
						text: 'Active',
						value: 'Active'
					},
					{
						text: 'Inactive',
						value: 'Inactive'
					}
				],
				onFilter: (value, record) => {
					const status = record.status ? 'Active' : 'Inactive';
					return status.indexOf(value) === 0;
				}
			}
		];
	};

	formatData = data => {
		if (data && data.length) {
			return data.map((item, index) => {
				return {
					...item,
					key: item._id,
					featureUrl: isYoutubeLink(item.featureUrl)
						? item.featureUrl
						: getS3CDN(item.featureUrl)
				};
			});
		}
	};

	updateOrderHandler = orderData => {
		const updateOrderData = {
			key: ORDER_KEYS.STORY,
			...orderData
		};
		this.props.onUpdateOrder(updateOrderData);
	};

	compare = (a, b) => {
		if (a.orderStory < b.orderStory) {
			return -1;
		}
		if (a.orderStory > b.orderStory) {
			return 1;
		}
		return 0;
	};

	render() {
		const { stories } = this.props;
		const { list } = stories;
		const mergeMap = list && list.sort(this.compare);
		const columns = this.formatColumns();
		const dataSource = this.formatData(mergeMap);
		return (
			<main role="main" className="page-content stories-screen">
				<Jumbotron className="mgt-40">
					<h2 className="sub-heading">Our Stories</h2>
					<p className="description">Manage order Our Stories</p>
					<div className="divider"></div>
					<Container>
						<DataTable
							columns={columns}
							dataSource={dataSource}
							pagination={false}
							onUpdateOrder={this.updateOrderHandler}
						/>
					</Container>
				</Jumbotron>
			</main>
		);
	}
}

export default withTranslation('common')(StoriesScreen);
