import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const oauthGoogleDrive = () => {
  return BaseConfig.END_POINT.OAUTH.GOOGLE_DRIVE;
};

export const verifyTokenDrive = () => {
  const url = BaseConfig.END_POINT.OAUTH.VERIFY_TOKEN_DRIVE;
  const response = httpService._get(url);
  return response;
};

export const logoutDrive = () => {
  const url = BaseConfig.END_POINT.OAUTH.LOGOUT_DRIVE;
  const response = httpService._post(url);
  return response;
};

export const setPublicAllFiles = (data) => {
  const url = BaseConfig.END_POINT.OAUTH.SET_PUBLIC_ALL_FILES;
  const response = httpService._post(url, data);
  return response;
}

export const getListFiles = (data) => {
  const url = BaseConfig.END_POINT.OAUTH.GET_LIST_FILES;
  const response = httpService._post(url, data);
  return response;
}