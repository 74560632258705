import {
	UPDATE_BANNER_REQUEST,
	UPDATE_BANNER_SUCCESS,
	UPDATE_BANNER_FAILED,
	GET_BANNER_REQUEST,
	GET_BANNER_SUCCESS,
	GET_BANNER_FAILED,
	GET_REVIEWS_REQUEST,
	GET_REVIEWS_SUCCESS,
	GET_REVIEWS_FAILED
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';

const defaultState = {
	success: false,
	error: null,
	data: null,
	isLoading: false,
	reviews: null
};

const bannerReducer = (state = defaultState, action) => {
	switch (action.type) {
		case UPDATE_BANNER_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case UPDATE_BANNER_SUCCESS: {
			openNotification('success', 'Update successful');
			return {
				...state,
				isLoading: false,
				...action.payload
			};
		}
		case UPDATE_BANNER_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case GET_BANNER_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case GET_BANNER_SUCCESS: {
			return {
				...state,
				isLoading: false,
				...action.payload
			};
		}
		case GET_BANNER_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case GET_REVIEWS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case GET_REVIEWS_SUCCESS: {
			return {
				...state,
				isLoading: false,
				reviews: action.payload.data
			};
		}
		case GET_REVIEWS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		default:
			return state;
	}
};

export default bannerReducer;
