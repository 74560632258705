import React from 'react';
import { connect } from 'react-redux';
import './workspace.scss';
import { Form, Tabs, Button, Icon, Modal } from 'antd';
import FileAndLinkForm from './topicsScreen/filesAndLinksForm/filesAndLinksForm';
import TopicList from './topicsScreen/topicsScreen';
import socketProjectNameSpace from '../../../../core/sockets';
import Conversation from './topicsScreen/conversationForm/conversationForm';
import TopicsForm from './topicsScreen/topicsForm/topicsForm';
import { getTopicById } from '../../../../redux/actions/admin/topicsAction';
import { openNotification } from '../../notification/notification';
import { filterAcceptedMembers } from '../../../../core/utils/commonUtils';
import TopicActivities from 'src/components/common/form/workspace/topicActivities/topicActivities';
import confirmModal from 'src/components/common/confirmModal/confirmModal';

const { TabPane } = Tabs;

class Workspace extends React.Component {
  constructor(props) {
    super(props);
    this.socketProject = socketProjectNameSpace();
    this.state = {
      title:
        this.props.currentProject && this.props.currentProject.title
          ? this.props.currentProject.title
          : null,
      activitiesPage: 1,
      activitiesLimit: '',
      isContainerDisplayed: true,
      topicSource: 'topicList',
      isSendingMessage: false,
    };
  }

  onLoadMoreActivitiesHandler = () => {
    const { activities, currentProject } = this.props;
    const totalPage = activities.pages;
    const { activitiesPage } = this.state;
    if (activitiesPage < totalPage) {
      this.setState(
        prevState => ({
          activitiesPage: prevState.activitiesPage + 1,
        }),
        () => {
          this.props.onLoadMoreActivities({
            id: currentProject._id,
            page: this.state.activitiesPage,
            limit: this.state.activitiesLimit,
          });
        }
      );
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const status = values.status.trim();
        const data = {
          id: this.props.currentProject._id,
          status,
        };
        this.props.onUpdateStatus(data);
      }
    });
  };

  filterAcceptedCreatives = creatives => {
    if (creatives && creatives.length) {
      return creatives.filter(item => item.status === 'ACCEPT');
    }
    return [];
  };

  getActivities = () => {
    const { currentProject } = this.props;
    const projectId = currentProject._id;
    const params = {
      page: 1,
      id: projectId,
    };
    this.props.onGetActivities(params);
  };

  tabChange = key => {
    if (key === 'activity') {
      this.setState({
        activitiesPage: 1,
      });
      this.getActivities();
    }
    this.props.onSetQuoteFilesEmpty();
  };

  onSwitchChange = checked => {
    const { currentProject } = this.props;
    this.props.onUpdateProjectCall({
      id: currentProject._id,
      isUrgent: checked ? true : false,
    });
  };

  preventBackClick = () => {
    this.setState({
      isSendingMessage: true,
    });
  };

  allowBackClick = () => {
    this.setState({
      isSendingMessage: false,
    });
    this.closeAllModal();
  };

  confirmModalHandler = () => {
    this.backHandler();
  };

  closeConfirmModalHandler = () => {
    this.closeAllModal();
  };

  closeAllModal = () => {
    Modal.destroyAll();
  };

  openConfirmModal = () => {
    confirmModal({
      onOk: this.confirmModalHandler,
      onCancel: this.closeConfirmModalHandler,
    });
  };

  renderBackButton = () => {
    const { isSendingMessage } = this.state;
    return (
      <Button
        className="btn-back"
        onClick={() => {
          if (isSendingMessage) {
            this.openConfirmModal();
          } else {
            this.backHandler();
          }
        }}
      >
        <div className="btn-back-icon">
          <Icon className="icon-back" type="left" />{' '}
          <span className="back-text">Back</span>
        </div>
      </Button>
    );
  };

  renderTopicDetail = () => {
    const { currentTopic, currentProject, quoteFiles, currentUser } = this.props;
    return (
      <>
        {this.renderBackButton()}
        <Conversation
          currentUser={currentUser}
          currentProject={currentProject}
          currentTopic={currentTopic}
          quoteFiles={quoteFiles}
          goBack={this.backHandler}
          onToggleActionsContainer={this.props.onToggleActionsContainer}
          onPreventBackClick={() => {
            this.preventBackClick();
            this.props.onPreventBackClick();
          }}
          onAllowBackClick={() => {
            this.allowBackClick();
            this.props.onAllowBackClick();
          }}
        />
      </>
    );
  };

  backHandler = () => {
    this.setState({
      topicSource: 'topicList',
    });
    this.props.onToggleActionsContainer(true);
    this.props.onSetQuoteFilesEmpty();
    // this.state.socketIO.unregisterEvent();
  };

  renderTopicCreateForm = () => {
    const { currentProject } = this.props;
    return (
      <>
        {this.renderBackButton()}
        <TopicsForm
          onClickBackBtn={this.backHandler}
          currentProject={currentProject}
          onToggleActionsContainer={this.props.onToggleActionsContainer}
          onSetTopicSource={this.setTopicSourceHandler}
        />
      </>
    );
  };

  createTopicsHandler = () => {
    this.setState({
      topicSource: 'topicCreate',
    });
    // this.props.onToggleActionsContainer(false);
  };

  renderActions = () => {
    const { hasAssigned } = this.props;
    return (
      <div className="actions-container">
        <Button
          className="btn-primary-0 btn-create-topic"
          onClick={() => this.createTopicsHandler()}
          type="primary"
          disabled={!hasAssigned}
        >
          Create New Topic
        </Button>
      </div>
    );
  };

  setTopicSourceHandler = topicSource => {
    this.setState({
      topicSource,
    });
  };

  renderTopicList = () => {
    const { currentProject, hasAssigned } = this.props;
    return (
      <>
        {this.renderActions()}
        <TopicList
          currentProject={currentProject}
          onToggleActionsContainer={this.props.onToggleActionsContainer}
          onSetTopicSource={this.setTopicSourceHandler}
          hasAssigned={hasAssigned}
        />
      </>
    );
  };

  renderTopicContent = topicSource => {
    switch (topicSource) {
      case 'topicList':
        return this.renderTopicList();
      case 'topicCreate':
        return this.renderTopicCreateForm();
      case 'topicDetail':
        return this.renderTopicDetail();
      default:
        return null;
    }
  };

  workspaceTabsRender = () => {
    const { topicSource } = this.state;
    const { currentProject, hasAssigned, currentTopic } = this.props;
    const activitiesVisable =
      topicSource !== 'topicList' && topicSource !== 'topicCreate' && hasAssigned;
    return (
      <Tabs
        destroyInactiveTabPane={true}
        className="project-tabs"
        onChange={this.tabChange}
        type="card"
      >
        <TabPane tab="Workspace" key="workspace">
          {this.renderTopicContent(topicSource)}
        </TabPane>
        <TabPane tab="Files and links" key="filesAndLinks">
          <FileAndLinkForm currentProject={currentProject} hasAssigned={hasAssigned} />
        </TabPane>
        {activitiesVisable && (
          <TabPane tab="Activity" key="activity">
            <TopicActivities currentTopic={currentTopic} />
          </TabPane>
        )}
      </Tabs>
    );
  };

  filterUserIds = arr => {
    if (arr && arr.length) {
      return arr.map(item => item.userId);
    }
  };

  combineMemberIds = currentProject => {
    const customer =
      currentProject.customerId && currentProject.customerId.userId
        ? [currentProject.customerId.userId]
        : [];
    const editors =
      currentProject.editorId && currentProject.editorId.length
        ? this.filterUserIds(filterAcceptedMembers(currentProject.editorId))
        : [];
    const creatives =
      currentProject.creativeId && currentProject.creativeId.length
        ? this.filterUserIds(filterAcceptedMembers(currentProject.creativeId))
        : [];
    const admins =
      currentProject.admins && currentProject.admins.length
        ? this.filterUserIds(currentProject.admins)
        : [];
    return [...customer, ...editors, ...creatives, ...admins].map(item => item._id);
  };

  isProjectMember = ids => {
    const { currentUser } = this.props;
    return currentUser && ids.includes(currentUser._id);
  };

  componentDidMount() {
    const { currentProject, currentTopic } = this.props;
    // const { currentUser } = this.props;
    if (currentTopic) {
      this.setState({
        topicSource: 'topicDetail',
      });
    }
    this.socketProject.joinProject(currentProject._id);
    this.socketProject.updateProjectSuccess(projectUpdated => {
      if (!this.isProjectMember(this.combineMemberIds(projectUpdated))) {
        this.socketProject.unregisterEvent();
        openNotification(
          'error',
          `You already has been removed of this project ${projectUpdated._id}!`
        );
        this.props.onCloseDrawerClick();
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentProject !== prevProps.currentProject) {
      this.socketProject.updateProject(this.props.currentProject);
    }
    if (this.props.currentTopic !== prevProps.currentTopic) {
      this.setTopicSourceHandler('topicDetail');
    }
  }

  componentWillUnmount() {
    // const { currentUser } = this.props;
    // const { currentProject } = this.props;
    this.socketProject.unregisterEvent();
  }

  render() {
    return this.workspaceTabsRender();
  }
}

const mapStateToProps = state => {
  return {
    success: state.topics.success,
    error: state.topics.error,
    isLoading: state.topics.isLoading,
    topics: state.topics.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetTopicById: id => {
      dispatch(getTopicById(id));
    },
  };
};

const WrappedWorkspace = Form.create({ name: 'normal_projects_workspace' })(Workspace);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedWorkspace);
