import {
  GET_NOTIFICATION_REQUEST,
  COUNT_NOTIFICATION_REQUEST,
  READ_NOTIFICATION_REQUEST,
  READ_ALL_NOTIFICATION_REQUEST, RESET_NOTIFICATION
} from '../typeAction';

export const getNotification = (page, limit) => ({
  type: GET_NOTIFICATION_REQUEST,
  payload: { page, limit },
});

export const readNotification = id => ({
  type: READ_NOTIFICATION_REQUEST,
  payload: id,
});

export const readAllNotifications = () => ({
  type: READ_ALL_NOTIFICATION_REQUEST,
});

export const countNotification = () => ({
  type: COUNT_NOTIFICATION_REQUEST,
  payload: {},
});

export const resetNotification = () => ({
  type: RESET_NOTIFICATION
})