import React from 'react';
import 'antd/dist/antd.css';
import './loginForm.scss';
import { Form, Input, Button, Checkbox } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import {
  isEmailValidate,
  validateInput,
  validateEmail,
} from '../../../../core/utils/customValidator';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const email = values.email.trim();
        if (!isEmailValidate) {
          return;
        }
        const password = values.password.trim();
        const data = {
          email,
          password,
          rememberMe: values.rememberMe,
        };
        this.props.onAuthHandler(data);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <Form className="login-form box-shadow-primary">
          <Form.Item label="Email">
            {getFieldDecorator('email', {
              rules: [
                {
                  validator: (rule, value, callback) => {
                    const msg = validateEmail(value, 'Email');
                    if (msg) {
                      callback(msg);
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(<Input placeholder="Email" size="large" />)}
          </Form.Item>
          <Form.Item label="Password">
            {getFieldDecorator('password', {
              rules: [
                {
                  validator: (rule, value, callback) => {
                    const msg = validateInput(value, 0, 50, 'Password');
                    if (msg) {
                      callback(msg);
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(<Input type="password" placeholder="Password" size="large" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('rememberMe', {
              valuePropName: 'checked',
              initialValue: true,
            })(<Checkbox>Remember me</Checkbox>)}
            <Link
              to="/auth/forgot-password"
              component={React.forwardRef(({ navigate, ...props }, ref) => (
                <button
                  ref={ref}
                  {...props}
                  type="button"
                  className="btn-none login-form-forgot text-primary-0"
                  onClick={navigate}
                >
                  Forgot password?
                </button>
              ))}
            />

            <Button
              type="primary"
              loading={this.props.isLoading}
              onClick={this.handleSubmit}
              className="login-form-button btn-primary-0 mgt-10"
              size="large"
              htmlType="submit"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </React.Fragment>
    );
  }
}

const WrappedLoginForm = Form.create({ name: 'normal_login' })(LoginForm);

export default withRouter(WrappedLoginForm);
