import {
  GET_EDITORS_REQUEST,
  GET_EDITORS_SUCCESS,
  GET_EDITORS_FAILED,
  GET_EDITORS_DETAIL_REQUEST,
  GET_EDITORS_DETAIL_SUCCESS,
  GET_EDITORS_DETAIL_FAILED,
  SEARCH_EDITORS_REQUEST,
  SEARCH_EDITORS_SUCCESS,
  SEARCH_EDITORS_FAILED,
  LOAD_MORE_EDITORS_REQUEST,
  LOAD_MORE_EDITORS_FAILED,
  LOAD_MORE_EDITORS_SUCCESS,
  SEND_INVITATION_EDITORS_REQUEST,
  SEND_INVITATION_EDITORS_SUCCESS,
  SEND_INVITATION_EDITORS_FAILED,
  UPDATE_EDITORS_SUCCESS,
  UPDATE_EDITORS_REQUEST,
  UPDATE_EDITORS_FAILED,
  RESEND_INVITATION_EDITORS_REQUEST,
  RESEND_INVITATION_EDITORS_SUCCESS,
  RESEND_INVITATION_EDITORS_FAILED,
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';

const updateEditorHelper = (state, action) => {
  const index = state.data.list.findIndex(item => item._id === action.payload.data._id);
  const currentList = [...state.data.list];
  currentList[index] = { ...currentList[index], ...action.payload.data };
  return { ...state.data, list: currentList };
};

const loadMoreHelper = (state, action) => {
  const currentList =
    state.data && state.data.list && state.data.list.length
      ? [...state.data.list, ...action.payload.data.list]
      : [...action.payload.data.list];
  return { ...state.data, list: currentList };
};

const defaultState = {
  success: false,
  error: null,
  data: [],
  isLoading: false,
  detailData: null,
  isLoadingMore: false,
  isSendingInvitation: false,
  hasSentInvitation: false,
};

const editorsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_EDITORS_REQUEST: {
      return {
        ...state,
      };
    }
    case GET_EDITORS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case GET_EDITORS_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        error: action.payload,
      };
    }
    case LOAD_MORE_EDITORS_REQUEST: {
      return {
        ...state,
        isLoadingMore: true,
      };
    }
    case LOAD_MORE_EDITORS_SUCCESS: {
      return {
        ...state,
        isLoadingMore: false,
        data: loadMoreHelper(state, action),
      };
    }
    case LOAD_MORE_EDITORS_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        error: action.payload,
        isLoadingMore: false,
      };
    }
    case GET_EDITORS_DETAIL_REQUEST: {
      return {
        ...state,
      };
    }
    case GET_EDITORS_DETAIL_SUCCESS: {
      return {
        ...state,
        detailData: { ...action.payload.data },
      };
    }
    case GET_EDITORS_DETAIL_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        error: action.payload,
      };
    }
    case SEARCH_EDITORS_REQUEST: {
      return {
        ...state,
      };
    }
    case SEARCH_EDITORS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SEARCH_EDITORS_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        error: action.payload,
      };
    }
    case UPDATE_EDITORS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPDATE_EDITORS_SUCCESS: {
      openNotification('success', 'Update successful');
      return {
        ...state,
        isLoading: false,
        data: updateEditorHelper(state, action),
        detailData: action.payload.data,
      };
    }
    case UPDATE_EDITORS_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case SEND_INVITATION_EDITORS_REQUEST: {
      return {
        ...state,
        isSendingInvitation: true,
        hasSentInvitation: false,
      };
    }
    case SEND_INVITATION_EDITORS_SUCCESS: {
      openNotification('success', 'Invitation sent successfully');
      return {
        ...state,
        isSendingInvitation: false,
        hasSentInvitation: true,
      };
    }
    case SEND_INVITATION_EDITORS_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isSendingInvitation: false,
        hasSentInvitation: false,
        error: action.payload,
      };
    }
    case RESEND_INVITATION_EDITORS_REQUEST: {
      return {
        ...state,
        isSendingInvitation: true,
      };
    }
    case RESEND_INVITATION_EDITORS_SUCCESS: {
      openNotification('success', 'Invitation sent successfully');
      return {
        ...state,
        isSendingInvitation: false,
        hasSentInvitation: true,
      };
    }
    case RESEND_INVITATION_EDITORS_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isSendingInvitation: false,
        hasSentInvitation: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

export default editorsReducer;
