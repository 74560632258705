import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Tabs } from 'antd';
import { Jumbotron, Container } from 'react-bootstrap';
import { chain } from 'lodash';
import './addonsScreen.scss';
import ModalData from '../../../common/newModal/newModal';
import AddonsForm from '../../../common/form/addonsForm/addonsForm';
import { ORDER_KEYS } from '../../../../core/constants/constants';
import Table from 'react-bootstrap/Table';

const { TabPane } = Tabs;

class AddonsScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: 'Admin Addons - VidOps',
			data: [],
			key: '',
			itemUpdate: null,
			isModalOpen: false,
			isCreate: false,
			subCategoryId: null,
			detailCategories: [],
			categoryId: null,
			currentService: null
		};
	}

	componentDidMount() {
		this.props.onGetCurrentUser();
		this.props.onGetAddons();
		this.props.onGetPackages();
		this.props.onGetCategories();
	}

	handleUpdate = item => {
		if (item) {
			this.setState({
				itemUpdate: item
			});
		}
		this.setState({
			isModalOpen: true,
			isCreate: false
		});
	};

	handleAdd = data => {
		this.setState({
			isModalOpen: true,
			isCreate: true,
			currentService: data.currentService,
			categoryId: data.cateId,
			subCategoryId: data.subId
		});
	};

	createHandler = data => {
		this.props.onCreateAddons(data);
	};

	updateHandler = data => {
		const payload = {
			data,
			id: this.state.key
		};
		this.props.onUpdateAddons(payload);
	};

	closeModalHandler = () => {
		this.setState({
			isModalOpen: false
		});
	};

	// Group by name and add key for table row
	formatAddons = addons => {
		return chain(addons)
			.groupBy('name')
			.map((value, key) => ({ key: key, values: value }))
			.value();
	};

	formatData = data => {
		if (data && data.length) {
			return data.map(item => {
				return {
					...item,
					addOns: this.formatAddons(item.addOns)
				};
			});
		}
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.data !== this.props.data) {
			this.setState({
				isModalOpen: false
			});
			this.props.onGetCategoriesById(this.state.categoryId);
			this.props.onGetPackages();
		}
		if (nextProps.categories !== this.props.categories) {
			this.setState({
				categoryId: nextProps.categories[0]._id
			});
			this.props.onGetCategoriesById(nextProps.categories[0]._id);
		}
		if (nextProps.detailCategories !== this.props.detailCategories) {
			this.setState({
				detailCategories: nextProps.detailCategories
			});
		}
	}

	updateOrderHandler = orderData => {
		const updateOrderData = {
			key: ORDER_KEYS.PRICE,
			...orderData
		};
		this.props.onUpdateOrder(updateOrderData);
	};

	tabsChange = id => {
		this.setState({
			categoryId: id
		});
		this.props.onGetCategoriesById(id);
	};

	filterPackages = packages => {
		return packages.filter(item => !item.isBasic);
	};

	renderAddons = (service, items) => {
		const { packages, productTypeId } = service;
		if (packages && items && packages.length && items.length) {
			// Render packages title
			const packagesRender = packages.map(item => <th key={item._id}>{item.name}</th>);
			// Render prices base on packages
			const itemsRender = items.map(item => {
				const packagesData = [];
				if (item.values[0].productTypeId === productTypeId) {
					return (
						<tr key={item.key}>
							<td>{item.key}</td>
							{packages.map(el => {
								const pricesRender = el.addOns.filter(
									price => price.name === item.key
								)[0];
								if (pricesRender) {
									packagesData.push({
										addOnId: pricesRender._id,
										packageId: el._id,
										name: el.name,
										price: pricesRender.price
									});
									return <td key={el._id}>{pricesRender.price}</td>;
								}
								packagesData.push({
									packageId: el._id,
									name: el.name,
									price: 'N/A'
								});
								return <td key={el._id}>N/A</td>;
							})}
							<td>{item.values && item.values[0].status ? 'Active' : 'Inactive'}</td>
							<td>
								<Button
									onClick={() =>
										this.handleUpdate({
											name: item.key,
											description: item.values[0].description,
											status: item.values[0].status,
											subContent: item.values[0].subContent,
											packages: packagesData,
											order: item.values[0].order,
											subCategoryId: item.values[0].subCategoryId
										})
									}
									type="primary"
									className="btn-primary-0"
									icon="edit"
								></Button>
							</td>
						</tr>
					);
				}
				return null;
			});

			return (
				<React.Fragment>
					<thead>
						<tr>
							<th>Addons/Packages</th>
							{packagesRender}
							<th>Status</th>
							<th>Operation</th>
						</tr>
					</thead>
					<tbody>{itemsRender}</tbody>
				</React.Fragment>
			);
		}
	};

	filterActivePackages = packages => {
		return packages.filter(item => item.status);
	};

	groupPackagesByServiceId = () => {
		const { packages } = this.props;
		const packageFilter = this.filterActivePackages(packages);
		if (packageFilter && packageFilter.length) {
			// Group by name and add key for table row
			const groupArr = chain(packageFilter)
				.groupBy('productTypeId[_id]')
				.map((value, key) => ({
					productTypeId: key,
					packages:
						value && value.length ? value.map(item => ({ ...item, key: item._id })) : []
				}))
				.value();
			return groupArr;
		}
	};

	subCategoriesRender = (category, el) => {
		const { detailCategories } = this.state;
		const detailCategoriesFormat = this.formatData(detailCategories);
		if (detailCategoriesFormat && detailCategoriesFormat.length) {
			return detailCategoriesFormat.map(sub => {
				if (sub.productTypeIds && sub.productTypeIds.includes(el.productTypeId)) {
					return (
						<div className="subCategories-container" key={sub._id}>
							<span className="subCategories-heading">
								{sub.name} ({sub.measureUnitId.name})
							</span>
							<Button
								className="btn-primary-0"
								onClick={() =>
									this.handleAdd({
										currentService: el,
										cateId: category._id,
										subId: sub._id
									})
								}
								type="primary"
								style={{ marginBottom: 16 }}
							>
								Add new
							</Button>
							<Table striped bordered hover>
								{this.renderAddons(el, sub.addOns)}
							</Table>
						</div>
					)
				}
				return null;
			});
		}
	};

	renderService = category => {
		const groupPackages = this.groupPackagesByServiceId();
		if (groupPackages && groupPackages.length) {
			return groupPackages.map(el => (
				<div key={el.productTypeId}>
					<div className="mgt-40">
						<h2 className="sub-heading">{el.packages[0].productTypeId.name} Service</h2>
						{this.subCategoriesRender(category, el)}
					</div>
					<div className="divider"></div>
				</div>
			));
		}
	};

	render() {
		const { categories, isLoading } = this.props;
		const {
			isModalOpen,
			isCreate,
			key,
			itemUpdate,
			subCategoryId,
			currentService
		} = this.state;
		const filterPackages =
			currentService && currentService.packages && currentService.packages.length
				? this.filterPackages(currentService.packages)
				: [];
		const categoriesRender = categories.map(item => (
			<TabPane tab={item.name} key={item._id}>
				{this.renderService(item)}
			</TabPane>
		));
		return (
			<main role="main" className="page-content addons-screen">
				<Jumbotron className="mgt-40">
					<h2 className="sub-heading">Addons</h2>
					<p className="description">Manage Addons</p>
					<div className="divider"></div>
					<Container>
						<ModalData
							className="big-modal"
							title={isCreate ? 'Create Addons' : 'Update Addons'}
							visible={isModalOpen}
							onCloseModal={this.closeModalHandler}
							dataSource={
								<AddonsForm
									packages={filterPackages}
									onCreateAddons={this.createHandler}
									onUpdateAddons={this.updateHandler}
									isLoading={isLoading}
									isCreate={isCreate}
									itemUpdate={itemUpdate}
									key={key}
									subCategoryId={subCategoryId}
									currentService={currentService}
								/>
							}
							footer={null}
						/>
						<Tabs defaultActiveKey="1" onChange={this.tabsChange}>
							{categoriesRender}
						</Tabs>
					</Container>
				</Jumbotron>
			</main>
		);
	}
}

export default withTranslation('common')(AddonsScreen);
