import React from 'react';
import './conversationForm.scss';
import { Row, Col, Spin } from 'antd';
import MessageSection from '../messageSection/messageSection';
import TitleForm from '../titleForm/titleForm';
import SettingForm from '../settingForm/settingForm';
import socketTopicNameSpace from '../../../../../../core/sockets';
import { openNotification } from '../../../../notification/notification';
import { shallowEqual } from 'react-redux';

class Conversation extends React.Component {
  constructor(props) {
    super(props);
    this.socketTopic = socketTopicNameSpace();
    this.state = {
      currentTopic: this.props.currentTopic,
    };
  }

  isParticipants = topic => {
    const { currentUser } = this.props;
    const participants =
      topic.participants && topic.participants.length ? topic.participants : [];
    const participantsIndex = participants.findIndex(
      item => item._id === currentUser._id
    );
    return participantsIndex > -1;
  };

  componentDidMount() {
    const { currentTopic } = this.state;
    this.props.onToggleActionsContainer(false);
    // Join topic and listen topic after update successfully
    if (currentTopic) {
      this.socketTopic.joinTopic(currentTopic._id);
    }
    this.socketTopic.updateTopicSuccess(this.onUpdateSocketListener);
    const editor = document.getElementsByClassName('conversation-editor')[0];
    if (editor) {
      editor.scrollIntoView();
    }
  }

  onUpdateSocketListener = newTopic => {
    this.setState(
      {
        currentTopic: newTopic,
      },
      () => {
        if (!this.isParticipants(newTopic)) {
          this.socketTopic.unregisterEvent();
          openNotification(
            'error',
            `You already has been removed of this topic ${newTopic._id}!`
          );
          this.props.goBack();
        }
      }
    );
  };

  componentDidUpdate(prevProps) {
    if (!shallowEqual(this.props.currentTopic, prevProps.currentTopic)) {
      this.setState({
        currentTopic: this.props.currentTopic,
      });
      // Emit topic when got update response
      this.socketTopic.updateTopic(this.props.currentTopic);
    }
  }

  componentWillUnmount() {
    this.socketTopic.unregisterEvent();
  }

  render() {
    const { currentProject, quoteFiles } = this.props;
    const { currentTopic } = this.state;

    return (
      <React.Fragment>
        <div className="conversation-wrapper">
          {currentTopic ? (
            <Row>
              <Col span={17}>
                <TitleForm currentTopic={currentTopic} />
                <MessageSection
                  currentTopic={currentTopic}
                  quoteFiles={quoteFiles}
                  onPreventBackClick={this.props.onPreventBackClick}
                  onAllowBackClick={this.props.onAllowBackClick}
                />
              </Col>
              <Col span={1}></Col>
              <Col span={6}>
                <SettingForm
                  currentProject={currentProject}
                  currentTopic={currentTopic}
                />
              </Col>
            </Row>
          ) : (
            <Spin />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Conversation;
