import { connect } from 'react-redux';
import HowItWorkScreen from '../../../components/screens/admin/howitwork/howitworkScreen';
import {
	getHowitwork,
	deleteHowitwork,
	createHowitwork,
	updateHowitwork,
	updateOrder
} from '../../../redux/actions/admin/howitworkAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';

const mapStateToProps = state => {
	return {
		success: state.howitwork.success,
		error: state.howitwork.error,
		isLoading: state.howitwork.isLoading,
		data: state.howitwork.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetHowitwork: () => {
			dispatch(getHowitwork());
		},
		onDeleteHowitwork: key => {
			dispatch(deleteHowitwork(key));
		},
		onCreateHowitwork: data => {
			dispatch(createHowitwork(data));
		},
		onUpdateHowitwork: ({ data, id }) => {
			dispatch(updateHowitwork({ data, id }));
		},
		onUpdateOrder: data => {
			dispatch(updateOrder(data));
		},
		onGetCurrentUser: () => {
			dispatch(getCurrentUser());
		}
	};
};

const HowItWorkContainer = connect(mapStateToProps, mapDispatchToProps)(HowItWorkScreen);

export default HowItWorkContainer;
