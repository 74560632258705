import {
	GET_FILES_AND_LINKS_REQUEST,
	UPDATE_FILES_AND_LINKS_REQUEST,
	MOVE_FILES_TO_ASSETS_REQUEST
} from '../typeAction';

export const getFilesAndLinks = params => ({
	type: GET_FILES_AND_LINKS_REQUEST,
	payload: params
});

export const updateFile = params => ({
	type: UPDATE_FILES_AND_LINKS_REQUEST,
	payload: params
});

export const moveToAssets = data => ({
	type: MOVE_FILES_TO_ASSETS_REQUEST,
	payload: data
});
