import React from 'react';
import { connect } from 'react-redux';
import { Form, InputNumber } from 'antd';
import { updateProjects } from 'src/redux/actions/admin/projectsAction';
import CreativePayment from './creativePayment';

import './paymentInformation.scss';

class PaymentInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onUpdate = (property, value) => {
    const { currentProject } = this.props;
    const { payment = {} } = currentProject;
    const valueData = Number(value);
    const paymentType = payment[property] || 0;

    if (valueData !== paymentType) {
      this.props.onUpdateProject({
        id: currentProject._id,
        [property]: valueData,
      });
    }
  };

  render() {
    const { currentProject, hasAssigned } = this.props;
    const payment = (currentProject && currentProject.payment) || null;
    const customerPayment = (payment && payment.customerPayment) || null;
    const creativePayment = (payment && payment.creativePayment) || null;
    const editorPayment = (payment && payment.editorPayment) || null;
    const otherPayment = (payment && payment.otherPayment) || null;
    if (currentProject) {
      return (<>
        <div className="information-section">
          <h6 className="information-title">Project finances</h6>
          <div className="payment-container">
            <div className="payment-item-container">
              <p className="item-text">Customer Payment</p>
              <InputNumber
                className="item-input"
                defaultValue={customerPayment}
                onBlur={e => {
                  if (customerPayment !== e.target.value) {
                    this.onUpdate('customerPayment', e.target.value);
                  }
                }}
                onPressEnter={e => this.onUpdate('customerPayment', e.target.value)}
                disabled={!hasAssigned || currentProject.isDelete}
              />
            </div>
            <div className="payment-item-container">
              <p className="item-text">Creative Payment</p>
              <InputNumber
                className="item-input"
                defaultValue={creativePayment}
                onBlur={e => {
                  if (creativePayment !== e.target.value) {
                    this.onUpdate('creativePayment', e.target.value);
                  }
                }}
                onPressEnter={e => this.onUpdate('creativePayment', e.target.value)}
                disabled={!hasAssigned || currentProject.isDelete}
              />
            </div>
            <div className="payment-item-container">
              <p className="item-text">Editor Payment</p>
              <InputNumber
                className="item-input"
                defaultValue={editorPayment}
                onBlur={e => {
                  if (editorPayment !== e.target.value) {
                    this.onUpdate('editorPayment', e.target.value);
                  }
                }}
                onPressEnter={e => this.onUpdate('editorPayment', e.target.value)}
                disabled={!hasAssigned || currentProject.isDelete}
              />
            </div>
            <div className="payment-item-container">
              <p className="item-text">Other</p>
              <InputNumber
                className="item-input"
                defaultValue={otherPayment}
                onBlur={e => {
                  if (otherPayment !== e.target.value) {
                    this.onUpdate('otherPayment', e.target.value);
                  }
                }}
                onPressEnter={e => this.onUpdate('otherPayment', e.target.value)}
                disabled={!hasAssigned || currentProject.isDelete}
              />
            </div>
          </div>
        </div>

        <div className="information-section">
          <h6 className="information-title">Creatives finances</h6>
          <CreativePayment project={currentProject} />
        </div>
      </>);
    } else {
      return (
        <div className="information-section">
          <h6 className="information-title">Payment Information</h6>
          <div className="payment-container">
            <div className="payment-item-container">
              <p className="item-text">Customer Payment</p>
              <InputNumber
                className="item-input"
                onBlur={e => {
                  this.props.onInputFields('customerPayment', e.target.value);
                }}
                onPressEnter={e =>
                  this.props.onInputFields('customerPayment', e.target.value)
                }
              />
            </div>
            <div className="payment-item-container">
              <p className="item-text">Creative Payment</p>
              <InputNumber
                className="item-input"
                onBlur={e => {
                  this.props.onInputFields('creativePayment', e.target.value);
                }}
                onPressEnter={e =>
                  this.props.onInputFields('creativePayment', e.target.value)
                }
              />
            </div>
            <div className="payment-item-container">
              <p className="item-text">Editor Payment</p>
              <InputNumber
                className="item-input"
                onBlur={e => {
                  this.props.onInputFields('editorPayment', e.target.value);
                }}
                onPressEnter={e =>
                  this.props.onInputFields('editorPayment', e.target.value)
                }
              />
            </div>
            <div className="payment-item-container">
              <p className="item-text">Other</p>
              <InputNumber
                className="item-input"
                onBlur={e => {
                  this.props.onInputFields('otherPayment', e.target.value);
                }}
                onPressEnter={e =>
                  this.props.onInputFields('otherPayment', e.target.value)
                }
              />
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateProject: data => {
      dispatch(updateProjects(data));
    },
  };
};

const WrappedPaymentInformation = Form.create({ name: 'normal_payment_information' })(
  connect(mapStateToProps, mapDispatchToProps)(PaymentInformation)
);

export default WrappedPaymentInformation;
