import { put, call, takeLatest } from 'redux-saga/effects';

import {
	GET_EDITORS_FAILED,
	GET_EDITORS_SUCCESS,
	GET_EDITORS_DETAIL_FAILED,
	GET_EDITORS_DETAIL_SUCCESS,
	SEARCH_EDITORS_FAILED,
	SEARCH_EDITORS_SUCCESS,
	GET_EDITORS_REQUEST,
	GET_EDITORS_DETAIL_REQUEST,
	SEARCH_EDITORS_REQUEST,
	LOAD_MORE_EDITORS_FAILED,
	LOAD_MORE_EDITORS_SUCCESS,
	LOAD_MORE_EDITORS_REQUEST,
	SEND_INVITATION_EDITORS_FAILED,
	SEND_INVITATION_EDITORS_REQUEST,
	SEND_INVITATION_EDITORS_SUCCESS,
	UPDATE_EDITORS_SUCCESS,
	UPDATE_EDITORS_FAILED,
	UPDATE_EDITORS_REQUEST,
	RESEND_INVITATION_EDITORS_FAILED,
	RESEND_INVITATION_EDITORS_SUCCESS,
	RESEND_INVITATION_EDITORS_REQUEST
} from '../../actions/typeAction';
import {
	getEditorsService,
	getEditorsDetailService,
	searchEditorsService,
	updateEditorService,
	sendInvitationService,
	resendInvitationService
} from '../../../core/services/modules/editorsService';

export function* getEditorsSaga(action) {
	const response = yield call(getEditorsService, action.payload);
	if (response.data.error) {
		yield put({
			type: GET_EDITORS_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: GET_EDITORS_SUCCESS,
			payload: data
		});
	}
}

export function* loadMoreEditorsSaga(action) {
	const response = yield call(getEditorsService, action.payload);
	if (response.data.error) {
		yield put({
			type: LOAD_MORE_EDITORS_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: LOAD_MORE_EDITORS_SUCCESS,
			payload: data
		});
	}
}

export function* getEditorsDetailSaga(action) {
	const response = yield call(getEditorsDetailService, action.payload);
	if (response.data.error) {
		yield put({
			type: GET_EDITORS_DETAIL_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: GET_EDITORS_DETAIL_SUCCESS,
			payload: data
		});
	}
}

export function* searchEditorsSaga(action) {
	const response = yield call(searchEditorsService, action.payload);
	if (response.data.error) {
		yield put({
			type: SEARCH_EDITORS_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: SEARCH_EDITORS_SUCCESS,
			payload: data
		});
	}
}

export function* updateEditorSaga(action) {
	const response = yield call(updateEditorService, action.payload);
	if (response.data.error) {
		yield put({
			type: UPDATE_EDITORS_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: UPDATE_EDITORS_SUCCESS,
			payload: data
		});
	}
}

export function* sendInvitationSaga(action) {
	const response = yield call(sendInvitationService, action.payload);
	if (response.data.error) {
		yield put({
			type: SEND_INVITATION_EDITORS_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: SEND_INVITATION_EDITORS_SUCCESS,
			payload: data
		});
	}
}

export function* resendInvitationSaga(action) {
	const response = yield call(resendInvitationService, action.payload);
	if (response.data.error) {
		yield put({
			type: RESEND_INVITATION_EDITORS_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: RESEND_INVITATION_EDITORS_SUCCESS,
			payload: data
		});
	}
}

export function* watchEditors() {
	yield takeLatest(GET_EDITORS_REQUEST, getEditorsSaga);
	yield takeLatest(LOAD_MORE_EDITORS_REQUEST, loadMoreEditorsSaga);
	yield takeLatest(GET_EDITORS_DETAIL_REQUEST, getEditorsDetailSaga);
	yield takeLatest(SEARCH_EDITORS_REQUEST, searchEditorsSaga);
	yield takeLatest(UPDATE_EDITORS_REQUEST, updateEditorSaga);
	yield takeLatest(SEND_INVITATION_EDITORS_REQUEST, sendInvitationSaga);
	yield takeLatest(RESEND_INVITATION_EDITORS_REQUEST, resendInvitationSaga);
}
