import qs from 'src/core/utils/querystring';

const RouterHelper = {
  requestJoinTeam({ requestTeamId, email }) {
    const queryString = qs.stringify(
      JSON.parse(
        JSON.stringify({
          requestTeamId,
          callback: JSON.stringify({ query: { email } }),
        })
      )
    );

    return `/dashboard/member?${queryString}`;
  },
  projectWithId({ projectId }) {
    return `/projects/${projectId}`;
  },
  topicWithId({ projectId, topicId }) {
    const queryString = qs.stringify({ topicId });
    return `/projects/${projectId}?${queryString}`;
  },
  creativeProfile ({ creativeId }) {
    return `/creatives/${creativeId}`
  },
  customerProfile({ customerId }) {
    return `/customers/${customerId}`
  }
};

export default RouterHelper;
