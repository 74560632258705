import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Button, Modal } from 'antd';
import { connect } from 'react-redux';
import SelectFilter from 'src/components/common/selectFilter/selectFilter';
import { getTeams, loadMoreTeams } from 'src/redux/actions/admin/teamsAction';
import debounce from 'lodash/debounce';
import './styles.scss';
import { BaseConfig } from 'src/core/configs/baseConfig';
import { httpService } from 'src/core/services/httpService';
import { openNotification } from '../../../../notification/notification';
import { getCustomersDetail } from 'src/redux/actions/admin/customersAction';
import { sendGetCustomersAction } from 'src/core/utils/event_emitter/handler';

class AssignToTeam extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      teamInfo: {},
      currentTeamPage: 1,
      searchTextTeam: '',
    };
    this.searchTeamsHandler = debounce(value => {
      this.setState({
        currentTeamPage: 1,
        searchTextTeam: value.trim(),
      });
      this.props.onGetTeams({
        page: 1,
        limit: 10,
        searchText: value.trim(),
      });
    }, 600);
  }

  componentDidMount() {
    const { currentTeamPage } = this.state;
    this.props.onGetTeams({
      page: currentTeamPage,
      limit: 10,
    });
  }

  closeModal = () => {
    this.setState({
      teamInfo: {},
    });
    this.props.onCloseModal();
  };

  handleLoadMoreTeams = ({ page }) => {
    const { searchTextTeam } = this.state;
    this.props.onLoadMoreTeams({
      page,
      limit: 10,
      searchText: searchTextTeam,
    });
  };

  handleSelectTeamName = value => {
    this.setState({
      searchTextTeam: '',
    });
    this.props.onGetTeams({
      page: 1,
      limit: 10,
    });
    this.props.form.setFieldsValue({ teamName: value });
  };

  handleSubmit = () => {
    try {
      const { customerInfo } = this.props;
      this.setState({ loading: true });

      this.props.form.validateFields(async (err, values) => {
        if (!err) {
          const dataAssign = {
            idTeamWork: values.teamName && values.teamName.trim(),
            idCustomer: customerInfo._id,
          };
          const url = BaseConfig.END_POINT.ADMIN.ASSIGN_TO_TEAM;
          const { data } = await httpService._post(url, dataAssign);
          if (!data.success) {
            openNotification('error', data.error.message);
            this.setState({ loading: false });
          } else {
            this.props.onCloseModal();
            openNotification('success', 'Successfully!');
            this.setState({ loading: false });
            sendGetCustomersAction();
            this.props.onGetCustomersDetail(customerInfo._id);
          }
        } else this.setState({ loading: false });
      });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { visibleModal, teams, customerInfo } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 24,
        },
      },
    };
    return (
      <Modal
        visible={visibleModal}
        footer={null}
        title={`ASSIGN TO TEAM`}
        width={600}
        onCancel={() => this.closeModal()}
        destroyOnClose={true}
        closable={true}
        maskClosable={false}
        className="assign-to-team"
      >
        <p>
          Please select team for{' '}
          <b>
            {customerInfo.userId.email}-{customerInfo.userId.fullName}
          </b>
        </p>
        <Form.Item label="Team Name">
          {getFieldDecorator('teamName', {
            rules: [
              {
                required: true,
                message: 'Please select a team',
              },
            ],
          })(
            <SelectFilter
              data={teams}
              onSearchHandler={this.searchTeamsHandler}
              onLoadMoreHandler={this.handleLoadMoreTeams}
              onSelect={this.handleSelectTeamName}
            />
          )}
        </Form.Item>
        <Form.Item {...tailFormItemLayout} className="text-right">
          <Button
            type="primary"
            className="btn btn-primary-0 mgl-10"
            loading={loading}
            disabled={loading}
            onClick={this.handleSubmit}
            size={'large'}
          >
            Save
          </Button>
        </Form.Item>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    teams: state.teams.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetTeams: params => {
      dispatch(getTeams(params));
    },
    onLoadMoreTeams: params => {
      dispatch(loadMoreTeams(params));
    },
    onGetCustomersDetail: params => {
      dispatch(getCustomersDetail(params));
    },
  };
};

const WrapAssignToTeam = Form.create({ name: 'assign_to_team' })(AssignToTeam);

export default withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(WrapAssignToTeam)
);
