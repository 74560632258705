import { connect } from 'react-redux';
import BudgetScreen from '../../../components/screens/admin/budget/budgetScreen';
import {
	getBudget,
	createBudget,
	updateBudget,
	updateOrder
} from '../../../redux/actions/admin/budgetAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';

const mapStateToProps = state => {
	return {
		success: state.budget.success,
		error: state.budget.error,
		isLoading: state.budget.isLoading,
		data: state.budget.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetBudget: () => {
			dispatch(getBudget());
		},
		onCreateBudget: data => {
			dispatch(createBudget(data));
		},
		onUpdateBudget: ({ data, id }) => {
			dispatch(updateBudget({ data, id }));
		},
		onUpdateOrder: data => {
			dispatch(updateOrder(data));
		},
		onGetCurrentUser: () => {
			dispatch(getCurrentUser());
		}
	};
};

const CustomerStoriesContainer = connect(mapStateToProps, mapDispatchToProps)(BudgetScreen);

export default CustomerStoriesContainer;
