/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import { Icon, notification, Progress } from 'antd';
import axios from 'axios';

const AssetFileProgress = memo(props => {
  const { file, onUploaded = () => null } = props;
  const { origin, thumbnail, originalUrl, thumbnailUrl, fileName } = file;
  const [uploading, setUploading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const onFileUploading = async () => {
    try {
      setUploading(true);

      let promises = [
        axios.put(originalUrl, origin, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => {
            setUploadPercentage(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          },
        }),
      ];

      if (!!thumbnailUrl) {
        promises.push(
          axios.put(thumbnailUrl, thumbnail, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        );
      }

      await Promise.all(promises);
    } catch (e) {
      notification['error']({
        message: `${fileName} uploaded failed!`,
      });
    }

    onUploaded({ origin, thumbnail });
    setUploading(false);
  };

  const onCancelUpload = async () => {};

  const checkIconTypeFile = file => {
    const ext = file.substring(file.lastIndexOf('.') + 1);
    let resIcon;
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'svg':
      case 'png':
      case 'gif':
        resIcon = <Icon type="picture" theme="twoTone" twoToneColor="#f46036" />;
        break;
      case 'pdf':
      case 'doc':
      case 'docx':
      case 'xls':
      case 'xlsx':
      case 'ppt':
      case 'pptx':
      case 'txt':
      case 'zip':
      case 'rar':
      case 'gzip':
        resIcon = <Icon type="file" theme="twoTone" twoToneColor="#f46036" />;
        break;
      default:
        resIcon = <Icon type="file-unknown" theme="twoTone" twoToneColor="#f46036" />;
        break;
    }
    return resIcon;
  };

  useEffect(() => {
    onFileUploading();
  }, []);

  if (!uploading) return null;

  return (
    <div className="upload-progress-body">
      <div className="layer-x-btn">
        <Icon
          type="close-circle"
          className="icon-hide add-cursor"
          onClick={onCancelUpload}
        />
      </div>
      <div className="flex-progress-file-n">
        <div>{checkIconTypeFile(fileName)}</div>
        <div className="file-progress">{fileName}</div>
      </div>
      <Progress
        className="progess-bar-custom"
        percent={uploadPercentage}
        size="small"
        status="active"
      />
    </div>
  );
});

export default AssetFileProgress;
