import React, { memo, useMemo } from 'react';
import { Avatar, Tooltip } from 'antd';
import { getS3CDN } from 'src/core/utils/commonUtils';

import './avatar.scss';

const UserAvatar = ({
  fullName,
  avatar,
  size,
  onClick,
  status,
  iconSrc,
  showTooltip = true,
}) => {
  const checkSize = () => {
    switch (size) {
      case 'small':
        return 'small';
      case 'large':
        return 'large';

      default:
        return 'medium';
    }
  };
  const getFirstLetter = useMemo(() => {
    if (!fullName) return null;

    return fullName.charAt(0).toUpperCase();
  }, [fullName]);

  const renderAvatar = () => {
    return (
      <Tooltip title={showTooltip && fullName}>
        <Avatar
          src={getS3CDN(avatar)}
          className={`user-avatar  ${!!onClick ? 'c-pointer' : ''}`}
          icon={avatar || 'user'}
          onClick={onClick}
        />
      </Tooltip>
    );
  };

  const renderName = () => {
    return (
      <Tooltip title={showTooltip && fullName}>
        <div className={`user-avatar ${!!onClick ? 'c-pointer' : ''}`} onClick={onClick}>
          <span className="text-white">{getFirstLetter}</span>
        </div>
      </Tooltip>
    );
  };

  const renderContent = () => {
    switch (true) {
      case !status:
        return (
          <Tooltip title={showTooltip && fullName}>
            <Avatar
              className={`deactive user-avatar ${!!onClick ? 'c-pointer' : ''}`}
              icon="user"
              onClick={onClick}
            />
          </Tooltip>
        );
      case !avatar:
        const nameRender = renderName();
        return nameRender;
      default:
        const avatarRender = renderAvatar();
        return avatarRender;
    }
  };
  const sizeIcon = checkSize();

  return (
    <div className={`user-avatar-wraper ${sizeIcon}`}>
      {renderContent()}
      {iconSrc && <img className="avatar-icon" src={iconSrc} alt="user icon" />}
    </div>
  );
};

export default memo(UserAvatar);
