import React from 'react';
import { Modal, Tag } from 'antd';
import { BaseConfig } from 'src/core/configs/baseConfig';
import LogoHubspot from 'src/assets/images/hubspot/hubspot.svg';
import {
  logoutHubspot,
  verifyTokenHubspot,
} from 'src/core/services/modules/hubspotService';

import './styles.scss';

const { confirm } = Modal;
export default class HubspotAuth extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      info: {},
    };
    this.externalWindow = null;
    this.codeCheck = null;
  }

  componentDidMount() {
    setTimeout(() => {
      this.verifyToken();
    }, 1000);
  }

  componentWillUnmount() {
    if (this.externalWindow) {
      this.externalWindow.close();
    }
  }

  verifyToken = async () => {
    try {
      const { data: info } = await verifyTokenHubspot();
      this.setState({
        info,
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  login = () => {
    const url = BaseConfig.END_POINT.OAUTH.HUBSPOT;
    this.externalWindow = window.open(url);

    this.codeCheck = setInterval(() => {
      try {
        const params = new URL(this.externalWindow.location).searchParams;
        const code = params.get('response');
        if (!code) return;
        clearInterval(this.codeCheck);
        this.externalWindow.close();
        window.location.reload();
      } catch (err) {
        console.log('err', err);
      }
    }, 100);

    this.externalWindow.onbeforeunload = () => clearInterval(this.codeCheck);
  };

  logout = async () => {
    try {
      confirm({
        title: 'Disconnect Hubspot?',
        content: 'Are you sure you want to disconnect to Hubspot?',
        okText: 'OK',
        cancelText: 'Cancel',
        onOk: async () => {
          const result = await logoutHubspot();
          if (result.data) {
            this.setState({
              info: {},
            });
          }
        },
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  renderLogin = () => {
    return (
      <button
        className="mt-3 button-hubspot-login d-flex align-items-center justify-content-center"
        onClick={() => this.login()}
      >
        <img src={LogoHubspot} alt="" />
        Login with Hubspot
      </button>
    );
  };

  renderLogout = () => {
    const { info } = this.state;
    return (
      <>
        <Tag color="green">Authenticated as {info.user}</Tag>
        <button
          className="mt-3 button-hubspot-logout d-flex align-items-center justify-content-center"
          onClick={() => this.logout()}
        >
          <span>
            <img src={LogoHubspot} alt="" />
            Disconnect with Hubspot
          </span>
        </button>
      </>
    );
  };

  render() {
    const { info } = this.state;
    return (
      <div className="hubspot mt-2">
        {!info?.user ? this.renderLogin() : this.renderLogout()}
      </div>
    );
  }
}
