import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container } from 'react-bootstrap';
import './forgotPassword.scss';
import WrappedForgotPasswordForm from 'src/components/common/form/forgotPasswordForm/forgotPasswordForm';
import { storageService } from 'src/core/services/storageService';
import { convertUrlParametersToObject } from 'src/core/utils/commonUtils';
import ResetPasswordForm from 'src/components/common/form/resetPasswordForm/resetPasswordForm';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Forgot Password - VidOps',
      hasClickedLink: false,
      token: '',
    };
  }

  componentDidMount() {
    document.getElementById('sidebar-wrapper').style.display = 'none';
    document.getElementById('navbar-admin').style.display = 'none';
    document.getElementById('page-content-wrapper').style.background = '#ffffff';

    if (this.props.location && this.props.location.search) {
      const { token } = convertUrlParametersToObject(this.props.location);

      storageService.setAuthToken(token);
      this.setState({
        hasClickedLink: true,
        token,
      });
    }
  }

  onAuthHandler = data => {
    this.props.onAuth(data);
  };

  componentWillUnmount() {
    document.getElementById('sidebar-wrapper').style.display = '';
    document.getElementById('navbar-admin').style.display = '';
    document.getElementById('page-content-wrapper').style.background = '#dddde0';
  }

  onResetPasswordHandler = password => {
    this.props.onResetPassword({ ...password, token: this.state.token });
  };

  render() {
    return (
      <div className="forgot-password-page">
        <main role="main" className="page-content">
          <Container>
            {!this.state.hasClickedLink ? (
              <WrappedForgotPasswordForm
                {...this.props}
                onAuthHandler={this.onAuthHandler}
                isLoading={this.props.isLoading}
              />
            ) : (
              <ResetPasswordForm
                onResetPassword={this.onResetPasswordHandler}
                success={this.props.resetSuccess}
              />
            )}
          </Container>
        </main>
      </div>
    );
  }
}

export default withTranslation('common')(Login);
