import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'antd';
import { Link } from 'react-router-dom';
import DataTable from 'src/components/common/dataTable/dataTable';

class TeamProjectsModal extends PureComponent {
  render() {
    const { visibleModal, projects } = this.props;

    const columns = [
      {
        title: 'Project ID',
        render: (_, record) => {
          return (
            <Link
              className="project-id"
              target="_blank"
              to={{ pathname: '/projects/' + record._id }}
              // onClick={() => this.handleGetDetail(projectId)}
            >
              {record.alias || 'Add Project ID'}
            </Link>
          );
        },
      },
      {
        title: 'Project Name',
        dataIndex: 'title',
        width: 240,
      },
      {
        title: 'Status',
        dataIndex: 'status',
      },
    ];

    return (
      <Modal
        className="modal-create-team"
        visible={visibleModal}
        footer={null}
        title={'PROJECTS'}
        width={600}
        onCancel={() => this.props.onCloseModal()}
        destroyOnClose={true}
        closable={true}
        maskClosable={false}
      >
        <DataTable
          columns={columns}
          dataSource={projects}
          onChangeHandler={this.handleChangeTable}
          hiddenUpdateOrder={true}
        />
      </Modal>
    );
  }
}

const WrapCreateTeam = Form.create({ name: 'normal_get_started' })(TeamProjectsModal);

export default withTranslation('common')(WrapCreateTeam);
