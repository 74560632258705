import {
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_DETAIL_REQUEST,
  SEARCH_PROJECTS_REQUEST,
  UPDATE_STATUS_PROJECTS_REQUEST,
  UPDATE_PROJECTS_REQUEST,
  CLEAR_PROJECTS_DETAIL,
  ADMIN_CREATE_PROJECT_REQUEST,
  GET_NUMBER_OF_PROJECTS_REQUEST,
  GET_BRIEF_HISTORY_REQUEST,
} from '../typeAction';

export const getProjects = data => ({
  type: GET_PROJECTS_REQUEST,
  payload: data,
});

export const getProjectsDetail = id => ({
  type: GET_PROJECTS_DETAIL_REQUEST,
  payload: id,
});

export const createProject = (data, uploads) => ({
  type: ADMIN_CREATE_PROJECT_REQUEST,
  payload: { data, uploads },
});

export const searchProjects = data => ({
  type: SEARCH_PROJECTS_REQUEST,
  payload: data,
});

/**
 * @param {{ id: string, [key: any]: any }} data
 */
export const updateProjects = data => ({
  type: UPDATE_PROJECTS_REQUEST,
  payload: data,
});

export const clearProjectsDetail = () => ({
  type: CLEAR_PROJECTS_DETAIL,
});

export const updateStatus = data => ({
  type: UPDATE_STATUS_PROJECTS_REQUEST,
  payload: data,
});

export const getNumberOfProject = data => ({
  type: GET_NUMBER_OF_PROJECTS_REQUEST,
  payload: data,
});

export const getBriefHistoryProjects = data => ({
  type: GET_BRIEF_HISTORY_REQUEST,
  payload: data,
});
