import React from 'react';
import 'antd/dist/antd.css';
import './scrollList.scss';
import { List, Spin, Icon } from 'antd';
import UserAvatar from 'src/components/common/userAvatar/index.js';

import InfiniteScroll from 'react-infinite-scroller';

class scrollList extends React.Component {
  handleInfiniteOnLoad = () => {
    this.props.loadMore();
  };

  renderList = () => {
    const { data, isLoading, type, ids } = this.props;
    const { list } = data;
    return (
      <List
        dataSource={list}
        renderItem={item => {
          let isOnlyOneAdmin =
            type === 'admins' && ids && ids.length === 1 && ids.includes(item._id);
          let avt, fullName;
          if (type === 'admins') {
            avt = item.userId.avt;
            fullName = item.userId.fullName;
          } else {
            avt = item.avt;
            fullName = item.fullName;
          }
          return (
            <List.Item
              key={item._id}
              onClick={
                isOnlyOneAdmin
                  ? () => {
                      return;
                    }
                  : () => this.props.onSelectItem(item._id, type)
              }
            >
              <List.Item.Meta
                className={isOnlyOneAdmin && 'prevent-click-item'}
                avatar={
                  <UserAvatar
                    key={item._id}
                    status={true}
                    avatar={avt}
                    fullName={fullName}
                    size="small"
                  />
                }
                title={item.fullName || (item.userId && item.userId.fullName)}
                description={item.email || (item.userId && item.userId.email)}
              />
              {ids && ids.includes(item._id) ? (
                <Icon className="icon-check" type="check" />
              ) : null}
            </List.Item>
          );
        }}
      >
        {isLoading && (
          <div className="demo-loading-container">
            <Spin />
          </div>
        )}
      </List>
    );
  };

  render() {
    const { data, isLoading } = this.props;
    const { list, total } = data;
    return (
      <div className="demo-infinite-container">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={this.handleInfiniteOnLoad}
          hasMore={!isLoading && list && list.length < total}
          useWindow={false}
        >
          {this.renderList()}
        </InfiniteScroll>
      </div>
    );
  }
}

export default scrollList;
