import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import 'antd/dist/antd.css';
import './topicsScreen.scss';
import { List, Button, Icon, Row, Col } from 'antd';
import moment from 'moment';
import { getTopics, getTopicById } from '../../../../../redux/actions/admin/topicsAction';
import socket from '../../../../../core/sockets/conversation';
import { storageService } from '../../../../../core/services/storageService';
import { isDeactiveUser } from '../../../../../core/utils/commonUtils';
import UserAvatar from 'src/components/common/userAvatar/index.js';

class TopicsScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      socketIO: socket(),
      currentUser: storageService.getCurrentUser(),
    };
    this.searchHandler = debounce(value => {}, 500);
  }

  componentDidMount() {
    const projectId = this.props.currentProject._id;
    const params = {
      page: 1,
      id: projectId,
    };
    this.props.onGetTopics(params);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentTopic !== this.props.currentTopic) {
      this.props.onSetTopicSource('topicDetail');
    }
  }

  backHandler = () => {
    const projectId = this.props.currentProject._id;
    const params = {
      page: 1,
      id: projectId,
    };
    this.props.onGetTopics(params);
    this.setState({
      currentPage: 1,
    });
    this.state.socketIO.unregisterEvent();
    this.props.onToggleActionsContainer(true);
  };

  componentWillUnmount() {
    this.state.socketIO.unregisterEvent();
  }

  onloadMoreClick = () => {
    const { currentPage } = this.state;
    const projectId = this.props.currentProject._id;
    const params = {
      page: currentPage + 1,
      id: projectId,
    };
    this.props.onGetTopics(params);
    this.setState({
      currentPage: currentPage + 1,
    });
  };

  _showConversation = topicId => {
    this.props.onGetTopicById(topicId);
    // this.props.onToggleActionsContainer(false);
  };

  renderListTopic = item => {
    const { hasAssigned } = this.props;
    const { avt, fullName } = (item && item.creator) || {};
    return (
      <List.Item
        className="add-cursor"
        onClick={() => {
          if (hasAssigned) this._showConversation(item._id);
        }}
      >
        <Row className="topics-row">
          <Col span={3}>#{item.alias}</Col>
          <Col span={7}>{item.title}</Col>
          <Col span={2}>
            <div className="unread-container">
              <Icon className="icon-message" type="message" />
              {item && item.unRead > 0 ? (
                <div className="unread-number-container">
                  <span className="unread-number">{item.unRead}</span>
                </div>
              ) : null}
            </div>
          </Col>
          <Col span={3}>{item.status ? 'Open' : 'Closed'}</Col>
          <Col span={3}>
            <UserAvatar
              fullName={fullName}
              avatar={avt}
              status={!isDeactiveUser(item.creator)}
              size="small"
            />
          </Col>
          <Col span={6}>{moment(item.lastMessage).format('YYYY/MM/DD HH:mm')}</Col>
        </Row>
      </List.Item>
    );
  };

  renderTopics = () => {
    const { currentPage } = this.state;
    const { topics, isLoading } = this.props;
    const list = topics && topics.docs && topics.docs.length ? topics.docs : [];

    const totalPage = topics && topics.pages ? topics.pages : 1;
    return (
      <>
        <Row className="topics-row topic-title">
          <Col span={3}>ID</Col>
          <Col span={7}>Title</Col>
          <Col span={2}></Col>
          <Col span={3}>Status</Col>
          <Col span={3}></Col>
          <Col span={6}>Last Updated</Col>
        </Row>
        <List dataSource={list} renderItem={item => this.renderListTopic(item)}></List>
        {currentPage < totalPage ? (
          <Button
            className="btn-load-more btn-primary-0"
            type="primary"
            loading={isLoading}
            onClick={() => this.onloadMoreClick()}
          >
            Load more
          </Button>
        ) : null}
      </>
    );
  };

  render() {
    return this.renderTopics();
  }
}

const mapStateToProps = state => {
  return {
    success: state.topics.success,
    error: state.topics.error,
    isLoading: state.topics.isLoading,
    topics: state.topics.data,
    currentTopic: state.topics.detailData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetTopics: params => {
      dispatch(getTopics(params));
    },
    onGetTopicById: id => {
      dispatch(getTopicById(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopicsScreen);
