/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useRef, useState } from 'react';
import Linkify from 'react-linkify';
import FileExtentions from 'src/components/common/fileExtentions';
import FileDetail from 'src/components/common/fileDetail';
import { checkYoutubeLink } from 'src/core/utils/commonUtils';

const LinkDecorator = memo(props => {
  const { url, onGetLink = () => null } = props;

  useEffect(() => {
    onGetLink(url);
  }, [url]);

  return null;
});

const YoutubePreviewer = memo(props => {
  const { url, onClick = () => null } = props;

  return (
    <div className="mt-2 rounded">
      <FileExtentions
        className="comment-content-video"
        fileName="link"
        path={url}
        onclick={onClick}
      />
    </div>
  );
});

const MessageLinkifier = memo(props => {
  const { content } = props;
  const links = useRef(new Set());
  const [listURL, setListURL] = useState([]);
  const [detail, setDetail] = useState(null);

  const onGetLink = url => {
    if (checkYoutubeLink(url)) {
      links.current.add(url);
      setListURL([...links.current]);
    }
  };

  const renderComponentDecorator = (href, text, key) => {
    return (
      <React.Fragment key={key}>
        <a href={href} target="_blank" without="true" rel="noopener noreferrer">
          {text}
        </a>

        <LinkDecorator url={href} onGetLink={onGetLink} />
      </React.Fragment>
    );
  };

  return (
    <>
      <Linkify componentDecorator={renderComponentDecorator}>{content}</Linkify>

      <div>
        {listURL.map(item => (
          <YoutubePreviewer
            key={item}
            url={item}
            onClick={st => setDetail({ path: item })}
          />
        ))}
      </div>
      <FileDetail
        detail={detail}
        onCancel={() => {
          setDetail(null);
        }}
      />
    </>
  );
});

export default MessageLinkifier;
