import React from 'react';
import './subCategoriesForm.scss';
import { Form, Input, Button, Select } from 'antd';

const { Option } = Select;
const { TextArea } = Input;

class SubCategoriesForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderProductTypes = () => {
    const { productTypes } = this.props;
    return (
      productTypes &&
      productTypes.map(item => <Option key={item._id}>{item.name}</Option>)
    );
  };

  renderProductTypesSelect = () => {
    return (
      <Select
        mode="tags"
        placeholder="Product types"
        onChange={value => console.info(value)}
        style={{ width: '100%' }}
      >
        {this.renderProductTypes()}
      </Select>
    );
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const productTypeIds = values.productTypeIds;
        const name = values.name.trim();
        const status = values.status.trim() === 'Active' ? true : false;
        const description = values.description.trim();
        const categoryId = values.categoryId;
        const measureUnitId = values.unit;
        const data = {
          name,
          status,
          description,
          categoryId,
          measureUnitId,
          productTypeIds,
        };
        this.props.isCreate
          ? this.props.onCreateSubCategories(data)
          : this.props.onUpdateSubCategories(data);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isCreate, itemUpdate, isLoading, categories, units } = this.props;
    const categoriesRender = categories.map(item => (
      <Option key={item} value={item._id}>
        {item.name}
      </Option>
    ));
    const unitRender = units.map(item => (
      <Option key={item._id} value={item._id}>
        {item.name}
      </Option>
    ));
    if (isCreate) {
      return (
        <Form className="subCategories-form">
          <Form.Item label="Name">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Please input subCategories name',
                },
                {
                  whitespace: true,
                  message: 'Please do not input whitespace only',
                },
              ],
              initialValue: null,
            })(<Input placeholder="Name" size="large" />)}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator('description', {
              rules: [
                {
                  required: true,
                  message: 'Please input categories description',
                },
                {
                  whitespace: true,
                  message: 'Please do not input whitespace only',
                },
              ],
              initialValue: null,
            })(<TextArea placeholder="Description" rows={4} />)}
          </Form.Item>
          <Form.Item label="Categories" className="categories-item">
            {getFieldDecorator('categoryId', {
              rules: [
                {
                  required: true,
                  message: 'Please select categories',
                },
              ],
              initialValue: categories[0]._id,
            })(<Select>{categoriesRender}</Select>)}
          </Form.Item>
          <Form.Item label="Product Types" className="product-types-item">
            {getFieldDecorator('productTypeIds', {
              rules: [
                {
                  required: true,
                  message: 'Please select product types',
                },
              ],
            })(this.renderProductTypesSelect())}
          </Form.Item>
          <Form.Item label="Measure Unit" className="unit-item">
            {getFieldDecorator('unit', {
              rules: [
                {
                  required: true,
                  message: 'Please select unit',
                },
              ],
              initialValue: units[0]._id,
            })(<Select>{unitRender}</Select>)}
          </Form.Item>
          <Form.Item label="Status" className="status-item">
            {getFieldDecorator('status', {
              rules: [
                {
                  required: true,
                  message: 'Please select subCategories status',
                },
              ],
              initialValue: 'Active',
            })(
              <Select>
                <Option value="Active">Active</Option>
                <Option value="Inactive">Inactive</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              loading={isLoading}
              onClick={this.handleSubmit}
              className="subCategories-form-button btn-primary-0"
              size="large"
              disabled={isLoading}
            >
              Create
            </Button>
          </Form.Item>
        </Form>
      );
    }
    return (
      <Form className="subCategories-form">
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Please input subCategories name',
              },
              {
                whitespace: true,
                message: 'Please do not input whitespace only',
              },
            ],
            initialValue: itemUpdate.name,
          })(<Input placeholder="Name" size="large" />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator('description', {
            rules: [
              {
                required: true,
                message: 'Please input categories description',
              },
              {
                whitespace: true,
                message: 'Please do not input whitespace only',
              },
            ],
            initialValue: itemUpdate.description,
          })(<TextArea placeholder="Description" rows={4} />)}
        </Form.Item>
        <Form.Item label="Categories" className="categories-item">
          {getFieldDecorator('categoryId', {
            rules: [
              {
                required: true,
                message: 'Please select categories',
              },
            ],
            initialValue: itemUpdate.categoryId._id,
          })(<Select>{categoriesRender}</Select>)}
        </Form.Item>
        <Form.Item label="Product Types" className="product-types-item">
          {getFieldDecorator('productTypeIds', {
            rules: [
              {
                required: true,
                message: 'Please select product types',
              },
            ],
            initialValue: itemUpdate.productTypeIds,
          })(this.renderProductTypesSelect())}
        </Form.Item>
        <Form.Item label="Measure Unit" className="unit-item">
          {getFieldDecorator('unit', {
            rules: [
              {
                required: true,
                message: 'Please select unit',
              },
            ],
            initialValue: itemUpdate.measureUnitId._id,
          })(<Select>{unitRender}</Select>)}
        </Form.Item>
        <Form.Item label="Status" className="status-item">
          {getFieldDecorator('status', {
            rules: [
              {
                required: true,
                message: 'Please select subCategories status',
              },
            ],
            initialValue: itemUpdate.status ? 'Active' : 'Inactive',
          })(
            <Select>
              <Option value="Active">Active</Option>
              <Option value="Inactive">Inactive</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            loading={isLoading}
            onClick={this.handleSubmit}
            className="subCategories-form-button btn-primary-0"
            size="large"
            disabled={isLoading}
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedSubCategoriesForm = Form.create({ name: 'normal_subCategories' })(
  SubCategoriesForm
);

export default WrappedSubCategoriesForm;
