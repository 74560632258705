//import library
import {
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGIN_FAILED,
	LOGIN_RESET
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';

const defaultState = {
	data: null,
	error: null,
	success: false,
	isLoading: false
};

const authReducer = (state = defaultState, action) => {
	switch (action.type) {
		case LOGIN_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case LOGIN_SUCCESS: {
			return {
				...state,
				isLoading: false,
				data: action.payload.data,
				success: action.payload.success,
				error: null
			};
		}
		case LOGIN_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case LOGIN_RESET: {
			return {
				...state,
				error: null
			};
		}
		default:
			return state;
	}
};

export default authReducer;
