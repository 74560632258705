import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const getCustomerStoriesService = () => {
  const url = BaseConfig.END_POINT.ADMIN.CUSTOMER_STORIES;
  const response = httpService._get(url);
  return response;
};

export const deleteCustomerStoriesService = (id) => {
  const url = BaseConfig.END_POINT.ADMIN.CUSTOMER_STORIES;
  const response = httpService._delete(`${url}/${id}`);
  return response;
};

export const createCustomerStoriesService = (data) => {
  const url = BaseConfig.END_POINT.ADMIN.CUSTOMER_STORIES;
  const response = httpService._post(url, data);
  return response;
};

export const updateCustomerStoriesService = ({ data, id }) => {
  const url = BaseConfig.END_POINT.ADMIN.CUSTOMER_STORIES;
  const response = httpService._put(`${url}/${id}`, data);
  return response;
};

export const getCustomerStoriesById = (id) => {
  const url = BaseConfig.END_POINT.ADMIN.CUSTOMER_STORIES;
  const response = httpService._get(`${url}/${id}`);
  return response;
};
export const getCustomerStoriesByHightLight = (queryPrams) => {
  let query = '';
  Object.entries(queryPrams).forEach(([key, params]) => {
    query = `${key}=${params}`;
  });
  const url = BaseConfig.END_POINT.ADMIN.CUSTOMER_STORIES;
  const response = httpService._get(`${url}?${query}`);
  return response;
};
