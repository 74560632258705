import React, { memo, useEffect, useMemo, useState } from 'react';
import iJpg from 'src/assets/images/icons/extentions/jpg.svg';
import iSvg from 'src/assets/images/icons/extentions/svg.svg';
import iPng from 'src/assets/images/icons/extentions/png.svg';
import iXls from 'src/assets/images/icons/extentions/xls.svg';
import iXlsx from 'src/assets/images/icons/extentions/xlsx.svg';
import iDoc from 'src/assets/images/icons/extentions/doc.svg';
import iDocx from 'src/assets/images/icons/extentions/docx.svg';
import iPpt from 'src/assets/images/icons/extentions/ppt.svg';
import iPptx from 'src/assets/images/icons/extentions/pptx.svg';
import iPdf from 'src/assets/images/icons/extentions/pdf.svg';
import iTxt from 'src/assets/images/icons/extentions/txt.svg';
import iMp4 from 'src/assets/images/icons/extentions/mp4.svg';
import iMp3 from 'src/assets/images/icons/extentions/mp3.svg';
import iGif from 'src/assets/images/icons/extentions/gif.svg';
import iMov from 'src/assets/images/icons/extentions/mov.svg';
import iAvi from 'src/assets/images/icons/extentions/avi.svg';
import iFlv from 'src/assets/images/icons/extentions/flv.svg';
import iWmv from 'src/assets/images/icons/extentions/wmv.svg';
import iFiles from 'src/assets/images/icons/extentions/files.svg';
import iFolder from 'src/assets/images/icons/extentions/folder.svg';
import iLink from 'src/assets/images/icons/extentions/link.svg';
import './fileExtentions.scss';
import {
  getS3CDN,
  isVideoFile,
  getFileType,
  generateYoutubeThumbnail,
  checkYoutubeLink,
} from 'src/core/utils/commonUtils';
import { IMAGES_TYPE, VIDEO_TYPES } from 'src/core/constants/constants';

const FileExtentions = ({
  fileName = '',
  onclick,
  className = '',
  path = '',
  name = '',
}) => {
  const [showDefaultImage, setShowDefaultImage] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [hasErr, setError] = useState(false);

  const onCheckImageLoaded = async () => {
    setShowDefaultImage(true);
    if (name) setError(true);
  };

  useEffect(() => {
    if (path) {
      const checkYoutubeVideo = checkYoutubeLink(path);
      setIsVideo(isVideoFile({ filename: path }) || checkYoutubeVideo);
    }
  }, [path]);

  const getFileExtension = useMemo(() => {
    if (!fileName) return null;
    if (fileName === 'folder') {
      return iFolder;
    }

    if (fileName === 'link') {
      return generateYoutubeThumbnail(path) || iLink;
    }
    const extension = getFileType(path);
    const isImage = IMAGES_TYPE.includes(`.${extension}`);
    const isVideo = VIDEO_TYPES.includes(`.${extension}`);

    if (!showDefaultImage && (isImage || isVideo)) {
      return getS3CDN(fileName);
    }

    if (!isVideo && !isImage && name) {
      setError(true);
    }

    switch (extension) {
      case 'jpg':
      case 'jpeg':
        return iJpg;
      case 'svg':
        return iSvg;
      case 'png':
        return iPng;
      case 'xls':
        return iXls;
      case 'xlsx':
        return iXlsx;
      case 'doc':
        return iDoc;
      case 'docx':
        return iDocx;
      case 'ppt':
        return iPpt;
      case 'pptx':
        return iPptx;
      case 'pdf':
        return iPdf;
      case 'txt':
        return iTxt;
      case 'mp4':
        return iMp4;
      case 'mp3':
        return iMp3;
      case 'gif':
        return iGif;
      case 'mov':
        return iMov;
      case 'avi':
        return iAvi;
      case 'flv':
        return iFlv;
      case 'wmv':
        return iWmv;
      default:
        return iFiles;
    }
  }, [fileName, showDefaultImage, path, name]);

  return (
    <>
      {hasErr ? (
        <div
          className={`extention-icon-error ${onclick ? 'c-pointer' : ''}`}
          onClick={onclick}
        >
          <img src={getFileExtension} alt="icon" />
          <p className="ml-1 my-0">{name}</p>
        </div>
      ) : (
        <div
          className={`extention-icon ${onclick ? 'c-pointer' : ''} ${
            !hasErr && className
          } ${isVideo && !showDefaultImage ? 'video' : ''}`}
          onClick={onclick}
        >
          <img onError={onCheckImageLoaded} src={getFileExtension} alt="icon" />
        </div>
      )}
    </>
  );
};

export default memo(FileExtentions);
