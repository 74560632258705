import React, { PureComponent } from 'react';
import { shallowEqual } from 'react-redux';
import { Switch, Route, Link } from 'react-router-dom';
import { Button, Pagination, Drawer, Divider, Modal, Form, Input, Spin } from 'antd';
import { Jumbotron, Container } from 'react-bootstrap';
import moment from 'moment';
import debounce from 'lodash/debounce';
import queryString from 'query-string';
import { joinArr, isSuperAdmin } from 'src/core/utils/commonUtils';
import LeftArrowIcon from 'src/assets/images/icons/common/left-arrow.svg';
import DataTable from 'src/components/common/dragTable/dragTable';
import ProjectsDetail from 'src/components/common/form/projectsDetail/projectsDetail';
import ModalData from 'src/components/common/newModal/newModal';
import ProjectHeader from 'src/components/common/form/projectsDetail/projectHeader/projectHeader';
import ProjectTitle from 'src/components/common/form/projectsDetail/projectTitle/projectTitle';
import Workspace from 'src/components/common/form/workspace/workspace';
import ProjectActions from 'src/components/common/form/projectsDetail/projectActions/projectActions';
import ProjectCreateForm from 'src/components/common/form/projectCreateForm/projectCreateForm';
import ProjectInfoPreview from 'src/components/common/form/projectsDetail/projectInfoPreview/projectInfoPreview';
import QuoteForm from 'src/components/common/form/projectsDetail/quoteForm/quoteForm';
// import ProjectUrlHook from 'src/components/screens/admin/projects/projectUrlHook';
import confirmModal from 'src/components/common/confirmModal/confirmModal';
import UserAvatar from 'src/components/common/userAvatar/index.js';
import NotFound from '../../404/404';

import './projectsScreen.scss';

const { Search } = Input;

class ProjectsScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Admin Projects - VidOps',
      data: [],
      key: '',
      currentPage: 1,
      currentStatus: [],
      currentLimit: '',
      isCreate: false,
      visibleDrawer: false,
      modalStatus: false,
      quickNote: '',
      drawerSource: '',
      teamId: '',
      customerId: '',
      editorId: '',
      isExpanded: false,
      isActionsContainerDisplayed: true,
      quoteFiles: [],
      currentTopic: props.currentTopic,
      isSendingMessage: false,
      textSearchProject: '',
      tabIndex: '',
    };
  }

  preventBackClick = () => {
    this.setState({
      isSendingMessage: true,
    });
  };

  allowBackClick = () => {
    this.setState({
      isSendingMessage: false,
    });
    this.closeAllModal();
  };

  confirmModalHandler = () => {
    this.closeDrawer();
  };

  closeConfirmModalHandler = () => {
    this.closeAllModal();
  };

  closeAllModal = () => {
    Modal.destroyAll();
  };

  openConfirmModal = () => {
    confirmModal({
      onOk: this.confirmModalHandler,
      onCancel: this.closeConfirmModalHandler,
    });
  };

  toggleActionsContainer = status => {
    this.setState({
      isActionsContainerDisplayed: status,
    });
  };

  getProjectsByKey = (props = this.props) => {
    const { textSearchProject, currentLimit = 10, currentStatus } = this.state;
    let params;
    let type;
    let typeId;
    let status = undefined;
    let page = this.state.currentPage;
    if (props.location && props.location.search) {
      params = queryString.parse(props.location.search);
      type = Object.keys(params)[0];
      typeId = params[type];
      this.setState({
        [type]: typeId,
      });
      if (Object.keys(params).includes('status')) {
        this.setState({
          currentStatus: [params['status']],
          teamId: undefined,
        });
        status = [params['status']];
        if (
          (currentStatus &&
            currentStatus.length &&
            currentStatus[0] !== params['status']) ||
          !currentStatus.length
        ) {
          this.setState(
            {
              textSearchProject: '',
              currentPage: 1,
            },
            () => {
              props.onGetProjects({
                // page: currentPage,
                searchText: '',
                page: page,
                limit: currentLimit,
                status: status && status.length ? joinArr(status) : undefined,
                teamId: type === 'teamId' ? typeId : undefined,
                customerId: type === 'customerId' ? typeId : undefined,
                editorId: type === 'editorId' ? typeId : undefined,
                creativeId: type === 'creativesId' ? typeId : undefined,
              });
            }
          );
          page = 1;
        }
      }
    }

    if (textSearchProject === '') {
      props.onGetProjects({
        // page: currentPage,
        searchText: textSearchProject === '' ? undefined : textSearchProject,
        page: page,
        limit: currentLimit,
        status: status && status.length ? joinArr(status) : undefined,
        teamId: type === 'teamId' ? typeId : undefined,
        customerId: type === 'customerId' ? typeId : undefined,
        editorId: type === 'editorId' ? typeId : undefined,
        creativeId: type === 'creativesId' ? typeId : undefined,
      });
    }
  };

  componentDidMount() {
    this.props.onGetCurrentUser();
    this.getProjectsByKey();
    if (this.state.textSearchProject !== '') {
      this.setState({ textSearchProject: '' });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!shallowEqual(this.props.currentTopic, nextProps.currentTopic)) {
      this.setState({
        currentTopic: nextProps.currentTopic,
      });
    }

    if (!shallowEqual(this.props.location, nextProps.location)) {
      this.getProjectsByKey(nextProps);
    }
  }

  componentDidUpdate() {
    const { match } = this.props;
    if (match.isExact) {
      this.props.onClearProjectDetail();
    }
  }

  handleGetDetail = id => {
    const { detailData, detailError, detailLoading } = this.props;
    if (detailLoading) {
      return;
    }

    if (detailData && detailData._id === id) {
      return;
    }

    if (detailError && detailError.requestingId === id) {
      return;
    }

    this.props.onGetProjectsDetail(id);
  };

  handleChangeTable = (pagination, filters, sorter) => {
    const { teamId, customerId } = this.state;
    this.setState({
      currentStatus: filters.status,
    });
    this.props.onGetProjects({
      page: 1,
      limit: this.state.currentLimit,
      status:
        !!filters.status && filters.status.length ? joinArr(filters.status) : undefined,
      teamId: !!teamId ? teamId : undefined,
      customerId: !!customerId ? customerId : undefined,
    });
  };

  renderUsers = creatives => {
    if (creatives && creatives.length) {
      return (
        <div className="d-flex flex-wrap justify-content-around">
          {creatives.map((item, index) => {
            const { avt, fullName } = item.id && item.id.userId && item.id.userId;
            const status =
              !item.status ||
              (item.status && item.status !== 'ACCEPT') ||
              (item.id && item.id.status !== 'active');
            return (
              <UserAvatar
                fullName={fullName}
                key={index}
                avatar={avt}
                status={!status}
                size="small"
              />
            );
          })}
        </div>
      );
    }
  };

  formatColumns = () => {
    const { location } = this.props;
    const params = queryString.parse(location.search);
    let search = '';
    if (params.status) search = `?status=${params.status}`;
    if (params.teamId) search = `?teamId=${params.teamId}`;
    if (params.creativesId) search = `?creativesId=${params.creativesId}`;
    if (params.editorId) search = `?editorId=${params.editorId}`;
    return [
      {
        title: '',
        dataIndex: 'isUrgent',
        fixed: 'left',
        width: 9,
        render: isUrgent => {
          if (isUrgent) {
            return {
              props: {
                style: { background: '#FF1A1A' },
              },
            };
          }
          return {
            props: {
              style: { background: '#01FF02' },
            },
          };
        },
      },
      {
        title: <div style={{ fontWeight: 'bold', padding: '0' }}>Project ID</div>,
        fixed: 'left',
        width: 125,
        render: (dataIndex, record) => {
          const { _id: projectId, alias } = record;
          // const { location } = this.props;
          // const params = queryString.parse(location.search);
          // let search = '';
          // if (params.status) search = `?status=${params.status}`;
          // if (params.teamId) search = `?teamId=${params.teamId}`;
          // if (params.creativesId) search = `?creativesId=${params.creativesId}`;
          // if (params.editorId) search = `?editorId=${params.editorId}`;
          return {
            props: {
              style: { background: '#FFF9F4' },
            },
            children: (
              <Link
                className="project-id"
                to={{ pathname: '/projects/' + projectId, search }}
                // onClick={() => this.handleGetDetail(projectId)}
              >
                {alias || 'Add Project ID'}
              </Link>
            ),
          };
        },
      },
      {
        title: <div style={{ fontWeight: 'bold' }}>Company</div>,
        dataIndex: 'company',
        width: 170,
        fixed: 'left',
        render: (dataIndex, record) => {
          const get = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);

          const company = get(['customerId', 'userId', 'company'], record);

          return {
            props: {
              style: { background: '#FFF9F4' },
            },
            children: company || ' ',
          };
        },
      },
      {
        title: <div style={{ fontWeight: 'bold' }}>Project Title</div>,
        dataIndex: 'title',
        width: 200,
        fixed: 'left',
        render: (dataIndex, record) => {
          const { title } = record;
          return {
            props: {
              style: { background: '#FFF9F4' },
            },
            children: title,
          };
        },
      },
      // {
      //   title: <div style={{ fontWeight: 'bold' }}>Workspace</div>,
      //   dataIndex: 'workspace',
      //   fixed: 'left',
      //   width: 106,
      //   render: (text, record) => {
      //     return {
      //       props: {
      //         style: { background: '#FFF9F4' },
      //       },
      //       children: (
      //         <div className="operation-container">
      //           <Button
      //             onClick={() => this.props.history.push({
      //               pathname: '/projects/' + record._id + '/workspace',
      //               search
      //             })}
      //             type="primary"
      //             className="btn-primary-0"
      //           >
      //             Launch
      //           </Button>
      //         </div>
      //       ),
      //     };
      //   },
      // },
      // {
      //   title: 'Customer Name',
      //   dataIndex: 'customerId[userId][fullName]',
      //   width: 150,
      //   render: (dataIndex, record) => {
      //     const { customerId } = record;
      //     const { userId } = customerId;
      //     const { fullName } = userId || {};
      //     return {
      //       children: fullName,
      //     };
      //   },
      // },
      // {
      //   title: 'Team Name',
      //   dataIndex: 'teamId[name]',
      //   width: 200,
      // },
      {
        title: <div style={{ fontWeight: 'bold' }}>Production Date(s)</div>,
        dataIndex: 'productionDates',
        render: productionDates => {
          let productionDatesRender = null;
          if (productionDates && productionDates.length) {
            productionDatesRender = productionDates.map((item, index) => (
              <div className="production-date-item text-center" key={item._id}>
                <span className="item-date">
                  {moment(item.startDate).format('DD/MM/YYYY')}
                </span>
              </div>
            ));
          }
          return {
            props: {
              style: { textAlign: 'center' },
            },
            children: productionDatesRender,
          };
        },
      },

      {
        title: <div style={{ fontWeight: 'bold' }}>Production Type</div>,
        dataIndex: 'productTypeId[name]',
      },
      // {
      //   title: 'Creatives',
      //   dataIndex: 'creativeId',
      //   render: creativeId => this.renderUsers(creativeId),
      // },
      // {
      //   title: 'Editor',
      //   dataIndex: 'editorId',
      //   render: editorId => this.renderUsers(editorId),
      // },
      {
        title: <div style={{ fontWeight: 'bold' }}>Status</div>,
        dataIndex: 'status',
        render: status => {
          return <span>{status}</span>;
        },
        // filters: PROJECT_STATUS,
        // onFilter: (value, record) => {
        //   return record.status.indexOf(value) === 0;
        // },
      },
      // {
      //   title: 'Project Notes',
      //   dataIndex: 'quickNote',
      //   render: quickNote => {
      //     return (
      //       <div className="operation-container">
      //         <Button
      //           onClick={() => this.openQuickNote(quickNote)}
      //           type="primary"
      //           className="btn-primary-0"
      //         >
      //           Read
      //         </Button>
      //       </div>
      //     );
      //   },
      // },
    ];
  };

  workspaceOpenHandler = id => {
    const { detailData } = this.props;
    if (detailData && (!detailData._id || detailData._id === id)) {
      return;
    }
    this.props.onGetProjectsDetail(id);
  };

  openQuickNote = quickNote => {
    this.setState({
      modalStatus: true,
      quickNote,
    });
  };

  closeModalHandler = () => {
    this.setState({
      modalStatus: false,
    });
  };

  formatData = data => {
    if (data && data.length) {
      return data.map((item, index) => {
        return {
          ...item,
          key: item._id,
        };
      });
    }
  };

  onChangePage = page => {
    const { teamId, customerId, editorId, textSearchProject } = this.state;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        this.props.onGetProjects({
          page: this.state.currentPage,
          limit: this.state.currentLimit,
          status: joinArr(this.state.currentStatus),
          teamId: !!teamId ? teamId : undefined,
          customerId: !!customerId ? customerId : undefined,
          editorId: !!editorId ? editorId : undefined,
          searchText: textSearchProject,
        });
      }
    );
  };

  updateStatusHandler = data => {
    this.props.onUpdateStatus(data);
  };

  // componentDidUpdate(prevProps) {
  //   if (this.props.detailData && this.props.detailData !== prevProps.detailData) {
  //     this.openDrawer();
  //   }
  // }

  openDrawer = () => {
    this.setState({
      visibleDrawer: true,
    });
  };

  closeDrawer = () => {
    this.setState({
      visibleDrawer: false,
      currentTopic: undefined,
    });
    // if (drawerSource !== 'workspace') {
    // 	window.history.back();
    // }
  };

  closeRoutingDrawer = () => {
    const { search } = this.props.location;
    this.props.history.push({
      pathname: '/projects',
      search,
    });
    if (this.state.isExpanded) {
      this.handleProjectPanel();
    }
  };

  handleAddNew = () => {
    this.setState({
      drawerSource: 'addNewProject',
    });
    this.openDrawer();
  };

  openWorkSpaceHandler = files => {
    const { detailData } = this.props;
    if (!detailData) {
      return;
    }
    this.closeSendQuote();
    this.setState({
      quoteFiles: files,
    });
    const { search } = this.props.location;
    this.props.history.push({
      pathname: '/projects/' + detailData._id + '/workspace',
      search,
    });
  };

  openSendQuote = () => {
    this.setState({
      drawerSource: 'sendQuote',
    });
    this.openDrawer();
  };

  closeSendQuote = () => {
    this.setState({
      drawerSource: '',
      visibleDrawer: false,
    });
  };

  setQuoteFilesEmptyHandler = () => {
    this.setState({
      quoteFiles: [],
    });
  };

  renderAddNewProject = () => {
    const { history, location, match } = this.props;
    return (
      <ProjectCreateForm
        {...{ history, location, match }}
        onSetDrawerSource={this.setDrawerSourceHandler}
        onCloseDrawer={this.closeDrawer}
      />
    );
  };

  setTabIndex = tabIndex => {
    this.setState({
      tabIndex,
    });
  };

  renderProjectDetail = routeProps => {
    if (!routeProps || !routeProps.match) {
      return;
    }
    const { params } = routeProps.match;
    this.handleGetDetail(params.itemId);

    const {
      drawerSource,
      isActionsContainerDisplayed,
      isSendingMessage,
      quoteFiles,
      currentTopic,
    } = this.state;
    const {
      detailData,
      detailError,
      detailLoading,
      activities,
      isActivitiesLoading,
      currentUser,
      packages,
      creatives,
      editors,
      isLoading,
      isCreativesLoadingMore,
      isEditorsLoadingMore,
    } = this.props;
    const hasAssigned = this.checkAdminAssignee();
    const isFetching =
      detailLoading ||
      ((!detailData || (detailData._id && detailData._id !== params.itemId)) &&
        !detailError);
    if (isFetching) {
      return <Spin />;
    }

    const isNotfound = detailError || (detailData && !detailData._id);
    if (isNotfound) {
      return <NotFound embedded={true} />;
    }

    return (
      <>
        <ProjectHeader
          currentProject={detailData}
          onCloseDrawerClick={() => {
            if (isSendingMessage) {
              this.openConfirmModal();
            } else {
              this.closeRoutingDrawer();
            }
          }}
          drawerSource={drawerSource}
          hasAssigned={hasAssigned}
          tabIndex={this.state.tabIndex}
        />
        {detailData && (
          <ProjectTitle
            currentProject={detailData}
            drawerSource={drawerSource}
            hasAssigned={hasAssigned}
          />
        )}
        {isActionsContainerDisplayed && (
          <ProjectActions
            currentProject={detailData}
            hasAssigned={hasAssigned}
            currentUser={currentUser}
          />
        )}
        <ProjectsDetail
          currentUser={currentUser}
          currentProject={detailData}
          activities={activities}
          quoteFiles={quoteFiles}
          currentTopic={currentTopic}
          packages={packages}
          creatives={creatives}
          editors={editors}
          hasAssigned={hasAssigned}
          isActivitiesLoading={isActivitiesLoading}
          isCreativesLoadingMore={isCreativesLoadingMore}
          isEditorsLoadingMore={isEditorsLoadingMore}
          isLoading={isLoading}
          onGetActivities={this.props.onGetActivities}
          onLoadMoreActivities={this.props.onLoadMoreActivities}
          onUpdateProject={this.props.onUpdateProject}
          onGetCreatives={this.props.onGetCreatives}
          onLoadMoreCreatives={this.props.onLoadMoreCreatives}
          onGetEditors={this.props.onGetEditors}
          onLoadMoreEditors={this.props.onLoadMoreEditors}
          onGetDetailProject={this.handleGetDetail}
          onSetQuoteFilesEmpty={this.setQuoteFilesEmptyHandler}
          onSendQuote={this.openSendQuote}
          onToggleActionsContainer={this.toggleActionsContainer}
          onPreventBackClick={this.preventBackClick}
          onAllowBackClick={this.allowBackClick}
          refreshOnChangeStatus={this.refreshOnChangeStatus}
          getProjects={this.getProjectsByKey}
          onClose={this.closeRoutingDrawer}
          setTabIndex={this.setTabIndex}
        />
      </>
    );
  };

  renderWorkspace = routeProps => {
    if (!routeProps || !routeProps.match) {
      return;
    }
    const { params } = routeProps.match;
    this.workspaceOpenHandler(params.itemId);

    const {
      drawerSource,
      isActionsContainerDisplayed,
      quoteFiles,
      currentTopic,
      isSendingMessage,
    } = this.state;
    const {
      isLoading,
      detailData,
      packages,
      creatives,
      isCreativesLoadingMore,
      editors,
      isEditorsLoadingMore,
      activities,
      isActivitiesLoading,
      currentUser,
    } = this.props;
    const hasAssigned = this.checkAdminAssignee();
    const isFetching =
      !detailData || (detailData._id && detailData._id !== params.itemId);
    if (isFetching) {
      return <Spin />;
    }

    const isNotfound = detailData && !detailData._id;
    if (isNotfound) {
      return <NotFound embedded={true} />;
    }

    return (
      <>
        <ProjectHeader
          currentProject={detailData}
          onCloseDrawerClick={() => {
            if (isSendingMessage) {
              this.openConfirmModal();
            } else {
              this.closeRoutingDrawer();
            }
          }}
          drawerSource={drawerSource}
          hasAssigned={hasAssigned}
        />
        <ProjectTitle
          currentProject={detailData}
          drawerSource={drawerSource}
          hasAssigned={hasAssigned}
        />
        {isActionsContainerDisplayed && (
          <ProjectActions
            currentProject={detailData}
            hasAssigned={hasAssigned}
            currentUser={currentUser}
          />
        )}
        <Workspace
          currentProject={detailData}
          currentTopic={currentTopic}
          quoteFiles={quoteFiles}
          isCreativesLoadingMore={isCreativesLoadingMore}
          isEditorsLoadingMore={isEditorsLoadingMore}
          isLoading={isLoading}
          packages={packages}
          creatives={creatives}
          editors={editors}
          activities={activities}
          isActivitiesLoading={isActivitiesLoading}
          onUpdateProjectCall={this.props.onUpdateProject}
          onGetCreatives={this.props.onGetCreatives}
          onLoadMoreCreatives={this.props.onLoadMoreCreatives}
          onGetEditors={this.props.onGetEditors}
          onLoadMoreEditors={this.props.onLoadMoreEditors}
          onGetActivities={this.props.onGetActivities}
          onLoadMoreActivities={this.props.onLoadMoreActivities}
          onCloseDrawerClick={this.closeRoutingDrawer}
          onGetDetailProject={this.handleGetDetail}
          onToggleActionsContainer={this.toggleActionsContainer}
          onSetQuoteFilesEmpty={this.setQuoteFilesEmptyHandler}
          hasAssigned={hasAssigned}
          currentUser={currentUser}
          onPreventBackClick={this.preventBackClick}
          onAllowBackClick={this.allowBackClick}
        />
      </>
    );
  };

  renderSendQuote = () => {
    const { detailData } = this.props;
    return (
      <div className="send-quote-screen">
        <img
          className="back-icon"
          src={LeftArrowIcon}
          alt="back-icon"
          onClick={() => this.closeSendQuote()}
        />
        <h1 className="heading">Send a Quote</h1>
        <Divider className="divider" />
        <ProjectInfoPreview currentProject={detailData} />
        <QuoteForm
          currentProject={detailData}
          onOpenWorkSpace={this.openWorkSpaceHandler}
          onSetDrawerSource={this.closeSendQuote}
        />
      </div>
    );
  };

  checkAdminAssignee = () => {
    const { detailData, currentUser } = this.props;
    const currentUserId = currentUser && currentUser.adminId && currentUser.adminId._id;
    const admins = (detailData && detailData.admins) || [];

    if (isSuperAdmin(currentUser)) return true;
    return admins.length && admins.findIndex(item => item._id === currentUserId) > -1
      ? true
      : false;
  };

  refreshOnChangeStatus = () => {
    const { teamId, customerId, editorId } = this.state;
    this.props.onGetProjects({
      page: this.state.currentPage,
      limit: this.state.currentLimit,
      status: joinArr(this.state.currentStatus),
      teamId: !!teamId ? teamId : undefined,
      customerId: !!customerId ? customerId : undefined,
      editorId: !!editorId ? editorId : undefined,
    });
  };

  renderContentDrawer = () => {
    const { drawerSource } = this.state;

    switch (drawerSource) {
      case 'addNewProject':
        return this.renderAddNewProject();
      // case 'projectInfo':
      //   return this.renderProjectDetail(routeProps);
      // case 'workspace':
      //   return this.renderWorkspace(routeProps);
      case 'sendQuote':
        return this.renderSendQuote();
      default:
        return null;
    }
  };

  setDrawerSourceHandler = drawerSource => {
    this.setState({
      drawerSource,
    });
  };

  handleSearchProject = debounce(async text => {
    const textSearchProject = text.trim();
    this.props.onGetProjects({
      page: 1,
      searchText: textSearchProject,
      limit: this.state.currentLimit,
      status: joinArr(this.state.currentStatus),
    });
  }, 400);

  handleProjectPanel = () => {
    const { isExpanded } = this.state;
    this.setState({
      isExpanded: !isExpanded,
    });
  };

  render() {
    const { data, currentUser } = this.props;
    const {
      visibleDrawer,
      modalStatus,
      quickNote,
      currentPage,
      textSearchProject,
      isExpanded,
    } = this.state;
    let dataSource = [];
    const columns = this.formatColumns();
    if (data && data.list) {
      dataSource = this.formatData(data.list);
    }

    return (
      <>
        {/* <ProjectUrlHook
          onOpenProject={() => {
            this.setDrawerSourceHandler('projectInfo');
            // if (!isProjectDeleted) {
            //   this.openDrawer();
            // }
          }}
          onOpenTopic={() => {
            this.setDrawerSourceHandler('workspace');
          }}
          currentPage={currentPage}
        /> */}
        <main role="main" className="page-content projects-screen">
          <Jumbotron className="mgt-40">
            <h2 className="sub-heading">All Projects</h2>
            <p className="description">Manage all projects</p>
            <div className="divider"></div>
            <Container>
              {isSuperAdmin(currentUser) && (
                <Button
                  className="btn-primary-0"
                  onClick={this.handleAddNew}
                  type="primary"
                  style={{ marginBottom: 16 }}
                  disabled={!isSuperAdmin(currentUser)}
                >
                  Add new
                </Button>
              )}
              <div className="control-container">
                <Form>
                  <Form.Item>
                    <Search
                      placeholder="search..."
                      allowClear
                      value={textSearchProject}
                      onChange={e => this.setState({ textSearchProject: e.target.value })}
                      onSearch={this.handleSearchProject}
                      style={{ width: 300, height: 42 }}
                    />
                  </Form.Item>
                </Form>
                {!!data.total && (
                  <Pagination
                    className="project-pagination"
                    total={data.total}
                    showTotal={(total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`
                    }
                    pageSize={10}
                    simple={false}
                    onChange={this.onChangePage}
                    current={currentPage}
                  />
                )}
              </div>
              <DataTable
                columns={columns}
                dataSource={dataSource}
                scrollX={{ x: 1200 }}
                onChangeHandler={this.handleChangeTable}
                hiddenUpdateOrder={true}
              />
              <ModalData
                className="quick-note-modal"
                title="Project Notes"
                visible={modalStatus}
                onCloseModal={this.closeModalHandler}
                dataSource={quickNote}
                footer={null}
              />
              <Switch>
                <Route
                  path={'/projects/:itemId'}
                  render={routeProps => (
                    <Drawer
                      className="drawer-detail-project"
                      width={isExpanded ? '90%' : 790}
                      zIndex={999}
                      placement="right"
                      closable={false}
                      onClose={this.closeRoutingDrawer}
                      visible={true}
                      destroyOnClose={true}
                    >
                      {/* {this.renderContentDrawer(routeProps, 'projectInfo')} */}
                      {this.renderProjectDetail(routeProps)}
                      <Button
                        type={isExpanded ? 'outline' : 'primary'}
                        shape="circle"
                        icon={isExpanded ? 'double-right' : 'double-left'}
                        className="btn-expandable"
                        onClick={this.handleProjectPanel}
                      />
                    </Drawer>
                  )}
                />
              </Switch>
              <Drawer
                className="drawer-detail-project"
                width={790}
                placement="right"
                closable={false}
                onClose={this.closeDrawer}
                visible={visibleDrawer}
                destroyOnClose={true}
              >
                {this.renderContentDrawer()}
              </Drawer>
            </Container>
          </Jumbotron>
        </main>
      </>
    );
  }
}

export default ProjectsScreen;
