import { connect } from 'react-redux';
import SubCategoriesScreen from '../../../components/screens/admin/subCategories/subCategoriesScreen';
import {
	getSubCategories,
	createSubCategories,
	updateSubCategories,
	updateOrder
} from '../../../redux/actions/admin/subCategoriesAction';
import { getCategories } from '../../../redux/actions/admin/categoriesAction';
import { getUnits } from '../../../redux/actions/admin/unitsAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';
import { getProductTypes } from '../../../redux/actions/admin/productTypesAction';

const mapStateToProps = state => {
	return {
		success: state.subCategories.success,
		error: state.subCategories.error,
		isLoading: state.subCategories.isLoading,
		data: state.subCategories.data,
		categories: state.categories.data,
		units: state.units.data,
		productTypes: state.productType.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetSubCategories: () => {
			dispatch(getSubCategories());
		},
		onCreateSubCategories: data => {
			dispatch(createSubCategories(data));
		},
		onUpdateSubCategories: ({ data, id }) => {
			dispatch(updateSubCategories({ data, id }));
		},
		onUpdateOrder: data => {
			dispatch(updateOrder(data));
		},
		onGetCategories: () => {
			dispatch(getCategories());
		},
		onGetUnits: () => {
			dispatch(getUnits());
		},
		onGetCurrentUser: () => {
			dispatch(getCurrentUser());
		},
		onGetProductTypes: () => {
			dispatch(getProductTypes());
		}
	};
};

const CustomerStoriesContainer = connect(mapStateToProps, mapDispatchToProps)(SubCategoriesScreen);

export default CustomerStoriesContainer;
