import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const updateOrderService = data => {
	const orderData = {
		key: data.key,
		data: data.ids
	};
	const url = BaseConfig.END_POINT.ADMIN.ORDER;
	const response = httpService._put(`${url}`, orderData);
	return response;
};
