import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const getMessages = async data => {
  const url = BaseConfig.END_POINT.ADMIN.MESSAGE;
  const qs = await import('src/core/utils/querystring');
  const queryString = qs.stringify(JSON.parse(JSON.stringify(data)));
  return httpService._get(`${url}?${queryString}`);
};

export const sendMessage = data => {
  const url = BaseConfig.END_POINT.ADMIN.MESSAGE;
  const objData = {
    content: data.content,
    topicId: data.topicId,
    typeMessage: data.type,
    fileName: data.fileName
  };
  const response = httpService._post(url, objData);
  return response;
};

export const getPresignedMessageFileUrl = async ({ topicId, data }) => {
  const url = BaseConfig.END_POINT.ADMIN.MESSAGE_FILE_PRESIGNED;
  const payload = {
    data,
    topicId,
  };

  return await httpService._post(url, payload);
};

export const uploadMessage = async ({
  fileSize,
  fileName,
  topicId,
  filePath,
  isPublic,
  isSendQuote = false,
}) => {
  const url = BaseConfig.END_POINT.ADMIN.MESSAGE_FILE_UPLOAD;
  const payload = {
    data: { fileSize, fileName, filePath, isPublic, isSendQuote },
    topicId,
  };

  return await httpService._post(url, payload);
};

export const sendQuoteEmail = async ({ quoteFilePaths, topicId }) => {
  const url = BaseConfig.END_POINT.ADMIN.MESSAGE_SEND_QUOTE_FILE;
  const payload = { topicId, quoteFilePaths };

  return await httpService._post(url, payload);
};

export const sendFile = data => {
  const url = BaseConfig.END_POINT.ADMIN.UPLOAD_FILE;
  const objData = {
    data: data.dataFiles,
    topicId: data.topicId,
  };
  const response = httpService._post(url, objData);
  return response;
};
