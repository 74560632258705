import queryString from 'query-string';
import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const getTeamsService = params => {
  const url = BaseConfig.END_POINT.ADMIN.TEAMS;
  const response = httpService._get(
    `${url}?page=${params.page}&limit=${params.limit}${
      params.searchText ? `&search=${params.searchText}` : ''
    }`
  );
  return response;
};

export const getCustomerTeamWork = async ({ customerId }) => {
  const url = BaseConfig.END_POINT.ADMIN.CUSTOMER_TEAMWORK;
  const response = await httpService._get(`${url}/${customerId}`);

  return response;
};

export const getInvitationsService = params => {
  const queryParams = queryString.stringify(params);
  const url = BaseConfig.END_POINT.ADMIN.INVITATIONS;
  const response = httpService._get(`${url}?${queryParams}`);
  return response;
};

export const deleteInvitationService = id => {
  const url = BaseConfig.END_POINT.ADMIN.DELETE_INVITATION;
  const response = httpService._put(url, id);
  return response;
};

export const getTeamDetailService = id => {
  const url = BaseConfig.END_POINT.ADMIN.TEAM;
  const response = httpService._get(`${url}/${id}`);
  return response;
};
