import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { Card, Button } from 'antd';
import { VIDOPS_LOCATION } from 'src/core/constants/constants';
import FlagIcon from 'src/assets/images/icons/common/maps-and-flags.svg';
import OnsiteContactInformation from '../../projectsDetail/onsiteContactInformation/onsiteContactInformation';
import UserAvatar from 'src/components/common/userAvatar';

const lat = VIDOPS_LOCATION.lat, lng = VIDOPS_LOCATION.lng;
const AnyReactComponent = () => (
  <div>
    <img alt="pin" style={{ width: '20px' }} src={FlagIcon} />
  </div>
);

const tabList = [
  {
    key: 'datetime',
    tab: 'Date & time',
  },
  {
    key: 'address',
    tab: 'Address',
  },
  {
    key: 'contact',
    tab: 'On-site contact',
  },
];

const tabContent = {
  /** @param {import('.').ProductionDate} detail */
  datetime: (detail, renderCreative) => (<>
    <div className="production-date-item">
      <p>
        <strong>Date:</strong>&nbsp;
        <span className="start-date">
          {moment(detail.startDate).format('MMM DD, YYYY')}
        </span>
      </p>
      <p>
        <strong>Duration:</strong>&nbsp;
        <span className="duration">
          {moment(detail.startDate).format('h:mm A')} -{' '}
          {moment(detail.startDate).add(detail.duration, 'h').format('h:mm A')}
        </span>
      </p>
    </div>
    <div className='production-date-item'>
      <strong>Creatives assignment:</strong>&nbsp;
      {(!detail.creatives || !detail.creatives.length) && <span>{'No creatives'}</span>}
      {detail.creatives && !!detail.creatives.length && detail.creatives.map(renderCreative)}
    </div>
  </>),
  /** @param {import('.').ProductionDate} detail */
  address: (detail) => (<>
    <p>
      <strong>Location:</strong>&nbsp;
      <span>{(detail.address && detail.address.label) || 'No locations'}</span>
    </p>
    <div className="map-wrapper">
      <GoogleMapReact
        bootstrapURLKeys={{
          lang: 'en',
        }}
        center={{ lat, lng }}
        zoom={13}
      >
        <AnyReactComponent
          lat={lat}
          lng={lng}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
  </>),
  contact: (detail) => (<OnsiteContactInformation formData={detail.contact} disabled={true} />)
};

/**
 * @param {{
 *   onEdit?: Function
 *   detail: import('.').ProductionDate
 *   order: number
 * }} props
 */
export default function ProductionDate(props) {
  const { onEdit, detail, order } = props;
  const [tab, setTab] = useState(tabList[0].key);
  const creativesData = useSelector(state => state.creatives.data);

  const renderCreative = (id) => {
    if (!creativesData || !creativesData.list || !creativesData.list.length) {
      return null;
    }

    const creative = creativesData.list.find(item => item._id === id);
    if (!creative) {
      return null;
    }

    return <UserAvatar key={creative._id} status={false} avatar={creative.avt} fullName={creative.fullName} size="small" />;
  };

  return (
    <Card
      className='production-date'
      title={'Shooting date #' + order}
      size='small'
      hoverable={true}
      tabList={tabList}
      activeTabKey={tab}
      onTabChange={key => setTab(key)}
      extra={<Button type="link" onClick={onEdit}>Edit</Button>}
    >
      {tabContent[tab](detail, renderCreative)}
    </Card>
  );
};
