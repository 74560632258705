import { connect } from 'react-redux';
import ItemsScreen from '../../../components/screens/admin/items/itemsScreen';
import {
	getItems,
	createItems,
	updateItems,
	updateOrder
} from '../../../redux/actions/admin/itemsAction';
import { getPackage } from '../../../redux/actions/admin/packageAction';
import { getUnits } from '../../../redux/actions/admin/unitsAction';
import { getProductTypes, getProductTypesById } from '../../../redux/actions/admin/productTypesAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';

const mapStateToProps = state => {
	return {
		success: state.items.success,
		error: state.items.error,
		isLoading: state.items.isLoading,
		data: state.items.data,
		packages: state.packages.data,
		units: state.units.data,
		productTypes: state.productType.data,
		productTypesDetail: state.productType.detailData
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetItems: () => {
			dispatch(getItems());
		},
		onCreateItems: data => {
			dispatch(createItems(data));
		},
		onUpdateItems: ({ data, id }) => {
			dispatch(updateItems({ data, id }));
		},
		onUpdateOrder: data => {
			dispatch(updateOrder(data));
		},
		onGetPackages: () => {
			dispatch(getPackage());
		},
		onGetUnits: () => {
			dispatch(getUnits());
		},
		onGetProductTypes: () => {
			dispatch(getProductTypes());
		},
		onGetDetailService: id => {
			dispatch(getProductTypesById(id));
		},
		onGetCurrentUser: () => {
			dispatch(getCurrentUser());
		}
	};
};

const CustomerStoriesContainer = connect(mapStateToProps, mapDispatchToProps)(ItemsScreen);

export default CustomerStoriesContainer;
