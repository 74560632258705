/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip, Switch, Modal } from 'antd';

import Info from '../../../../assets/images/icons/icons8-info.svg';

import {
  getCurrentUser,
  updateUserInfo,
} from '../../../../redux/actions/admin/userAction';
import GoogleDriveAuth from 'src/components/common/googleDriveAuth';
import FrameIOAuth from 'src/components/common/frameIOAuth';
import AsanaAuth from 'src/components/common/asanaAuth';
import HubspotAuth from 'src/components/common/hubspotAuth';

import './style.scss';
import GoogleCalendarAuth from 'src/components/common/googleCalendar';

const { confirm } = Modal;

const NOTI_CUSTOMER = 'Turn on to receive the notification when a new customer signs up.';
const NOTI_CREATIVE = 'Turn on to receive the notification when a new creative signs up.';
const NOTI_PRJ =
  'Turn on to receive the notification when a new online booking is created.';

const SettingScreen = React.memo(() => {
  const admin = useSelector(state => state.user.data);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  const contentPrj = (
    <div>
      <p>{NOTI_PRJ}</p>
    </div>
  );
  const contentCus = (
    <div>
      <p>{NOTI_CUSTOMER}</p>
    </div>
  );
  const contentCreative = (
    <div>
      <p>{NOTI_CREATIVE}</p>
    </div>
  );

  const confirmAndUpdateNoti = (isTurnOn, notiType) => {
    if (!isTurnOn) {
      confirm({
        title: 'Turn off notification',
        content: 'Are you sure want to turn off this notification ?',
        okText: 'No',
        cancelText: 'Yes',
        className: 'confirm-turn-off',
        onCancel: async () => {
          return dispatch(updateUserInfo({ adminReceiveNoti: { [notiType]: isTurnOn } }));
        },
        onOk: () => {},
      });
    } else {
      return dispatch(updateUserInfo({ adminReceiveNoti: { [notiType]: isTurnOn } }));
    }
  };

  const onChangePrjNoti = e => confirmAndUpdateNoti(e, 'isOnlineBooking');
  const onChangeCustomerNoti = e => confirmAndUpdateNoti(e, 'isCustomerSignUp');
  const onChangeCreativeNoti = e => confirmAndUpdateNoti(e, 'isCreativeSignUp');

  const checkedProject = (admin && admin.adminReceiveNoti.isOnlineBooking) || false;
  const checkedCustomer = (admin && admin.adminReceiveNoti.isCustomerSignUp) || false;
  const checkedCreative = (admin && admin.adminReceiveNoti.isCreativeSignUp) || false;

  return (
    <div className="admin-setting">
      <div className="noti-setting">
        <h4>Notifications</h4>
        <div className="noti-options d-flex flex-column justify-content-between">
          <div className="noti-option">
            <strong className="noti-type">
              <span className="mr-1">Online Booking</span>
              <Tooltip title={contentPrj}>
                <span>
                  <img src={Info} alt="noti project description" />
                </span>
              </Tooltip>
            </strong>
            <Switch
              className="custom-switch"
              onChange={onChangePrjNoti}
              checked={checkedProject}
            />
          </div>
          <div className="noti-option">
            <strong className="noti-type">
              <span className="mr-1">Customer Sign Up</span>
              <Tooltip title={contentCus}>
                <span>
                  <img src={Info} alt="noti customer description" />
                </span>
              </Tooltip>
            </strong>
            <Switch
              className="custom-switch"
              onChange={onChangeCustomerNoti}
              checked={checkedCustomer}
            />
          </div>
          <div className="noti-option">
            <strong className="noti-type">
              <span className="mr-1">Creative Sign Up</span>
              <Tooltip title={contentCreative}>
                <span>
                  <img src={Info} alt="noti creative description" />
                </span>
              </Tooltip>
            </strong>
            <Switch
              className="custom-switch"
              onChange={onChangeCreativeNoti}
              checked={checkedCreative}
            />
          </div>
        </div>
      </div>
      <div className="noti-setting mt-5">
        <h4 className="mb-2">Google Drive</h4>
        <div>
          Login into your Google Account here and you can access your Google Drive to
          upload files to the Workspace and Assets.
        </div>
        <GoogleDriveAuth />
      </div>

      <div className="noti-setting mt-5">
        <h4 className="mb-2">Frame IO</h4>
        <div>
          Login into your Frame IO Account here and you can access your Frame IO to access
          to the Workspace and Assets.
        </div>
        <FrameIOAuth />
      </div>

      {/* <div className="noti-setting mt-5">
        <h4 className="mb-2">Google Calendar</h4>
        <div>Login into your Google Account here and you can create your events.</div>
        <GoogleCalendarAuth />
      </div> */}

      <div className="noti-setting mt-5">
        <h4 className="mb-2">Asana</h4>
        <div>
          Login into your Asana Account here and you can access your Asana to access to
          the Workspace and Assets.
        </div>
        <AsanaAuth />
      </div>

      <div className="noti-setting mt-5">
        <h4 className="mb-2">Hubspot</h4>
        <div>
          Login into your Hubspot Account here and you can access your Hubspot to access
          to the Workspace and Assets.
        </div>
        <HubspotAuth />
      </div>
    </div>
  );
});

export default withTranslation('common')(SettingScreen);
