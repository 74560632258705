import {
	GET_UNITS_REQUEST,
	GET_UNITS_SUCCESS,
	GET_UNITS_FAILED,
	CREATE_UNITS_REQUEST,
	CREATE_UNITS_SUCCESS,
	CREATE_UNITS_FAILED,
	UPDATE_UNITS_REQUEST,
	UPDATE_UNITS_SUCCESS,
	UPDATE_UNITS_FAILED,
	UPDATE_ORDER_UNITS_SUCCESS,
	UPDATE_ORDER_UNITS_FAILED
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';
import { updateHelper, compare } from '../../../core/utils/commonUtils';

const defaultState = {
	success: false,
	error: null,
	data: [],
	isLoading: false
};

const unitsReducer = (state = defaultState, action) => {
	switch (action.type) {
		case GET_UNITS_REQUEST: {
			return {
				...state
			};
		}
		case GET_UNITS_SUCCESS: {
			return {
				...state,
				...action.payload
			};
		}
		case GET_UNITS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				error: action.payload
			};
		}
		case CREATE_UNITS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case CREATE_UNITS_SUCCESS: {
			openNotification('success', 'Create successfully');
			return {
				...state,
				isLoading: false,
				data: [action.payload.data, ...state.data].sort(compare)
			};
		}
		case CREATE_UNITS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_UNITS_REQUEST: {
			return {
				...state,
				isLoading: true
			};
		}
		case UPDATE_UNITS_SUCCESS: {
			openNotification('success', 'Update successful');
			return {
				...state,
				isLoading: false,
				data: updateHelper(state, action)
			};
		}
		case UPDATE_UNITS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		case UPDATE_ORDER_UNITS_SUCCESS: {
			return {
				...state,
				isLoading: false,
				data: action.payload.data
			};
		}
		case UPDATE_ORDER_UNITS_FAILED: {
			openNotification('error', action.payload.message);
			return {
				...state,
				isLoading: false,
				error: action.payload
			};
		}
		default:
			return state;
	}
};

export default unitsReducer;
