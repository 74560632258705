import queryString from 'query-string';
import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const getFilesAndLinksService = params => {
	const queryParams = queryString.stringify(params);
	const url = BaseConfig.END_POINT.ADMIN.RESOURCES;
	const response = httpService._get(
		`${url}?${queryParams}`
	);
	return response;
};

export const updateFilesAndLinksService = ({ id, ...rest }) => {
	const url = BaseConfig.END_POINT.ADMIN.RESOURCE;
	const response = httpService._put(`${url}/${id}`, rest);
	return response;
};

export const moveFilesToAssetsService = payload => {
	const url = BaseConfig.END_POINT.ADMIN.FILES_TO_ASSETS;
	const response = httpService._post(`${url}`, payload);
	return response;
};
