import has from 'lodash/has';
import { put, call, takeLatest } from 'redux-saga/effects';

import {
  GET_CUSTOMERS_FAILED,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_DETAIL_FAILED,
  GET_CUSTOMERS_DETAIL_SUCCESS,
  SEARCH_CUSTOMERS_FAILED,
  SEARCH_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_DETAIL_REQUEST,
  SEARCH_CUSTOMERS_REQUEST,
  UPDATE_STATUS_CUSTOMERS_FAILED,
  UPDATE_STATUS_CUSTOMERS_SUCCESS,
  UPDATE_STATUS_CUSTOMERS_REQUEST,
  UPDATE_CUSTOMERS_REQUEST,
  UPDATE_CUSTOMERS_FAILED,
  UPDATE_CUSTOMERS_SUCCESS,
  TRANSFER_ADMIN_REQUEST,
  TRANSFER_ADMIN_FAILED,
  TRANSFER_ADMIN_SUCCESS,
  LOAD_MORE_CUSTOMERS_REQUEST,
  LOAD_MORE_CUSTOMERS_FAILED,
  LOAD_MORE_CUSTOMERS_SUCCESS,
  REGISTER_CUSTOMER_FAILED,
  REGISTER_CUSTOMER_SUCCESS,
  REGISTER_CUSTOMER_REQUEST,
  RESEND_INVITATION_TEAM_SUCCESS,
  RESEND_INVITATION_TEAM_FAILED,
  RESEND_INVITATION_TEAM_REQUEST,
  SEND_INVITATION_TEAM_SUCCESS,
  SEND_INVITATION_TEAM_FAILED,
  SEND_INVITATION_TEAM_REQUEST,
} from '../../actions/typeAction';
import {
  getCustomersService,
  getCustomersDetailService,
  searchCustomersService,
  updateStatusService,
  updateCustomersService,
  transferAdminService,
  customerRegisterService,
  inviteJoinTeamService,
  resendInviteJoinTeamService,
} from '../../../core/services/modules/customersService';

export function* getCustomersSaga(action) {
  const response = yield call(getCustomersService, action.payload);
  if (response.data.error) {
    yield put({
      type: GET_CUSTOMERS_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: GET_CUSTOMERS_SUCCESS,
      payload: data,
    });
  }
}

export function* loadMoreCustomersSaga(action) {
  const response = yield call(getCustomersService, action.payload);
  if (response.data.error) {
    yield put({
      type: LOAD_MORE_CUSTOMERS_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: LOAD_MORE_CUSTOMERS_SUCCESS,
      payload: data,
    });
  }
}

export function* getCustomersDetailSaga(action) {
  const response = yield call(getCustomersDetailService, action.payload);
  if (response.data.error || !has(response, 'data.data._id')) {
    yield put({
      type: GET_CUSTOMERS_DETAIL_FAILED,
      payload: response.data.error || {},
    });
  } else {
    const data = response.data;
    yield put({
      type: GET_CUSTOMERS_DETAIL_SUCCESS,
      payload: data,
    });
  }
}

export function* searchCustomersSaga(action) {
  const response = yield call(searchCustomersService, action.payload);
  if (response.data.error) {
    yield put({
      type: SEARCH_CUSTOMERS_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: SEARCH_CUSTOMERS_SUCCESS,
      payload: data,
    });
  }
}

export function* transferAdminSaga(action) {
  const response = yield call(transferAdminService, action.payload);
  if (response.data.error) {
    yield put({
      type: TRANSFER_ADMIN_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: TRANSFER_ADMIN_SUCCESS,
      payload: data,
    });
  }
}

export function* updateStatusSaga(action) {
  const response = yield call(updateStatusService, action.payload);
  if (response.data.error) {
    yield put({
      type: UPDATE_STATUS_CUSTOMERS_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    yield put({
      type: UPDATE_STATUS_CUSTOMERS_SUCCESS,
      payload: data,
    });
  }
}

export function* updateProjectSaga(action) {
  const response = yield call(updateCustomersService, action.payload);
  if (response.data.error) {
    yield put({
      type: UPDATE_CUSTOMERS_FAILED,
      payload: response.data.error,
    });
  } else {
    yield put({
      type: UPDATE_CUSTOMERS_SUCCESS,
      payload: response.data,
    });
  }
}

export function* customerRegisterSaga(action) {
  const response = yield call(customerRegisterService, action.payload);

  if (response.data.error) {
    yield put({
      type: REGISTER_CUSTOMER_FAILED,
      payload: response.data.error,
    });
  } else {
    yield put({
      type: REGISTER_CUSTOMER_SUCCESS,
      payload: response.data,
    });
  }
}

export function* adminInviteJoinTeamSaga(action) {
  const response = yield call(inviteJoinTeamService, action.payload);

  if (response.data.error) {
    yield put({
      type: SEND_INVITATION_TEAM_FAILED,
      payload: response.data.error,
    });
  } else {
    yield put({
      type: SEND_INVITATION_TEAM_SUCCESS,
      payload: response.data,
    });
  }
}

export function* resendInviteTeamSaga(action) {
  const response = yield call(resendInviteJoinTeamService, action.payload);
  if (response.data.error) {
    yield put({
      type: RESEND_INVITATION_TEAM_FAILED,
      payload: response.data.error,
    });
  } else {
    yield put({
      type: RESEND_INVITATION_TEAM_SUCCESS,
      payload: response.data,
    });
  }
}

export function* watchCustomers() {
  yield takeLatest(GET_CUSTOMERS_REQUEST, getCustomersSaga);
  yield takeLatest(LOAD_MORE_CUSTOMERS_REQUEST, loadMoreCustomersSaga);
  yield takeLatest(GET_CUSTOMERS_DETAIL_REQUEST, getCustomersDetailSaga);
  yield takeLatest(TRANSFER_ADMIN_REQUEST, transferAdminSaga);
  yield takeLatest(SEARCH_CUSTOMERS_REQUEST, searchCustomersSaga);
  yield takeLatest(UPDATE_CUSTOMERS_REQUEST, updateProjectSaga);
  yield takeLatest(UPDATE_STATUS_CUSTOMERS_REQUEST, updateStatusSaga);
  yield takeLatest(REGISTER_CUSTOMER_REQUEST, customerRegisterSaga);
  yield takeLatest(RESEND_INVITATION_TEAM_REQUEST, resendInviteTeamSaga);
  yield takeLatest(SEND_INVITATION_TEAM_REQUEST, adminInviteJoinTeamSaga);
}
