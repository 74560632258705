import React from 'react';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import './filesAndLinksForm.scss';
import { Form, Menu, Dropdown, Table, Icon, Spin, message, Pagination } from 'antd';
import {
  encodeURIThumbnail,
  formatBytes,
  isDeactiveUser,
  isVideoStreamFile,
  isFileHasThumbnail,
  getIdLinkDrive,
  getThumbnailLinkDrive,
  checkDriveLink
} from '../../../../../../core/utils/commonUtils';
import {
  getFilesAndLinks,
  updateFile,
  moveToAssets,
} from '../../../../../../redux/actions/admin/filesAndLinksAction';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import UserAvatar from 'src/components/common/userAvatar';
import FileExtentions from 'src/components/common/fileExtentions';
import FileDetail from 'src/components/common/fileDetail';
import { getS3CDN, isUrl } from 'src/core/utils/commonUtils';
import { isYoutubeLink } from 'src/core/utils/customValidator.js';
import IconImageThumbnail from 'src/assets/images/google-login/icons8-google-drive-thumbnail.svg';
class FilesAndLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      currentPage: 1,
      currentLimit: '',
      recordDetail: null,
    };
  }

  privateMenu = item => {
    return (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() =>
            this.props.onUpdateFile({
              id: item._id,
              isPublic: false,
            })
          }
        >
          <Icon type="lock" /> Private
        </Menu.Item>
      </Menu>
    );
  };

  publicMenu = item => {
    return (
      <Menu>
        <Menu.Item
          key="0"
          onClick={() =>
            this.props.onUpdateFile({
              id: item._id,
              isPublic: true,
            })
          }
        >
          <Icon type="global" /> Public
        </Menu.Item>
      </Menu>
    );
  };

  menu = record => {
    if (record.isLink) {
      if (record.isAsset) {
        return (
          <Menu>
            <Menu.Item key="copy" className="option-item">
              <CopyToClipboard
                text={isUrl(record.path) ? record.path : getS3CDN(record.path)}
                onCopy={() => message.success('Link copied')}
              >
                <span>Copy Link</span>
              </CopyToClipboard>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key="remove"
              onClick={() =>
                this.props.onUpdateFile({
                  id: record._id,
                  isAsset: false,
                })
              }
            >
              <span>Remove from assets</span>
            </Menu.Item>
          </Menu>
        );
      }
      return (
        <Menu>
          <Menu.Item key="copy" className="option-item">
            <CopyToClipboard
              text={isUrl(record.path) ? record.path : getS3CDN(record.path)}
              onCopy={() => message.success('Link copied')}
            >
              <span>Copy Link</span>
            </CopyToClipboard>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            key="move-to-asset"
            onClick={() =>
              this.props.onUpdateFile({
                id: record._id,
                isAsset: true,
              })
            }
          >
            <span>Add to assets</span>
          </Menu.Item>
        </Menu>
      );
    } else {
      if (record.isAsset) {
        return (
          <Menu>
            <Menu.Item
              key="copy"
              onClick={() =>
                this.props.onUpdateFile({
                  id: record._id,
                  isAsset: false,
                })
              }
            >
              <span>Remove from assets</span>
            </Menu.Item>
          </Menu>
        );
      }
      return (
        <Menu>
          <Menu.Item key="download">
            <a href={getS3CDN(record.path)} download={record.title}>
              <span>Download</span>
            </a>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            key="copy"
            onClick={() =>
              this.props.onUpdateFile({
                id: record._id,
                isAsset: true,
              })
            }
          >
            <span>Add to assets</span>
          </Menu.Item>
        </Menu>
      );
    }
  };

  moveToAssets = () => {
    const { selectedRowKeys } = this.state;
    if (!selectedRowKeys.length) return;
    this.props.onMoveToAssets({
      data: selectedRowKeys,
    });
  };

  formatColumns = () => {
    const { hasAssigned } = this.props;
    return [
      {
        title: '',
        dataIndex: '',
        width: 50,
        render: (title, record) => {
          let fileName = '';
          switch (true) {
            case record.isGallery:
              fileName = 'folder';
              break;
            case record.isLink:
              fileName = 'link';
              break;
            default:
              fileName = encodeURIThumbnail(record.pathThumbnail, record.path);
              break;
          }

          const checkHasThumnail =
            isFileHasThumbnail(record.path) || isYoutubeLink(record.path);

          if(checkDriveLink(record.path)) {
            const idUrl = getIdLinkDrive(record.path);
            const thumbnail = getThumbnailLinkDrive(idUrl);
            return (
              <div className="drive-link position-relative">
                <img src={thumbnail}
                  onError={(ev) => {
                    ev.target.src = IconImageThumbnail
                  }}
                  alt=''
                />
                <img src={IconImageThumbnail} className="position-absolute watermark" alt=""/>
              </div>
            )
          }

          return (
            <FileExtentions
              onclick={
                checkHasThumnail ? () => this.setState({ recordDetail: record }) : null
              }
              path={record.path}
              fileName={fileName}
            />
          );
        },
      },
      {
        title: '',
        dataIndex: 'path',
        width: 200,
        render: (path, record) => {
          // if(checkDriveLink(path)) {
          //   return <span>{path}</span>;
          // }
          if (record.name) {
            return <span>{record.name}</span>;
          }
          return <span>{record.customTitle || path}</span>;
        },
      },
      {
        title: '',
        dataIndex: 'senderId[fullName]',
        render: (fullname, record) => {
          const { avt, fullName } = record.createdBy || {};
          return (
            <UserAvatar
              fullName={fullName}
              avatar={avt}
              status={!isDeactiveUser(record.senderId)}
              size="small"
            />
          );
        },
      },
      {
        title: '',
        dataIndex: 'createdAt',
        width: 100,
      },
      {
        title: hasAssigned ? (
          <span className="btn-move-assets" onClick={this.moveToAssets}>
            Move to assets
          </span>
        ) : (
          <span className="btn-move-assets">Move to assets</span>
        ),
        colSpan: 3,
        width: 100,
        dataIndex: 'fileSize',
        render: (fileSize, record) => {
          if (record.fileSize) {
            return <span>{fileSize}</span>;
          }
          return null;
        },
      },
      {
        title: '',
        colSpan: 0,
        dataIndex: 'isPublic',
        render: (isPublic, record) => {
          if (!record.isLink) {
            if (isVideoStreamFile({ filename: record.path })) return null;

            if (isPublic) {
              return (
                <Dropdown
                  overlay={() => this.privateMenu(record)}
                  trigger={['click']}
                  disabled={!hasAssigned}
                >
                  <span className="ant-dropdown-link add-cursor">
                    <Icon type="global" /> <Icon type="down" />
                  </span>
                </Dropdown>
              );
            }
            return (
              <Dropdown
                overlay={() => this.publicMenu(record)}
                trigger={['click']}
                disabled={!hasAssigned}
              >
                <span className="ant-dropdown-link add-cursor">
                  <Icon type="lock" /> <Icon type="down" />
                </span>
              </Dropdown>
            );
          }
          return null;
        },
      },
      {
        title: '',
        colSpan: 0,
        dataIndex: 'isAsset',
        render: (isAsset, record) => {
          return (
            <Dropdown
              overlay={() => this.menu(record)}
              trigger={['click']}
              disabled={!hasAssigned}
            >
              <Icon type="ellipsis" key="ellipsis" className="add-cursor" />
            </Dropdown>
          );
        },
      },
    ];
  };

  formatData = () => {
    const { filesAndLinks } = this.props;
    const list = filesAndLinks && filesAndLinks.docs;
    if (list && list.length) {
      return list.map((item, index) => {
        if (item.fileSize) {
          return {
            ...item,
            key: item._id,
            createdAt: moment(item.createdAt).format('MMM DD, YYYY [at] HH:mm'),
            fileSize: formatBytes(item.fileSize),
          };
        }
        return {
          ...item,
          key: item._id,
          createdAt: moment(item.createdAt).format('MMM DD, YYYY [at] HH:mm'),
        };
      });
    }
  };

  getFilesAndLinks = (page, limit) => {
    const { currentProject } = this.props;
    const params = {
      projectId: currentProject._id,
      page,
      limit,
    };
    this.props.onGetFilesAndLinks(params);
  };

  componentDidMount() {
    const { currentLimit } = this.state;
    this.getFilesAndLinks(1, currentLimit);
  }

  componentDidUpdate(prevProps) {
    const { currentPage, currentLimit } = this.state;
    if (this.props.hasMoved && prevProps.hasMoved !== this.props.hasMoved) {
      this.getFilesAndLinks(currentPage, currentLimit);
    }
  }

  onChangePage = page => {
    const { currentLimit } = this.state;
    this.setState(
      {
        currentPage: page,
      },
      () => {
        this.getFilesAndLinks(page, currentLimit);
      }
    );
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onShowSizeChange = (currentPage, pageSize) => {
    this.setState(
      {
        currentPage,
        currentLimit: pageSize,
      },
      () => {
        this.getFilesAndLinks(currentPage, pageSize);
      }
    );
  };

  render() {
    const { filesAndLinks, isLoading } = this.props;
    const totalPages = filesAndLinks && filesAndLinks.total;
    const { selectedRowKeys, currentPage, recordDetail } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const columns = this.formatColumns();
    const dataSource = this.formatData();

    return (
      <div className="mgt-20 file-link-tabs-wrapper">
        <Spin spinning={isLoading}>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
          />
        </Spin>
        <Pagination
          className="files-pagination"
          current={currentPage}
          onChange={this.onChangePage}
          total={totalPages}
          onShowSizeChange={this.onShowSizeChange}
          showSizeChanger={true}
        />
        <FileDetail
          detail={recordDetail}
          onCancel={() => this.setState({ recordDetail: null })}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    filesAndLinks: state.filesAndLinks.data,
    hasMoved: state.filesAndLinks.hasMoved,
    isLoading: state.filesAndLinks.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetFilesAndLinks: params => {
      dispatch(getFilesAndLinks(params));
    },
    onUpdateFile: params => {
      dispatch(updateFile(params));
    },
    onMoveToAssets: data => {
      dispatch(moveToAssets(data));
    },
  };
};

const WrappedFilesAndLinks = Form.create({ name: 'normal_files_and_links' })(
  connect(mapStateToProps, mapDispatchToProps)(FilesAndLinks)
);

export default WrappedFilesAndLinks;
