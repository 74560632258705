import React from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Tabs, Button, Switch } from 'antd';
import { isSuperAdmin } from 'src/core/utils/commonUtils';
import { deleteProjectService } from 'src/core/services/modules/projectsService';
import { openNotification } from '../../notification/notification';
import {
  getNumberOfProject,
  getProjectsDetail,
  updateProjects,
} from 'src/redux/actions/admin/projectsAction';
import { sendGetNumberProjectAction } from 'src/core/utils/event_emitter/handler';
import CustomerInfomation from './customerInformation/customerInformation';
// import OnsiteContactInformation from './onsiteContactInformation/onsiteContactInformation';
import AssetForm from './assetForm/assetForm';
import ActivityForm from './activityForm/activityForm';
import PaymentInformation from '../projectCreateForm/paymentInformation/paymentInformation';
import ProjectInformation from './projectInformation/projectInfomation';
import ProductionDates from './productionDates';
import ProjectBriefs from './briefs/briefs';
import Workspace from '../workspace/workspace';

import CustomerInformation from 'src/components/common/form/projectsDetail/customerInformation/customerInformation';

import './projectsDetail.scss';
import AssetFormV2 from 'src/components/common/form/projectsDetail/assetFormV2/assetFormV2';

const { TabPane } = Tabs;
const { confirm } = Modal;

class ProjectsDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title:
        this.props.currentProject && this.props.currentProject.title
          ? this.props.currentProject.title
          : null,
      activitiesPage: 1,
      activitiesLimit: '',
      isContainerDisplayed: true,
      newCustomer: props.currentProject.customerId._id,
    };
  }

  onLoadMoreActivitiesHandler = () => {
    const { activities, currentProject } = this.props;
    const totalPage = activities.pages;
    const { activitiesPage } = this.state;
    if (activitiesPage < totalPage) {
      this.setState(
        prevState => ({
          activitiesPage: prevState.activitiesPage + 1,
        }),
        () => {
          this.props.onLoadMoreActivities({
            id: currentProject._id,
            page: this.state.activitiesPage,
            limit: this.state.activitiesLimit,
          });
        }
      );
    }
  };

  getActivities = () => {
    const { currentProject } = this.props;
    const projectId = currentProject._id;
    const params = {
      page: 1,
      id: projectId,
    };
    this.props.onGetActivities(params);
  };

  tabChange = key => {
    const { currentProject } = this.props;
    this.props.setTabIndex(key);
    const projectId = currentProject._id;
    switch (key) {
      case 'activity':
        this.setState({
          activitiesPage: 1,
        });
        this.getActivities();
        break;
      case '1':
        this.props.onGetProjectsDetail(projectId);
        break;

      default:
        break;
    }
  };

  onHandleDelete = async prjId => {
    const response = await deleteProjectService({ projectId: prjId });
    if (response.data.success) {
      sendGetNumberProjectAction();
      this.props.getProjects();
      this.props.onClose();
    } else {
      openNotification('error', 'Delete Project Fail');
    }
  };

  onConfirmDelete = () => {
    const { currentProject } = this.props;
    confirm({
      title: 'Delete Project',
      content:
        'Are you sure you would like to delete this project, this action can’t be undone?',
      okText: 'No',
      cancelText: 'Yes',
      className: 'confirm-delete-account',
      onCancel: () => this.onHandleDelete(currentProject._id),
    });
  };

  handleChangeCustomer = (property, value) => {
    const valueData = typeof value === 'string' ? value.trim() : value;

    if (valueData !== this.props.currentProject?.customerId?._id) {
      this.props.onUpdateProject({
        id: this.props.currentProject._id,
        newCustomer: valueData,
      });
    }
  };

  projectTabsRender = () => {
    const {
      isLoading,
      isActivitiesLoading,
      isCreativesLoadingMore,
      isEditorsLoadingMore,
      currentProject,
      activities,
      hasAssigned,
      currentUser,
      currentTopic,
      quoteFiles,
      packages,
      creatives,
      editors,
    } = this.props;
    const { activitiesPage } = this.state;

    return (
      <Tabs
        className="project-tabs mgt-20"
        destroyInactiveTabPane={true}
        onChange={this.tabChange}
        type="card"
      >
        <TabPane tab="Information" key="1">
          {/* <div className="d-flex mb-4 align-items-center">
            <h6 className="mb-0 mr-2" style={{ color: 'grey' }}>
              <strong>Archive</strong>
            </h6>
            <Switch
              className="mr-3"
              disabled
              checked={currentProject && currentProject.isArchive}
            />
          </div> */}
          <CustomerInformation
            isArchive={currentProject.isArchive}
            onInputFields={this.handleChangeCustomer}
          />
          <CustomerInfomation currentProject={currentProject} hasAssigned={hasAssigned} />
          <ProjectInformation
            currentProject={currentProject}
            hasAssigned={hasAssigned}
            refreshOnChangeStatus={this.props.refreshOnChangeStatus}
          />
          {/* <OnsiteContactInformation
            currentProject={currentProject}
            hasAssigned={hasAssigned}
          /> */}
          <Form.Item>
            <Button
              className="btn-primary-0"
              onClick={this.onConfirmDelete}
              type="primary"
              disabled={!isSuperAdmin(currentUser)}
            >
              Delete project
            </Button>
          </Form.Item>
        </TabPane>
        <TabPane tab="Call sheet" key="callsheet">
          <ProductionDates project={currentProject} />
        </TabPane>
        <TabPane tab="Project brief" key="brief">
          <ProjectBriefs
            project={currentProject}
            briefHistories={this.props.briefHistories}
          />
        </TabPane>
        <TabPane tab="Contact" key="contact">
          <Workspace
            currentProject={currentProject}
            currentTopic={currentTopic}
            quoteFiles={quoteFiles}
            isCreativesLoadingMore={isCreativesLoadingMore}
            isEditorsLoadingMore={isEditorsLoadingMore}
            isLoading={isLoading}
            packages={packages}
            creatives={creatives}
            editors={editors}
            activities={activities}
            isActivitiesLoading={isActivitiesLoading}
            onUpdateProjectCall={this.props.onUpdateProject}
            onGetCreatives={this.props.onGetCreatives}
            onLoadMoreCreatives={this.props.onLoadMoreCreatives}
            onGetEditors={this.props.onGetEditors}
            onLoadMoreEditors={this.props.onLoadMoreEditors}
            onGetActivities={this.props.onGetActivities}
            onLoadMoreActivities={this.props.onLoadMoreActivities}
            onGetDetailProject={this.props.onGetDetailProject}
            onToggleActionsContainer={this.props.onToggleActionsContainer}
            onSetQuoteFilesEmpty={this.props.onSetQuoteFilesEmpty}
            hasAssigned={hasAssigned}
            currentUser={currentUser}
            onPreventBackClick={this.props.onPreventBackClick}
            onAllowBackClick={this.props.onAllowBackClick}
          />
        </TabPane>
        <TabPane tab="Assets" key="2">
          <AssetFormV2 currentProject={currentProject} />
          {/*<AssetForm*/}
          {/*  hasAssigned={hasAssigned}*/}
          {/*  onPreventBackClick={this.props.onPreventBackClick}*/}
          {/*  onAllowBackClick={this.props.onAllowBackClick}*/}
          {/*/>*/}
        </TabPane>
        <TabPane tab="Payment" key="payment">
          <PaymentInformation currentProject={currentProject} hasAssigned={hasAssigned} />
          <Form.Item>
            <Button
              className="btn-primary-0 mr-3"
              onClick={this.props.onSendQuote}
              type="primary"
              disabled={!hasAssigned}
            >
              Send a quote
            </Button>
          </Form.Item>
        </TabPane>
        <TabPane tab="Activity" key="activity">
          <ActivityForm
            currentProject={currentProject}
            activities={activities}
            onloadMoreClick={this.onLoadMoreActivitiesHandler}
            isActivitiesLoading={isActivitiesLoading}
            activitiesPage={activitiesPage}
          />
        </TabPane>
      </Tabs>
    );
  };

  componentDidMount() {
    this.props.onToggleActionsContainer(true);
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
      },
    };
    return (
      <Form {...formItemLayout} className="projects-form">
        {this.projectTabsRender()}
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    briefHistories: state.projects.briefHistories,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateProject: data => {
      dispatch(updateProjects(data));
    },
    onGetProjectsDetail: id => {
      dispatch(getProjectsDetail(id));
    },
    getNumberProjectAction: () => {
      return dispatch(getNumberOfProject());
    },
  };
};

const WrappedProjectsDetail = Form.create({ name: 'normal_projects_detail' })(
  connect(mapStateToProps, mapDispatchToProps)(ProjectsDetail)
);

export default WrappedProjectsDetail;
