import { connect } from 'react-redux';
import FaqsScreen from '../../../components/screens/admin/faqs/faqsScreen';
import {
	getFaqs,
	createFaqs,
	updateFaqs,
	updateOrder
} from '../../../redux/actions/admin/faqsActions';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';

const mapStateToProps = state => {
	return {
		success: state.faqs.success,
		error: state.faqs.error,
		isLoading: state.faqs.isLoading,
		data: state.faqs.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetFaqs: () => {
			dispatch(getFaqs());
		},
		onCreateFaqs: data => {
			dispatch(createFaqs(data));
		},
		onUpdateFaqs: ({ data, id }) => {
			dispatch(updateFaqs({ data, id }));
		},
		onUpdateOrder: data => {
			dispatch(updateOrder(data));
		},
		onGetCurrentUser: () => {
			dispatch(getCurrentUser());
		}
	};
};

const FaqsContainer = connect(mapStateToProps, mapDispatchToProps)(FaqsScreen);

export default FaqsContainer;
