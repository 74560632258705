import { put, call, takeLatest } from 'redux-saga/effects';

import {
	GET_UNITS_FAILED,
	GET_UNITS_SUCCESS,
	CREATE_UNITS_FAILED,
	CREATE_UNITS_SUCCESS,
	UPDATE_UNITS_FAILED,
	UPDATE_UNITS_SUCCESS,
	UPDATE_ORDER_UNITS_FAILED,
	GET_UNITS_REQUEST,
	CREATE_UNITS_REQUEST,
	UPDATE_UNITS_REQUEST,
	UPDATE_ORDER_UNITS_SUCCESS
} from '../../actions/typeAction';
import {
	getUnitsService,
	createUnitsService,
	updateUnitsService
} from '../../../core/services/modules/unitsService';
import { updateOrderService } from '../../../core/services/modules/orderService';
import { openNotification } from '../../../components/common/notification/notification';

export function* getUnitsSaga() {
	const response = yield call(getUnitsService);
	if (response.data.error) {
		yield put({
			type: GET_UNITS_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: GET_UNITS_SUCCESS,
			payload: data
		});
	}
}

export function* createUnitsSaga(action) {
	const response = yield call(createUnitsService, action.payload);
	if (response.data.error) {
		yield put({
			type: CREATE_UNITS_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: CREATE_UNITS_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateUnitsSaga(action) {
	const response = yield call(updateUnitsService, action.payload);
	if (response.data.error) {
		yield put({
			type: UPDATE_UNITS_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: UPDATE_UNITS_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateOrderSaga(action) {
	const response = yield call(updateOrderService, action.payload);
	if (response.data.error) {
		yield put({
			type: UPDATE_ORDER_UNITS_FAILED,
			payload: response.data.error
		});
	} else {
		openNotification('success', 'Update successful');
	}
}

export function* watchUnits() {
	yield takeLatest(GET_UNITS_REQUEST, getUnitsSaga);
	yield takeLatest(CREATE_UNITS_REQUEST, createUnitsSaga);
	yield takeLatest(UPDATE_UNITS_REQUEST, updateUnitsSaga);
	yield takeLatest(UPDATE_ORDER_UNITS_SUCCESS, updateOrderSaga);
}
