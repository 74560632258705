import { put, call, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../../actions/typeAction';
import { loginService } from '../../../core/services/modules/authService';
import { storageService } from '../../../core/services/storageService';

export function* loginSaga(action) {
  const response = yield call(loginService, action.payload);
  if (response.data.error) {
    yield put({
      type: actionTypes.LOGIN_FAILED,
      payload: response.data.error,
    });
  } else {
    const data = response.data;
    try {
      delete data.data.token;
      delete data.data.refreshToken;
      // yield storageService.setAuthToken(data.data.token, data.data.refreshToken);
      yield storageService.setRememberMe(data.data.rememberMe);
      yield storageService.setCurrentUser(data.data);
    } catch (e) {
      console.error(e.message, e.stack);
    }
    yield put({
      type: actionTypes.LOGIN_SUCCESS,
      payload: data,
    });
  }
}

export function* watchLogin() {
  yield takeLatest(actionTypes.LOGIN_REQUEST, loginSaga);
}
