export const vi = {
    msg: {
        required: 'This field is a mandatory field',
        character_string: 'Contents for this textbox must have at least one Alphabetic character',
        special_characters: 'Can\'t enter special characters',
        select_required: 'Please select a value for this field.',
        email: 'Please enter a valid email address',
        email_exists: 'This email already exists.',
        maxlength: 'This field only allows max {{value}} characters, please revise accordingly.',
        minlength: 'This field can be at least {{value}} characters long',
        maxDigits: 'This field can be at most XXX numeric digits',
        minDigits: 'This field can be at least {{value}} numeric digits',
        password_format: 'Password should be at least 6 characters long and include at least one number or symbol.',
        password_format_lenght: 'Be at least 6 characters long.',
        password_format_contain: 'Include at least one number or symbol.',
        password_not_match: 'Confirm password field does not match the password field.',
        old_pw_invalid: 'Old password do not match',
        birthday_invalid: 'Birthday is invalid',
        phone: 'Must be a valid 10 digits phone number',
        valid: 'Please enter a valid {{value}}.',
        promocode_minprice: 'You need to spend minimum {{value}} on promo specific product to enjoy this discount.',
        promocode_not_apply: 'Promo code not applicable.',
        card_number_length: 'You need to re-enter your 16 digit credit card number to save latest details',
        image_empty: 'Please upload a photo of your whisky bottle',
        image_invalid: 'Your image file is unsupported, please try again',
        image_limit: 'Please upload 3 or more (max 10) pictures of your whisky bottle',
        requiredFile: 'Please select a file to upload.',
        max_length: 'Maximum {{value}} characters',
    },
    field: {
        email: 'email address',
        phone: 'phone number',
        postcode: 'postcode',
    },
    btn: {
        continue: 'CONTINUE',
        back: 'BACK',
        save_changes: 'Save Changes',
        cancel: 'Cancel',
        cancel_auction: 'Cancel Auction',
        close: 'Close',
        save: 'Save',
        select: 'Select',
        revert: 'Undo Cancellation',
        update_plan: 'UPDATE PLAN',
        login: 'LOGIN',
        create_account: 'CREATE AN ACCOUNT',
        forgot_password: 'Forgot Password',
        logout: 'SIGN OUT',
        back_signin: 'BACK TO LOGIN',
        send: 'SEND',
        resend: 'RESEND',
        confirm: 'Confirm',
        next: 'Next',
        read_more: 'READ MORE',
        submit: 'SUBMIT',
        upload_photo: 'UPLOAD PHOTOS',
        add_another_bottle: 'Add another bottle',
        edit_submission: 'EDIT SUBMISSION',
        packing_guide: 'PACKING GUIDE',
        details: 'DETAILS',
        go_to_acc_dashboard: 'GO TO ACCOUNT DASHBOARD',
        save_draft: 'Save Draft',
        save_publish: 'Save & Publish',
        change_bank_details: 'Change bank details',
        update_bank_details: 'Update bank details',
        export_csv: 'Export CSV'
    },
    errors: {
        
    },
    tooltips: {
        
    },
};