import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const getSkillsService = () => {
    const url = BaseConfig.END_POINT.ADMIN.SKILLS;
    const response = httpService._get(url);
    return response;
};

export const createSkillsService = (data) => {
    const url = BaseConfig.END_POINT.ADMIN.SKILLS;
    const response = httpService._post(url, data);
    return response;
};

export const updateSkillsService = ({data, id}) => {
    const url = BaseConfig.END_POINT.ADMIN.SKILLS;
    const response = httpService._put(`${url}/${id}`, data);
    return response;
};
