import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Icon, Input, Dropdown, Menu, Select, Modal, Button, notification } from 'antd';
import { EDITOR_STATUS } from '../../../../../core/constants/constants';
import { updateEditor } from '../../../../../redux/actions/admin/editorsAction';
import { getSkills } from '../../../../../redux/actions/admin/skillsAction';
import { getHourRate } from '../../../../../redux/actions/admin/hourRateAction';
import { isSuperAdmin } from 'src/core/utils/commonUtils';
import { deleteAccount } from 'src/core/services/modules/userService.js';
import { getEditors } from 'src/redux/actions/admin/editorsAction.js';

import './editorInfo.scss';

const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

class EditorInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleRedirect = () => {
    const { editor } = this.props;
    const url = `/?editorId=${editor._id}`;
    this.props.history.push(url);
  };

  renderStatus = () => {
    const { editor } = this.props;
    const { status } = editor;
    const filterStatus = EDITOR_STATUS.filter(item => item.value !== status);
    const menu = (
      <Menu>
        {filterStatus.map(item => (
          <Menu.Item key={item.value}>
            <span
              onClick={() =>
                this.props.onUpdateEditor({
                  id: editor._id,
                  status: item.value,
                })
              }
            >
              {item.label}
            </span>
          </Menu.Item>
        ))}
      </Menu>
    );
    const statusRender = EDITOR_STATUS.find(item => item.value === status);
    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
          {statusRender.label || null} <Icon type="down" />
        </span>
      </Dropdown>
    );
  };

  renderSkills = () => {
    const { editor, allSkills } = this.props;
    const { skills } = editor;
    const allSkillsName =
      (allSkills && allSkills.length && allSkills.map(item => item.name)) || [];
    const otherIndex =
      skills && skills.length && skills.findIndex(item => !allSkillsName.includes(item));
    if (otherIndex) skills.push(skills.splice(otherIndex, 1)[0]);
    const skillsRender =
      (skills &&
        skills.length &&
        skills.map(item => {
          if (!allSkillsName.includes(item)) {
            return (
              <div key={item} className="other-skills-container">
                <span key={item} className="skills-item">
                  Others
                </span>
                <TextArea rows={4} defaultValue={item} disabled />
              </div>
            );
          }
          return (
            <span key={item} className="skills-item">
              {item}
            </span>
          );
        })) ||
      null;
    return (
      <div className="skills-container container-item">
        <span className="info-title">Skills</span>
        <div className="skills-list">{skillsRender}</div>
      </div>
    );
  };

  renderEquipment = () => {
    const { editor } = this.props;
    return (
      <div className="equipment-container container-item">
        <span className="info-title">Equipment</span>
        <TextArea rows={4} defaultValue={editor.equipment} disabled />
      </div>
    );
  };

  renderDateCreated = () => {
    const { editor } = this.props;
    return (
      <div className="date-created-container container-item">
        <span className="info-title">Date Created</span>
        <Input
          defaultValue={moment(editor.createdAt).format('MMM DD, YYYY [at] HH:mm')}
          disabled
        />
      </div>
    );
  };

  renderHourRate = () => {
    const { allHourRates } = this.props;
    const { editor } = this.props;
    return (
      <div className="hour-rate-container container-item">
        <span className="info-title">Hourly rate</span>
        <Select
          className="hour-rate-select"
          defaultValue={
            editor.hourRate ||
            (allHourRates && allHourRates.length && allHourRates[0].name)
          }
          onChange={value =>
            this.props.onUpdateEditor({
              id: editor._id,
              hourRate: value,
            })
          }
        >
          {allHourRates &&
            allHourRates.length &&
            allHourRates.map(item => {
              return item.status ? (
                <Option key={item._id} value={item.name}>
                  {item.name}
                </Option>
              ) : null;
            })}
        </Select>
      </div>
    );
  };

  renderPortfolioLinks = () => {
    const { editor } = this.props;
    const { linkProfile } = editor;
    return (
      <div className="portfolios-container container-item">
        <span className="info-title">Portfolio links</span>
        {(linkProfile &&
          linkProfile.length &&
          linkProfile.map(item => {
            return (
              <a
                key={item}
                href={item.includes('http') ? item : `//${item}`}
                className="d-block portfolio-item"
                target="_blank"
                rel="noopener noreferrer"
              >
                {item}
              </a>
            );
          })) ||
          null}
      </div>
    );
  };

  renderSpecialSation = () => {
    const { editor } = this.props;
    const { specialSation } = editor;
    return (
      <div className="specialSation-container container-item">
        <span className="info-title">Specialisations</span>
        <Input defaultValue={specialSation} disabled />
      </div>
    );
  };

  renderNote = () => {
    const { editor, currentUser } = this.props;
    const { note } = editor;
    return (
      <div className="note-container container-item">
        <span className="info-title">Notes</span>
        {(note && (
          <TextArea
            rows={4}
            defaultValue={note}
            disabled={!isSuperAdmin(currentUser)}
            onBlur={e => {
              if (note !== e.target.value) {
                this.props.onUpdateEditor({
                  id: editor._id,
                  note: e.target.value,
                });
              }
            }}
          />
        )) ||
          null}
      </div>
    );
  };

  componentDidMount() {
    this.props.onGetSkills();
    this.props.onGetHourRate();
  }

  handleDeleteAcount = () => {
    const { editor, onClose, onGetEditors } = this.props;
    const { userId } = editor || {};
    confirm({
      title: 'Delete account',
      content: 'Are you sure want to delete this account ?',
      okText: 'No',
      cancelText: 'Yes',
      className: 'confirm-delete-account',
      onCancel: async () => {
        try {
          const res = await deleteAccount(userId._id);
          const { data } = res;
          if (data.success) {
            notification['success']({
              message: 'Delete success',
            });
            onGetEditors();
            return onClose();
          }
          notification['warning']({
            message: data.error.message,
          });
        } catch (error) {
          notification['warning']({
            message: 'Something wrong!',
          });
        }
      },
      onOk: () => {},
    });
  };
  checkDeleteAccount = () => {
    const { currentUser, editor } = this.props;
    const { roleId } = currentUser;
    const deteledUser = editor && editor.userId && editor.userId.isDelete;
    const canDeleted = roleId && roleId.name === 'superadmin' && !deteledUser;
    return canDeleted;
  };

  render() {
    const { editor } = this.props;
    return (
      <div className="editor-info-container">
        <div className="info-item">
          {this.renderSkills()}
          {this.renderEquipment()}
          {this.renderDateCreated()}
          <span onClick={() => this.handleRedirect()} className="projects-link">
            {editor.userId && editor.userId.fullName}'s projects
          </span>
        </div>
        <div className="info-item">
          {this.renderHourRate()}
          {this.renderPortfolioLinks()}
          {this.renderSpecialSation()}
          {this.renderNote()}
          <Button
            type="primary"
            className="d-block ml-auto"
            onClick={this.handleDeleteAcount}
            disabled={!this.checkDeleteAccount()}
          >
            Delete account
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allSkills: state.skills.data,
    allHourRates: state.hour_rate.data,
    currentUser: state.user.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetSkills: () => {
      dispatch(getSkills());
    },
    onUpdateEditor: data => {
      dispatch(updateEditor(data));
    },
    onGetHourRate: () => {
      dispatch(getHourRate());
    },
    onGetEditors: () => {
      dispatch(getEditors({ page: 1, limit: 10 }));
    },
  };
};

export default withTranslation('common')(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(EditorInfo))
);
