import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { Collapse } from 'antd';

export function BriefEditor(props) {
  const { fieldName, fieldData, onInputFields } = props;
  return (
    <div className="w-100">
      <CKEditor
        className="w-100"
        onInit={editor => {
          editor.ui
            .getEditableElement()
            .parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement()
            );
        }}
        onChange={(event, editor) => onInputFields(fieldName, editor.getData())}
        data={fieldData || ''}
        editor={DecoupledEditor}
        row="4"
        config={{
          toolbar: [
            'heading',
            'bold',
            'italic',
            'link',
            'numberedList',
            'bulletedList',
            'alignment',
            'blockQuote',
            'insertTable',
            'mediaEmbed',
          ],
        }}
      />
    </div>
  );
}

const { Panel } = Collapse;

export default function ProjectBriefs(props) {
  const { formData, onInputFields } = props;

  return (<>
    <Collapse
      defaultActiveKey={['0']}
      expandIconPosition='left'
    >
      <Panel header="Vision (Legacy Brief)" key="0">
        <BriefEditor fieldName="brief" fieldData={formData.brief} onInputFields={onInputFields} />
      </Panel>
      <Panel header="Schedule" key="1">
        <BriefEditor fieldName="briefSchedule" fieldData={formData.briefSchedule} onInputFields={onInputFields} />
      </Panel>
      <Panel header="Shotlist" key="2">
        <BriefEditor fieldName="briefShotlist" fieldData={formData.briefShotlist} onInputFields={onInputFields} />
      </Panel>
      <Panel header="Script & interview questions" key="3">
        <BriefEditor fieldName="briefScript" fieldData={formData.briefScript} onInputFields={onInputFields} />
      </Panel>
      <Panel header="Technical settings" key="4">
        <BriefEditor fieldName="briefTechnical" fieldData={formData.briefTechnical} onInputFields={onInputFields} />
      </Panel>
    </Collapse>
  </>);
}
