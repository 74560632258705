import { connect } from 'react-redux';
import OurTeamScreen from '../../../components/screens/admin/ourTeam/ourTeamScreen';
import {
  getOurTeamMembers,
  createOurTeamMembers,
  updateOurTeamMembers,
  updateOrder
} from '../../../redux/actions/admin/ourTeamMembersAction';
import { getOurTeam, updateOurTeam } from '../../../redux/actions/admin/ourTeamAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';

const mapStateToProps = state => {
  return {
    isLoading: state.ourTeamMembers.isLoading,
    ourTeamMembers: state.ourTeamMembers.data,
    ourTeam: state.ourTeam.data,
    isLoadingBanner: state.ourTeam.isLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetOurTeam: () => {
      dispatch(getOurTeam());
    },
    onUpdateOurTeam: data => {
      dispatch(updateOurTeam(data));
    },
    onGetOurTeamMembers: () => {
      dispatch(getOurTeamMembers());
    },
    onCreateOurTeamMembers: data => {
      dispatch(createOurTeamMembers(data));
    },
    onUpdateOurTeamMembers: ({ data, id }) => {
      dispatch(updateOurTeamMembers({ data, id }));
    },
    onUpdateOrder: data => {
      dispatch(updateOrder(data));
    },
    onGetCurrentUser: () => {
      dispatch(getCurrentUser());
    }
  };
};

const OurTeamContainer = connect(mapStateToProps, mapDispatchToProps)(OurTeamScreen);

export default OurTeamContainer;
