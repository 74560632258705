import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../../actions/typeAction';
import {
    getServiceTypeService,
    getServiceTypeByIdProductType,
} from '../../../core/services/modules/serviceTypeService';
import { updateOrderService } from '../../../core/services/modules/orderService';
import { getServiceTypeByIdService,
		createServiceTypeService,
		updateServiceTypeService } from '../../../core/services/modules/serviceTypeService';
import { openNotification } from '../../../components/common/notification/notification';

export function* getServiceTypeSaga(action) {
    const response = yield call(getServiceTypeService, action.payload);
    if (response.data.error) {
        yield put({
            type: actionTypes.GET_SERVICE_TYPE_FAILED,
            payload: response.data.error,
        });
    } else {
        const data = response.data;

        yield put({
            type: actionTypes.GET_SERVICE_TYPE_SUCCESS,
            payload: data,
        });
    }
}

export function* getServiceTypeByProductTypeSaga(actions) {
    const response = yield call(getServiceTypeByIdProductType, actions.payload);
    if (response.data.error) {
        yield put({
            type: actionTypes.GET_SERVICE_TYPE_BY_PRODUCT_TYPE_SUCCESS,
            payload: response.data.error,
        });
    } else {
        const data = response.data;

        yield put({
            type: actionTypes.GET_SERVICE_TYPE_BY_PRODUCT_TYPE_SUCCESS,
            payload: data,
        });
    }
}

export function* getServiceTypeByIdSaga(action) {
	const response = yield call(getServiceTypeByIdService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_SERVICE_TYPE_BY_ID_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_SERVICE_TYPE_BY_ID_SUCCESS,
			payload: data
		});
	}
}

export function* createServiceTypeSaga(action) {
	const response = yield call(createServiceTypeService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.CREATE_SERVICE_TYPE_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.CREATE_SERVICE_TYPE_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateServiceTypeSaga(action) {
	const response = yield call(updateServiceTypeService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_SERVICE_TYPE_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.UPDATE_SERVICE_TYPE_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateOrderSaga(action) {
	const response = yield call(updateOrderService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_ORDER_SERVICE_TYPE_FAILED,
			payload: response.data.error
		});
	} else {
		openNotification('success', 'Update successful');
	}
}

export function* watchServiceType() {
    yield takeLatest(actionTypes.GET_SERVICE_TYPE_REQUEST, getServiceTypeSaga);
    yield takeLatest(
        actionTypes.GET_SERVICE_TYPE_BY_PRODUCT_TYPE_REQUEST,
        getServiceTypeByProductTypeSaga
    );
	yield takeLatest(actionTypes.CREATE_SERVICE_TYPE_REQUEST, createServiceTypeSaga);
	yield takeLatest(actionTypes.UPDATE_SERVICE_TYPE_REQUEST, updateServiceTypeSaga);
	yield takeLatest(actionTypes.UPDATE_ORDER_SERVICE_TYPE_SUCCESS, updateOrderSaga);
	yield takeLatest(actionTypes.GET_SERVICE_TYPE_BY_ID_REQUEST, getServiceTypeByIdSaga);
}
