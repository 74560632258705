import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { httpService } from 'src/core/services/httpService';
import { BaseConfig } from 'src/core/configs/baseConfig';
import { Form, Button, Input, Select, Modal } from 'antd';
import SelectFilterV2 from '../../../../common/selectFilterV2';
import { openNotification } from 'src/components/common/notification/notification';

const { Option } = Select;
const { TextArea } = Input;

class CreateTeam extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      customersMember: [],
      businessTypes: [],
      page: 1,
      pageMember: 1,
      search: null,
      searchMember: null,
      hasMore: true,
      hasMoreMember: true,
      admin: null,
      members: [],

      resetSelect: undefined,
      loading: false,
    };
  }

  async componentDidMount() {
    await this.getBusinessType();
    await this.getAllCustomerHaveNotTeam({ page: 1 });
  }

  getBusinessType = async () => {
    try {
      const url = BaseConfig.END_POINT.ADMIN.CREATE_TEAM.BUSINESS_TYPE;
      const { data } = await httpService._get(url);
      this.setState({ businessTypes: data.data });
    } catch (err) {
      console.log('err', err);
    }
  };

  getAllCustomerHaveNotTeam = async ({ page }) => {
    try {
      const { customers, search } = this.state;
      let url = BaseConfig.END_POINT.ADMIN.CREATE_TEAM.GET_ALL_CUSTOMER_HAVE_NOT_TEAM;
      url = `${url}?page=${page}`;
      if (search) url = `${url}&search=${search}`;
      const { data } = await httpService._get(url);
      this.setState({
        customers: [...customers, ...data.data.docs],
        page: data.data.page,
      });
      if (!data.data.docs.length) this.setState({ hasMore: false });
    } catch (err) {
      console.log('err', err);
    }
  };

  handleLoadMoreCustomers = async ({ page }) => {
    await this.getAllCustomerHaveNotTeam({ page });
  };

  searchTeamsHandler = async search => {
    this.setState(
      {
        search,
        customers: [],
        page: 1,
        hasMore: true,
      },
      async () => {
        await this.getAllCustomerHaveNotTeam({ page: 1 });
      }
    );
  };

  getAllCustomerMemberHaveNotTeam = async ({ pageMember }) => {
    try {
      const { customersMember, searchMember } = this.state;
      let url = BaseConfig.END_POINT.ADMIN.CREATE_TEAM.GET_ALL_CUSTOMER_HAVE_NOT_TEAM;
      url = `${url}?page=${pageMember}`;
      if (searchMember) url = `${url}&search=${searchMember}`;
      const { data } = await httpService._get(url);
      this.setState({
        customersMember: [...customersMember, ...data.data.docs],
        pageMember: data.data.page,
      });
      if (!data.data.docs.length) this.setState({ hasMoreMember: false });
    } catch (err) {
      console.log('err', err);
    }
  };

  handleLoadMoreCustomersMember = async ({ page }) => {
    await this.getAllCustomerMemberHaveNotTeam({ pageMember: page });
  };

  searchTeamsHandlerMember = async searchMember => {
    this.setState(
      {
        searchMember,
        customersMember: [],
        pageMember: 1,
        hasMoreMember: true,
      },
      async () => {
        await this.getAllCustomerMemberHaveNotTeam({ pageMember: 1 });
      }
    );
  };

  handleSelectTeamAdmin = async value => {
    const adminId = this.state.customers.find(e => e.user.email === value);
    this.props.form.resetFields(['member']);
    this.props.form.setFieldsValue({ member: [], admin: adminId._id });
    this.setState(
      {
        admin: value,
        search: null,
        hasMore: true,
        customers: [],
        customersMember: [],
        hasMoreMember: true,
        members: [],
        searchMember: null,
        pageMember: 1,
        page: 1,
        resetSelect: [],
      },
      async () => {
        await this.getAllCustomerMemberHaveNotTeam({ pageMember: 1 });
        await this.getAllCustomerHaveNotTeam({ page: 1 });
      }
    );
  };

  handleSelectTeamMember = async value => {
    this.setState(
      {
        resetSelect: undefined,
        members: value,

        searchMember: null,
        pageMember: 1,
        customersMember: [],
        hasMoreMember: true,
      },
      async () => {
        await this.getAllCustomerMemberHaveNotTeam({ pageMember: 1 });
      }
    );
  };

  handleSubmit = async () => {
    try {
      const { admin, members } = this.state;
      this.setState({ loading: true });

      this.props.form.validateFields(async (err, values) => {
        if (!err) {
          const dataTeam = {
            name: values.teamName && values.teamName.trim(),
            businessTypeId: values.businessType,
            bio: values.bio ? values.bio.trim() : '',
            admin,
            members,
            adminId: values.admin,
          };
          const url = BaseConfig.END_POINT.ADMIN.CREATE_TEAM.CREATE_NEW_TEAM;
          const { data } = await httpService._post(url, dataTeam);
          if (!data.success) {
            openNotification('error', data.error.message);
            this.setState({ loading: false });
          } else {
            this.props.onCloseModal();
            openNotification('success', 'Create new team successfully!');
            this.setState({ loading: false });
          }
        } else this.setState({ loading: false });
      });

    } catch (e) {
      console.log('e', e);
      this.setState({ loading: false });
    }
  };

  renderTeamAdmin = () => {
    const { customers } = this.state;
    if (!customers.length) return null;
    // bug scroll choose email item._id --> item.user.email
    return customers.map(item => (
      <Option
        key={`admin_${item._id}`}
        value={item.user.email}
        title={item.user.fullName}
      >
        {item.user.email} ({item.status})
      </Option>
    ));
  };

  renderTeamMember = () => {
    let { customersMember, admin } = this.state;
    customersMember = customersMember.filter(e => String(e.user.email) !== admin);
    if (!customersMember.length) return null;
    return customersMember.map(item => (
      <Option key={item._id} value={item.user.email} title={item.user.fullName}>
        {item.user.email} ({item.status})
      </Option>
    ));
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { visibleModal } = this.props;
    const {
      businessTypes,
      page,
      hasMore,
      admin,
      hasMoreMember,
      pageMember,
      resetSelect,
      loading,
    } = this.state;
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 24,
        },
      },
    };
    return (
      <Modal
        className="modal-create-team"
        visible={visibleModal}
        footer={null}
        title={'CREATE TEAM'}
        width={600}
        onCancel={() => this.props.onCloseModal()}
        destroyOnClose={true}
        closable={true}
        maskClosable={false}
      >
        <Form.Item {...tailFormItemLayout} label="Team Name">
          {getFieldDecorator('teamName', {
            rules: [
              {
                required: true,
                message: 'Please add a Team name',
              },
              {
                whitespace: true,
                message: 'Whitespace only is not allowed',
              },
            ],
          })(<Input className="input-custom-height" placeholder="Ex: VidOps,..." />)}
        </Form.Item>
        <Form.Item label="Business Type">
          {getFieldDecorator('businessType', {
            rules: [
              {
                required: true,
                message: 'Please select a business type',
              },
            ],
          })(
            <Select
              placeholder={'Select business type'}
              className="w-100 select-business"
            >
              {businessTypes.length &&
                businessTypes.map((item, index) => {
                  return (
                    <Option value={item._id} key={index}>
                      {item.name}
                    </Option>
                  );
                })}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Team Admin">
          {getFieldDecorator('admin', {
            rules: [
              {
                required: true,
                message: 'Please select a admin',
              },
            ],
          })(
            <SelectFilterV2
              hasMore={hasMore}
              page={page}
              placeholder={'Select team admin'}
              onSearchHandler={this.searchTeamsHandler}
              onLoadMoreHandler={this.handleLoadMoreCustomers}
              onSelect={this.handleSelectTeamAdmin}
              disabled={false}
              mode={'default'}
            >
              {this.renderTeamAdmin()}
            </SelectFilterV2>
          )}
        </Form.Item>
        <Form.Item label="Team Members">
          {getFieldDecorator('member', {
            rules: [
              {
                required: false,
              },
            ],
          })(
            <SelectFilterV2
              hasMore={hasMoreMember}
              page={pageMember}
              placeholder={'Select team members'}
              onSearchHandler={this.searchTeamsHandlerMember}
              onLoadMoreHandler={this.handleLoadMoreCustomersMember}
              onSelect={this.handleSelectTeamMember}
              disabled={!admin}
              mode={'multiple'}
              selected={resetSelect}
            >
              {this.renderTeamMember()}
            </SelectFilterV2>
          )}
        </Form.Item>
        <Form.Item {...tailFormItemLayout} label="Bio">
          {getFieldDecorator('bio')(
            <TextArea rows={4} maxLength={200} placeholder="Maximum 200 characters" />
          )}
        </Form.Item>
        <Form.Item {...tailFormItemLayout} className="text-right">
          <Button
            type="primary"
            className="btn btn-primary-0 mgl-10"
            loading={loading}
            disabled={loading}
            onClick={this.handleSubmit}
            size={'large'}
          >
            Save
          </Button>
        </Form.Item>
      </Modal>
    );
  }
}

const WrapCreateTeam = Form.create({ name: 'normal_get_started' })(CreateTeam);

export default withTranslation('common')(WrapCreateTeam);
