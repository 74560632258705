import React from 'react';
import './dashboardForm.scss';
import { Jumbotron, Container, Row, Col } from 'react-bootstrap';
import { Upload, Button, Icon, Form, Input, Modal } from 'antd';
import { extractFileExtension, uuid } from 'src/core/utils/commonUtils';
import {
  getBase64,
  formDataConfig,
  getS3CDN,
  dummyRequest,
} from '../../../../core/utils/commonUtils';
import { S3_FOLDERS } from 'src/core/constants/constants';
import { httpService } from 'src/core/services/httpService';

const { TextArea } = Input;

class DashboardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      subTitle: '',
      video: null,
      urlBanner: '',
      deleteLogos: [],
      fileList: [],
      fileVideos: [],
      isBannerDisplay: true,
      previewVisible: false,
      previewImage: '',
      photosError: '',
      videoError: '',
      isBtnDisabled: false,
      isLoading: false,
    };
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ fileList }) => {
    if (fileList.length) {
      const lastImage = fileList[fileList.length - 1];
      lastImage.status !== 'done'
        ? this.setState({
            isBtnDisabled: true,
          })
        : this.setState({
            isBtnDisabled: false,
          });
      if (lastImage.originFileObj) {
        const isJpgOrPng =
          lastImage.type === 'image/jpeg' || lastImage.type === 'image/png';
        if (!isJpgOrPng) {
          this.setState({
            photosError: 'You can only upload JPG/PNG file',
          });
          fileList.pop();
        } else {
          this.setState({
            photosError: '',
          });
        }
      }
    }
    this.setState({
      fileList,
    });
  };

  handleChangeVideoUpload = info => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    const videoWrapper = document.getElementsByClassName('video-wrapper')[0];
    const uploadList = document.getElementsByClassName('ant-upload-list')[0];
    const videoBanner = document.getElementsByClassName('video-banner')[0];
    const videoFile = info.file.originFileObj;
    const isJpgOrPng = videoFile.type.indexOf('video') > -1;

    if (!isJpgOrPng) {
      this.setState({
        videoError: 'You can only upload video file',
      });
      return;
    }
    if (fileList[0] && fileList[0].status !== 'done') {
      this.setState({
        fileVideos: fileList,
        isBannerDisplay: false,
        isBtnDisabled: true,
      });
      videoWrapper.style.display = 'none';
      uploadList.style.display = 'block';
      this.setState({
        videoError: '',
      });
    } else {
      videoWrapper.style.display = 'block';
      videoWrapper.style.marginBottom = '10px';
      uploadList.style.display = 'none';
      if (fileList[0] && fileList[0].originFileObj) {
        this.blobURL = URL.createObjectURL(fileList[0].originFileObj);
        videoBanner.src = this.blobURL;
        this.setState({
          video: info.file.originFileObj,
          isBtnDisabled: false,
        });
      }
    }
  };

  // get old path in filelist
  filterFilePath = images => {
    const imageUrls = images.filter(item => !item.size);
    if (imageUrls && imageUrls.length) {
      const imagePaths = imageUrls.map(item => item.name);
      return imagePaths;
    }
    return [];
  };

  filterOriginFiles = images => {
    const filterImages = images.filter(item => item.size > 0);
    return filterImages.map(item => item.originFileObj);
  };

  filterNameLogos = logos => {
    return logos.map(item => item.split('/')[2]);
  };

  handleSubmit = e => {
    e.preventDefault();
    if (!this.state.video && !this.state.urlBanner) {
      this.setState({
        videoError: 'Please upload a video',
      });
      return;
    }
    if (!this.state.fileList.length) {
      this.setState({
        photosError: 'Please upload photos',
      });
      return;
    }
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const { fileList, deleteLogos, video } = this.state;
        const filterImages = this.filterOriginFiles(fileList);
        const oldFilePath = this.filterFilePath(fileList);
        deleteLogos.push('deleteLogos');
        let bannerParams;
        let trustedByParams = [];
        let data = {
          title: values.title.trim(),
          subTitle: values.subTitle.trim(),
          deleteLogos,
        };

        this.setState({
          isLoading: true,
        });

        // upload banner to s3
        if (video) {
          bannerParams = {
            filename: `${
              S3_FOLDERS.CMS.LANDING_PAGE.BANNER
            }/${uuid()}${extractFileExtension({ filename: video.name })}`,
            file: video,
          };
          const res = await httpService._upload({ photos: [bannerParams] });
          if (res && res.length) {
            data.video = res[0];
          }
        }

        // upload trustedBy to s3
        if (filterImages && filterImages.length) {
          trustedByParams = filterImages.map(item => ({
            filename: `${
              S3_FOLDERS.CMS.LANDING_PAGE.TRUSTED_BY
            }/${uuid()}${extractFileExtension({ filename: item.name })}`,
            file: item,
          }));
          const res = await httpService._upload({ photos: trustedByParams });
          if (res && res.length) {
            data.logos = [...oldFilePath, ...res];
          }
        }

        if (data.subTitle === '') {
          data.subTitle = ' ';
        }
        this.props.onUpdateBanner(formDataConfig(data));
      }
    });
    URL.revokeObjectURL(this.blobURL);
  };

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        isLoading: false,
      });
    }
  }

  onRemove = file => {
    const { deleteLogos } = this.state;
    if (file.name) {
      deleteLogos.push(file.name);
      this.setState({
        deleteLogos,
        isBtnDisabled: false,
      });
    }
  };

  onRemoveVideo = file => {
    this.setState({
      isBannerDisplay: true,
      isBtnDisabled: false,
      video: null,
    });
    const videoBanner = document.getElementsByClassName('video-banner')[0];
    videoBanner.src = '';
  };

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      let urlBanner = '';
      const images = nextProps.data.urlLogo
        ? nextProps.data.urlLogo.map((item, index) => {
            return {
              uid: index,
              name: item,
              status: 'done',
              url: getS3CDN(item),
            };
          })
        : [];
      if (nextProps.data.urlBanner) {
        urlBanner = getS3CDN(nextProps.data.urlBanner);
      }
      const { title, subTitle } = nextProps.data;
      this.setState({
        fileList: images,
        urlBanner,
        title,
        subTitle,
      });
    }
  }

  revertData = () => {
    // const videoBanner = document.getElementsByClassName('video-banner')[0];
    const videoWrapper = document.getElementsByClassName('video-wrapper')[0];
    this.props.form.setFieldsValue({
      title: this.props.data.title,
      subTitle: this.props.data.subTitle,
    });
    this.setState({
      isBannerDisplay: true,
      fileVideos: null,
      video: null,
    });
    videoWrapper.style.display = 'block';
    // videoBanner.src = '';
    if (Array.isArray(this.props.data) && !this.props.data.length) {
      this.setState({
        fileList: [],
      });
    } else {
      const images = this.props.data.urlLogo.map((item, index) => {
        return {
          uid: index,
          name: item,
          status: 'done',
          url: getS3CDN(item),
        };
      });
      this.setState({
        fileList: images,
      });
    }
  };

  render() {
    const fileVideos = [];
    const propsVideo = {
      customRequest: dummyRequest,
      listType: 'picture',
      defaultFileList: [...fileVideos],
      className: 'upload-list-inline',
      onChange: this.handleChangeVideoUpload,
      onRemove: this.onRemoveVideo,
    };
    const { previewVisible, previewImage, fileList, isLoading } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const { getFieldDecorator } = this.props.form;
    return (
      <Form className="admin-hompage-form" onSubmit={this.handleSubmit}>
        <div className="content-header">
          <h1 className="heading">Homepage</h1>
          <div>
            <Button onClick={this.revertData} className="btn-second-0">
              Discard
            </Button>
            <Button
              htmlType="submit"
              className="btn-primary-0 ml-4"
              disabled={this.state.isBtnDisabled}
              loading={isLoading}
            >
              Save
            </Button>
          </div>
        </div>
        <Jumbotron className="mgt-40">
          <h2 className="sub-heading">Hero section</h2>
          <p className="description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </p>
          <div className="divider"></div>
          <Container>
            <Row>
              <Col className="video-upload-container" xs={6}>
                <h3 className="video-heading">Video background</h3>

                <div className="video-wrapper">
                  {this.state.isBannerDisplay &&
                    (!this.state.urlBanner ? (
                      <div className="novideo-container">No video banner!</div>
                    ) : (
                      <video
                        className="initial-banner"
                        src={this.state.urlBanner}
                        alt="video background"
                      />
                    ))}
                  <video className="video-banner" alt="video background" />
                </div>
                {this.state.videoError ? (
                  <div className="ant-form-explain">{this.state.videoError}</div>
                ) : null}
                <Upload {...propsVideo} fileList={this.state.fileVideos}>
                  <Button className="btn-primary-0">Upload video</Button>
                  <p className="description">Change your video background</p>
                </Upload>
              </Col>
              <Col xs={6}>
                <h3 className="video-heading">Title</h3>
                <Form.Item>
                  {getFieldDecorator('title', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input title',
                      },
                      {
                        whitespace: true,
                        message: 'Please do not input whitespace only',
                      },
                    ],
                    initialValue: this.state.title,
                  })(<TextArea rows={4} />)}
                </Form.Item>
                <h3 className="video-heading">Subtitle</h3>
                <Form.Item>
                  {getFieldDecorator('subTitle', {
                    rules: [
                      {
                        whitespace: true,
                        message: 'Please do not input whitespace only',
                      },
                    ],
                    initialValue: this.state.subTitle,
                  })(<TextArea rows={4} />)}
                </Form.Item>
              </Col>
            </Row>
          </Container>
        </Jumbotron>
        <Jumbotron className="mgt-40">
          <h2 className="sub-heading">Trusted by - Clients logo</h2>
          <p className="description">Update clients logo from the list</p>
          <div className="divider"></div>
          <Container>
            <div className="clearfix">
              <Upload
                customRequest={dummyRequest}
                listType="picture-card"
                fileList={fileList}
                onPreview={this.handlePreview}
                onChange={this.handleChange}
                onRemove={this.onRemove}
              >
                {fileList.length >= 12 ? null : uploadButton}
              </Upload>
              {this.state.photosError && (
                <div className="ant-form-explain">{this.state.photosError}</div>
              )}
              <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
              </Modal>
            </div>
          </Container>
        </Jumbotron>
      </Form>
    );
  }
}

const WrappedDashboardForm = Form.create({ name: 'normal_upload' })(DashboardForm);

export default WrappedDashboardForm;
