import { connect } from 'react-redux';
import ProductTypesScreen from '../../../components/screens/admin/productTypes/productTypesScreen';
import {
	getProductTypes,
	createProductTypes,
	updateProductTypes,
	updateOrder
} from '../../../redux/actions/admin/productTypesAction';
import { getCurrentUser } from '../../../redux/actions/admin/userAction';

const mapStateToProps = state => {
	return {
		success: state.productType.success,
		error: state.productType.error,
		isLoading: state.productType.isLoading,
		data: state.productType.data
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onGetProductTypes: () => {
			dispatch(getProductTypes());
		},
		onCreateProductTypes: data => {
			dispatch(createProductTypes(data));
		},
		onUpdateProductTypes: ({ data, id }) => {
			dispatch(updateProductTypes({ data, id }));
		},
		onUpdateOrder: data => {
			dispatch(updateOrder(data));
		},
		onGetCurrentUser: () => {
			dispatch(getCurrentUser());
		}
	};
};

const ProductTypesContainer = connect(mapStateToProps, mapDispatchToProps)(ProductTypesScreen);

export default ProductTypesContainer;
