import {
  GET_INVITATION_FAILED,
  GET_INVITATION_SUCCESS,
  GET_INVITATION_REQUEST,
  RESEND_INVITATION_TEAM_SUCCESS,
  RESEND_INVITATION_TEAM_FAILED,
  RESEND_INVITATION_TEAM_REQUEST,
  SEND_INVITATION_TEAM_SUCCESS,
  SEND_INVITATION_TEAM_FAILED,
  SEND_INVITATION_TEAM_REQUEST,
} from '../../actions/typeAction';
import { openNotification } from '../../../components/common/notification/notification';

const defaultState = {
  success: false,
  error: null,
  data: [],
  isLoading: false,
};

const invitationsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_INVITATION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_INVITATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    }
    case GET_INVITATION_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case RESEND_INVITATION_TEAM_SUCCESS: {
      openNotification('success', 'Resent successfully');
      return {
        ...state,
        isLoading: true,
      };
    }
    case RESEND_INVITATION_TEAM_REQUEST: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case RESEND_INVITATION_TEAM_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    case SEND_INVITATION_TEAM_REQUEST: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case SEND_INVITATION_TEAM_SUCCESS: {
      openNotification('success', 'Invitation sent successfully');
      return {
        ...state,
        isLoading: false,
      };
    }
    case SEND_INVITATION_TEAM_FAILED: {
      openNotification('error', action.payload.message);
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
      };
    }
    default:
      return state;
  }
};

export default invitationsReducer;
