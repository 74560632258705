import { httpService } from '../httpService';
import { BaseConfig } from '../../configs/baseConfig';

export const getHowitworkService = () => {
    const url = BaseConfig.END_POINT.ADMIN.HOWITWORK;
    const response = httpService._get(url);
    return response;
};

export const deleteHowitworkService = (id) => {
    const url = BaseConfig.END_POINT.ADMIN.HOWITWORK;
    const response = httpService._delete(`${url}/${id}`);
    return response;
};

export const createHowitworkService = (data) => {
    const url = BaseConfig.END_POINT.ADMIN.HOWITWORK;
    const response = httpService._post(url, data);
    return response;
};

export const updateHowitworkService = ({data, id}) => {
    const url = BaseConfig.END_POINT.ADMIN.HOWITWORK;
    const response = httpService._put(`${url}/${id}`, data);
    return response;
};