import { put, call, takeLatest } from 'redux-saga/effects';

import * as actionTypes from '../../actions/typeAction';
import {
	getAddhocService,
	createAddhocService,
	updateAddhocService
} from '../../../core/services/modules/addhocService';

export function* getAddhocSaga() {
	const response = yield call(getAddhocService);
	if (response.data.error) {
		yield put({
			type: actionTypes.GET_ADD_HOC_FAILED,
			payload: response.data.error
		});
	} else {
		const data = response.data;
		yield put({
			type: actionTypes.GET_ADD_HOC_SUCCESS,
			payload: data
		});
	}
}

export function* createAddhocSaga(action) {
	const response = yield call(createAddhocService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.CREATE_ADD_HOC_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.CREATE_ADD_HOC_SUCCESS,
			payload: response.data
		});
	}
}

export function* updateAddhocSaga(action) {
	const response = yield call(updateAddhocService, action.payload);
	if (response.data.error) {
		yield put({
			type: actionTypes.UPDATE_ADD_HOC_FAILED,
			payload: response.data.error
		});
	} else {
		yield put({
			type: actionTypes.UPDATE_ADD_HOC_SUCCESS,
			payload: response.data
		});
	}
}

export function* watchAddhoc() {
	yield takeLatest(actionTypes.GET_ADD_HOC_REQUEST, getAddhocSaga);
	yield takeLatest(actionTypes.CREATE_ADD_HOC_REQUEST, createAddhocSaga);
	yield takeLatest(actionTypes.UPDATE_ADD_HOC_REQUEST, updateAddhocSaga);
}
