import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Select } from 'antd';
import './selectFilter.scss';

const { Option } = Select;

class SelectFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selected: null,
    };
  }

  onChange = value => {
    this.setState({ selected: value });
    this.props.onSelect(value);
  };

  onSearch = val => {
    this.props.onSearchHandler(val);
  };

  handleScroll = event => {
    const { data } = this.props;
    const { page, pages } = data;
    if (page === pages) return;
    const { scrollTop, offsetHeight, scrollHeight } = event.target;
    if (!this.state.loading && scrollTop + offsetHeight === scrollHeight) {
      this.setState({ loading: true }, () => {
        setTimeout(() => {
          if (page < pages) {
            this.props.onLoadMoreHandler({ page: page + 1 });
          }
          this.setState({ loading: false });
        }, 1000);
      });
    }
  };

  renderOption = docs => {
    return (
      (docs &&
        docs.length &&
        docs.map(item => {
          let label = item.name;
          if (
            this.props.type === 'team' &&
            item.customerUser &&
            !!item.customerUser.company
          ) {
            label = `${item.customerUser.company} - ${item.name}`;
          }
          return (
            <Option key={item._id} value={item._id}>
              {label}
            </Option>
          );
        })) ||
      []
    );
  };

  render() {
    const { data, placeholder, disabled } = this.props;
    let docs = (data && (data.docs || data.list)) || [];
    return (
      <Select
        showSearch
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={this.onChange}
        onSearch={this.onSearch}
        onPopupScroll={this.handleScroll}
        disabled={disabled}
        className="select-filter"
        mode="default"
        style={{ width: '90%' }}
        getPopupContainer={triggerNode => triggerNode.parentElement}
        value={this.props.selected === 'reset-select' ? null : this.state.selected}
      >
        {!this.state.loading
          ? this.renderOption(docs)
          : [
              ...this.renderOption(docs),
              <Option key="loading" disabled>
                Loading...
              </Option>,
            ]}
      </Select>
    );
  }
}

export default withTranslation('common')(SelectFilter);
